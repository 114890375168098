import React from "react";

const WordCloudChip = ({ words, label }) => {
    const wordCloudStyle = {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        padding: "10px",
        gap: "10px",
        marginBottom: "20px",
        maxWidth: "300px",
    };

    const chipStyle = {
        padding: "5px 10px",
        backgroundColor: "gray",
        textAlign: "center",
        color: "white",
        fontSize: "10px",
    };

    const labelStyle = {
        fontSize: "10px",
        margin: "10px 0 0 0",
        textAlign: "center",
        width: "100%",
        color: "green",
        fontWeight: "bold",
    };

    return (
        <div>
            <div style={wordCloudStyle}>
                {words.map((word, index) => (
                    <div key={index} style={chipStyle}>
                        {word}
                    </div>
                ))}
            </div>
            <div style={labelStyle}>{label}</div>
        </div>
    );
};

export default WordCloudChip;
