import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";

import withStyles from "@mui/styles/withStyles";

import Footer from "./Footer";
import Header from "./Header";
import PageHeader from "./PageHeader";
import Navigation from "./Navigation";
import NotReady from "./NotReady";

const styles = (theme) => ({
    appContainer: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        overflow: "hidden",
    },
    contentPane: {
        flex: 1,
        overflowY: "auto",
    },
    contentPaneFlex: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
    },
    contentContainer: {
        padding: theme.spacing(4),
    },
    contentContainerFlex: {
        flex: 1,
        display: "flex",
        overflowY: "hidden",
    },
    publicBackground: {
        backgroundColor: "#f0f0f0",
    },
    whiteBackground: {
        backgroundColor: "#ffffff",
    },
});

class AppContainer extends Component {
    render() {
        const {
            children,
            classes,
            authenticated,
            flex,
            navState,
            authState,
            synced,
            handleUnsavedFields,
            homePage,
            color,
            title,
            pageTitle,
            crumbs,
            header,
            navOpen,
            onNavOpenChange,
            background,
        } = this.props;

        return authenticated ? ( // Logged In
            <div className={classes.appContainer}>
                <Header authenticated handleUnsavedFields={handleUnsavedFields} />
                <div
                    id="appContainer"
                    style={{
                        flex: 1,
                        display: "flex",
                        overflow: "hidden",
                        backgroundColor: background !== undefined ? background : "white",
                    }}
                >
                    <Navigation navOpen={navOpen} onNavOpenChange={onNavOpenChange} />
                    <div className={flex ? classes.contentPaneFlex : classes.contentPane}>
                        {header}
                        {title && <PageHeader color={color} title={title} pageTitle={pageTitle} crumbs={crumbs} />}
                        {authState.user ? (
                            !synced ? (
                                <div className={flex ? classes.contentContainerFlex : classes.contentContainer}>
                                    {children}
                                </div>
                            ) : (
                                <NotReady message="Loading..." children={children} />
                            )
                        ) : (
                            <NotReady storeReady={navState.storeReady} children={children} />
                        )}
                    </div>
                </div>
                <Footer />
            </div>
        ) : (
            // Public
            <div
                className={classNames(
                    classes.appContainer,
                    homePage ? classes.whiteBackground : classes.publicBackground,
                )}
            >
                <Header />
                <div
                    className={classNames(
                        classes.contentPane,
                        homePage ? classes.whiteBackground : classes.publicBackground,
                    )}
                >
                    {children}
                </div>
                <Footer />
            </div>
        );
    }
}

AppContainer = connect((state) => ({
    navState: state.nav,
    authState: state.auth,
}))(AppContainer);

export default withStyles(styles)(AppContainer);
