import React from "react";
import BiodiversityScore from "./BiodiversityScore";
import SoilCarbonScore from "./SoilCarbonScore";
import WaterQualityScore from "./WaterQualityScore";
import BiodiversityScorePrint from "./BiodiversityScorePrint";
import Score from "./Score";
import { getValue } from "../../../../api/utils";
import { getUnitsValue, round } from "../StaticData";

const webScore = {
    biodiversity: BiodiversityScore,
    soilCarbon: SoilCarbonScore,
    waterQuality: WaterQualityScore,
};

const printScore = {
    biodiversity: BiodiversityScorePrint,
    soilCarbon: SoilCarbonScore,
    waterQuality: WaterQualityScore,
};

function DefaultScore({ indicator, printable }) {
    const result = getValue(indicator, "fieldprintResult"),
        unit = result && (getUnitsValue(result, "unit") || result.index),
        value = round(result && result.value, indicator.metric);
    return <Score value={value} unit={unit} printable={printable} />;
}

export default function getScoreComponent(metric, printable) {
    if (printable) {
        return printScore[metric] || DefaultScore;
    } else {
        return webScore[metric] || DefaultScore;
    }
}
