import { useState, useMemo, useCallback } from "react";

export default function useHistory(initialValue) {
    const [history, setHistory] = useState([]),
        [historyIndex, setHistoryIndex] = useState(null),
        data = useMemo(() => {
            if (history.length === 0 || historyIndex === 0) {
                return {
                    type: "FeatureCollection",
                    features: initialValue ? [{ type: "Feature", id: 1, geometry: initialValue }] : [],
                };
            } else if (historyIndex !== null && history[historyIndex - 1]) {
                return history[historyIndex - 1];
            } else {
                return history[history.length - 1];
            }
        }, [history, historyIndex, initialValue]),
        setData = useCallback(
            (data) => {
                if (history.length === 0) {
                    setHistory([data]);
                } else if (historyIndex !== null) {
                    setHistory(history.slice(0, historyIndex).concat([data]));
                } else if (JSON.stringify(data) === JSON.stringify(history[history.length - 1])) {
                    setHistory(history.slice(0, -1).concat([data]));
                } else {
                    setHistory(history.concat([data]));
                }
                setHistoryIndex(null);
            },
            [history, historyIndex],
        ),
        undo =
            history.length === 0 || historyIndex === 0
                ? null
                : () => {
                      if (historyIndex === null && history.length > 0) {
                          setHistoryIndex(history.length - 1);
                      } else if (historyIndex !== null && historyIndex > 0) {
                          setHistoryIndex(historyIndex - 1);
                      }
                  },
        redo =
            history.length === 0 || historyIndex === null || historyIndex > history.length - 1
                ? null
                : () => {
                      if (historyIndex !== null) {
                          if (historyIndex + 1 < history.length) {
                              setHistoryIndex(historyIndex + 1);
                          } else {
                              setHistoryIndex(null);
                          }
                      }
                  };

    return [data, setData, undo, redo];
}
