import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import Button from "../../common/ButtonWrapper";
import Grid from "../../common/GridWrapper";
import Typography from "../../common/TypographyWrapper";
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import StepIcon from "@mui/material/StepIcon";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import withStyles from "@mui/styles/withStyles";
import ReviewTable from "./ReviewTable";
import WarningDialog from "../../common/WarningDialog";
import * as constants from "./constants";

import { FarmHpiSurvey } from "../models";

import { setValue, getValue } from "../../../api/utils";
import { apiFetch } from "../../../api/fetch";

import { LandConversionTypes } from "../fieldactivities/models";

const allLandConversionTypes = LandConversionTypes.selectAll();

const styles = (theme) => ({
    rightAlign: {
        textAlign: "right",
    },
    centerAlign: {
        textAlign: "center",
    },
    inline: {
        display: "inline",
        textAlign: "left",
    },

    gutterTop: {
        marginTop: ".6em",
    },
    listItem: {
        paddingTop: 20,
        paddingBottom: 20,
        borderTop: "1px solid #DDDDDD",
        borderBottom: "1px solid #DDDDDD",
        "& svg": {
            color: theme.palette.primary.main, // + " !important",
        },
    },
    linkColor: {
        color: theme.palette.primary.main,
    },
    titleContainer: {
        backgroundColor: "#ff7d32",
        textAlign: "center",
    },
    closeIcon: {
        float: "right",
        color: "#fff",
        marginTop: -16,
        marginRight: -16,
    },
    dialogMin: {
        minWidth: "400px",
    },
    questionTitle: {
        color: "#fff",
        fontWeight: 500,
        float: "left",
    },
    button: {
        float: "right",
        marginTop: 24,
        marginRight: 24,
    },
    marginTopTwo: {
        marginTop: 24,
    },
    cancelBtn: {
        border: "1px solid",
        marginRight: 24,
    },
    gridHeader: {
        backgroundColor: theme.palette.primary.main,
    },
});

const arraySum = (arr) => arr.reduce((a, b) => a + b, 0);

const arrayAvg = (arr) => arr.reduce((a, b) => a + b, 0) / arr.length;

class Review extends Component {
    state = {
        helpOpen: false,
    };

    getCultivatedLandSurveyData = () => {
        var data = [];
        let crop_ids = [];
        this.props.farmHpiSurvey.cultivated.forEach((q) => {
            if (crop_ids.indexOf(q.crop_id) === -1) {
                crop_ids.push(q.crop_id);
            }
        });

        crop_ids.forEach((id) => {
            let crop_name = this.props.allCrops.filter((c) => c.id === id)[0]["name"];
            let cqs = this.props.farmHpiSurvey.cultivated.filter((q) => q.crop_id === id);
            let total_area = arraySum(cqs.map((q) => parseFloat(q["plantable_area"])));
            data.push({ name: crop_name, area: total_area.toFixed(2) });
        });

        if (data.length === 0) {
            return [];
        }

        let total_area = arraySum(data.map((d) => parseFloat(d.area)));

        data.push({ name: "Cultivated Total", area: total_area.toFixed(2) });
        return data;
    };

    getNoncultivatedLandSurveyData = () => {
        var data = [];

        // Forests

        if (this.props.farmHpiSurvey.forest && this.props.farmHpiSurvey.forest.conifer_area) {
            data.push({
                name: "Conifer Plantation",
                area: parseFloat(this.props.farmHpiSurvey.forest.conifer_area).toFixed(2),
            });
        }

        if (this.props.farmHpiSurvey.forest && this.props.farmHpiSurvey.forest.deciduous_area) {
            data.push({
                name: "Deciduous Forest",
                area: parseFloat(this.props.farmHpiSurvey.forest.deciduous_area).toFixed(2),
            });
        }

        if (this.props.farmHpiSurvey.forest && this.props.farmHpiSurvey.forest.evergreen_area) {
            data.push({
                name: "Evergreen Forest",
                area: parseFloat(this.props.farmHpiSurvey.forest.evergreen_area).toFixed(2),
            });
        }

        if (this.props.farmHpiSurvey.forest && this.props.farmHpiSurvey.forest.mixed_area) {
            data.push({
                name: "Mixed Forest",
                area: parseFloat(this.props.farmHpiSurvey.forest.mixed_area).toFixed(2),
            });
        }

        // Grassland

        if (this.props.farmHpiSurvey.grassland && this.props.farmHpiSurvey.grassland.native_ungrazed_area) {
            data.push({
                name: "Native Grassland/Savanna (ungrazed)",
                area: parseFloat(this.props.farmHpiSurvey.grassland.native_ungrazed_area).toFixed(2),
            });
        }

        if (this.props.farmHpiSurvey.grassland && this.props.farmHpiSurvey.grassland.native_grazed_area) {
            data.push({
                name: "Native Grassland/Savanna (grazed)",
                area: parseFloat(this.props.farmHpiSurvey.grassland.native_grazed_area).toFixed(2),
            });
        }

        if (this.props.farmHpiSurvey.grassland && this.props.farmHpiSurvey.grassland.nonnative_ungrazed_area) {
            data.push({
                name: "Non-native Grassland/Savanna (ungrazed)",
                area: parseFloat(this.props.farmHpiSurvey.grassland.nonnative_ungrazed_area).toFixed(2),
            });
        }

        if (this.props.farmHpiSurvey.grassland && this.props.farmHpiSurvey.grassland.nonnative_grazed_area) {
            data.push({
                name: "Non-native Grassland/Savanna (grazed)",
                area: parseFloat(this.props.farmHpiSurvey.grassland.nonnative_grazed_area).toFixed(2),
            });
        }

        //  Riparian

        if (this.props.farmHpiSurvey.buffer && this.props.farmHpiSurvey.buffer.edge_area) {
            data.push({
                name: "Edge Features",
                area: parseFloat(this.props.farmHpiSurvey.buffer.edge_area).toFixed(2),
            });
        }

        if (this.props.farmHpiSurvey.buffer && this.props.farmHpiSurvey.buffer.riparian_area) {
            data.push({
                name: "Riparian Buffers",
                area: parseFloat(this.props.farmHpiSurvey.buffer.riparian_area).toFixed(2),
            });
        }

        //  Surface waters

        if (this.props.farmHpiSurvey.surfacewater && this.props.farmHpiSurvey.surfacewater.stream_length) {
            data.push({
                name: "Streams",
                area: parseFloat(this.props.farmHpiSurvey.surfacewater.stream_length).toFixed(2),
            });
        }

        if (this.props.farmHpiSurvey.surfacewater && this.props.farmHpiSurvey.surfacewater.river_length) {
            data.push({
                name: "Rivers",
                area: parseFloat(this.props.farmHpiSurvey.surfacewater.river_length).toFixed(2),
            });
        }

        if (this.props.farmHpiSurvey.surfacewater && this.props.farmHpiSurvey.surfacewater.pond_natural_area) {
            data.push({
                name: "Ponds (natural)",
                area: parseFloat(this.props.farmHpiSurvey.surfacewater.pond_natural_area).toFixed(2),
            });
        }

        if (this.props.farmHpiSurvey.surfacewater && this.props.farmHpiSurvey.surfacewater.pond_artificial_area) {
            data.push({
                name: "Ponds (artificial)",
                area: parseFloat(this.props.farmHpiSurvey.surfacewater.pond_artificial_area).toFixed(2),
            });
        }

        if (this.props.farmHpiSurvey.surfacewater && this.props.farmHpiSurvey.surfacewater.lake_natural_area) {
            data.push({
                name: "Lakes (natural)",
                area: parseFloat(this.props.farmHpiSurvey.surfacewater.lake_natural_area).toFixed(2),
            });
        }

        if (this.props.farmHpiSurvey.surfacewater && this.props.farmHpiSurvey.surfacewater.lake_artificial_area) {
            data.push({
                name: "Lakes (artificial)",
                area: parseFloat(this.props.farmHpiSurvey.surfacewater.lake_artificial_area),
            });
        }

        //  Wetlands

        if (this.props.farmHpiSurvey.wetland && this.props.farmHpiSurvey.wetland.natural_area) {
            data.push({
                name: "Natural or Restored Wetland",
                area: parseFloat(this.props.farmHpiSurvey.wetland.natural_area),
            });
        }

        if (this.props.farmHpiSurvey.wetland && this.props.farmHpiSurvey.wetland.artificial_area) {
            data.push({
                name: "Artificial Wetland",
                area: parseFloat(this.props.farmHpiSurvey.wetland.artificial_area),
            });
        }

        if (data.length === 0) {
            return [];
        }

        let total_area = arraySum(data.map((d) => parseFloat(d.area)));

        data.push({ name: "Non-Cultivated Total", area: total_area.toFixed(2) });
        return data;
    };

    getMissingData = () => {
        let data = {};

        if (this.props.farmHpiSurvey.cultivated.length === 0) {
            data["cultivated"] = true;
        } else {
            this.props.farmHpiSurvey.cultivated.forEach((q) => {
                if (q.missing > 0) {
                    data["cultivated"] = true;
                }
            });
        }

        if (this.props.farmHpiSurvey.forest && this.props.farmHpiSurvey.forest.missing > 0) {
            data["forest"] = true;
        }

        if (this.props.farmHpiSurvey.grassland && this.props.farmHpiSurvey.grassland.missing > 0) {
            data["grassland"] = true;
        }

        if (this.props.farmHpiSurvey.riparian && this.props.farmHpiSurvey.riparian.missing > 0) {
            data["buffer"] = true;
        }

        if (this.props.farmHpiSurvey.surfacewater && this.props.farmHpiSurvey.surfacewater.missing > 0) {
            data["surface_water"] = true;
        }

        if (this.props.farmHpiSurvey.wetland && this.props.farmHpiSurvey.wetland.missing > 0) {
            data["wetland"] = true;
        }

        return data;
    };

    finalize(is_final) {
        const { id, year } = this.props.farmHpiSurvey;

        if (parseInt(year) > new Date().getFullYear()) {
            this.setState({ helpOpen2: true });
            return;
        }

        if (is_final) {
            this.props.ormFarmHpiSurveyUpdate({
                id: id,
                is_final: is_final,
            });
            this.setState({ moveDialogOpen: true });
        } else {
            this.setState({ moveDialogOpen2: true });
        }
    }

    async getEcoregion() {
        var x_coords = [];
        var y_coords = [];
        this.props.cropyears.forEach((c) => {
            x_coords.push(c.field.center.lng);
            y_coords.push(c.field.center.lat);
        });

        const x_coord = arrayAvg(x_coords);
        const y_coord = arrayAvg(y_coords);

        const response = await apiFetch("/v4/calc/Ecoregion", {
            method: "POST",
            body: JSON.stringify({
                latitude: y_coord,
                longitude: x_coord,
            }),
        });

        const ecoregion = await response.json();
        return ecoregion;
    }

    getEcoweight = (questionnaire, ecoregion, land_type_code, pop_change) => {
        if (land_type_code === "native_grazed") {
            if (pop_change === 0) {
                if (questionnaire[land_type_code + "_grazing_intensity"] === "1") {
                    return constants.ecoregions.filter((e) => e["eco_code"] === ecoregion["eco_code"])[0][
                        "native_ungrazed"
                    ][pop_change];
                } else {
                    return 2;
                }
            } else {
                if (questionnaire[land_type_code + "_grazing_intensity"] === "1") {
                    return constants.ecoregions.filter((e) => e["eco_code"] === ecoregion["eco_code"])[0][
                        "native_ungrazed"
                    ][pop_change];
                } else {
                    return 2.5;
                }
            }
        } else if (land_type_code === "wetland_artificial") {
            if (
                [
                    "M262",
                    "262",
                    "222",
                    "411",
                    "332",
                    "311",
                    "331",
                    "212",
                    "234",
                    "M231",
                    "232",
                    "255",
                    "251",
                    "231",
                    "315",
                ].indexOf(ecoregion["eco_code"]) > -1
            ) {
                if (pop_change === 0) {
                    if (this.checkForRiceCrop()) {
                        return 4;
                    } else {
                        return 2;
                    }
                } else {
                    if (this.checkForRiceCrop()) {
                        return 4.5;
                    } else {
                        return 2.5;
                    }
                }
            }
        }

        return constants.ecoregions.filter((e) => e["eco_code"] === ecoregion["eco_code"])[0][land_type_code][
            pop_change
        ];
    };

    checkForRiceCrop = () => {
        var rice_found = false;
        this.props.farmHpiSurvey.cultivated.forEach((c) => {
            if (c.crop_id === "39") {
                rice_found = true;
            }
        });
        return rice_found;
    };

    getHPI1 = (questionnaire, type) => {
        if (type === "stream" || type === "river") {
            return parseFloat(questionnaire[type + "_length"].split(" ")[0]);
        }
        return parseFloat(questionnaire[type + "_area"].split(" ")[0]);
    };

    getHPI2 = (questionnaire, type, ecoregion, pop_change) => {
        //  have to fudge the types to handle inconsistance with wetland types
        var type_2 = JSON.parse(JSON.stringify(type));
        if (type.includes("wetland")) {
            type = type.includes("wetland") ? type.split("_")[1] : type;
        }

        var area = this.getHPI1(questionnaire, type);
        var area_converted = 0;

        if (type === "plantable") {
            area_converted = questionnaire["field_area_converted"]
                ? parseFloat(questionnaire["field_area_converted"].split(" ")[0])
                : 0;
        } else {
            //  if there is no change, return 1
            area_converted = questionnaire[type + "_area_converted"]
                ? parseFloat(questionnaire[type + "_area_converted"].split(" ")[0])
                : 0;
        }

        if (area_converted === 0) {
            return 1;
        }

        if (type === "plantable" && questionnaire["crop_id"] === "39") {
            if (questionnaire["wildlife_habitat"] === "3") {
                let from_use_id = questionnaire["previous_land_cover"];
                let from_use_code = constants.land_types.filter((n) => n.id === from_use_id)[0]["code"];

                const from_value = this.getEcoweight(questionnaire, ecoregion, from_use_code, pop_change);

                const to_value = this.getEcoweight(questionnaire, ecoregion, "wetland_artificial", pop_change);
                let change_score = 1 - (area_converted / area) * (Math.abs(to_value - from_value) / 4);
                return change_score;
            } else {
                let from_use_id = questionnaire["previous_land_cover"];
                let from_use_code = constants.land_types.filter((n) => n.id === from_use_id[0])["code"];
                const from_value = this.getEcoweight(questionnaire, ecoregion, from_use_code, pop_change);

                let change_score = 1 - (area_converted / area) * (Math.abs(1 - from_value) / 4);
                return change_score;
            }
        }

        let from_use_id =
            type === "plantable" ? questionnaire["previous_land_cover"] : questionnaire[type + "_previous_land_cover"];
        let from_use_code =
            from_use_id === "99" ? "cultivated" : constants.land_types.filter((n) => n.id === from_use_id)[0]["code"];
        let from_value =
            from_use_code === "cultivated" ? 1 : this.getEcoweight(questionnaire, ecoregion, from_use_code, pop_change);
        let to_value = type_2 === "plantable" ? 1 : this.getEcoweight(questionnaire, ecoregion, type_2, pop_change);
        let change_score =
            area_converted === 0 ? 1 : 1 - (area_converted / area) * Math.abs((to_value - from_value) / 4);
        return change_score;
    };

    getHPI3 = (questionnaire) => {
        return constants.hpi_tillage_scores.filter((v) => v.id === questionnaire["tillage_practice"])[0]["value"];
    };

    getHPI4 = (questionnaire, crop) => {
        if (questionnaire.crop_id === "8") {
            return constants.hpi_rotation_practices_alfalfa_scores.filter(
                (v) => v.id === questionnaire["rotation_practice"],
            )[0]["value"];
        } else {
            return constants.hpi_rotation_practices_scores.filter(
                (v) => v.id === questionnaire["rotation_practice"],
            )[0]["value"];
        }
    };

    getHPI5 = (questionnaire) => {
        return constants.hpi_cover_crop_scores.filter((v) => v.id === questionnaire["cover_crop"])[0]["value"];
    };

    getHPI6 = (questionnaire) => {
        if (questionnaire["nutrient_management_plan"] === true) {
            return 1;
        }

        var scores = constants.hpi_nutrient_4rs_scores
            .filter((v) => questionnaire["nutrient_4_rs"].split(",").indexOf(v.id) >= 0)
            .map((s) => s.value);

        return arraySum(scores);
    };

    getHPI7 = (questionnaire) => {
        return constants.hpi_pest_management_scores.filter((v) => v.id === questionnaire["pest_management"])[0][
            "value"
        ];
    };

    getHPI8 = (questionnaire) => {
        if (questionnaire["majority_irrigated"]) {
            var water_source_score = constants.hpi_water_source_scores.filter(
                (v) => v.id === questionnaire["water_source"],
            )[0]["value"];
            if (water_source_score !== null) {
                return water_source_score;
            }
            return constants.hpi_water_conservation_scores.filter(
                (v) => v.id === questionnaire["irrigation_conservation"],
            )[0]["value"];
        }
        return 1;
    };

    getHPI9 = (questionnaire) => {
        return constants.hpi_drainage_scores.filter(
            (v) => v.ftm_options.indexOf(questionnaire["tile_drainage"]) >= 0,
        )[0]["value"];
    };

    getHPI10 = (questionnaire, crop_id) => {
        var scores = constants.hpi_wildlife_habitat_scores
            .filter((v) => questionnaire["wildlife_habitat"].split(",").indexOf(v.id) >= 0)
            .map((s) => s.value);
        return arraySum(scores);
    };

    getHPI11 = (questionnaire, type) => {
        return constants.hpi_buffer_vegetation_scores.filter((v) => v.id === questionnaire[type + "_vegetated"])[0][
            "value"
        ];
    };

    getHPI12 = (questionnaire, type) => {
        var scores = constants.hpi_buffer_management_scores
            .filter((v) => questionnaire[type + "_management"].split(",").indexOf(v.id) >= 0)
            .map((v) => v.value);
        var management_score = arraySum(scores);
        management_score = management_score > 1 ? 1 : management_score;
        return management_score;
    };

    getHPI13 = (questionnaire, type) => {
        return constants.hpi_forest_configuration_scores.filter(
            (v) => v.id === questionnaire[type + "_predominant_configuration"],
        )[0]["value"];
    };

    getHPI14 = (questionnaire, type) => {
        var scores = constants.hpi_forest_management_scores
            .filter((v) => questionnaire[type + "_management"].split(",").indexOf(v.id) >= 0)
            .map((v) => v.value);
        return arraySum(scores) > 1 ? 1 : arraySum(scores);
    };

    getHPI15 = (questionnaire, type) => {
        return constants.hpi_grassland_configuration_scores.filter(
            (v) => v.id === questionnaire[type + "_configuration"],
        )[0]["value"];
    };

    getHPI16 = (questionnaire, type) => {
        const intensity = constants.hpi_grazing_intensity_scores.filter(
            (g) => g.id === questionnaire[type + "_grazing_intensity"],
        )[0]["value"];
        const conservation = constants.hpi_grazing_conservation_scores.filter(
            (g) => g.id === questionnaire[type + "_grazing_conservation"],
        )[0]["value"];
        var grazing_score = intensity + conservation;
        grazing_score = grazing_score > 1 ? 1 : grazing_score;
        return grazing_score;
    };

    getHPI17 = (questionnaire, type) => {
        var scores = constants.hpi_grassland_management_scores
            .filter((v) => questionnaire[type + "_management"].split(",").indexOf(v.id) >= 0)
            .map((v) => v.value);
        return arraySum(scores) > 1 ? 1 : arraySum(scores);
    };

    getHPI18 = (questionnaire, type) => {
        return constants.hpi_natural_wetland_regime_scores.filter(
            (v) => v.id === questionnaire[type + "_management"],
        )[0]["value"];
    };

    getHPI19 = (questionnaire, type) => {
        return constants.hpi_artificial_wetlands_water_level_management_scores.filter(
            (v) => v.id === questionnaire[type + "_management"],
        )[0]["value"];
    };

    getHPI20 = (questionnaire, type) => {
        var scores = constants.hpi_wetland_habitat_management_scores
            .filter((v) => questionnaire[type + "_habitat_management"].split(",").indexOf(v.id) >= 0)
            .map((v) => v.value);
        return arraySum(scores) > 1 ? 1 : arraySum(scores);
    };

    getHPI21 = (questionnaire, type) => {
        return constants.hpi_surfacewaters_riparian_buffer_scores.filter(
            (b) => b.id === questionnaire[type + "_riparian"],
        )[0]["value"];
    };

    getHPI22 = (questionnaire, type) => {
        return constants.hpi_surfacewaters_riparian_vegetation_scores.filter(
            (b) => b.id === questionnaire[type + "_riparian_vegetation"],
        )[0]["value"];
    };

    getHPI23 = (questionnaire, type) => {
        var undisturbed = parseFloat(questionnaire[type + "_riparian_undisturbed"]);
        var limited = parseFloat(questionnaire[type + "_riparian_limited_cattle_access"]);
        var unlimited = parseFloat(questionnaire[type + "_riparian_unlimited_cattle_access"]);
        var crop = parseFloat(questionnaire[type + "_riparian_crop"]);
        var impervious = parseFloat(questionnaire[type + "_riparian_impervious"]);
        var result = (undisturbed * 1 + limited * 0.5 + unlimited * 0 + crop * 0 + impervious * 0) / 100;
        return result;
    };

    getHPI24 = (questionnaire, type) => {
        const stability = constants.hpi_surfacewaters_bank_stability_scores.filter(
            (b) => b.id === questionnaire[type + "_bank_condition"],
        )[0]["value"];

        const management = constants.hpi_surfacewaters_bank_management_scores.filter(
            (b) => b.id === questionnaire[type + "_bank_management"],
        )[0]["value"];

        var score = stability + management;
        score = score > 1 ? 1 : score;
        return score;
    };

    getHPI25 = (questionnaire, type) => {
        const channelization = constants.hpi_surfacewaters_channelization_scores.filter(
            (b) => b.id === questionnaire[type + "_modifications"],
        )[0]["value"];

        if (channelization === null) {
            return null;
        }

        const dams = constants.hpi_surfacewaters_dams_scores.filter((b) => b.id === questionnaire[type + "_dams"])[0][
            "value"
        ];

        var score = channelization + dams;
        return score;
    };

    getHPI26 = (questionnaire, type) => {
        var ids = questionnaire[type + "_instream_habitat"].split(",");
        var scores = ids.map((h) => {
            return constants.hpi_surfacewaters_instream_habitat_scores.filter((s) => s.id === h)[0]["value"];
        });
        return arraySum(scores) > 1 ? 1 : arraySum(scores);
    };

    getHPI27 = (questionnaire, type) => {
        return constants.hpi_surfacewaters_predominant_use_scores.filter(
            (b) => b.id === questionnaire[type + "_predominant_use"],
        )[0]["value"];
    };

    getHPI28 = (questionnaire, type) => {
        return constants.hpi_surfacewaters_average_depth_scores.filter(
            (b) => b.id === questionnaire[type + "_average_depth"],
        )[0]["value"];
    };

    getHPI29 = (questionnaire, type) => {
        var scores = constants.hpi_surfacewaters_management_scores
            .filter((v) => questionnaire[type + "_management"].split(",").indexOf(v.id) >= 0)
            .map((v) => v.value);

        return arraySum(scores) > 1 ? 1 : arraySum(scores);
    };

    computeBufferHPIScores = (ecoregion, pop_change) => {
        var tempObj = {};

        if (this.props.farmHpiSurvey.buffer) {
            var q = this.props.farmHpiSurvey.buffer;

            ["buffer", "edge"].forEach((type) => {
                if (q[type + "_area"]) {
                    const area = this.getHPI1(q, type);
                    const hpi_2 = this.getHPI2(q, type, ecoregion, pop_change);
                    const hpi_11 = this.getHPI11(q, type);
                    const hpi_12 = this.getHPI12(q, type);
                    const structural_score = (hpi_2 + hpi_11) / (1 + 1);
                    const management_score = hpi_12;
                    const aggregate_score = ((management_score * 2 + structural_score) / 3) * 10;
                    const eco_weight = this.getEcoweight(q, ecoregion, type, pop_change);
                    const farm_land_cover_hpi_score = area * aggregate_score * eco_weight;
                    const total_possible_land_cover_hpi_score = area * 10 * eco_weight;
                    const farm_percent_realized_hpi_score = farm_land_cover_hpi_score / (area * 10 * eco_weight);

                    setValue(tempObj, type + ".structural", structural_score);
                    setValue(tempObj, type + ".management", management_score);
                    setValue(tempObj, type + ".aggregate", aggregate_score);
                    setValue(tempObj, type + ".farm_land_cover_hpi_score", farm_land_cover_hpi_score);
                    setValue(
                        tempObj,
                        type + ".total_possible_land_cover_hpi_score",
                        total_possible_land_cover_hpi_score,
                    );
                    setValue(tempObj, type + ".farm_percent_realized_hpi_score", farm_percent_realized_hpi_score);
                } else {
                    setValue(tempObj, type, null);
                }
            });
        } else {
            tempObj = null;
        }

        return tempObj;
    };

    computeForestHPIScores = (ecoregion, pop_change) => {
        var tempObj = {};

        if (this.props.farmHpiSurvey.forest) {
            const q = this.props.farmHpiSurvey.forest;

            ["conifer", "deciduous", "evergreen", "mixed"].forEach((type) => {
                if (q[type + "_area"]) {
                    const area = this.getHPI1(q, type);
                    const hpi_2 = this.getHPI2(q, type, ecoregion, pop_change);
                    const hpi_13 = this.getHPI13(q, type);
                    const hpi_14 = this.getHPI14(q, type);
                    const management_score = hpi_14;
                    const structural_score = (hpi_2 + hpi_13) / (1 + 1);
                    const aggregate_score = ((management_score * 2 + structural_score) / 3) * 10;
                    const eco_weight = this.getEcoweight(q, ecoregion, type, pop_change);
                    const farm_land_cover_hpi_score = area * aggregate_score * eco_weight;
                    const total_possible_land_cover_hpi_score = area * 10 * eco_weight;
                    const farm_percent_realized_hpi_score = farm_land_cover_hpi_score / (area * 10 * eco_weight);

                    setValue(tempObj, type + ".structural", structural_score);
                    setValue(tempObj, type + ".management", management_score);
                    setValue(tempObj, type + ".aggregate", aggregate_score);
                    setValue(tempObj, type + ".farm_land_cover_hpi_score", farm_land_cover_hpi_score);
                    setValue(
                        tempObj,
                        type + ".total_possible_land_cover_hpi_score",
                        total_possible_land_cover_hpi_score,
                    );
                    setValue(tempObj, type + ".farm_percent_realized_hpi_score", farm_percent_realized_hpi_score);
                } else {
                    setValue(tempObj, type, null);
                }
            });
        } else {
            tempObj = null;
        }

        return tempObj;
    };

    computeGrasslandHPIScores = (ecoregion, pop_change) => {
        var tempObj = {};

        if (this.props.farmHpiSurvey.grassland) {
            const q = this.props.farmHpiSurvey.grassland;

            ["native", "nonnative"].forEach((type_1) => {
                ["grazed", "ungrazed"].forEach((type_2) => {
                    if (q[[type_1, type_2].join("_") + "_area"]) {
                        const full_type = [type_1, type_2].join("_");
                        const area = this.getHPI1(q, full_type);
                        const hpi_2 = this.getHPI2(q, full_type, ecoregion, pop_change);
                        const hpi_15 = this.getHPI15(q, full_type);
                        const hpi_16 = type_2 === "ungrazed" ? null : this.getHPI16(q, full_type);
                        const hpi_17 = this.getHPI17(q, full_type);
                        const management_score = type_2 === "ungrazed" ? hpi_17 : (hpi_16 + hpi_17) / (1 + 1);
                        const structural_score = (hpi_2 + hpi_15) / (1 + 1);
                        const aggregate_score = ((management_score * 2 + structural_score) / 3) * 10;
                        const eco_weight = this.getEcoweight(q, ecoregion, full_type, pop_change);
                        const farm_land_cover_hpi_score = area * aggregate_score * eco_weight;
                        const total_possible_land_cover_hpi_score = area * 10 * eco_weight;
                        const farm_percent_realized_hpi_score = farm_land_cover_hpi_score / (area * 10 * eco_weight);

                        setValue(tempObj, [type_1, type_2, "structural"].join("."), structural_score);
                        setValue(tempObj, [type_1, type_2, "management"].join("."), management_score);
                        setValue(tempObj, [type_1, type_2, "aggregate"].join("."), aggregate_score);
                        setValue(
                            tempObj,
                            [type_1, type_2, "farm_land_cover_hpi_score"].join("."),
                            farm_land_cover_hpi_score,
                        );
                        setValue(
                            tempObj,
                            [type_1, type_2, "total_possible_land_cover_hpi_score"].join("."),
                            total_possible_land_cover_hpi_score,
                        );
                        setValue(
                            tempObj,
                            [type_1, type_2, "farm_percent_realized_hpi_score"].join("."),
                            farm_percent_realized_hpi_score,
                        );
                    } else {
                        setValue(tempObj, [type_1, type_2].join("."), null);
                    }
                });
            });
        } else {
            tempObj = null;
        }

        return tempObj;
    };

    computeWetlandHPIScores = (ecoregion, pop_change) => {
        var tempObj = {};

        if (this.props.farmHpiSurvey.wetland) {
            var q = this.props.farmHpiSurvey.wetland;

            ["wetland_natural", "wetland_artificial"].forEach((type) => {
                if (q[type.split("_")[1] + "_area"]) {
                    const area = this.getHPI1(q, type.split("_")[1]);
                    const hpi_2 = this.getHPI2(q, type, ecoregion, pop_change);
                    const hpi_18 = type.includes("natural") ? this.getHPI18(q, "natural") : null;
                    const hpi_19 = type.includes("artificial") ? this.getHPI19(q, "artificial") : null;
                    const hpi_20 = this.getHPI20(q, type.split("_")[1]);
                    const structural_score = type.includes("natural") ? hpi_18 : hpi_2;
                    const management_score = type.includes("natural") ? hpi_20 : (hpi_19 + hpi_20) / (1 + 1);
                    const aggregate_score = ((management_score * 2 + structural_score) / 3) * 10;
                    const eco_weight = this.getEcoweight(q, ecoregion, type, pop_change);
                    const farm_land_cover_hpi_score = area * aggregate_score * eco_weight;
                    const total_possible_land_cover_hpi_score = area * 10 * eco_weight;
                    const farm_percent_realized_hpi_score = farm_land_cover_hpi_score / (area * 10 * eco_weight);

                    setValue(tempObj, type.split("_")[1] + ".structural", structural_score);
                    setValue(tempObj, type.split("_")[1] + ".management", management_score);
                    setValue(tempObj, type.split("_")[1] + ".aggregate", aggregate_score);
                    setValue(tempObj, type.split("_")[1] + ".farm_land_cover_hpi_score", farm_land_cover_hpi_score);
                    setValue(
                        tempObj,
                        type.split("_")[1] + ".total_possible_land_cover_hpi_score",
                        total_possible_land_cover_hpi_score,
                    );
                    setValue(
                        tempObj,
                        type.split("_")[1] + ".farm_percent_realized_hpi_score",
                        farm_percent_realized_hpi_score,
                    );
                } else {
                    setValue(tempObj, type.split("_")[1], null);
                }
            });
        } else {
            tempObj = null;
        }

        return tempObj;
    };

    computeSurfaceWaterHPIScores = (ecoregion, pop_change) => {
        var tempObj = {};

        if (this.props.farmHpiSurvey.surfacewater) {
            var q = this.props.farmHpiSurvey.surfacewater;

            ["pond_natural", "pond_artificial", "lake_natural", "lake_artificial", "stream", "river"].forEach(
                (type) => {
                    if (q[type + "_area"] || q[type + "_length"]) {
                        var area = this.getHPI1(q, type);

                        if (type.includes("pond") || type.includes("lake")) {
                            const hpi_21 = this.getHPI21(q, type);
                            const hpi_22 = this.getHPI22(q, type);
                            const hpi_23 = this.getHPI23(q, type);
                            const hpi_24 = this.getHPI24(q, type);
                            const hpi_27 = this.getHPI27(q, type);
                            const hpi_28 = this.getHPI28(q, type);
                            const hpi_29 = this.getHPI29(q, type);

                            const structural_score =
                                (hpi_21 * 0.5 + hpi_22 * 0.5 + hpi_24 + hpi_28) / (0 + 1 * 0.5 + 1 * 0.5 + 1 + 1);
                            const management_score = (hpi_23 * 0.5 + hpi_27 + hpi_29) / (1 * 0.5 + 1 + 1);
                            const aggregate_score = ((management_score * 2 + structural_score) / 3) * 10;
                            const eco_weight = this.getEcoweight(q, ecoregion, type, pop_change);
                            const farm_land_cover_hpi_score = area * aggregate_score * eco_weight;
                            const total_possible_land_cover_hpi_score = area * 10 * eco_weight;
                            const farm_percent_realized_hpi_score =
                                farm_land_cover_hpi_score / (area * 10 * eco_weight);

                            setValue(tempObj, type.split("_").join(".") + ".structural", structural_score);
                            setValue(tempObj, type.split("_").join(".") + ".management", management_score);
                            setValue(tempObj, type.split("_").join(".") + ".aggregate", aggregate_score);
                            setValue(
                                tempObj,
                                type.split("_").join(".") + ".farm_land_cover_hpi_score",
                                farm_land_cover_hpi_score,
                            );
                            setValue(
                                tempObj,
                                type.split("_").join(".") + ".total_possible_land_cover_hpi_score",
                                total_possible_land_cover_hpi_score,
                            );
                            setValue(
                                tempObj,
                                type.split("_").join(".") + ".farm_percent_realized_hpi_score",
                                farm_percent_realized_hpi_score,
                            );
                        } else if (type === "stream" || type === "river") {
                            area = area * 0.0189394 * 640;

                            const hpi_21 = this.getHPI21(q, type);
                            const hpi_22 = this.getHPI22(q, type);
                            const hpi_23 = this.getHPI23(q, type);
                            const hpi_24 = this.getHPI24(q, type);
                            const hpi_25 = this.getHPI25(q, type);
                            const hpi_26 = this.getHPI26(q, type);

                            const structural_score =
                                hpi_25 === null
                                    ? (hpi_21 + hpi_22 + hpi_24) / (1 + 1 + 1)
                                    : (hpi_21 + hpi_22 + hpi_24 + hpi_25) / (1 + 1 + 1 + 1);
                            const management_score = (hpi_23 + hpi_26) / (1 + 1);
                            const aggregate_score = ((management_score * 2 + structural_score) / 3) * 10;
                            const eco_weight = this.getEcoweight(q, ecoregion, type, pop_change);
                            const farm_land_cover_hpi_score = area * aggregate_score * eco_weight;
                            const total_possible_land_cover_hpi_score = area * 10 * eco_weight;
                            const farm_percent_realized_hpi_score =
                                farm_land_cover_hpi_score / (area * 10 * eco_weight);

                            setValue(tempObj, type.split("_").join(".") + ".structural", structural_score);
                            setValue(tempObj, type.split("_").join(".") + ".management", management_score);
                            setValue(tempObj, type.split("_").join(".") + ".aggregate", aggregate_score);
                            setValue(
                                tempObj,
                                type.split("_").join(".") + ".farm_land_cover_hpi_score",
                                farm_land_cover_hpi_score,
                            );
                            setValue(
                                tempObj,
                                type.split("_").join(".") + ".total_possible_land_cover_hpi_score",
                                total_possible_land_cover_hpi_score,
                            );
                            setValue(
                                tempObj,
                                type.split("_").join(".") + ".farm_percent_realized_hpi_score",
                                farm_percent_realized_hpi_score,
                            );
                        }
                    } else {
                        setValue(tempObj, type.split("_").join("."), null);
                    }
                },
            );
        } else {
            tempObj = null;
        }

        return tempObj;
    };

    computeCultivatedLandHPIScores = (ecoregion, pop_change) => {
        var crop_scores = [];

        this.props.farmHpiSurvey.cultivated.forEach((questionnaire) => {
            var structural_score = null;
            var management_score = null;
            var aggregate_score = null;
            var land_cover_score = null;
            var possible_land_cover_score = null;
            var percent_realized_hpi = null;

            var hpi_2 = null;
            var hpi_3 = null;
            var hpi_4 = null;
            var hpi_5 = null;
            var hpi_6 = null;
            var hpi_7 = null;
            var hpi_8 = null;
            var hpi_9 = null;
            var hpi_10 = null;

            var area = parseFloat(questionnaire["plantable_area"]);

            if (questionnaire.crop_id === "8") {
                //  alfalfa
                hpi_2 = this.getHPI2(questionnaire, "plantable", ecoregion, pop_change);
                hpi_4 = this.getHPI4(questionnaire);
                hpi_6 = this.getHPI6(questionnaire);
                hpi_7 = this.getHPI7(questionnaire);
                hpi_10 = this.getHPI10(questionnaire);

                structural_score = hpi_2;
                management_score =
                    (hpi_4 * 31 + hpi_6 * 19 + hpi_7 * 31 + hpi_10 * 87) / (1 * 31 + 1 * 19 + 1 * 31 + 1 * 87);
                aggregate_score = ((management_score * 2 + structural_score) / 3) * 10;
            } else if (questionnaire.crop_id === "4") {
                //  cotton
                hpi_2 = this.getHPI2(questionnaire, "plantable", ecoregion, pop_change);
                hpi_3 = this.getHPI3(questionnaire);
                hpi_4 = this.getHPI4(questionnaire);
                hpi_5 = this.getHPI5(questionnaire);
                hpi_6 = this.getHPI6(questionnaire);
                hpi_7 = this.getHPI7(questionnaire);
                hpi_8 = this.getHPI8(questionnaire);
                hpi_10 = this.getHPI10(questionnaire);

                structural_score = hpi_2;
                management_score =
                    (hpi_3 * 35 + hpi_4 * 31 + hpi_5 * 42 + hpi_6 * 19 + hpi_7 * 31 + hpi_8 * 44 + hpi_9 * 87) /
                    (1 * 35 + 1 * 31 + 1 * 42 + 1 * 19 + 1 * 31 + 1 * 44 + 1 * 87);
                aggregate_score = ((management_score * 2 + structural_score) / 3) * 10;
            } else if (questionnaire.crop_id === "39") {
                //  rice
                hpi_2 = this.getHPI2(questionnaire, "plantable", ecoregion, pop_change, pop_change);
                hpi_3 = this.getHPI3(questionnaire);
                hpi_4 = this.getHPI4(questionnaire);
                hpi_5 = this.getHPI5(questionnaire);
                hpi_6 = this.getHPI6(questionnaire);
                hpi_7 = this.getHPI7(questionnaire);
                hpi_8 = this.getHPI8(questionnaire);
                hpi_9 = this.getHPI9(questionnaire);
                hpi_10 = this.getHPI10(questionnaire);

                var wildlife_weight = questionnaire["wildlife_habitat"] === "3" ? 174 : 87;

                structural_score = hpi_2;
                management_score =
                    (hpi_3 * 35 +
                        hpi_4 * 31 +
                        hpi_5 * 42 +
                        hpi_6 * 19 +
                        hpi_7 * 31 +
                        hpi_8 * 44 +
                        hpi_9 * 26 +
                        hpi_10 * wildlife_weight) /
                    (1 * 35 + 1 * 31 + 1 * 42 + 1 * 19 + 1 * 31 + 1 * 44 + 1 * 26 + 1 * wildlife_weight);
                aggregate_score = ((management_score * 2 + structural_score) / 3) * 10;
            } else {
                hpi_2 = this.getHPI2(questionnaire, "plantable", ecoregion, pop_change);
                hpi_3 = this.getHPI3(questionnaire);
                hpi_4 = this.getHPI4(questionnaire);
                hpi_5 = this.getHPI5(questionnaire);
                hpi_6 = this.getHPI6(questionnaire);
                hpi_7 = this.getHPI7(questionnaire);
                hpi_8 = this.getHPI8(questionnaire);
                hpi_9 = this.getHPI9(questionnaire);
                hpi_10 = this.getHPI10(questionnaire);

                structural_score = hpi_2;
                management_score =
                    (hpi_3 * 35 +
                        hpi_4 * 31 +
                        hpi_5 * 42 +
                        hpi_6 * 19 +
                        hpi_7 * 31 +
                        hpi_8 * 44 +
                        hpi_9 * 26 +
                        hpi_10 * 87) /
                    (1 * 35 + 1 * 31 + 1 * 42 + 1 * 19 + 1 * 31 + 1 * 44 + 1 * 26 + 1 * 87);
                aggregate_score = ((management_score * 2 + structural_score) / 3) * 10;
            }

            if (questionnaire.crop_id === "39") {
                const eco_weight = this.getEcoweight(questionnaire, ecoregion, "wetland_artificial", pop_change);
                land_cover_score = area * aggregate_score * eco_weight;
                possible_land_cover_score = area * 10 * eco_weight;
                percent_realized_hpi = land_cover_score / (area * 10 * eco_weight);
            } else {
                land_cover_score = area * aggregate_score * 1;
                possible_land_cover_score = area * 10 * 1;
                percent_realized_hpi = land_cover_score / (area * 10 * 1);
            }

            const obj = {
                crop_id: questionnaire["crop_id"],
                structural: structural_score,
                management: management_score,
                aggregate: aggregate_score,
                farm_land_cover_hpi_score: land_cover_score,
                total_possible_land_cover_hpi_score: possible_land_cover_score,
                farm_percent_realized_hpi: percent_realized_hpi,
            };

            crop_scores.push(obj);
        });

        return crop_scores;
    };

    computeTotalScores = (scores, ecoregion) => {
        var tempObj = {};

        if (scores.cultivated.length > 0) {
            //  cultivated
            const cultivated_farm_land_cover_hpi_scores = scores.cultivated.map((s) => s.farm_land_cover_hpi_score);
            const cultivated_total_possible_land_cover_hpi_scores = scores.cultivated.map(
                (s) => s.total_possible_land_cover_hpi_score,
            );

            const cultivated_farm_land_cover_hpi_score_sum = arraySum(cultivated_farm_land_cover_hpi_scores);
            const cultivated_total_possible_land_cover_hpi_score_sum = arraySum(
                cultivated_total_possible_land_cover_hpi_scores,
            );
            const cultivated_farm_percent_realized_hpi =
                cultivated_farm_land_cover_hpi_score_sum / cultivated_total_possible_land_cover_hpi_score_sum;
            const cultivated_farm_realized_hpi_average = arrayAvg(
                scores.cultivated.map((s) => s.farm_percent_realized_hpi),
            );

            setValue(tempObj, "cultivated.farm_land_cover_hpi_score", cultivated_farm_land_cover_hpi_score_sum);
            setValue(
                tempObj,
                "cultivated.total_possible_land_cover_hpi_score",
                cultivated_total_possible_land_cover_hpi_score_sum,
            );
            setValue(tempObj, "cultivated.farm_percent_realized_hpi", cultivated_farm_percent_realized_hpi);
            setValue(tempObj, "cultivated.farm_percent_realized_hpi_average", cultivated_farm_realized_hpi_average);
        } else {
            tempObj["cultivated"] = null;
        }

        var forest_total_farm_percent_realized_hpi_scores = [];
        var grassland_total_farm_percent_realized_hpi_scores = [];
        var wetland_total_farm_percent_realized_hpi_scores = [];
        var surfacewater_total_farm_percent_realized_hpi_scores = [];
        var buffer_total_farm_percent_realized_hpi_scores = [];

        //  forests
        if (scores["noncultivated"]["forest"]) {
            const forest_scores = scores["noncultivated"]["forest"];
            var forest_total_farm_land_cover_hpi_score_sum = 0;
            var forest_total_total_possible_land_cover_hpi_score_sum = 0;
            ["conifer", "deciduous", "evergreen", "mixed"].forEach((type) => {
                if (forest_scores[type]) {
                    forest_total_farm_land_cover_hpi_score_sum += forest_scores[type]["farm_land_cover_hpi_score"];
                    forest_total_total_possible_land_cover_hpi_score_sum +=
                        forest_scores[type]["total_possible_land_cover_hpi_score"];
                    forest_total_farm_percent_realized_hpi_scores.push(
                        forest_scores[type]["farm_percent_realized_hpi_score"],
                    );
                }
            });
            const forest_total_farm_percent_realized_hpi =
                forest_total_farm_land_cover_hpi_score_sum / forest_total_total_possible_land_cover_hpi_score_sum;
            const forest_total_farm_realized_hpi_average = arrayAvg(forest_total_farm_percent_realized_hpi_scores);

            setValue(
                tempObj,
                "noncultivated.forest.farm_land_cover_hpi_score",
                forest_total_farm_land_cover_hpi_score_sum,
            );
            setValue(
                tempObj,
                "noncultivated.forest.total_possible_land_cover_hpi_score",
                forest_total_total_possible_land_cover_hpi_score_sum,
            );
            setValue(tempObj, "noncultivated.forest.farm_percent_realized_hpi", forest_total_farm_percent_realized_hpi);
            setValue(
                tempObj,
                "noncultivated.forest.farm_percent_realized_hpi_average",
                forest_total_farm_realized_hpi_average,
            );
        } else {
            setValue(tempObj, "noncultivated.forest", null);
        }

        //  grasslands
        if (scores["noncultivated"]["grassland"]) {
            const grassland_scores = scores["noncultivated"]["grassland"];
            var grassland_total_farm_land_cover_hpi_score_sum = 0;
            var grassland_total_total_possible_land_cover_hpi_score_sum = 0;
            ["native", "nonnative"].forEach((type_1) => {
                ["grazed", "ungrazed"].forEach((type_2) => {
                    if (grassland_scores[type_1][type_2]) {
                        grassland_total_farm_land_cover_hpi_score_sum +=
                            grassland_scores[type_1][type_2]["farm_land_cover_hpi_score"];
                        grassland_total_total_possible_land_cover_hpi_score_sum +=
                            grassland_scores[type_1][type_2]["total_possible_land_cover_hpi_score"];
                        grassland_total_farm_percent_realized_hpi_scores.push(
                            grassland_scores[type_1][type_2]["farm_percent_realized_hpi_score"],
                        );
                    }
                });
            });
            const grassland_total_farm_percent_realized_hpi =
                grassland_total_farm_land_cover_hpi_score_sum / grassland_total_total_possible_land_cover_hpi_score_sum;
            const grassland_total_farm_realized_hpi_average = arrayAvg(
                grassland_total_farm_percent_realized_hpi_scores,
            );

            setValue(
                tempObj,
                "noncultivated.grassland.farm_land_cover_hpi_score",
                grassland_total_farm_land_cover_hpi_score_sum,
            );
            setValue(
                tempObj,
                "noncultivated.grassland.total_possible_land_cover_hpi_score",
                grassland_total_total_possible_land_cover_hpi_score_sum,
            );
            setValue(
                tempObj,
                "noncultivated.grassland.farm_percent_realized_hpi",
                grassland_total_farm_percent_realized_hpi,
            );
            setValue(
                tempObj,
                "noncultivated.grassland.farm_percent_realized_hpi_average",
                grassland_total_farm_realized_hpi_average,
            );
        } else {
            setValue(tempObj, "noncultivated.grassland", null);
        }

        //  wetlands
        if (scores["noncultivated"]["wetland"]) {
            const wetland_scores = scores["noncultivated"]["wetland"];
            var wetland_total_farm_land_cover_hpi_score_sum = 0;
            var wetland_total_total_possible_land_cover_hpi_score_sum = 0;
            ["natural", "artificial"].forEach((type) => {
                if (wetland_scores[type]) {
                    wetland_total_farm_land_cover_hpi_score_sum += wetland_scores[type]["farm_land_cover_hpi_score"];
                    wetland_total_total_possible_land_cover_hpi_score_sum +=
                        wetland_scores[type]["total_possible_land_cover_hpi_score"];
                    wetland_total_farm_percent_realized_hpi_scores.push(
                        wetland_scores[type]["farm_percent_realized_hpi_score"],
                    );
                }
            });
            const wetland_total_farm_percent_realized_hpi =
                wetland_total_farm_land_cover_hpi_score_sum / wetland_total_total_possible_land_cover_hpi_score_sum;
            const wetland_total_farm_realized_hpi_average = arrayAvg(wetland_total_farm_percent_realized_hpi_scores);

            setValue(
                tempObj,
                "noncultivated.wetland.farm_land_cover_hpi_score",
                wetland_total_farm_land_cover_hpi_score_sum,
            );
            setValue(
                tempObj,
                "noncultivated.wetland.total_possible_land_cover_hpi_score",
                wetland_total_total_possible_land_cover_hpi_score_sum,
            );
            setValue(
                tempObj,
                "noncultivated.wetland.farm_percent_realized_hpi",
                wetland_total_farm_percent_realized_hpi,
            );
            setValue(
                tempObj,
                "noncultivated.wetland.farm_percent_realized_hpi_average",
                wetland_total_farm_realized_hpi_average,
            );
        } else {
            setValue(tempObj, "noncultivated.wetland", null);
        }

        //  surface waters
        if (scores["noncultivated"]["surfacewater"]) {
            const surfacewater_scores = scores["noncultivated"]["surfacewater"];
            var surfacewater_total_farm_land_cover_hpi_score_sum = 0;
            var surfacewater_total_total_possible_land_cover_hpi_score_sum = 0;

            ["pond", "lake"].forEach((type_1) => {
                ["natural", "artificial"].forEach((type_2) => {
                    if (surfacewater_scores[type_1][type_2]) {
                        surfacewater_total_farm_land_cover_hpi_score_sum +=
                            surfacewater_scores[type_1][type_2]["farm_land_cover_hpi_score"];
                        surfacewater_total_total_possible_land_cover_hpi_score_sum +=
                            surfacewater_scores[type_1][type_2]["total_possible_land_cover_hpi_score"];
                        surfacewater_total_farm_percent_realized_hpi_scores.push(
                            surfacewater_scores[type_1][type_2]["farm_percent_realized_hpi_score"],
                        );
                    }
                });
            });

            ["river", "stream"].forEach((type) => {
                if (surfacewater_scores[type]) {
                    surfacewater_total_farm_land_cover_hpi_score_sum +=
                        surfacewater_scores[type]["farm_land_cover_hpi_score"];
                    surfacewater_total_total_possible_land_cover_hpi_score_sum +=
                        surfacewater_scores[type]["total_possible_land_cover_hpi_score"];
                    surfacewater_total_farm_percent_realized_hpi_scores.push(
                        surfacewater_scores[type]["farm_percent_realized_hpi_score"],
                    );
                }
            });

            const surfacewater_total_farm_percent_realized_hpi =
                surfacewater_total_farm_land_cover_hpi_score_sum /
                surfacewater_total_total_possible_land_cover_hpi_score_sum;
            const surfacewater_total_farm_realized_hpi_average = arrayAvg(
                surfacewater_total_farm_percent_realized_hpi_scores,
            );

            setValue(
                tempObj,
                "noncultivated.surfacewater.farm_land_cover_hpi_score",
                surfacewater_total_farm_land_cover_hpi_score_sum,
            );
            setValue(
                tempObj,
                "noncultivated.surfacewater.total_possible_land_cover_hpi_score",
                surfacewater_total_total_possible_land_cover_hpi_score_sum,
            );
            setValue(
                tempObj,
                "noncultivated.surfacewater.farm_percent_realized_hpi",
                surfacewater_total_farm_percent_realized_hpi,
            );
            setValue(
                tempObj,
                "noncultivated.surfacewater.farm_percent_realized_hpi_average",
                surfacewater_total_farm_realized_hpi_average,
            );
        } else {
            setValue(tempObj, "noncultivated.surfacewater", null);
        }

        //  buffer
        if (scores["noncultivated"]["buffer"]) {
            const buffer_scores = scores["noncultivated"]["buffer"];
            var buffer_total_farm_land_cover_hpi_score_sum = 0;
            var buffer_total_total_possible_land_cover_hpi_score_sum = 0;

            ["edge", "riparian"].forEach((type) => {
                if (buffer_scores[type]) {
                    buffer_total_farm_land_cover_hpi_score_sum += buffer_scores[type]["farm_land_cover_hpi_score"];
                    buffer_total_total_possible_land_cover_hpi_score_sum +=
                        buffer_scores[type]["total_possible_land_cover_hpi_score"];
                    buffer_total_farm_percent_realized_hpi_scores.push(
                        buffer_scores[type]["farm_percent_realized_hpi_score"],
                    );
                }
            });
            const buffer_total_farm_percent_realized_hpi =
                buffer_total_farm_land_cover_hpi_score_sum / buffer_total_total_possible_land_cover_hpi_score_sum;
            const buffer_total_farm_realized_hpi_average = arrayAvg(buffer_total_farm_percent_realized_hpi_scores);

            setValue(
                tempObj,
                "noncultivated.buffer.farm_land_cover_hpi_score",
                buffer_total_farm_land_cover_hpi_score_sum,
            );
            setValue(
                tempObj,
                "noncultivated.buffer.total_possible_land_cover_hpi_score",
                buffer_total_total_possible_land_cover_hpi_score_sum,
            );
            setValue(tempObj, "noncultivated.buffer.farm_percent_realized_hpi", buffer_total_farm_percent_realized_hpi);
            setValue(
                tempObj,
                "noncultivated.buffer.farm_percent_realized_hpi_average",
                buffer_total_farm_realized_hpi_average,
            );
        } else {
            setValue(tempObj, "noncultivated.buffer", null);
        }

        //  non-cultivated land total
        if (
            scores["noncultivated"]["forest"] ||
            scores["noncultivated"]["grassland"] ||
            scores["noncultivated"]["wetland"] ||
            scores["noncultivated"]["surfacewater"] ||
            scores["noncultivated"]["buffer"]
        ) {
            var noncultivated_farm_land_cover_hpi_score_sum = 0;
            var noncultivated_total_possible_land_cover_hpi_score_sum = 0;
            ["forest", "grassland", "wetland", "surfacewater", "buffer"].forEach((type) => {
                if (scores["noncultivated"][type]) {
                    noncultivated_farm_land_cover_hpi_score_sum +=
                        tempObj["noncultivated"][type]["farm_land_cover_hpi_score"];
                    noncultivated_total_possible_land_cover_hpi_score_sum +=
                        tempObj["noncultivated"][type]["total_possible_land_cover_hpi_score"];
                }
            });

            const noncultivated_farm_percent_realized_hpi =
                noncultivated_farm_land_cover_hpi_score_sum / noncultivated_total_possible_land_cover_hpi_score_sum;

            const farm_percent_realized_hpi_scores = [
                ...forest_total_farm_percent_realized_hpi_scores,
                ...grassland_total_farm_percent_realized_hpi_scores,
                ...wetland_total_farm_percent_realized_hpi_scores,
                ...buffer_total_farm_percent_realized_hpi_scores,
                ...surfacewater_total_farm_percent_realized_hpi_scores,
            ];

            const farm_percent_realized_hpi_average = arrayAvg(farm_percent_realized_hpi_scores);

            setValue(
                tempObj,
                "noncultivated.farm_land_cover_hpi_score_sum",
                noncultivated_farm_land_cover_hpi_score_sum,
            );
            setValue(
                tempObj,
                "noncultivated.total_possible_land_cover_hpi_score_sum",
                noncultivated_total_possible_land_cover_hpi_score_sum,
            );
            setValue(tempObj, "noncultivated.farm_percent_realized_hpi", noncultivated_farm_percent_realized_hpi);
            setValue(tempObj, "noncultivated.farm_percent_realized_hpi_average", farm_percent_realized_hpi_average);
        } else {
            tempObj["noncultivated"] = null;
        }

        return tempObj;
    };

    async computeFarmHpiScore() {
        const ecoregion = await this.getEcoregion();

        const pop_change =
            constants.pop_change.filter(
                (p) => p.state_name === ecoregion.state.name && p.county_name === ecoregion.county.name,
            )[0] === undefined
                ? 0
                : 1;

        //  buffer
        const buffer_scores = this.computeBufferHPIScores(ecoregion, pop_change);

        //  forest
        const forest_scores = this.computeForestHPIScores(ecoregion, pop_change);

        //  grassland
        const grassland_scores = this.computeGrasslandHPIScores(ecoregion, pop_change);

        //  wetlands
        const wetland_scores = this.computeWetlandHPIScores(ecoregion, pop_change);

        //  surface waters
        const surfacewater_scores = this.computeSurfaceWaterHPIScores(ecoregion, pop_change);

        //  cultivated lands
        const cultivated_scores = this.computeCultivatedLandHPIScores(ecoregion, pop_change);

        var results = {
            noncultivated: {
                buffer: buffer_scores,
                forest: forest_scores,
                grassland: grassland_scores,
                wetland: wetland_scores,
                surfacewater: surfacewater_scores,
            },
            cultivated: cultivated_scores,
        };

        const farm_scores = this.computeTotalScores(results, ecoregion);
        results["farm"] = farm_scores;

        //  farm aggregate score
        let a = getValue(results, "farm.cultivated.farm_land_cover_hpi_score")
            ? getValue(results, "farm.cultivated.farm_land_cover_hpi_score")
            : 0;
        let b = getValue(results, "farm.noncultivated.farm_land_cover_hpi_score_sum")
            ? getValue(results, "farm.noncultivated.farm_land_cover_hpi_score_sum")
            : 0;
        let c = getValue(results, "farm.cultivated.total_possible_land_cover_hpi_score")
            ? getValue(results, "farm.cultivated.total_possible_land_cover_hpi_score")
            : 0;
        let d = getValue(results, "farm.noncultivated.total_possible_land_cover_hpi_score_sum")
            ? getValue(results, "farm.noncultivated.total_possible_land_cover_hpi_score_sum")
            : 0;
        let farm_aggregate_score = (a + b) / (c + d);

        setValue(results, "farm.aggregate_score", farm_aggregate_score);
        setValue(results, "ecoregion", ecoregion);

        const { ormFarmHpiSurveyUpdate } = this.props;

        let promises = [];

        promises.push(
            ormFarmHpiSurveyUpdate({
                id: this.props.farmHpiSurvey.id,
                results: results,
            }),
        );

        Promise.all(promises).then((result) => {
            this.props.history.push(`/farm/hpiresults/${this.props.farmHpiSurvey.id}`);
        });
    }

    componentWillMount() {
        let missing = this.getMissingData();
        const helpOpen = Object.keys(missing).length > 0;

        this.setState({
            missing: missing,
            helpOpen: helpOpen,
        });
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const { classes, farmHpiSurvey, handleStep } = this.props;
        const { missing, helpOpen, helpOpen2 } = this.state;
        const isFinal = farmHpiSurvey.is_final;

        const cropColumnData = [
            { id: "name", numeric: false, disablePadding: false, label: "Crop", allowSort: false },
            { id: "area", numeric: false, disablePadding: false, label: "Acres", allowSort: false },
        ];

        const landTypeColumnData = [
            { id: "name", numeric: false, disablePadding: false, label: "Land Type", allowSort: false },
            { id: "area", numeric: false, disablePadding: false, label: "Acres", allowSort: false },
        ];

        const cultivated_data = Object.keys(missing).length === 0 ? this.getCultivatedLandSurveyData() : [];
        const noncultivated_data = Object.keys(missing).length === 0 ? this.getNoncultivatedLandSurveyData() : [];

        return (
            <Grid container spacing={16}>
                <WarningDialog
                    noActions
                    open={helpOpen}
                    onClose={() => this.setState({ helpOpen: false })}
                    title="Missing Data Required for Submission"
                    text={
                        <div>
                            <Typography gutterBottom>We're missing some data in the following sections:</Typography>

                            {missing["cultivated"] === true && (
                                <Button
                                    color="primary"
                                    fullWidth
                                    classes={{ label: classes.inline }}
                                    onClick={() => handleStep(1, false)}
                                >
                                    Cultivated Land &gt;
                                </Button>
                            )}

                            {missing["forest"] === true && (
                                <Button
                                    color="primary"
                                    fullWidth
                                    classes={{ label: classes.inline }}
                                    onClick={() => handleStep(1, false)}
                                >
                                    Forests &gt;
                                </Button>
                            )}

                            {missing["grassland"] === true && (
                                <Button
                                    color="primary"
                                    fullWidth
                                    classes={{ label: classes.inline }}
                                    onClick={() => handleStep(1, false)}
                                >
                                    Grasslands &gt;
                                </Button>
                            )}

                            {missing["buffer"] === true && (
                                <Button
                                    color="primary"
                                    fullWidth
                                    classes={{ label: classes.inline }}
                                    onClick={() => handleStep(1, false)}
                                >
                                    Edge Features/Riparian Buffer &gt;
                                </Button>
                            )}

                            {missing["surface_water"] === true && (
                                <Button
                                    color="primary"
                                    fullWidth
                                    classes={{ label: classes.inline }}
                                    onClick={() => handleStep(1, false)}
                                >
                                    Surface Waters &gt;
                                </Button>
                            )}

                            {missing["wetland"] === true && (
                                <Button
                                    color="primary"
                                    fullWidth
                                    classes={{ label: classes.inline }}
                                    onClick={() => handleStep(1, false)}
                                >
                                    Wetlands &gt;
                                </Button>
                            )}

                            <Typography gutterBottom className={classes.gutterTop}>
                                Please review these sections and supply any missing information. Fields that are missing
                                data are marked with a red outline.
                            </Typography>
                            <Typography>
                                If you need any assistance, please email us at{" "}
                                <a className={classes.linkColor} href="mailto:support@fieldtomarket.org">
                                    support@fieldtomarket.org
                                </a>
                            </Typography>
                        </div>
                    }
                />
                {Object.keys(missing).length > 0 && (
                    <>
                        <WarningDialog
                            noActions
                            open={helpOpen2}
                            onClose={() => this.setState({ helpOpen2: false })}
                            title="Future Crop Year"
                            text="You cannot finalize a future crop year."
                        />
                        <Grid item xs={8}>
                            <Typography gutterBottom variant="display3">
                                Review Missing Data
                            </Typography>
                            <Typography gutterBottom>
                                All required inputs must be entered before the Fieldprint Analysis can be generated.
                                Please review the sections below to identify the missing data inputs.
                            </Typography>
                            {missing["cultivated"] === true && (
                                <ListItem
                                    title="Forests"
                                    button
                                    className={classes.listItem}
                                    onClick={() => handleStep(1, false)}
                                >
                                    <ListItemIcon>
                                        <StepIcon icon="2" />
                                    </ListItemIcon>
                                    <ListItemText inset disableTypography>
                                        <Typography variant="title">
                                            Cultivated Land <i>(Incomplete)</i>
                                        </Typography>
                                    </ListItemText>
                                    <ChevronRightIcon />
                                </ListItem>
                            )}
                            {missing["forest"] === true && (
                                <ListItem
                                    title="Forests"
                                    button
                                    className={classes.listItem}
                                    onClick={() => handleStep(1, false)}
                                >
                                    <ListItemIcon>
                                        <StepIcon icon="2" />
                                    </ListItemIcon>
                                    <ListItemText inset disableTypography>
                                        <Typography variant="title">
                                            Forests <i>(Incomplete)</i>
                                        </Typography>
                                    </ListItemText>
                                    <ChevronRightIcon />
                                </ListItem>
                            )}
                            {missing["grassland"] === true && (
                                <ListItem
                                    title="Grasslands"
                                    button
                                    className={classes.listItem}
                                    onClick={() => handleStep(1, false)}
                                >
                                    <ListItemIcon>
                                        <StepIcon icon="2" />
                                    </ListItemIcon>
                                    <ListItemText inset disableTypography>
                                        <Typography variant="title">
                                            Grasslands <i>(Incomplete)</i>
                                        </Typography>
                                    </ListItemText>
                                    <ChevronRightIcon />
                                </ListItem>
                            )}
                            {missing["buffer"] === true && (
                                <ListItem
                                    title="buffer"
                                    button
                                    className={classes.listItem}
                                    onClick={() => handleStep(1, false)}
                                >
                                    <ListItemIcon>
                                        <StepIcon icon="2" />
                                    </ListItemIcon>
                                    <ListItemText inset disableTypography>
                                        <Typography variant="title">
                                            Riparian <i>(Incomplete)</i>
                                        </Typography>
                                    </ListItemText>
                                    <ChevronRightIcon />
                                </ListItem>
                            )}
                            {missing["surface_water"] === true && (
                                <ListItem
                                    title="Surface Waters"
                                    button
                                    className={classes.listItem}
                                    onClick={() => handleStep(1, false)}
                                >
                                    <ListItemIcon>
                                        <StepIcon icon="2" />
                                    </ListItemIcon>
                                    <ListItemText inset disableTypography>
                                        <Typography variant="title">
                                            Surface Waters <i>(Incomplete)</i>
                                        </Typography>
                                    </ListItemText>
                                    <ChevronRightIcon />
                                </ListItem>
                            )}
                            {missing["wetland"] === true && (
                                <ListItem
                                    title="Wetlands"
                                    button
                                    className={classes.listItem}
                                    onClick={() => handleStep(1, false)}
                                >
                                    <ListItemIcon>
                                        <StepIcon icon="2" />
                                    </ListItemIcon>
                                    <ListItemText inset disableTypography>
                                        <Typography variant="title">
                                            Wetlands <i>(Incomplete)</i>
                                        </Typography>
                                    </ListItemText>
                                    <ChevronRightIcon />
                                </ListItem>
                            )}

                            <Typography gutterBottom variant="display3" className={classes.gutterTop}>
                                Next Steps
                            </Typography>
                            <Typography variant="title">Return to Farm homepage</Typography>
                            <Typography gutterBottom>
                                Return to Farm homepage to manage other information.
                                <Button
                                    color="primary"
                                    component={Link}
                                    to={"/farm/" + farmHpiSurvey.farm.id + "/edit"}
                                >
                                    Go Now &gt;
                                </Button>
                            </Typography>
                        </Grid>

                        <Grid item xs={4}>
                            <Typography gutterBottom variant="display3">
                                Help
                            </Typography>
                            <Typography gutterBottom>
                                <b>What if I am not ready to complete all data required data inputs? </b>
                                <br />
                                You can return to the data input pages for this crop year when you have the required
                                data inputs. Not that you cannot generate a Fieldprint Analysis until all required
                                inputs are entered.
                            </Typography>
                            <Typography gutterBottom>
                                <b>What if I don’t have specific required data inputs?</b>
                                <br />
                                It is important to provide accurate information needed to generate your Fieldprint
                                Analysis. If you do not have specific information, please &nbsp;
                                <a className={classes.linkColor} href="mailto:support@fieldtomarket.org">
                                    contact us
                                </a>
                                &nbsp; for assistance.
                            </Typography>
                        </Grid>
                    </>
                )}

                {Object.keys(missing).length === 0 && (
                    <Grid item xs={12}>
                        <Typography gutterBottom variant="display3">
                            Review and Calculate
                        </Typography>
                        <Typography gutterBottom>
                            <b>1. Review Acres by Land Type</b>
                            <br />
                            The table presents a summary of the acres by land type you have entered in for this Farm in
                            the survey year. Please review the acres to ensure they are accurate. If they need to be
                            adjusted go back to the questionaire step in the navigation above.
                            <br />
                        </Typography>

                        <Typography>Cultivated Lands</Typography>
                        <ReviewTable
                            columnData={cropColumnData}
                            data={cultivated_data}
                            noRecordsMessage={"No cultivated crops entered."}
                        />
                        <br />

                        <Typography>Non-Cultivated Lands</Typography>
                        <ReviewTable
                            columnData={landTypeColumnData}
                            data={noncultivated_data}
                            noRecordsMessage={"No non-cultivated land types entered."}
                        />
                        <br />

                        <Typography gutterBottom>
                            <b>2. Confirm Data Status</b>
                            <br />
                            By default, Farm HPI survey data status is marked as provisional. When your data is complete
                            and finalized for the year, and you have reviewed the data for accuracy, mark the data as
                            finalized. This is important as only finalized data is included in Project and Field to
                            Market Program analysis and reporting.
                            <br />
                            {!isFinal && (
                                <div style={{ marginLeft: 40, marginBottom: 24 }}>
                                    <Typography variant="title" gutterBottom className={classes.gutterTop}>
                                        Current Data Status: Provisional
                                    </Typography>
                                    <Button color="primary" variant="raised" onClick={() => this.finalize(true)}>
                                        Click to Finalize Your Current Data
                                    </Button>
                                </div>
                            )}
                            {isFinal && (
                                <div style={{ marginLeft: 40, marginBottom: 24 }}>
                                    <Typography variant="title" gutterBottom className={classes.gutterTop}>
                                        Current Data Status: Final
                                    </Typography>
                                    <Button
                                        color="primary"
                                        variant="raised"
                                        onClick={() => {
                                            this.props.ormFarmHpiSurveyUpdate({
                                                id: farmHpiSurvey.id,
                                                is_final: false,
                                            });
                                            this.setState({ moveDialogOpen2: false });
                                        }}
                                    >
                                        Click to Make Your Data Provisional
                                    </Button>
                                </div>
                            )}
                        </Typography>
                        <Typography gutterBottom>
                            <b>3.0 Calculate Farm HPI Results</b>
                            <br />
                            Now that your data is complete, you can generate your results. You can return at any time to
                            edit the HPI survey questions or change the data status. <br />
                            <Button
                                color="primary"
                                variant="raised"
                                style={{ marginLeft: 40, marginTop: 16 }}
                                onClick={() => this.computeFarmHpiScore()}
                            >
                                Generate Your Farm HPI Results
                            </Button>
                        </Typography>
                        <Typography gutterBottom variant="display3" className={classes.gutterTop}>
                            Other Steps
                        </Typography>
                        <Typography variant="title">Return to Farm homepage</Typography>
                        <Typography gutterBottom>
                            Return to Farm homepage to manage other information.
                            <Button color="primary" component={Link} to={"/farm/" + farmHpiSurvey.farm.id + "/edit"}>
                                Go Now &gt;
                            </Button>
                        </Typography>
                    </Grid>
                )}
            </Grid>
        );
    }
}

Review = connect(
    (state, ownProps) => ({
        landConversionTypes: allLandConversionTypes(state),
    }),
    {
        ...FarmHpiSurvey.actions,
    },
)(Review);

export default withStyles(styles)(Review);
