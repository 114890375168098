import { SCENARIO_ACCEPT_TERMS } from "./actions";

function scenarioReducer(state = {}, action) {
    switch (action.type) {
        case SCENARIO_ACCEPT_TERMS:
            return {
                acceptedTerms: true,
            };
        default:
            return state;
    }
}

export default scenarioReducer;
