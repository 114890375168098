const stateOptions = [
    // { value: "all", label: "All States", cmzs: ["all"] },
    { value: "AL", label: "Alabama", cmzs: ["63", "64", "66", "67", "69"] },
    { value: "AZ", label: "Arizona", cmzs: ["22", "29", "30", "31", "33"] },
    { value: "AR", label: "Arkansas", cmzs: ["17", "38", "42", "63"] },
    { value: "CA", label: "California", cmzs: ["26", "28", "30", "31", "32", "33", "34", "36", "39", "45"] },
    { value: "CO", label: "Colorado", cmzs: ["05", "09", "12", "13", "14", "15", "15.1", "22", "27", "28", "30"] },
    { value: "CT", label: "Connecticut", cmzs: ["65"] },
    { value: "DE", label: "Delaware", cmzs: ["59", "65"] },
    { value: "FL", label: "Florida", cmzs: ["67", "69", "70"] },
    { value: "GA", label: "Georgia", cmzs: ["64", "66", "67", "69"] },
    { value: "ID", label: "Idaho", cmzs: ["08", "10", "11", "25", "27", "35", "46", "47", "50", "55", "68"] },
    { value: "IL", label: "Illinois", cmzs: ["04", "16", "17", "63"] },
    { value: "IN", label: "Indiana", cmzs: ["04", "16", "17", "62", "63"] },
    { value: "IA", label: "Iowa", cmzs: ["04", "16"] },
    { value: "KS", label: "Kansas", cmzs: ["05", "15", "15.1", "16", "17", "24", "40"] },
    { value: "KY", label: "Kentucky", cmzs: ["17", "62", "63", "64"] },
    { value: "LA", label: "Louisiana", cmzs: ["37", "38", "42", "69"] },
    { value: "ME", label: "Maine", cmzs: ["60"] },
    { value: "MD", label: "Maryland", cmzs: ["04.1", "59", "62", "65", "66", "67"] },
    { value: "MA", label: "Massachusetts", cmzs: ["60", "65"] },
    { value: "MI", label: "Michigan", cmzs: ["01", "04"] },
    { value: "MN", label: "Minnesota", cmzs: ["01", "02", "04"] },
    { value: "MS", label: "Mississippi", cmzs: ["38", "42", "63", "69"] },
    { value: "MO", label: "Missouri", cmzs: ["04", "16", "17", "63"] },
    { value: "MT", label: "Montana", cmzs: ["02", "03", "06", "07", "08", "18"] },
    { value: "NE", label: "Nebraska", cmzs: ["03", "04", "05", "16", "24"] },
    { value: "NV", label: "Nevada", cmzs: ["25", "26", "28", "29", "31", "32", "33", "36"] },
    { value: "NH", label: "New Hampshire", cmzs: ["60", "65"] },
    { value: "NJ", label: "New Jersey", cmzs: ["59", "65"] },
    {
        value: "NM",
        label: "New Mexico",
        cmzs: ["13", "14", "15", "15.1", "19", "20", "21", "22", "23", "29", "30", "33", "43"],
    },
    { value: "NY", label: "New York", cmzs: ["04.1", "59", "60", "65"] },
    { value: "NC", label: "North Carolina", cmzs: ["64", "66", "67"] },
    { value: "ND", label: "North Dakota", cmzs: ["01", "02", "03"] },
    { value: "OH", label: "Ohio", cmzs: ["04", "04.1", "16", "17", "62"] },
    { value: "OK", label: "Oklahoma", cmzs: ["15", "15.1", "17", "40", "42", "48"] },
    {
        value: "OR",
        label: "Oregon",
        cmzs: ["08", "11", "25", "26", "32", "36", "45", "47", "49", "50", "51", "52", "53", "54", "55", "71"],
    },
    { value: "PA", label: "Pennsylvania", cmzs: ["04", "04.1", "16", "59", "62", "65"] },
    { value: "RI", label: "Rhode Island", cmzs: ["65"] },
    { value: "SC", label: "South Carolina", cmzs: ["64", "66", "67"] },
    { value: "SD", label: "South Dakota", cmzs: ["01", "03", "04", "05"] },
    { value: "TN", label: "Tennessee", cmzs: ["17", "42", "62", "63", "64", "66"] },
    {
        value: "TX",
        label: "Texas",
        cmzs: [
            "15",
            "19",
            "20",
            "23",
            "30",
            "37",
            "37.1",
            "38",
            "38.1",
            "40",
            "41",
            "42",
            "43",
            "44",
            "48",
            "57",
            "58",
        ],
    },
    { value: "UT", label: "Utah", cmzs: ["09", "10", "13", "22", "25", "27", "28", "29", "30", "31", "35"] },
    { value: "VA", label: "Virginia", cmzs: ["59", "62", "64", "65", "66", "67"] },
    { value: "WA", label: "Washington", cmzs: ["08", "46", "47", "49", "50", "51", "52", "53", "54", "56", "71"] },
    { value: "WV", label: "West Virginia", cmzs: ["04.1", "16", "62", "64", "65", "66"] },
    { value: "VT", label: "Vermont", cmzs: ["04.1", "60"] },
    { value: "WI", label: "Wisconsin", cmzs: ["01", "04"] },
    { value: "WY", label: "Wyoming", cmzs: ["03", "05", "07", "08", "09", "10", "12", "27", "30"] },
];

const cropContainsOptions = [
    { id: "0", label: "Contains ONLY these crops (default)" },
    { id: "1", label: "Contains ANY of these crops" },
];

const durationOptions = [
    { label: "All", value: "all" },
    { label: "Single Year", value: "single-year" },
    { label: "Multi-Year", value: "multi-year" },
];

const tillageOptions = [
    { label: "All Tillage Types (default)", value: "all", stirMin: "", stirMax: "" },
    { label: "Conventional Till", value: "conventional_till", stirMin: 80, stirMax: "" },
    { label: "Reduced Till", value: "reduced_till", stirMin: 20, stirMax: 80 },
    { label: "No-Till", value: "no_till", stirMin: "", stirMax: 20 },
];

const monthOptions = [
    { label: "January", value: "01" },
    { label: "February", value: "02" },
    { label: "March", value: "03" },
    { label: "April", value: "04" },
    { label: "May", value: "05" },
    { label: "June", value: "06" },
    { label: "July", value: "07" },
    { label: "August", value: "08" },
    { label: "September", value: "09" },
    { label: "October", value: "10" },
    { label: "November", value: "11" },
    { label: "December", value: "12" },
];

const cropMonthOptions = [
    { label: "January", value: "02" },
    { label: "February", value: "03" },
    { label: "March", value: "04" },
    { label: "April", value: "05" },
    { label: "May", value: "06" },
    { label: "June", value: "07" },
    { label: "July", value: "08" },
    { label: "August", value: "09" },
    { label: "September", value: "10" },
    { label: "October", value: "11" },
    { label: "November", value: "12" },
    { label: "December", value: "01" },
];

const monthLength = [
    { days: 31, month: "01" },
    { days: 28, month: "02" },
    { days: 31, month: "03" },
    { days: 30, month: "04" },
    { days: 31, month: "05" },
    { days: 30, month: "06" },
    { days: 31, month: "07" },
    { days: 31, month: "08" },
    { days: 30, month: "09" },
    { days: 31, month: "10" },
    { days: 30, month: "11" },
    { days: 31, month: "12" },
];

export {
    stateOptions,
    durationOptions,
    cropContainsOptions,
    tillageOptions,
    monthOptions,
    cropMonthOptions,
    monthLength,
};
