import React, { Component } from "react";
import withRouter from "../common/withRouter";
import classNames from "classnames";

import Button from "../common/ButtonWrapper";
import withStyles from "@mui/styles/withStyles";
import Typography from "../common/TypographyWrapper";
import Grid from "../common/GridWrapper";

import ActionButton from "../common/ActionButton";

import { connect } from "react-redux";
import * as authActions from "./actions";
import * as navActions from "../common/actions";

const styles = (theme) => ({
    loginHeaders: {
        fontSize: 30,
        fontWeight: 100,
        marginBottom: 8,
    },
    loginError: {
        color: "red",
        "text-align": "center",
        "font-size": 16,
        marginBottom: 24,
    },
    marginBottom: {
        height: "4.25em",
        marginBottom: 40,
    },
    greyButton: {
        backgroundColor: "#606060",
        color: "#fff",
        float: "right",
        maxWidth: 200,
        [theme.breakpoints.down("md")]: {
            float: "none",
            maxWidth: "none",
        },
    },
    loginButton: {
        maxWidth: 150,
    },
    loginButtonWide: {
        maxWidth: 200,
    },
    welcome: {
        fontSize: 18,
        font: "medium roboto slab",
        color: "black",
        marginBottom: 3,
    },
    primaryButton: {
        maxWidth: 250,
        [theme.breakpoints.down("md")]: {
            maxWidth: "none",
        },
    },
    secondaryButton: {
        backgroundColor: "#fff",
    },
});

function Welcome({ classes, user }) {
    if (!user.email_valid) {
        return <Typography className={classes.welcome}>Your new account is almost ready to use.</Typography>;
    } else if (user.new_user) {
        return <Typography className={classes.welcome}>Your new account is active and ready to use.</Typography>;
    } else {
        return <Typography className={classes.welcome}>Welcome, {user.first_name}!</Typography>;
    }
}

class LoginPage extends Component {
    logOut = () => {
        this.props.authLogout();
    };

    continuePlatform = () => {
        const { authState } = this.props,
            { user } = authState;
        let nextPage;
        if (!user.email_valid) {
            nextPage = "activate/resend";
        } else if (user.new_user) {
            nextPage = "dashboard";
        } else {
            nextPage = "dashboard";
        }
        this.props.navSelectPage(nextPage);
        this.props.history.push("/" + nextPage);
    };

    guestLogin = () => {
        this.props.authLoginGuest();
        this.props.navSelectPage("welcome");
        this.props.history.push("/welcome");
    };

    continueRegister = () => {
        this.props.history.push("/register");
    };

    render() {
        const { classes, authState } = this.props;

        if (!authState) return "...";

        if (authState.user && !authState.user.guest) {
            return (
                <>
                    <Grid container spacing={8}>
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                <Welcome classes={classes} user={authState.user} />
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <ActionButton
                                onClick={this.continuePlatform}
                                loading={authState.revalidatePending}
                                label={authState.revalidatePending ? "Checking Login..." : "Continue to Calculator"}
                                variant={authState.revalidatePending ? "outlined" : "contained"}
                                disableElevation
                                color={authState.revalidatePending ? "inherit" : "primary"}
                                className={classes.primaryButton}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Button
                                onClick={this.logOut}
                                variant="raised"
                                disableElevation
                                fullWidth
                                className={classes.greyButton}
                            >
                                Logout
                            </Button>
                        </Grid>
                    </Grid>
                </>
            );
        } else {
            const oauthUrl = window.location.hostname === "localhost" ? "/admin/login/?next=/" : "/login/neon?next=/";
            return (
                <Grid container spacing={8}>
                    <Grid item xs={12} md={6}>
                        <ActionButton
                            loading={authState.autoPending}
                            href={oauthUrl}
                            label={authState.autoPending ? "Checking Login..." : "Login"}
                            variant={authState.autoPending ? "outlined" : "contained"}
                            disableElevation
                            color={authState.autoPending ? "inherit" : "primary"}
                            className={classNames(
                                classes.primaryButton,
                                authState.autoPending ? classes.loginButtonWide : classes.loginButton,
                            )}
                        />
                    </Grid>
                    {this.renderSecondary()}
                </Grid>
            );
        }
    }

    renderSecondary() {
        const { classes } = this.props;

        return (
            <Grid item xs={12} md={6}>
                <Grid container spacing={8}>
                    <Grid item xs={12} md={6}>
                        <Button
                            onClick={this.continueRegister}
                            className={classes.secondaryButton}
                            fullWidth
                            variant="outlined"
                            color="primary"
                        >
                            Register
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Button
                            onClick={this.guestLogin}
                            className={classes.secondaryButton}
                            fullWidth
                            variant="outlined"
                            color="primary"
                        >
                            Guest
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

LoginPage = connect((state) => ({ authState: state.auth }), {
    ...authActions,
    ...navActions,
})(LoginPage);

export default withStyles(styles)(withRouter(LoginPage));
