import {
    AUTH_AUTOLOGIN_PENDING,
    AUTH_AUTOLOGIN_ERROR,
    AUTH_AUTOLOGIN_SUCCESS,
    AUTH_LOGIN_GUEST,
    AUTH_LOGOUT,
    AUTH_REGISTER_PENDING,
    AUTH_REGISTER_SUCCESS,
    AUTH_REGISTER_ERROR,
    AUTH_REVALIDATE_PENDING,
    AUTH_REVALIDATE_SUCCESS,
    AUTH_REVALIDATE_ERROR,
    AUTH_UPDATE_PENDING,
    AUTH_UPDATE_SUCCESS,
    AUTH_UPDATE_ERROR,
    AUTH_PASSWORD_CHANGE_PENDING,
    AUTH_PASSWORD_CHANGE_SUCCESS,
    AUTH_PASSWORD_CHANGE_ERROR,
    AUTH_ACCOUNT_CONFIRM_PENDING,
    AUTH_ACCOUNT_CONFIRM_SUCCESS,
    AUTH_ACCOUNT_CONFIRM_ERROR,
} from "./actions";

function serializeError(data) {
    return (
        "Error: " +
        Object.values(data)
            .map((err) => err /*[0]*/)
            .join(", ")
    );
}

function authReducer(state = {}, action) {
    switch (action.type) {
        case AUTH_AUTOLOGIN_PENDING:
            return {
                autoPending: true,
                accountConfirm: state.accountConfirm,
            };
        case AUTH_REVALIDATE_PENDING:
            return {
                revalidatePending: true,
                user: state.user,
                accountConfirm: state.accountConfirm,
            };
        case AUTH_LOGIN_GUEST:
        case AUTH_AUTOLOGIN_SUCCESS:
        case AUTH_REVALIDATE_SUCCESS:
            return {
                user: action.user,
                accountConfirm: state.accountConfirm,
            };
        case AUTH_AUTOLOGIN_ERROR:
        case AUTH_REVALIDATE_ERROR:
        case AUTH_LOGOUT:
            return {
                accountConfirm: state.accountConfirm,
            };
        case AUTH_REGISTER_PENDING:
            return { register: { pending: true } };
        case AUTH_REGISTER_SUCCESS:
            return { register: { success: true } };
        case AUTH_REGISTER_ERROR:
            return { register: { error: serializeError(action.error) } };
        case AUTH_UPDATE_PENDING:
            return {
                update: {
                    pending: true,
                },
                user: state.user,
            };
        case AUTH_UPDATE_SUCCESS:
            return {
                update: {
                    success: true,
                },
                user: action.user,
            };
        case AUTH_UPDATE_ERROR:
            return {
                update: {
                    error: serializeError(action.error),
                },
                user: state.user,
            };
        case AUTH_ACCOUNT_CONFIRM_PENDING:
            return {
                accountConfirm: { pending: true },
                user: state.user || null,
            };
        case AUTH_ACCOUNT_CONFIRM_SUCCESS:
            return {
                accountConfirm: { success: true },
                user: state.user
                    ? {
                          ...state.user,
                          email_valid: true,
                      }
                    : null,
            };
        case AUTH_ACCOUNT_CONFIRM_ERROR:
            return {
                accountConfirm: { error: serializeError(action.error) },
                user: state.user || null,
            };
        case AUTH_PASSWORD_CHANGE_PENDING:
            return {
                change: {
                    pending: true,
                },
                user: state.user,
            };
        case AUTH_PASSWORD_CHANGE_SUCCESS:
            return {
                change: {
                    success: true,
                },
                user: state.user,
            };
        case AUTH_PASSWORD_CHANGE_ERROR:
            return {
                change: {
                    error: serializeError(action.error),
                },
                user: state.user,
            };
        default:
            return state;
    }
}

export default authReducer;
