import React, { Component } from "react";

import { Dialog, DialogContent } from "@mui/material";
import DialogContentText from "@mui/material/DialogContentText";
import withStyles from "@mui/styles/withStyles";
import Typography from "../common/TypographyWrapper";
import Toolbar from "@mui/material/Toolbar";
import Close from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Privacy from "./Privacy";

const styles = {
    flex: {
        flex: 1,
    },
    termDialog: {
        width: 1000,
        maxWidth: "80%",
    },
    marginLeft: {
        marginLeft: 24,
    },
    linkColor: {
        color: "#808080",
        cursor: "pointer",
        textDecoration: "underline",
    },
};

class Terms extends Component {
    state = {
        privacyOpen: false,
    };

    openPrivacy = () => {
        this.setState({ privacyOpen: true });
    };

    closePrivacy = () => {
        this.setState({ privacyOpen: false });
    };
    render() {
        const { classes, dialogOpen, closeEvent } = this.props;
        const { privacyOpen } = this.state;
        return (
            <Dialog open={dialogOpen} classes={{ paper: classes.termDialog }}>
                <Toolbar>
                    <Typography variant="headline" className={classes.flex}>
                        Terms of Use
                    </Typography>
                    <IconButton onClick={closeEvent} size="large">
                        <Close />
                    </IconButton>
                </Toolbar>
                <DialogContent>
                    <DialogContentText>
                        <Typography variant="title" gutterBottom>
                            Last Updated: September 28, 2018
                        </Typography>
                        <Typography gutterBottom>
                            The Field to Market Fieldprint Platform (“Calculator”) and Field to Market website,
                            collectively referred to as the “Site”, is a service of Field to Market. These Field to
                            Market Terms of Use (“Terms”) set forth the terms and conditions applicable to your access
                            and use of the Site however accessed and/or used, whether via personal computer, mobile
                            device, or otherwise.
                        </Typography>
                        <Typography gutterBottom>
                            BY ACCESSING OR USING THE SITE, YOU AGREE TO THESE TERMS. IF YOU DO NOT AGREE TO THESE TERMS
                            WITHOUT MODIFICATION, YOU ARE NOT AUTHORIZED TO ACCESS OR USE THE SITE.
                        </Typography>
                        <Typography gutterBottom>
                            PLEASE NOTE THAT, NOTWITHSTANDING ANYTHING CONTAINED IN THESE TERMS OF USE OR ON THE SITE TO
                            THE CONTRARY, THE SITE IS PROVIDED WITHOUT ANY WARRANTY AND SUBJECT TO LIMITATIONS ON OUR
                            LIABILITY. THESE LIMITATIONS ARE CONTAINED IN SECTIONS 6 AND 8 BELOW.
                        </Typography>
                        <Typography gutterBottom>
                            WE MAY REVISE THESE TERMS AT ANYTIME. WHEN WE DO, WE WILL ALSO REVISE THE “LAST UPDATED”
                            DATE AT THE TOP OF THESE TERMS. YOU ARE RESPONSIBLE FOR REGULARLY REVIEWING THE CURRENT
                            TERMS. THE MOST CURRENT VERSION OF THE TERMS CAN BE REVIEWED BY CLICKING ON THE “TERMS OF
                            USE” HYPERTEXT LINK LOCATED ON OUR WEB PAGES. YOUR CONTINUED ACCESS AND/OR USE OF THE SITE
                            AFTER WE POST ANY REVISED TERMS CONSTITUTES YOUR AGREEMENT TO ANY SUCH REVISED TERMS.
                        </Typography>
                        <Typography variant="headline" gutterBottom>
                            1. General Use and Restrictions
                        </Typography>
                        <Typography gutterBottom>1.1 Use of the Site</Typography>
                        <Typography gutterBottom>
                            Subject to your compliance with these Terms, we grant you a personal, non-exclusive,
                            non-transferable and limited license to access and use the Site solely to provide
                            information about your farm and fields and farm operations in order to measure the
                            environmental impacts of commodity crop production and identify opportunities for continuous
                            improvement. You agree not to access or use the Site for any other purpose, including,
                            without limitation, any purpose that is prohibited by these Terms or that is otherwise
                            unlawful. You further agree to comply with all applicable U.S. and international laws,
                            statutes, ordinances, regulations, contracts, and applicable licenses regarding your use of
                            the Site.
                        </Typography>
                        <Typography gutterBottom>1.2 Use of Content</Typography>
                        <Typography gutterBottom>
                            You may print a single copy of any information displayed or transmitted on the Site
                            (collectively, “Content”) that you are authorized to access, provided that you:
                            <br />
                            <br />
                            <span className={classes.marginLeft}>
                                (a) do not remove any notice or other information contained on such Content, and{" "}
                            </span>
                            <br />
                            <br />
                            <span className={classes.marginLeft}>
                                (b) strictly comply with the provisions of the Terms, including, without limitations,
                                Section 1.3 below.{" "}
                            </span>
                        </Typography>
                        <Typography gutterBottom>1.3 Restrictions</Typography>
                        <Typography gutterBottom>
                            Other than printing a single copy of particular Content as provided in Section 1.2 above,
                            you may not (and you agree that you will not) reproduce, alter, modify, create derivative
                            works from, distribute, perform or display any Content without first receiving our express
                            written permission. You further agree not to, without first obtaining our express written
                            permission:
                            <br />
                            <br />
                            <span className={classes.marginLeft}>
                                (a) use any of our trademarks as metatags on other websites,
                            </span>
                            <br />
                            <br />
                            <span className={classes.marginLeft}>
                                (b) use the Site in any manner that is illegal or impairs the operation of the Site or
                                its availability to or usage by others,
                            </span>
                            <br />
                            <br />
                            <span className={classes.marginLeft}>
                                (c) display any part of the Site in frames (or any Content via in-line links), and/or{" "}
                            </span>
                            <br />
                            <br />
                            <span className={classes.marginLeft}>
                                (d) use or access, or attempt to use or access, any portion of the Site for which you
                                are not given access by us.
                            </span>
                        </Typography>
                        <Typography gutterBottom>
                            You further agree not to decompile, reverse engineer, or disassemble any software or other
                            products or processes accessible through the Site, and not to insert any code or product or
                            manipulate the Site in any way that affects the user’s experience including, without
                            limitation, taking any action that imposes, or may impose, an unreasonable or
                            disproportionately large load on the Site. You further agree not to use any data mining, web
                            crawlers, robots, cancelbots, spiders, Trojan horses, or any data gathering or extraction
                            method in connection with your use of the Site except for customary search engines used in
                            accordance with automated instructions directed to search engines and available on the Site.
                        </Typography>
                        <Typography variant="headline" gutterBottom>
                            2. Accounts
                        </Typography>
                        <Typography gutterBottom>2.1 Registration</Typography>
                        <Typography gutterBottom>
                            If we issue a username and password to you in connection with your use of the Site or any
                            portion thereof (an “Account”), you will be considered a “Registered User.” Certain portions
                            of the Site may be accessible only to Registered Users. If you are a Registered User, you
                            may not authorize, permit, or otherwise allow any third party to use your username and
                            password to access your Account on your behalf. Instead, as a Registered User, the Site
                            allows you to authorize other Site Users to access your Account, access your information,
                            and enter data on your behalf via their own unique usernames and passwords. This is only
                            possible if you explicitly authorize this access using Site Delegate features. When you
                            authorize users to access your Account and act on your behalf to provide information on the
                            Site, you are responsible for ensuring that any authorized users appropriately use and
                            protect your information.
                        </Typography>
                        <Typography gutterBottom>
                            You agree to protect your username and password by, among other actions, keeping your
                            username and password confidential. You agree to notify us immediately of any unauthorized
                            use of your username, password, or Account. If, notwithstanding the foregoing obligations,
                            you allow another party to use your Account, you will be responsible for all use by the
                            party using your Account.
                        </Typography>
                        <Typography gutterBottom>2.2 Termination/Suspension of the Site</Typography>
                        <Typography gutterBottom>
                            These Terms are effective until terminated. Should you fail to comply with any of these
                            Terms herein, all of these Terms will automatically terminate without notice and all of your
                            rights hereunder will cease. We may, in our sole discretion, terminate or suspend your
                            Account and/or your access to, and/or use of, the Site, or any portion thereof, at any time,
                            with or without notice and for any reason (or no reason), and you agree that (a) if your
                            authorization to access the Site is terminated, you will not thereafter access, or attempt
                            to access the Site, directly or indirectly, and (b) if your authorization to access the Site
                            is suspended, you will not thereafter access, or attempt to access, the Site directly or
                            indirectly, until your suspension is removed and we give you express notice thereof.
                        </Typography>
                        <Typography variant="headline" gutterBottom>
                            3. User Information
                        </Typography>
                        <Typography gutterBottom>3.1 Information You Provide</Typography>
                        <Typography gutterBottom>
                            You agree that all information provided to us will be true, accurate, current, and complete.
                            By uploading, providing or otherwise submitting information to the Site (“User
                            Information”), you agree to collection, use and sharing of User information for the purposes
                            specified in these Terms as well as those specified in Field to Market’s&nbsp;
                            <span className={classes.linkColor} onClick={this.openPrivacy}>
                                Data and Privacy Policy
                            </span>
                            . You represent and warrant to Field to Market that you have the right to upload or
                            otherwise submit such User Information to us and to grant Field to Market the licenses and
                            rights deemed granted in this Section 3.1. You shall have sole responsibility for the
                            accuracy, quality, integrity, legality, reliability, and appropriateness of all User
                            Information. You agree that you will not provide any User Information to the Site that (a)
                            may infringe upon the copyright, trademark, trade secret, or other intellectual property
                            right of a third party, (b) may violate the privacy rights of another, or (c) may violate
                            any applicable municipal, state, foreign, or federal law, rule, regulation, or ordinance, in
                            each instance when used as contemplated by the Site. For the avoidance of doubt, User
                            Information does not include Feedback as defined below in Section 3.2.
                        </Typography>
                        <Typography gutterBottom>3.2 Feedback</Typography>
                        <Typography gutterBottom>
                            If you provide any comments, ideas, know-how, instructions, suggestions, information, and/or
                            other feedback to us regarding the Site or any application available on the Site
                            (collectively, “Feedback”), such Feedback shall be owned exclusively by Field to Market. To
                            the extent you have or obtain any intellectual property right in or to any Feedback, you
                            shall be deemed to assign all right, title and interest therein, if any, to Field to Market
                            as of the date such right first vests in you.
                        </Typography>
                        <Typography gutterBottom>3.3 Removal of User Information</Typography>
                        <Typography gutterBottom>
                            You acknowledge that Field to Market has no obligation to pre-screen content contributed to
                            the Site. However, Field to Market has the right, but not the obligation, at any time and
                            without prior notice, to refuse, remove or disable any content, including your User
                            information, that is available via the Site. Without limiting the generality of the
                            foregoing, Field to Market may edit or remove any User Information you submit to the Site
                            that Field to Market, in its sole discretion, deems abusive, defamatory, obscene, unlawful,
                            or otherwise unacceptable, and you waive any moral rights you may have with respect to
                            changes in the User Information. We are not responsible for maintaining your User
                            Information and we may, in our sole discretion, delete or destroy it at any time with no
                            liability or obligation to you.
                        </Typography>
                        <Typography gutterBottom>3.4 Limitations</Typography>
                        <Typography gutterBottom>
                            You agree not to provide User Information that:
                            <br />
                            <br />
                            <span className={classes.marginLeft}>
                                i. contains vulgar, profane, abusive, or hateful language, epithets, or slurs, texts, or
                                illustrations, in poor taste, inflammatory attacks of a personal, racial, or religious
                                nature, or expressions of bigotry, racism, discrimination, or hate; or
                            </span>
                            <br />
                            <br />
                            <span className={classes.marginLeft}>
                                ii. is defamatory, threatening, disparaging, inflammatory, false, misleading, deceptive,
                                fraudulent, inaccurate, unfair, contains gross exaggeration or unsubstantiated claims,
                                is unreasonably harmful or offensive to any individual or community, contains any
                                actionable statement, or tends to mislead or reflect unfairly on any other person,
                                business, or entity; or
                            </span>
                            <br />
                            <br />
                            <span className={classes.marginLeft}>
                                iii. violates or infringes any right of Field to Market or any third party, including,
                                without limitation, any privacy, trademark, copyright, or other intellectual property
                                right, or will otherwise cause injury to any third party; or
                            </span>
                            <br />
                            <br />
                            <span className={classes.marginLeft}>
                                iv. discriminates on the grounds of race, religion, national origin, gender, age,
                                marital status, sexual orientation or disability, or refers to such matters in any
                                manner prohibited by law; or
                            </span>
                            <br />
                            <br />
                            <span className={classes.marginLeft}>
                                v. violates any municipal, state, foreign, or federal law, rule, regulation, or
                                ordinance, or attempts to encourage evasion or violation thereof; or
                            </span>
                            <br />
                            <br />
                            <span className={classes.marginLeft}>
                                vi. unfairly interferes with any third party’s uninterrupted use and enjoyment of the
                                Site; or
                            </span>
                            <br />
                            <br />
                            <span className={classes.marginLeft}>
                                vii. advertises, promotes, or offers to trade any goods or services; or
                            </span>
                            <br />
                            <br />
                            <span className={classes.marginLeft}>
                                viii. includes or transmits viruses or other harmful, disruptive, or destructive files;
                                or
                            </span>
                            <br />
                            <br />
                            <span className={classes.marginLeft}>
                                ix. disrupts, interferes with, or otherwise harms or violates the security of the Site,
                                system resources, accounts, passwords, servers, or networks connected to or accessible
                                through the Site or affiliated or linked sites; or
                            </span>
                            <br />
                            <br />
                            <span className={classes.marginLeft}>
                                x. “flames” any individual or entity (e.g., sends repeated messages related to another
                                user and/or makes derogatory or offensive comments about another individual), or repeats
                                prior posting of the same message under multiple threads or subjects; or
                            </span>
                            <br />
                            <br />
                            <span className={classes.marginLeft}>xi. otherwise violates these Terms of Use.</span>
                        </Typography>
                        <Typography variant="headline" gutterBottom>
                            4. Linking and Third-Party Dealings
                        </Typography>
                        <Typography gutterBottom>4.1 Links to External Sites</Typography>
                        <Typography gutterBottom>
                            We may provide hyperlinks to other web sites and internet resources operated by parties
                            other than Field to Market. We have no control over such sites and resources or their
                            privacy policies. Such hyperlinks are provided for your reference only. The inclusion of
                            hyperlinks to such web sites does not imply any sponsorship, affiliation, or endorsement of
                            the material on such web sites or with their operators. You agree that Field to Market is
                            not responsible or liable for any content, advertising, or other materials available through
                            the third-party web sites, and that Field to Market is not responsible or liable, directly
                            or indirectly for any damage or loss caused to you by your use of or reliance on any
                            content, goods, or services available through the third-party web sites and internet
                            resources.
                        </Typography>
                        <Typography gutterBottom>4.2 Linking to the Site</Typography>
                        <Typography gutterBottom>
                            Subject to the further provisions of this Section 4.2, we welcome links to the Site from
                            other web sites. If we demand that you not link to the Site, or any portion of the Site, you
                            agree that you will not, directly or indirectly, link to the Site or such portion of the
                            Site as directed in our demand, at any time after such demand is made.
                        </Typography>
                        <Typography variant="headline" gutterBottom>
                            5. Intellectual Property
                        </Typography>
                        <Typography gutterBottom>5.1 Field to Market Intellectual Property</Typography>
                        <Typography gutterBottom>
                            Except for Content that is in the public domain, the Site and all Content, as well as the
                            selection and arrangement of the Content, is owned by (or licensed to) Field to Market and
                            is protected by copyright, trade dress, trademark, unfair competition, and/or other laws and
                            may not be used, copied, or imitated in whole or in part. www.fieldtomarket.org,
                            calculator.fieldtomarket.org, and all related logos are either trademarks or registered
                            trademarks of Field to Market or its licensors and may not be copied, imitated, or used, in
                            whole or in part, without our prior written permission. In addition, all page headers,
                            custom graphics, button icons, and scripts are service marks, trademarks, and/or trade dress
                            of Field to Market or its licensors and may not be copied, imitated, or used, in whole or in
                            part, without our prior written permission. Except as otherwise expressly provided in these
                            Terms, all rights in and to the Site (including all Content) are expressly reserved by Field
                            to Market.
                        </Typography>
                        <Typography gutterBottom>5.2 Digital Millennium Copyright Act Notice</Typography>
                        <Typography gutterBottom>
                            Field to Market respects the intellectual property rights of others. If you believe that
                            your work has been copied and is accessible on the Site in a way that constitutes copyright
                            infringement, you may notify our agent as provided in this Section 5.2. Field to Market may
                            terminate your use and access to the Site if Field to Market believes that you are
                            infringing the intellectual property rights of others and/or are aiding or threatening such
                            infringement.
                        </Typography>
                        <Typography variant="headline" gutterBottom>
                            6. No Warranties
                        </Typography>
                        <Typography gutterBottom>6.1 Warranty Waiver</Typography>
                        <Typography gutterBottom>
                            The Site is provided on an “AS IS,” “WITH ALL FAULTS,” and “AS AVAILABLE” basis. We assume
                            no liability or responsibility for any errors or omissions in connection with the Site
                            including, without limitation, any errors or omissions with respect to any Content. You
                            acknowledge and agree that you assume sole responsibility for ensuring that all Content is
                            accurate and up to date. Further, we do not represent or warrant that any aspect of the Site
                            or its applications will work properly or will be continuously available. WITHOUT LIMITING
                            THE GENERALITY OF THE FOREGOING, ANY CONTENT DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE
                            USE OF THE SITE IS DONE AT YOUR SOLE DISCRETION AND RISK. WE AND OUR SUPPLIERS, VENDORS, AND
                            PARTNERS HEREBY DISCLAIM ANY AND ALL WARRANTIES AND REPRESENTATIONS, WHETHER EXPRESS OR
                            IMPLIED, ORAL OR WRITTEN, INCLUDING, WITHOUT LIMITATION, ANY AND ALL IMPLIED WARRANTIES OF
                            MERCHANTABILITY, REASONABLE CARE, SECURITY, QUALITY, TIMELINESS, AVAILABILITY, COMPLETENESS,
                            RELIABILITY, ACCURACY, AND/OR FITNESS FOR A PARTICULAR PURPOSE (WHETHER OR NOT WE AND/OR ANY
                            OF OUR PARTNERS KNOW, HAVE REASON TO KNOW, HAVE BEEN ADVISED, OR ARE OTHERWISE IN FACT AWARE
                            OF ANY SUCH PURPOSE), IN EACH INSTANCE WITH RESPECT TO THE SITE (INCLUDING, WITHOUT
                            LIMITATION, THE CONTENT). FURTHERMORE, WE EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES OF TITLE
                            AND/OR NON-INFRINGEMENT WITH RESPECT TO THE SITE (INCLUDING, WITHOUT LIMITATION, THE
                            CONTENT). Some jurisdictions do not allow implied warranties to be excluded or modified, so
                            not all of the above limitations may apply to you.
                        </Typography>
                        <Typography variant="headline" gutterBottom>
                            7. Indemnification
                        </Typography>
                        <Typography gutterBottom>7.1 User Indemnification</Typography>
                        <Typography gutterBottom>
                            You hereby agree to indemnify and hold harmless Field to Market and its affiliates,
                            partners, officers, directors, employees, shareholders, information providers, suppliers,
                            and licensees (collectively, “Indemnified Parties”) from and against any and all liability
                            and costs, including, without limitation, reasonable attorney’s fees, incurred by the
                            Indemnified Parties in connection with any claim arising out of or related to (a) your
                            access and/or use of the Site including, without limitation, any Content, (b) any breach of
                            any of these Terms by you or, if applicable, any user of your Account, (c) your User
                            Information, and/or (d) any allegation which, if true, would constitute a breach of any of
                            these Terms by you or, if applicable, any user of your Account.
                        </Typography>
                        <Typography variant="headline" gutterBottom>
                            8. Limitation of Liability
                        </Typography>
                        <Typography gutterBottom>8.1 Field to Market’s Limited Liability</Typography>
                        <Typography gutterBottom>
                            EXCEPT WHERE PROHIBITED BY LAW, IN NO EVENT WILL FIELD TO MARKET AND/OR ITS PARTNERS,
                            VENDORS, OR SUPPLIERS BE LIABLE FOR (A) ANY DAMAGES OF ANY NATURE WHATSOEVER RESULTING FROM,
                            OR RELATED TO, THE LOSS, DELAY OR INABILITY TO USE THE SITE, THE LOSS OF ANY CONTENT
                            OBTAINED THROUGH THE SITE, AND/OR THE INACCURACY OF ANY CONTENT, AND/OR (B) ANY INDIRECT,
                            CONSEQUENTIAL, SPECIAL, INCIDENTAL, OR PUNITIVE DAMAGES WHATSOEVER INCLUDING, WITHOUT
                            LIMITATION, DAMAGES FOR LOSS OF PROFITS ARISING OUT OF OR IN ANY WAY CONNECTED WITH THESE
                            TERMS OF USE AND/OR OTHERWISE ARISING OUT OF THE USE OR PERFORMANCE OF THE SITE (INCLUDING,
                            WITHOUT LIMITATION, ANY CONTENT), IN EACH OF (A) AND (B) ABOVE WHETHER BASED IN CONTRACT,
                            TORT, NEGLIGENCE, STRICT LIABILITY, OR OTHERWISE AND EVEN IF FIELD TO MARKET AND/OR ITS
                            PARTNERS, VENDORS, OR SUPPLIERS HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN ALL
                            EVENTS, FIELD TO MARKET AND ITS PARTNERS, VENDORS, AND SUPPLIERS AGGREGATE LIABILITY FOR
                            CLAIMS ARISING OUT OF OR IN ANY WAY CONNECTED WITH THESE TERMS AND/OR THE USE OR PERFORMANCE
                            OF THE SITE (INCLUDING, WITHOUT LIMITATION, ANY CONTENT) WHETHER BASED IN CONTRACT, TORT,
                            NEGLIGENCE, STRICT LIABILITY, OR OTHERWISE SHALL BE LIMITED TO $5.00. SOME JURISDICTION DO
                            NOT ALLOW THE DISCLAIMER OF SOME TYPES OF DAMAGES, SO SOME OF THE ABOVE MAY NOT APPLY TO
                            YOU.
                        </Typography>
                        <Typography variant="headline" gutterBottom>
                            9. Release
                        </Typography>
                        <Typography gutterBottom>9.1 Release</Typography>
                        <Typography gutterBottom>
                            In the event that you now or hereafter have a dispute with any person or entity arising from
                            your use of the Site, you release Field to Market (and its officers, directors, agents,
                            affiliates, partners, and employees) from claims, demands, and damages (actual and
                            consequential) of every kind and nature, known and unknown, suspected and unsuspected,
                            disclosed and undisclosed, arising out of, or in any way connected with, such dispute.
                        </Typography>
                        <Typography variant="headline" gutterBottom>
                            10. Modification of the Site
                        </Typography>
                        <Typography gutterBottom>10.1 Field to Market’s Right to Modify</Typography>
                        <Typography gutterBottom>
                            For the avoidance of any doubt, we may modify, suspend, discontinue, and/or restrict the use
                            of all or any portion of the Site (including, without limitation, any Content contained on
                            the Site) at any time for any reason (or for no reason at all) and without notice or
                            liability.
                        </Typography>
                        <Typography variant="headline" gutterBottom>
                            11. Privacy
                        </Typography>
                        <Typography gutterBottom>11.1 Consent to Privacy Policy</Typography>
                        <Typography gutterBottom>
                            By using this Site, you consent to Field to Market’s&nbsp;
                            <span className={classes.linkColor} onClick={this.openPrivacy}>
                                Data and Privacy Policy
                            </span>
                            .
                        </Typography>
                        <Typography variant="headline" gutterBottom>
                            12. Governing Law and Jurisdiction
                        </Typography>
                        <Typography gutterBottom>12.1 Dispute Resolution</Typography>
                        <Typography gutterBottom>
                            These Terms, and all matters arising out of or relating to the Site, shall be governed by
                            the laws of the United States and the District of Columbia, without giving effect to the
                            conflict of law provisions thereof. Additionally, the Uniform Computer Information
                            Transaction Act shall not apply to these Terms or the Site. In the event you desire to
                            initiate any suit against Field to Market arising out of or relating to the Site and/or
                            these Terms, you agree to bring such suit in the federal court sitting in the District of
                            Columbia, USA unless no federal subject matter jurisdiction exists, in which case you agree
                            to bring such suit in the state courts sitting in Alexandria, Virginia. You further agree
                            that we may initiate a suit against you arising out of or relating to the Site and/or these
                            Terms in such courts and you hereby waive, to the fullest extent permissible by applicable
                            law, all rights you may have, or which may hereafter arise, to contest jurisdiction or venue
                            in such courts.
                        </Typography>
                        <Typography variant="headline" gutterBottom>
                            13. Miscellaneous
                        </Typography>
                        <Typography gutterBottom>13.1 Controlling Terms</Typography>
                        <Typography gutterBottom>
                            These Terms, including, without limitation, (a) any other terms and conditions that may
                            appear on the Site from time-to-time (such as, without limitation, additional
                            representations that we may ask you to make when submitting information to the Site), and
                            (b) any written terms and conditions that you and Field to Market may agree to in writing
                            (executed by you and Field to Market with specific reference to these Terms) are to
                            supplement these Terms (whether such writing is agreed to before, after, or concurrently
                            with these Terms), contain the full understanding with respect to your use and access of the
                            Site and supersede all prior agreements, terms, conditions, and understandings, both written
                            and oral, with respect to such use and access of the Site.
                        </Typography>
                        <Typography gutterBottom>13.2 Assignment</Typography>
                        <Typography gutterBottom>
                            You may not transfer any rights or obligations you may have to your Account or under these
                            Terms of Use without our prior written consent. We may transfer our rights under these Terms
                            of Use without your consent.
                        </Typography>
                        <Typography gutterBottom>13.3 Invalidity; Unenforceability</Typography>
                        <Typography gutterBottom>
                            If any portion of these Terms of Use is held to be invalid or unenforceable, the invalid or
                            unenforceable portion shall be modified in accordance with the applicable law as nearly as
                            possible to reflect the original intention of the applicable provision, and the remainder of
                            these Terms of Use shall remain in full force and effect.
                        </Typography>
                        <Typography gutterBottom>13.4 Admissibility</Typography>
                        <Typography gutterBottom>
                            A printed version of these Terms of Use and of any notices given to you in electronic form
                            shall be admissible in judicial or administrative proceedings based upon or relating to
                            these Terms to the same extent and subject to the same conditions as other business
                            documents and records originally generated and maintained in printed form.
                        </Typography>
                        <Typography gutterBottom>13.5 Waiver</Typography>
                        <Typography gutterBottom>
                            The failure of Field to Market to insist upon or enforce strict performance by you of any
                            provision of these Terms of Use shall not be construed as a waiver of any provision or
                            right.
                        </Typography>
                        <Typography gutterBottom>13.6 Nonexclusive Remedies</Typography>
                        <Typography gutterBottom>
                            None of Field to Market’s rights or remedies conferred by these Terms of Use are exclusive
                            of any other right or remedy conferred herein or by law or in equity; rather, all of such
                            rights and remedies are cumulative of every other such right or remedy and may be exercised
                            concurrently or separately from time-to-time.
                        </Typography>
                        <Typography gutterBottom>13.7 Notice</Typography>
                        <Typography gutterBottom>
                            We may provide notice to you relating to the Site and/or these Terms of Use by any
                            reasonable means including, without limitation, sending an email to your last known email
                            address, and any such email notice shall be deemed given and received on the day it is sent.
                        </Typography>
                        <Typography gutterBottom>13.8 Time Limitation</Typography>
                        <Typography gutterBottom>
                            You agree that any cause of action that you may desire to bring arising out of or related to
                            these Terms of Use and/or the Site must commence within one (1) year after the cause of
                            action arises; otherwise, such cause of action shall be permanently barred.
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <Privacy dialogOpen={privacyOpen} closeEvent={this.closePrivacy} />
            </Dialog>
        );
    }
}

export default withStyles(styles)(Terms);
