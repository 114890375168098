import orm, { ReferenceData, Model } from "../../../common/orm";
import { fk } from "redux-orm";

export class Fertilizer extends Model {
    static get fields() {
        return {
            activity: fk("FieldActivity", "fertilizers"),
        };
    }
    static get modelName() {
        return "Fertilizer";
    }
}
orm.register(Fertilizer);

export class ActivityIrrigationWaterUseEvent extends Model {
    static get fields() {
        return {
            activity: fk("FieldActivity", "wateruseevent"),
        };
    }
    static get modelName() {
        return "ActivityIrrigationWaterUseEvent";
    }
}
orm.register(ActivityIrrigationWaterUseEvent);

export class SeedingRates extends ReferenceData {
    static get modelName() {
        return "SeedingRates";
    }
}
orm.register(SeedingRates);

export class DryingSystems extends ReferenceData {
    static get modelName() {
        return "DryingSystems";
    }
}
orm.register(DryingSystems);

export class ApplicationTimes extends ReferenceData {
    static get modelName() {
        return "ApplicationTimes";
    }
}
orm.register(ApplicationTimes);

export class DominantApplicationMethods extends ReferenceData {
    static get modelName() {
        return "DominantApplicationMethods";
    }
}
orm.register(DominantApplicationMethods);

export class Fertilizers extends ReferenceData {
    static get modelName() {
        return "Fertilizers";
    }
}
orm.register(Fertilizers);

export class IrrigationMethods extends ReferenceData {
    static get modelName() {
        return "IrrigationMethods";
    }
}
orm.register(IrrigationMethods);

export class PumpPressures extends ReferenceData {
    static get modelName() {
        return "PumpPressures";
    }
}
orm.register(PumpPressures);

export class PumpingDepths extends ReferenceData {
    static get modelName() {
        return "PumpingDepths";
    }
}
orm.register(PumpingDepths);

export class FuelTypes extends ReferenceData {
    static get modelName() {
        return "FuelTypes";
    }
}
orm.register(FuelTypes);

export class AbandonmentReasons extends ReferenceData {
    static get modelName() {
        return "AbandonmentReasons";
    }
}
orm.register(AbandonmentReasons);

export class EnergySources extends ReferenceData {
    static get modelName() {
        return "EnergySources";
    }
}
orm.register(EnergySources);

export class AlfalfaHarvestTypes extends ReferenceData {
    static get modelName() {
        return "AlfalfaHarvestTypes";
    }
}
orm.register(AlfalfaHarvestTypes);

export class RiceCultivars extends ReferenceData {
    static get modelName() {
        return "RiceCultivars";
    }
}
orm.register(RiceCultivars);

export class RicePlantingMethods extends ReferenceData {
    static get modelName() {
        return "RicePlantingMethods";
    }
}
orm.register(RicePlantingMethods);

export class CottonMoisture extends ReferenceData {
    static get modelName() {
        return "CottonMoisture";
    }
}
orm.register(CottonMoisture);

export class WaterSources extends ReferenceData {
    static get modelName() {
        return "WaterSources";
    }
}
orm.register(WaterSources);

export class ManureTypes extends ReferenceData {
    static get modelName() {
        return "ManureTypes";
    }
}
orm.register(ManureTypes);

export class AlfalfaDryingSystems extends ReferenceData {
    static get modelName() {
        return "AlfalfaDryingSystems";
    }
}
orm.register(AlfalfaDryingSystems);

export class ManureAmounts extends ReferenceData {
    static get modelName() {
        return "ManureAmounts";
    }
}
orm.register(ManureAmounts);

export class OrganicFertTypes extends ReferenceData {
    static get modelName() {
        return "OrganicFertTypes";
    }
}
orm.register(OrganicFertTypes);

export class RiceYieldMethods extends ReferenceData {
    static get modelName() {
        return "RiceYieldMethods";
    }
}
orm.register(RiceYieldMethods);
