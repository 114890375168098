// https://reactrouter.com/en/main/start/faq

import { useLocation, useNavigate, useParams } from "react-router-dom";

export default function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        const history = {
                push: navigate,
            },
            match = { params };
        return <Component {...props} router={{ location, navigate, params }} history={history} match={match} />;
    }

    return ComponentWithRouterProp;
}
