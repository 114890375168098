import * as utils from "./CropManagementUtils";
import { csipFetch } from "../../api/fetch";

export const ROTATION_MGMT_SEARCH_PENDING = "ROTATION_MGMT_SEARCH_PENDING";
export const ROTATION_MGMT_SEARCH_SUCCESS = "ROTATION_MGMT_SEARCH_SUCCESS";
export const ROTATION_MGMT_SEARCH_ERROR = "ROTATION_MGMT_SEARCH_ERROR";

export function rotationMgmtSearchPending() {
    return {
        type: ROTATION_MGMT_SEARCH_PENDING,
    };
}

export function rotationMgmtSearchSuccess(results) {
    return {
        type: ROTATION_MGMT_SEARCH_SUCCESS,
        results: results,
    };
}

export function rotationMgmtSearchError(error) {
    return {
        type: ROTATION_MGMT_SEARCH_ERROR,
        error: error,
    };
}

export function rotationMgmtSearch(values) {
    return function (dispatch) {
        let search_parameters = values.search;
        let filter_parameters = values.filter;
        if (!search_parameters.keyword_lists && !search_parameters.keyword_string) {
            dispatch(rotationMgmtSearchSuccess([]));
            return;
        }
        dispatch(rotationMgmtSearchPending());

        //  get request(s) info based on the search input
        let request_infos = [];
        if (search_parameters.searchType === "0") {
            search_parameters.keyword_lists.forEach((keywords) => {
                search_parameters.cmz.forEach((cmz) => {
                    request_infos.push({
                        parameter: [
                            {
                                name: "file_key",
                                value: "",
                            },
                            {
                                name: "format",
                                value: "",
                            },
                            {
                                name: "keywords",
                                value: keywords,
                            },
                            {
                                name: "cmz",
                                value: cmz,
                            },
                            {
                                name: "stir_min",
                                value: search_parameters.stirMin ? search_parameters.stirMin : "",
                            },
                            {
                                name: "stir_max",
                                value: search_parameters.stirMax ? search_parameters.stirMax : "",
                            },
                            {
                                name: "limit",
                                value: "all",
                            },
                            {
                                name: "offset",
                                value: "0",
                            },
                        ],
                    });
                });
            });
        } else {
            if (search_parameters.management_ids) {
                search_parameters.management_ids.forEach((id) => {
                    request_infos.push({
                        parameter: [
                            {
                                name: "id",
                                value: [id],
                            },
                        ],
                    });
                });
            } else {
                request_infos.push({
                    parameter: [
                        {
                            name: "format",
                            value: "",
                        },
                        {
                            name: "keywords",
                            value: search_parameters.keyword_string,
                        },
                        {
                            name: "limit",
                            value: "all",
                        },
                        {
                            name: "offset",
                            value: "0",
                        },
                    ],
                });
            }
        }

        //  get promises for the search input
        var promises = request_infos.map((request_info) => {
            return csipFetch("/csip-crlmod/d/management/5.0", { body: JSON.stringify(request_info) })
                .then((response) => response.json())
                .catch(() => {
                    console.log("  --> Request: Error");
                    return dispatch(rotationMgmtSearchError());
                });
        });

        //  process the results
        Promise.all(promises)
            .then((jsonResponses) => {
                let managements = [];
                console.log(jsonResponses);
                jsonResponses.forEach(function (r) {
                    let status = r.metainfo.status;
                    if (status === "Finished") {
                        managements.push(...r.result[0].value.rotations);
                    }
                });

                managements = utils.UpdateManagementData(managements);
                managements = utils.removeDuplicateManagements(managements);
                managements = utils.addDurationToSearchResults(managements);

                if (search_parameters.searchType === "0") {
                    managements = utils.FilterManagementsData(managements, filter_parameters, values.crlmodCrops);
                }

                dispatch(rotationMgmtSearchSuccess(managements));
            })
            .catch((e) => {
                console.log("  --> Request: Error");
                console.log(e);
                return dispatch(rotationMgmtSearchError());
            });
    };
}
