import React, { Component } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "../common/GridWrapper";
import Typography from "../common/TypographyWrapper";
import Button from "../common/ButtonWrapper";
import { Link } from "react-router-dom";
import withStyles from "@mui/styles/withStyles";

const styles = {
    root: {
        height: "90%",
    },
    message: {
        textAlign: "center",
    },
    error: {
        fontSize: "1.5em",
        color: "red",
    },
};

function NotReadyWrapper({ children, ...rest }) {
    let title;
    if (children) {
        const root = React.Children.toArray(children)[0],
            firstChild = root && root.props && root.props.children && root.props.children[0];
        if (firstChild && firstChild.props && firstChild.props.title) {
            title = firstChild;
        }
    }
    if (title) {
        return (
            <>
                <Grid container spacing={24}>
                    {title}
                </Grid>
                <NotReady {...rest} />
            </>
        );
    } else {
        return <NotReady {...rest} />;
    }
}

class NotReady extends Component {
    render() {
        const { classes, storeReady, message } = this.props;
        return (
            <Grid container className={classes.root} justifyContent="center" alignItems="center">
                <Grid item className={classes.message}>
                    {!storeReady && (
                        // While store is still initializing, don't assume user isn't logged in.
                        <>
                            <CircularProgress size={100} thickness={1.5} />
                            <Typography>{message ? message : "Loading Application..."}</Typography>
                        </>
                    )}
                    {storeReady && (
                        <>
                            <Typography className={classes.error} gutterBottom>
                                You must be logged in to view this page.
                            </Typography>
                            <Button component={Link} to="/" variant="raised" color="primary">
                                Return to Home
                            </Button>
                        </>
                    )}
                </Grid>
            </Grid>
        );
    }
}

NotReady = withStyles(styles)(NotReady);

export default NotReadyWrapper;
