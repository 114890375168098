import React, { Component } from "react";
import withRouter from "../common/withRouter";

import AppContainer from "../common/AppContainer";
import LoginPage from "../auth/LoginPage";
import Terms from "../auth/Terms";

import Grid from "../common/GridWrapper";
import Typography from "../common/TypographyWrapper";
import withStyles from "@mui/styles/withStyles";
import classNames from "classnames";

import { connect } from "react-redux";
import * as authActions from "../auth/actions";
import * as navActions from "../common/actions";
import { Message } from "./models";

const SUPPORT = "https://support.fieldtomarket.org/";

const getMaintenance = Message.selectById("maintenance"),
    getNotice = Message.selectById("notice");

const styles = {
    marginTopMain: {
        marginTop: 24,
    },
    marginBottomMain: {
        marginBottom: 22,
    },
    loginHeaders: {
        fontSize: 30,
        fontWeight: 100,
        marginBottom: 8,
    },
    marginBottom: {
        height: "4.25em",
        marginBottom: 20,
    },
    marginBottomSmall: {
        marginBottom: 15,
    },
    marginTop: {
        marginTop: 20,
    },
    marginBottomButton: {
        marginBottom: 12,
    },
    leftDivide: {
        paddingRight: 32,
        borderRight: "2px solid #666666",
    },
    nowrap: {
        flexWrap: "nowrap",
    },
    rightDivide: {
        paddingLeft: 32,
    },
    alertBox: {
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 16,
        paddingRight: 16,
        marginBottom: 16,
        marginTop: 16,
        width: "100%",
        borderRadius: "0.25em",
    },
    maintenance: {
        border: "1px solid #ccb",
        backgroundColor: "#FFFFE0",
        padding: 20,
    },
    linkColor: {
        color: "rgb(255, 125, 50)",
        cursor: "pointer",
        textDecoration: "underline",
    },
    section: {
        backgroundColor: "#f5f5f5",
        padding: 20,
    },
    started: {
        fontSize: 22,
        font: "medium roboto slab",
        color: "black",
        marginBottom: 3,
    },
    welcome: {
        fontSize: 30,
        font: "medium roboto slab",
        color: "black",
    },
    ptfont: {
        fontSize: 20,
    },
};

class HomePage extends Component {
    state = {
        termsOpen: false,
    };

    openTerms = () => {
        this.setState({ termsOpen: true });
    };

    closeTerms = () => {
        this.setState({ termsOpen: false });
    };

    render() {
        const { classes, maintenance, notice } = this.props;
        const { termsOpen } = this.state;
        return (
            <AppContainer homePage={true}>
                <Grid container spacing={40} justifyContent="center" className={classes.marginTopMain}>
                    <Grid item xs={12} md={8} lg={6} xl={5}>
                        <Typography variant="title" className={classes.welcome} gutterBottom>
                            <b>Welcome!</b>
                        </Typography>
                        <Typography variant="body1" className={classNames(classes.ptfont, classes.marginBottomMain)}>
                            The Fieldprint® Platform is a pioneering assessment framework that empowers brands,
                            retailers, suppliers and farmers at every stage in their sustainability journey, to measure
                            the environmental impacts of commodity crop production and identify opportunities for
                            continuous improvement.
                        </Typography>
                        {maintenance && (
                            <Grid container className={classNames(classes.nowrap, classes.marginBottomMain)}>
                                <Grid item xs={12} className={classes.maintenance}>
                                    <Typography variant="body1">{maintenance}</Typography>
                                </Grid>
                            </Grid>
                        )}
                        {!maintenance && this.renderOptions()}
                        {notice && (
                            <Grid
                                container
                                className={classNames(classes.nowrap, classes.marginTop, classes.marginBottomMain)}
                            >
                                <Grid item xs={12} className={classes.section}>
                                    <Typography variant="title" className={classes.started}>
                                        Announcements
                                    </Typography>
                                    <Typography variant="body1">{notice}</Typography>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <Terms dialogOpen={termsOpen} closeEvent={this.closeTerms} />
            </AppContainer>
        );
    }

    renderOptions() {
        const { classes } = this.props;
        return (
            <Grid container className={classes.nowrap}>
                <Grid item xs={12} className={classes.section}>
                    <Typography variant="title" className={classes.started}>
                        Get Started
                    </Typography>
                    <Typography variant="body1" className={classes.marginBottomSmall}>
                        Use of the Calculator is free and data is kept confidential. Login, register for an account or
                        explore the features as a guest. By Continuing with any of these options, you agree to the{" "}
                        <span className={classNames(classes.linkColor)} onClick={this.openTerms}>
                            Terms of Use
                        </span>
                        .
                    </Typography>
                    <LoginPage />
                    <Typography variant="body1" className={classes.marginTop}>
                        Need Help? Visit the{" "}
                        <a className={classes.linkColor} href={SUPPORT} target="_blank" rel="noopener noreferrer">
                            Support Portal
                        </a>{" "}
                        or contact us at{" "}
                        <a className={classes.linkColor} href="mailto:support@fieldtomarket.org">
                            support@fieldtomarket.org
                        </a>
                        . Password reset is available from the login page.
                    </Typography>
                </Grid>
            </Grid>
        );
    }
}

HomePage = connect(
    (state) => ({
        authState: state.auth,
        notice: getNotice(state),
        maintenance: getMaintenance(state),
    }),
    {
        ...authActions,
        ...navActions,
    },
)(HomePage);

export default withStyles(styles)(withRouter(HomePage));
