import React, { Component } from "react";
import { Form } from "react-form";
import { connect } from "react-redux";

import withStyles from "@mui/styles/withStyles";
import Grid from "../../common/GridWrapper";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import CheckboxGroup from "../../common/CheckboxGroup";

import CropTable from "./CropTable";
import AddCropDialog from "./dialogs/AddCropDialog";
import LandQuestionsDialog from "./dialogs/LandQuestionsDialog";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Button from "../../common/ButtonWrapper";

import Select from "../../common/Select";
import TextField from "../../common/TextField";
import { MAKE_OPTIONS, YES_NO_OPTIONS } from "../../../api/constants";
import Typography from "../../common/TypographyWrapper";

import { GET_HAS_WHAT_2, GET_DEFAULT_SURVEY_VALUES, VALIDATION, land_types } from "./constants";

import { getValue } from "../../../api/utils";

import {
    RiparianVegetation,
    RiparianManagement,
    ForestConfiguration,
    ForestManagement,
    GrasslandConfiguration,
    GrazingIntensity,
    GrasslandManagement,
    NaturalWetlandRegime,
    WetlandManagement,
    SurfaceWaterBufferPercentage,
    SurfaceWaterRiparianVegetation,
    BankStability,
    BankManagement,
    SurfaceWaterChannelization,
    SurfaceWaterInstreamHabitat,
    SurfaceWaterPredominantUse,
    SurfaceWaterAverageDepth,
    SurfaceWaterManagement,
} from "./models";

import {
    FarmHpiSurvey,
    FarmHpiCultivatedLandQuestionnaire,
    FarmHpiForestQuestionnaire,
    FarmHpiGrasslandQuestionnaire,
    FarmHpiRiparianQuestionnaire,
    FarmHpiSurfaceWaterQuestionnaire,
    FarmHpiWetlandQuestionnaire,
} from "../models";

const allRiparianVegetation = RiparianVegetation.selectAll();
const allRiparianManagement = RiparianManagement.selectAll();
const allForestConfiguration = ForestConfiguration.selectAll();
const allForestManagement = ForestManagement.selectAll();
const allGrasslandConfiguration = GrasslandConfiguration.selectAll();
const allGrazingIntensity = GrazingIntensity.selectAll();
const allGrasslandManagement = GrasslandManagement.selectAll();
const allNaturalWetlandRegime = NaturalWetlandRegime.selectAll();
const allWetlandManagement = WetlandManagement.selectAll();
const allSurfaceWaterBufferPercentage = SurfaceWaterBufferPercentage.selectAll();
const allSurfaceWaterRiparianVegetation = SurfaceWaterRiparianVegetation.selectAll();
const allBankStability = BankStability.selectAll();
const allBankManagement = BankManagement.selectAll();
const allSurfaceWaterChannelization = SurfaceWaterChannelization.selectAll();
const allSurfaceWaterInstreamHabitat = SurfaceWaterInstreamHabitat.selectAll();
const allSurfaceWaterPredominantUse = SurfaceWaterPredominantUse.selectAll();
const allSurfaceWaterAverageDepth = SurfaceWaterAverageDepth.selectAll();
const allSurfaceWaterManagement = SurfaceWaterManagement.selectAll();

const styles = (theme) => ({
    rightAlign: {
        textAlign: "right",
    },
    button: {
        marginRight: theme.spacing(1),
    },
    centerAlign: {
        textAlign: "center",
    },
    indent: {
        marginLeft: 24,
    },
    redText: {
        color: "red",
    },
    optionsContainer: {
        display: "flex",
    },
    expansionPannelSummary: {
        backgroundColor: "#ebebeb",
    },
});

class LandTypeQuestionaire extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            crop_name: null,
            addCropDialogOpen: false,
            landQuestionsDialogOpen: false,
            missing_cultivated_lands: false,
            draftClicked: false,
            submitClicked: false,
            cultivated_land_crop: null,
            cultivated_land_questionnaire_id: null,
        };
    }

    getCropTableData = () => {
        let rows = [];

        this.props.farmHpiSurvey.cultivated.forEach((q) => {
            rows.push({
                id: q.id,
                crop_name: this.props.allCrops.filter((c) => c.id === q.crop_id)[0]["name"],
                plantable_acres: q.plantable_area === null ? "---" : parseFloat(q.plantable_area),
                status: q.completed === true ? "Complete" : "Incomplete",
            });
        });
        return rows;
    };

    handleEditCultivatedLandsQuestionnaire = (id) => {
        this.setState({
            cultivated_land_questionnaire_id: id,
            landQuestionsDialogOpen: true,
        });
    };

    handleDeleteCultivatedLandsQuestionnaire = (id) => {
        this.props.ormFarmHpiSurveyUpdate({ id: this.props.farmHpiSurvey.id, results: null });
        this.props.ormFarmHpiCultivatedLandQuestionnaireDelete(id);
    };

    checkNoneOfTheAbove = (values, path, data) => {
        console.log("--> checkNoneOfTheAbove");

        var none_value = data.filter((d) => d.name.toLowerCase() === "none of the above")[0]["id"];

        if (values.length === 1 && values[0] === none_value) {
            //  do nothing
        } else if (values.length > 0 && values[0] === none_value) {
            values.shift();
        } else if (values.length > 0 && values[values.length - 1] === none_value) {
            values = [none_value];
        }

        this.formApi.setValue(path, values);
    };

    getGroupHasConverted = (values, type) => {
        if (Object.keys(values).indexOf(type) === -1) {
            return false;
        }

        var hasConverted = false;

        Object.keys(values[type]).forEach((k) => {
            if (values[type][k]["area_converted"] && parseFloat(values[type][k]["area_converted"]) > 0) {
                hasConverted = true;
            }
        });

        console.log(values);
        return hasConverted;
    };

    getHasConverted = (values, subPath) => {
        var area_converted = getValue(values, [subPath + ".area_converted"]);
        if (area_converted && parseFloat(area_converted) > 0) {
            return true;
        }
        return false;
    };

    handleValueChange = (e, path) => {
        console.log("--> handleValueChange");
        this.props.handleValueChange(e.target.value, path);
    };

    errorValidator = (values, has_what, hard_require) => {
        console.log("--> errorValidator");

        let validation = VALIDATION(values, has_what, hard_require, this.props, false, false, this.state.submitClicked);

        return validation;
    };

    updateSurvey = (values, has_what) => {
        console.log("--> updateSurvey");

        var isCompleted = VALIDATION(values, has_what, false, this.props.farmHpiSurvey, false, true, false);

        let promises = [];

        //  farm hpi survey
        promises.push(this.props.ormFarmHpiSurveyUpdate({ id: this.props.farmHpiSurvey.id, results: null }));

        // riparian
        if (has_what.has_edge === true || has_what.has_riparian === true) {
            try {
                var r = { id: this.props.farmHpiSurvey.buffer.id };

                r["edge_area"] = getValue(values, "buffer.edge.area");
                r["edge_area_converted"] = getValue(values, "buffer.edge.area_converted");
                r["edge_previous_land_cover"] =
                    parseFloat(getValue(values, "buffer.edge.area_converted")) <= 0
                        ? null
                        : getValue(values, "buffer.edge.previous_land_cover");
                r["edge_vegetated"] = getValue(values, "buffer.edge.composition");
                r["edge_management"] = getValue(values, "buffer.edge.management").join(",");

                r["riparian_area"] = getValue(values, "buffer.riparian.area");
                r["riparian_area_converted"] = getValue(values, "buffer.riparian.area_converted");
                r["riparian_previous_land_cover"] =
                    parseFloat(getValue(values, "buffer.edge.area_converted")) <= 0
                        ? null
                        : getValue(values, "buffer.riparian.previous_land_cover");
                r["riparian_vegetated"] = getValue(values, "buffer.riparian.composition");
                r["riparian_management"] = getValue(values, "buffer.riparian.management").join(",");

                r["completed"] = isCompleted["buffer"][0] === 0;
                r["missing"] = isCompleted["buffer"][0];
                r["required"] = isCompleted["buffer"][1];

                console.log(r);
                promises.push(this.props.ormFarmHpiRiparianQuestionnaireUpdate(r));
            } catch (e) {
                console.log(e);
            }
        }

        //  forest
        if (
            has_what.has_conifer === true ||
            has_what.has_deciduous === true ||
            has_what.has_evergreen === true ||
            has_what.has_mixed === true
        ) {
            var f = { id: this.props.farmHpiSurvey.forest.id };

            f["conifer_area"] = getValue(values, "forest.conifer.area");
            f["conifer_area_converted"] = getValue(values, "forest.conifer.area_converted");
            f["conifer_previous_land_cover"] =
                parseFloat(getValue(values, "forest.conifer.area_converted")) <= 0
                    ? null
                    : getValue(values, "forest.conifer.previous_land_cover");
            f["conifer_predominant_configuration"] = getValue(values, "forest.conifer.configuration");
            f["conifer_management"] = getValue(values, "forest.conifer.riparian_management").join(",");

            f["deciduous_area"] = getValue(values, "forest.deciduous.area");
            f["deciduous_area_converted"] = getValue(values, "forest.deciduous.area_converted");
            f["deciduous_previous_land_cover"] =
                parseFloat(getValue(values, "forest.deciduous.area_converted")) <= 0
                    ? null
                    : getValue(values, "forest.deciduous.previous_land_cover");
            f["deciduous_predominant_configuration"] = getValue(values, "forest.deciduous.configuration");
            f["deciduous_management"] = getValue(values, "forest.deciduous.riparian_management").join(",");

            f["evergreen_area"] = getValue(values, "forest.evergreen.area");
            f["evergreen_area_converted"] = getValue(values, "forest.evergreen.area_converted");
            f["evergreen_previous_land_cover"] =
                parseFloat(getValue(values, "forest.evergreen.area_converted")) <= 0
                    ? null
                    : getValue(values, "forest.evergreen.previous_land_cover");
            f["evergreen_predominant_configuration"] = getValue(values, "forest.evergreen.configuration");
            f["evergreen_management"] = getValue(values, "forest.evergreen.riparian_management").join(",");

            f["mixed_area"] = getValue(values, "forest.mixed.area");
            f["mixed_area_converted"] = getValue(values, "forest.mixed.area_converted");
            f["mixed_previous_land_cover"] =
                parseFloat(getValue(values, "forest.mixed.area_converted")) <= 0
                    ? null
                    : getValue(values, "forest.mixed.previous_land_cover");
            f["mixed_predominant_configuration"] = getValue(values, "forest.mixed.configuration");
            f["mixed_management"] = getValue(values, "forest.mixed.riparian_management").join(",");

            f["completed"] = isCompleted["forest"][0] === 0;
            f["missing"] = isCompleted["forest"][0];
            f["required"] = isCompleted["forest"][1];

            promises.push(this.props.ormFarmHpiForestQuestionnaireUpdate(f));
        }

        //  grassland
        if (
            has_what.has_native_grazed === true ||
            has_what.has_native_ungrazed === true ||
            has_what.has_nonnative_grazed === true ||
            has_what.has_nonnative_ungrazed === true
        ) {
            var g = { id: this.props.farmHpiSurvey.grassland.id };

            g["native_grazed_area"] = getValue(values, "grassland.native_grazed.area");
            g["native_grazed_area_converted"] = getValue(values, "grassland.native_grazed.area_converted");
            g["native_grazed_previous_land_cover"] =
                parseFloat(getValue(values, "grassland.native_grazed.area_converted")) <= 0
                    ? null
                    : getValue(values, "grassland.native_grazed.previous_land_cover");
            g["native_grazed_configuration"] = getValue(values, "grassland.native_grazed.configuration");
            g["native_grazed_grazing_intensity"] = getValue(values, "grassland.native_grazed.grazing_intensity");
            g["native_grazed_grazing_conservation"] = getValue(values, "grassland.native_grazed.grazing_conservation");
            g["native_grazed_management"] = getValue(values, "grassland.native_grazed.management").join(",");

            g["native_ungrazed_area"] = getValue(values, "grassland.native_ungrazed.area");
            g["native_ungrazed_area_converted"] = getValue(values, "grassland.native_ungrazed.area_converted");
            g["native_ungrazed_previous_land_cover"] =
                parseFloat(getValue(values, "grassland.native_ungrazed.area_converted")) <= 0
                    ? null
                    : getValue(values, "grassland.native_ungrazed.previous_land_cover");
            g["native_ungrazed_configuration"] = getValue(values, "grassland.native_ungrazed.configuration");
            g["native_ungrazed_management"] = getValue(values, "grassland.native_ungrazed.management").join(",");

            g["nonnative_grazed_area"] = getValue(values, "grassland.nonnative_grazed.area");
            g["nonnative_grazed_area_converted"] = getValue(values, "grassland.nonnative_grazed.area_converted");
            g["nonnative_grazed_previous_land_cover"] =
                parseFloat(getValue(values, "grassland.nonnative_grazed.area_converted")) <= 0
                    ? 0
                    : getValue(values, "grassland.nonnative_grazed.previous_land_cover");
            g["nonnative_grazed_configuration"] = getValue(values, "grassland.nonnative_grazed.configuration");
            g["nonnative_grazed_grazing_intensity"] = getValue(values, "grassland.nonnative_grazed.grazing_intensity");
            g["nonnative_grazed_grazing_conservation"] = getValue(
                values,
                "grassland.nonnative_grazed.grazing_conservation",
            );
            g["nonnative_grazed_management"] = getValue(values, "grassland.nonnative_grazed.management").join(",");

            g["nonnative_ungrazed_area"] = getValue(values, "grassland.nonnative_ungrazed.area");
            g["nonnative_ungrazed_area_converted"] = getValue(values, "grassland.nonnative_ungrazed.area_converted");
            g["nonnative_ungrazed_previous_land_cover"] =
                parseFloat(getValue(values, "grassland.nonnative_ungrazed.area_converted")) <= 0
                    ? null
                    : getValue(values, "grassland.nonnative_ungrazed.previous_land_cover");
            g["nonnative_ungrazed_configuration"] = getValue(values, "grassland.nonnative_ungrazed.configuration");
            g["nonnative_ungrazed_management"] = getValue(values, "grassland.nonnative_ungrazed.management").join(",");

            g["completed"] = isCompleted["grassland"][0] === 0;
            g["missing"] = isCompleted["grassland"][0];
            g["required"] = isCompleted["grassland"][1];

            promises.push(this.props.ormFarmHpiGrasslandQuestionnaireUpdate(g));
        }

        //  surface water
        if (
            has_what.has_streams === true ||
            has_what.has_rivers === true ||
            has_what.has_ponds_natural === true ||
            has_what.has_ponds_artificial === true ||
            has_what.has_lakes_natural === true ||
            has_what.has_lakes_artificial === true
        ) {
            var s = { id: this.props.farmHpiSurvey.surfacewater.id };

            //  stream
            s["stream_length"] = getValue(values, "surfacewater.stream.length");
            s["stream_riparian"] = getValue(values, "surfacewater.stream.riparian");
            s["stream_riparian_vegetation"] = getValue(values, "surfacewater.stream.riparian_vegetation");
            s["stream_riparian_undisturbed"] = getValue(values, "surfacewater.stream.riparian_undisturbed");
            s["stream_riparian_limited_cattle_access"] = getValue(
                values,
                "surfacewater.stream.riparian_limited_cattle_access",
            );
            s["stream_riparian_unlimited_cattle_access"] = getValue(
                values,
                "surfacewater.stream.riparian_unlimited_cattle_access",
            );
            s["stream_riparian_crop"] = getValue(values, "surfacewater.stream.riparian_crop");
            s["stream_riparian_impervious"] = getValue(values, "surfacewater.stream.riparian_impervious");
            s["stream_bank_condition"] = getValue(values, "surfacewater.stream.bank_condition");
            s["stream_bank_management"] = getValue(values, "surfacewater.stream.bank_management");
            s["stream_modifications"] = getValue(values, "surfacewater.stream.modifications");
            s["stream_dams"] = getValue(values, "surfacewater.stream.dams");
            s["stream_instream_habitat"] = getValue(values, "surfacewater.stream.instream_habitat")
                .filter((v) => v !== "")
                .join(",");

            //  river
            s["river_length"] = getValue(values, "surfacewater.river.length");
            s["river_riparian"] = getValue(values, "surfacewater.river.riparian");
            s["river_riparian_vegetation"] = getValue(values, "surfacewater.river.riparian_vegetation");
            s["river_riparian_undisturbed"] = getValue(values, "surfacewater.river.riparian_undisturbed");
            s["river_riparian_limited_cattle_access"] = getValue(
                values,
                "surfacewater.river.riparian_limited_cattle_access",
            );
            s["river_riparian_unlimited_cattle_access"] = getValue(
                values,
                "surfacewater.river.riparian_unlimited_cattle_access",
            );
            s["river_riparian_crop"] = getValue(values, "surfacewater.river.riparian_crop");
            s["river_riparian_impervious"] = getValue(values, "surfacewater.river.riparian_impervious");
            s["river_bank_condition"] = getValue(values, "surfacewater.river.bank_condition");
            s["river_bank_management"] = getValue(values, "surfacewater.river.bank_management");
            s["river_modifications"] = getValue(values, "surfacewater.river.modifications");
            s["river_dams"] = getValue(values, "surfacewater.river.dams");
            s["river_instream_habitat"] = getValue(values, "surfacewater.river.instream_habitat")
                .filter((v) => v !== "")
                .join(",");

            //  pond natural
            s["pond_natural_area"] = getValue(values, "surfacewater.pond_natural.area");
            s["pond_natural_riparian"] = getValue(values, "surfacewater.pond_natural.riparian");
            s["pond_natural_riparian_vegetation"] = getValue(values, "surfacewater.pond_natural.riparian_vegetation");
            s["pond_natural_riparian_undisturbed"] = getValue(values, "surfacewater.pond_natural.riparian_undisturbed");
            s["pond_natural_riparian_limited_cattle_access"] = getValue(
                values,
                "surfacewater.pond_natural.riparian_limited_cattle_access",
            );
            s["pond_natural_riparian_unlimited_cattle_access"] = getValue(
                values,
                "surfacewater.pond_natural.riparian_unlimited_cattle_access",
            );
            s["pond_natural_riparian_crop"] = getValue(values, "surfacewater.pond_natural.riparian_crop");
            s["pond_natural_riparian_impervious"] = getValue(values, "surfacewater.pond_natural.riparian_impervious");
            s["pond_natural_bank_condition"] = getValue(values, "surfacewater.pond_natural.bank_condition");
            s["pond_natural_bank_management"] = getValue(values, "surfacewater.pond_natural.bank_management");
            s["pond_natural_predominant_use"] = getValue(values, "surfacewater.pond_natural.predominant_use");
            s["pond_natural_average_depth"] = getValue(values, "surfacewater.pond_natural.average_depth");
            s["pond_natural_management"] = getValue(values, "surfacewater.pond_natural.management").join(",");

            //  pond artificial
            s["pond_artificial_area"] = getValue(values, "surfacewater.pond_artificial.area");
            s["pond_artificial_riparian"] = getValue(values, "surfacewater.pond_artificial.riparian");
            s["pond_artificial_riparian_vegetation"] = getValue(
                values,
                "surfacewater.pond_artificial.riparian_vegetation",
            );
            s["pond_artificial_riparian_undisturbed"] = getValue(
                values,
                "surfacewater.pond_artificial.riparian_undisturbed",
            );
            s["pond_artificial_riparian_limited_cattle_access"] = getValue(
                values,
                "surfacewater.pond_artificial.riparian_limited_cattle_access",
            );
            s["pond_artificial_riparian_unlimited_cattle_access"] = getValue(
                values,
                "surfacewater.pond_artificial.riparian_unlimited_cattle_access",
            );
            s["pond_artificial_riparian_crop"] = getValue(values, "surfacewater.pond_artificial.riparian_crop");
            s["pond_artificial_riparian_impervious"] = getValue(
                values,
                "surfacewater.pond_artificial.riparian_impervious",
            );
            s["pond_artificial_bank_condition"] = getValue(values, "surfacewater.pond_artificial.bank_condition");
            s["pond_artificial_bank_management"] = getValue(values, "surfacewater.pond_artificial.bank_management");
            s["pond_artificial_predominant_use"] = getValue(values, "surfacewater.pond_artificial.predominant_use");
            s["pond_artificial_average_depth"] = getValue(values, "surfacewater.pond_artificial.average_depth");
            s["pond_artificial_management"] = getValue(values, "surfacewater.pond_artificial.management").join(",");

            //  lake natural
            s["lake_natural_area"] = getValue(values, "surfacewater.lake_natural.area");
            s["lake_natural_riparian"] = getValue(values, "surfacewater.lake_natural.riparian");
            s["lake_natural_riparian_vegetation"] = getValue(values, "surfacewater.lake_natural.riparian_vegetation");
            s["lake_natural_riparian_undisturbed"] = getValue(values, "surfacewater.lake_natural.riparian_undisturbed");
            s["lake_natural_riparian_limited_cattle_access"] = getValue(
                values,
                "surfacewater.lake_natural.riparian_limited_cattle_access",
            );
            s["lake_natural_riparian_unlimited_cattle_access"] = getValue(
                values,
                "surfacewater.lake_natural.riparian_unlimited_cattle_access",
            );
            s["lake_natural_riparian_crop"] = getValue(values, "surfacewater.lake_natural.riparian_crop");
            s["lake_natural_riparian_impervious"] = getValue(values, "surfacewater.lake_natural.riparian_impervious");
            s["lake_natural_bank_condition"] = getValue(values, "surfacewater.lake_natural.bank_condition");
            s["lake_natural_bank_management"] = getValue(values, "surfacewater.lake_natural.bank_management");
            s["lake_natural_predominant_use"] = getValue(values, "surfacewater.lake_natural.predominant_use");
            s["lake_natural_average_depth"] = getValue(values, "surfacewater.lake_natural.average_depth");
            s["lake_natural_management"] = getValue(values, "surfacewater.lake_natural.management").join(",");
            s["lake_natural_predominant_use"] = getValue(values, "surfacewater.lake_natural.predominant_use");
            s["lake_natural_average_depth"] = getValue(values, "surfacewater.lake_natural.average_depth");
            s["lake_natural_management"] = getValue(values, "surfacewater.lake_natural.management").join(",");

            //  lake artificial
            s["lake_artificial_area"] = getValue(values, "surfacewater.lake_artificial.area");
            s["lake_artificial_riparian"] = getValue(values, "surfacewater.lake_artificial.riparian");
            s["lake_artificial_riparian_vegetation"] = getValue(
                values,
                "surfacewater.lake_artificial.riparian_vegetation",
            );
            s["lake_artificial_riparian_undisturbed"] = getValue(
                values,
                "surfacewater.lake_artificial.riparian_undisturbed",
            );
            s["lake_artificial_riparian_limited_cattle_access"] = getValue(
                values,
                "surfacewater.lake_artificial.riparian_limited_cattle_access",
            );

            s["lake_artificial_riparian_unlimited_cattle_access"] = getValue(
                values,
                "surfacewater.lake_artificial.riparian_unlimited_cattle_access",
            );
            s["lake_artificial_riparian_crop"] = getValue(values, "surfacewater.lake_artificial.riparian_crop");
            s["lake_artificial_riparian_impervious"] = getValue(
                values,
                "surfacewater.lake_artificial.riparian_impervious",
            );
            s["lake_artificial_bank_condition"] = getValue(values, "surfacewater.lake_artificial.bank_condition");
            s["lake_artificial_bank_management"] = getValue(values, "surfacewater.lake_artificial.bank_management");
            s["lake_artificial_predominant_use"] = getValue(values, "surfacewater.lake_artificial.predominant_use");
            s["lake_artificial_average_depth"] = getValue(values, "surfacewater.lake_artificial.average_depth");
            s["lake_artificial_management"] = getValue(values, "surfacewater.lake_artificial.management").join(",");

            s["completed"] = isCompleted["surfacewater"][0] === 0;
            s["missing"] = isCompleted["surfacewater"][0];
            s["required"] = isCompleted["surfacewater"][1];

            promises.push(this.props.ormFarmHpiSurfaceWaterQuestionnaireUpdate(s));
        }

        //  wetland
        if (has_what.has_wetland_natural === true || has_what.has_wetland_artificial) {
            var w = { id: this.props.farmHpiSurvey.wetland.id };

            w["natural_area"] = getValue(values, "wetland.natural.area");
            w["natural_area_converted"] = getValue(values, "wetland.natural.area_converted");
            w["natural_previous_land_cover"] =
                parseFloat(getValue(values, "wetland.natural.area_converted")) <= 0
                    ? null
                    : getValue(values, "wetland.natural.previous_land_cover");
            w["natural_management"] = getValue(values, "wetland.natural.management");
            w["natural_habitat_management"] = getValue(values, "wetland.natural.habitat_management").join(",");

            w["artificial_area"] = getValue(values, "wetland.artificial.area");
            w["artificial_area_converted"] = getValue(values, "wetland.artificial.area_converted");
            w["artificial_previous_land_cover"] =
                parseFloat(getValue(values, "wetland.artificial.area_converted")) <= 0
                    ? null
                    : getValue(values, "wetland.artificial.previous_land_cover");
            w["artificial_management"] = getValue(values, "wetland.artificial.management");
            w["artificial_habitat_management"] = getValue(values, "wetland.artificial.habitat_management").join(",");

            w["completed"] = isCompleted["wetland"][0] === 0;
            w["missing"] = isCompleted["wetland"][0];
            w["required"] = isCompleted["wetland"][1];

            promises.push(this.props.ormFarmHpiWetlandQuestionnaireUpdate(w));
        }

        Promise.all(promises).then((results) => {
            if (this.state.draftClicked) {
                this.props.history.push("/farm/" + this.props.farmHpiSurvey.farm + "/edit");
            } else {
                this.props.handleNext();
            }
        });
    };

    addCropDialogSaveClick = (id) => {
        console.log("-->  addCropDialogSaveClick");
        this.setState({
            addCropDialogOpen: false,
            landQuestionsDialogOpen: true,
            cultivated_land_questionnaire_id: id,
        });
    };

    addCropDialogCancelClick = () => {
        this.setState({ addCropDialogOpen: false });
    };

    landQuestionsDialogCancelClick = () => {
        this.setState({
            crop_name: null,
            addCropDialogOpen: false,
            landQuestionsDialogOpen: false,
            cultivated_land_questionnaire_id: null,
        });
    };

    landQuestionsDialogSaveClick = () => {
        console.log("landQuestionsDialogSaveClick");

        this.setState({
            landQuestionsDialogOpen: false,
            cultivated_land_questionnaire_id: null,
        });
    };

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    get_section_styling = (section, has_what) => {
        let factor = 0;
        let factor_options = "";
        let factor_question = "";
        let option_width = "";
        let xs = 12;
        let sm = 6;
        let md = 3;

        let styling = [];

        if (section === "buffer") {
            factor = has_what.has_riparian === true ? factor + 1 : factor;
            factor = has_what.has_edge === true ? factor + 1 : factor;
            factor_options = factor <= 3 ? factor * 3 : factor * 2;
            factor_question = 12 - factor_options;
            option_width = "calc(" + (100 / factor).toString() + "% - 8px)";
            xs = 12;
            sm = 12;
            md = 12 / factor;
        }

        if (section === "forest") {
            factor = has_what.has_conifer === true ? factor + 1 : factor;
            factor = has_what.has_evergreen === true ? factor + 1 : factor;
            factor = has_what.has_deciduous === true ? factor + 1 : factor;
            factor = has_what.has_mixed === true ? factor + 1 : factor;
            factor_options = factor <= 3 ? factor * 3 : factor * 2;
            factor_question = 12 - factor_options;
            option_width = "calc(" + (100 / factor).toString() + "% - 8px)";
            xs = 12;
            sm = 12;
            md = 12 / factor;
        }

        if (section === "grassland") {
            factor = has_what.has_native_ungrazed === true ? factor + 1 : factor;
            factor = has_what.has_native_grazed === true ? factor + 1 : factor;
            factor = has_what.has_nonnative_ungrazed === true ? factor + 1 : factor;
            factor = has_what.has_nonnative_grazed === true ? factor + 1 : factor;
            factor_options = factor <= 3 ? factor * 3 : factor * 2;
            factor_question = 12 - factor_options;
            option_width = "calc(" + (100 / factor).toString() + "% - 8px)";
            xs = 12;
            sm = 12;
            md = 12 / factor;
        }

        if (section === "wetland") {
            factor = has_what.has_wetland_natural === true ? factor + 1 : factor;
            factor = has_what.has_wetland_artificial === true ? factor + 1 : factor;
            factor_options = factor <= 3 ? factor * 3 : factor * 2;
            factor_question = 12 - factor_options;
            option_width = "calc(" + (100 / factor).toString() + "% - 8px)";
            xs = 12;
            sm = 12;
            md = 12 / factor;
        }

        if (section === "surfacewater") {
            let stream_factor = 0;
            let pond_factor = 0;
            stream_factor = has_what.has_streams === true ? stream_factor + 1 : stream_factor;
            stream_factor = has_what.has_rivers === true ? stream_factor + 1 : stream_factor;
            pond_factor = has_what.has_ponds_natural === true ? pond_factor + 1 : pond_factor;
            pond_factor = has_what.has_ponds_artificial === true ? pond_factor + 1 : pond_factor;
            pond_factor = has_what.has_lakes_natural === true ? pond_factor + 1 : pond_factor;
            pond_factor = has_what.has_lakes_artificial === true ? pond_factor + 1 : pond_factor;
            factor = stream_factor + pond_factor;
            factor_options = factor <= 3 ? factor * 3 : factor * 2;
            factor_options = factor >= 5 ? 9 : factor * 2;
            factor_question = 12 - factor_options;
            option_width = "calc(" + (100 / factor).toString() + "% - 8px)";
            xs = 12;
            sm = 12;
            md = 12 / factor;

            styling["factor_" + section + "stream_management_xs"] = 12;
            styling["factor_" + section + "stream_management_sm"] = 12;
            styling["factor_" + section + "stream_management_md"] = 12 / stream_factor;
            styling["factor_" + section + "pond_management_xs"] = 12;
            styling["factor_" + section + "pond_management_sm"] = 12;
            styling["factor_" + section + "pond_management_md"] = 12 / pond_factor;
            styling["factor_" + section + "stream_factor"] = stream_factor;
            styling["factor_" + section + "pond_factor"] = pond_factor;
        }

        const header = {
            width: option_width,
            "padding-right": "4px",
            "padding-left": "4px",
            "text-align": "center",
        };
        const option_container = {
            width: option_width,
            "padding-right": "4px",
            "padding-left": "4px",
        };

        styling["factor_" + section + "_question"] = factor_question;
        styling["factor_" + section + "_options"] = factor_options;
        styling["factor_" + section + "_header"] = header;
        styling["factor_" + section + "_option"] = option_container;
        styling["factor_" + section + "_xs"] = xs;
        styling["factor_" + section + "_sm"] = sm;
        styling["factor_" + section + "_md"] = md;

        return styling;
    };

    render() {
        const {
            classes,
            riparianVegetation,
            riparianManagement,
            forestConfiguration,
            forestManagement,
            grasslandManagement,
            grasslandConfiguration,
            grazingIntensity,
            naturalWetlandRegime,
            wetlandManagement,
            surfaceWaterBufferPercentage,
            surfaceWaterRiparianVegetation,
            bankStability,
            bankManagement,
            surfaceWaterChannelization,
            surfaceWaterPredominantUse,
            surfaceWaterAverageDepth,
            surfaceWaterInstreamHabitat,
            surfaceWaterManagement,
            farmHpiSurvey,
            allCrops,
            allFields,
            cropyears,
        } = this.props;

        const {
            addCropDialogOpen,
            landQuestionsDialogOpen,
            missing_cultivated_lands,
            cultivated_land_questionnaire_id,
        } = this.state;

        const has_what = GET_HAS_WHAT_2(farmHpiSurvey);

        const columnData = [
            { id: "crop_name", numeric: false, disablePadding: false, label: "Crop Name", allowSort: false },
            {
                id: "plantable_acres",
                numeric: false,
                disablePadding: false,
                label: "Total Plantable Acres across All Fields for this Crop",
                allowSort: false,
            },
            { id: "status", numeric: false, disablePadding: false, label: "Entry Status", allowSort: false },
        ];

        const cropTableData = this.getCropTableData();
        const default_values = GET_DEFAULT_SURVEY_VALUES(this.props.farmHpiSurvey);
        const {
            factor_buffer_question,
            factor_buffer_header,
            factor_buffer_options,
            factor_buffer_option,
            factor_buffer_md,
            factor_buffer_sm,
            factor_buffer_xs,
        } = this.get_section_styling("buffer", has_what);

        const {
            factor_forest_question,
            factor_forest_header,
            factor_forest_options,
            factor_forest_option,
            factor_forest_md,
            factor_forest_sm,
            factor_forest_xs,
        } = this.get_section_styling("forest", has_what);

        const {
            factor_grassland_question,
            factor_grassland_header,
            factor_grassland_options,
            factor_grassland_option,
            factor_grassland_md,
            factor_grassland_sm,
            factor_grassland_xs,
        } = this.get_section_styling("grassland", has_what);

        const {
            factor_wetland_question,
            factor_wetland_header,
            factor_wetland_options,
            factor_wetland_option,
            factor_wetland_md,
            factor_wetland_sm,
            factor_wetland_xs,
        } = this.get_section_styling("wetland", has_what);

        const {
            factor_surfacewater_question,
            factor_surfacewater_header,
            factor_surfacewater_options,
            factor_surfacewater_option,
            factor_surfacewater_stream_management_xs,
            factor_surfacewater_stream_management_sm,
            factor_surfacewater_stream_management_md,
            factor_surfacewater_pond_management_xs,
            factor_surfacewater_pond_management_sm,
            factor_surfacewater_pond_management_md,
        } = this.get_section_styling("surfacewater", has_what);

        return (
            <>
                {addCropDialogOpen === true && (
                    <AddCropDialog
                        open={addCropDialogOpen}
                        onSaveClick={this.addCropDialogSaveClick}
                        onCancelClick={this.addCropDialogCancelClick}
                        cropyears={cropyears}
                        allCrops={allCrops}
                        allFields={allFields}
                        farmHpiSurvey={this.props.farmHpiSurvey}
                    />
                )}

                {landQuestionsDialogOpen && (
                    <LandQuestionsDialog
                        open={landQuestionsDialogOpen}
                        onSaveClick={this.landQuestionsDialogSaveClick}
                        onCancelClick={this.landQuestionsDialogCancelClick}
                        questionnaire_id={cultivated_land_questionnaire_id}
                        allCrops={allCrops}
                        farmHpiSurvey={farmHpiSurvey}
                        cq={farmHpiSurvey.cultivated.filter((c) => c.id === cultivated_land_questionnaire_id)[0]}
                    />
                )}

                <Form
                    getApi={(el) => (this.formApi = el)}
                    dontValidateOnMount={false}
                    validateOnSubmit={true}
                    defaultValues={default_values}
                    validateError={(values) => this.errorValidator(values, has_what, true)}
                    onSubmitFailure={(errors, formApi) => {}}
                    onSubmit={(values) => this.updateSurvey(values, has_what)}
                >
                    {(formApi) => (
                        <form onSubmit={formApi.submitForm}>
                            <Grid container spacing={24}>
                                {/* ////////////////////////////////////////////////////// 
                                // Cultivated Lands
                                ////////////////////////////////////////////////////// */}
                                <Grid item xs={12}>
                                    <Accordion defaultExpanded={true}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="cultivatedland-content"
                                            id="cultivatedland-header"
                                            className={classes.expansionPannelSummary}
                                        >
                                            <Typography className={classes.heading}>Cultivated Lands</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid container spacing={16}>
                                                <Grid item xs={12}>
                                                    <Typography>
                                                        Cultivated Land is summarized by crop for the survey year. Add
                                                        an entry for each crop grown on this farm in the survey year,
                                                        even if the crops grown are not otherwise entered in the
                                                        Fieldprint Platform. For each crop added here, you will be asked
                                                        to complete a set of inputs.
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Button
                                                        fullWidth
                                                        variant="raised"
                                                        color="primary"
                                                        onClick={() => this.setState({ addCropDialogOpen: true })}
                                                        closeAddCropDialog={this.closeAddCropDialog}
                                                    >
                                                        <AddCircleOutlineIcon />
                                                        &nbsp;&nbsp;&nbsp;Add Crop
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <CropTable
                                                        data={cropTableData}
                                                        columnData={columnData}
                                                        noRecordsMessage={"No crops added."}
                                                        onEdit={(id) => this.handleEditCultivatedLandsQuestionnaire(id)}
                                                        onDelete={(id) =>
                                                            this.handleDeleteCultivatedLandsQuestionnaire(id)
                                                        }
                                                    />
                                                </Grid>
                                                {missing_cultivated_lands && (
                                                    <Grid item xs={12}>
                                                        <Typography className={classes.redText}>
                                                            You must enter cultivated lands for at least one crop.
                                                        </Typography>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                                {/* //////////////////////////////////////////////////////
                             // Forests
                             ////////////////////////////////////////////////////// */}
                                {(has_what.has_conifer ||
                                    has_what.has_evergreen ||
                                    has_what.has_deciduous ||
                                    has_what.has_mixed) && (
                                    <Grid item xs={12}>
                                        <Accordion defaultExpanded={true}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="forest-content"
                                                id="forest-header"
                                                className={classes.expansionPannelSummary}
                                            >
                                                <Typography className={classes.heading}>Forests</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid container spacing={16}>
                                                    <Grid item xs={12}>
                                                        <Grid container spacing={8}>
                                                            <Grid item xs={factor_forest_question} />
                                                            <Grid item xs={factor_forest_options}>
                                                                <div className={classes.optionsContainer}>
                                                                    {has_what.has_conifer && (
                                                                        <div style={factor_forest_header}>
                                                                            Conifer Plantation
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_evergreen && (
                                                                        <div style={factor_forest_header}>
                                                                            Evergreen Forest
                                                                        </div>
                                                                    )}

                                                                    {has_what.has_deciduous && (
                                                                        <div style={factor_forest_header}>
                                                                            Deciduous Forest
                                                                        </div>
                                                                    )}

                                                                    {has_what.has_mixed && (
                                                                        <div style={factor_forest_header}>
                                                                            Mixed Forest
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <Grid container spacing={8}>
                                                            <Grid item xs={factor_forest_question}>
                                                                <Typography>
                                                                    1. How many acres of this land type do you have on
                                                                    this farm?
                                                                </Typography>
                                                            </Grid>

                                                            <Grid item xs={factor_forest_options}>
                                                                <div className={classes.optionsContainer}>
                                                                    {has_what.has_conifer && (
                                                                        <div style={factor_forest_option}>
                                                                            <TextField
                                                                                type="number"
                                                                                field="forest.conifer.area"
                                                                                label={null}
                                                                                fullWidth
                                                                                units="ac"
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_evergreen && (
                                                                        <div style={factor_forest_option}>
                                                                            <TextField
                                                                                type="number"
                                                                                field="forest.evergreen.area"
                                                                                label={null}
                                                                                fullWidth
                                                                                units="ac"
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_deciduous && (
                                                                        <div style={factor_forest_option}>
                                                                            <TextField
                                                                                type="number"
                                                                                field="forest.deciduous.area"
                                                                                label={null}
                                                                                fullWidth
                                                                                units="ac"
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_mixed && (
                                                                        <div style={factor_forest_option}>
                                                                            <TextField
                                                                                type="number"
                                                                                field="forest.mixed.area"
                                                                                label={null}
                                                                                fullWidth
                                                                                units="ac"
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <Grid container spacing={8}>
                                                            <Grid item xs={factor_forest_question}>
                                                                <Typography>
                                                                    2. Estimate how many acres of this land type has
                                                                    been converted from another land type in the past 5
                                                                    years?
                                                                </Typography>
                                                            </Grid>

                                                            <Grid item xs={factor_forest_options}>
                                                                <div className={classes.optionsContainer}>
                                                                    {has_what.has_conifer && (
                                                                        <div style={factor_forest_option}>
                                                                            <TextField
                                                                                type="number"
                                                                                field="forest.conifer.area_converted"
                                                                                label={null}
                                                                                fullWidth
                                                                                units="ac"
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_evergreen && (
                                                                        <div style={factor_forest_option}>
                                                                            <TextField
                                                                                type="number"
                                                                                field="forest.evergreen.area_converted"
                                                                                label={null}
                                                                                fullWidth
                                                                                units="ac"
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_deciduous && (
                                                                        <div style={factor_forest_option}>
                                                                            <TextField
                                                                                type="number"
                                                                                field="forest.deciduous.area_converted"
                                                                                label={null}
                                                                                fullWidth
                                                                                units="ac"
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_mixed && (
                                                                        <div style={factor_forest_option}>
                                                                            <TextField
                                                                                type="number"
                                                                                field="forest.mixed.area_converted"
                                                                                label={null}
                                                                                fullWidth
                                                                                units="ac"
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    {this.getGroupHasConverted(formApi.values, "forest") === true && (
                                                        <Grid item xs={12}>
                                                            <Grid container spacing={8}>
                                                                <Grid
                                                                    item
                                                                    xs={factor_forest_question}
                                                                    style={{ "padding-left": "16px" }}
                                                                >
                                                                    <Typography>
                                                                        What land type was this land converted from?
                                                                    </Typography>
                                                                </Grid>

                                                                <Grid item xs={factor_forest_options}>
                                                                    <div className={classes.optionsContainer}>
                                                                        {has_what.has_conifer && (
                                                                            <div style={factor_forest_option}>
                                                                                {this.getHasConverted(
                                                                                    formApi.values,
                                                                                    "forest.conifer",
                                                                                ) && (
                                                                                    <Select
                                                                                        field="forest.conifer.previous_land_cover"
                                                                                        options={MAKE_OPTIONS(
                                                                                            land_types,
                                                                                        )}
                                                                                        fullWidth
                                                                                        margin="none"
                                                                                    />
                                                                                )}
                                                                            </div>
                                                                        )}
                                                                        {has_what.has_evergreen && (
                                                                            <div style={factor_forest_option}>
                                                                                {this.getHasConverted(
                                                                                    formApi.values,
                                                                                    "forest.evergreen",
                                                                                ) && (
                                                                                    <Select
                                                                                        field="forest.evergreen.previous_land_cover"
                                                                                        options={MAKE_OPTIONS(
                                                                                            land_types,
                                                                                        )}
                                                                                        fullWidth
                                                                                        margin="none"
                                                                                    />
                                                                                )}
                                                                            </div>
                                                                        )}
                                                                        {has_what.has_deciduous && (
                                                                            <div style={factor_forest_option}>
                                                                                {this.getHasConverted(
                                                                                    formApi.values,
                                                                                    "forest.deciduous",
                                                                                ) && (
                                                                                    <Select
                                                                                        field="forest.deciduous.previous_land_cover"
                                                                                        options={MAKE_OPTIONS(
                                                                                            land_types,
                                                                                        )}
                                                                                        fullWidth
                                                                                        margin="none"
                                                                                    />
                                                                                )}
                                                                            </div>
                                                                        )}
                                                                        {has_what.has_mixed && (
                                                                            <div style={factor_forest_option}>
                                                                                {this.getHasConverted(
                                                                                    formApi.values,
                                                                                    "forest.mixed",
                                                                                ) && (
                                                                                    <Select
                                                                                        field="forest.mixed.previous_land_cover"
                                                                                        options={MAKE_OPTIONS(
                                                                                            land_types,
                                                                                        )}
                                                                                        fullWidth
                                                                                        margin="none"
                                                                                    />
                                                                                )}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    )}

                                                    <Grid item xs={12}>
                                                        <Grid container spacing={8}>
                                                            <Grid item xs={factor_forest_question}>
                                                                <Typography>
                                                                    3. What is the predominant forest configuration?
                                                                </Typography>
                                                            </Grid>

                                                            <Grid item xs={factor_forest_options}>
                                                                <div className={classes.optionsContainer}>
                                                                    {has_what.has_conifer && (
                                                                        <div style={factor_forest_option}>
                                                                            <Select
                                                                                field="forest.conifer.configuration"
                                                                                label={null}
                                                                                options={MAKE_OPTIONS(
                                                                                    forestConfiguration,
                                                                                )}
                                                                                fullWidth
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_evergreen && (
                                                                        <div style={factor_forest_option}>
                                                                            <Select
                                                                                field="forest.evergreen.configuration"
                                                                                label={null}
                                                                                options={MAKE_OPTIONS(
                                                                                    forestConfiguration,
                                                                                )}
                                                                                fullWidth
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_deciduous && (
                                                                        <div style={factor_forest_option}>
                                                                            <Select
                                                                                field="forest.deciduous.configuration"
                                                                                label={null}
                                                                                options={MAKE_OPTIONS(
                                                                                    forestConfiguration,
                                                                                )}
                                                                                fullWidth
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_mixed && (
                                                                        <div style={factor_forest_option}>
                                                                            <Select
                                                                                field="forest.mixed.configuration"
                                                                                label={null}
                                                                                options={MAKE_OPTIONS(
                                                                                    forestConfiguration,
                                                                                )}
                                                                                fullWidth
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <Typography>
                                                            4. How do you manage the edge/riparian features for habitat?
                                                            (Check all that apply or None of the above)
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <div className={classes.indent}>
                                                            <Grid container spacing={0}>
                                                                {has_what.has_conifer && (
                                                                    <Grid
                                                                        item
                                                                        xs={factor_forest_xs}
                                                                        sm={factor_forest_sm}
                                                                        md={factor_forest_md}
                                                                    >
                                                                        <Typography>Conifer Plantation</Typography>
                                                                        <CheckboxGroup
                                                                            helperText={false}
                                                                            field="forest.conifer.riparian_management"
                                                                            row={false}
                                                                            options={MAKE_OPTIONS(forestManagement)}
                                                                            eventHandle={(e) =>
                                                                                this.checkNoneOfTheAbove(
                                                                                    e,
                                                                                    "forest.conifer.riparian_management",
                                                                                    forestManagement,
                                                                                )
                                                                            }
                                                                        />
                                                                    </Grid>
                                                                )}

                                                                {has_what.has_evergreen && (
                                                                    <Grid
                                                                        item
                                                                        xs={factor_forest_xs}
                                                                        sm={factor_forest_sm}
                                                                        md={factor_forest_md}
                                                                    >
                                                                        <Typography>Evergreen Forest</Typography>
                                                                        <CheckboxGroup
                                                                            helperText={false}
                                                                            field="forest.evergreen.riparian_management"
                                                                            row={false}
                                                                            options={MAKE_OPTIONS(forestManagement)}
                                                                            eventHandle={(e) =>
                                                                                this.checkNoneOfTheAbove(
                                                                                    e,
                                                                                    "forest.evergreen.riparian_management",
                                                                                    forestManagement,
                                                                                )
                                                                            }
                                                                        />
                                                                    </Grid>
                                                                )}

                                                                {has_what.has_deciduous && (
                                                                    <Grid
                                                                        item
                                                                        xs={factor_forest_xs}
                                                                        sm={factor_forest_sm}
                                                                        md={factor_forest_md}
                                                                    >
                                                                        <Typography>Deciduous Forest</Typography>
                                                                        <CheckboxGroup
                                                                            helperText={false}
                                                                            field="forest.deciduous.riparian_management"
                                                                            row={false}
                                                                            options={MAKE_OPTIONS(forestManagement)}
                                                                            eventHandle={(e) =>
                                                                                this.checkNoneOfTheAbove(
                                                                                    e,
                                                                                    "forest.deciduous.riparian_management",
                                                                                    forestManagement,
                                                                                )
                                                                            }
                                                                        />
                                                                    </Grid>
                                                                )}

                                                                {has_what.has_mixed && (
                                                                    <Grid
                                                                        item
                                                                        xs={factor_forest_xs}
                                                                        sm={factor_forest_sm}
                                                                        md={factor_forest_md}
                                                                    >
                                                                        <Typography>Mixed Forest</Typography>
                                                                        <CheckboxGroup
                                                                            helperText={false}
                                                                            field="forest.mixed.riparian_management"
                                                                            row={false}
                                                                            options={MAKE_OPTIONS(forestManagement)}
                                                                            eventHandle={(e) =>
                                                                                this.checkNoneOfTheAbove(
                                                                                    e,
                                                                                    "forest.mixed.riparian_management",
                                                                                    forestManagement,
                                                                                )
                                                                            }
                                                                        />
                                                                    </Grid>
                                                                )}
                                                            </Grid>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                )}
                                {/* //////////////////////////////////////////////////////
                             // Grasslands
                             ////////////////////////////////////////////////////// */}
                                {(has_what.has_native_ungrazed ||
                                    has_what.has_native_grazed ||
                                    has_what.has_nonnative_ungrazed ||
                                    has_what.has_nonnative_grazed) && (
                                    <Grid item xs={12}>
                                        <Accordion defaultExpanded={true}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="grassland-content"
                                                id="grassland-header"
                                                className={classes.expansionPannelSummary}
                                            >
                                                <Typography className={classes.heading}>Grasslands/Savanna</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid container spacing={16}>
                                                    <Grid item xs={12}>
                                                        <Grid container spacing={8}>
                                                            <Grid item xs={factor_grassland_question} />

                                                            <Grid item xs={factor_grassland_options}>
                                                                <div className={classes.optionsContainer}>
                                                                    {has_what.has_native_ungrazed && (
                                                                        <div style={factor_grassland_header}>
                                                                            Native grassland (ungrazed)
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_native_grazed && (
                                                                        <div style={factor_grassland_header}>
                                                                            Native grassland (grazed)
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_nonnative_ungrazed && (
                                                                        <div style={factor_grassland_header}>
                                                                            Non-native grassland (ungrazed)
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_nonnative_grazed && (
                                                                        <div style={factor_grassland_header}>
                                                                            Non-native grassland (grazed)
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <Grid container spacing={8}>
                                                            <Grid item xs={factor_grassland_question}>
                                                                <Typography>
                                                                    1. How many acres of this land type do you have on
                                                                    this farm?
                                                                </Typography>
                                                            </Grid>

                                                            <Grid item xs={factor_grassland_options}>
                                                                <div className={classes.optionsContainer}>
                                                                    {has_what.has_native_ungrazed && (
                                                                        <div style={factor_grassland_option}>
                                                                            <TextField
                                                                                type="number"
                                                                                field="grassland.native_ungrazed.area"
                                                                                label={null}
                                                                                fullWidth
                                                                                units="ac"
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_native_grazed && (
                                                                        <div style={factor_grassland_option}>
                                                                            <TextField
                                                                                type="number"
                                                                                field="grassland.native_grazed.area"
                                                                                label={null}
                                                                                fullWidth
                                                                                units="ac"
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_nonnative_ungrazed && (
                                                                        <div style={factor_grassland_option}>
                                                                            <TextField
                                                                                type="number"
                                                                                field="grassland.nonnative_ungrazed.area"
                                                                                label={null}
                                                                                fullWidth
                                                                                units="ac"
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_nonnative_grazed && (
                                                                        <div style={factor_grassland_option}>
                                                                            <TextField
                                                                                type="number"
                                                                                field="grassland.nonnative_grazed.area"
                                                                                label={null}
                                                                                fullWidth
                                                                                units="ac"
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <Grid container spacing={8}>
                                                            <Grid item xs={factor_grassland_question}>
                                                                <Typography>
                                                                    2. Estimate how many acres of this land type has
                                                                    been converted from another land type in the past 5
                                                                    years?
                                                                </Typography>
                                                            </Grid>

                                                            <Grid item xs={factor_grassland_options}>
                                                                <div className={classes.optionsContainer}>
                                                                    {has_what.has_native_ungrazed && (
                                                                        <div style={factor_grassland_option}>
                                                                            <TextField
                                                                                type="number"
                                                                                field="grassland.native_ungrazed.area_converted"
                                                                                label={null}
                                                                                fullWidth
                                                                                units="ac"
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_native_grazed && (
                                                                        <div style={factor_grassland_option}>
                                                                            <TextField
                                                                                type="number"
                                                                                field="grassland.native_grazed.area_converted"
                                                                                label={null}
                                                                                fullWidth
                                                                                units="ac"
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_nonnative_ungrazed && (
                                                                        <div style={factor_grassland_option}>
                                                                            <TextField
                                                                                type="number"
                                                                                field="grassland.nonnative_ungrazed.area_converted"
                                                                                label={null}
                                                                                fullWidth
                                                                                units="ac"
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_nonnative_grazed && (
                                                                        <div style={factor_grassland_option}>
                                                                            <TextField
                                                                                type="number"
                                                                                field="grassland.nonnative_grazed.area_converted"
                                                                                label={null}
                                                                                fullWidth
                                                                                units="ac"
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    {this.getGroupHasConverted(formApi.values, "grassland") ===
                                                        true && (
                                                        <Grid item xs={12}>
                                                            <Grid container spacing={8}>
                                                                <Grid
                                                                    item
                                                                    xs={factor_grassland_question}
                                                                    style={{ "padding-left": "16px" }}
                                                                >
                                                                    <Typography>
                                                                        What land type was this land converted from?
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={factor_grassland_options}>
                                                                    <div className={classes.optionsContainer}>
                                                                        {has_what.has_native_ungrazed && (
                                                                            <div style={factor_grassland_option}>
                                                                                {this.getHasConverted(
                                                                                    formApi.values,
                                                                                    "grassland.native_ungrazed",
                                                                                ) && (
                                                                                    <Select
                                                                                        field="grassland.native_ungrazed.previous_land_cover"
                                                                                        options={MAKE_OPTIONS(
                                                                                            land_types,
                                                                                        )}
                                                                                        fullWidth
                                                                                        margin="none"
                                                                                    />
                                                                                )}
                                                                            </div>
                                                                        )}
                                                                        {has_what.has_native_grazed && (
                                                                            <div style={factor_grassland_option}>
                                                                                {this.getHasConverted(
                                                                                    formApi.values,
                                                                                    "grassland.native_grazed",
                                                                                ) && (
                                                                                    <Select
                                                                                        field="grassland.native_grazed.previous_land_cover"
                                                                                        options={MAKE_OPTIONS(
                                                                                            land_types,
                                                                                        )}
                                                                                        fullWidth
                                                                                        margin="none"
                                                                                    />
                                                                                )}
                                                                            </div>
                                                                        )}
                                                                        {has_what.has_nonnative_ungrazed && (
                                                                            <div style={factor_grassland_option}>
                                                                                {this.getHasConverted(
                                                                                    formApi.values,
                                                                                    "grassland.nonnative_ungrazed",
                                                                                ) && (
                                                                                    <Select
                                                                                        field="grassland.nonnative_ungrazed.previous_land_cover"
                                                                                        options={MAKE_OPTIONS(
                                                                                            land_types,
                                                                                        )}
                                                                                        fullWidth
                                                                                        margin="none"
                                                                                    />
                                                                                )}
                                                                            </div>
                                                                        )}

                                                                        {has_what.has_nonnative_grazed && (
                                                                            <div style={factor_grassland_option}>
                                                                                {this.getHasConverted(
                                                                                    formApi.values,
                                                                                    "grassland.nonnative_grazed",
                                                                                ) && (
                                                                                    <Select
                                                                                        field="grassland.nonnative_grazed.previous_land_cover"
                                                                                        options={MAKE_OPTIONS(
                                                                                            land_types,
                                                                                        )}
                                                                                        fullWidth
                                                                                        margin="none"
                                                                                    />
                                                                                )}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    )}

                                                    <Grid item xs={12}>
                                                        <Grid container spacing={8}>
                                                            <Grid item xs={factor_grassland_question}>
                                                                <Typography>
                                                                    3. What is the predominant arrangement/configuration
                                                                    of your grassland/savanna?
                                                                </Typography>
                                                            </Grid>

                                                            <Grid item xs={factor_grassland_options}>
                                                                <div className={classes.optionsContainer}>
                                                                    {has_what.has_native_ungrazed && (
                                                                        <div style={factor_grassland_option}>
                                                                            <Select
                                                                                field="grassland.native_ungrazed.configuration"
                                                                                label={null}
                                                                                options={MAKE_OPTIONS(
                                                                                    grasslandConfiguration,
                                                                                )}
                                                                                fullWidth
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_native_grazed && (
                                                                        <div style={factor_grassland_option}>
                                                                            <Select
                                                                                field="grassland.native_grazed.configuration"
                                                                                label={null}
                                                                                options={MAKE_OPTIONS(
                                                                                    grasslandConfiguration,
                                                                                )}
                                                                                fullWidth
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_nonnative_ungrazed && (
                                                                        <div style={factor_grassland_option}>
                                                                            <Select
                                                                                field="grassland.nonnative_ungrazed.configuration"
                                                                                label={null}
                                                                                options={MAKE_OPTIONS(
                                                                                    grasslandConfiguration,
                                                                                )}
                                                                                fullWidth
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_nonnative_grazed && (
                                                                        <div style={factor_grassland_option}>
                                                                            <Select
                                                                                field="grassland.nonnative_grazed.configuration"
                                                                                label={null}
                                                                                options={MAKE_OPTIONS(
                                                                                    grasslandConfiguration,
                                                                                )}
                                                                                fullWidth
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <Grid container spacing={8}>
                                                            <Grid item xs={factor_grassland_question}>
                                                                <Typography>
                                                                    4. What is the grazing intensity of your
                                                                    grassland/savanna?
                                                                </Typography>
                                                            </Grid>

                                                            <Grid item xs={factor_grassland_options}>
                                                                <div className={classes.optionsContainer}>
                                                                    {has_what.has_native_ungrazed && (
                                                                        <div style={factor_grassland_option}>
                                                                            <Typography align="center">
                                                                                Not Applicable
                                                                            </Typography>
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_native_grazed && (
                                                                        <div style={factor_grassland_option}>
                                                                            <Select
                                                                                field="grassland.native_grazed.grazing_intensity"
                                                                                label={null}
                                                                                options={MAKE_OPTIONS(grazingIntensity)}
                                                                                fullWidth
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_nonnative_ungrazed && (
                                                                        <div style={factor_grassland_option}>
                                                                            <Typography align="center">
                                                                                Not Applicable
                                                                            </Typography>
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_nonnative_grazed && (
                                                                        <div style={factor_grassland_option}>
                                                                            <Select
                                                                                field="grassland.nonnative_grazed.grazing_intensity"
                                                                                label={null}
                                                                                options={MAKE_OPTIONS(grazingIntensity)}
                                                                                fullWidth
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <Grid container spacing={8}>
                                                            <Grid item xs={factor_grassland_question}>
                                                                <Typography>
                                                                    5. Are you implementing conservation grazing?
                                                                </Typography>
                                                            </Grid>

                                                            <Grid item xs={factor_grassland_options}>
                                                                <div className={classes.optionsContainer}>
                                                                    {has_what.has_native_ungrazed && (
                                                                        <div style={factor_grassland_option}>
                                                                            <Typography align="center">
                                                                                Not Applicable
                                                                            </Typography>
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_native_grazed && (
                                                                        <div style={factor_grassland_option}>
                                                                            <Select
                                                                                field="grassland.native_grazed.grazing_conservation"
                                                                                label={null}
                                                                                options={[
                                                                                    { label: "Yes", value: "1" },
                                                                                    { label: "No", value: "2" },
                                                                                ]}
                                                                                fullWidth
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_nonnative_ungrazed && (
                                                                        <div style={factor_grassland_option}>
                                                                            <Typography align="center">
                                                                                Not Applicable
                                                                            </Typography>
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_nonnative_grazed && (
                                                                        <div style={factor_grassland_option}>
                                                                            <Select
                                                                                field="grassland.nonnative_grazed.grazing_conservation"
                                                                                label={null}
                                                                                options={[
                                                                                    { label: "Yes", value: "1" },
                                                                                    { label: "No", value: "2" },
                                                                                ]}
                                                                                fullWidth
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <Typography>
                                                            6. How do you manage your grassland/savanna for habitat?
                                                            (Check all that apply or None of the above)
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <div className={classes.indent}>
                                                            <Grid container spacing={0}>
                                                                {has_what.has_native_ungrazed && (
                                                                    <Grid
                                                                        item
                                                                        xs={factor_grassland_xs}
                                                                        sm={factor_grassland_sm}
                                                                        md={factor_grassland_md}
                                                                    >
                                                                        <Typography>
                                                                            Native grassland (ungrazed)
                                                                        </Typography>
                                                                        <Grid container xs={12}>
                                                                            <CheckboxGroup
                                                                                field="grassland.native_ungrazed.management"
                                                                                row={false}
                                                                                options={MAKE_OPTIONS(
                                                                                    grasslandManagement,
                                                                                )}
                                                                                eventHandle={(e) =>
                                                                                    this.checkNoneOfTheAbove(
                                                                                        e,
                                                                                        "grassland.native_ungrazed.management",
                                                                                        grasslandManagement,
                                                                                    )
                                                                                }
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                )}
                                                                {has_what.has_native_grazed && (
                                                                    <Grid
                                                                        item
                                                                        xs={factor_grassland_xs}
                                                                        sm={factor_grassland_sm}
                                                                        md={factor_grassland_md}
                                                                    >
                                                                        <Typography>
                                                                            Native Grasslands (grazed)
                                                                        </Typography>
                                                                        <Grid container xs={12}>
                                                                            <CheckboxGroup
                                                                                field="grassland.native_grazed.management"
                                                                                row={false}
                                                                                options={MAKE_OPTIONS(
                                                                                    grasslandManagement,
                                                                                )}
                                                                                eventHandle={(e) =>
                                                                                    this.checkNoneOfTheAbove(
                                                                                        e,
                                                                                        "grassland.native_grazed.management",
                                                                                        grasslandManagement,
                                                                                    )
                                                                                }
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                )}
                                                                {has_what.has_nonnative_ungrazed && (
                                                                    <Grid
                                                                        item
                                                                        xs={factor_grassland_xs}
                                                                        sm={factor_grassland_sm}
                                                                        md={factor_grassland_md}
                                                                    >
                                                                        <Typography>
                                                                            Non-native Grasslands (ungrazed)
                                                                        </Typography>
                                                                        <Grid container xs={12}>
                                                                            <CheckboxGroup
                                                                                field="grassland.nonnative_ungrazed.management"
                                                                                row={false}
                                                                                options={MAKE_OPTIONS(
                                                                                    grasslandManagement,
                                                                                )}
                                                                                eventHandle={(e) =>
                                                                                    this.checkNoneOfTheAbove(
                                                                                        e,
                                                                                        "grassland.nonnative_ungrazed.management",
                                                                                        grasslandManagement,
                                                                                    )
                                                                                }
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                )}
                                                                {has_what.has_nonnative_grazed && (
                                                                    <Grid
                                                                        item
                                                                        xs={factor_grassland_xs}
                                                                        sm={factor_grassland_sm}
                                                                        md={factor_grassland_md}
                                                                    >
                                                                        <Typography>
                                                                            Non-native Grasslands (grazed)
                                                                        </Typography>
                                                                        <Grid container xs={12}>
                                                                            <CheckboxGroup
                                                                                field="grassland.nonnative_grazed.management"
                                                                                row={false}
                                                                                options={MAKE_OPTIONS(
                                                                                    grasslandManagement,
                                                                                )}
                                                                                eventHandle={(e) =>
                                                                                    this.checkNoneOfTheAbove(
                                                                                        e,
                                                                                        "grassland.nonnative_grazed.management",
                                                                                        grasslandManagement,
                                                                                    )
                                                                                }
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                )}
                                                            </Grid>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                )}

                                {/* //////////////////////////////////////////////////////
                             // Riparian
                             ////////////////////////////////////////////////////// */}
                                {(has_what.has_edge || has_what.has_riparian) && (
                                    <Grid item xs={12}>
                                        <Accordion defaultExpanded={true}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="buffer-content"
                                                id="buffer-header"
                                                className={classes.expansionPannelSummary}
                                            >
                                                <Typography className={classes.heading}>
                                                    Edge Features/Riparian Buffers
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid container spacing={16}>
                                                    <Grid item xs={12}>
                                                        <Grid container spacing={8}>
                                                            <Grid item xs={factor_buffer_question} />
                                                            <Grid item xs={factor_buffer_options}>
                                                                <div className={classes.optionsContainer}>
                                                                    {has_what.has_edge && (
                                                                        <div style={factor_buffer_header}>
                                                                            Edge Features
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_riparian && (
                                                                        <div style={factor_buffer_header}>
                                                                            Riparian Features
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <Grid container spacing={8}>
                                                            <Grid item xs={factor_buffer_question}>
                                                                <Typography>
                                                                    1. How many acres of this land type do you have on
                                                                    this farm?
                                                                </Typography>
                                                            </Grid>

                                                            <Grid item xs={factor_buffer_options}>
                                                                <div className={classes.optionsContainer}>
                                                                    {has_what.has_edge && (
                                                                        <div style={factor_buffer_option}>
                                                                            <TextField
                                                                                type="number"
                                                                                field="buffer.edge.area"
                                                                                label={null}
                                                                                fullWidth
                                                                                units="ac"
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}

                                                                    {has_what.has_riparian && (
                                                                        <div style={factor_buffer_option}>
                                                                            <TextField
                                                                                type="number"
                                                                                field="buffer.riparian.area"
                                                                                label={null}
                                                                                fullWidth
                                                                                units="ac"
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <Grid container spacing={8}>
                                                            <Grid item xs={factor_buffer_question}>
                                                                <Typography>
                                                                    2. How many acres of this land type has been
                                                                    converted from another land type in the past 5
                                                                    years?
                                                                </Typography>
                                                            </Grid>

                                                            <Grid item xs={factor_buffer_options}>
                                                                <div className={classes.optionsContainer}>
                                                                    {has_what.has_edge && (
                                                                        <div style={factor_buffer_option}>
                                                                            <TextField
                                                                                type="number"
                                                                                field="buffer.edge.area_converted"
                                                                                label={null}
                                                                                fullWidth
                                                                                units="ac"
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}

                                                                    {has_what.has_riparian && (
                                                                        <div style={factor_buffer_option}>
                                                                            <TextField
                                                                                type="number"
                                                                                field="buffer.riparian.area_converted"
                                                                                label={null}
                                                                                fullWidth
                                                                                units="ac"
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    {this.getGroupHasConverted(formApi.values, "buffer") === true && (
                                                        <Grid item xs={12}>
                                                            <Grid container spacing={8}>
                                                                <Grid
                                                                    item
                                                                    xs={factor_buffer_question}
                                                                    style={{ "padding-left": "16px" }}
                                                                >
                                                                    <Typography>
                                                                        What land type was this land converted from?
                                                                    </Typography>
                                                                </Grid>

                                                                <Grid item xs={factor_buffer_options}>
                                                                    <div className={classes.optionsContainer}>
                                                                        {has_what.has_edge && (
                                                                            <div style={factor_buffer_option}>
                                                                                {this.getHasConverted(
                                                                                    formApi.values,
                                                                                    "buffer.edge",
                                                                                ) && (
                                                                                    <Select
                                                                                        field="buffer.edge.previous_land_cover"
                                                                                        options={MAKE_OPTIONS(
                                                                                            land_types,
                                                                                        )}
                                                                                        fullWidth
                                                                                        margin="none"
                                                                                    />
                                                                                )}
                                                                            </div>
                                                                        )}
                                                                        {has_what.has_riparian && (
                                                                            <div style={factor_buffer_option}>
                                                                                {this.getHasConverted(
                                                                                    formApi.values,
                                                                                    "buffer.riparian",
                                                                                ) && (
                                                                                    <Select
                                                                                        field="buffer.riparian.previous_land_cover"
                                                                                        options={MAKE_OPTIONS(
                                                                                            land_types,
                                                                                        )}
                                                                                        fullWidth
                                                                                        margin="none"
                                                                                    />
                                                                                )}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    )}

                                                    <Grid item xs={12}>
                                                        <Grid container spacing={8}>
                                                            <Grid item xs={factor_buffer_question}>
                                                                <Typography>
                                                                    3. How much of the edge feature/riparian buffer is
                                                                    vegetated and how much is native?
                                                                </Typography>
                                                            </Grid>

                                                            <Grid item xs={factor_buffer_options}>
                                                                <div className={classes.optionsContainer}>
                                                                    {has_what.has_edge && (
                                                                        <div style={factor_buffer_option}>
                                                                            <Select
                                                                                field="buffer.edge.composition"
                                                                                label={null}
                                                                                options={MAKE_OPTIONS(
                                                                                    riparianVegetation,
                                                                                )}
                                                                                fullWidth
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}

                                                                    {has_what.has_riparian && (
                                                                        <div style={factor_buffer_option}>
                                                                            <Select
                                                                                field="buffer.riparian.composition"
                                                                                label={null}
                                                                                options={MAKE_OPTIONS(
                                                                                    riparianVegetation,
                                                                                )}
                                                                                fullWidth
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <Typography>
                                                            4. How do you manage the edge/riparian features for habitat?
                                                            (Check all that apply or None of the above)
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <div className={classes.indent}>
                                                            <Grid container spacing={0}>
                                                                {has_what.has_edge && (
                                                                    <Grid
                                                                        item
                                                                        xs={factor_buffer_xs}
                                                                        sm={factor_buffer_sm}
                                                                        md={factor_buffer_md}
                                                                    >
                                                                        <Typography>Edge Features</Typography>
                                                                        <Grid container xs={12}>
                                                                            <CheckboxGroup
                                                                                field="buffer.edge.management"
                                                                                row={false}
                                                                                options={MAKE_OPTIONS(
                                                                                    riparianManagement,
                                                                                )}
                                                                                eventHandle={(e) =>
                                                                                    this.checkNoneOfTheAbove(
                                                                                        e,
                                                                                        "buffer.edge.management",
                                                                                        riparianManagement,
                                                                                    )
                                                                                }
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                )}
                                                                {has_what.has_riparian && (
                                                                    <Grid
                                                                        item
                                                                        xs={factor_buffer_xs}
                                                                        sm={factor_buffer_sm}
                                                                        md={factor_buffer_md}
                                                                    >
                                                                        <Typography>Riparian Buffers</Typography>
                                                                        <Grid container xs={12}>
                                                                            <CheckboxGroup
                                                                                field="buffer.riparian.management"
                                                                                row={false}
                                                                                options={MAKE_OPTIONS(
                                                                                    riparianManagement,
                                                                                )}
                                                                                eventHandle={(e) =>
                                                                                    this.checkNoneOfTheAbove(
                                                                                        e,
                                                                                        "buffer.riparian.management",
                                                                                        riparianManagement,
                                                                                    )
                                                                                }
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                )}
                                                            </Grid>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                )}

                                {/* //////////////////////////////////////////////////////
                             // Surface Waters
                             ////////////////////////////////////////////////////// */}
                                {(has_what.has_streams ||
                                    has_what.has_rivers ||
                                    has_what.has_ponds_artificial ||
                                    has_what.has_ponds_natural ||
                                    has_what.has_lakes_artificial ||
                                    has_what.has_lakes_natural) && (
                                    <Grid item xs={12}>
                                        <Accordion defaultExpanded={true}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="surfacewater-content"
                                                id="surfacewater-header"
                                                className={classes.expansionPannelSummary}
                                            >
                                                <Typography className={classes.heading}>Surface Waters</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid container spacing={16}>
                                                    <Grid item xs={12}>
                                                        <Grid container spacing={8}>
                                                            <Grid item xs={factor_surfacewater_question} />
                                                            <Grid item xs={factor_surfacewater_options}>
                                                                <div className={classes.optionsContainer}>
                                                                    {has_what.has_streams && (
                                                                        <div style={factor_surfacewater_header}>
                                                                            Streams
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_rivers && (
                                                                        <div style={factor_surfacewater_header}>
                                                                            Rivers
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_ponds_natural && (
                                                                        <div style={factor_surfacewater_header}>
                                                                            Pond (natural)
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_ponds_artificial && (
                                                                        <div style={factor_surfacewater_header}>
                                                                            Pond (artificial)
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_lakes_natural && (
                                                                        <div style={factor_surfacewater_header}>
                                                                            Lake (natural)
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_lakes_artificial && (
                                                                        <div style={factor_surfacewater_header}>
                                                                            Lake (artificial)
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <Grid container spacing={8}>
                                                            <Grid item xs={factor_surfacewater_question}>
                                                                <Typography>
                                                                    1. How many miles (streams and rivers) or estimated
                                                                    acres (ponds and lakes) of this land type do you
                                                                    have on this farm?
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={factor_surfacewater_options}>
                                                                <div className={classes.optionsContainer}>
                                                                    {has_what.has_streams && (
                                                                        <div style={factor_surfacewater_option}>
                                                                            <TextField
                                                                                type="number"
                                                                                field="surfacewater.stream.length"
                                                                                label={null}
                                                                                fullWidth
                                                                                units="mi"
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_rivers && (
                                                                        <div style={factor_surfacewater_option}>
                                                                            <TextField
                                                                                type="number"
                                                                                field="surfacewater.river.length"
                                                                                label={null}
                                                                                fullWidth
                                                                                units="mi"
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_ponds_natural && (
                                                                        <div style={factor_surfacewater_option}>
                                                                            <TextField
                                                                                type="number"
                                                                                field="surfacewater.pond_natural.area"
                                                                                label={null}
                                                                                fullWidth
                                                                                units="ac"
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_ponds_artificial && (
                                                                        <div style={factor_surfacewater_option}>
                                                                            <TextField
                                                                                type="number"
                                                                                field="surfacewater.pond_artificial.area"
                                                                                label={null}
                                                                                fullWidth
                                                                                units="ac"
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_lakes_natural && (
                                                                        <div style={factor_surfacewater_option}>
                                                                            <TextField
                                                                                type="number"
                                                                                field="surfacewater.lake_natural.area"
                                                                                label={null}
                                                                                fullWidth
                                                                                units="ac"
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_lakes_artificial && (
                                                                        <div style={factor_surfacewater_option}>
                                                                            <TextField
                                                                                type="number"
                                                                                field="surfacewater.lake_artificial.area"
                                                                                label={null}
                                                                                fullWidth
                                                                                units="ac"
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <Grid container spacing={8}>
                                                            <Grid item xs={factor_surfacewater_question}>
                                                                <Typography>
                                                                    2. What is the estimated percent of the surface
                                                                    water edge that has a riparian buffer?
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={factor_surfacewater_options}>
                                                                <div className={classes.optionsContainer}>
                                                                    {has_what.has_streams && (
                                                                        <div style={factor_surfacewater_option}>
                                                                            <Select
                                                                                field="surfacewater.stream.riparian"
                                                                                label={null}
                                                                                options={MAKE_OPTIONS(
                                                                                    surfaceWaterBufferPercentage,
                                                                                )}
                                                                                fullWidth
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_rivers && (
                                                                        <div style={factor_surfacewater_option}>
                                                                            <Select
                                                                                field="surfacewater.river.riparian"
                                                                                label={null}
                                                                                options={MAKE_OPTIONS(
                                                                                    surfaceWaterBufferPercentage,
                                                                                )}
                                                                                fullWidth
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_ponds_natural && (
                                                                        <div style={factor_surfacewater_option}>
                                                                            <Select
                                                                                field="surfacewater.pond_natural.riparian"
                                                                                label={null}
                                                                                options={MAKE_OPTIONS(
                                                                                    surfaceWaterBufferPercentage,
                                                                                )}
                                                                                fullWidth
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_ponds_artificial && (
                                                                        <div style={factor_surfacewater_option}>
                                                                            <Select
                                                                                field="surfacewater.pond_artificial.riparian"
                                                                                label={null}
                                                                                options={MAKE_OPTIONS(
                                                                                    surfaceWaterBufferPercentage,
                                                                                )}
                                                                                fullWidth
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_lakes_natural && (
                                                                        <div style={factor_surfacewater_option}>
                                                                            <Select
                                                                                field="surfacewater.lake_natural.riparian"
                                                                                label={null}
                                                                                options={MAKE_OPTIONS(
                                                                                    surfaceWaterBufferPercentage,
                                                                                )}
                                                                                fullWidth
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_lakes_artificial && (
                                                                        <div style={factor_surfacewater_option}>
                                                                            <Select
                                                                                field="surfacewater.lake_artificial.riparian"
                                                                                label={null}
                                                                                options={MAKE_OPTIONS(
                                                                                    surfaceWaterBufferPercentage,
                                                                                )}
                                                                                fullWidth
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <Grid container spacing={8}>
                                                            <Grid item xs={factor_surfacewater_question}>
                                                                <Typography>
                                                                    3. What is the % of vegetative cover of the riparian
                                                                    zone (land adjacent to surface water)?
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={factor_surfacewater_options}>
                                                                <div className={classes.optionsContainer}>
                                                                    {has_what.has_streams && (
                                                                        <div style={factor_surfacewater_option}>
                                                                            <Select
                                                                                field="surfacewater.stream.riparian_vegetation"
                                                                                label={null}
                                                                                options={MAKE_OPTIONS(
                                                                                    surfaceWaterRiparianVegetation,
                                                                                )}
                                                                                fullWidth
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_rivers && (
                                                                        <div style={factor_surfacewater_option}>
                                                                            <Select
                                                                                field="surfacewater.river.riparian_vegetation"
                                                                                label={null}
                                                                                options={MAKE_OPTIONS(
                                                                                    surfaceWaterRiparianVegetation,
                                                                                )}
                                                                                fullWidth
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_ponds_natural && (
                                                                        <div style={factor_surfacewater_option}>
                                                                            <Select
                                                                                field="surfacewater.pond_natural.riparian_vegetation"
                                                                                label={null}
                                                                                options={MAKE_OPTIONS(
                                                                                    surfaceWaterRiparianVegetation,
                                                                                )}
                                                                                fullWidth
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_ponds_artificial && (
                                                                        <div style={factor_surfacewater_option}>
                                                                            <Select
                                                                                field="surfacewater.pond_artificial.riparian_vegetation"
                                                                                label={null}
                                                                                options={MAKE_OPTIONS(
                                                                                    surfaceWaterRiparianVegetation,
                                                                                )}
                                                                                fullWidth
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_lakes_natural && (
                                                                        <div style={factor_surfacewater_option}>
                                                                            <Select
                                                                                field="surfacewater.lake_natural.riparian_vegetation"
                                                                                label={null}
                                                                                options={MAKE_OPTIONS(
                                                                                    surfaceWaterRiparianVegetation,
                                                                                )}
                                                                                fullWidth
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_lakes_artificial && (
                                                                        <div style={factor_surfacewater_option}>
                                                                            <Select
                                                                                field="surfacewater.lake_artificial.riparian_vegetation"
                                                                                label={null}
                                                                                options={MAKE_OPTIONS(
                                                                                    surfaceWaterRiparianVegetation,
                                                                                )}
                                                                                fullWidth
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <Grid container spacing={8}>
                                                            <Grid item xs={factor_surfacewater_question}>
                                                                <Typography>
                                                                    4a. What % of riparian zone management is in a
                                                                    conservation easement or undisturbed?
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={factor_surfacewater_options}>
                                                                <div className={classes.optionsContainer}>
                                                                    {has_what.has_streams && (
                                                                        <div style={factor_surfacewater_option}>
                                                                            <TextField
                                                                                type="number"
                                                                                field="surfacewater.stream.riparian_undisturbed"
                                                                                label={null}
                                                                                fullWidth
                                                                                margin="none"
                                                                                units="%"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_rivers && (
                                                                        <div style={factor_surfacewater_option}>
                                                                            <TextField
                                                                                type="number"
                                                                                field="surfacewater.river.riparian_undisturbed"
                                                                                label={null}
                                                                                fullWidth
                                                                                margin="none"
                                                                                units="%"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_ponds_natural && (
                                                                        <div style={factor_surfacewater_option}>
                                                                            <TextField
                                                                                type="number"
                                                                                field="surfacewater.pond_natural.riparian_undisturbed"
                                                                                label={null}
                                                                                fullWidth
                                                                                margin="none"
                                                                                units="%"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_ponds_artificial && (
                                                                        <div style={factor_surfacewater_option}>
                                                                            <TextField
                                                                                type="number"
                                                                                field="surfacewater.pond_artificial.riparian_undisturbed"
                                                                                label={null}
                                                                                fullWidth
                                                                                margin="none"
                                                                                units="%"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_lakes_natural && (
                                                                        <div style={factor_surfacewater_option}>
                                                                            <TextField
                                                                                type="number"
                                                                                field="surfacewater.lake_natural.riparian_undisturbed"
                                                                                label={null}
                                                                                fullWidth
                                                                                units="%"
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_lakes_artificial && (
                                                                        <div style={factor_surfacewater_option}>
                                                                            <TextField
                                                                                type="number"
                                                                                field="surfacewater.lake_artificial.riparian_undisturbed"
                                                                                label={null}
                                                                                fullWidth
                                                                                margin="none"
                                                                                units="%"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <Grid container spacing={8}>
                                                            <Grid item xs={factor_surfacewater_question}>
                                                                <Typography>
                                                                    4b. What % of riparian zone is with limited cattle
                                                                    access?
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={factor_surfacewater_options}>
                                                                <div className={classes.optionsContainer}>
                                                                    {has_what.has_streams && (
                                                                        <div style={factor_surfacewater_option}>
                                                                            <TextField
                                                                                type="number"
                                                                                field="surfacewater.stream.riparian_limited_cattle_access"
                                                                                label={null}
                                                                                fullWidth
                                                                                units="%"
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_rivers && (
                                                                        <div style={factor_surfacewater_option}>
                                                                            <TextField
                                                                                type="number"
                                                                                field="surfacewater.river.riparian_limited_cattle_access"
                                                                                label={null}
                                                                                fullWidth
                                                                                units="%"
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_ponds_natural && (
                                                                        <div style={factor_surfacewater_option}>
                                                                            <TextField
                                                                                type="number"
                                                                                field="surfacewater.pond_natural.riparian_limited_cattle_access"
                                                                                label={null}
                                                                                fullWidth
                                                                                units="%"
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_ponds_artificial && (
                                                                        <div style={factor_surfacewater_option}>
                                                                            <TextField
                                                                                type="number"
                                                                                field="surfacewater.pond_artificial.riparian_limited_cattle_access"
                                                                                label={null}
                                                                                fullWidth
                                                                                units="%"
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_lakes_natural && (
                                                                        <div style={factor_surfacewater_option}>
                                                                            <TextField
                                                                                type="number"
                                                                                field="surfacewater.lake_natural.riparian_limited_cattle_access"
                                                                                label={null}
                                                                                fullWidth
                                                                                units="%"
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_lakes_artificial && (
                                                                        <div style={factor_surfacewater_option}>
                                                                            <TextField
                                                                                type="number"
                                                                                field="surfacewater.lake_artificial.riparian_limited_cattle_access"
                                                                                label={null}
                                                                                fullWidth
                                                                                units="%"
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <Grid container spacing={8}>
                                                            <Grid item xs={factor_surfacewater_question}>
                                                                <Typography>
                                                                    4c. What % of riparian zone is with unlimited cattle
                                                                    access?
                                                                </Typography>
                                                            </Grid>

                                                            <Grid item xs={factor_surfacewater_options}>
                                                                <div className={classes.optionsContainer}>
                                                                    {has_what.has_streams && (
                                                                        <div style={factor_surfacewater_option}>
                                                                            <TextField
                                                                                type="number"
                                                                                field="surfacewater.stream.riparian_unlimited_cattle_access"
                                                                                label={null}
                                                                                fullWidth
                                                                                units="%"
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_rivers && (
                                                                        <div style={factor_surfacewater_option}>
                                                                            <TextField
                                                                                type="number"
                                                                                field="surfacewater.river.riparian_unlimited_cattle_access"
                                                                                label={null}
                                                                                fullWidth
                                                                                units="%"
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_ponds_natural && (
                                                                        <div style={factor_surfacewater_option}>
                                                                            <TextField
                                                                                type="number"
                                                                                field="surfacewater.pond_natural.riparian_unlimited_cattle_access"
                                                                                label={null}
                                                                                fullWidth
                                                                                units="%"
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_ponds_artificial && (
                                                                        <div style={factor_surfacewater_option}>
                                                                            <TextField
                                                                                type="number"
                                                                                field="surfacewater.pond_artificial.riparian_unlimited_cattle_access"
                                                                                label={null}
                                                                                fullWidth
                                                                                units="%"
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_lakes_natural && (
                                                                        <div style={factor_surfacewater_option}>
                                                                            <TextField
                                                                                type="number"
                                                                                field="surfacewater.lake_natural.riparian_unlimited_cattle_access"
                                                                                label={null}
                                                                                fullWidth
                                                                                units="%"
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_lakes_artificial && (
                                                                        <div style={factor_surfacewater_option}>
                                                                            <TextField
                                                                                type="number"
                                                                                field="surfacewater.lake_artificial.riparian_unlimited_cattle_access"
                                                                                label={null}
                                                                                fullWidth
                                                                                units="%"
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <Grid container spacing={8}>
                                                            <Grid item xs={factor_surfacewater_question}>
                                                                <Typography>
                                                                    4d. What % of riparian zone is in crop production?
                                                                </Typography>
                                                            </Grid>

                                                            <Grid item xs={factor_surfacewater_options}>
                                                                <div className={classes.optionsContainer}>
                                                                    {has_what.has_streams && (
                                                                        <div style={factor_surfacewater_option}>
                                                                            <TextField
                                                                                type="number"
                                                                                field="surfacewater.stream.riparian_crop"
                                                                                label={null}
                                                                                fullWidth
                                                                                units="%"
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_rivers && (
                                                                        <div style={factor_surfacewater_option}>
                                                                            <TextField
                                                                                type="number"
                                                                                field="surfacewater.river.riparian_crop"
                                                                                label={null}
                                                                                fullWidth
                                                                                units="%"
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_ponds_natural && (
                                                                        <div style={factor_surfacewater_option}>
                                                                            <TextField
                                                                                type="number"
                                                                                field="surfacewater.pond_natural.riparian_crop"
                                                                                label={null}
                                                                                fullWidth
                                                                                units="%"
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_ponds_artificial && (
                                                                        <div style={factor_surfacewater_option}>
                                                                            <TextField
                                                                                type="number"
                                                                                field="surfacewater.pond_artificial.riparian_crop"
                                                                                label={null}
                                                                                fullWidth
                                                                                units="%"
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_lakes_natural && (
                                                                        <div style={factor_surfacewater_option}>
                                                                            <TextField
                                                                                type="number"
                                                                                field="surfacewater.lake_natural.riparian_crop"
                                                                                label={null}
                                                                                fullWidth
                                                                                units="%"
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_lakes_artificial && (
                                                                        <div style={factor_surfacewater_option}>
                                                                            <TextField
                                                                                type="number"
                                                                                field="surfacewater.lake_artificial.riparian_crop"
                                                                                label={null}
                                                                                fullWidth
                                                                                units="%"
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <Grid container spacing={8}>
                                                            <Grid item xs={factor_surfacewater_question}>
                                                                <Typography>
                                                                    4e. What % of riparian zone is with roads or
                                                                    constructed features?
                                                                </Typography>
                                                            </Grid>

                                                            <Grid item xs={factor_surfacewater_options}>
                                                                <div className={classes.optionsContainer}>
                                                                    {has_what.has_streams && (
                                                                        <div style={factor_surfacewater_option}>
                                                                            <TextField
                                                                                type="number"
                                                                                field="surfacewater.stream.riparian_impervious"
                                                                                label={null}
                                                                                fullWidth
                                                                                units="%"
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_rivers && (
                                                                        <div style={factor_surfacewater_option}>
                                                                            <TextField
                                                                                type="number"
                                                                                field="surfacewater.river.riparian_impervious"
                                                                                label={null}
                                                                                fullWidth
                                                                                units="%"
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_ponds_natural && (
                                                                        <div style={factor_surfacewater_option}>
                                                                            <TextField
                                                                                type="number"
                                                                                field="surfacewater.pond_natural.riparian_impervious"
                                                                                label={null}
                                                                                fullWidth
                                                                                units="%"
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_ponds_artificial && (
                                                                        <div style={factor_surfacewater_option}>
                                                                            <TextField
                                                                                type="number"
                                                                                field="surfacewater.pond_artificial.riparian_impervious"
                                                                                label={null}
                                                                                fullWidth
                                                                                units="%"
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_lakes_natural && (
                                                                        <div style={factor_surfacewater_option}>
                                                                            <TextField
                                                                                type="number"
                                                                                field="surfacewater.lake_natural.riparian_impervious"
                                                                                label={null}
                                                                                fullWidth
                                                                                units="%"
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_lakes_artificial && (
                                                                        <div style={factor_surfacewater_option}>
                                                                            <TextField
                                                                                type="number"
                                                                                field="surfacewater.lake_artificial.riparian_impervious"
                                                                                label={null}
                                                                                fullWidth
                                                                                units="%"
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <Grid container spacing={8}>
                                                            <Grid item xs={factor_surfacewater_question}>
                                                                <Typography>
                                                                    5. What is the condition for the majority of the
                                                                    surface water banks?
                                                                </Typography>
                                                            </Grid>

                                                            <Grid item xs={factor_surfacewater_options}>
                                                                <div className={classes.optionsContainer}>
                                                                    {has_what.has_streams && (
                                                                        <div style={factor_surfacewater_option}>
                                                                            <Select
                                                                                field="surfacewater.stream.bank_condition"
                                                                                label={null}
                                                                                options={MAKE_OPTIONS(bankStability)}
                                                                                fullWidth
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_rivers && (
                                                                        <div style={factor_surfacewater_option}>
                                                                            <Select
                                                                                field="surfacewater.river.bank_condition"
                                                                                label={null}
                                                                                options={MAKE_OPTIONS(bankStability)}
                                                                                fullWidth
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_ponds_natural && (
                                                                        <div style={factor_surfacewater_option}>
                                                                            <Select
                                                                                field="surfacewater.pond_natural.bank_condition"
                                                                                label={null}
                                                                                options={MAKE_OPTIONS(bankStability)}
                                                                                fullWidth
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_ponds_artificial && (
                                                                        <div style={factor_surfacewater_option}>
                                                                            <Select
                                                                                field="surfacewater.pond_artificial.bank_condition"
                                                                                label={null}
                                                                                options={MAKE_OPTIONS(bankStability)}
                                                                                fullWidth
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_lakes_natural && (
                                                                        <div style={factor_surfacewater_option}>
                                                                            <Select
                                                                                field="surfacewater.lake_natural.bank_condition"
                                                                                label={null}
                                                                                options={MAKE_OPTIONS(bankStability)}
                                                                                fullWidth
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_lakes_artificial && (
                                                                        <div style={factor_surfacewater_option}>
                                                                            <Select
                                                                                field="surfacewater.lake_artificial.bank_condition"
                                                                                label={null}
                                                                                options={MAKE_OPTIONS(bankStability)}
                                                                                fullWidth
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <Grid container spacing={8}>
                                                            <Grid item xs={factor_surfacewater_question}>
                                                                <Typography>
                                                                    6. What is the management for the majority of the
                                                                    surface water banks?
                                                                </Typography>
                                                            </Grid>

                                                            <Grid item xs={factor_surfacewater_options}>
                                                                <div className={classes.optionsContainer}>
                                                                    {has_what.has_streams && (
                                                                        <div style={factor_surfacewater_option}>
                                                                            <Select
                                                                                field="surfacewater.stream.bank_management"
                                                                                label={null}
                                                                                options={MAKE_OPTIONS(bankManagement)}
                                                                                fullWidth
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_rivers && (
                                                                        <div style={factor_surfacewater_option}>
                                                                            <Select
                                                                                field="surfacewater.river.bank_management"
                                                                                label={null}
                                                                                options={MAKE_OPTIONS(bankManagement)}
                                                                                fullWidth
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_ponds_natural && (
                                                                        <div style={factor_surfacewater_option}>
                                                                            <Select
                                                                                field="surfacewater.pond_natural.bank_management"
                                                                                label={null}
                                                                                options={MAKE_OPTIONS(bankManagement)}
                                                                                fullWidth
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_ponds_artificial && (
                                                                        <div style={factor_surfacewater_option}>
                                                                            <Select
                                                                                field="surfacewater.pond_artificial.bank_management"
                                                                                label={null}
                                                                                options={MAKE_OPTIONS(bankManagement)}
                                                                                fullWidth
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_lakes_natural && (
                                                                        <div style={factor_surfacewater_option}>
                                                                            <Select
                                                                                field="surfacewater.lake_natural.bank_management"
                                                                                label={null}
                                                                                options={MAKE_OPTIONS(bankManagement)}
                                                                                fullWidth
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_lakes_artificial && (
                                                                        <div style={factor_surfacewater_option}>
                                                                            <Select
                                                                                field="surfacewater.lake_artificial.bank_management"
                                                                                label={null}
                                                                                options={MAKE_OPTIONS(bankManagement)}
                                                                                fullWidth
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <Grid container spacing={8}>
                                                            <Grid item xs={factor_surfacewater_question}>
                                                                <Typography>
                                                                    7. What is the extent of modifications to the
                                                                    river/stream channel (on the farm)?
                                                                </Typography>
                                                            </Grid>

                                                            <Grid item xs={factor_surfacewater_options}>
                                                                <div className={classes.optionsContainer}>
                                                                    {has_what.has_streams === false &&
                                                                        has_what.has_rivers === false && (
                                                                            <div
                                                                                style={{
                                                                                    width: "100%",
                                                                                    "text-align": "center",
                                                                                }}
                                                                            >
                                                                                <Typography>Not Applicable</Typography>
                                                                            </div>
                                                                        )}

                                                                    {has_what.has_streams && (
                                                                        <div style={factor_surfacewater_option}>
                                                                            <Select
                                                                                field="surfacewater.stream.modifications"
                                                                                label={null}
                                                                                options={MAKE_OPTIONS(
                                                                                    surfaceWaterChannelization,
                                                                                )}
                                                                                fullWidth
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_rivers && (
                                                                        <div style={factor_surfacewater_option}>
                                                                            <Select
                                                                                field="surfacewater.river.modifications"
                                                                                label={null}
                                                                                options={MAKE_OPTIONS(
                                                                                    surfaceWaterChannelization,
                                                                                )}
                                                                                fullWidth
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}

                                                                    {(has_what.has_streams || has_what.has_rivers) && (
                                                                        <>
                                                                            {has_what.has_ponds_natural && (
                                                                                <div
                                                                                    style={factor_surfacewater_option}
                                                                                />
                                                                            )}
                                                                            {has_what.has_ponds_artificial && (
                                                                                <div
                                                                                    style={factor_surfacewater_option}
                                                                                />
                                                                            )}
                                                                            {has_what.has_lakes_natural && (
                                                                                <div
                                                                                    style={factor_surfacewater_option}
                                                                                />
                                                                            )}
                                                                            {has_what.has_lakes_artificial && (
                                                                                <div
                                                                                    style={factor_surfacewater_option}
                                                                                />
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <Grid container spacing={8}>
                                                            <Grid item xs={factor_surfacewater_question}>
                                                                <Typography>
                                                                    8. Are levee(s) or dam(s) present (on the farm)?
                                                                </Typography>
                                                            </Grid>

                                                            <Grid item xs={factor_surfacewater_options}>
                                                                <div className={classes.optionsContainer}>
                                                                    {has_what.has_streams === false &&
                                                                        has_what.has_rivers === false && (
                                                                            <div
                                                                                style={{
                                                                                    width: "100%",
                                                                                    "text-align": "center",
                                                                                }}
                                                                            >
                                                                                <Typography>Not Applicable</Typography>
                                                                            </div>
                                                                        )}

                                                                    {has_what.has_streams && (
                                                                        <div style={factor_surfacewater_option}>
                                                                            <Select
                                                                                field="surfacewater.stream.dams"
                                                                                label={null}
                                                                                options={YES_NO_OPTIONS}
                                                                                fullWidth
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_rivers && (
                                                                        <div style={factor_surfacewater_option}>
                                                                            <Select
                                                                                field="surfacewater.river.dams"
                                                                                label={null}
                                                                                options={YES_NO_OPTIONS}
                                                                                fullWidth
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}

                                                                    {(has_what.has_streams || has_what.has_rivers) && (
                                                                        <>
                                                                            {has_what.has_ponds_natural && (
                                                                                <div
                                                                                    style={factor_surfacewater_option}
                                                                                />
                                                                            )}
                                                                            {has_what.has_ponds_artificial && (
                                                                                <div
                                                                                    style={factor_surfacewater_option}
                                                                                />
                                                                            )}
                                                                            {has_what.has_lakes_natural && (
                                                                                <div
                                                                                    style={factor_surfacewater_option}
                                                                                />
                                                                            )}
                                                                            {has_what.has_lakes_artificial && (
                                                                                <div
                                                                                    style={factor_surfacewater_option}
                                                                                />
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <Grid container spacing={8}>
                                                            <Grid item xs={factor_surfacewater_question}>
                                                                <Typography>
                                                                    9. What is the predominant use for the pond/lake?
                                                                </Typography>
                                                            </Grid>

                                                            <Grid item xs={factor_surfacewater_options}>
                                                                <div className={classes.optionsContainer}>
                                                                    {has_what.has_ponds_natural === false &&
                                                                        has_what.has_ponds_artificial === false &&
                                                                        has_what.has_lakes_natural === false &&
                                                                        has_what.has_lakes_artificial === false && (
                                                                            <div
                                                                                style={{
                                                                                    width: "100%",
                                                                                    "text-align": "center",
                                                                                }}
                                                                            >
                                                                                <Typography>Not Applicable</Typography>
                                                                            </div>
                                                                        )}

                                                                    {(has_what.has_ponds_natural ||
                                                                        has_what.has_ponds_artificial ||
                                                                        has_what.has_lakes_natural ||
                                                                        has_what.has_lakes_artificial) && (
                                                                        <>
                                                                            {has_what.has_streams && (
                                                                                <div
                                                                                    style={factor_surfacewater_option}
                                                                                />
                                                                            )}
                                                                            {has_what.has_rivers && (
                                                                                <div
                                                                                    style={factor_surfacewater_option}
                                                                                />
                                                                            )}

                                                                            {has_what.has_ponds_natural && (
                                                                                <div style={factor_surfacewater_option}>
                                                                                    <Select
                                                                                        field="surfacewater.pond_natural.predominant_use"
                                                                                        label={null}
                                                                                        options={MAKE_OPTIONS(
                                                                                            surfaceWaterPredominantUse,
                                                                                        )}
                                                                                        fullWidth
                                                                                        margin="none"
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                            {has_what.has_ponds_artificial && (
                                                                                <div style={factor_surfacewater_option}>
                                                                                    <Select
                                                                                        field="surfacewater.pond_artificial.predominant_use"
                                                                                        label={null}
                                                                                        options={MAKE_OPTIONS(
                                                                                            surfaceWaterPredominantUse,
                                                                                        )}
                                                                                        fullWidth
                                                                                        margin="none"
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                            {has_what.has_lakes_natural && (
                                                                                <div style={factor_surfacewater_option}>
                                                                                    <Select
                                                                                        field="surfacewater.lake_natural.predominant_use"
                                                                                        label={null}
                                                                                        options={MAKE_OPTIONS(
                                                                                            surfaceWaterPredominantUse,
                                                                                        )}
                                                                                        fullWidth
                                                                                        margin="none"
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                            {has_what.has_lakes_artificial && (
                                                                                <div style={factor_surfacewater_option}>
                                                                                    <Select
                                                                                        field="surfacewater.lake_artificial.predominant_use"
                                                                                        label={null}
                                                                                        options={MAKE_OPTIONS(
                                                                                            surfaceWaterPredominantUse,
                                                                                        )}
                                                                                        fullWidth
                                                                                        margin="none"
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <Grid container spacing={8}>
                                                            <Grid item xs={factor_surfacewater_question}>
                                                                <Typography>
                                                                    10. What is the average depth of the pond/lake?
                                                                </Typography>
                                                            </Grid>

                                                            <Grid item xs={factor_surfacewater_options}>
                                                                <div className={classes.optionsContainer}>
                                                                    {has_what.has_ponds_natural === false &&
                                                                        has_what.has_ponds_artificial === false &&
                                                                        has_what.has_lakes_natural === false &&
                                                                        has_what.has_lakes_artificial === false && (
                                                                            <div
                                                                                style={{
                                                                                    width: "100%",
                                                                                    "text-align": "center",
                                                                                }}
                                                                            >
                                                                                <Typography>Not Applicable</Typography>
                                                                            </div>
                                                                        )}

                                                                    {(has_what.has_ponds_natural ||
                                                                        has_what.has_ponds_artificial ||
                                                                        has_what.has_lakes_natural ||
                                                                        has_what.has_lakes_artificial) && (
                                                                        <>
                                                                            {has_what.has_streams && (
                                                                                <div
                                                                                    style={factor_surfacewater_option}
                                                                                />
                                                                            )}
                                                                            {has_what.has_rivers && (
                                                                                <div
                                                                                    style={factor_surfacewater_option}
                                                                                />
                                                                            )}

                                                                            {has_what.has_ponds_natural && (
                                                                                <div style={factor_surfacewater_option}>
                                                                                    <Select
                                                                                        field="surfacewater.pond_natural.average_depth"
                                                                                        label={null}
                                                                                        options={MAKE_OPTIONS(
                                                                                            surfaceWaterAverageDepth,
                                                                                        )}
                                                                                        fullWidth
                                                                                        margin="none"
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                            {has_what.has_ponds_artificial && (
                                                                                <div style={factor_surfacewater_option}>
                                                                                    <Select
                                                                                        field="surfacewater.pond_artificial.average_depth"
                                                                                        label={null}
                                                                                        options={MAKE_OPTIONS(
                                                                                            surfaceWaterAverageDepth,
                                                                                        )}
                                                                                        fullWidth
                                                                                        margin="none"
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                            {has_what.has_lakes_natural && (
                                                                                <div style={factor_surfacewater_option}>
                                                                                    <Select
                                                                                        field="surfacewater.lake_natural.average_depth"
                                                                                        label={null}
                                                                                        options={MAKE_OPTIONS(
                                                                                            surfaceWaterAverageDepth,
                                                                                        )}
                                                                                        fullWidth
                                                                                        margin="none"
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                            {has_what.has_lakes_artificial && (
                                                                                <div style={factor_surfacewater_option}>
                                                                                    <Select
                                                                                        field="surfacewater.lake_artificial.average_depth"
                                                                                        label={null}
                                                                                        options={MAKE_OPTIONS(
                                                                                            surfaceWaterAverageDepth,
                                                                                        )}
                                                                                        fullWidth
                                                                                        margin="none"
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <Typography>
                                                            11. How are you managing the in-stream habitat of the
                                                            river/stream? (Check all that apply)
                                                        </Typography>
                                                    </Grid>

                                                    {has_what.has_streams === false &&
                                                        has_what.has_rivers === false && (
                                                            <Grid item xs={12}>
                                                                <Typography align="center">Not Applicable.</Typography>
                                                            </Grid>
                                                        )}

                                                    <Grid item xs={12}>
                                                        <div className={classes.indent}>
                                                            <Grid container spacing={0}>
                                                                {has_what.has_streams && (
                                                                    <Grid
                                                                        item
                                                                        xs={factor_surfacewater_stream_management_xs}
                                                                        sm={factor_surfacewater_stream_management_sm}
                                                                        md={factor_surfacewater_stream_management_md}
                                                                    >
                                                                        <Typography>Streams</Typography>
                                                                        <Grid container xs={12}>
                                                                            <CheckboxGroup
                                                                                field="surfacewater.stream.instream_habitat"
                                                                                row={false}
                                                                                options={MAKE_OPTIONS(
                                                                                    surfaceWaterInstreamHabitat.filter(
                                                                                        (h) => h.id !== "5",
                                                                                    ),
                                                                                )}
                                                                                eventHandle={(e) =>
                                                                                    this.checkNoneOfTheAbove(
                                                                                        e,
                                                                                        "surfacewater.stream.instream_habitat",
                                                                                        surfaceWaterInstreamHabitat,
                                                                                    )
                                                                                }
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                )}
                                                                {has_what.has_rivers && (
                                                                    <Grid
                                                                        item
                                                                        xs={factor_surfacewater_stream_management_xs}
                                                                        sm={factor_surfacewater_stream_management_sm}
                                                                        md={factor_surfacewater_stream_management_md}
                                                                    >
                                                                        <Typography>Rivers</Typography>
                                                                        <Grid container xs={12}>
                                                                            <CheckboxGroup
                                                                                field="surfacewater.river.instream_habitat"
                                                                                row={false}
                                                                                options={MAKE_OPTIONS(
                                                                                    surfaceWaterInstreamHabitat,
                                                                                )}
                                                                                eventHandle={(e) =>
                                                                                    this.checkNoneOfTheAbove(
                                                                                        e,
                                                                                        "surfacewater.river.instream_habitat",
                                                                                        surfaceWaterInstreamHabitat,
                                                                                    )
                                                                                }
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                )}
                                                            </Grid>
                                                        </div>
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <Typography>
                                                            12. How are you managing the pond/lake? (Check all that
                                                            apply)
                                                        </Typography>
                                                    </Grid>

                                                    {has_what.has_ponds_natural === false &&
                                                        has_what.has_ponds_artificial === false &&
                                                        has_what.has_lakes_natural === false &&
                                                        has_what.has_lakes_artificial === false && (
                                                            <Grid item xs={12}>
                                                                <Typography align="center">Not Applicable.</Typography>
                                                            </Grid>
                                                        )}

                                                    {(has_what.has_ponds_natural ||
                                                        has_what.has_ponds_artificial ||
                                                        has_what.has_lakes_natural ||
                                                        has_what.has_lakes_artificial) && (
                                                        <Grid item xs={12}>
                                                            <div className={classes.indent}>
                                                                <Grid container spacing={0}>
                                                                    {has_what.has_ponds_natural && (
                                                                        <Grid
                                                                            item
                                                                            xs={factor_surfacewater_pond_management_xs}
                                                                            sm={factor_surfacewater_pond_management_sm}
                                                                            md={factor_surfacewater_pond_management_md}
                                                                        >
                                                                            <Typography>Pond (natural)</Typography>
                                                                            <Grid container xs={12}>
                                                                                <CheckboxGroup
                                                                                    field="surfacewater.pond_natural.management"
                                                                                    row={false}
                                                                                    options={MAKE_OPTIONS(
                                                                                        surfaceWaterManagement,
                                                                                    )}
                                                                                    eventHandle={(e) =>
                                                                                        this.checkNoneOfTheAbove(
                                                                                            e,
                                                                                            "surfacewater.pond_natural.management",
                                                                                            surfaceWaterManagement,
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </Grid>
                                                                        </Grid>
                                                                    )}
                                                                    {has_what.has_ponds_artificial && (
                                                                        <Grid
                                                                            item
                                                                            xs={factor_surfacewater_pond_management_xs}
                                                                            sm={factor_surfacewater_pond_management_sm}
                                                                            md={factor_surfacewater_pond_management_md}
                                                                        >
                                                                            <Typography>Pond (artificial)</Typography>
                                                                            <Grid container xs={12}>
                                                                                <CheckboxGroup
                                                                                    field="surfacewater.pond_artificial.management"
                                                                                    row={false}
                                                                                    options={MAKE_OPTIONS(
                                                                                        surfaceWaterManagement,
                                                                                    )}
                                                                                    eventHandle={(e) =>
                                                                                        this.checkNoneOfTheAbove(
                                                                                            e,
                                                                                            "surfacewater.pond_artificial.management",
                                                                                            surfaceWaterManagement,
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </Grid>
                                                                        </Grid>
                                                                    )}
                                                                    {has_what.has_lakes_natural && (
                                                                        <Grid
                                                                            item
                                                                            xs={factor_surfacewater_pond_management_xs}
                                                                            sm={factor_surfacewater_pond_management_sm}
                                                                            md={factor_surfacewater_pond_management_md}
                                                                        >
                                                                            <Typography>Lake (natural)</Typography>
                                                                            <Grid container xs={12}>
                                                                                <CheckboxGroup
                                                                                    field="surfacewater.lake_natural.management"
                                                                                    row={false}
                                                                                    options={MAKE_OPTIONS(
                                                                                        surfaceWaterManagement,
                                                                                    )}
                                                                                    eventHandle={(e) =>
                                                                                        this.checkNoneOfTheAbove(
                                                                                            e,
                                                                                            "surfacewater.lake_natural.management",
                                                                                            surfaceWaterManagement,
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </Grid>
                                                                        </Grid>
                                                                    )}
                                                                    {has_what.has_lakes_artificial && (
                                                                        <Grid
                                                                            item
                                                                            xs={factor_surfacewater_pond_management_xs}
                                                                            sm={factor_surfacewater_pond_management_sm}
                                                                            md={factor_surfacewater_pond_management_md}
                                                                        >
                                                                            <Typography>Lake (artificial)</Typography>
                                                                            <Grid container xs={12}>
                                                                                <CheckboxGroup
                                                                                    field="surfacewater.lake_artificial.management"
                                                                                    row={false}
                                                                                    options={MAKE_OPTIONS(
                                                                                        surfaceWaterManagement,
                                                                                    )}
                                                                                    eventHandle={(e) =>
                                                                                        this.checkNoneOfTheAbove(
                                                                                            e,
                                                                                            "surfacewater.lake_artificial.management",
                                                                                            surfaceWaterManagement,
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </Grid>
                                                                        </Grid>
                                                                    )}
                                                                </Grid>
                                                            </div>
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                )}

                                {/* //////////////////////////////////////////////////////
                             // Wetlands
                             ////////////////////////////////////////////////////// */}
                                {(has_what.has_wetland_natural || has_what.has_wetland_artificial) && (
                                    <Grid item xs={12}>
                                        <Accordion defaultExpanded={true}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="wetland-content"
                                                id="wetland-header"
                                                className={classes.expansionPannelSummary}
                                            >
                                                <Typography className={classes.heading}>Wetlands</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid container spacing={16}>
                                                    <Grid item xs={12}>
                                                        <Grid container spacing={8}>
                                                            <Grid item xs={factor_wetland_question} />

                                                            <Grid item xs={factor_wetland_options}>
                                                                <div className={classes.optionsContainer}>
                                                                    {has_what.has_wetland_natural && (
                                                                        <div style={factor_wetland_header}>
                                                                            Natural or Restored Wetland
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_wetland_artificial && (
                                                                        <div style={factor_wetland_header}>
                                                                            Artificial Wetland
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <Grid container spacing={8}>
                                                            <Grid item xs={factor_wetland_question}>
                                                                <Typography>
                                                                    1. How many acres of this land type do you have on
                                                                    this farm?
                                                                </Typography>
                                                            </Grid>

                                                            <Grid item xs={factor_wetland_options}>
                                                                <div className={classes.optionsContainer}>
                                                                    {has_what.has_wetland_natural && (
                                                                        <div style={factor_wetland_option}>
                                                                            <TextField
                                                                                type="number"
                                                                                field="wetland.natural.area"
                                                                                label={null}
                                                                                fullWidth
                                                                                units="ac"
                                                                                margin="normal"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_wetland_artificial && (
                                                                        <div style={factor_wetland_option}>
                                                                            <TextField
                                                                                type="number"
                                                                                field="wetland.artificial.area"
                                                                                label={null}
                                                                                fullWidth
                                                                                units="ac"
                                                                                margin="normal"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <Grid container spacing={8}>
                                                            <Grid item xs={factor_wetland_question}>
                                                                <Typography>
                                                                    2. Estimate how many acres of this land type has
                                                                    been converted from another land type in the past 5
                                                                    years?
                                                                </Typography>
                                                            </Grid>

                                                            <Grid item xs={factor_wetland_options}>
                                                                <div className={classes.optionsContainer}>
                                                                    {has_what.has_wetland_natural && (
                                                                        <div style={factor_wetland_option}>
                                                                            <TextField
                                                                                type="number"
                                                                                field="wetland.natural.area_converted"
                                                                                label={null}
                                                                                fullWidth
                                                                                units="ac"
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {has_what.has_wetland_artificial && (
                                                                        <div style={factor_wetland_option}>
                                                                            <TextField
                                                                                type="number"
                                                                                field="wetland.artificial.area_converted"
                                                                                label={null}
                                                                                fullWidth
                                                                                units="ac"
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    {this.getGroupHasConverted(formApi.values, "wetland") === true && (
                                                        <Grid item xs={12}>
                                                            <Grid container spacing={8}>
                                                                <Grid
                                                                    item
                                                                    xs={factor_wetland_question}
                                                                    style={{ "padding-left": "16px" }}
                                                                >
                                                                    <Typography>
                                                                        What land type was this land converted from?
                                                                    </Typography>
                                                                </Grid>

                                                                <Grid item xs={factor_wetland_options}>
                                                                    <div className={classes.optionsContainer}>
                                                                        {has_what.has_wetland_natural && (
                                                                            <div style={factor_wetland_option}>
                                                                                {this.getHasConverted(
                                                                                    formApi.values,
                                                                                    "wetland.natural",
                                                                                ) && (
                                                                                    <Select
                                                                                        field="wetland.natural.previous_land_cover"
                                                                                        options={MAKE_OPTIONS(
                                                                                            land_types,
                                                                                        )}
                                                                                        fullWidth
                                                                                        margin="none"
                                                                                    />
                                                                                )}
                                                                            </div>
                                                                        )}
                                                                        {has_what.has_wetland_artificial && (
                                                                            <div style={factor_wetland_option}>
                                                                                {this.getHasConverted(
                                                                                    formApi.values,
                                                                                    "wetland.artificial",
                                                                                ) && (
                                                                                    <Select
                                                                                        field="wetland.artificial.previous_land_cover"
                                                                                        options={MAKE_OPTIONS(
                                                                                            land_types,
                                                                                        )}
                                                                                        fullWidth
                                                                                        margin="none"
                                                                                    />
                                                                                )}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    )}

                                                    <Grid item xs={12}>
                                                        <Grid container spacing={8}>
                                                            <Grid item xs={factor_wetland_question}>
                                                                <Typography>3. What is the water regime?</Typography>
                                                            </Grid>

                                                            <Grid item xs={factor_wetland_options}>
                                                                <div className={classes.optionsContainer}>
                                                                    {has_what.has_wetland_natural && (
                                                                        <div style={factor_wetland_option}>
                                                                            <Select
                                                                                field="wetland.natural.management"
                                                                                label={null}
                                                                                options={MAKE_OPTIONS(
                                                                                    naturalWetlandRegime,
                                                                                )}
                                                                                fullWidth
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}

                                                                    {has_what.has_wetland_artificial && (
                                                                        <div style={factor_wetland_option}>
                                                                            <Typography align="center">
                                                                                Not Applicable
                                                                            </Typography>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <Grid container spacing={8}>
                                                            <Grid item xs={factor_wetland_question}>
                                                                <Typography>
                                                                    4. Is water level management being used?
                                                                </Typography>
                                                            </Grid>

                                                            <Grid item xs={factor_wetland_options}>
                                                                <div className={classes.optionsContainer}>
                                                                    {has_what.has_wetland_natural && (
                                                                        <div style={factor_wetland_option}>
                                                                            <Typography align="center">
                                                                                Not Applicable
                                                                            </Typography>
                                                                        </div>
                                                                    )}

                                                                    {has_what.has_wetland_artificial && (
                                                                        <div style={factor_wetland_option}>
                                                                            <Select
                                                                                field="wetland.artificial.management"
                                                                                label={null}
                                                                                options={MAKE_OPTIONS([
                                                                                    { id: "1", name: "Yes" },
                                                                                    { id: "2", name: "No" },
                                                                                ])}
                                                                                fullWidth
                                                                                margin="none"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <Typography>
                                                            5. How do you manage the wetland for habitat (Check all that
                                                            apply or None of the above)
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <div className={classes.indent}>
                                                            <Grid container spacing={0}>
                                                                {has_what.has_wetland_natural && (
                                                                    <Grid
                                                                        item
                                                                        xs={factor_wetland_xs}
                                                                        sm={factor_wetland_sm}
                                                                        md={factor_wetland_md}
                                                                    >
                                                                        <Typography>
                                                                            Natural or Restored Wetland
                                                                        </Typography>
                                                                        <Grid container xs={12}>
                                                                            <CheckboxGroup
                                                                                field="wetland.natural.habitat_management"
                                                                                row={false}
                                                                                options={MAKE_OPTIONS(
                                                                                    wetlandManagement,
                                                                                )}
                                                                                eventHandle={(e) =>
                                                                                    this.checkNoneOfTheAbove(
                                                                                        e,
                                                                                        "wetland.natural.habitat_management",
                                                                                        wetlandManagement,
                                                                                    )
                                                                                }
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                )}
                                                                {has_what.has_wetland_artificial && (
                                                                    <Grid
                                                                        item
                                                                        xs={factor_wetland_xs}
                                                                        sm={factor_wetland_sm}
                                                                        md={factor_wetland_md}
                                                                    >
                                                                        <Typography>Artificial Wetland</Typography>
                                                                        <Grid container xs={12}>
                                                                            <CheckboxGroup
                                                                                field="wetland.artificial.habitat_management"
                                                                                row={false}
                                                                                options={MAKE_OPTIONS(
                                                                                    wetlandManagement,
                                                                                )}
                                                                                eventHandle={(e) =>
                                                                                    this.checkNoneOfTheAbove(
                                                                                        e,
                                                                                        "wetland.artificial.habitat_management",
                                                                                        wetlandManagement,
                                                                                    )
                                                                                }
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                )}
                                                            </Grid>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                )}

                                <Grid item xs={12} className={classes.rightAlign}>
                                    <Button
                                        variant="raised"
                                        type="submit"
                                        onClick={() => this.setState({ draftClicked: true, submitClicked: false })}
                                        className={classes.button}
                                    >
                                        Save and Return to Farm Homepage
                                    </Button>
                                    <Button
                                        variant="raised"
                                        type="submit"
                                        color="primary"
                                        onClick={() => this.setState({ draftClicked: false, submitClicked: true })}
                                        className={classes.button}
                                    >
                                        Save and Move to Next Step <ChevronRightIcon />
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </Form>
            </>
        );
    }
}

LandTypeQuestionaire = connect(
    (state, ownProps) => ({
        riparianVegetation: allRiparianVegetation(state),
        riparianManagement: allRiparianManagement(state),
        forestConfiguration: allForestConfiguration(state),
        forestManagement: allForestManagement(state),
        grasslandConfiguration: allGrasslandConfiguration(state),
        grazingIntensity: allGrazingIntensity(state),
        grasslandManagement: allGrasslandManagement(state),
        naturalWetlandRegime: allNaturalWetlandRegime(state),
        wetlandManagement: allWetlandManagement(state),
        surfaceWaterBufferPercentage: allSurfaceWaterBufferPercentage(state),
        surfaceWaterRiparianVegetation: allSurfaceWaterRiparianVegetation(state),
        bankStability: allBankStability(state),
        bankManagement: allBankManagement(state),
        surfaceWaterChannelization: allSurfaceWaterChannelization(state),
        surfaceWaterPredominantUse: allSurfaceWaterPredominantUse(state),
        surfaceWaterAverageDepth: allSurfaceWaterAverageDepth(state),
        surfaceWaterInstreamHabitat: allSurfaceWaterInstreamHabitat(state),
        surfaceWaterManagement: allSurfaceWaterManagement(state),
    }),
    {
        ...FarmHpiSurvey.actions,
        ...FarmHpiCultivatedLandQuestionnaire.actions,
        ...FarmHpiForestQuestionnaire.actions,
        ...FarmHpiGrasslandQuestionnaire.actions,
        ...FarmHpiRiparianQuestionnaire.actions,
        ...FarmHpiSurfaceWaterQuestionnaire.actions,
        ...FarmHpiWetlandQuestionnaire.actions,
    },
)(LandTypeQuestionaire);

export default withStyles(styles)(LandTypeQuestionaire);
