import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";

import Divider from "@mui/material/Divider";
import Grid from "../common/GridWrapper";
import Typography from "../common/TypographyWrapper";

import withStyles from "@mui/styles/withStyles";
import ExpandLess from "@mui/icons-material/ExpandLess";
import EyeIcon from "../common/icons/EyeIcon";
import { getValue } from "../../api/utils";
import { Field } from "./models";

const styles = (theme) => ({
    hidden: {
        opacity: 0,
        overflow: "hidden",
        padding: "0 !important",
        height: "0 !important",
    },
    panelText: {
        color: "#fff",
        textAlign: "center",
        cursor: "pointer",
        backgroundColor: "#34a853",
        marginLeft: "auto",
        marginRight: "auto",
        width: "155px",
        borderBottomLeftRadius: theme.spacing(3),
        borderBottomRightRadius: theme.spacing(3),
        padding: "0 16px 0 8px",
    },
    panelContainer: {
        padding: "20px",
        width: "100%",
        height: "165px",
        transition: theme.transitions.create(["height", "opacity"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    button: {
        height: "20px",
        width: "20px",
        color: "#fff",
    },
    marginTop: {
        marginTop: theme.spacing(3),
    },
    divider: {
        backgroundColor: "#34a853",
    },
    floatRight: {
        float: "right",
        marginRight: theme.spacing(1),
    },
    underlineBottom: {
        borderBottom: "1px dotted",
        marginBottom: theme.spacing(2),
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
    },
});

// Create custom expansion "panel"
class Facts extends Component {
    state = {
        expanded: false,
    };

    formatDate(dateString) {
        if (!dateString) {
            return null;
        }
        const d = new Date(dateString);
        return d.toLocaleDateString();
    }

    componentDidMount() {
        this.loadField({});
    }

    componentDidUpdate(prevProps) {
        this.loadField(prevProps);
    }

    loadField(prevProps) {
        const { field, ormFieldLoadDetail, syncState, authState } = this.props,
            { field: lastYear, syncState: lastSyncState } = prevProps;

        if (!field || !authState.user || authState.user.guest) {
            return;
        }
        if (!lastYear || lastYear.id !== field.id || !lastSyncState.ready) {
            if (syncState.ready && !getValue(field, "synced")) {
                ormFieldLoadDetail(field.id);
            }
        }
    }

    render() {
        const { classes, field } = this.props;
        const { expanded } = this.state;

        if (field === undefined)
            // This shouldn't actually happen
            return "Field Undefined";

        return (
            <div>
                <Grid
                    container
                    spacing={24}
                    className={classNames(classes.panelContainer, !expanded && classes.hidden)}
                >
                    <Grid item xs={4} xl={3}>
                        <Typography className={classes.underlineBottom}>
                            <b>FPP ID</b>: {field.fpp_id}
                        </Typography>
                        <Typography className={classes.underlineBottom}>
                            <b>Last Updated</b>: {this.formatDate(field.modify_date)}
                        </Typography>
                        <Typography className={classes.underlineBottom}>
                            <b>Last Updated By</b>: {field.modify_user}
                        </Typography>
                    </Grid>
                    <Grid item xs={4} xl={3}>
                        <Typography className={classes.underlineBottom}>
                            <b>Plantable Acres</b>: {field.size}
                        </Typography>
                        <Typography className={classes.underlineBottom}>
                            <b>Location</b>: {field.location}
                        </Typography>
                        <Typography className={classes.underlineBottom}>
                            <b>Crop Management Zone</b>: {field.cmz}
                        </Typography>
                    </Grid>
                    <Grid item xs={4} xl={3}>
                        <Typography className={classes.underlineBottom}>
                            <b>Farm Serial Number</b>: {getValue(field, "extrainfo.farm_serial_number")}
                        </Typography>
                        <Typography className={classes.underlineBottom}>
                            <b>Tract Number</b>: {getValue(field, "extrainfo.tract_number")}
                        </Typography>
                        <Typography className={classes.underlineBottom}>
                            <b>Field Number</b>: {getValue(field, "extrainfo.field_number")}
                        </Typography>
                    </Grid>
                </Grid>
                {expanded && (
                    <div>
                        <Divider className={classes.divider} />
                        <Typography onClick={() => this.setState({ expanded: false })} className={classes.panelText}>
                            <ExpandLess className={classes.button} />
                            <span className={classes.floatRight}>Hide Quick Facts</span>
                        </Typography>
                    </div>
                )}
                {!expanded && (
                    <div className={classes.marginTop} id="quickFacts">
                        <Divider className={classes.divider} />
                        <Typography onClick={() => this.setState({ expanded: true })} className={classes.panelText}>
                            <EyeIcon className={classes.button} />
                            <span className={classes.floatRight}>View Quick Facts</span>
                        </Typography>
                    </div>
                )}
            </div>
        );
    }
}

Facts = connect(
    (state, ownProps) => ({
        syncState: state.sync,
        authState: state.auth,
    }),
    {
        ...Field.actions,
    },
)(Facts);

export default withStyles(styles)(Facts);
