import { getValue, setValue } from "../../../api/utils";

export const farm_realized_levels = {
    high: {
        title: "High",
        text: "Your biodiversity assessment shows that you are well on your way to maximized your farm's potential to support wildlife habitat across cultivated and non-cultivated lands. Kudos! Consider sharing with other farmers and your trusted advisers what management practices have helped you manage for biodiversity.",
    },
    moderate: {
        title: "Moderate",
        text: "Your biodiversity assessment shows that there is still some unrealized potential for your farm to support wildlife habitat across cultivated and non-cultivated lands. Explore opportunities with your trusted adviser to manage for biodiversity.",
    },
    low: {
        title: "Low",
        text: "Your biodiversity assessment shows that there is significant unrealized potential for your farm to support wildlife habitat across cultivated and non-cultivated lands. Explore opportunities with your trusted adviser to manage for biodiversity.",
    },
};

// HPI-3 Residue Cover
export const hpi_tillage_scores = [
    { id: "1", value: 0.0 },
    { id: "2", value: 0.5 },
    { id: "3", value: 1.0 },
];

//  HPI-4 Crop Rotation
export const hpi_rotation_practices_scores = [
    { id: "1", value: 1.0 },
    { id: "2", value: 0.5 },
    { id: "3", value: 0.0 },
];

//  HPI-4 Alfalfa Crop Rotation
export const hpi_rotation_practices_alfalfa_scores = [
    { id: "4", value: 1.0 },
    { id: "5", value: 0.5 },
];

//  HPI-5 Cover Crop
export const hpi_cover_crop_scores = [
    { id: "1", value: 1.0 },
    { id: "2", value: 0.5 },
    { id: "3", value: 0.0 },
];

//  HPI-6 Nutrient Management
export const hpi_nutrient_4rs_scores = [
    { id: "1", value: 0.25 },
    { id: "2", value: 0.25 },
    { id: "3", value: 0.25 },
    { id: "4", value: 0.25 },
];

//  HPI-7 Pest Management
export const hpi_pest_management_scores = [
    { id: "6", value: 1.0 },
    { id: "7", value: 0.75 },
    { id: "8", value: 0.5 },
    { id: "9", value: 0.0 },
];

//  HPI-8 Water Conservation - Water Source
export const hpi_water_source_scores = [
    { id: "1", value: 1.0 },
    { id: "2", value: null },
    { id: "3", value: null },
    { id: "4", value: null },
];

//  HPI-8 Water Conservation - Conservation
export const hpi_water_conservation_scores = [
    { id: true, value: 0.75 },
    { id: false, value: 0.25 },
];

//  HPI-9 Water Drainage Management
export const hpi_drainage_scores = [
    { id: "1", value: 1.0, ftm_options: ["1"] },
    { id: "2", value: 1.0, ftm_options: ["3", "5"] },
    { id: "3", value: 0.5, ftm_options: ["4"] },
    { id: "4", value: 0.0, ftm_options: ["2"] },
];

//  HPI-10 Wildlife Habitat Enhancements
export const hpi_wildlife_habitat_scores = [
    { id: "1", value: 1, crop: "" },
    { id: "2", value: 1, crop: "" },
    { id: "3", value: 1, crop: "39" },
];

//  HPI-11 Buffer Vegetation
export const hpi_buffer_vegetation_scores = [
    { id: "1", value: 1.0 },
    { id: "2", value: 0.85 },
    { id: "3", value: 0.7 },
    { id: "4", value: 0.55 },
    { id: "5", value: 0.4 },
    { id: "6", value: 0.25 },
    { id: "7", value: 0.0 },
];

//  HPI-12 Buffer Management
export const hpi_buffer_management_scores = [
    { id: "1", value: 0.25 },
    { id: "2", value: 0.25 },
    { id: "3", value: 0.25 },
    { id: "4", value: 0.25 },
    { id: "5", value: 0.25 },
    { id: "6", value: 0.25 },
    { id: "7", value: 0.25 },
    { id: "8", value: 0.25 },
    { id: "9", value: 0.0 },
];

//  HPI-13 Forest Configuration
export const hpi_forest_configuration_scores = [
    { id: "1", value: 1 },
    { id: "2", value: 0.5 },
    { id: "3", value: 0.25 },
];

//  HPI-14 Forest Management
export const hpi_forest_management_scores = [
    { id: "1", value: 0.25 },
    { id: "2", value: 0.25 },
    { id: "3", value: 0.25 },
    { id: "4", value: 0.25 },
    { id: "5", value: 0.25 },
    { id: "6", value: 0.25 },
    { id: "7", value: 0.0 },
];

//  HPI-15 Grassland Configuration
export const hpi_grassland_configuration_scores = [
    { id: "1", value: 1.0 },
    { id: "2", value: 0.5 },
    { id: "3", value: 0.25 },
];

//  HPI-16 Grazing Intensity
export const hpi_grazing_intensity_scores = [
    { id: "1", value: 1.0 },
    { id: "2", value: 0.5 },
    { id: "3", value: 0.25 },
];

export const hpi_grazing_conservation_scores = [
    { id: "1", value: 0.5 },
    { id: "2", value: 0.0 },
];

//  HPI-17 Grassland Management
export const hpi_grassland_management_scores = [
    { id: "1", value: 1.0 },
    { id: "2", value: 0.25 },
    { id: "3", value: 0.25 },
    { id: "4", value: 0.25 },
    { id: "5", value: 0.25 },
    { id: "6", value: 0.25 },
    { id: "7", value: 0.25 },
    { id: "8", value: 0.0 },
];

//  HPI-18 Natural Wetland Regime
export const hpi_natural_wetland_regime_scores = [
    { id: "1", value: 0.75 },
    { id: "2", value: 0.5 },
    { id: "3", value: 0.5 },
    { id: "4", value: 0.25 },
    { id: "5", value: 0.25 },
];

//  HPI-19 Artificial Wetland Water Level Management
export const hpi_artificial_wetlands_water_level_management_scores = [
    { id: "1", value: 1 },
    { id: "2", value: 0 },
];

//  HPI-20 Wetland Habitat Management
export const hpi_wetland_habitat_management_scores = [
    { id: "1", value: 1.0 },
    { id: "2", value: 0.5 },
    { id: "3", value: 0.5 },
    { id: "4", value: 0 },
];

//  HPI-21 Surface Waters Riparian Buffer
export const hpi_surfacewaters_riparian_buffer_scores = [
    { id: "1", value: 1.0 },
    { id: "2", value: 0.5 },
    { id: "3", value: 0.25 },
    { id: "4", value: 0 },
];

//  HPI-22 Surface Waters Riparian Vegetation
export const hpi_surfacewaters_riparian_vegetation_scores = [
    { id: "1", value: 1.0 },
    { id: "2", value: 0.5 },
    { id: "3", value: 0.25 },
    { id: "4", value: 0 },
];

//  HPI-23 Surface Waters Riparian Management
export const hpi_surfacewaters_riparian_management_scores = [
    { id: "1", value: 1 },
    { id: "2", value: 0.5 },
    { id: "3", value: 0 },
    { id: "4", value: 0 },
    { id: "5", value: 0 },
];

//  HPI-24 Surface Waters Bank Stability
export const hpi_surfacewaters_bank_stability_scores = [
    { id: "1", value: 1.0 },
    { id: "2", value: 0.5 },
    { id: "3", value: 0.25 },
    { id: "4", value: 0 },
];

//  HPI-24 Surface Waters Bank Management
export const hpi_surfacewaters_bank_management_scores = [
    { id: "1", value: 0.25 },
    { id: "2", value: 0 },
];

//  HPI-25 Surface Waters Channelization
export const hpi_surfacewaters_channelization_scores = [
    { id: "1", value: null },
    { id: "2", value: 1.0 },
    { id: "3", value: 0.5 },
    { id: "4", value: 0.25 },
    { id: "5", value: 0 },
];

//  HPI-25 Surface Waters Levees or Dams
export const hpi_surfacewaters_dams_scores = [
    { id: true, value: -0.25 },
    { id: false, value: 0 },
];

//  HPI-26 Surface Waters In-Stream Distubance
export const hpi_surfacewaters_instream_habitat_scores = [
    { id: "1", value: 1.0 },
    { id: "2", value: 1.0 },
    { id: "3", value: 0.5 },
    { id: "4", value: 0 },
    { id: "5", value: 0 },
    { id: "6", value: 0 },
];

//  HPI-27 Surface Waters Predominant Use
export const hpi_surfacewaters_predominant_use_scores = [
    { id: "1", value: 1.0 },
    { id: "2", value: 0.75 },
    { id: "3", value: 0.75 },
    { id: "4", value: 0.25 },
    { id: "5", value: 0.25 },
    { id: "6", value: 0.25 },
    { id: "7", value: 0.25 },
    { id: "8", value: 0 },
];

//  HPI-28 Surface Waters Depth
export const hpi_surfacewaters_average_depth_scores = [
    { id: "1", value: 1.0 },
    { id: "2", value: 0.9 },
    { id: "3", value: 0.8 },
    { id: "4", value: 0.7 },
    { id: "5", value: 0.6 },
    { id: "6", value: 0.5 },
    { id: "7", value: 0.4 },
    { id: "8", value: 0.3 },
    { id: "9", value: 0.2 },
    { id: "10", value: 0.1 },
    { id: "11", value: 0 },
];

//  HPI-29 Surface Waters Management
export const hpi_surfacewaters_management_scores = [
    { id: "1", value: 1.0 },
    { id: "2", value: 0.75 },
    { id: "3", value: 0 },
    { id: "4", value: 0 },
    { id: "5", value: 0 },
];

export const chart_colors = {
    1: "rgb(195,155,211)",
    2: "rgb(247,220,111)",
    3: "rgb(215,219,221)",
    4: "rgb(127,179,213)",
    5: "rgb(130,224,170)",
    6: "rgb(118,215,196)",
    8: "rgb(217,136,128)",
    9: "rgb(229,152,102)",
    10: "rgb(240,178,122)",
    39: "rgb(125,206,160)",
    41: "rgb(115,198,182)",
    46: "rgb(187,143,206)",
    48: "rgb(241,148,138)",
    51: "rgb(248,196,113)",
    999: "rgb(133,193,233)",
    buffer: "rgb(151,186,181)",
    forest: "rgb(191,179,142)",
    grassland: "rgb(157,206,92)",
    wetland: "rgb(109,160,208)",
    surfacewater: "rgb(86,192,247)",
    cultivated: "rgb(114,144,238)",
    hpi_overall: "rgb(241,92,34)",
    hpi_cultivated: "rgb(114,144,238)",
    hpi_noncultivated: "rgb(166,118,29)",
};

export const landcover_groups = [
    { id: "1", name: "Edge Features/Riparian Buffer", land_type_ids: ["1", "2"], field: "buffer" },
    { id: "2", name: "Forests", land_type_ids: ["3", "4", "5", "6"], field: "forest" },
    { id: "3", name: "Grassland/Savanna", land_type_ids: ["7", "8", "97", "98"], field: "grassland" },
    { id: "4", name: "Wetlands", land_type_ids: ["11", "12"], field: "wetland" },
    { id: "5", name: "Surface Waters", land_type_ids: ["13", "14", "15", "16", "17", "18"], field: "surfacewater" },
];

export const land_types = [
    { id: "1", code: "edge", name: "Edge Features" },
    { id: "2", code: "riparian", name: "Riparian Buffers" },
    { id: "3", code: "conifer", name: "Conifer Plantation" },
    { id: "4", code: "deciduous", name: "Deciduous Forest" },
    { id: "5", code: "evergreen", name: "Evergreen Forest" },
    { id: "6", code: "mixed", name: "Mixed Forest" },
    { id: "7", code: "native_ungrazed", name: "Native Grassland/Savanna (ungrazed)" },
    { id: "8", code: "native_grazed", name: "Native Grassland/Savanna (grazed)" },
    { id: "97", code: "nonnative_ungrazed", name: "Non-native Grassland/Savanna (ungrazed)" },
    { id: "98", code: "nonnative_grazed", name: "Non-native Grassland/Savanna (grazed)" },
    { id: "11", code: "wetland_natural", name: "Natural or Restored Wetland" },
    { id: "12", code: "wetland_artificial", name: "Artificial Wetland" },
    { id: "13", code: "streams", name: "Streams" },
    { id: "14", code: "rivers", name: "Rivers" },
    { id: "15", code: "pond_natural", name: "Pond (natural)" },
    { id: "16", code: "pond_artificial", name: "Pond (artificial)" },
    { id: "17", code: "lake_natural", name: "Lake (natural)" },
    { id: "18", code: "lake_artificial", name: "Lake (artificial)" },
    { id: "99", code: "cultivated", name: "Cultivated" },
];

export const ecoregions = [
    {
        id: 1,
        eco_code: "M212",
        name: "Adirondak - New England Mixed Forest - Coniferous Forest - Alpine Meadow",
        conifer: { 0: 2, 1: 2.5 },
        evergreen: { 0: 4, 1: 4.5 },
        deciduous: { 0: 4, 1: 4.5 },
        mixed: { 0: 4, 1: 4.5 },
        native_ungrazed: { 0: 3, 1: 3.5 },
        native_grazed: null,
        nonnative_ungrazed: { 0: 2, 1: 2.5 },
        nonnative_grazed: { 0: 2, 1: 2.5 },
        wetland_artificial: { 0: 2, 1: 2.5 },
        wetland_natural: { 0: 5, 1: 5 },
        edge: { 0: 2, 1: 2.5 },
        riparian: { 0: 4, 1: 4.5 },
        stream: { 0: 4, 1: 4.5 },
        river: { 0: 4, 1: 4.5 },
        pond_natural: { 0: 4, 1: 4.5 },
        pond_artificial: { 0: 3, 1: 3.5 },
        lake_natural: { 0: 4, 1: 4.5 },
        lake_artificial: { 0: 3, 1: 3.5 },
    },
    {
        id: 2,
        eco_code: "322",
        name: "American Semidesert and Desert",
        conifer: { 0: 2, 1: 2.5 },
        evergreen: { 0: 3, 1: 3.5 },
        deciduous: { 0: 3, 1: 3.5 },
        mixed: { 0: 3, 1: 3.5 },
        native_ungrazed: { 0: 3, 1: 3.5 },
        native_grazed: null,
        nonnative_ungrazed: { 0: 2, 1: 2.5 },
        nonnative_grazed: { 0: 2, 1: 2.5 },
        wetland_artificial: { 0: 2, 1: 2.5 },
        wetland_natural: { 0: 5, 1: 5 },
        edge: { 0: 2, 1: 2.5 },
        riparian: { 0: 4, 1: 4.5 },
        stream: { 0: 4, 1: 4.5 },
        river: { 0: 4, 1: 4.5 },
        pond_natural: { 0: 4, 1: 4.5 },
        pond_artificial: { 0: 3, 1: 3.5 },
        lake_natural: { 0: 4, 1: 4.5 },
        lake_artificial: { 0: 3, 1: 3.5 },
    },
    {
        id: 3,
        eco_code: "M313",
        name: "Arizona - New Mexico Mountains Semidesert - Open Woodland - Coniferous Forest Alpine Meadow",
        conifer: { 0: 2, 1: 2.5 },
        evergreen: { 0: 4, 1: 4.5 },
        deciduous: { 0: 3, 1: 3.5 },
        mixed: { 0: 4, 1: 4.5 },
        native_ungrazed: { 0: 3, 1: 3.5 },
        native_grazed: null,
        nonnative_ungrazed: { 0: 2, 1: 2.5 },
        nonnative_grazed: { 0: 2, 1: 2.5 },
        wetland_artificial: { 0: 2, 1: 2.5 },
        wetland_natural: { 0: 5, 1: 5 },
        edge: { 0: 2, 1: 2.5 },
        riparian: { 0: 4, 1: 4.5 },
        stream: { 0: 4, 1: 4.5 },
        river: { 0: 4, 1: 4.5 },
        pond_natural: { 0: 4, 1: 4.5 },
        pond_artificial: { 0: 3, 1: 3.5 },
        lake_natural: { 0: 4, 1: 4.5 },
        lake_artificial: { 0: 3, 1: 3.5 },
    },
    {
        id: 4,
        eco_code: "M334",
        name: "Black Hills Coniferous Forest ",
        conifer: { 0: 2, 1: 2.5 },
        evergreen: { 0: 4, 1: 4.5 },
        deciduous: { 0: 3, 1: 3.5 },
        mixed: { 0: 4, 1: 4.5 },
        native_ungrazed: { 0: 4, 1: 4.5 },
        native_grazed: null,
        nonnative_ungrazed: { 0: 2, 1: 2.5 },
        nonnative_grazed: { 0: 2, 1: 2.5 },
        wetland_artificial: { 0: 2, 1: 2.5 },
        wetland_natural: { 0: 5, 1: 5 },
        edge: { 0: 2, 1: 2.5 },
        riparian: { 0: 4, 1: 4.5 },
        stream: { 0: 4, 1: 4.5 },
        river: { 0: 4, 1: 4.5 },
        pond_natural: { 0: 4, 1: 4.5 },
        pond_artificial: { 0: 3, 1: 3.5 },
        lake_natural: { 0: 4, 1: 4.5 },
        lake_artificial: { 0: 3, 1: 3.5 },
    },
    {
        id: 5,
        eco_code: "261",
        name: "California Coastal Chaparral Forest Shrub",
        conifer: { 0: 2, 1: 2.5 },
        evergreen: { 0: 4, 1: 4.5 },
        deciduous: { 0: 3, 1: 3.5 },
        mixed: { 0: 3, 1: 3.5 },
        native_ungrazed: { 0: 4, 1: 4.5 },
        native_grazed: null,
        nonnative_ungrazed: { 0: 2, 1: 2.5 },
        nonnative_grazed: { 0: 2, 1: 2.5 },
        wetland_artificial: { 0: 2, 1: 2.5 },
        wetland_natural: { 0: 5, 1: 5 },
        edge: { 0: 2, 1: 2.5 },
        riparian: { 0: 4, 1: 4.5 },
        stream: { 0: 4, 1: 4.5 },
        river: { 0: 4, 1: 4.5 },
        pond_natural: { 0: 4, 1: 4.5 },
        pond_artificial: { 0: 3, 1: 3.5 },
        lake_natural: { 0: 4, 1: 4.5 },
        lake_artificial: { 0: 3, 1: 3.5 },
    },
    {
        id: 6,
        eco_code: "M262",
        name: "California Coastal Range Open Woodland - Shrub - Coniferous Forest - Meadow",
        conifer: { 0: 2, 1: 2.5 },
        evergreen: { 0: 4, 1: 4.5 },
        deciduous: { 0: 3, 1: 3.5 },
        mixed: { 0: 3, 1: 3.5 },
        native_ungrazed: { 0: 4, 1: 4.5 },
        native_grazed: null,
        nonnative_ungrazed: { 0: 2, 1: 2.5 },
        nonnative_grazed: { 0: 2, 1: 2.5 },
        wetland_artificial: null,
        wetland_natural: { 0: 5, 1: 5 },
        edge: { 0: 2, 1: 2.5 },
        riparian: { 0: 4, 1: 4.5 },
        stream: { 0: 4, 1: 4.5 },
        river: { 0: 4, 1: 4.5 },
        pond_natural: { 0: 4, 1: 4.5 },
        pond_artificial: { 0: 3, 1: 3.5 },
        lake_natural: { 0: 4, 1: 4.5 },
        lake_artificial: { 0: 3, 1: 3.5 },
    },
    {
        id: 7,
        eco_code: "262",
        name: "California Dry Steppe",
        conifer: { 0: 2, 1: 2.5 },
        evergreen: { 0: 3, 1: 3.5 },
        deciduous: { 0: 3, 1: 3.5 },
        mixed: { 0: 3, 1: 3.5 },
        native_ungrazed: { 0: 4, 1: 4.5 },
        native_grazed: null,
        nonnative_ungrazed: { 0: 2, 1: 2.5 },
        nonnative_grazed: { 0: 2, 1: 2.5 },
        wetland_artificial: null,
        wetland_natural: { 0: 5, 1: 5 },
        edge: { 0: 2, 1: 2.5 },
        riparian: { 0: 4, 1: 4.5 },
        stream: { 0: 4, 1: 4.5 },
        river: { 0: 4, 1: 4.5 },
        pond_natural: { 0: 4, 1: 4.5 },
        pond_artificial: { 0: 3, 1: 3.5 },
        lake_natural: { 0: 4, 1: 4.5 },
        lake_artificial: { 0: 3, 1: 3.5 },
    },
    {
        id: 8,
        eco_code: "M242",
        name: "Cascade Mixed Forest - Coniferous Forest - Alpine Meadow",
        conifer: { 0: 2, 1: 2.5 },
        evergreen: { 0: 4, 1: 4.5 },
        deciduous: { 0: 3, 1: 3.5 },
        mixed: { 0: 4, 1: 4.5 },
        native_ungrazed: { 0: 3, 1: 3.5 },
        native_grazed: null,
        nonnative_ungrazed: { 0: 2, 1: 2.5 },
        nonnative_grazed: { 0: 2, 1: 2.5 },
        wetland_artificial: { 0: 2, 1: 2.5 },
        wetland_natural: { 0: 5, 1: 5 },
        edge: { 0: 2, 1: 2.5 },
        riparian: { 0: 4, 1: 4.5 },
        stream: { 0: 4, 1: 4.5 },
        river: { 0: 4, 1: 4.5 },
        pond_natural: { 0: 4, 1: 4.5 },
        pond_artificial: { 0: 3, 1: 3.5 },
        lake_natural: { 0: 4, 1: 4.5 },
        lake_artificial: { 0: 3, 1: 3.5 },
    },
    {
        id: 9,
        eco_code: "M221",
        name: "Central Appalachian Broadleaf Forest - Coniferous Forest - Meadow",
        conifer: { 0: 2, 1: 2.5 },
        evergreen: { 0: 3, 1: 3.5 },
        deciduous: { 0: 4, 1: 4.5 },
        mixed: { 0: 4, 1: 4.5 },
        native_ungrazed: { 0: 3, 1: 3.5 },
        native_grazed: null,
        nonnative_ungrazed: { 0: 2, 1: 2.5 },
        nonnative_grazed: { 0: 2, 1: 2.5 },
        wetland_artificial: { 0: 2, 1: 2.5 },
        wetland_natural: { 0: 5, 1: 5 },
        edge: { 0: 2, 1: 2.5 },
        riparian: { 0: 4, 1: 4.5 },
        stream: { 0: 4, 1: 4.5 },
        river: { 0: 4, 1: 4.5 },
        pond_natural: { 0: 4, 1: 4.5 },
        pond_artificial: { 0: 3, 1: 3.5 },
        lake_natural: { 0: 4, 1: 4.5 },
        lake_artificial: { 0: 3, 1: 3.5 },
    },
    {
        id: 10,
        eco_code: "321",
        name: "Chihuahuan Desert",
        conifer: { 0: 2, 1: 2.5 },
        evergreen: { 0: 3, 1: 3.5 },
        deciduous: { 0: 3, 1: 3.5 },
        mixed: { 0: 3, 1: 3.5 },
        native_ungrazed: { 0: 3, 1: 3.5 },
        native_grazed: null,
        nonnative_ungrazed: { 0: 2, 1: 2.5 },
        nonnative_grazed: { 0: 2, 1: 2.5 },
        wetland_artificial: { 0: 2, 1: 2.5 },
        wetland_natural: { 0: 5, 1: 5 },
        edge: { 0: 2, 1: 2.5 },
        riparian: { 0: 4, 1: 4.5 },
        stream: { 0: 4, 1: 4.5 },
        river: { 0: 4, 1: 4.5 },
        pond_natural: { 0: 4, 1: 4.5 },
        pond_artificial: { 0: 3, 1: 3.5 },
        lake_natural: { 0: 4, 1: 4.5 },
        lake_artificial: { 0: 3, 1: 3.5 },
    },
    {
        id: 11,
        eco_code: "313",
        name: "Colorado Plateau Semidesert",
        conifer: { 0: 2, 1: 2.5 },
        evergreen: { 0: 3, 1: 3.5 },
        deciduous: { 0: 3, 1: 3.5 },
        mixed: { 0: 3, 1: 3.5 },
        native_ungrazed: { 0: 3, 1: 3.5 },
        native_grazed: null,
        nonnative_ungrazed: { 0: 2, 1: 2.5 },
        nonnative_grazed: { 0: 2, 1: 2.5 },
        wetland_artificial: { 0: 2, 1: 2.5 },
        wetland_natural: { 0: 5, 1: 5 },
        edge: { 0: 2, 1: 2.5 },
        riparian: { 0: 4, 1: 4.5 },
        stream: { 0: 4, 1: 4.5 },
        river: { 0: 4, 1: 4.5 },
        pond_natural: { 0: 4, 1: 4.5 },
        pond_artificial: { 0: 3, 1: 3.5 },
        lake_natural: { 0: 4, 1: 4.5 },
        lake_artificial: { 0: 3, 1: 3.5 },
    },
    {
        id: 12,
        eco_code: "222",
        name: "Eastern Broadleaf Forest (Continental)",
        conifer: { 0: 2, 1: 2.5 },
        evergreen: { 0: 3, 1: 3.5 },
        deciduous: { 0: 4, 1: 4.5 },
        mixed: { 0: 4, 1: 4.5 },
        native_ungrazed: { 0: 3, 1: 3.5 },
        native_grazed: null,
        nonnative_ungrazed: { 0: 2, 1: 2.5 },
        nonnative_grazed: { 0: 2, 1: 2.5 },
        wetland_artificial: null,
        wetland_natural: { 0: 5, 1: 5 },
        edge: { 0: 2, 1: 2.5 },
        riparian: { 0: 4, 1: 4.5 },
        stream: { 0: 4, 1: 4.5 },
        river: { 0: 4, 1: 4.5 },
        pond_natural: { 0: 4, 1: 4.5 },
        pond_artificial: { 0: 3, 1: 3.5 },
        lake_natural: { 0: 4, 1: 4.5 },
        lake_artificial: { 0: 3, 1: 3.5 },
    },
    {
        id: 13,
        eco_code: "221",
        name: "Eastern Broadleaf Forest (Oceanic)",
        conifer: { 0: 2, 1: 2.5 },
        evergreen: { 0: 3, 1: 3.5 },
        deciduous: { 0: 4, 1: 4.5 },
        mixed: { 0: 3, 1: 3.5 },
        native_ungrazed: { 0: 3, 1: 3.5 },
        native_grazed: null,
        nonnative_ungrazed: { 0: 2, 1: 2.5 },
        nonnative_grazed: { 0: 2, 1: 2.5 },
        wetland_artificial: { 0: 2, 1: 2.5 },
        wetland_natural: { 0: 5, 1: 5 },
        edge: { 0: 2, 1: 2.5 },
        riparian: { 0: 4, 1: 4.5 },
        stream: { 0: 4, 1: 4.5 },
        river: { 0: 4, 1: 4.5 },
        pond_natural: { 0: 4, 1: 4.5 },
        pond_artificial: { 0: 3, 1: 3.5 },
        lake_natural: { 0: 4, 1: 4.5 },
        lake_artificial: { 0: 3, 1: 3.5 },
    },
    {
        id: 14,
        eco_code: "411",
        name: "Everglades",
        conifer: { 0: 2, 1: 2.5 },
        evergreen: { 0: 4, 1: 4.5 },
        deciduous: { 0: 3, 1: 3.5 },
        mixed: { 0: 3, 1: 3.5 },
        native_ungrazed: { 0: 4, 1: 4.5 },
        native_grazed: null,
        nonnative_ungrazed: { 0: 2, 1: 2.5 },
        nonnative_grazed: { 0: 2, 1: 2.5 },
        wetland_artificial: null,
        wetland_natural: { 0: 5, 1: 5 },
        edge: { 0: 2, 1: 2.5 },
        riparian: { 0: 4, 1: 4.5 },
        stream: { 0: 4, 1: 4.5 },
        river: { 0: 4, 1: 4.5 },
        pond_natural: { 0: 4, 1: 4.5 },
        pond_artificial: { 0: 3, 1: 3.5 },
        lake_natural: { 0: 4, 1: 4.5 },
        lake_artificial: { 0: 3, 1: 3.5 },
    },
    {
        id: 15,
        eco_code: "332",
        name: "Great Plains Steppe",
        conifer: { 0: 2, 1: 2.5 },
        evergreen: { 0: 3, 1: 3.5 },
        deciduous: { 0: 3, 1: 3.5 },
        mixed: { 0: 3, 1: 3.5 },
        native_ungrazed: { 0: 4, 1: 4.5 },
        native_grazed: null,
        nonnative_ungrazed: { 0: 2, 1: 2.5 },
        nonnative_grazed: { 0: 2, 1: 2.5 },
        wetland_artificial: null,
        wetland_natural: { 0: 5, 1: 5 },
        edge: { 0: 2, 1: 2.5 },
        riparian: { 0: 4, 1: 4.5 },
        stream: { 0: 4, 1: 4.5 },
        river: { 0: 4, 1: 4.5 },
        pond_natural: { 0: 4, 1: 4.5 },
        pond_artificial: { 0: 3, 1: 3.5 },
        lake_natural: { 0: 4, 1: 4.5 },
        lake_artificial: { 0: 3, 1: 3.5 },
    },
    {
        id: 16,
        eco_code: "311",
        name: "Great Plains Steppe and Shrub",
        conifer: { 0: 2, 1: 2.5 },
        evergreen: { 0: 3, 1: 3.5 },
        deciduous: { 0: 3, 1: 3.5 },
        mixed: { 0: 3, 1: 3.5 },
        native_ungrazed: { 0: 4, 1: 4.5 },
        native_grazed: null,
        nonnative_ungrazed: { 0: 2, 1: 2.5 },
        nonnative_grazed: { 0: 2, 1: 2.5 },
        wetland_artificial: null,
        wetland_natural: { 0: 5, 1: 5 },
        edge: { 0: 2, 1: 2.5 },
        riparian: { 0: 4, 1: 4.5 },
        stream: { 0: 4, 1: 4.5 },
        river: { 0: 4, 1: 4.5 },
        pond_natural: { 0: 4, 1: 4.5 },
        pond_artificial: { 0: 3, 1: 3.5 },
        lake_natural: { 0: 4, 1: 4.5 },
        lake_artificial: { 0: 3, 1: 3.5 },
    },
    {
        id: 17,
        eco_code: "331",
        name: "Great Plains - Palouse Dry Steppe",
        conifer: { 0: 2, 1: 2.5 },
        evergreen: { 0: 3, 1: 3.5 },
        deciduous: { 0: 3, 1: 3.5 },
        mixed: { 0: 3, 1: 3.5 },
        native_ungrazed: { 0: 4, 1: 4.5 },
        native_grazed: null,
        nonnative_ungrazed: { 0: 2, 1: 2.5 },
        nonnative_grazed: { 0: 2, 1: 2.5 },
        wetland_artificial: null,
        wetland_natural: { 0: 5, 1: 5 },
        edge: { 0: 2, 1: 2.5 },
        riparian: { 0: 4, 1: 4.5 },
        stream: { 0: 4, 1: 4.5 },
        river: { 0: 4, 1: 4.5 },
        pond_natural: { 0: 4, 1: 4.5 },
        pond_artificial: { 0: 3, 1: 3.5 },
        lake_natural: { 0: 4, 1: 4.5 },
        lake_artificial: { 0: 3, 1: 3.5 },
    },
    {
        id: 18,
        eco_code: "342",
        name: "Intermountain Semidesert",
        conifer: { 0: 2, 1: 2.5 },
        evergreen: { 0: 3, 1: 3.5 },
        deciduous: { 0: 3, 1: 3.5 },
        mixed: { 0: 3, 1: 3.5 },
        native_ungrazed: { 0: 3, 1: 3.5 },
        native_grazed: null,
        nonnative_ungrazed: { 0: 2, 1: 2.5 },
        nonnative_grazed: { 0: 2, 1: 2.5 },
        wetland_artificial: { 0: 2, 1: 2.5 },
        wetland_natural: { 0: 5, 1: 5 },
        edge: { 0: 2, 1: 2.5 },
        riparian: { 0: 4, 1: 4.5 },
        stream: { 0: 4, 1: 4.5 },
        river: { 0: 4, 1: 4.5 },
        pond_natural: { 0: 4, 1: 4.5 },
        pond_artificial: { 0: 3, 1: 3.5 },
        lake_natural: { 0: 4, 1: 4.5 },
        lake_artificial: { 0: 3, 1: 3.5 },
    },
    {
        id: 19,
        eco_code: "341",
        name: "Intermountain Semidesert and Desert",
        conifer: { 0: 2, 1: 2.5 },
        evergreen: { 0: 3, 1: 3.5 },
        deciduous: { 0: 3, 1: 3.5 },
        mixed: { 0: 3, 1: 3.5 },
        native_ungrazed: { 0: 3, 1: 3.5 },
        native_grazed: null,
        nonnative_ungrazed: { 0: 2, 1: 2.5 },
        nonnative_grazed: { 0: 2, 1: 2.5 },
        wetland_artificial: { 0: 2, 1: 2.5 },
        wetland_natural: { 0: 5, 1: 5 },
        edge: { 0: 2, 1: 2.5 },
        riparian: { 0: 4, 1: 4.5 },
        stream: { 0: 4, 1: 4.5 },
        river: { 0: 4, 1: 4.5 },
        pond_natural: { 0: 4, 1: 4.5 },
        pond_artificial: { 0: 3, 1: 3.5 },
        lake_natural: { 0: 4, 1: 4.5 },
        lake_artificial: { 0: 3, 1: 3.5 },
    },
    {
        id: 20,
        eco_code: "212",
        name: "Laurentian Mixed Forest",
        conifer: { 0: 2, 1: 2.5 },
        evergreen: { 0: 3, 1: 3.5 },
        deciduous: { 0: 3, 1: 3.5 },
        mixed: { 0: 4, 1: 4.5 },
        native_ungrazed: { 0: 3, 1: 3.5 },
        native_grazed: null,
        nonnative_ungrazed: { 0: 2, 1: 2.5 },
        nonnative_grazed: { 0: 2, 1: 2.5 },
        wetland_artificial: null,
        wetland_natural: { 0: 5, 1: 5 },
        edge: { 0: 2, 1: 2.5 },
        riparian: { 0: 4, 1: 4.5 },
        stream: { 0: 4, 1: 4.5 },
        river: { 0: 4, 1: 4.5 },
        pond_natural: { 0: 4, 1: 4.5 },
        pond_artificial: { 0: 3, 1: 3.5 },
        lake_natural: { 0: 4, 1: 4.5 },
        lake_artificial: { 0: 3, 1: 3.5 },
    },
    {
        id: 21,
        eco_code: "234",
        name: "Lower Mississippi Riverine Forest",
        conifer: { 0: 2, 1: 2.5 },
        evergreen: { 0: 3, 1: 3.5 },
        deciduous: { 0: 4, 1: 4.5 },
        mixed: { 0: 4, 1: 4.5 },
        native_ungrazed: { 0: 3, 1: 3.5 },
        native_grazed: null,
        nonnative_ungrazed: { 0: 2, 1: 2.5 },
        nonnative_grazed: { 0: 2, 1: 2.5 },
        wetland_artificial: null,
        wetland_natural: { 0: 5, 1: 5 },
        edge: { 0: 2, 1: 2.5 },
        riparian: { 0: 4, 1: 4.5 },
        stream: { 0: 4, 1: 4.5 },
        river: { 0: 4, 1: 4.5 },
        pond_natural: { 0: 4, 1: 4.5 },
        pond_artificial: { 0: 3, 1: 3.5 },
        lake_natural: { 0: 4, 1: 4.5 },
        lake_artificial: { 0: 3, 1: 3.5 },
    },
    {
        id: 22,
        eco_code: "M332",
        name: "Middle Rocky Mountain Steppe - Coniferous Forest - Alpine Meadow",
        conifer: { 0: 2, 1: 2.5 },
        evergreen: { 0: 4, 1: 4.5 },
        deciduous: { 0: 3, 1: 3.5 },
        mixed: { 0: 3, 1: 3.5 },
        native_ungrazed: { 0: 3, 1: 3.5 },
        native_grazed: null,
        nonnative_ungrazed: { 0: 2, 1: 2.5 },
        nonnative_grazed: { 0: 2, 1: 2.5 },
        wetland_artificial: { 0: 2, 1: 2.5 },
        wetland_natural: { 0: 5, 1: 5 },
        edge: { 0: 2, 1: 2.5 },
        riparian: { 0: 4, 1: 4.5 },
        stream: { 0: 4, 1: 4.5 },
        river: { 0: 4, 1: 4.5 },
        pond_natural: { 0: 4, 1: 4.5 },
        pond_artificial: { 0: 3, 1: 3.5 },
        lake_natural: { 0: 4, 1: 4.5 },
        lake_artificial: { 0: 3, 1: 3.5 },
    },
    {
        id: 23,
        eco_code: "M341",
        name: "Nevada - Utah Mountains Semidesert - Coniferous Forest - Alpine Meadow",
        conifer: { 0: 2, 1: 2.5 },
        evergreen: { 0: 3, 1: 3.5 },
        deciduous: { 0: 3, 1: 3.5 },
        mixed: { 0: 3, 1: 3.5 },
        native_ungrazed: { 0: 3, 1: 3.5 },
        native_grazed: null,
        nonnative_ungrazed: { 0: 2, 1: 2.5 },
        nonnative_grazed: { 0: 2, 1: 2.5 },
        wetland_artificial: { 0: 2, 1: 2.5 },
        wetland_natural: { 0: 5, 1: 5 },
        edge: { 0: 2, 1: 2.5 },
        riparian: { 0: 4, 1: 4.5 },
        stream: { 0: 4, 1: 4.5 },
        river: { 0: 4, 1: 4.5 },
        pond_natural: { 0: 4, 1: 4.5 },
        pond_artificial: { 0: 3, 1: 3.5 },
        lake_natural: { 0: 4, 1: 4.5 },
        lake_artificial: { 0: 3, 1: 3.5 },
    },
    {
        id: 24,
        eco_code: "M333",
        name: "Northern Rocky Mountain Forest Steppe - Coniferous Forest - Alpine Meadow",
        conifer: { 0: 2, 1: 2.5 },
        evergreen: { 0: 4, 1: 4.5 },
        deciduous: { 0: 3, 1: 3.5 },
        mixed: { 0: 4, 1: 4.5 },
        native_ungrazed: { 0: 3, 1: 3.5 },
        native_grazed: null,
        nonnative_ungrazed: { 0: 2, 1: 2.5 },
        nonnative_grazed: { 0: 2, 1: 2.5 },
        wetland_artificial: { 0: 2, 1: 2.5 },
        wetland_natural: { 0: 5, 1: 5 },
        edge: { 0: 2, 1: 2.5 },
        riparian: { 0: 4, 1: 4.5 },
        stream: { 0: 4, 1: 4.5 },
        river: { 0: 4, 1: 4.5 },
        pond_natural: { 0: 4, 1: 4.5 },
        pond_artificial: { 0: 3, 1: 3.5 },
        lake_natural: { 0: 4, 1: 4.5 },
        lake_artificial: { 0: 3, 1: 3.5 },
    },
    {
        id: 25,
        eco_code: "M231",
        name: "Ouachita Mixed Forest - Meadow",
        conifer: { 0: 2, 1: 2.5 },
        evergreen: { 0: 3, 1: 3.5 },
        deciduous: { 0: 3, 1: 3.5 },
        mixed: { 0: 4, 1: 4.5 },
        native_ungrazed: { 0: 3, 1: 3.5 },
        native_grazed: null,
        nonnative_ungrazed: { 0: 2, 1: 2.5 },
        nonnative_grazed: { 0: 2, 1: 2.5 },
        wetland_artificial: null,
        wetland_natural: { 0: 5, 1: 5 },
        edge: { 0: 2, 1: 2.5 },
        riparian: { 0: 4, 1: 4.5 },
        stream: { 0: 4, 1: 4.5 },
        river: { 0: 4, 1: 4.5 },
        pond_natural: { 0: 4, 1: 4.5 },
        pond_artificial: { 0: 3, 1: 3.5 },
        lake_natural: { 0: 4, 1: 4.5 },
        lake_artificial: { 0: 3, 1: 3.5 },
    },
    {
        id: 26,
        eco_code: "232",
        name: "Outer Coastal Plain Mixed",
        conifer: { 0: 2, 1: 2.5 },
        evergreen: { 0: 4, 1: 4.5 },
        deciduous: { 0: 3, 1: 3.5 },
        mixed: { 0: 3, 1: 3.5 },
        native_ungrazed: { 0: 3, 1: 3.5 },
        native_grazed: null,
        nonnative_ungrazed: { 0: 2, 1: 2.5 },
        nonnative_grazed: { 0: 2, 1: 2.5 },
        wetland_artificial: null,
        wetland_natural: { 0: 5, 1: 5 },
        edge: { 0: 2, 1: 2.5 },
        riparian: { 0: 4, 1: 4.5 },
        stream: { 0: 4, 1: 4.5 },
        river: { 0: 4, 1: 4.5 },
        pond_natural: { 0: 4, 1: 4.5 },
        pond_artificial: { 0: 3, 1: 3.5 },
        lake_natural: { 0: 4, 1: 4.5 },
        lake_artificial: { 0: 3, 1: 3.5 },
    },
    {
        id: 27,
        eco_code: "M222",
        name: "Ozark Broadleaf Forest - Meadow",
        conifer: { 0: 2, 1: 2.5 },
        evergreen: { 0: 3, 1: 3.5 },
        deciduous: { 0: 3, 1: 3.5 },
        mixed: { 0: 4, 1: 4.5 },
        native_ungrazed: { 0: 3, 1: 3.5 },
        native_grazed: null,
        nonnative_ungrazed: { 0: 2, 1: 2.5 },
        nonnative_grazed: { 0: 2, 1: 2.5 },
        wetland_artificial: { 0: 2, 1: 2.5 },
        wetland_natural: { 0: 5, 1: 5 },
        edge: { 0: 2, 1: 2.5 },
        riparian: { 0: 4, 1: 4.5 },
        stream: { 0: 4, 1: 4.5 },
        river: { 0: 4, 1: 4.5 },
        pond_natural: { 0: 4, 1: 4.5 },
        pond_artificial: { 0: 3, 1: 3.5 },
        lake_natural: { 0: 4, 1: 4.5 },
        lake_artificial: { 0: 3, 1: 3.5 },
    },
    {
        id: 28,
        eco_code: "242",
        name: "Pacific Lowland Mixed Forest",
        conifer: { 0: 2, 1: 2.5 },
        evergreen: { 0: 4, 1: 4.5 },
        deciduous: { 0: 3, 1: 3.5 },
        mixed: { 0: 4, 1: 4.5 },
        native_ungrazed: { 0: 3, 1: 3.5 },
        native_grazed: null,
        nonnative_ungrazed: { 0: 2, 1: 2.5 },
        nonnative_grazed: { 0: 2, 1: 2.5 },
        wetland_artificial: { 0: 2, 1: 2.5 },
        wetland_natural: { 0: 5, 1: 5 },
        edge: { 0: 2, 1: 2.5 },
        riparian: { 0: 4, 1: 4.5 },
        stream: { 0: 4, 1: 4.5 },
        river: { 0: 4, 1: 4.5 },
        pond_natural: { 0: 4, 1: 4.5 },
        pond_artificial: { 0: 3, 1: 3.5 },
        lake_natural: { 0: 4, 1: 4.5 },
        lake_artificial: { 0: 3, 1: 3.5 },
    },
    {
        id: 29,
        eco_code: "255",
        name: "Prairie Parkland (Subtropical)",
        conifer: { 0: 2, 1: 2.5 },
        evergreen: { 0: 4, 1: 4.5 },
        deciduous: { 0: 3, 1: 3.5 },
        mixed: { 0: 4, 1: 4.5 },
        native_ungrazed: { 0: 4, 1: 4.5 },
        native_grazed: null,
        nonnative_ungrazed: { 0: 2, 1: 2.5 },
        nonnative_grazed: { 0: 2, 1: 2.5 },
        wetland_artificial: null,
        wetland_natural: { 0: 5, 1: 5 },
        edge: { 0: 2, 1: 2.5 },
        riparian: { 0: 4, 1: 4.5 },
        stream: { 0: 4, 1: 4.5 },
        river: { 0: 4, 1: 4.5 },
        pond_natural: { 0: 4, 1: 4.5 },
        pond_artificial: { 0: 3, 1: 3.5 },
        lake_natural: { 0: 4, 1: 4.5 },
        lake_artificial: { 0: 3, 1: 3.5 },
    },
    {
        id: 30,
        eco_code: "251",
        name: "Prairie Parkland (Temperate)",
        conifer: { 0: 2, 1: 2.5 },
        evergreen: { 0: 3, 1: 3.5 },
        deciduous: { 0: 3, 1: 3.5 },
        mixed: { 0: 4, 1: 4.5 },
        native_ungrazed: { 0: 4, 1: 4.5 },
        native_grazed: null,
        nonnative_ungrazed: { 0: 2, 1: 2.5 },
        nonnative_grazed: { 0: 2, 1: 2.5 },
        wetland_artificial: null,
        wetland_natural: { 0: 5, 1: 5 },
        edge: { 0: 2, 1: 2.5 },
        riparian: { 0: 4, 1: 4.5 },
        stream: { 0: 4, 1: 4.5 },
        river: { 0: 4, 1: 4.5 },
        pond_natural: { 0: 4, 1: 4.5 },
        pond_artificial: { 0: 3, 1: 3.5 },
        lake_natural: { 0: 4, 1: 4.5 },
        lake_artificial: { 0: 3, 1: 3.5 },
    },
    {
        id: 31,
        eco_code: "M261",
        name: "Sierran Steppe - Mixed Forest - Coniferous Forest - Alpine Meadow",
        conifer: { 0: 2, 1: 2.5 },
        evergreen: { 0: 4, 1: 4.5 },
        deciduous: { 0: 3, 1: 3.5 },
        mixed: { 0: 3, 1: 3.5 },
        native_ungrazed: { 0: 4, 1: 4.5 },
        native_grazed: null,
        nonnative_ungrazed: { 0: 2, 1: 2.5 },
        nonnative_grazed: { 0: 2, 1: 2.5 },
        wetland_artificial: { 0: 2, 1: 2.5 },
        wetland_natural: { 0: 5, 1: 5 },
        edge: { 0: 2, 1: 2.5 },
        riparian: { 0: 4, 1: 4.5 },
        stream: { 0: 4, 1: 4.5 },
        river: { 0: 4, 1: 4.5 },
        pond_natural: { 0: 4, 1: 4.5 },
        pond_artificial: { 0: 3, 1: 3.5 },
        lake_natural: { 0: 4, 1: 4.5 },
        lake_artificial: { 0: 3, 1: 3.5 },
    },
    {
        id: 32,
        eco_code: "231",
        name: "Southern Mixed Forest",
        conifer: { 0: 2, 1: 2.5 },
        evergreen: { 0: 3, 1: 3.5 },
        deciduous: { 0: 3, 1: 3.5 },
        mixed: { 0: 4, 1: 4.5 },
        native_ungrazed: { 0: 3, 1: 3.5 },
        native_grazed: null,
        nonnative_ungrazed: { 0: 2, 1: 2.5 },
        nonnative_grazed: { 0: 2, 1: 2.5 },
        wetland_artificial: null,
        wetland_natural: { 0: 5, 1: 5 },
        edge: { 0: 2, 1: 2.5 },
        riparian: { 0: 4, 1: 4.5 },
        stream: { 0: 4, 1: 4.5 },
        river: { 0: 4, 1: 4.5 },
        pond_natural: { 0: 4, 1: 4.5 },
        pond_artificial: { 0: 3, 1: 3.5 },
        lake_natural: { 0: 4, 1: 4.5 },
        lake_artificial: { 0: 3, 1: 3.5 },
    },
    {
        id: 33,
        eco_code: "M331",
        name: "Southern Rocky Mountain Steppe - Open Woodland - Coniferous Forest - Alpine Meadow",
        conifer: { 0: 2, 1: 2.5 },
        evergreen: { 0: 4, 1: 4.5 },
        deciduous: { 0: 3, 1: 3.5 },
        mixed: { 0: 4, 1: 4.5 },
        native_ungrazed: { 0: 3, 1: 3.5 },
        native_grazed: null,
        nonnative_ungrazed: { 0: 2, 1: 2.5 },
        nonnative_grazed: { 0: 2, 1: 2.5 },
        wetland_artificial: { 0: 2, 1: 2.5 },
        wetland_natural: { 0: 5, 1: 5 },
        edge: { 0: 2, 1: 2.5 },
        riparian: { 0: 4, 1: 4.5 },
        stream: { 0: 4, 1: 4.5 },
        river: { 0: 4, 1: 4.5 },
        pond_natural: { 0: 4, 1: 4.5 },
        pond_artificial: { 0: 3, 1: 3.5 },
        lake_natural: { 0: 4, 1: 4.5 },
        lake_artificial: { 0: 3, 1: 3.5 },
    },
    {
        id: 34,
        eco_code: "315",
        name: "Southwest Plateau and Plains Dry Steppe and Shrub",
        conifer: { 0: 2, 1: 2.5 },
        evergreen: { 0: 3, 1: 3.5 },
        deciduous: { 0: 3, 1: 3.5 },
        mixed: { 0: 3, 1: 3.5 },
        native_ungrazed: { 0: 4, 1: 4.5 },
        native_grazed: null,
        nonnative_ungrazed: { 0: 2, 1: 2.5 },
        nonnative_grazed: { 0: 2, 1: 2.5 },
        wetland_artificial: null,
        wetland_natural: { 0: 5, 1: 5 },
        edge: { 0: 2, 1: 2.5 },
        riparian: { 0: 4, 1: 4.5 },
        stream: { 0: 4, 1: 4.5 },
        river: { 0: 4, 1: 4.5 },
        pond_natural: { 0: 4, 1: 4.5 },
        pond_artificial: { 0: 3, 1: 3.5 },
        lake_natural: { 0: 4, 1: 4.5 },
        lake_artificial: { 0: 3, 1: 3.5 },
    },
];

export const improvement_section_headers = {
    cultivated: "Cultivated Lands",
    buffer: "Edge Features, Riparian Buffer",
    forest: "Conifer Plantation, Deciduous Forest, Evergreen Forest, Mixed Forest",
    grassland: "Grasslands",
    surfacewater: "Streams, Rivers, Ponds and Lakes (natural and artificial)",
    wetland: "Wetlands",
};

export const improvements = {
    cultivated: [
        "Reduce soil disturbing practices to increase residues to above 15% through No-till, strip till, conservation, or reduced tillage practices.",
        "Manage vegetation diversity by cover crops, diverse rotations or allowing for native vegetation establishment during fallow periods.",
        "Apply nutrients according to nutrient management plan or using the 4R management strategies (right rate, time, place, and source).",
        "Control pests through IPM strategies with or without chemical use.",
        "Reduce surface water withdrawals for crop irrigation through irrigation management and use of alternative irrigation techniques.",
        "Manage subsurface /groundwater tile drainage with a drainage water management or without avoiding these areas.",
        "Provide foraging habitat by selectively leaving vegetation for wildlife.  Provide early successional habitat for upland game species breeding and/or nesting habitat",
    ],
    buffer: [
        "Improve native diversity through invasive weed control utilizing Integrated Pest Management (IPM)strategies, prescribed grazing/mowing/burning/selective herbicide use.",
        "Encourage early successional habitat by cutting back edges or edge feathering to increase field border width throughout tree dripline where crop production is minimized.",
        "Limit or avoid ditch cleaning.",
    ],
    forest: [
        "Maintain forest diversity through selective harvesting of timber and understory management.",
        "Establish logging and drag roads in wildlife friendly early successional vegetation to create wildlife corridors.",
        "Manage forest edge to create a soft edge for brooding and nesting habitat for upland game species.",
        "Protect riparian areas, wetlands, and other critical environments for beneficial wildlife habitat.",
        "Consider long-term conservation easements to limit habitat risk.",
    ],
    grassland: [
        "Consider long-term conservation easements to limit habitat risk.",
        "Manage grasslands to reduce woody encroachment and control of invasive weed species through IPM strategies, prescribed grazing/mowing/burning/selective herbicide use.",
        "Increase vegetative diversity by inter-seeding legumes and native forbs in grasslands to provide food, shelter, and residual cover for wildlife.",
    ],
    surfacewater: [
        "Install long-term conservation easements to limit habitat risk.",
        "Manage or eliminate livestock access to surface water through limited access points or alternative water systems.",
    ],
    wetland: [
        "Manage flood water in wetlands seasonally or temporarily to allow for vegetation diversity.",
        "Consider long-term conservation easements to limit habitat risk.",
        "Manage wetland to limit nutrient and sediment loading.",
        "Enhance habitat by seasonal flooding and increasing wildlife food sources by inter-seeding forbs or small grain species.",
        "Remove/discourage spread of invasive species through IPM strategies.",
    ],
};

export function GET_HAS_WHAT_1(values) {
    const has_edge = values["buffer"].indexOf(land_types.filter((l) => l.code === "edge")[0]["id"]) > -1;
    const has_riparian = values["buffer"].indexOf(land_types.filter((l) => l.code === "riparian")[0]["id"]) > -1;
    const has_conifer = values["forest"].indexOf(land_types.filter((l) => l.code === "conifer")[0]["id"]) > -1;
    const has_deciduous = values["forest"].indexOf(land_types.filter((l) => l.code === "deciduous")[0]["id"]) > -1;
    const has_evergreen = values["forest"].indexOf(land_types.filter((l) => l.code === "evergreen")[0]["id"]) > -1;
    const has_mixed = values["forest"].indexOf(land_types.filter((l) => l.code === "mixed")[0]["id"]) > -1;
    const has_native_ungrazed =
        values["grassland"].indexOf(land_types.filter((l) => l.code === "native_ungrazed")[0]["id"]) > -1;
    const has_native_grazed =
        values["grassland"].indexOf(land_types.filter((l) => l.code === "native_grazed")[0]["id"]) > -1;
    const has_nonnative_ungrazed =
        values["grassland"].indexOf(land_types.filter((l) => l.code === "nonnative_ungrazed")[0]["id"]) > -1;
    const has_nonnative_grazed =
        values["grassland"].indexOf(land_types.filter((l) => l.code === "nonnative_grazed")[0]["id"]) > -1;
    const has_wetland_natural =
        values["wetland"].indexOf(land_types.filter((l) => l.code === "wetland_natural")[0]["id"]) > -1;
    const has_wetland_artificial =
        values["wetland"].indexOf(land_types.filter((l) => l.code === "wetland_artificial")[0]["id"]) > -1;
    const has_streams = values["surfacewater"].indexOf(land_types.filter((l) => l.code === "streams")[0]["id"]) > -1;
    const has_rivers = values["surfacewater"].indexOf(land_types.filter((l) => l.code === "rivers")[0]["id"]) > -1;
    const has_ponds_natural =
        values["surfacewater"].indexOf(land_types.filter((l) => l.code === "pond_natural")[0]["id"]) > -1;
    const has_ponds_artificial =
        values["surfacewater"].indexOf(land_types.filter((l) => l.code === "pond_artificial")[0]["id"]) > -1;
    const has_lakes_natural =
        values["surfacewater"].indexOf(land_types.filter((l) => l.code === "lake_natural")[0]["id"]) > -1;
    const has_lakes_artificial =
        values["surfacewater"].indexOf(land_types.filter((l) => l.code === "lake_artificial")[0]["id"]) > -1;

    const has_what = {
        has_edge: has_edge,
        has_riparian: has_riparian,
        has_conifer: has_conifer,
        has_evergreen: has_evergreen,
        has_deciduous: has_deciduous,
        has_mixed: has_mixed,
        has_native_ungrazed: has_native_ungrazed,
        has_native_grazed: has_native_grazed,
        has_nonnative_ungrazed: has_nonnative_ungrazed,
        has_nonnative_grazed: has_nonnative_grazed,
        has_wetland_natural: has_wetland_natural,
        has_wetland_artificial: has_wetland_artificial,
        has_streams: has_streams,
        has_rivers: has_rivers,
        has_ponds_natural: has_ponds_natural,
        has_ponds_artificial: has_ponds_artificial,
        has_lakes_natural: has_lakes_natural,
        has_lakes_artificial: has_lakes_artificial,
    };

    return has_what;
}

export function GET_HAS_WHAT_2(farmHpiSurvey) {
    const selected_land_types = farmHpiSurvey["landtypes"].split(",");
    const has_edge = selected_land_types.indexOf(land_types.filter((l) => l.code === "edge")[0]["id"]) > -1;
    const has_riparian = selected_land_types.indexOf(land_types.filter((l) => l.code === "riparian")[0]["id"]) > -1;
    const has_conifer = selected_land_types.indexOf(land_types.filter((l) => l.code === "conifer")[0]["id"]) > -1;
    const has_deciduous = selected_land_types.indexOf(land_types.filter((l) => l.code === "deciduous")[0]["id"]) > -1;
    const has_evergreen = selected_land_types.indexOf(land_types.filter((l) => l.code === "evergreen")[0]["id"]) > -1;
    const has_mixed = selected_land_types.indexOf(land_types.filter((l) => l.code === "mixed")[0]["id"]) > -1;
    const has_native_ungrazed =
        selected_land_types.indexOf(land_types.filter((l) => l.code === "native_ungrazed")[0]["id"]) > -1;
    const has_native_grazed =
        selected_land_types.indexOf(land_types.filter((l) => l.code === "native_grazed")[0]["id"]) > -1;
    const has_nonnative_ungrazed =
        selected_land_types.indexOf(land_types.filter((l) => l.code === "nonnative_ungrazed")[0]["id"]) > -1;
    const has_nonnative_grazed =
        selected_land_types.indexOf(land_types.filter((l) => l.code === "nonnative_grazed")[0]["id"]) > -1;
    const has_wetland_natural =
        selected_land_types.indexOf(land_types.filter((l) => l.code === "wetland_natural")[0]["id"]) > -1;
    const has_wetland_artificial =
        selected_land_types.indexOf(land_types.filter((l) => l.code === "wetland_artificial")[0]["id"]) > -1;
    const has_streams = selected_land_types.indexOf(land_types.filter((l) => l.code === "streams")[0]["id"]) > -1;
    const has_rivers = selected_land_types.indexOf(land_types.filter((l) => l.code === "rivers")[0]["id"]) > -1;
    const has_ponds_natural =
        selected_land_types.indexOf(land_types.filter((l) => l.code === "pond_natural")[0]["id"]) > -1;
    const has_ponds_artificial =
        selected_land_types.indexOf(land_types.filter((l) => l.code === "pond_artificial")[0]["id"]) > -1;
    const has_lakes_natural =
        selected_land_types.indexOf(land_types.filter((l) => l.code === "lake_natural")[0]["id"]) > -1;
    const has_lakes_artificial =
        selected_land_types.indexOf(land_types.filter((l) => l.code === "lake_artificial")[0]["id"]) > -1;

    const has_what = {
        has_edge: has_edge,
        has_riparian: has_riparian,
        has_conifer: has_conifer,
        has_evergreen: has_evergreen,
        has_deciduous: has_deciduous,
        has_mixed: has_mixed,
        has_native_ungrazed: has_native_ungrazed,
        has_native_grazed: has_native_grazed,
        has_nonnative_ungrazed: has_nonnative_ungrazed,
        has_nonnative_grazed: has_nonnative_grazed,
        has_wetland_natural: has_wetland_natural,
        has_wetland_artificial: has_wetland_artificial,
        has_streams: has_streams,
        has_rivers: has_rivers,
        has_ponds_natural: has_ponds_natural,
        has_ponds_artificial: has_ponds_artificial,
        has_lakes_natural: has_lakes_natural,
        has_lakes_artificial: has_lakes_artificial,
    };

    return has_what;
}

export function GET_DEFAULT_SURVEY_VALUES(source_values) {
    function getCheckboxGroupValue(obj, path) {
        if (obj[path] !== undefined && obj[path] !== null) {
            return obj[path].split(",");
        } else {
            return [];
        }
    }

    let values = {};

    if (source_values.buffer !== undefined) {
        const q = source_values.buffer;
        setValue(values, "buffer.edge.area", q["edge_area"]);
        setValue(values, "buffer.edge.area_converted", q["edge_area_converted"]);
        setValue(values, "buffer.edge.previous_land_cover", q["edge_previous_land_cover"]);
        setValue(values, "buffer.edge.composition", q["edge_vegetated"]);
        setValue(values, "buffer.edge.management", getCheckboxGroupValue(q, "edge_management"));
        setValue(values, "buffer.riparian.area", q["riparian_area"]);
        setValue(values, "buffer.riparian.area_converted", q["riparian_area_converted"]);
        setValue(values, "buffer.riparian.previous_land_cover", q["riparian_previous_land_cover"]);
        setValue(values, "buffer.riparian.composition", q["riparian_vegetated"]);
        setValue(values, "buffer.riparian.management", getCheckboxGroupValue(q, "riparian_management"));
    }

    if (source_values.forest !== undefined) {
        const q = source_values.forest;

        setValue(values, "forest.conifer.area", q["conifer_area"]);
        setValue(values, "forest.conifer.area_converted", q["conifer_area_converted"]);
        setValue(values, "forest.conifer.previous_land_cover", q["conifer_previous_land_cover"]);
        setValue(values, "forest.conifer.configuration", q["conifer_predominant_configuration"]);
        setValue(values, "forest.conifer.riparian_management", getCheckboxGroupValue(q, "conifer_management"));

        setValue(values, "forest.deciduous.area", q["deciduous_area"]);
        setValue(values, "forest.deciduous.area_converted", q["deciduous_area_converted"]);
        setValue(values, "forest.deciduous.previous_land_cover", q["deciduous_previous_land_cover"]);
        setValue(values, "forest.deciduous.configuration", q["deciduous_predominant_configuration"]);
        setValue(values, "forest.deciduous.riparian_management", getCheckboxGroupValue(q, "deciduous_management"));

        setValue(values, "forest.evergreen.area", q["evergreen_area"]);
        setValue(values, "forest.evergreen.area_converted", q["evergreen_area_converted"]);
        setValue(values, "forest.evergreen.previous_land_cover", q["evergreen_previous_land_cover"]);
        setValue(values, "forest.evergreen.configuration", q["evergreen_predominant_configuration"]);
        setValue(values, "forest.evergreen.riparian_management", getCheckboxGroupValue(q, "evergreen_management"));

        setValue(values, "forest.mixed.area", q["mixed_area"]);
        setValue(values, "forest.mixed.area_converted", q["mixed_area_converted"]);
        setValue(values, "forest.mixed.previous_land_cover", q["mixed_previous_land_cover"]);
        setValue(values, "forest.mixed.configuration", q["mixed_predominant_configuration"]);
        setValue(values, "forest.mixed.riparian_management", getCheckboxGroupValue(q, "mixed_management"));
    }

    if (source_values.grassland !== undefined) {
        const q = source_values.grassland;

        setValue(values, "grassland.native_grazed.area", q["native_grazed_area"]);
        setValue(values, "grassland.native_grazed.area_converted", q["native_grazed_area_converted"]);
        setValue(values, "grassland.native_grazed.previous_land_cover", q["native_grazed_previous_land_cover"]);
        setValue(values, "grassland.native_grazed.configuration", q["native_grazed_configuration"]);
        setValue(values, "grassland.native_grazed.grazing_intensity", q["native_grazed_grazing_intensity"]);
        setValue(values, "grassland.native_grazed.grazing_conservation", q["native_grazed_grazing_conservation"]);
        setValue(values, "grassland.native_grazed.management", getCheckboxGroupValue(q, "native_grazed_management"));

        setValue(values, "grassland.native_ungrazed.area", q["native_ungrazed_area"]);
        setValue(values, "grassland.native_ungrazed.area_converted", q["native_ungrazed_area_converted"]);
        setValue(values, "grassland.native_ungrazed.previous_land_cover", q["native_ungrazed_previous_land_cover"]);
        setValue(values, "grassland.native_ungrazed.configuration", q["native_ungrazed_configuration"]);
        setValue(
            values,
            "grassland.native_ungrazed.management",
            getCheckboxGroupValue(q, "native_ungrazed_management"),
        );

        setValue(values, "grassland.nonnative_grazed.area", q["nonnative_grazed_area"]);
        setValue(values, "grassland.nonnative_grazed.area_converted", q["nonnative_grazed_area_converted"]);
        setValue(values, "grassland.nonnative_grazed.previous_land_cover", q["nonnative_grazed_previous_land_cover"]);
        setValue(values, "grassland.nonnative_grazed.configuration", q["nonnative_grazed_configuration"]);
        setValue(values, "grassland.nonnative_grazed.grazing_intensity", q["nonnative_grazed_grazing_intensity"]);
        setValue(values, "grassland.nonnative_grazed.grazing_conservation", q["nonnative_grazed_grazing_conservation"]);
        setValue(
            values,
            "grassland.nonnative_grazed.management",
            getCheckboxGroupValue(q, "nonnative_grazed_management"),
        );

        setValue(values, "grassland.nonnative_ungrazed.area", q["nonnative_ungrazed_area"]);
        setValue(values, "grassland.nonnative_ungrazed.area_converted", q["nonnative_ungrazed_area_converted"]);
        setValue(
            values,
            "grassland.nonnative_ungrazed.previous_land_cover",
            q["nonnative_ungrazed_previous_land_cover"],
        );
        setValue(values, "grassland.nonnative_ungrazed.configuration", q["nonnative_ungrazed_configuration"]);
        setValue(
            values,
            "grassland.nonnative_ungrazed.management",
            getCheckboxGroupValue(q, "nonnative_ungrazed_management"),
        );
    }

    if (source_values.surfacewater !== undefined) {
        const q = source_values.surfacewater;

        setValue(values, "surfacewater.stream.length", q["stream_length"]);
        setValue(values, "surfacewater.stream.riparian", q["stream_riparian"]);
        setValue(values, "surfacewater.stream.riparian_vegetation", q["stream_riparian_vegetation"]);
        setValue(values, "surfacewater.stream.riparian_undisturbed", q["stream_riparian_undisturbed"]);
        setValue(
            values,
            "surfacewater.stream.riparian_limited_cattle_access",
            q["stream_riparian_limited_cattle_access"],
        );
        setValue(
            values,
            "surfacewater.stream.riparian_unlimited_cattle_access",
            q["stream_riparian_unlimited_cattle_access"],
        );
        setValue(values, "surfacewater.stream.riparian_crop", q["stream_riparian_crop"]);
        setValue(values, "surfacewater.stream.riparian_impervious", q["stream_riparian_impervious"]);
        setValue(values, "surfacewater.stream.bank_condition", q["stream_bank_condition"]);
        setValue(values, "surfacewater.stream.bank_management", q["stream_bank_management"]);
        setValue(values, "surfacewater.stream.modifications", q["stream_modifications"]);
        setValue(values, "surfacewater.stream.dams", q["stream_dams"] !== null ? q["stream_dams"].toString() : null);
        setValue(values, "surfacewater.stream.instream_habitat", getCheckboxGroupValue(q, "stream_instream_habitat"));

        setValue(values, "surfacewater.river.length", q["river_length"]);
        setValue(values, "surfacewater.river.riparian", q["river_riparian"]);
        setValue(values, "surfacewater.river.riparian_vegetation", q["river_riparian_vegetation"]);
        setValue(values, "surfacewater.river.riparian_undisturbed", q["river_riparian_undisturbed"]);
        setValue(
            values,
            "surfacewater.river.riparian_limited_cattle_access",
            q["river_riparian_limited_cattle_access"],
        );
        setValue(
            values,
            "surfacewater.river.riparian_unlimited_cattle_access",
            q["river_riparian_unlimited_cattle_access"],
        );
        setValue(values, "surfacewater.river.riparian_crop", q["river_riparian_crop"]);
        setValue(values, "surfacewater.river.riparian_impervious", q["river_riparian_impervious"]);
        setValue(values, "surfacewater.river.bank_condition", q["river_bank_condition"]);
        setValue(values, "surfacewater.river.bank_management", q["river_bank_management"]);
        setValue(values, "surfacewater.river.modifications", q["river_modifications"]);
        setValue(values, "surfacewater.river.dams", q["river_dams"] !== null ? q["river_dams"].toString() : null);
        setValue(values, "surfacewater.river.instream_habitat", getCheckboxGroupValue(q, "river_instream_habitat"));

        setValue(values, "surfacewater.pond_natural.area", q["pond_natural_area"]);
        setValue(values, "surfacewater.pond_natural.riparian", q["pond_natural_riparian"]);
        setValue(values, "surfacewater.pond_natural.riparian_vegetation", q["pond_natural_riparian_vegetation"]);
        setValue(values, "surfacewater.pond_natural.riparian_undisturbed", q["pond_natural_riparian_undisturbed"]);
        setValue(
            values,
            "surfacewater.pond_natural.riparian_limited_cattle_access",
            q["pond_natural_riparian_limited_cattle_access"],
        );
        setValue(
            values,
            "surfacewater.pond_natural.riparian_unlimited_cattle_access",
            q["pond_natural_riparian_unlimited_cattle_access"],
        );
        setValue(values, "surfacewater.pond_natural.riparian_crop", q["pond_natural_riparian_crop"]);
        setValue(values, "surfacewater.pond_natural.riparian_impervious", q["pond_natural_riparian_impervious"]);
        setValue(values, "surfacewater.pond_natural.bank_condition", q["pond_natural_bank_condition"]);
        setValue(values, "surfacewater.pond_natural.bank_management", q["pond_natural_bank_management"]);
        setValue(values, "surfacewater.pond_natural.predominant_use", q["pond_natural_predominant_use"]);
        setValue(values, "surfacewater.pond_natural.average_depth", q["pond_natural_average_depth"]);
        setValue(values, "surfacewater.pond_natural.management", getCheckboxGroupValue(q, "pond_natural_management"));

        setValue(values, "surfacewater.pond_artificial.area", q["pond_artificial_area"]);
        setValue(values, "surfacewater.pond_artificial.riparian", q["pond_artificial_riparian"]);
        setValue(values, "surfacewater.pond_artificial.riparian_vegetation", q["pond_artificial_riparian_vegetation"]);
        setValue(
            values,
            "surfacewater.pond_artificial.riparian_undisturbed",
            q["pond_artificial_riparian_undisturbed"],
        );
        setValue(
            values,
            "surfacewater.pond_artificial.riparian_limited_cattle_access",
            q["pond_artificial_riparian_limited_cattle_access"],
        );
        setValue(
            values,
            "surfacewater.pond_artificial.riparian_unlimited_cattle_access",
            q["pond_artificial_riparian_unlimited_cattle_access"],
        );
        setValue(values, "surfacewater.pond_artificial.riparian_crop", q["pond_artificial_riparian_crop"]);
        setValue(values, "surfacewater.pond_artificial.riparian_impervious", q["pond_artificial_riparian_impervious"]);
        setValue(values, "surfacewater.pond_artificial.bank_condition", q["pond_artificial_bank_condition"]);
        setValue(values, "surfacewater.pond_artificial.bank_management", q["pond_artificial_bank_management"]);
        setValue(values, "surfacewater.pond_artificial.predominant_use", q["pond_artificial_predominant_use"]);
        setValue(values, "surfacewater.pond_artificial.average_depth", q["pond_artificial_average_depth"]);
        setValue(
            values,
            "surfacewater.pond_artificial.management",
            getCheckboxGroupValue(q, "pond_artificial_management"),
        );

        setValue(values, "surfacewater.lake_natural.area", q["lake_natural_area"]);
        setValue(values, "surfacewater.lake_natural.riparian", q["lake_natural_riparian"]);
        setValue(values, "surfacewater.lake_natural.riparian_vegetation", q["lake_natural_riparian_vegetation"]);
        setValue(values, "surfacewater.lake_natural.riparian_undisturbed", q["lake_natural_riparian_undisturbed"]);
        setValue(
            values,
            "surfacewater.lake_natural.riparian_limited_cattle_access",
            q["lake_natural_riparian_limited_cattle_access"],
        );
        setValue(
            values,
            "surfacewater.lake_natural.riparian_unlimited_cattle_access",
            q["lake_natural_riparian_unlimited_cattle_access"],
        );
        setValue(values, "surfacewater.lake_natural.riparian_crop", q["lake_natural_riparian_crop"]);
        setValue(values, "surfacewater.lake_natural.riparian_impervious", q["lake_natural_riparian_impervious"]);
        setValue(values, "surfacewater.lake_natural.bank_condition", q["lake_natural_bank_condition"]);
        setValue(values, "surfacewater.lake_natural.bank_management", q["lake_natural_bank_management"]);
        setValue(values, "surfacewater.lake_natural.predominant_use", q["lake_natural_predominant_use"]);
        setValue(values, "surfacewater.lake_natural.average_depth", q["lake_natural_average_depth"]);
        setValue(values, "surfacewater.lake_natural.management", getCheckboxGroupValue(q, "lake_natural_management"));

        setValue(values, "surfacewater.lake_artificial.area", q["lake_artificial_area"]);
        setValue(values, "surfacewater.lake_artificial.riparian", q["lake_artificial_riparian"]);
        setValue(values, "surfacewater.lake_artificial.riparian_vegetation", q["lake_artificial_riparian_vegetation"]);
        setValue(
            values,
            "surfacewater.lake_artificial.riparian_undisturbed",
            q["lake_artificial_riparian_undisturbed"],
        );
        setValue(
            values,
            "surfacewater.lake_artificial.riparian_limited_cattle_access",
            q["lake_artificial_riparian_limited_cattle_access"],
        );
        setValue(
            values,
            "surfacewater.lake_artificial.riparian_unlimited_cattle_access",
            q["lake_artificial_riparian_unlimited_cattle_access"],
        );
        setValue(values, "surfacewater.lake_artificial.riparian_crop", q["lake_artificial_riparian_crop"]);
        setValue(values, "surfacewater.lake_artificial.riparian_impervious", q["lake_artificial_riparian_impervious"]);
        setValue(values, "surfacewater.lake_artificial.bank_condition", q["lake_artificial_bank_condition"]);
        setValue(values, "surfacewater.lake_artificial.bank_management", q["lake_artificial_bank_management"]);
        setValue(values, "surfacewater.lake_artificial.predominant_use", q["lake_artificial_predominant_use"]);
        setValue(values, "surfacewater.lake_artificial.average_depth", q["lake_artificial_average_depth"]);
        setValue(
            values,
            "surfacewater.lake_artificial.management",
            getCheckboxGroupValue(q, "lake_artificial_management"),
        );
    }

    if (source_values.wetland !== undefined) {
        const q = source_values.wetland;

        setValue(values, "wetland.natural.area", q["natural_area"]);
        setValue(values, "wetland.natural.area_converted", q["natural_area_converted"]);
        setValue(values, "wetland.natural.previous_land_cover", q["natural_previous_land_cover"]);
        setValue(values, "wetland.natural.management", q["natural_management"]);
        setValue(values, "wetland.natural.habitat_management", getCheckboxGroupValue(q, "natural_habitat_management"));

        setValue(values, "wetland.artificial.area", q["artificial_area"]);
        setValue(values, "wetland.artificial.area_converted", q["artificial_area_converted"]);
        setValue(values, "wetland.artificial.previous_land_cover", q["artificial_previous_land_cover"]);
        setValue(values, "wetland.artificial.management", q["artificial_management"]);
        setValue(
            values,
            "wetland.artificial.habitat_management",
            getCheckboxGroupValue(q, "artificial_habitat_management"),
        );
    }

    return values;
}

export function VALIDATION(values, has_what, hard_require, farmHpiSurvey, landtypeSubmit, draftSubmit, surveySubmit) {
    let f_o = {};
    let g_o = {};
    let b_o = {};
    let s_o = {};
    let w_o = {};

    let f_r = 0;
    let g_r = 0;
    let b_r = 0;
    let s_r = 0;
    let w_r = 0;

    const validateArea = (o, path) => {
        let val = getValue(values, path);
        if (!val) {
            setValue(o, path, "Required");
            return;
        }
        const number = parseFloat(val);
        if (number <= 0) {
            setValue(onanimationiteration, path, "Must be greater than 0 ac.");
            return;
        }
        setValue(o, path, null);
    };

    const validateAreaConverted = (o, path) => {
        let val = getValue(values, path);
        if (!val) {
            setValue(o, path, "Required");
            return;
        }
        const number = parseFloat(val);
        if (number < 0) {
            setValue(o, path, "Must be greater than or equal to 0 ac.");
            return;
        }
        var area_path = path.split(".").slice(0, -1);
        area_path.push("area");
        area_path = area_path.join(".");
        if (number > parseFloat(getValue(values, area_path))) {
            setValue(o, path, "Must be less than or equal to the area entered in question 1.");
            return;
        }
        setValue(o, path, null);
    };

    const validatePreviousLandCover = (o, path) => {
        var path_parts = path.split(".");
        path_parts.pop();
        path_parts.push("area_converted");
        var area_converted_path = path_parts.join(".");
        var area_converted_value = getValue(values, area_converted_path);
        if (area_converted_value && parseFloat(area_converted_value) > 0) {
            isRequired(o, path);
            return;
        }
        setValue(o, path, null);
    };

    const isRequired = (o, path) => {
        let val = getValue(values, path);
        setValue(o, path, val || val === false ? null : "Required");
    };

    const validateCheckBoxGroup = (o, path) => {
        let val = getValue(values, path);
        if (!val) {
            setValue(o, path, "Required");
            return;
        } else if (val.length === 0) {
            setValue(o, path, "Required");
            return;
        } else if (val.length === 1 && val[0] === "") {
            setValue(o, path, "Required");
            return;
        }
        setValue(o, path, null);
    };

    const validatePercent = (o, path) => {
        let val = getValue(values, path);
        if (!val) {
            setValue(o, path, "Required");
            return;
        }
        const number = parseFloat(val);
        if (number < 0 || val > 100) {
            setValue(o, path, "Must be from 0 to 100.");
            return;
        }
        setValue(o, path, null);
    };

    function forestValidator(has_what) {
        if (has_what["has_conifer"]) {
            validateArea(f_o, "forest.conifer.area");
            validateAreaConverted(f_o, "forest.conifer.area_converted");
            validatePreviousLandCover(f_o, "forest.conifer.previous_land_cover");
            isRequired(f_o, "forest.conifer.configuration");
            validateCheckBoxGroup(f_o, "forest.conifer.riparian_management");
            f_r += 4;
        }

        if (has_what["has_evergreen"]) {
            validateArea(f_o, "forest.evergreen.area");
            validateAreaConverted(f_o, "forest.evergreen.area_converted");
            validatePreviousLandCover(f_o, "forest.evergreen.previous_land_cover");
            isRequired(f_o, "forest.evergreen.configuration");
            validateCheckBoxGroup(f_o, "forest.evergreen.riparian_management");
            f_r += 4;
        }

        if (has_what["has_deciduous"]) {
            validateArea(f_o, "forest.deciduous.area");
            validateAreaConverted(f_o, "forest.deciduous.area_converted");
            validatePreviousLandCover(f_o, "forest.deciduous.previous_land_cover");
            isRequired(f_o, "forest.deciduous.configuration");
            validateCheckBoxGroup(f_o, "forest.deciduous.riparian_management");
            f_r += 4;
        }

        if (has_what["has_mixed"]) {
            validateArea(f_o, "forest.mixed.area");
            validateAreaConverted(f_o, "forest.mixed.area_converted");
            validatePreviousLandCover(f_o, "forest.mixed.previous_land_cover");
            isRequired(f_o, "forest.mixed.configuration");
            validateCheckBoxGroup(f_o, "forest.mixed.riparian_management");
            f_r += 4;
        }
    }

    function grasslandValidator(has_what) {
        if (has_what["has_native_grazed"]) {
            validateArea(g_o, "grassland.native_grazed.area");
            validateAreaConverted(g_o, "grassland.native_grazed.area_converted");
            validatePreviousLandCover(g_o, "grassland.native_grazed.previous_land_cover");
            isRequired(g_o, "grassland.native_grazed.configuration");
            isRequired(g_o, "grassland.native_grazed.grazing_intensity");
            isRequired(g_o, "grassland.native_grazed.grazing_conservation");
            validateCheckBoxGroup(g_o, "grassland.native_grazed.management");
            g_r += 4;
        }

        if (has_what["has_native_ungrazed"]) {
            validateArea(g_o, "grassland.native_ungrazed.area");
            validateAreaConverted(g_o, "grassland.native_ungrazed.area_converted");
            validatePreviousLandCover(g_o, "grassland.native_ungrazed.previous_land_cover");
            isRequired(g_o, "grassland.native_ungrazed.configuration");
            validateCheckBoxGroup(g_o, "grassland.native_ungrazed.management");
            g_r += 4;
        }

        if (has_what["has_nonnative_grazed"]) {
            validateArea(g_o, "grassland.nonnative_grazed.area");
            validateAreaConverted(g_o, "grassland.nonnative_grazed.area_converted");
            validatePreviousLandCover(g_o, "grassland.nonnative_grazed.previous_land_cover");
            isRequired(g_o, "grassland.nonnative_grazed.configuration");
            isRequired(g_o, "grassland.nonnative_grazed.grazing_intensity");
            isRequired(g_o, "grassland.nonnative_grazed.grazing_conservation");
            validateCheckBoxGroup(g_o, "grassland.nonnative_grazed.management");
            g_r += 4;
        }

        if (has_what["has_nonnative_ungrazed"]) {
            validateArea(g_o, "grassland.nonnative_ungrazed.area");
            validateAreaConverted(g_o, "grassland.nonnative_ungrazed.area_converted");
            validatePreviousLandCover(g_o, "grassland.nonnative_ungrazed.previous_land_cover");
            isRequired(g_o, "grassland.nonnative_ungrazed.configuration");
            validateCheckBoxGroup(g_o, "grassland.nonnative_ungrazed.management");
            g_r += 4;
        }
    }

    function bufferValidator(has_what) {
        if (has_what["has_edge"]) {
            validateArea(b_o, "buffer.edge.area");
            validateAreaConverted(b_o, "buffer.edge.area_converted");
            validatePreviousLandCover(b_o, "buffer.edge.previous_land_cover");
            isRequired(b_o, "buffer.edge.composition");
            validateCheckBoxGroup(b_o, "buffer.edge.management");
            b_r += 4;
        }
        if (has_what["has_riparian"]) {
            validateArea(b_o, "buffer.riparian.area");
            validateAreaConverted(b_o, "buffer.riparian.area_converted");
            validatePreviousLandCover(b_o, "buffer.riparian.previous_land_cover");
            isRequired(b_o, "buffer.riparian.composition");
            validateCheckBoxGroup(b_o, "buffer.riparian.management");
            b_r += 4;
        }
    }

    function surfaceWaterValidator(has_what) {
        if (has_what["has_streams"]) {
            validateArea(s_o, "surfacewater.stream.length");
            isRequired(s_o, "surfacewater.stream.riparian");
            isRequired(s_o, "surfacewater.stream.riparian_vegetation");
            validatePercent(s_o, "surfacewater.stream.riparian_undisturbed");
            validatePercent(s_o, "surfacewater.stream.riparian_limited_cattle_access");
            validatePercent(s_o, "surfacewater.stream.riparian_unlimited_cattle_access");
            validatePercent(s_o, "surfacewater.stream.riparian_crop");
            validatePercent(s_o, "surfacewater.stream.riparian_impervious");
            isRequired(s_o, "surfacewater.stream.bank_condition");
            isRequired(s_o, "surfacewater.stream.bank_management");
            isRequired(s_o, "surfacewater.stream.modifications");
            isRequired(s_o, "surfacewater.stream.dams");
            validateCheckBoxGroup(s_o, "surfacewater.stream.instream_habitat");
            s_r += 13;
        }

        if (has_what["has_rivers"]) {
            validateArea(s_o, "surfacewater.river.length");
            isRequired(s_o, "surfacewater.river.riparian");
            isRequired(s_o, "surfacewater.river.riparian_vegetation");
            validatePercent(s_o, "surfacewater.river.riparian_undisturbed");
            validatePercent(s_o, "surfacewater.river.riparian_limited_cattle_access");
            validatePercent(s_o, "surfacewater.river.riparian_unlimited_cattle_access");
            validatePercent(s_o, "surfacewater.river.riparian_crop");
            validatePercent(s_o, "surfacewater.river.riparian_impervious");
            isRequired(s_o, "surfacewater.river.bank_condition");
            isRequired(s_o, "surfacewater.river.bank_management");
            isRequired(s_o, "surfacewater.river.modifications");
            isRequired(s_o, "surfacewater.river.dams");
            validateCheckBoxGroup(s_o, "surfacewater.river.instream_habitat");
            s_r += 13;
        }

        if (has_what["has_ponds_natural"]) {
            validateArea(s_o, "surfacewater.pond_natural.area");
            isRequired(s_o, "surfacewater.pond_natural.riparian");
            isRequired(s_o, "surfacewater.pond_natural.riparian_vegetation");
            validatePercent(s_o, "surfacewater.pond_natural.riparian_undisturbed");
            validatePercent(s_o, "surfacewater.pond_natural.riparian_limited_cattle_access");
            validatePercent(s_o, "surfacewater.pond_natural.riparian_unlimited_cattle_access");
            validatePercent(s_o, "surfacewater.pond_natural.riparian_crop");
            validatePercent(s_o, "surfacewater.pond_natural.riparian_impervious");
            isRequired(s_o, "surfacewater.pond_natural.bank_condition");
            isRequired(s_o, "surfacewater.pond_natural.bank_management");
            isRequired(s_o, "surfacewater.pond_natural.predominant_use");
            isRequired(s_o, "surfacewater.pond_natural.average_depth");
            validateCheckBoxGroup(s_o, "surfacewater.pond_natural.management");
            s_r += 13;
        }

        if (has_what["has_ponds_artificial"]) {
            validateArea(s_o, "surfacewater.pond_artificial.area");
            isRequired(s_o, "surfacewater.pond_artificial.riparian");
            isRequired(s_o, "surfacewater.pond_artificial.riparian_vegetation");
            validatePercent(s_o, "surfacewater.pond_artificial.riparian_undisturbed");
            validatePercent(s_o, "surfacewater.pond_artificial.riparian_limited_cattle_access");
            validatePercent(s_o, "surfacewater.pond_artificial.riparian_unlimited_cattle_access");
            validatePercent(s_o, "surfacewater.pond_artificial.riparian_crop");
            validatePercent(s_o, "surfacewater.pond_artificial.riparian_impervious");
            isRequired(s_o, "surfacewater.pond_artificial.bank_condition");
            isRequired(s_o, "surfacewater.pond_artificial.bank_management");
            isRequired(s_o, "surfacewater.pond_artificial.predominant_use");
            isRequired(s_o, "surfacewater.pond_artificial.average_depth");
            validateCheckBoxGroup(s_o, "surfacewater.pond_artificial.management");
            s_r += 13;
        }

        if (has_what["has_lakes_natural"]) {
            validateArea(s_o, "surfacewater.lake_natural.area");
            isRequired(s_o, "surfacewater.lake_natural.riparian");
            isRequired(s_o, "surfacewater.lake_natural.riparian_vegetation");
            validatePercent(s_o, "surfacewater.lake_natural.riparian_undisturbed");
            validatePercent(s_o, "surfacewater.lake_natural.riparian_limited_cattle_access");
            validatePercent(s_o, "surfacewater.lake_natural.riparian_unlimited_cattle_access");
            validatePercent(s_o, "surfacewater.lake_natural.riparian_crop");
            validatePercent(s_o, "surfacewater.lake_natural.riparian_impervious");
            isRequired(s_o, "surfacewater.lake_natural.bank_condition");
            isRequired(s_o, "surfacewater.lake_natural.bank_management");
            isRequired(s_o, "surfacewater.lake_natural.predominant_use");
            isRequired(s_o, "surfacewater.lake_natural.average_depth");
            validateCheckBoxGroup(s_o, "surfacewater.lake_natural.management");
            s_r += 13;
        }

        if (has_what["has_lakes_artificial"]) {
            validateArea(s_o, "surfacewater.lake_artificial.area");
            isRequired(s_o, "surfacewater.lake_artificial.riparian");
            isRequired(s_o, "surfacewater.lake_artificial.riparian_vegetation");
            validatePercent(s_o, "surfacewater.lake_artificial.riparian_undisturbed");
            validatePercent(s_o, "surfacewater.lake_artificial.riparian_limited_cattle_access");
            validatePercent(s_o, "surfacewater.lake_artificial.riparian_unlimited_cattle_access");
            validatePercent(s_o, "surfacewater.lake_artificial.riparian_crop");
            validatePercent(s_o, "surfacewater.lake_artificial.riparian_impervious");
            isRequired(s_o, "surfacewater.lake_artificial.bank_condition");
            isRequired(s_o, "surfacewater.lake_artificial.bank_management");
            isRequired(s_o, "surfacewater.lake_artificial.predominant_use");
            isRequired(s_o, "surfacewater.lake_artificial.average_depth");
            validateCheckBoxGroup(s_o, "surfacewater.lake_artificial.management");
            s_r += 13;
        }
    }

    function wetlandValidator(has_what) {
        if (has_what["has_wetland_natural"]) {
            validateArea(w_o, "wetland.natural.area");
            validateAreaConverted(w_o, "wetland.natural.area_converted");
            validatePreviousLandCover(w_o, "wetland.natural.previous_land_cover");
            isRequired(w_o, "wetland.natural.management");
            validateCheckBoxGroup(w_o, "wetland.natural.habitat_management");
            w_r += 4;
        }

        if (has_what["has_wetland_artificial"]) {
            validateArea(w_o, "wetland.artificial.area");
            validateAreaConverted(w_o, "wetland.artificial.area_converted");
            validatePreviousLandCover(w_o, "wetland.artificial.previous_land_cover");
            isRequired(w_o, "wetland.artificial.management");
            validateCheckBoxGroup(w_o, "wetland.artificial.habitat_management");
            w_r += 4;
        }
    }

    bufferValidator(has_what);
    forestValidator(has_what);
    grasslandValidator(has_what);
    surfaceWaterValidator(has_what);
    wetlandValidator(has_what);

    let valObj = { ...f_o, ...g_o, ...b_o, ...s_o, ...w_o };

    let validation_objects = { forest: f_o, grassland: g_o, buffer: b_o, surfacewater: s_o, wetland: w_o };

    if (surveySubmit) {
        return valObj;
    }

    //  validation when loading tab 2 or initial validation on draftSubmit
    if (landtypeSubmit === false && draftSubmit === false && surveySubmit === false) {
        Object.keys(valObj).forEach(function (key) {
            if (valObj[key] !== null && typeof valObj[key] === "object") {
                // Also check child objects
                Object.keys(valObj[key]).forEach(function (childKey) {
                    if (valObj[key][childKey] !== null && typeof valObj[key][childKey] === "object") {
                        Object.keys(valObj[key][childKey]).forEach(function (childKey2) {
                            valObj[key][childKey][childKey2] = null;
                        });
                    }
                });
            }
        });

        return valObj;
    }

    //  submitting landtype selections on tab 1.  Need to update missing/required values on questionnaires.
    if (landtypeSubmit || draftSubmit) {
        const landtypes = ["forest", "grassland", "buffer", "surfacewater", "wetland"];

        const questionnaires = {
            forest: farmHpiSurvey.forest,
            grassland: farmHpiSurvey.grassland,
            buffer: farmHpiSurvey.buffer,
            surfacewater: farmHpiSurvey.surfacewater,
            wetland: farmHpiSurvey.wetland,
        };
        const requireds = {
            forest: f_r,
            grassland: g_r,
            buffer: b_r,
            surfacewater: s_r,
            wetland: w_r,
        };

        let extraInfo = {};
        landtypes.forEach((t) => {
            if (questionnaires[t]) {
                if (validation_objects[t]) {
                    var missing = 0;
                    const validation_object = validation_objects[t];
                    Object.keys(validation_object).forEach(function (key) {
                        if (validation_object[key] !== null && typeof validation_object[key] === "object") {
                            // Also check child objects
                            Object.keys(validation_object[key]).forEach(function (childKey) {
                                if (
                                    validation_object[key][childKey] !== null &&
                                    typeof validation_object[key][childKey] === "object"
                                ) {
                                    Object.keys(validation_object[key][childKey]).forEach(function (childKey2) {
                                        if (validation_object[key][childKey][childKey2] !== null) missing++;
                                    });
                                }
                            });
                        } else if (validation_object[key] !== null) missing++;
                    });
                    extraInfo[t] = [missing, requireds[t]];
                }
            }
        });

        return extraInfo;
    }
}

//  only store the state/counties with a value of 1 to keep the file siz small
export const pop_change = [
    {
        state_name: "Alabama",
        county_name: "Baldwin County",
        value: 1,
    },
    {
        state_name: "Alabama",
        county_name: "Lee County",
        value: 1,
    },
    {
        state_name: "Alabama",
        county_name: "Limestone County",
        value: 1,
    },
    {
        state_name: "Alabama",
        county_name: "Russell County",
        value: 1,
    },
    {
        state_name: "Alabama",
        county_name: "Shelby County",
        value: 1,
    },
    {
        state_name: "Arizona",
        county_name: "Greenlee County",
        value: 1,
    },
    {
        state_name: "Arizona",
        county_name: "Maricopa County",
        value: 1,
    },
    {
        state_name: "Arkansas",
        county_name: "Benton County",
        value: 1,
    },
    {
        state_name: "Arkansas",
        county_name: "Craighead County",
        value: 1,
    },
    {
        state_name: "Arkansas",
        county_name: "Faulkner County",
        value: 1,
    },
    {
        state_name: "Arkansas",
        county_name: "Saline County",
        value: 1,
    },
    {
        state_name: "Arkansas",
        county_name: "Washington County",
        value: 1,
    },
    {
        state_name: "California",
        county_name: "Alameda County",
        value: 1,
    },
    {
        state_name: "California",
        county_name: "Contra Costa County",
        value: 1,
    },
    {
        state_name: "California",
        county_name: "Placer County",
        value: 1,
    },
    {
        state_name: "California",
        county_name: "Riverside County",
        value: 1,
    },
    {
        state_name: "California",
        county_name: "San Mateo County",
        value: 1,
    },
    {
        state_name: "California",
        county_name: "Santa Clara County",
        value: 1,
    },
    {
        state_name: "Colorado",
        county_name: "Adams County",
        value: 1,
    },
    {
        state_name: "Colorado",
        county_name: "Arapahoe County",
        value: 1,
    },
    {
        state_name: "Colorado",
        county_name: "Boulder County",
        value: 1,
    },
    {
        state_name: "Colorado",
        county_name: "Broomfield County",
        value: 1,
    },
    {
        state_name: "Colorado",
        county_name: "Denver County",
        value: 1,
    },
    {
        state_name: "Colorado",
        county_name: "Douglas County",
        value: 1,
    },
    {
        state_name: "Colorado",
        county_name: "El Paso County",
        value: 1,
    },
    {
        state_name: "Colorado",
        county_name: "Kiowa County",
        value: 1,
    },
    {
        state_name: "Colorado",
        county_name: "Larimer County",
        value: 1,
    },
    {
        state_name: "Colorado",
        county_name: "Rio Blanco County",
        value: 1,
    },
    {
        state_name: "Colorado",
        county_name: "Saguache County",
        value: 1,
    },
    {
        state_name: "Colorado",
        county_name: "San Miguel County",
        value: 1,
    },
    {
        state_name: "Colorado",
        county_name: "Weld County",
        value: 1,
    },
    {
        state_name: "Delaware",
        county_name: "Kent County",
        value: 1,
    },
    {
        state_name: "Delaware",
        county_name: "Sussex County",
        value: 1,
    },
    {
        state_name: "District of Columbia",
        county_name: "District of Columbia",
        value: 1,
    },
    {
        state_name: "Florida",
        county_name: "Broward County",
        value: 1,
    },
    {
        state_name: "Florida",
        county_name: "Collier County",
        value: 1,
    },
    {
        state_name: "Florida",
        county_name: "Hillsborough County",
        value: 1,
    },
    {
        state_name: "Florida",
        county_name: "Lee County",
        value: 1,
    },
    {
        state_name: "Florida",
        county_name: "Leon County",
        value: 1,
    },
    {
        state_name: "Florida",
        county_name: "Manatee County",
        value: 1,
    },
    {
        state_name: "Florida",
        county_name: "Miami-Dade County",
        value: 1,
    },
    {
        state_name: "Florida",
        county_name: "Okaloosa County",
        value: 1,
    },
    {
        state_name: "Florida",
        county_name: "Orange County",
        value: 1,
    },
    {
        state_name: "Florida",
        county_name: "Osceola County",
        value: 1,
    },
    {
        state_name: "Florida",
        county_name: "Santa Rosa County",
        value: 1,
    },
    {
        state_name: "Florida",
        county_name: "St. Johns County",
        value: 1,
    },
    {
        state_name: "Florida",
        county_name: "Sumter County",
        value: 1,
    },
    {
        state_name: "Florida",
        county_name: "Suwannee County",
        value: 1,
    },
    {
        state_name: "Florida",
        county_name: "Walton County",
        value: 1,
    },
    {
        state_name: "Georgia",
        county_name: "Bryan County",
        value: 1,
    },
    {
        state_name: "Georgia",
        county_name: "Bulloch County",
        value: 1,
    },
    {
        state_name: "Georgia",
        county_name: "Charlton County",
        value: 1,
    },
    {
        state_name: "Georgia",
        county_name: "Chatham County",
        value: 1,
    },
    {
        state_name: "Georgia",
        county_name: "Chattahoochee County",
        value: 1,
    },
    {
        state_name: "Georgia",
        county_name: "Cherokee County",
        value: 1,
    },
    {
        state_name: "Georgia",
        county_name: "Cobb County",
        value: 1,
    },
    {
        state_name: "Georgia",
        county_name: "Columbia County",
        value: 1,
    },
    {
        state_name: "Georgia",
        county_name: "Forsyth County",
        value: 1,
    },
    {
        state_name: "Georgia",
        county_name: "Fulton County",
        value: 1,
    },
    {
        state_name: "Georgia",
        county_name: "Gwinnett County",
        value: 1,
    },
    {
        state_name: "Georgia",
        county_name: "Hall County",
        value: 1,
    },
    {
        state_name: "Georgia",
        county_name: "Houston County",
        value: 1,
    },
    {
        state_name: "Georgia",
        county_name: "Jenkins County",
        value: 1,
    },
    {
        state_name: "Georgia",
        county_name: "Lanier County",
        value: 1,
    },
    {
        state_name: "Georgia",
        county_name: "Liberty County",
        value: 1,
    },
    {
        state_name: "Georgia",
        county_name: "Long County",
        value: 1,
    },
    {
        state_name: "Georgia",
        county_name: "Lowndes County",
        value: 1,
    },
    {
        state_name: "Georgia",
        county_name: "Muscogee County",
        value: 1,
    },
    {
        state_name: "Idaho",
        county_name: "Ada County",
        value: 1,
    },
    {
        state_name: "Indiana",
        county_name: "Bartholomew County",
        value: 1,
    },
    {
        state_name: "Indiana",
        county_name: "Boone County",
        value: 1,
    },
    {
        state_name: "Indiana",
        county_name: "Hamilton County",
        value: 1,
    },
    {
        state_name: "Indiana",
        county_name: "Hendricks County",
        value: 1,
    },
    {
        state_name: "Indiana",
        county_name: "Tippecanoe County",
        value: 1,
    },
    {
        state_name: "Iowa",
        county_name: "Dallas County",
        value: 1,
    },
    {
        state_name: "Iowa",
        county_name: "Johnson County",
        value: 1,
    },
    {
        state_name: "Iowa",
        county_name: "Polk County",
        value: 1,
    },
    {
        state_name: "Kansas",
        county_name: "Geary County",
        value: 1,
    },
    {
        state_name: "Kansas",
        county_name: "Greeley County",
        value: 1,
    },
    {
        state_name: "Kansas",
        county_name: "Hodgeman County",
        value: 1,
    },
    {
        state_name: "Kansas",
        county_name: "Johnson County",
        value: 1,
    },
    {
        state_name: "Kansas",
        county_name: "Pottawatomie County",
        value: 1,
    },
    {
        state_name: "Kansas",
        county_name: "Riley County",
        value: 1,
    },
    {
        state_name: "Kentucky",
        county_name: "Boone County",
        value: 1,
    },
    {
        state_name: "Kentucky",
        county_name: "Fayette County",
        value: 1,
    },
    {
        state_name: "Kentucky",
        county_name: "Scott County",
        value: 1,
    },
    {
        state_name: "Kentucky",
        county_name: "Shelby County",
        value: 1,
    },
    {
        state_name: "Kentucky",
        county_name: "Warren County",
        value: 1,
    },
    {
        state_name: "Louisiana",
        county_name: "Ascension Parish",
        value: 1,
    },
    {
        state_name: "Louisiana",
        county_name: "Bossier Parish",
        value: 1,
    },
    {
        state_name: "Louisiana",
        county_name: "Livingston Parish",
        value: 1,
    },
    {
        state_name: "Louisiana",
        county_name: "Orleans Parish",
        value: 1,
    },
    {
        state_name: "Louisiana",
        county_name: "Plaquemines Parish",
        value: 1,
    },
    {
        state_name: "Louisiana",
        county_name: "St. Bernard Parish",
        value: 1,
    },
    {
        state_name: "Maryland",
        county_name: "Howard County",
        value: 1,
    },
    {
        state_name: "Maryland",
        county_name: "Montgomery County",
        value: 1,
    },
    {
        state_name: "Maryland",
        county_name: "St. Mary's County",
        value: 1,
    },
    {
        state_name: "Massachusetts",
        county_name: "Dukes County",
        value: 1,
    },
    {
        state_name: "Massachusetts",
        county_name: "Suffolk County",
        value: 1,
    },
    {
        state_name: "Michigan",
        county_name: "Keweenaw County",
        value: 1,
    },
    {
        state_name: "Minnesota",
        county_name: "Carver County",
        value: 1,
    },
    {
        state_name: "Minnesota",
        county_name: "Hennepin County",
        value: 1,
    },
    {
        state_name: "Minnesota",
        county_name: "Scott County",
        value: 1,
    },
    {
        state_name: "Mississippi",
        county_name: "DeSoto County",
        value: 1,
    },
    {
        state_name: "Mississippi",
        county_name: "Hancock County",
        value: 1,
    },
    {
        state_name: "Mississippi",
        county_name: "Harrison County",
        value: 1,
    },
    {
        state_name: "Mississippi",
        county_name: "Lafayette County",
        value: 1,
    },
    {
        state_name: "Mississippi",
        county_name: "Lamar County",
        value: 1,
    },
    {
        state_name: "Mississippi",
        county_name: "Madison County",
        value: 1,
    },
    {
        state_name: "Missouri",
        county_name: "Boone County",
        value: 1,
    },
    {
        state_name: "Missouri",
        county_name: "Johnson County",
        value: 1,
    },
    {
        state_name: "Missouri",
        county_name: "Platte County",
        value: 1,
    },
    {
        state_name: "Montana",
        county_name: "Blaine County",
        value: 1,
    },
    {
        state_name: "Montana",
        county_name: "Dawson County",
        value: 1,
    },
    {
        state_name: "Montana",
        county_name: "Fallon County",
        value: 1,
    },
    {
        state_name: "Montana",
        county_name: "Gallatin County",
        value: 1,
    },
    {
        state_name: "Montana",
        county_name: "Garfield County",
        value: 1,
    },
    {
        state_name: "Montana",
        county_name: "Musselshell County",
        value: 1,
    },
    {
        state_name: "Montana",
        county_name: "Petroleum County",
        value: 1,
    },
    {
        state_name: "Montana",
        county_name: "Richland County",
        value: 1,
    },
    {
        state_name: "Montana",
        county_name: "Roosevelt County",
        value: 1,
    },
    {
        state_name: "Montana",
        county_name: "Sheridan County",
        value: 1,
    },
    {
        state_name: "Montana",
        county_name: "Wibaux County",
        value: 1,
    },
    {
        state_name: "Nebraska",
        county_name: "Arthur County",
        value: 1,
    },
    {
        state_name: "Nebraska",
        county_name: "Banner County",
        value: 1,
    },
    {
        state_name: "Nebraska",
        county_name: "Blaine County",
        value: 1,
    },
    {
        state_name: "Nebraska",
        county_name: "Buffalo County",
        value: 1,
    },
    {
        state_name: "Nebraska",
        county_name: "Chase County",
        value: 1,
    },
    {
        state_name: "Nebraska",
        county_name: "Grant County",
        value: 1,
    },
    {
        state_name: "Nebraska",
        county_name: "Hall County",
        value: 1,
    },
    {
        state_name: "Nebraska",
        county_name: "Sarpy County",
        value: 1,
    },
    {
        state_name: "Nebraska",
        county_name: "Thomas County",
        value: 1,
    },
    {
        state_name: "Nevada",
        county_name: "Elko County",
        value: 1,
    },
    {
        state_name: "Nevada",
        county_name: "Humboldt County",
        value: 1,
    },
    {
        state_name: "Nevada",
        county_name: "Lander County",
        value: 1,
    },
    {
        state_name: "New Jersey",
        county_name: "Hudson County",
        value: 1,
    },
    {
        state_name: "New Mexico",
        county_name: "Harding County",
        value: 1,
    },
    {
        state_name: "New Mexico",
        county_name: "Lea County",
        value: 1,
    },
    {
        state_name: "New Mexico",
        county_name: "Otero County",
        value: 1,
    },
    {
        state_name: "New York",
        county_name: "Jefferson County",
        value: 1,
    },
    {
        state_name: "North Carolina",
        county_name: "Brunswick County",
        value: 1,
    },
    {
        state_name: "North Carolina",
        county_name: "Cabarrus County",
        value: 1,
    },
    {
        state_name: "North Carolina",
        county_name: "Chatham County",
        value: 1,
    },
    {
        state_name: "North Carolina",
        county_name: "Durham County",
        value: 1,
    },
    {
        state_name: "North Carolina",
        county_name: "Harnett County",
        value: 1,
    },
    {
        state_name: "North Carolina",
        county_name: "Hoke County",
        value: 1,
    },
    {
        state_name: "North Carolina",
        county_name: "Johnston County",
        value: 1,
    },
    {
        state_name: "North Carolina",
        county_name: "Lee County",
        value: 1,
    },
    {
        state_name: "North Carolina",
        county_name: "Mecklenburg County",
        value: 1,
    },
    {
        state_name: "North Carolina",
        county_name: "New Hanover County",
        value: 1,
    },
    {
        state_name: "North Carolina",
        county_name: "Orange County",
        value: 1,
    },
    {
        state_name: "North Carolina",
        county_name: "Pender County",
        value: 1,
    },
    {
        state_name: "North Carolina",
        county_name: "Union County",
        value: 1,
    },
    {
        state_name: "North Carolina",
        county_name: "Wake County",
        value: 1,
    },
    {
        state_name: "North Dakota",
        county_name: "Billings County",
        value: 1,
    },
    {
        state_name: "North Dakota",
        county_name: "Burke County",
        value: 1,
    },
    {
        state_name: "North Dakota",
        county_name: "Burleigh County",
        value: 1,
    },
    {
        state_name: "North Dakota",
        county_name: "Cass County",
        value: 1,
    },
    {
        state_name: "North Dakota",
        county_name: "Divide County",
        value: 1,
    },
    {
        state_name: "North Dakota",
        county_name: "Dunn County",
        value: 1,
    },
    {
        state_name: "North Dakota",
        county_name: "Golden Valley County",
        value: 1,
    },
    {
        state_name: "North Dakota",
        county_name: "Hettinger County",
        value: 1,
    },
    {
        state_name: "North Dakota",
        county_name: "McHenry County",
        value: 1,
    },
    {
        state_name: "North Dakota",
        county_name: "McKenzie County",
        value: 1,
    },
    {
        state_name: "North Dakota",
        county_name: "McLean County",
        value: 1,
    },
    {
        state_name: "North Dakota",
        county_name: "Mountrail County",
        value: 1,
    },
    {
        state_name: "North Dakota",
        county_name: "Renville County",
        value: 1,
    },
    {
        state_name: "North Dakota",
        county_name: "Rolette County",
        value: 1,
    },
    {
        state_name: "North Dakota",
        county_name: "Sioux County",
        value: 1,
    },
    {
        state_name: "North Dakota",
        county_name: "Slope County",
        value: 1,
    },
    {
        state_name: "North Dakota",
        county_name: "Stark County",
        value: 1,
    },
    {
        state_name: "North Dakota",
        county_name: "Towner County",
        value: 1,
    },
    {
        state_name: "North Dakota",
        county_name: "Ward County",
        value: 1,
    },
    {
        state_name: "North Dakota",
        county_name: "Williams County",
        value: 1,
    },
    {
        state_name: "Ohio",
        county_name: "Delaware County",
        value: 1,
    },
    {
        state_name: "Oklahoma",
        county_name: "Beckham County",
        value: 1,
    },
    {
        state_name: "Oklahoma",
        county_name: "Canadian County",
        value: 1,
    },
    {
        state_name: "Oklahoma",
        county_name: "Cleveland County",
        value: 1,
    },
    {
        state_name: "Oklahoma",
        county_name: "Custer County",
        value: 1,
    },
    {
        state_name: "Oklahoma",
        county_name: "Logan County",
        value: 1,
    },
    {
        state_name: "Oklahoma",
        county_name: "McClain County",
        value: 1,
    },
    {
        state_name: "Oklahoma",
        county_name: "Oklahoma County",
        value: 1,
    },
    {
        state_name: "Oklahoma",
        county_name: "Roger Mills County",
        value: 1,
    },
    {
        state_name: "Oklahoma",
        county_name: "Texas County",
        value: 1,
    },
    {
        state_name: "Oklahoma",
        county_name: "Woodward County",
        value: 1,
    },
    {
        state_name: "Oregon",
        county_name: "Deschutes County",
        value: 1,
    },
    {
        state_name: "Oregon",
        county_name: "Gilliam County",
        value: 1,
    },
    {
        state_name: "Oregon",
        county_name: "Multnomah County",
        value: 1,
    },
    {
        state_name: "Oregon",
        county_name: "Washington County",
        value: 1,
    },
    {
        state_name: "South Carolina",
        county_name: "Beaufort County",
        value: 1,
    },
    {
        state_name: "South Carolina",
        county_name: "Berkeley County",
        value: 1,
    },
    {
        state_name: "South Carolina",
        county_name: "Charleston County",
        value: 1,
    },
    {
        state_name: "South Carolina",
        county_name: "Dorchester County",
        value: 1,
    },
    {
        state_name: "South Carolina",
        county_name: "Greenville County",
        value: 1,
    },
    {
        state_name: "South Carolina",
        county_name: "Horry County",
        value: 1,
    },
    {
        state_name: "South Carolina",
        county_name: "Jasper County",
        value: 1,
    },
    {
        state_name: "South Carolina",
        county_name: "Lancaster County",
        value: 1,
    },
    {
        state_name: "South Carolina",
        county_name: "Lexington County",
        value: 1,
    },
    {
        state_name: "South Carolina",
        county_name: "York County",
        value: 1,
    },
    {
        state_name: "South Dakota",
        county_name: "Buffalo County",
        value: 1,
    },
    {
        state_name: "South Dakota",
        county_name: "Dewey County",
        value: 1,
    },
    {
        state_name: "South Dakota",
        county_name: "Harding County",
        value: 1,
    },
    {
        state_name: "South Dakota",
        county_name: "Jackson County",
        value: 1,
    },
    {
        state_name: "South Dakota",
        county_name: "Lake County",
        value: 1,
    },
    {
        state_name: "South Dakota",
        county_name: "Lincoln County",
        value: 1,
    },
    {
        state_name: "South Dakota",
        county_name: "Mellette County",
        value: 1,
    },
    {
        state_name: "South Dakota",
        county_name: "Minnehaha County",
        value: 1,
    },
    {
        state_name: "South Dakota",
        county_name: "Pennington County",
        value: 1,
    },
    {
        state_name: "South Dakota",
        county_name: "Shannon County",
        value: 1,
    },
    {
        state_name: "South Dakota",
        county_name: "Spink County",
        value: 1,
    },
    {
        state_name: "South Dakota",
        county_name: "Sully County",
        value: 1,
    },
    {
        state_name: "South Dakota",
        county_name: "Todd County",
        value: 1,
    },
    {
        state_name: "South Dakota",
        county_name: "Union County",
        value: 1,
    },
    {
        state_name: "Tennessee",
        county_name: "Davidson County",
        value: 1,
    },
    {
        state_name: "Tennessee",
        county_name: "Montgomery County",
        value: 1,
    },
    {
        state_name: "Tennessee",
        county_name: "Rutherford County",
        value: 1,
    },
    {
        state_name: "Tennessee",
        county_name: "Sevier County",
        value: 1,
    },
    {
        state_name: "Tennessee",
        county_name: "Sumner County",
        value: 1,
    },
    {
        state_name: "Tennessee",
        county_name: "Williamson County",
        value: 1,
    },
    {
        state_name: "Tennessee",
        county_name: "Wilson County",
        value: 1,
    },
    {
        state_name: "Texas",
        county_name: "Andrews County",
        value: 1,
    },
    {
        state_name: "Texas",
        county_name: "Aransas County",
        value: 1,
    },
    {
        state_name: "Texas",
        county_name: "Atascosa County",
        value: 1,
    },
    {
        state_name: "Texas",
        county_name: "Bell County",
        value: 1,
    },
    {
        state_name: "Texas",
        county_name: "Bexar County",
        value: 1,
    },
    {
        state_name: "Texas",
        county_name: "Brazoria County",
        value: 1,
    },
    {
        state_name: "Texas",
        county_name: "Brazos County",
        value: 1,
    },
    {
        state_name: "Texas",
        county_name: "Chambers County",
        value: 1,
    },
    {
        state_name: "Texas",
        county_name: "Collin County",
        value: 1,
    },
    {
        state_name: "Texas",
        county_name: "Comal County",
        value: 1,
    },
    {
        state_name: "Texas",
        county_name: "Crane County",
        value: 1,
    },
    {
        state_name: "Texas",
        county_name: "Dallam County",
        value: 1,
    },
    {
        state_name: "Texas",
        county_name: "Dallas County",
        value: 1,
    },
    {
        state_name: "Texas",
        county_name: "Denton County",
        value: 1,
    },
    {
        state_name: "Texas",
        county_name: "Dimmit County",
        value: 1,
    },
    {
        state_name: "Texas",
        county_name: "Ector County",
        value: 1,
    },
    {
        state_name: "Texas",
        county_name: "El Paso County",
        value: 1,
    },
    {
        state_name: "Texas",
        county_name: "Erath County",
        value: 1,
    },
    {
        state_name: "Texas",
        county_name: "Fort Bend County",
        value: 1,
    },
    {
        state_name: "Texas",
        county_name: "Frio County",
        value: 1,
    },
    {
        state_name: "Texas",
        county_name: "Gaines County",
        value: 1,
    },
    {
        state_name: "Texas",
        county_name: "Galveston County",
        value: 1,
    },
    {
        state_name: "Texas",
        county_name: "Guadalupe County",
        value: 1,
    },
    {
        state_name: "Texas",
        county_name: "Harris County",
        value: 1,
    },
    {
        state_name: "Texas",
        county_name: "Harrison County",
        value: 1,
    },
    {
        state_name: "Texas",
        county_name: "Hays County",
        value: 1,
    },
    {
        state_name: "Texas",
        county_name: "Hemphill County",
        value: 1,
    },
    {
        state_name: "Texas",
        county_name: "Hidalgo County",
        value: 1,
    },
    {
        state_name: "Texas",
        county_name: "Karnes County",
        value: 1,
    },
    {
        state_name: "Texas",
        county_name: "Kaufman County",
        value: 1,
    },
    {
        state_name: "Texas",
        county_name: "Kendall County",
        value: 1,
    },
    {
        state_name: "Texas",
        county_name: "Kenedy County",
        value: 1,
    },
    {
        state_name: "Texas",
        county_name: "Kent County",
        value: 1,
    },
    {
        state_name: "Texas",
        county_name: "La Salle County",
        value: 1,
    },
    {
        state_name: "Texas",
        county_name: "Lipscomb County",
        value: 1,
    },
    {
        state_name: "Texas",
        county_name: "Martin County",
        value: 1,
    },
    {
        state_name: "Texas",
        county_name: "Midland County",
        value: 1,
    },
    {
        state_name: "Texas",
        county_name: "Montgomery County",
        value: 1,
    },
    {
        state_name: "Texas",
        county_name: "Ochiltree County",
        value: 1,
    },
    {
        state_name: "Texas",
        county_name: "Randall County",
        value: 1,
    },
    {
        state_name: "Texas",
        county_name: "Reagan County",
        value: 1,
    },
    {
        state_name: "Texas",
        county_name: "Rockwall County",
        value: 1,
    },
    {
        state_name: "Texas",
        county_name: "San Jacinto County",
        value: 1,
    },
    {
        state_name: "Texas",
        county_name: "Sterling County",
        value: 1,
    },
    {
        state_name: "Texas",
        county_name: "Tarrant County",
        value: 1,
    },
    {
        state_name: "Texas",
        county_name: "Travis County",
        value: 1,
    },
    {
        state_name: "Texas",
        county_name: "Victoria County",
        value: 1,
    },
    {
        state_name: "Texas",
        county_name: "Webb County",
        value: 1,
    },
    {
        state_name: "Texas",
        county_name: "Wheeler County",
        value: 1,
    },
    {
        state_name: "Texas",
        county_name: "Williamson County",
        value: 1,
    },
    {
        state_name: "Texas",
        county_name: "Wilson County",
        value: 1,
    },
    {
        state_name: "Texas",
        county_name: "Winkler County",
        value: 1,
    },
    {
        state_name: "Texas",
        county_name: "Yoakum County",
        value: 1,
    },
    {
        state_name: "Utah",
        county_name: "Davis County",
        value: 1,
    },
    {
        state_name: "Utah",
        county_name: "Duchesne County",
        value: 1,
    },
    {
        state_name: "Utah",
        county_name: "Morgan County",
        value: 1,
    },
    {
        state_name: "Utah",
        county_name: "Salt Lake County",
        value: 1,
    },
    {
        state_name: "Utah",
        county_name: "Summit County",
        value: 1,
    },
    {
        state_name: "Utah",
        county_name: "Uintah County",
        value: 1,
    },
    {
        state_name: "Utah",
        county_name: "Utah County",
        value: 1,
    },
    {
        state_name: "Utah",
        county_name: "Wasatch County",
        value: 1,
    },
    {
        state_name: "Utah",
        county_name: "Washington County",
        value: 1,
    },
    {
        state_name: "Virginia",
        county_name: "Albemarle County",
        value: 1,
    },
    {
        state_name: "Virginia",
        county_name: "Alexandria city",
        value: 1,
    },
    {
        state_name: "Virginia",
        county_name: "Arlington County",
        value: 1,
    },
    {
        state_name: "Virginia",
        county_name: "Fairfax city",
        value: 1,
    },
    {
        state_name: "Virginia",
        county_name: "Fairfax County",
        value: 1,
    },
    {
        state_name: "Virginia",
        county_name: "Falls Church city",
        value: 1,
    },
    {
        state_name: "Virginia",
        county_name: "Fredericksburg city",
        value: 1,
    },
    {
        state_name: "Virginia",
        county_name: "Harrisonburg city",
        value: 1,
    },
    {
        state_name: "Virginia",
        county_name: "King George County",
        value: 1,
    },
    {
        state_name: "Virginia",
        county_name: "Loudoun County",
        value: 1,
    },
    {
        state_name: "Virginia",
        county_name: "Manassas city",
        value: 1,
    },
    {
        state_name: "Virginia",
        county_name: "Manassas Park city",
        value: 1,
    },
    {
        state_name: "Virginia",
        county_name: "New Kent County",
        value: 1,
    },
    {
        state_name: "Virginia",
        county_name: "Prince George County",
        value: 1,
    },
    {
        state_name: "Virginia",
        county_name: "Prince William County",
        value: 1,
    },
    {
        state_name: "Virginia",
        county_name: "Richmond city",
        value: 1,
    },
    {
        state_name: "Virginia",
        county_name: "Stafford County",
        value: 1,
    },
    {
        state_name: "Virginia",
        county_name: "Williamsburg city",
        value: 1,
    },
    {
        state_name: "Washington",
        county_name: "Benton County",
        value: 1,
    },
    {
        state_name: "Washington",
        county_name: "Clark County",
        value: 1,
    },
    {
        state_name: "Washington",
        county_name: "Franklin County",
        value: 1,
    },
    {
        state_name: "Washington",
        county_name: "King County",
        value: 1,
    },
    {
        state_name: "Washington",
        county_name: "Whitman County",
        value: 1,
    },
    {
        state_name: "West Virginia",
        county_name: "Monongalia County",
        value: 1,
    },
    {
        state_name: "Wisconsin",
        county_name: "Dane County",
        value: 1,
    },
    {
        state_name: "Wyoming",
        county_name: "Campbell County",
        value: 1,
    },
    {
        state_name: "Wyoming",
        county_name: "Laramie County",
        value: 1,
    },
    {
        state_name: "Wyoming",
        county_name: "Natrona County",
        value: 1,
    },
    {
        state_name: "Wyoming",
        county_name: "Sweetwater County",
        value: 1,
    },
];
