import React, { Component } from "react";
import { getValue } from "../../../api/utils";
import withStyles from "@mui/styles/withStyles";
import { getLabel, getColor, getDescription, getScoreDescription } from "./StaticData";
import { getIcon } from "./StaticIcons";

import Grid from "../../common/GridWrapper";
import Typography from "../../common/TypographyWrapper";
import { Header, Paragraph } from "./Table";

import Callout from "./Callout";
import BenchmarkTable from "./BenchmarkTable";

import metricDetail from "./metrics/detail";
import getScoreComponent from "./metrics/getScoreComponent";

export const styles = {
    // Styles Scores
    scoreContainer: {
        border: "1px solid #DDDDDD",
        textAlign: "center",
    },
    container1: {
        backgroundColor: getColor("active_year"),
        paddingTop: 8,
        paddingBottom: 8,
    },
    text1: {
        color: "#fff",
    },
    text2: {
        color: getColor("active_year"),
    },
    alignRight: {
        textAlign: "right",
        paddingRight: 8,
    },
    indicatorIcon: {
        width: "28px",
        height: "28px",
        verticalAlign: "middle",
    },
    label: {
        color: "rgba(0, 0, 0, 0.87)",
        fontSize: 15,
    },
};

function Summary({ metric, indicator, printable, Score, classes }) {
    if (printable) {
        const label = getLabel(metric),
            icon = getIcon(metric);
        return (
            <>
                <Header style={{ display: "flex", alignItems: "center", width: "100%" }}>
                    <img src={icon} className={classes.indicatorIcon} alt={label} />
                    <span className={classes.label}>{label}:</span>
                    <Score indicator={indicator} printable />
                </Header>

                <Grid item xs={12}>
                    <Paragraph>{getDescription(metric + "Print")}</Paragraph>
                </Grid>
            </>
        );
    }
    const project_benchmarks = getValue(indicator.cropyear, "project.project_benchmarks");
    return (
        <>
            <Grid item xs={12}>
                <Typography>{getDescription(metric)}</Typography>
            </Grid>
            <Callout title={"Your Score"} printable={printable} split={6}>
                <Grid container justifyContent="center" className={classes.scoreContainer}>
                    <Grid xs={12} className={classes.container1}>
                        <Typography variant="title" className={classes.text1}>
                            {getLabel(metric)}
                        </Typography>
                    </Grid>
                    <Grid xs={12}>
                        <Typography>{indicator.year + " " + indicator.crop}</Typography>
                    </Grid>
                    <Score indicator={indicator} printable={printable} />
                </Grid>

                <Typography>{getScoreDescription(metric, indicator)}</Typography>
            </Callout>

            <BenchmarkTable
                title={"Comparison to Benchmarks"}
                indicator={indicator}
                printable={printable}
                project_benchmarks={project_benchmarks}
            />
        </>
    );
}

class MetricDetail extends Component {
    getSummaryComponent() {
        return Summary;
    }
    getScoreComponent() {
        const { metric, printable } = this.props;
        return getScoreComponent(metric, printable);
    }

    getDetailComponent() {
        const { metric } = this.props;
        return metricDetail[metric] || (() => null);
    }

    render() {
        const { classes, metric, cropYear, mode, printable } = this.props,
            result = getValue(cropYear, "metrics.Calculator.result.cropyears[0]"),
            indicator = {
                cropyear: cropYear,
                survey: 1,
                metric,
                year: getValue(result, "year"),
                crop: getValue(result, "crop"),
                ...getValue(result, metric),
            },
            showSummary = mode !== "detail",
            showDetail = mode !== "summary";

        const Summary = this.getSummaryComponent(),
            Score = this.getScoreComponent(),
            Detail = this.getDetailComponent();

        return (
            <Grid container justifyContent="center" spacing={printable ? 8 : 24} alignItems="center">
                {showSummary && (
                    <Summary
                        metric={metric}
                        indicator={indicator}
                        printable={printable}
                        Score={Score}
                        classes={classes}
                    />
                )}
                {showDetail && <Detail indicator={indicator} printable={printable} />}
            </Grid>
        );
    }
}

export default withStyles(styles)(MetricDetail);
