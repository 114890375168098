import React, { Component } from "react";
import Grid from "../../common/GridWrapper";
import withStyles from "@mui/styles/withStyles";
import Typography from "../../common/TypographyWrapper";
import classNames from "classnames";
import { getColor } from "./StaticData";

const styles = {
    triangle: {
        position: "relative",
        background: "#EEEEEE",
        border: "3px solid " + getColor("active_year"),
        minHeight: "5em",
        minWidth: "10em",
        "&::before, &::after": {
            right: "100%",
            top: "50%",
            border: "solid transparent",
            content: "'  '",
            height: 0,
            width: 0,
            position: "absolute",
            pointerEvents: "none",
        },
        "&::after": {
            borderColor: "rgba(136, 183, 213, 0)",
            borderRightColor: "#EEEEEE",
            borderWidth: 24,
            marginTop: -24,
        },
        "&::before": {
            borderColor: "rgba(194, 225, 245, 0)",
            borderRightColor: getColor("active_year"),
            borderWidth: 28,
            marginTop: -28,
        },
    },
    triangle2: {
        border: "3px solid " + getColor("national") + " !important",
        "&::before": {
            borderRightColor: getColor("national") + " !important",
        },
    },
    triangleTop2: {
        border: "3px solid " + getColor("national") + " !important",
        "&::before": {
            borderBottomColor: getColor("national") + " !important",
        },
    },
    triangle3: {
        border: "3px solid " + getColor("state") + " !important",
        "&::before": {
            borderRightColor: getColor("state") + " !important",
        },
    },
    triangleTop3: {
        border: "3px solid " + getColor("state") + " !important",
        "&::before": {
            borderBottomColor: getColor("state") + " !important",
        },
    },
    marginScore: {
        marginTop: 16,
        marginBottom: 16,
        padding: "12px !important",
    },
    padRight: {
        paddingRight: "2.5em !important",
    },
    callout: {
        position: "relative",
        background: "#EEEEEE",
        textAlign: "center",
        "&::before, &::after": {
            bottom: "100%",
            left: "50%",
            border: "solid transparent",
            content: "'  '",
            height: 0,
            width: 0,
            position: "absolute",
            pointerEvents: "none",
        },
        "&::after": {
            borderColor: "rgba(136, 183, 213, 0)",
            borderBottomColor: "#EEEEEE",
            borderWidth: 30,
            marginLeft: -30,
        },
        "&::before": {
            borderColor: "rgba(194, 225, 245, 0)",
            borderBottomColor: getColor("active_year"),
            borderWidth: 36,
            marginLeft: -36,
        },
    },
};

class Callout extends Component {
    render() {
        const { classes, children, printable, split, variant, title } = this.props,
            fixedSplit = !printable && typeof split === "string",
            [graphic, description] = children,
            gsize =
                fixedSplit || printable
                    ? { xs: true }
                    : {
                          xs: split,
                          sm: split - 1,
                          md: split - 2,
                          lg: split - 3,
                          xl: split - 4,
                      },
            adjust = (width) => (width <= 4 ? 12 : width),
            csize = printable
                ? {
                      xs: 12,
                  }
                : fixedSplit
                  ? { xs: 12 }
                  : {
                        xs: adjust(12 - split),
                        sm: adjust(12 - split + 1),
                        md: adjust(12 - split + 2),
                        lg: adjust(12 - split + 3),
                        xl: adjust(12 - split + 4),
                    },
            gStyles = !printable && fixedSplit ? { width: split, flexGrow: 0, display: "table" } : {},
            gClass = printable ? null : classNames(classes.marginScore, classes.padRight),
            cClass = printable
                ? null
                : classNames(classes.marginScore, !fixedSplit ? classes.triangle : classes.callout, classes[variant]);
        return (
            <>
                {printable === true && (
                    <>
                        {title && (
                            <Grid item className={cClass} {...csize}>
                                <Typography variant={"title"} style={{ fontWeight: "600" }}>
                                    {title}
                                </Typography>
                            </Grid>
                        )}
                        <Grid item className={cClass} {...csize}>
                            {description}
                        </Grid>
                        <Grid item style={gStyles} className={gClass} {...gsize}>
                            {graphic}
                        </Grid>
                    </>
                )}

                {printable === undefined && (
                    <>
                        <Grid item style={gStyles} className={gClass} {...gsize}>
                            {graphic}
                        </Grid>
                        <Grid item className={cClass} {...csize}>
                            {description}
                        </Grid>
                    </>
                )}
            </>
        );
    }
}

export default withStyles(styles)(Callout);
