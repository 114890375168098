import React, { Component } from "react";
import AppContainer from "../../common/AppContainer";
import withStyles from "@mui/styles/withStyles";
import Button from "../../common/ButtonWrapper";
import PrintIcon from "@mui/icons-material/Print";
import { CropYear } from "../fieldactivities/models";
import { connect } from "react-redux";
import { Table, TableRow, TableCell } from "@mui/material";
import ReactToPrint from "react-to-print";
import ReportLayout from "./ReportLayout";

import { Link } from "react-router-dom";

// Grab an instance of the cropYear through the ID in the URL
const getCropYear = CropYear.selectByUrlId((cropYear) => ({
    crop_name: cropYear.crop.name,
    farm_name: cropYear.field.farm.name,
    field_name: cropYear.field._fields.name,
    field_id: cropYear.field._fields.id,
    fieldObject: cropYear.field._fields,
    activities: cropYear.activities.toModelArray().map((activity) => ({
        fertilizers: activity.fertilizers.toRefArray(),
        ...activity.ref,
    })),
    ...cropYear.ref,
    rotation: {
        events: cropYear.rotation.events.toRefArray(),
        ...cropYear.rotation.ref,
    },
}));

// Custom CSS classes
const styles = (theme) => ({
    printHeader: {
        maxWidth: "8.5in",
    },
    printWarning: {
        fontSize: "1.5em",
        color: "red",
    },
});

class Report extends Component {
    render() {
        const { cropYear, classes } = this.props;

        return (
            <AppContainer authenticated>
                {cropYear && (
                    <div>
                        <Table paddingDense className={classes.printHeader}>
                            <TableRow>
                                <TableCell className={classes.printWarning} style={{ width: "2.5in", padding: "4px" }}>
                                    Note: For optimal print layout, use the "Print Report" button (at right) rather than
                                    the system Print command.
                                </TableCell>
                                <TableCell style={{ width: "1in", padding: "4px" }}>
                                    <ReactToPrint
                                        trigger={() => (
                                            <Button variant="raised" color="primary">
                                                Print Report &nbsp;
                                                <PrintIcon />
                                            </Button>
                                        )}
                                        title={"asdfasdfasdf"}
                                        content={() => this.componentRef}
                                    />
                                </TableCell>
                                <TableCell style={{ width: "1.5in", padding: "4px" }}>
                                    <Button
                                        variant="raised"
                                        color="primary"
                                        component={Link}
                                        to={"/cropyear/" + cropYear.id + "/analysis"}
                                    >
                                        Back to Fieldprint Analysis
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </Table>

                        <div ref={(el) => (this.componentRef = el)}>
                            <ReportLayout cropYear={cropYear} />
                        </div>
                    </div>
                )}
            </AppContainer>
        );
    }
}

Report = connect((state, ownProps) => ({
    cropYear: getCropYear(state, ownProps),
}))(Report);

export default withStyles(styles)(Report);
