import React, { Component, useState } from "react";
import Card from "@mui/material/Card";
import Typography from "../../../common/TypographyWrapper";
import { PieChart, Pie, Cell, Legend } from "recharts";
import Callout from "../Callout";
import { withTableStyles, TH, TD, Paragraph } from "../Table";

import { getValue } from "../../../../api/utils";
import {
    getLabel,
    getDescription,
    round,
    chartValue,
    PIE_COLORS,
    ENERGY_USE_SUBMETRICS,
    PIE_CHART_LABEL,
} from "../StaticData";

const styles = {
    pieLegend: {
        top: 60,
        left: 520,
        lineHeight: "22px",
    },
    learnMoreContainer: {
        backgroundColor: "#f0e3db",
        marginTop: "4em",
        marginLeft: "0.5em",
        marginRight: "0.5em",
        padding: "0.5em",
    },
    learnMoreLink: {
        color: "#f15d22",
        textDecoration: "none",
        fontWeight: "bold",
    },
};

export function Submetric(props) {
    const { name, suffix, yieldValue, acreValue, parts, printable, indent } = props;
    if (!printable && parts && parts.length > 0) {
        return <ExpandableSubmetric {...props} />;
    }
    return (
        <>
            <tr>
                <TD first indent={indent} subcomponent={indent} indentCollapse={!printable} printable={printable}>
                    {name} {suffix}
                </TD>
                <TD printable={printable} subcomponent={indent}>
                    {acreValue}
                </TD>
                <TD printable={printable} subcomponent={indent}>
                    {yieldValue}
                </TD>
            </tr>
            {parts && parts.map((part) => <Submetric {...part} suffix={suffix} indent printable />)}
        </>
    );
}

function ExpandableSubmetric({ name, suffix, yieldValue, acreValue, parts }) {
    const [open, setOpen] = useState(false);
    return (
        <>
            <tr>
                <TD first toggle open={open} setOpen={setOpen}>
                    {name} {suffix}
                </TD>
                <TD>{acreValue}</TD>
                <TD>{yieldValue}</TD>
            </tr>
            <tr>
                <TD first collapsible open={open} subcomponent>
                    {parts.map((part) => (
                        <>
                            {part.name} {suffix}
                        </>
                    ))}
                </TD>
                <TD collapsible open={open} subcomponent>
                    {parts.map((part) => (
                        <>{part.acreValue}</>
                    ))}
                </TD>
                <TD collapsible open={open} subcomponent>
                    {parts.map((part) => (
                        <>{part.yieldValue}</>
                    ))}
                </TD>
            </tr>
        </>
    );
}

class EnergyUse extends Component {
    state = {
        activeIndex: null,
    };

    onPieEnter = (data, index) => {
        this.setState({
            activeIndex: index,
        });
    };

    render() {
        const { indicator, classes, printable } = this.props,
            P = printable ? Paragraph : Typography,
            fieldprintUnit = getValue(indicator, "fieldprintResult.unit"),
            energyUnit = getValue(indicator, "totalEnergy.unit"),
            getVal = (path) => round(getValue(indicator, path), indicator.metric),
            getChartVal = (path) => chartValue(getValue(indicator, path), indicator.metric),
            energy = getVal("totalEnergy.value"),
            fieldprint = getVal("fieldprintResult.value"),
            submetrics = Object.entries(ENERGY_USE_SUBMETRICS).map(([component, parts]) => ({
                name: getLabel(component),
                yieldValue: getVal(component + ".fieldprintResult.value"),
                yieldChartValue: getChartVal(component + ".fieldprintResult.value"),
                acreValue: getVal(component + ".energy.value"),
                parts: parts
                    .map((part) => ({
                        name: getLabel(`${component}.${part}`),
                        yieldValue: getVal(`${component}.${part}.fieldprintResult.value`),
                        acreValue: getVal(`${component}.${part}.energy.value`),
                    }))
                    .filter((part) => part.yieldValue),
            }));

        return (
            <>
                <Callout
                    title={printable ? "" : "Breakdown of Energy Use Score Components"}
                    printable={printable}
                    split={printable ? 12 : 9}
                    variant="triangle2"
                >
                    <table className={classes.dataTable}>
                        <thead>
                            <tr>
                                <TH printable={printable} noborder />
                                <TH printable={printable} colspan={2} noborder>
                                    Energy
                                </TH>
                            </tr>
                            <tr>
                                <TH printable={printable}> Component </TH>
                                <TH printable={printable}> ({energyUnit}) </TH>
                                <TH printable={printable}> ({fieldprintUnit}) </TH>
                            </tr>
                        </thead>
                        <tbody>
                            {submetrics.map((component) => (
                                <Submetric suffix="Energy" {...component} printable={printable} />
                            ))}
                        </tbody>
                        <tfoot>
                            <tr>
                                <TD first total heading printable={printable} indentCollapse={!printable}>
                                    Total Energy
                                </TD>
                                <TD total printable={printable}>
                                    {energy}
                                </TD>
                                <TD total printable={printable}>
                                    {fieldprint}
                                </TD>
                            </tr>
                        </tfoot>
                    </table>
                    <P>{getDescription(printable ? "energyUsePartsPrint" : "energyUseParts")}</P>
                </Callout>
                {printable && (
                    <div className={classes.learnMoreContainer}>
                        <Typography variant="body2" style={{ fontSize: 13 }}>
                            Want to learn more? Download{" "}
                            <a
                                className={classes.learnMoreLink}
                                href="https://fieldtomarket.org/media/2018/06/FTM_Harnessing-Sustainability-Insights_WEB.pdf"
                            >
                                Harnessing Sustainability Insights and Unleashing Opportunity (PDF)
                            </a>{" "}
                            from Field to Market. The guide explains the environmental, economic and community-level
                            importance of each sustainability indicator; how it is measured by the Fieldprint Platform;
                            the field characteristics and management practices used to calculate sustainability outcomes
                            encapsulated in a Fieldprint Analysis; and the top ways to improve your results. To learn
                            more about Field to Market, visit{" "}
                            <a href="https://www.fieldtomarket.org" className={classes.learnMoreLink}>
                                www.fieldtomarket.org
                            </a>
                            .
                        </Typography>
                    </div>
                )}
                {!printable && (
                    <Callout printable={printable} split={"700px"} variant="triangleTop3">
                        <Card
                            style={{
                                width: "650px",
                                "text-align": "center",
                            }}
                            elevation={printable ? 0 : 1}
                        >
                            <Typography>Energy Components ({fieldprintUnit})</Typography>
                            <PieChart width={650} height={300}>
                                <Pie
                                    data={submetrics.map(({ name, yieldChartValue: value }) => ({ name, value }))}
                                    cx={250}
                                    cy={140}
                                    innerRadius={60}
                                    outerRadius={80}
                                    activeShape={(props) => PIE_CHART_LABEL(props, fieldprintUnit)}
                                    onMouseEnter={this.onPieEnter}
                                    activeIndex={this.state.activeIndex}
                                >
                                    {submetrics.map((entry, index) => (
                                        <Cell fill={PIE_COLORS[index % PIE_COLORS.length]} />
                                    ))}
                                </Pie>
                                <Legend
                                    layout="vertical"
                                    verticalAlign="middle"
                                    wrapperStyle={styles.pieLegend}
                                    onMouseEnter={this.onPieEnter}
                                    iconSize={7}
                                />
                            </PieChart>
                        </Card>
                        <Typography>{getDescription("energyUseChart")}</Typography>
                    </Callout>
                )}
            </>
        );
    }
}
export default withTableStyles(styles)(EnergyUse);
