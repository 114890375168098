import orm, { Model } from "../common/orm";
import { fk } from "redux-orm";

export class Scenario extends Model {
    static get fields() {
        return {
            field: fk("Field", "scenario_field"),
        };
    }
    static get modelName() {
        return "Scenario";
    }
    static generateCode(payload, state) {
        const session = orm.session(state.orm);

        let maxCode = 0;
        session["Scenario"]
            .all()
            .toRefArray()
            .forEach((row) => {
                const lastCode = +row.code;
                if (lastCode > maxCode) {
                    maxCode = lastCode;
                }
            });
        let nextCode = `${maxCode + 1}`;
        while (nextCode.length < this.codeWidth) {
            nextCode = `0${nextCode}`;
        }

        return nextCode;
    }
    static generateId(payload) {
        return payload.code;
    }
}
orm.register(Scenario);

export class ScenarioPractice extends Model {
    static get fields() {
        return {
            scenario: fk("Scenario", "scenario_practice"),
        };
    }
    static get modelName() {
        return "ScenarioPractice";
    }
}
orm.register(ScenarioPractice);
