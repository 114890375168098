import Map from "react-map-gl/maplibre";
import { useState, useEffect, useRef } from "react";
import "maplibre-gl/dist/maplibre-gl.css";
import Geocoder from "./Geocoder";
import useGeolocation from "./geolocate";
import { ACTION, MODE, FLAG } from "./Tools";
import bbox from "@turf/bbox";
import { List, ListSubheader, ListItem, ListItemIcon, ListItemText, Switch } from "@mui/material";

const returnTrue = () => true,
    returnFalse = () => false;

export default function MapView({
    children,
    initialFitData = null,
    fitData = null,
    layers = [],
    extraLayerProps = null,
    toolbars = [],
    extraFlags = null,
    isToolVisible = returnTrue,
    isToolDisabled = returnFalse,
    isLayerDisabled = returnFalse,
    onAction = null,
    onClearMode = null,
    onToggleFlag = null,
}) {
    const mapRef = useRef(),
        [mode, setMode] = useState(null),
        [_flags, setFlags] = useState({}),
        flags = extraFlags ? { ..._flags, ...extraFlags } : _flags,
        [activeLayers, setActiveLayers] = useState({ imagery: true }),
        [viewState, setViewState] = useState({
            longitude: -96,
            latitude: 39,
            zoom: 3.5,
        }),
        [startLocate, stopLocate] = useGeolocation(setViewState, () => toggleFlag("locate")),
        onSetBounds = (bounds, padding) => {
            const camera = mapRef.current.cameraForBounds(bounds, {
                maxZoom: 16,
                padding: padding || 0,
            });
            setViewState({
                latitude: camera.center.lat,
                longitude: camera.center.lng,
                zoom: camera.zoom,
            });
        },
        zoomTo = (data) => {
            const bounds = bbox(data);
            if (bounds.some((bound) => bound < 90 && bound > -90)) {
                onSetBounds(bounds, 48);
            }
        },
        activateTool = (tool) => {
            if (tool.type === ACTION) {
                triggerAction(tool.id);
            } else if (tool.type === MODE) {
                if (mode === tool.id && onClearMode) {
                    onClearMode(setMode, flags, toggleFlag);
                } else {
                    setMode(tool.id);
                }
            } else if (tool.type === FLAG) {
                toggleFlag(tool.id);
            }
        },
        isToolActive = (tool) => {
            if (tool.type === MODE && mode === tool.id) {
                return true;
            } else if (tool.type === FLAG && flags[tool.id]) {
                return true;
            } else {
                return false;
            }
        },
        triggerAction = (action, noFlag) => {
            switch (action) {
                case "zoomin": {
                    mapRef.current.zoomIn();
                    break;
                }
                case "zoomout": {
                    mapRef.current.zoomOut();
                    break;
                }
                case "zoomfit": {
                    zoomTo(fitData);
                    break;
                }
                default: {
                    if (onAction) {
                        onAction(action, flags, toggleFlag, setMode, noFlag);
                    }
                }
            }
        },
        toggleFlag = (id, noAction) => {
            const nextState = !flags[id];
            setFlags({ ...flags, [id]: nextState });
            if (id === "locate") {
                if (nextState) {
                    startLocate();
                } else {
                    stopLocate();
                }
            }
            if (onToggleFlag) {
                onToggleFlag(id, nextState, mode, setMode, triggerAction, noAction);
            }
        },
        toggleLayer = (id) => {
            const nextState = !activeLayers[id],
                nextActiveLayers = { ...activeLayers, [id]: nextState };
            setActiveLayers(nextActiveLayers);
        };

    useEffect(() => {
        if (initialFitData && mapRef.current) {
            zoomTo(initialFitData);
        }
    }, [mapRef.current, initialFitData]); // eslint-disable-line

    return (
        <div
            style={{
                flex: 1,
                position: "relative",
            }}
        >
            {toolbars.map(({ id, component: Tools, left }) => (
                <Toolbar
                    key={id}
                    id={id}
                    left={left ? 16 : undefined}
                    right={left ? undefined : 16}
                    paddingTop={8}
                    paddingBottom={8}
                    paddingLeft={4}
                    paddingRight={4}
                >
                    <Tools
                        activateTool={activateTool}
                        isActive={isToolActive}
                        isDisabled={(tool) => isToolDisabled(tool, flags, mapRef)}
                        isVisible={(tool) => isToolVisible(tool, flags, mapRef)}
                    />
                </Toolbar>
            ))}
            {flags.search && (
                <Toolbar left={80} width="20em" padding={8} overflowY="hidden">
                    <Geocoder onSetBounds={onSetBounds} />
                </Toolbar>
            )}
            {flags.layers && (
                <Toolbar right={80} width="15em">
                    <LayerList
                        layers={layers}
                        activeLayers={activeLayers}
                        toggleLayer={toggleLayer}
                        isDisabled={(layer) => isLayerDisabled(layer, flags, mapRef)}
                    />
                </Toolbar>
            )}
            {children}
            <Map
                ref={mapRef}
                {...viewState}
                dragRotate={false}
                onMove={(evt) => setViewState(evt.viewState)}
                style={{ width: "100%", height: "100%" }}
                mapStyle={activeLayers.imagery ? "/static/app/style-hybrid.json" : "/static/app/style-blank.json"}
            >
                {layers
                    .filter((layer) => Boolean(layer.component))
                    .map(({ id, component: Layer }) => (
                        <Layer
                            key={id}
                            activeLayers={activeLayers}
                            mode={mode}
                            onSetMode={setMode}
                            flags={flags}
                            onToggleFlag={toggleFlag}
                            zoomTo={zoomTo}
                            {...extraLayerProps}
                        />
                    ))}
            </Map>
        </div>
    );
}

function Toolbar({ id, children, ...style }) {
    return (
        <div
            id={id}
            style={{
                position: "absolute",
                top: 16,
                width: 40,
                zIndex: 1000,
                backgroundColor: "white",
                border: "1px solid #999",
                maxHeight: "calc(100% - 32px)",
                overflowX: "hidden",
                overflowY: "auto",
                ...style,
            }}
        >
            {children}
        </div>
    );
}

function LayerList({ layers, activeLayers, toggleLayer, isDisabled }) {
    return (
        <List dense>
            <ListSubheader>Reference Layers</ListSubheader>
            {layers
                .filter((layer) => Boolean(layer.title))
                .map((layer) => (
                    <ListItem key={layer.id} button disabled={isDisabled(layer)} onClick={() => toggleLayer(layer.id)}>
                        <ListItemIcon>
                            <Switch checked={!!activeLayers[layer.id]} />
                        </ListItemIcon>
                        <ListItemText>{layer.title}</ListItemText>
                    </ListItem>
                ))}
        </List>
    );
}
