import React from "react";
import { connect } from "react-redux";
import Grid from "../common/GridWrapper";
import { ListItem, Badge, ListItemText } from "@mui/material";
import classNames from "classnames";
import Typography from "../common/TypographyWrapper";
import Collapse from "@mui/material/Collapse";
import CropTypeIcon from "../common/icons/CropTypeIcon";
import Switch from "@mui/material/Switch";
import { apiFetch, dbFetch } from "../../api/fetch";
import saveAs from "file-saver";
import { toggleAccordian } from "../common/actions";
import ActionMenu from "../common/ActionMenu";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";
import CancelIcon from "@mui/icons-material/Cancel";
import ExpandMore from "@mui/icons-material/ExpandMore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import AddCircle from "@mui/icons-material/AddCircle";
import withRouter from "../common/withRouter";
import { withStyles } from "@mui/styles";
import { CropYear } from "../field/fieldactivities/models";
import { Field } from "../field/models";
import { getValue } from "../../api/utils";
import { Metric } from "../../api/models";
import { Project, ProjectUser } from "../project/models";
import ProjectEnrollDialog from "./ProjectEnrollDialog";
import CropYearEnrollDialog from "./CropYearEnrollDialog";

import BiodiversityIcon from "../common/icons/fpp_icon_bio_bw.png";
import EnergyIcon from "../common/icons/fpp_icon_energy_bw.png";
import GreenhouseGasIcon from "../common/icons/fpp_icon_ghg_bw.png";
import IrrigationIcon from "../common/icons/fpp_icon_irrigation_bw.png";
import LanduseIcon from "../common/icons/fpp_icon_landuse_bw.png";
import SoilCarbonIcon from "../common/icons/fpp_icon_soc_bw.png";
import SoilConservationIcon from "../common/icons/fpp_icon_soilcon_bw.png";
import WaterQualityIcon from "../common/icons/fpp_icon_wq_bw.png";
import Tooltip from "@mui/material/Tooltip";
import WarningDialog from "../common/WarningDialog";

const allProjectUsers = ProjectUser.selectAll((PU) => ({
    project_attributes: PU.project,
    ...PU.ref,
}));

const ColoredLine = ({ color }) => (
    <hr
        style={{
            color: color,
            backgroundColor: color,
            width: "100%",
            margin: "0px",
        }}
    />
);

const metricItem = { marginRight: "30px", marginBottom: "3px" };
const metricTitle = { fontSize: "10px", fontWeight: "bold" };
const metricVal = { fontSize: "12px" };
const metricValItem = { fontSize: "12px", fontWeight: "bold", color: "red" };

const SmallSwitch = withStyles({
    switchBase: {
        padding: 14,
        "&$checked": {
            transform: "translate(12px)",
        },
        "&$checked + $track": {
            backgroundColor: "#778721",
            opacity: 1,
        },
    },
    thumb: {
        width: 8,
        height: 8,
        backgroundColor: "#fff",
    },
    track: {
        borderRadius: 16 / 2,
        border: `1px solid #cdd2d5`,
        backgroundColor: "#cccccc",
        opacity: 1,
        transition: "background-color 0.2s",
        width: "22px",
        height: "10px",
    },
    checked: {},
})(Switch);

class CropYearComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            downloading: false,
            projectPopupOpen: false,
            cropYearPopupOpen: false,
            checked: false,
            projectEnroll: null,
            cropYearView: 0,
            position: {
                top: 0,
                left: 0,
            },
            deleteDialogOpen: false,
            deleteDialogText: null,
            deleteDialogConfirmAction: null,
        };
    }

    componentDidMount() {
        this.syncCropInfo();
    }

    handleTooltipMove = (event) => {
        this.setState({
            position: {
                top: event.clientY,
                left: event.clientX,
            },
        });
    };

    async download() {
        this.setState({ downloading: true });

        const { authState, cropHistory } = this.props;
        let response;
        if (authState.user && authState.user.guest) {
            response = await apiFetch("/api/v4/calc/CalculatorReport", {
                method: "POST",
                body: JSON.stringify(cropHistory),
                headers: { Accept: "application/pdf" },
            });
        } else {
            response = await dbFetch(`/reports/${cropHistory.id}/pdf`);
        }

        saveAs(await response.blob(), cropHistory.id + ".pdf");

        this.setState({ downloading: false });
    }

    handleRecordStatusChange = () => {
        this.props.ormCropYearUpdate({
            id: this.props.cropHistory.id,
            is_final: !this.props.cropHistory.is_final,
        });
    };

    handleCloneCropYear = () => {
        var { cropHistory } = this.props;

        this.props.ormCropYearCreate({
            ...cropHistory,
        });
    };

    deleteCropYear = (crop, name) => {
        this.setState({
            deleteDialogOpen: true,
            deleteDialogConfirmAction: crop,
            deleteDialogText: "Are you sure you wish to permanently delete " + name + "?",
        });
    };

    handleMetricClick = () => {
        this.props.toggleAccordian("METRIC-" + this.props.cropHistory.id);
    };

    handleOperationClick = () => {
        this.props.toggleAccordian("OPERATION-" + this.props.cropHistory.id);
    };

    handlePopupClickOpen = (event) => {
        event.preventDefault();
        this.setState({ projectPopupOpen: true });
    };

    handleCropYearPopupClickOpen = (projid) => {
        this.setState({ cropYearPopupOpen: true, projectEnroll: projid, cropYearView: 0 });
    };

    handleRemoveCropYearPopupClickOpen = (projid) => {
        this.setState({ cropYearPopupOpen: true, projectEnroll: projid, cropYearView: 3 });
    };

    handlePopupClickClose = () => {
        this.setState({ projectPopupOpen: false });
    };

    handleCropYearPopupClickClose = () => {
        this.setState({ cropYearPopupOpen: false, cropYearView: 0 });
    };

    handleEditCropYearClick = () => {
        var { cropHistory, history } = this.props;

        history.push("/cropyear/" + cropHistory.id);
    };

    handleViewMetrics = () => {
        var { cropHistory, history } = this.props;

        history.push("/cropyear/" + cropHistory.id + "/analysis");
    };

    handleDownloadReportClick = () => {
        this.download();
    };

    handleRunMetrics = () => {
        const { cropHistory, runMetric } = this.props;

        const pending = getValue(cropHistory, "metrics.Calculator.pending");

        if (pending === undefined) {
            runMetric("Calculator", cropHistory.id, true);
        }
    };

    renderNoTillChip(noTill) {
        const contentMap = {
            1: null,
            2: "REDUCE TILLAGE",
            3: "NO TILL",
            default: null,
        };

        return contentMap[noTill] || contentMap["default"];
    }

    calcMetrics() {
        const cyMetrics = getValue(this.props.cropHistory, "metrics.Calculator.result.cropyears");

        if (cyMetrics) {
            const cropYr = parseInt(this.props.cropHistory.year);

            const filteredMetrics = cyMetrics.find((item) => item.year === cropYr);

            if (filteredMetrics) {
                let metrics = {};

                metrics.biodiversity =
                    filteredMetrics.biodiversity !== undefined
                        ? filteredMetrics.biodiversity.fieldprintResult
                        : { unit: "", value: null };
                metrics.energy =
                    filteredMetrics.energyUse !== undefined
                        ? filteredMetrics.energyUse.fieldprintResult
                        : { unit: "", value: null };
                metrics.greenhouse =
                    filteredMetrics.greenhouseGas !== undefined
                        ? filteredMetrics.greenhouseGas.fieldprintResult
                        : { unit: "", value: null };
                metrics.landuse =
                    filteredMetrics.landUse != undefined
                        ? filteredMetrics.landUse.fieldprintResult
                        : { unit: "", value: null };
                metrics.soilcarbon =
                    filteredMetrics.soilCarbon !== undefined
                        ? filteredMetrics.soilCarbon.fieldprintResult
                        : { unit: "", value: null };
                metrics.soilconservation =
                    filteredMetrics.soilConservation !== undefined
                        ? filteredMetrics.soilConservation.fieldprintResult
                        : { unit: "", value: null };
                metrics.waterquality =
                    filteredMetrics.waterQuality !== undefined
                        ? filteredMetrics.waterQuality.fieldprintResult
                        : { unit: "", value: null };
                metrics.irrigationwateruse =
                    filteredMetrics.irrigationWaterUse !== undefined
                        ? filteredMetrics.irrigationWaterUse.fieldprintResult
                        : { unit: "", value: null };

                return metrics;
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    syncCropInfo() {
        var { syncState, cropHistory, ormFieldLoadDetail, ormCropYearLoadDetail } = this.props;

        if (syncState.ready && !getValue(cropHistory.fieldObject, "synced")) {
            ormFieldLoadDetail(cropHistory.fieldObject.id);
        }
        if (syncState.ready && !getValue(cropHistory, "synced")) {
            ormCropYearLoadDetail(cropHistory.id);
        }
    }

    parseProjectCrops() {
        var { projects, cropHistory } = this.props;

        let availProject = [];

        // Don't show crop years that aren't applicable
        projects.map(({ project_attributes }) => {
            // No Future Crop Years
            if (cropHistory.year <= new Date().getFullYear()) {
                // Must have crop related to project
                if (project_attributes.crops_label.includes(cropHistory.crop_name)) {
                    // Not related to a different project
                    if (cropHistory.project_instance === null || cropHistory.project_instance === undefined) {
                        availProject.push({
                            id: project_attributes.id,
                            name: project_attributes.name,
                            existing: false,
                            validCrop: true,
                        });
                    } else if (cropHistory.project_instance === project_attributes.id) {
                        availProject.push({
                            id: project_attributes.id,
                            name: project_attributes.name,
                            existing: true,
                            validCrop: true,
                        });
                    }
                } else {
                    availProject.push({ id: null, name: null, existing: null, validCrop: false });
                }
            }
        });

        // if only one project, return that
        if (availProject.length === 1) {
            return availProject[0];
        } else {
            const hasExistingCrop = availProject.some((item) => item.existing === true);
            const hasValidCrop = availProject.some((item) => item.validCrop === true);
            if (hasExistingCrop) {
                // if multiple projects, test if there is already one assigned to the cy and return that
                return availProject.find((item) => item.existing === true);
            } else if (hasValidCrop) {
                // else return the first valid project for the cy type
                return availProject.find((item) => item.validCrop === true);
            } else {
                // else return the null value object
                return availProject.find((item) => item.validCrop === false);
            }
        }
    }

    render() {
        var { isMetricExpanded, cropHistory, allCropHistory, classes, ormCropYearDelete } = this.props;
        const { deleteDialogOpen, deleteDialogText, deleteDialogConfirmAction } = this.state;

        let percentComplete = 0;

        const error = getValue(cropHistory, "metrics.Calculator.error"),
            pending = getValue(cropHistory, "metrics.Calculator.pending"),
            percentMetricsComplete = getValue(cropHistory, "metrics.Calculator.percentComplete"),
            completeStatus = getValue(cropHistory, "metrics.Calculator.completeStatus");

        const covercrop = getValue(cropHistory, "biodiversity.cover_crop");
        const tillage_class = getValue(this.props.cropHistory, "biodiversity.tillage_class");
        const metrics = this.calcMetrics();

        const noTill = this.renderNoTillChip(tillage_class);

        const projEnrol = this.parseProjectCrops();

        if (cropHistory.extrainfo != null) {
            const m = cropHistory.extrainfo.missing;
            const r = cropHistory.extrainfo.required;
            if (r) {
                var missing =
                    (m[0] || m[0] === 0 ? m[0] : 5) +
                    (m[1] || m[1] === 0 ? m[1] : 10) +
                    (m[2] || m[2] === 0 ? m[2] : 3);
                var required =
                    (r[0] || r[0] === 0 ? r[0] : 5) +
                    (r[1] || r[1] === 0 ? r[1] : 10) +
                    (r[2] || r[2] === 0 ? r[2] : 3);
                percentComplete = (((required - missing) / required) * 100).toFixed(0);
            }
        }

        return (
            <Grid
                key={cropHistory.id}
                container
                maxWidth="lg"
                style={{ marginTop: "25px", border: "solid 1px #bcbcbc", borderRadius: "6px" }}
            >
                <Grid item container>
                    <Grid item sm={1} xs={0}>
                        <CropTypeIcon
                            className={classNames(classes.iconSize, "hideWhenSmall")}
                            style={{ margin: "15px" }}
                        />
                    </Grid>
                    <Grid item sm={11} xs={12}>
                        <ListItem>
                            <Grid container xs={12}>
                                <Grid container sm={9}>
                                    <Grid item xs={12}>
                                        <Typography
                                            style={{ fontSize: "18px", color: "#212121", textDecoration: "underline" }}
                                        >
                                            {cropHistory.year + " " + cropHistory.crop_name}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container style={{ width: "100%" }}>
                                            <Grid item>
                                                <Typography style={{ fontSize: "12px" }}>DATA INPUT</Typography>
                                                {percentComplete === "100" ? (
                                                    <Badge
                                                        color="secondary"
                                                        style={{
                                                            marginRight: "10px",
                                                            backgroundColor: "#778721",
                                                            marginBottom: "3px",
                                                            borderRadius: "2px",
                                                        }}
                                                    >
                                                        <CheckCircleIcon
                                                            style={{ height: "17px", color: "#fff", paddingTop: "2px" }}
                                                        />
                                                        <span
                                                            style={{
                                                                fontSize: "12px",
                                                                color: "#fff",
                                                                paddingRight: "3px",
                                                                paddingTop: "3px",
                                                            }}
                                                        >
                                                            COMPLETE
                                                        </span>
                                                    </Badge>
                                                ) : (
                                                    <Badge
                                                        color="secondary"
                                                        style={{
                                                            marginRight: "10px",
                                                            backgroundColor: "#bb616b",
                                                            marginBottom: "3px",
                                                            borderRadius: "2px",
                                                        }}
                                                    >
                                                        <RemoveCircleRoundedIcon
                                                            style={{ height: "17px", color: "#fff", paddingTop: "2px" }}
                                                        />
                                                        <span
                                                            style={{
                                                                fontSize: "12px",
                                                                color: "#fff",
                                                                paddingRight: "3px",
                                                                paddingTop: "3px",
                                                            }}
                                                        >
                                                            INCOMPLETE
                                                        </span>
                                                    </Badge>
                                                )}
                                            </Grid>
                                            <Grid item style={{ margin: 0, padding: 0 }}>
                                                <Typography style={{ fontSize: "12px" }}>RECORD STATUS</Typography>
                                                <Grid container alignItems="flex-start">
                                                    {cropHistory.is_final ? (
                                                        <Badge
                                                            color="secondary"
                                                            style={{
                                                                marginRight: "10px",
                                                                backgroundColor: "#778721",
                                                                marginBottom: "3px",
                                                                verticalAlign: "top",
                                                                borderRadius: "2px",
                                                            }}
                                                        >
                                                            <CheckCircleIcon
                                                                style={{
                                                                    height: "17px",
                                                                    color: "#fff",
                                                                    paddingTop: "2px",
                                                                }}
                                                            />
                                                            <span
                                                                style={{
                                                                    fontSize: "12px",
                                                                    color: "#fff",
                                                                    paddingRight: "12px",
                                                                    paddingTop: "3px",
                                                                }}
                                                            >
                                                                FINAL
                                                            </span>
                                                        </Badge>
                                                    ) : (
                                                        <Badge
                                                            color="secondary"
                                                            style={{
                                                                marginRight: "10px",
                                                                backgroundColor: "#f4be37",
                                                                marginBottom: "3px",
                                                                verticalAlign: "top",
                                                                borderRadius: "2px",
                                                            }}
                                                        >
                                                            <RemoveCircleRoundedIcon
                                                                style={{
                                                                    height: "17px",
                                                                    color: "#564313",
                                                                    paddingTop: "2px",
                                                                }}
                                                            />
                                                            <span
                                                                style={{
                                                                    fontSize: "12px",
                                                                    color: "#564313",
                                                                    paddingRight: "3px",
                                                                    paddingTop: "3px",
                                                                }}
                                                            >
                                                                PROVISIONAL
                                                            </span>
                                                        </Badge>
                                                    )}
                                                    <Grid item style={{ marginTop: "-7px", marginLeft: "-17px" }}>
                                                        <SmallSwitch
                                                            checked={cropHistory.is_final}
                                                            onChange={this.handleRecordStatusChange}
                                                            value={cropHistory.is_final}
                                                            color="secondary"
                                                            className={classes.margin}
                                                            classes={{
                                                                root: classes.icon,
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Typography style={{ fontSize: "12px" }}>CALCULATED</Typography>
                                                {cropHistory.metrics_ran ? (
                                                    <Badge
                                                        color="secondary"
                                                        style={{
                                                            marginRight: "10px",
                                                            backgroundColor: "#778721",
                                                            marginBottom: "3px",
                                                            verticalAlign: "top",
                                                            borderRadius: "2px",
                                                        }}
                                                    >
                                                        <CheckCircleIcon
                                                            style={{ height: "17px", color: "#fff", paddingTop: "2px" }}
                                                        />
                                                        <span
                                                            style={{
                                                                fontSize: "12px",
                                                                color: "#fff",
                                                                paddingRight: "12px",
                                                                paddingTop: "3px",
                                                            }}
                                                        >
                                                            YES
                                                        </span>
                                                    </Badge>
                                                ) : (
                                                    <Badge
                                                        color="secondary"
                                                        style={{
                                                            marginRight: "10px",
                                                            backgroundColor: "#f4be37",
                                                            marginBottom: "3px",
                                                            verticalAlign: "top",
                                                            borderRadius: "2px",
                                                        }}
                                                    >
                                                        <RemoveCircleRoundedIcon
                                                            style={{
                                                                height: "17px",
                                                                color: "#564313",
                                                                paddingTop: "2px",
                                                            }}
                                                        />
                                                        <span
                                                            style={{
                                                                fontSize: "12px",
                                                                color: "#564313",
                                                                paddingRight: "3px",
                                                                paddingTop: "3px",
                                                            }}
                                                        >
                                                            NO
                                                        </span>
                                                    </Badge>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item sm={3} alignItems="flex-end">
                                    <ActionMenu
                                        title="Actions"
                                        variant="contained"
                                        style={{
                                            backgroundColor: "#6d8d22",
                                            color: "white",
                                            height: "30px",
                                            minWidth: "110px",
                                            float: "right",
                                        }}
                                        actions={[
                                            { name: "Edit Crop Year", onClick: this.handleEditCropYearClick },
                                            {
                                                name:
                                                    error != undefined
                                                        ? "Error Calculating Report"
                                                        : "Calculate Report",
                                                onClick: this.handleRunMetrics,
                                                disabled:
                                                    percentComplete === "100" && cropHistory.metrics_ran === false
                                                        ? false
                                                        : true,
                                                isLoading: pending,
                                                loadingMessage:
                                                    (percentMetricsComplete !== "null" ? percentMetricsComplete : "0") +
                                                    "% Complete: " +
                                                    (completeStatus !== "null" ? completeStatus : "Loading..."),
                                            },
                                            {
                                                name: "View Metrics",
                                                onClick: this.handleViewMetrics,
                                                disabled:
                                                    percentComplete === "100" && cropHistory.metrics_ran === true
                                                        ? false
                                                        : true,
                                            },
                                            {
                                                name: "Download Report",
                                                onClick: this.handleDownloadReportClick,
                                                disabled:
                                                    cropHistory.metrics_ran === true && !this.state.downloading
                                                        ? false
                                                        : true,
                                                isLoading: this.state.downloading,
                                            },
                                            // { name: "Clone Crop Year", onClick: () => this.handleCloneCropYear() },
                                            // { name: "Delete Crop Year", onClick: () => this.deleteCropYear(cropHistory.id, cropHistory.year + " " + cropHistory.crop_name) }
                                        ]}
                                    />
                                </Grid>
                                <WarningDialog
                                    confirmAction={() => {
                                        ormCropYearDelete(deleteDialogConfirmAction);
                                        this.setState({ deleteDialogOpen: false });
                                    }}
                                    cancelAction={() => this.setState({ deleteDialogOpen: false })}
                                    open={deleteDialogOpen}
                                    title="Delete Crop Year"
                                    text={deleteDialogText}
                                />
                            </Grid>
                        </ListItem>
                        <ListItem style={{ marginTop: "-12px" }}>
                            <Grid container alignItems="space-between">
                                <Grid sm={6}>
                                    <Typography style={{ fontSize: "14px" }}>
                                        <Tooltip
                                            title="If you have signed up for a Field Print Project, you can enroll the crop year."
                                            arrow
                                            placement="bottom"
                                        >
                                            CROP YEAR ENROLLMENT
                                        </Tooltip>
                                    </Typography>

                                    <Grid container>
                                        {projEnrol !== undefined ? (
                                            !projEnrol.existing ? (
                                                projEnrol.validCrop ? (
                                                    <Grid item>
                                                        <Badge
                                                            color="secondary"
                                                            onClick={() => this.handleCropYearPopupClickOpen(projEnrol)}
                                                            style={{
                                                                marginRight: "10px",
                                                                backgroundColor: "#778721",
                                                                marginBottom: "6px",
                                                                verticalAlign: "top",
                                                                padding: "3px",
                                                                cursor: "pointer",
                                                                borderRadius: "2px",
                                                            }}
                                                        >
                                                            <AddCircle
                                                                style={{
                                                                    height: "17px",
                                                                    color: "#fff",
                                                                    paddingTop: "2px",
                                                                }}
                                                            />
                                                            <span
                                                                style={{
                                                                    fontSize: "12px",
                                                                    color: "#fff",
                                                                    paddingRight: "12px",
                                                                    paddingTop: "3px",
                                                                }}
                                                            >
                                                                ENROLL
                                                            </span>
                                                        </Badge>
                                                    </Grid>
                                                ) : (
                                                    <Grid item>
                                                        <Badge
                                                            color="secondary"
                                                            style={{
                                                                marginRight: "10px",
                                                                backgroundColor: "#f4be37",
                                                                marginBottom: "3px",
                                                                verticalAlign: "top",
                                                                borderRadius: "2px",
                                                            }}
                                                        >
                                                            <RemoveCircleRoundedIcon
                                                                style={{
                                                                    height: "17px",
                                                                    color: "#564313",
                                                                    paddingTop: "2px",
                                                                }}
                                                            />
                                                            <span
                                                                style={{
                                                                    fontSize: "12px",
                                                                    color: "#564313",
                                                                    paddingRight: "3px",
                                                                    paddingTop: "3px",
                                                                }}
                                                            >
                                                                UNAVAILABLE
                                                            </span>
                                                        </Badge>
                                                    </Grid>
                                                )
                                            ) : (
                                                <Grid item>
                                                    <Badge
                                                        color="secondary"
                                                        onClick={() =>
                                                            this.handleRemoveCropYearPopupClickOpen(projEnrol)
                                                        }
                                                        style={{
                                                            marginRight: "10px",
                                                            backgroundColor: "#f5f5f5",
                                                            border: "1px solid #90a857",
                                                            marginBottom: "6px",
                                                            verticalAlign: "top",
                                                            padding: "3px",
                                                            cursor: "pointer",
                                                            borderRadius: "2px",
                                                        }}
                                                    >
                                                        <CancelIcon
                                                            style={{
                                                                height: "17px",
                                                                color: "#8aa44e",
                                                                paddingTop: "2px",
                                                            }}
                                                        />
                                                        <span
                                                            style={{
                                                                fontSize: "12px",
                                                                color: "#90a857",
                                                                paddingRight: "5px",
                                                                paddingTop: "3px",
                                                            }}
                                                        >
                                                            ENROLLED
                                                        </span>
                                                    </Badge>
                                                </Grid>
                                            )
                                        ) : (
                                            <Grid item>
                                                <Badge
                                                    color="secondary"
                                                    onClick={this.handlePopupClickOpen}
                                                    style={{
                                                        marginRight: "10px",
                                                        backgroundColor: "#f5f5f5",
                                                        marginBottom: "3px",
                                                        verticalAlign: "top",
                                                        border: "1px solid #dddddd",
                                                        cursor: "pointer",
                                                        borderRadius: "2px",
                                                    }}
                                                >
                                                    <AddCircle
                                                        style={{ height: "17px", color: "#aeaeae", paddingTop: "2px" }}
                                                    />
                                                    <span
                                                        style={{
                                                            fontSize: "12px",
                                                            color: "#aeaeae",
                                                            paddingRight: "3px",
                                                            paddingTop: "3px",
                                                        }}
                                                    >
                                                        ADD PROJECT
                                                    </span>
                                                </Badge>
                                            </Grid>
                                        )}
                                    </Grid>
                                    <ProjectEnrollDialog
                                        open={this.state.projectPopupOpen}
                                        project_name={"Fieldprint Project"}
                                        handlePopupClickClose={this.handlePopupClickClose}
                                    />
                                    <CropYearEnrollDialog
                                        open={this.state.cropYearPopupOpen}
                                        cropHistory={cropHistory}
                                        allCropHistory={allCropHistory}
                                        projects={this.props.projects}
                                        initView={this.state.cropYearView}
                                        handlePopupClickClose={this.handleCropYearPopupClickClose}
                                    />
                                </Grid>
                                <Grid sm={5}>
                                    <Typography style={{ fontSize: "14px" }}>KEY CONSERVATION PRACTICES</Typography>
                                    <Grid container style={{ width: "100%" }}>
                                        {!covercrop && !noTill && (
                                            <Grid item>
                                                <Badge
                                                    color="secondary"
                                                    style={{ paddingRight: "5px", borderRadius: "2px" }}
                                                >
                                                    <Typography
                                                        style={{
                                                            fontSize: "12px",
                                                            color: "#4e4e4e",
                                                            backgroundColor: "#e8ebed",
                                                            padding: "3px",
                                                            marginBottom: "3px",
                                                        }}
                                                    >
                                                        NONE
                                                    </Typography>
                                                </Badge>
                                            </Grid>
                                        )}
                                        {covercrop && (
                                            <Grid item>
                                                <Badge
                                                    color="secondary"
                                                    style={{ paddingRight: "5px", borderRadius: "2px" }}
                                                >
                                                    <Typography
                                                        style={{
                                                            fontSize: "12px",
                                                            color: "#4e4e4e",
                                                            backgroundColor: "#e8ebed",
                                                            padding: "3px",
                                                            marginBottom: "3px",
                                                        }}
                                                    >
                                                        COVER CROP
                                                    </Typography>
                                                </Badge>
                                            </Grid>
                                        )}
                                        {noTill && (
                                            <Grid item>
                                                <Badge
                                                    color="secondary"
                                                    style={{ paddingRight: "5px", borderRadius: "2px" }}
                                                >
                                                    <Typography
                                                        style={{
                                                            fontSize: "12px",
                                                            color: "#4e4e4e",
                                                            backgroundColor: "#e8ebed",
                                                            padding: "3px",
                                                            marginBottom: "3px",
                                                        }}
                                                    >
                                                        {noTill}
                                                    </Typography>
                                                </Badge>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </ListItem>
                    </Grid>
                </Grid>
                <Grid item container>
                    <ColoredLine key={"line1-" + cropHistory.id} color="#666666" />
                    <Grid container>
                        <ListItem
                            button
                            onClick={this.handleMetricClick}
                            style={{ paddingLeft: "1%", paddingBottom: "0px" }}
                            className={classNames(classes.listItem, classes.listItemField, classes.farmPannel)}
                        >
                            <ListItemText
                                classes={{
                                    primary: classes.farmPannelText,
                                }}
                            >
                                {isMetricExpanded ? <ExpandMore /> : <NavigateNextIcon />}{" "}
                                <span> {metrics !== null ? "METRIC RESULTS" : "RESULTS NOT CALCULATED"} </span>
                            </ListItemText>
                        </ListItem>
                    </Grid>
                    <Collapse
                        in={isMetricExpanded}
                        timeout="auto"
                        unmountOnExit
                        className={classNames(classes.overflow)}
                        style={{ width: "100%" }}
                    >
                        {metrics && (
                            <Grid container alignItems="space-between" style={{ width: "100%", marginLeft: "35px" }}>
                                <Grid container item xs={6} sm={2} style={metricItem}>
                                    <Grid xs={3}>
                                        <img src={BiodiversityIcon} alt="biodiversity icon" style={{ width: "25px" }} />
                                    </Grid>
                                    <Grid xs={9}>
                                        <Typography style={metricTitle}>BIODIVERSITY</Typography>
                                        <Typography style={metricVal}>
                                            {metrics.biodiversity.value !== null ? (
                                                <span style={metricValItem}>
                                                    {new Intl.NumberFormat("en-US", {
                                                        style: "percent",
                                                        minimumIntegerDigits: 1,
                                                    }).format(metrics.biodiversity.value)}
                                                </span>
                                            ) : (
                                                "NA"
                                            )}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container item xs={6} sm={2} style={metricItem}>
                                    <Grid xs={3}>
                                        <img src={EnergyIcon} alt="energy icon" style={{ width: "25px" }} />
                                    </Grid>
                                    <Grid xs={9}>
                                        <Typography style={metricTitle}>ENERGY</Typography>
                                        <Typography style={metricVal}>
                                            {metrics.energy.value !== null ? (
                                                <>
                                                    <span style={metricValItem}>
                                                        {new Intl.NumberFormat().format(
                                                            Math.round(metrics.energy.value),
                                                        )}
                                                    </span>{" "}
                                                    {metrics.energy.unit}
                                                </>
                                            ) : (
                                                "NA"
                                            )}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container item xs={6} sm={2} style={metricItem}>
                                    <Grid xs={3}>
                                        <img
                                            src={GreenhouseGasIcon}
                                            alt="greenhouse gas icon"
                                            style={{ width: "25px" }}
                                        />
                                    </Grid>
                                    <Grid xs={9}>
                                        <Typography style={metricTitle}>GREENHOUSE GAS</Typography>
                                        <Typography style={metricVal}>
                                            {metrics.greenhouse.value !== null ? (
                                                <>
                                                    <span style={metricValItem}>
                                                        {new Intl.NumberFormat().format(
                                                            Math.round(metrics.greenhouse.value),
                                                        )}
                                                    </span>{" "}
                                                    {metrics.greenhouse.unit}
                                                </>
                                            ) : (
                                                "NA"
                                            )}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container item xs={6} sm={2} style={metricItem}>
                                    <Grid xs={3}>
                                        <img src={IrrigationIcon} alt="greenhouse gas icon" style={{ width: "25px" }} />
                                    </Grid>
                                    <Grid xs={9}>
                                        <Typography style={metricTitle}>IRRIGATED WATER USE</Typography>
                                        <Typography style={metricVal}>
                                            {metrics.irrigationwateruse.value !== null ? (
                                                <>
                                                    <span style={metricValItem}>
                                                        {new Intl.NumberFormat().format(
                                                            metrics.irrigationwateruse.value,
                                                        )}
                                                    </span>{" "}
                                                    {metrics.irrigationwateruse.unit}
                                                </>
                                            ) : (
                                                "NA"
                                            )}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container item xs={6} sm={2} style={metricItem}>
                                    <Grid xs={3}>
                                        <img src={LanduseIcon} alt="landuse icon" style={{ width: "25px" }} />
                                    </Grid>
                                    <Grid xs={9}>
                                        <Typography style={metricTitle}>LAND USE</Typography>
                                        <Typography style={metricVal}>
                                            {metrics.landuse.value !== null ? (
                                                <>
                                                    <span style={metricValItem}>
                                                        {new Intl.NumberFormat().format(metrics.landuse.value)}
                                                    </span>{" "}
                                                    {metrics.landuse.unit}
                                                </>
                                            ) : (
                                                "NA"
                                            )}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container item xs={6} sm={2} style={metricItem}>
                                    <Grid xs={3}>
                                        <img src={SoilCarbonIcon} alt="soil carbon icon" style={{ width: "25px" }} />
                                    </Grid>
                                    <Grid xs={9}>
                                        <Typography style={metricTitle}>SOIL CARBON</Typography>
                                        <Typography style={metricVal}>
                                            {metrics.soilcarbon.value !== null ? (
                                                <>
                                                    <span style={metricValItem}>
                                                        {new Intl.NumberFormat().format(metrics.soilcarbon.value)}
                                                    </span>
                                                </>
                                            ) : (
                                                "NA"
                                            )}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container item xs={6} sm={2} style={metricItem}>
                                    <Grid xs={3}>
                                        <img
                                            src={SoilConservationIcon}
                                            alt="soil conservation icon"
                                            style={{ width: "25px" }}
                                        />
                                    </Grid>
                                    <Grid xs={9}>
                                        <Typography style={metricTitle}>SOIL CONSERVATION</Typography>
                                        <Typography style={metricVal}>
                                            {metrics.soilconservation.value !== null ? (
                                                <>
                                                    <span style={metricValItem}>
                                                        {new Intl.NumberFormat().format(metrics.soilconservation.value)}
                                                    </span>{" "}
                                                    {metrics.soilconservation.unit}
                                                </>
                                            ) : (
                                                "NA"
                                            )}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container item xs={6} sm={2} style={metricItem}>
                                    <Grid xs={3}>
                                        <img
                                            src={WaterQualityIcon}
                                            alt="water quality icon"
                                            style={{ width: "25px" }}
                                        />
                                    </Grid>
                                    <Grid xs={9}>
                                        <Typography style={metricTitle}>WATER QUALITY</Typography>
                                        <Typography style={metricVal}>
                                            {metrics.waterquality.value !== null ? (
                                                <>
                                                    <span style={metricValItem}>{metrics.waterquality.value}/4</span>{" "}
                                                    pathways
                                                </>
                                            ) : (
                                                "NA"
                                            )}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </Collapse>
                    <ColoredLine key={"line2-" + cropHistory.id} color="#666666" />
                    <Grid
                        container
                        direction="column"
                        alignItems="flex-end"
                        style={{ fontSize: "8px", padding: "5px" }}
                    >
                        <span>
                            Last Updated:{" "}
                            {Intl.DateTimeFormat("en-US", { month: "long", day: "numeric", year: "numeric" }).format(
                                new Date(cropHistory.modify_date),
                            )}
                        </span>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    isMetricExpanded:
        state.nav.dashboardAccordian != null
            ? state.nav.dashboardAccordian["METRIC-" + ownProps.cropHistory.id]
            : false || false,
    isOperationExpanded:
        state.nav.dashboardAccordian != null
            ? state.nav.dashboardAccordian["OPERATION-" + ownProps.cropHistory.id]
            : false || false,
    projects: allProjectUsers(state),
});
const mapDispatchToProps = {
    toggleAccordian,
    ...CropYear.actions,
    ...Metric.actions,
    ...Field.actions,
    ...Project.actions,
    ...ProjectUser.actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CropYearComponent));
