import React, { useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import Typography from "../common/TypographyWrapper";
import VimeoLink from "../common/VimeoLink";
import HelpIcon from "@mui/icons-material/Help";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import withStyles from "@mui/styles/withStyles";

const styles = {
    linkColor: {
        color: "#ff7d32",
    },
    helpIcon: {
        marginRight: 8,
    },
};

function Tutorials({ classes }) {
    const [open, setOpen] = useState();
    return (
        <Accordion expanded={open} onChange={() => setOpen(!open)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <HelpIcon color="primary" className={classes.helpIcon} />
                <Typography>Crop Rotation Library Demonstration Videos</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <div>
                    <Typography>
                        <VimeoLink vid="533200874" className={classes.linkColor}>
                            Part 1 - Overview and Search
                        </VimeoLink>
                    </Typography>
                    <Typography>
                        <VimeoLink vid="533204430" className={classes.linkColor}>
                            Part 2 - Custom Templates
                        </VimeoLink>
                    </Typography>
                    <Typography>
                        <VimeoLink vid="533225147" className={classes.linkColor}>
                            Part 3 - Cover Crops
                        </VimeoLink>
                    </Typography>
                    <Typography>
                        <VimeoLink vid="533226697" className={classes.linkColor}>
                            Part 4 - Copy, Import and Projects
                        </VimeoLink>
                    </Typography>
                    <Typography>
                        <VimeoLink vid="533229049" className={classes.linkColor}>
                            Part 5 - Validation Errors
                        </VimeoLink>
                    </Typography>
                </div>
            </AccordionDetails>
        </Accordion>
    );
}

export default withStyles(styles)(Tutorials);
