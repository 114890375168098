import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { reloadAll } from "../common/orm";
import { navSelectPage } from "../common/actions";

import Dialog from "@mui/material/Dialog";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "../common/TypographyWrapper";
import Modal from "@mui/material/Modal";
import CircularProgress from "@mui/material/CircularProgress";

import makeStyles from "@mui/styles/makeStyles";
import ImportData from "@mui/icons-material/DriveFolderUpload";
import Close from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

const iframeUrl = process.env.NODE_ENV === "production" ? "/api/db/" : "http://localhost:5174/api/db/";

const useStyles = makeStyles({
    paper: {
        height: 786,
    },
    flex: {
        flex: 1,
    },
    toolbar: {
        backgroundColor: "#00a2e5",
        "& h1": {
            color: "#fff",
        },
    },
    iframe: {
        flex: 1,
        border: 0,
    },
    listItem: {
        backgroundColor: "#eaeaea",
        borderBottom: "1px solid #666666",
    },
    alertText: {
        color: "#666666",
    },
});

export default function DataWizardDialog({ path, title, open, handleClose }) {
    const classes = useStyles(),
        dispatch = useDispatch();

    const closeDialog = (param) => {
        handleClose(param);
        dispatch(reloadAll());
    };

    window.closeDialog = closeDialog;

    const url = iframeUrl + path;

    if (!path || !title) {
        return null;
    }

    return (
        <Dialog open={open} onClose={() => closeDialog()} maxWidth="md" fullWidth classes={{ paper: classes.paper }}>
            <Toolbar className={classes.toolbar}>
                <Typography variant="headline" className={classes.flex}>
                    {title}
                </Typography>
                <Tooltip title="Close">
                    <IconButton onClick={() => closeDialog()} size="large">
                        <Close style={{ color: "#eee" }} />
                    </IconButton>
                </Tooltip>
            </Toolbar>
            <iframe className={classes.iframe} title={title} src={url} />
        </Dialog>
    );
}

function ReloadingSpinner({ open }) {
    return (
        <Modal open={open}>
            <div
                style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    color: "white",
                    textAlign: "center",
                }}
            >
                <CircularProgress color="inherit" size={100} thickness={1.5} />
                <Typography variant="h3" color="inherit">
                    Refreshing Field Library...
                </Typography>
            </div>
        </Modal>
    );
}

export function ImportFieldsAction({ onClick }) {
    const classes = useStyles(),
        dispatch = useDispatch(),
        ready = useSelector((state) => state.sync && state.sync.ready),
        navigate = useNavigate(),
        [path, setPath] = useState(null),
        [title, setTitle] = useState(null),
        [reloading, setReloading] = useState(false),
        importFields = () => {
            setPath("filesources/new?type=field");
            setTitle("Import Fields");
            onClick();
        },
        importCropYears = () => {
            setPath("filesources/new?type=cropyear");
            setTitle("Import Crop Years");
            onClick();
        },
        returnImportFieldsDialog = (nextField) => {
            setPath(null);
            setTitle(null);
            setReloading(true);
            if (nextField) {
                dispatch(navSelectPage(`field_${nextField}`));
                navigate(`/field/${nextField}`);
            }
        };
    useEffect(() => {
        if (ready && reloading) {
            setReloading(false);
        }
    }, [ready, reloading]);
    return (
        <>
            <DataWizardDialog path={path} title={title} open={Boolean(path)} handleClose={returnImportFieldsDialog} />

            <ReloadingSpinner open={reloading} />

            <ListItem onClick={importFields} button className={classes.listItem}>
                <ListItemIcon className={classes.alertText}>
                    <ImportData />
                </ListItemIcon>
                <ListItemText
                    primary="Import Fields"
                    classes={{
                        primary: classes.alertText,
                    }}
                />
            </ListItem>

            <ListItem onClick={importCropYears} button className={classes.listItem}>
                <ListItemIcon className={classes.alertText}>
                    <ImportData />
                </ListItemIcon>
                <ListItemText
                    primary="Import Crop Years"
                    classes={{
                        primary: classes.alertText,
                    }}
                />
            </ListItem>
        </>
    );
}
