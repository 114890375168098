import {
    NAV_TOGGLE_NAVIGATION,
    NAV_TOGGLE_FIELD_LIBRARY,
    NAV_TOGGLE_PROJECT,
    NAV_SELECT_PAGE,
    NAV_TOGGLE_DASH,
    DASHBOARD_SELECTOR,
    FARM_MESSAGE,
    SW_CHECKING,
    SW_REGISTERED,
    SW_INSTALLED,
    SW_UPDATE,
    SW_ERROR,
    SW_DISABLED,
    STORE_READY,
    TOGGLE_ACCORDIAN,
} from "./actions";

function navReducer(state = {}, action) {
    switch (action.type) {
        case NAV_TOGGLE_NAVIGATION:
            // If closing the navigation panel then also collapse the main menus
            // Don't worry about sub-menus - it is better to retain their status
            if (state.navigationOpen) {
                return {
                    ...state,
                    navigationOpen: !state.navigationOpen,
                    libraryOpen: false,
                    projectOpen: false,
                    libraryOpenPrevious: state.libraryOpen,
                    projectOpenPrevious: state.projectOpen,
                };
            } else {
                // If they have a previous state re-open to that
                if ("libraryOpenPrevious" in state) {
                    return {
                        ...state,
                        navigationOpen: !state.navigationOpen,
                        libraryOpen: state.libraryOpenPrevious,
                        projectOpen: state.projectOpenPrevious,
                    };
                } else {
                    return {
                        ...state,
                        libraryOpen: true,
                        navigationOpen: !state.navigationOpen,
                    };
                }
            }

        case NAV_TOGGLE_FIELD_LIBRARY:
            // If navigation is collapsed then reopen
            if (!state.navigationOpen) {
                return {
                    ...state,
                    navigationOpen: !state.navigationOpen,
                    libraryOpen: true,
                };
            } else {
                return {
                    ...state,
                    libraryOpen: !state.libraryOpen,
                };
            }

        case NAV_TOGGLE_DASH:
            return {
                ...state,
                dashOpen: !state.dashOpen,
            };

        case NAV_TOGGLE_PROJECT:
            // If navigation is collapsed then reopen
            if (!state.navigationOpen) {
                return {
                    ...state,
                    navigationOpen: !state.navigationOpen,
                    projectOpen: true,
                };
            } else {
                return {
                    ...state,
                    projectOpen: !state.projectOpen,
                };
            }

        case FARM_MESSAGE:
            return {
                ...state,
                hideFarmMessage: !state.hideFarmMessage,
            };

        case DASHBOARD_SELECTOR:
            return {
                ...state,
                selectedButton: action.button,
            };

        case NAV_SELECT_PAGE:
            return {
                ...state,
                selectedPage: action.page,
            };

        case SW_CHECKING:
            return {
                ...state,
                sw: { checking: true },
            };

        case SW_REGISTERED:
            return {
                ...state,
                sw: { registered: true },
            };

        case SW_INSTALLED:
            return {
                ...state,
                sw: { installed: true },
            };

        case SW_UPDATE:
            return {
                ...state,
                sw: { update: true },
            };

        case SW_ERROR:
            return {
                ...state,
                sw: { error: true },
            };

        case SW_DISABLED:
            return {
                ...state,
                sw: null,
            };

        case TOGGLE_ACCORDIAN:
            return {
                ...state,
                dashboardAccordian: {
                    ...state.dashboardAccordian,
                    [action.payload]:
                        state.dashboardAccordian != null ? !state.dashboardAccordian[action.payload] : false,
                },
            };

        case STORE_READY:
            return {
                ...state,
                storeReady: true,
            };

        default:
            return state;
    }
}

export default navReducer;
