import React from "react";

const CardChip = ({ number, label }) => {
    const cardStyle = {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100px",
        width: "100px",
        border: "1px solid #ccc",
        padding: "10px",
        boxSizing: "border-box",
    };

    const numberStyle = {
        fontSize: "24px",
        fontWeight: "bold",
        margin: "0",
    };

    const labelStyle = {
        fontSize: "10px",
        margin: "10px 0 0 0",
        textAlign: "center",
        width: "100%",
        color: "green",
        fontWeight: "bold",
    };

    const numberFormatter = new Intl.NumberFormat("en-US");
    const formattedNumber = numberFormatter.format(number);

    return (
        <div style={cardStyle}>
            <p style={numberStyle}>{formattedNumber}</p>
            <p style={labelStyle}>{label}</p>
        </div>
    );
};

export default CardChip;
