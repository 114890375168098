import React, { Component } from "react";
import withStyles from "@mui/styles/withStyles";
import Grid from "../../../common/GridWrapper";
import CompatTypography from "../../../common/TypographyWrapper";
import { Header } from "../Table";
import { getColor } from "../StaticData";

const styles = {
    text2: {
        color: getColor("active_year"),
        fontSize: 22,
    },
};

class Score extends Component {
    render() {
        const { value, unit, printable, classes } = this.props;
        if (printable) {
            return (
                <>
                    <Header
                        style={{
                            textAlign: "right",
                            paddingRight: 8,
                            flex: 1,
                            color: getColor("active_year"),
                            fontWeight: 400,
                        }}
                    >
                        {value}
                    </Header>
                    <Header style={{ fontWeight: 400 }}>{unit}</Header>
                </>
            );
        } else {
            const Typography = CompatTypography;
            return (
                <>
                    <Grid xs={12}>
                        <Typography variant="headline" className={classes.text2}>
                            {value}
                        </Typography>
                    </Grid>
                    <Grid xs={12}>
                        <Typography variant="body1">{unit}</Typography>
                    </Grid>
                </>
            );
        }
    }
}

export default withStyles(styles)(Score);
