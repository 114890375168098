import React, { Component } from "react";

import withStyles from "@mui/styles/withStyles";
import HelpIcon from "@mui/icons-material/Help";

import Typography from "../common/TypographyWrapper";
import Tooltip from "@mui/material/Tooltip";

import HelpDialog from "./HelpDialog";

const styles = (theme) => ({
    helpGrid: {
        width: "20px",
        opacity: ".4",
        cursor: "pointer",
        "&:hover": {
            color: theme.palette.secondary.dark,
        },
    },
    helpInline: {
        width: "20px",
        marginLeft: theme.spacing(1),
        marginBottom: "-7px",
        opacity: ".4",
        cursor: "pointer",
        "&:hover": {
            color: theme.palette.secondary.dark,
        },
    },
    absolute: {
        height: theme.spacing(2),
    },
    inline: {
        display: "inline",
    },
});

class HelpLabel extends Component {
    state = {
        helpOpen: false,
    };

    iconClick = (e) => {
        this.setState({ helpOpen: true });
        e.preventDefault();
    };

    render() {
        const { helpText, classes, inputLabel, question, style } = this.props;

        const { helpOpen } = this.state;

        return (
            <Typography style={style} className={!inputLabel ? classes.absolute : classes.inline}>
                {inputLabel}
                <Tooltip title="Help">
                    <HelpIcon className={inputLabel ? classes.helpInline : classes.helpGrid} onClick={this.iconClick} />
                </Tooltip>
                <HelpDialog
                    question={inputLabel && inputLabel !== true ? inputLabel : question}
                    text={helpText}
                    open={helpOpen}
                    onClose={() => this.setState({ helpOpen: false })}
                />
            </Typography>
        );
    }
}

export default withStyles(styles)(HelpLabel);
