import { reloadAll } from "../common/orm";
import { dbFetch } from "../../api/fetch";
import { RESET_STATE } from "@redux-offline/redux-offline/lib/constants";

export const AUTH_AUTOLOGIN_PENDING = "AUTH_AUTOLOGIN_PENDING";
export const AUTH_AUTOLOGIN_SUCCESS = "AUTH_AUTOLOGIN_SUCCESS";
export const AUTH_AUTOLOGIN_ERROR = "AUTH_AUTOLOGIN_ERROR";
export const AUTH_LOGIN_GUEST = "AUTH_LOGIN_GUEST";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_START_IMPERSONATE = "AUTH_START_IMPERSONATE";
export const AUTH_STOP_IMPERSONATE = "AUTH_STOP_IMPERSONATE";
export const AUTH_REGISTER_PENDING = "AUTH_REGISTER_PENDING";
export const AUTH_REGISTER_SUCCESS = "AUTH_REGISTER_SUCCESS";
export const AUTH_REGISTER_ERROR = "AUTH_REGISTER_ERROR";
export const AUTH_REVALIDATE_PENDING = "AUTH_REVALIDATE_PENDING";
export const AUTH_REVALIDATE_SUCCESS = "AUTH_REVALIDATE_SUCCESS";
export const AUTH_REVALIDATE_ERROR = "AUTH_REVALIDATE_ERROR";
export const AUTH_UPDATE_PENDING = "AUTH_UPDATE_PENDING";
export const AUTH_UPDATE_SUCCESS = "AUTH_UPDATE_SUCCESS";
export const AUTH_UPDATE_ERROR = "AUTH_UPDATE_ERROR";
export const AUTH_PASSWORD_CHANGE_PENDING = "AUTH_PASSWORD_CHANGE_PENDING";
export const AUTH_PASSWORD_CHANGE_SUCCESS = "AUTH_PASSWORD_CHANGE_SUCCESS";
export const AUTH_PASSWORD_CHANGE_ERROR = "AUTH_PASSWORD_CHANGE_ERROR";
export const AUTH_ACCOUNT_CONFIRM_PENDING = "AUTH_ACCOUNT_CONFIRM_PENDING";
export const AUTH_ACCOUNT_CONFIRM_SUCCESS = "AUTH_ACCOUNT_CONFIRM_SUCCESS";
export const AUTH_ACCOUNT_CONFIRM_ERROR = "AUTH_ACCOUNT_CONFIRM_ERROR";

function isSuccess(response) {
    return response.status >= 200 && response.status < 300;
}

export function authInit() {
    return function (dispatch, getState) {
        const state = getState();
        if (state && state.auth && state.auth.user && state.auth.user.email) {
            dbFetch("/impersonate/stop");
            dispatch(authRevalidate());
        } else {
            dispatch(autoLogin());
        }
    };
}

export function authAutoLoginPending() {
    return {
        type: AUTH_AUTOLOGIN_PENDING,
    };
}

export function authAutoLoginSuccess(userInfo) {
    return {
        type: AUTH_AUTOLOGIN_SUCCESS,
        user: userInfo,
    };
}

export function authAutoLoginError(userInfo) {
    return {
        type: AUTH_AUTOLOGIN_ERROR,
    };
}

export function authLoginGuest() {
    return function (dispatch) {
        dispatch({
            type: AUTH_LOGIN_GUEST,
            user: {
                first_name: "Guest",
                guest: true,
            },
        });
        dispatch(reloadAll());
    };
}

export function autoLogin() {
    return function (dispatch) {
        dispatch(authAutoLoginPending());
        dbFetch("/auth/auto")
            .then(function (response) {
                if (isSuccess(response)) {
                    return response.json();
                } else {
                    return {};
                }
            })
            .catch(function (err) {
                console.warn(err);
                return {};
            })
            .then(function (data) {
                if (data && data.email) {
                    dispatch(authAutoLoginSuccess(data));
                } else {
                    dispatch(authAutoLoginError());
                }
                dispatch(reloadAll(true));
            });
    };
}

export function authLogout() {
    return function (dispatch, getState) {
        const { auth, offline } = getState();
        // Warn user when logging out with outbox data
        if (offline.outbox.length > 0) {
            if (
                window.confirm(
                    "You currently have unsaved data waiting to sync to the server. If you continue this data will be lost. Do you wish to proceed?",
                )
            ) {
                // Clear outbox
                dispatch({ type: RESET_STATE });
            } else {
                // Cancel
                return;
            }
        }
        if (auth && auth.user && !auth.user.guest) {
            if (auth.user._impersonate) {
                delete auth.user._impersonate;
                delete auth.user._impersonateEmail;
                delete auth.user._impersonateName;
                dbFetch("/impersonate/stop").then(() => {
                    window.location.href = "/auth/logout";
                });
            } else {
                window.location.href = "/auth/logout";
            }
        }

        // Don't need to wait for response
        dispatch({
            type: AUTH_LOGOUT,
        });
    };
}

export function authStartImpersonate() {
    return {
        type: AUTH_START_IMPERSONATE,
    };
}

export function authStopImpersonate() {
    return {
        type: AUTH_STOP_IMPERSONATE,
    };
}

export function authRevalidatePending() {
    return {
        type: AUTH_REVALIDATE_PENDING,
    };
}

export function authRevalidateSuccess(userInfo) {
    return {
        type: AUTH_REVALIDATE_SUCCESS,
        user: userInfo,
    };
}

export function authRevalidateError(error) {
    return {
        type: AUTH_REVALIDATE_ERROR,
    };
}

export function authRevalidate() {
    return function (dispatch, getState) {
        var { auth } = getState();
        if (auth && auth.user && !auth.user.guest) {
            dispatch(authRevalidatePending());
            return dbFetch("/auth/info")
                .then((response) => {
                    if (isSuccess(response)) {
                        return response.json();
                    } else {
                        return {};
                    }
                })
                .catch((error) => {})
                .then((data) => {
                    if (data && data.email) {
                        dispatch(authRevalidateSuccess(data));
                        dispatch(reloadAll(true));
                    } else {
                        dispatch(authRevalidateError());
                    }
                });
        }
    };
}

export function authUpdatePending() {
    return {
        type: AUTH_UPDATE_PENDING,
    };
}

export function authUpdateSuccess(userInfo) {
    return {
        type: AUTH_UPDATE_SUCCESS,
        user: userInfo,
    };
}

export function authUpdateError(error) {
    return {
        type: AUTH_UPDATE_ERROR,
        error: error,
    };
}

export function authUpdate(values) {
    return function (dispatch, getState) {
        var { auth } = getState();

        if (auth && auth.user && !auth.user.guest) {
            dispatch(authUpdatePending());
            return dbFetch("/auth/update", {
                method: "POST",
                body: JSON.stringify(values),
            }).then(function (response) {
                if (isSuccess(response)) {
                    return response.json().then((data) => {
                        dispatch(authUpdateSuccess(data));
                    });
                } else if (response.status === 400) {
                    return response.json().then((data) => {
                        dispatch(authUpdateError(data));
                    });
                } else {
                    dispatch(authUpdateError({ error: "Unknown Server Error" }));
                }
            });
        }
    };
}

export function authResearch(endPoint, values) {
    return function (dispatch, getState) {
        var { auth } = getState();

        if (auth && auth.user && !auth.user.guest) {
            dispatch(authUpdatePending());
            return dbFetch(`/auth/${endPoint}`, {
                method: "POST",
                body: JSON.stringify(values),
            }).then(function (response) {
                if (isSuccess(response)) {
                    return response.json().then((data) => {
                        dispatch(authUpdateSuccess(data));
                    });
                } else if (response.status === 400) {
                    return response.json().then((data) => {
                        dispatch(authUpdateError(data));
                    });
                } else {
                    dispatch(authUpdateError({ error: "Unknown Server Error" }));
                }
            });
        }
    };
}

export function authSetFlag(values) {
    return authResearch("setflag", values);
}

export function authRegisterPending() {
    return {
        type: AUTH_REGISTER_PENDING,
    };
}

export function authRegisterSuccess() {
    return {
        type: AUTH_REGISTER_SUCCESS,
    };
}

export function authRegisterError(error) {
    return {
        type: AUTH_REGISTER_ERROR,
        error: error,
    };
}

export function authRegister(values) {
    return function (dispatch) {
        dispatch(authRegisterPending());
        return dbFetch("/auth/register", {
            method: "POST",
            body: JSON.stringify(values),
        }).then((response) => {
            if (isSuccess(response)) {
                dispatch(authRegisterSuccess());
            } else if (response.status === 400) {
                return response.json().then((data) => {
                    dispatch(authRegisterError(data));
                });
            } else {
                dispatch(authRegisterError({ error: "Unknown Server Error" }));
            }
        });
    };
}

export function authAccountConfirmPending() {
    return {
        type: AUTH_ACCOUNT_CONFIRM_PENDING,
    };
}

export function authAccountConfirmSuccess() {
    return {
        type: AUTH_ACCOUNT_CONFIRM_SUCCESS,
    };
}

export function authAccountConfirmError(error) {
    return {
        type: AUTH_ACCOUNT_CONFIRM_ERROR,
        error: error,
    };
}

export function authAccountConfirm(values) {
    return function (dispatch) {
        dispatch(authAccountConfirmPending());
        return dbFetch("/auth/validate", {
            method: "POST",
            body: JSON.stringify(values),
        }).then((response) => {
            if (isSuccess(response)) {
                dispatch(authAccountConfirmSuccess());
            } else if (response.status === 400 || response.status === 403) {
                return response.json().then((data) => {
                    dispatch(authAccountConfirmError(data));
                });
            } else {
                dispatch(authAccountConfirmError({ error: "Unknown Server Error" }));
            }
        });
    };
}

export function authResendEmail() {
    return function () {
        dbFetch("/auth/resend", {
            method: "POST",
            body: "{}",
        });
    };
}

export function authPasswordChangePending() {
    return {
        type: AUTH_PASSWORD_CHANGE_PENDING,
    };
}

export function authPasswordChangeSuccess() {
    return {
        type: AUTH_PASSWORD_CHANGE_SUCCESS,
    };
}

export function authPasswordChangeError(error) {
    return {
        type: AUTH_PASSWORD_CHANGE_ERROR,
        error: error,
    };
}

export function authPasswordChange(values) {
    return function (dispatch) {
        dispatch(authPasswordChangePending());
        return dbFetch("/auth/changepassword", {
            method: "POST",
            body: JSON.stringify(values),
        }).then((response) => {
            if (isSuccess(response)) {
                dispatch(authPasswordChangeSuccess());
            } else if (response.status === 400) {
                return response.json().then((data) => {
                    dispatch(authPasswordChangeError(data));
                });
            } else {
                dispatch(authPasswordChangeError({ error: "Unknown Server Error" }));
            }
        });
    };
}
