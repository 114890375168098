import React, { Component } from "react";
import classNames from "classnames";
import { FormField } from "react-form";
import MuiTextField from "@mui/material/TextField";
import { InputAdornment } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import Typography from "../common/TypographyWrapper";
import HelpLabel from "./HelpLabel";

const styles = (theme) => ({
    bootstrapInput: {
        borderRadius: 4,
        border: "1px solid #979797",
        padding: "8px 12px",
        width: "calc(100% - 24px)",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        "&:focus": {
            borderColor: theme.palette.secondary.light,
            boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
        },
        backgroundColor: "#fff",
        fontSize: "16px",
        fontWeight: 300,
        color: "#666666 !important",
    },
    labelSelected: {
        //border: '1px solid ' + theme.palette.primary.main,
    },
    bootstrapInputError: {
        "& input": {
            border: "1px solid #f44336",
        },
    },
    bootstrapSelectError: {
        // Select menus are a div not a input
        border: "1px solid #f44336",
    },
    rootLabel: {
        marginLeft: 12,
        marginTop: 3,
        whiteSpace: "nowrap",
        overflow: "visible",
    },
    rootLabelShrink: {
        marginLeft: 0,
        marginTop: -8,
        whiteSpace: "normal",
        transformOrigin: "bottom left",
        right: "-20%",
        position: "relative",
        marginBottom: -16,
    },
    rootMulti: {
        marginTop: 36,
    },
    whiteSpace: {
        whiteSpace: "nowrap",
    },
    whiteSpace2: {
        width: 107,
    },
    disabled: {
        backgroundColor: "#F5F5F5",
    },
    svgHelp: {
        marginRight: 30,
    },
});

// See https://react-form.js.org/#/custom-input
class TextFieldWrapper extends Component {
    handleChange = (e) => {
        let value = e.target.value;

        const { fieldApi, eventHandle, units } = this.props;
        const { setValue } = fieldApi;

        // Make sure value isn't blank otherwise result returns: " acres"
        // Which the server turns into "1 acres"
        if (value !== "" && units) {
            value += " " + units;
        }
        setValue(value);

        if (eventHandle) eventHandle(value);
        this.setState({ foo: "bar" });
    };

    render() {
        const {
            fieldApi,
            helperText,
            SelectProps,
            units,
            help,
            classes,
            label,
            disabled,
            unitsVisual,
            inlineLabel,
            ...rest
        } = this.props;
        const { getValue, getError, getWarning, getSuccess, setTouched } = fieldApi;

        const value = getValue() || (SelectProps ? (SelectProps.multiple ? [] : "") : getValue() === 0 ? 0 : ""),
            error = getError(),
            warning = getWarning(),
            success = getSuccess(),
            hasError = !!error,
            errorText = error || warning || success || helperText;

        let InputProps = {},
            renderValue;

        if (help || units) {
            InputProps["endAdornment"] = (
                <InputAdornment
                    position="end"
                    className={classNames(classes.whiteSpace, inlineLabel && classes.whiteSpace2)}
                >
                    {(units || unitsVisual) && (
                        <Typography color="textSecondary">
                            {units}
                            {unitsVisual}
                            {help && <HelpLabel question={label} helpText={help} inputLabel={true} />}
                        </Typography>
                    )}
                    {help && !units && !unitsVisual && <HelpLabel question={label} helpText={help} />}
                </InputAdornment>
            );
        }

        if (inlineLabel) {
            InputProps["startAdornment"] = (
                <InputAdornment position="start">
                    <Typography variant="body1">{label}</Typography>
                </InputAdornment>
            );
        }

        if (units) {
            if (value && value.split) {
                renderValue = value.split(" ")[0];
            } else {
                renderValue = value;
            }
        } else {
            renderValue = value;
        }

        InputProps["disableUnderline"] = true;
        InputProps["classes"] = {
            input: classNames(classes.bootstrapInput, value && classes.labelSelected, disabled && classes.disabled),
            error: classes.bootstrapInputError,
        };

        let sProps = {};
        if (SelectProps !== undefined) {
            sProps = SelectProps;
        }
        sProps["classes"] = {
            select: hasError ? classes.bootstrapSelectError : "",
            icon: help ? classes.svgHelp : "",
        };

        return (
            <MuiTextField
                variant="standard"
                value={renderValue.toString()}
                error={hasError}
                label={inlineLabel ? null : label}
                disabled={disabled}
                helperText={errorText}
                onChange={this.handleChange}
                onBlur={() => setTouched()}
                SelectProps={sProps}
                InputProps={InputProps}
                InputLabelProps={{
                    shrink: true,
                    classes: {
                        root: classes.rootLabel,
                        shrink: classes.rootLabelShrink,
                    },
                }}
                {...rest}
            />
        );
    }
}

const TextField = FormField(TextFieldWrapper);

export default withStyles(styles)(TextField);
