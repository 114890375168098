import React, { Component } from "react";
import { Link } from "react-router-dom";

import withStyles from "@mui/styles/withStyles";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

import Button from "../common/ButtonWrapper";
import Grid from "../common/GridWrapper";
import { Table, TableBody, TableRow, TableFooter, TablePagination } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Typography from "../common/TypographyWrapper";

import AppContainer from "../common/AppContainer";
import EnhancedTableHead from "../common/EnhancedTableHead";
import TablePaginationActionsWrapped from "../common/Paginator";
import CustomTableCell from "../common/TableCell";
import WarningDialog from "../common/WarningDialog";

import ProjectsDescription from "./ProjectsDescription";

import { ProjectUser } from "./models";
import { ProjectRotationSystem } from "../rotation/models";
import { CropYear } from "../field/fieldactivities/models";
import { connect } from "react-redux";

// CSS in JS styles
const styles = (theme) => ({
    floatRight: {
        float: "right",
    },
    floatLeft: {
        float: "left",
    },
    buttonWidth: {
        minWidth: "44px",
        width: "44px",
    },
    centerAlign: {
        textAlign: "center",
    },
    body1: {
        fontWeight: 400,
        fontSize: 14,
        color: "#666666",
    },
});

const allProjectUsers = ProjectUser.selectAll((PU) => ({
    project_attributes: PU.project,
    ...PU.ref,
}));

const columnData = [
    { id: "name", numeric: false, label: "Project", allowSort: true, width: "25%" },
    { id: "project_leads", numeric: false, label: "Lead", allowSort: true, width: "15%" },
    { id: "crops_label", numeric: false, label: "Crops Analyzed", allowSort: true, width: "20%" },
    { id: "num_fields", numeric: false, label: "Fields Assoc.", allowSort: true, width: "10%" },
    { id: "field_acres", numeric: false, label: "Acres Assoc.", allowSort: true, width: "10%" },
    { id: "active", numeric: false, label: "Active?", allowSort: true, width: "5%" },
    { id: "actions", numeric: false, label: "Actions", allowSort: false, width: "15%" },
];

class ProjectLibrary extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            order: "asc",
            orderBy: "name",
            page: 0,
            rowsPerPage: 10,
            deleteDialogOpen: false,
            deleteDialogText: null,
            deleteDialogConfirmAction: null,
        };
    }

    optOut = (project, name) => {
        this.setState({
            deleteDialogOpen: true,
            deleteDialogConfirmAction: project,
            deleteDialogText: "Are you sure you wish to opt out of " + name + "?",
        });
    };

    doOptOut = (project) => {
        const { projects, ormProjectUserDelete, ormProjectRotationSystemReload, ormCropYearReload } = this.props;
        // Find the id of the ProjectUser Model based on the Project id
        const deletion = projects.filter((pU) => pU.project === project)[0];

        ormProjectUserDelete(deletion.id);
        setTimeout(() => {
            ormProjectRotationSystemReload();
            ormCropYearReload();
        }, 1000);
        this.setState({ deleteDialogOpen: false });
    };

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        order === "desc"
            ? this.props.projects.sort((a, b) =>
                  b["project_attributes"][orderBy] < a["project_attributes"][orderBy] ? -1 : 1,
              )
            : this.props.projects.sort((a, b) =>
                  a["project_attributes"][orderBy] < b["project_attributes"][orderBy] ? -1 : 1,
              );

        this.setState({ order, orderBy });
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: event.target.value });
    };

    render() {
        const { projects, classes } = this.props;
        const { order, orderBy, rowsPerPage, page, deleteDialogOpen, deleteDialogText, deleteDialogConfirmAction } =
            this.state;
        const emptyRows = rowsPerPage - Math.min(rowsPerPage, projects.length - page * rowsPerPage);

        for (const pu of projects) {
            if (!pu.project_attributes) {
                continue;
            }
            pu.project_attributes.project_leads = pu.project_attributes.project_organization
                .filter((po) => po.organization_role === "PS")
                .map((po) => po.organization_label);
        }

        return (
            <Table>
                <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={this.handleRequestSort}
                    columnData={columnData}
                />
                <TableBody>
                    {projects
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map(({ project_attributes = {} }) => (
                            <TableRow hover key={project_attributes.id}>
                                <CustomTableCell>{project_attributes.name}</CustomTableCell>
                                <CustomTableCell>{project_attributes.project_leads.join(", ")}</CustomTableCell>
                                <CustomTableCell>{project_attributes.crops_label.join(", ")}</CustomTableCell>
                                <CustomTableCell>{project_attributes.num_fields}</CustomTableCell>
                                <CustomTableCell>{project_attributes.field_acres.toFixed(2)}</CustomTableCell>
                                <CustomTableCell>{project_attributes.active ? "Yes" : "No"}</CustomTableCell>
                                <CustomTableCell>
                                    <Tooltip title="View Project Details">
                                        <Button
                                            component={Link}
                                            to={"/project/" + project_attributes.id}
                                            className={classes.buttonWidth}
                                        >
                                            <RemoveRedEyeIcon color="primary" />
                                        </Button>
                                    </Tooltip>
                                    <Tooltip title="Opt-Out">
                                        <Button
                                            onClick={() => this.optOut(project_attributes.id, project_attributes.name)}
                                            className={classes.buttonWidth}
                                        >
                                            <CancelIcon color="primary" />
                                        </Button>
                                    </Tooltip>
                                </CustomTableCell>
                            </TableRow>
                        ))}
                    {emptyRows > 0 && projects.length > 10 && (
                        <TableRow style={{ height: 48 * emptyRows }}>
                            <CustomTableCell colSpan={7} />
                        </TableRow>
                    )}
                    {projects.length < 1 && (
                        <TableRow>
                            <CustomTableCell colSpan={7} className={classes.centerAlign}>
                                No Projects Found
                            </CustomTableCell>
                        </TableRow>
                    )}
                </TableBody>
                {projects.length > 10 && (
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                colSpan={7}
                                count={projects.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                classes={{
                                    caption: classes.body1,
                                }}
                                onPageChange={this.handleChangePage}
                                onRowsPerPageChange={this.handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActionsWrapped}
                            />
                        </TableRow>
                    </TableFooter>
                )}
                <WarningDialog
                    confirmAction={() => this.doOptOut(deleteDialogConfirmAction)}
                    cancelAction={() => this.setState({ deleteDialogOpen: false })}
                    open={deleteDialogOpen}
                    title="Opt Out"
                    text={deleteDialogText}
                    confirmText="Opt Out"
                    cancelText="Do Not Opt Out"
                />
            </Table>
        );
    }
}

class ProjectDashboard extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        var { classes, ormProjectUserDelete, ormProjectRotationSystemReload, ormCropYearReload, projects } = this.props;

        return (
            <AppContainer authenticated color="project" title="Projects">
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <ProjectsDescription />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="display3" className={classes.floatLeft}>
                            Projects
                        </Typography>
                        <Button
                            component={Link}
                            to="/project/opt"
                            className={classes.floatRight}
                            variant="raised"
                            color="primary"
                        >
                            Opt-In to New Project&nbsp;&nbsp;&nbsp;
                            <AddCircleOutlineIcon />
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <ProjectLibrary
                            projects={projects}
                            classes={classes}
                            ormProjectUserDelete={ormProjectUserDelete}
                            ormProjectRotationSystemReload={ormProjectRotationSystemReload}
                            ormCropYearReload={ormCropYearReload}
                        />
                    </Grid>
                </Grid>
            </AppContainer>
        );
    }
}

ProjectDashboard = connect(
    (state) => ({
        projects: allProjectUsers(state),
    }),
    {
        ...ProjectUser.actions,
        ...ProjectRotationSystem.actions,
        ...CropYear.actions,
    },
)(ProjectDashboard);

export default withStyles(styles)(ProjectDashboard);
