import React, { Component } from "react";
import { ThemeProvider, StyledEngineProvider } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import classNames from "classnames";
import Grid from "../common/GridWrapper";
import PageHeader from "./inputs/PageHeader";
import PageFooter from "./inputs/PageFooter";
import { Header, Paragraph } from "../field/analysis/Table";
import Cards from "./components/Cards";
import Chart from "./components/Chart";

// Custom CSS classes
const styles = (theme) => ({
    root: {
        padding: 8,
        fontSize: 11,
    },
    page: {
        height: "11in",
        width: "8.5in",
        overflow: "hidden",
        position: "relative",
        pageBreakAfter: "always",
        "& p": {
            lineHeight: 1.2,
        },
    },
    content: {
        width: "7.5in",
        paddingLeft: "0.5in",
        paddingRight: "0.5in",
    },
    column: {
        position: "absolute",
        top: "1.4in",
        width: "3.625in",
        height: "9in",
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
    },
    column1: {
        left: "0.5in",
    },
    column2: {
        right: "0.5in",
    },
    metric: {
        marginBottom: 24,
    },
    "@media screen": {
        page: {
            border: "1px solid #ccc",
            marginTop: "1em",
        },
    },
    header: {
        width: "7.5in",
        paddingLeft: "0.5in",
        paddingRight: "0.5in",
        backgroundColor: "#fce1d6",
        marginTop: 15,
        padding: 5,
    },
    marginTop: {
        marginTop: "1.0in",
    },
    box: {
        width: "7.5in",
        paddingLeft: "0.5in",
        paddingRight: "0.5in",
        backgroundColor: "#e4e4e4",
        marginTop: 15,
        padding: 16,
    },
});

const themeOverrides = {
    MuiTypography: {
        title: {
            fontSize: 15,
        },
        body2: {
            fontSize: 13,
            lineHeight: "1.4em",
        },
    },
    MuiTableCell: {
        body: {
            fontSize: "13px !important",
        },
        head: {
            fontSize: "13px !important",
        },
    },
};

class SummarySection extends Component {
    render() {
        const { classes, scenario, pageCount } = this.props;

        return (
            <>
                <div className={classes.page} id="scenarioreport">
                    <PageHeader first scenario={scenario} />
                    <div className={classNames(classes.content, classes.firstPageContent)}>
                        <Grid container spacing={8}>
                            <Grid item xs={12}>
                                <Header style={{ color: "#6d8d22", fontSize: 15, lineHeight: "1.16667em" }}>
                                    <b>COMET Planner Scenario Tool</b>
                                </Header>
                            </Grid>
                            <Grid item xs={12}>
                                <Paragraph style={{ fontSize: 13 }}>
                                    This evaluation tool is designed to provide generalized estimates of the greenhouse
                                    gas impacts of conservation practices and is intended for initial planning purposes.
                                    Site-specific conditions (not evaluated in this tool) are required for more detailed
                                    assessments of greenhouse gas dynamics on your farm. Total Soil Carbon and Total N
                                    <sub>2</sub>O for this scenario are in short tons of CO2 equivalent per year for the
                                    field based on practices selected in each year and acreage provided for this field.
                                    The report also includes the per practice emission coefficient in short tons of CO
                                    <sub>2</sub>e per acre per year. Negative values indicate increasing emissions and
                                    positive values indicate sequestration (soil carbon) or emissions reduction (N
                                    <sub>2</sub>O). Soil Carbon change is assumed to occur at a constant rate for 10
                                    years following the practice change; N<sub>2</sub>O change is assumed to occur every
                                    year that the practice is implemented.
                                </Paragraph>
                            </Grid>
                            <Grid item xs={12}>
                                <Header style={{ color: "#6d8d22", fontSize: 15, lineHeight: "1.16667em" }}>
                                    <b>Practices Selected for Scenario</b>
                                </Header>
                            </Grid>
                            <Grid item xs={12}>
                                <Paragraph style={{ fontSize: 13 }}>
                                    Below are Conservation Practice Standards (CPS) and associated Conservation Practice
                                    Implementations added to your scenario. Note that you can add a Multiple
                                    Conservation Practices. These practices contain two or more individual practices.
                                    For example, a Multiple Conservation Practices might include CPS 329 (Intensive Till
                                    to No Till or Strip Till) AND CPS 340 (Add Legume Seasonal Cover Crop). To ensure
                                    the most accurate scenario results, if you add a Multiple Conservation Practice,
                                    avoid adding any single Conservation Practice Standards containing the same practice
                                    standard (CPS 340 in this example). Also avoid duplicating any one CPS and note that
                                    many practices have an irrigated and non-irrigated version.
                                </Paragraph>
                            </Grid>
                            <div style={{ marginTop: 10, width: "100%" }}>
                                <Cards startIndex={0} data={scenario.practices.slice(0, 2)} />
                            </div>
                        </Grid>
                    </div>

                    <PageFooter page={1} pages={pageCount} />
                </div>
            </>
        );
    }
}
SummarySection = withStyles(styles)(SummarySection);

class PracticeExtraPage extends Component {
    render() {
        const { classes, data, pageCount } = this.props;
        return (
            <div className={classes.page}>
                <div className={classNames(classes.content, classes.firstPageContent)}>
                    <Grid container spacing={8}>
                        <div style={{ marginTop: 25, width: "100%" }}>
                            <Cards startIndex={2} data={data} />
                        </div>
                    </Grid>
                </div>
                <PageFooter page={2} pages={pageCount} />
            </div>
        );
    }
}
PracticeExtraPage = withStyles(styles)(PracticeExtraPage);

class ChartPage extends Component {
    render() {
        const { classes, scenario, pageCount } = this.props;
        return (
            <div className={classes.page}>
                <div className={classNames(classes.content, classes.firstPageContent)}>
                    <Grid container spacing={8}>
                        <Grid item xs={12} style={{ marginTop: 20 }}>
                            <Header style={{ color: "#6d8d22", fontSize: 15, lineHeight: "1.16667em" }}>
                                <b>Results from Practice Adoption</b>
                            </Header>
                        </Grid>
                        <Grid item xs={12}>
                            <Paragraph style={{ fontSize: 13 }}>
                                Results are total short tons of CO2 equivalent per year for the field based on practices
                                selected in each year and acreage provided for this field. Negative values indicate
                                increasing emissions and positive values indicate sequestration (soil carbon) or
                                emissions reduction (N<sub>2</sub>O). Soil Carbon change is assumed to occur at a
                                constant rate for 10 years following the practice change; N<sub>2</sub>O change is
                                assumed to occur every year that the practice is implemented.
                            </Paragraph>
                        </Grid>
                        <Grid item xs={12}>
                            <Chart scenario={scenario} />
                        </Grid>
                    </Grid>
                </div>
                <PageFooter page={scenario.practices.length > 2 ? 3 : 2} pages={pageCount} />
            </div>
        );
    }
}
ChartPage = withStyles(styles)(ChartPage);

class LearMore extends Component {
    render() {
        const { classes, scenario, pageCount } = this.props;
        return (
            <div className={classes.page}>
                <div className={classNames(classes.content, classes.firstPageContent)}>
                    <Grid container spacing={8}>
                        <div className={classNames(classes.header, classes.marginTop)}>
                            <Paragraph>
                                The Fieldprint® Platform is a pioneering assessment framework that empowers brands,
                                retailers, suppliers and farmers at every stage in their sustainability journey, to
                                measure the environmental impacts of commodity crop production and identify
                                opportunities for continuous improvement.
                            </Paragraph>
                            <br />
                            <Paragraph>
                                Grounded in science and focused on outcomes, the cornerstone of Field to Market’s
                                program lies in the eight sustainability metrics embedded within the Fieldprint
                                Platform. Designed to measure environmental outcomes from individual farm fields, these
                                metrics are science-based tools that have been developed or adopted by Field to Market
                                through the multi-stakeholder governance process over the past decade.
                            </Paragraph>
                            <br />
                            <Paragraph>
                                To learn more about the Program including additional information on our sustainability
                                metrics at{" "}
                                <a href="https://fieldtomarket.org/our-program/">
                                    https://fieldtomarket.org/our-program/
                                </a>
                            </Paragraph>
                        </div>
                        <div className={classNames(classes.box)}>
                            <Paragraph>Citation</Paragraph>
                            <br />
                            <Paragraph>
                                Amy Swan, Crystal Toureene, Mark Easter, Adam Chambers, Kevin Brown, Stephen A.
                                Williams, Jeff Creque, John Wick, Keith Paustian. COMET-Planner Dataset, Version 3.1,
                                Build 1, and COMET-Planner Report: Carbon and Greenhouse Gas Evaluation for NRCS
                                Conservation Practice Planning. Accessed through the Field to Market Fieldprint Platform
                                implementation of COMET-Planner with data revised in December 2023 at
                                http://comet-planner.com/.
                            </Paragraph>
                        </div>
                    </Grid>
                </div>
                <PageFooter page={scenario.practices.length > 3 ? 4 : 3} pages={pageCount} />
            </div>
        );
    }
}
LearMore = withStyles(styles)(LearMore);

export default function ReportLayout({ scenario }) {
    const pageCount = scenario.practices.length > 2 ? 4 : 3;
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider
                theme={(outerTheme) => ({ ...outerTheme, overrides: { ...outerTheme.overrides, ...themeOverrides } })}
            >
                <SummarySection scenario={scenario} pageCount={pageCount} />
                {scenario.practices.length > 2 && (
                    <PracticeExtraPage data={scenario.practices.slice(2)} pageCount={pageCount} />
                )}
                <ChartPage scenario={scenario} pageCount={pageCount} />
                <LearMore scenario={scenario} pageCount={pageCount} />
            </ThemeProvider>
        </StyledEngineProvider>
    );
}
