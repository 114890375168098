import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../common/withRouter";
import { Form } from "react-form";
import classNames from "classnames";

import withStyles from "@mui/styles/withStyles";

import Button from "../common/ButtonWrapper";
import Grid from "../common/GridWrapper";
import { Table, TableBody, TableRow, TableFooter, TablePagination } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Typography from "../common/TypographyWrapper";
import EqualizerIcon from "@mui/icons-material/Equalizer";

import AppContainer from "../common/AppContainer";
import Checkbox from "../common/Checkbox";
import EnhancedTableHead from "../common/EnhancedTableHead";
import TablePaginationActionsWrapped from "../common/Paginator";
import Snackbar from "../common/Snackbar";
import CustomTableCell from "../common/TableCell";
import Select from "../common/Select";

import ProjectTeam from "./components/ProjectTeam";
import { Project } from "./models";
import { connect } from "react-redux";
import { Field } from "../field/models";
import { CropYear, CROP_YEAR_COMPLETED } from "../field/fieldactivities/models";
import { createSelector } from "../common/orm";
import { getValue } from "../../api/utils";

const getProject = Project.selectByUrlId();
const allFields = Field.selectAll();
const allCropYears = createSelector(
    // Custom Selector for orderBy
    (schema) => {
        if (schema.Field.count() > 0 && schema.Farm.count() > 0) {
            return schema.CropYear.all()
                .orderBy("year", "desc")
                .toModelArray()
                .map((cropYear) => ({
                    farm_name: cropYear.field.farm.name,
                    farm_id: cropYear.field.farm.id,
                    field_name: cropYear.field.name,
                    field_id: cropYear.field.id,
                    field_acres: cropYear.field.size,
                    crop_name: cropYear.crop.name,
                    ...cropYear.ref,
                }));
        } else {
            return [];
        }
    },
);

// CSS in JS styles
const styles = (theme) => ({
    floatRight: {
        float: "right",
    },
    buttonWidth: {
        minWidth: "44px",
        width: "44px",
    },
    centerAlign: {
        textAlign: "center",
    },
    deleteWidth: {
        minWidth: "44px",
        width: "44px",
        marginLeft: "auto",
        marginRight: "auto",
    },
    grey: {
        backgroundColor: "#eeeeee",
        border: "4px solid #ffffff",
        whiteSpace: "nowrap",
    },
    standardMargin: {
        marginLeft: 8,
        marginRight: 8,
    },
    filterButton: {
        marginBottom: 8,
        width: "100%",
        border: "1px solid #666666",
    },
    paddingRight: {
        paddingRight: "24px !important",
    },
    linkColor: {
        color: "#808080",
    },
    crumbColor: {
        color: theme.palette.primary.main,
    },
    body1: {
        fontWeight: 400,
        fontSize: 14,
        color: "#666666",
    },
    analyticsTooltip: {
        // Tooltips don't work on disabled buttons without div, style div as button
        display: "inline-block",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.08)",
        },
    },
});

const columnData2 = [
    { id: "year", numeric: false, label: "Year", allowSort: true, width: "10%" },
    { id: "crop_label", numeric: false, label: "Crop", allowSort: true, width: "10%" },
    { id: "field_acres", numeric: true, label: "Total Entered Acres (All Fields)", allowSort: false, width: "25%" },
    { id: "managed_acres", numeric: true, label: "Total Managed", allowSort: false, width: "15%" },
    { id: "percent", numeric: true, label: "Percent of Total Managed Acres", allowSort: false, width: "25%" },
    { id: "reached", numeric: false, label: "10% Reached?", allowSort: false, width: "15%" },
];

class ProjectData extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            order: "desc",
            orderBy: "year",
            page: 0,
            rowsPerPage: 10,
        };
    }

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        order === "desc"
            ? this.props.data.sort((a, b) => (b[orderBy].toLowerCase() < a[orderBy].toLowerCase() ? -1 : 1))
            : this.props.data.sort((a, b) => (a[orderBy].toLowerCase() < b[orderBy].toLowerCase() ? -1 : 1));

        this.setState({ order, orderBy });
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: event.target.value });
    };

    render() {
        const { data, classes } = this.props;
        const { order, orderBy, rowsPerPage, page } = this.state;
        const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

        // If the cropyear has the same year and crop group them together
        var dataGrouped = [];
        data.forEach(function (d) {
            var hit = false;
            dataGrouped.forEach(function (dG) {
                if (d.year === dG.year && d.crop_label === dG.crop_label) {
                    hit = true;
                    dG.field_acres = dG.field_acres + d.field_acres;
                    if (
                        d.crop_user_id !== dG.crop_user_id &&
                        (!dG.farmer_list || !dG.farmer_list.includes(d.crop_user_id))
                    ) {
                        if (!dG.farmer_list) dG.farmer_list = [];
                        dG.farmer_list.push(d.crop_user_id);
                        dG.managed_acres = dG.managed_acres + d.managed_acres;
                    }
                }
            });
            if (!hit) dataGrouped.push(Object.assign({}, d));
        });

        dataGrouped.forEach(function (dG) {
            var p = dG.field_acres / dG.managed_acres;

            dG.percent = (p * 100).toFixed(0).toString() + "%";
            if (p < 0.1) {
                dG.reached = "No";
            } else {
                dG.reached = "Yes";
            }
        });

        return (
            <Table>
                <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={this.handleRequestSort}
                    columnData={columnData2}
                />
                <TableBody>
                    {dataGrouped.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((a) => (
                        <TableRow hover key={a.id}>
                            <CustomTableCell>{a.year}</CustomTableCell>
                            <CustomTableCell>{a.crop_label}</CustomTableCell>
                            <CustomTableCell>{a.field_acres.toFixed(2)}</CustomTableCell>
                            <CustomTableCell>{a.managed_acres}</CustomTableCell>
                            <CustomTableCell>{a.percent}</CustomTableCell>
                            <CustomTableCell>{a.reached}</CustomTableCell>
                        </TableRow>
                    ))}
                    {emptyRows > 0 && dataGrouped.length > 10 && (
                        <TableRow style={{ height: 48 * emptyRows }}>
                            <CustomTableCell colSpan={6} />
                        </TableRow>
                    )}
                    {dataGrouped.length < 1 && (
                        <TableRow>
                            <CustomTableCell colSpan={6} className={classes.centerAlign}>
                                No Crops Found
                            </CustomTableCell>
                        </TableRow>
                    )}
                </TableBody>
                {dataGrouped.length > 10 && (
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                colSpan={6}
                                count={dataGrouped.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                classes={{
                                    caption: classes.body1,
                                }}
                                onPageChange={this.handleChangePage}
                                onRowsPerPageChange={this.handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActionsWrapped}
                            />
                        </TableRow>
                    </TableFooter>
                )}
            </Table>
        );
    }
}

const columnData3 = [
    { id: "farm_name", numeric: false, label: "Farm", allowSort: true },
    { id: "field_name", numeric: false, label: "Field", allowSort: true },
    { id: "year", numeric: true, label: "Year", allowSort: true },
    { id: "crop", numeric: false, label: "Crop", allowSort: true },
    { id: "field_acres", numeric: true, label: "Entered Acres", allowSort: true },
    { id: "is_final", numeric: false, label: "Data Provisional or Final?", allowSort: false },
    { id: "metrics_version", numeric: false, label: "Metrics", allowSort: false },
    { id: "check", numeric: false, label: "Check", allowSort: false, center: true },
];

class CropLibrary extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            order: "desc",
            orderBy: "year",
            page: 0,
            rowsPerPage: 10,
            snackbarOpen: false,
            snackbarMessage: "saved",
        };
    }

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        const isNumeric = columnData3.filter(function (item) {
            if (item.id === property) return item;
            else return null;
        });
        if (isNumeric[0].numeric) {
            order === "desc"
                ? this.props.cropYears.sort((a, b) => b[orderBy].split(" ")[0] - a[orderBy].split(" ")[0])
                : this.props.cropYears.sort((a, b) => a[orderBy].split(" ")[0] - b[orderBy].split(" ")[0]);
        } else {
            order === "desc"
                ? this.props.cropYears.sort((a, b) => (b[orderBy].toLowerCase() < a[orderBy].toLowerCase() ? -1 : 1))
                : this.props.cropYears.sort((a, b) => (a[orderBy].toLowerCase() < b[orderBy].toLowerCase() ? -1 : 1));
        }

        this.setState({ order, orderBy });
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: event.target.value });
    };

    applyCropYear = (checked, cropYearId) => {
        const { ormCropYearUpdate, ormProjectLoadDetail, projectid } = this.props;
        ormCropYearUpdate({
            id: cropYearId,
            project_instance: checked ? projectid : null,
        });
        if (checked) this.setState({ snackbarOpen: true, snackbarMessage: "saved" });
        else this.setState({ snackbarOpen: true, snackbarMessage: "removed" });
        //FIXME: WHERE IS THE UPDATE CALLBACK??
        setTimeout(function () {
            ormProjectLoadDetail(projectid);
        }, 500);
    };

    selectAllFields = () => {
        const { ormCropYearUpdate, ormProjectLoadDetail, projectid, cropYears } = this.props;

        cropYears.forEach(function (cY) {
            ormCropYearUpdate({
                id: cY.id,
                project_instance: projectid,
            });
        });
        this.setState({ snackbarOpen: true, snackbarMessage: "saved" });
        //FIXME: WHERE IS THE UPDATE CALLBACK??
        setTimeout(function () {
            ormProjectLoadDetail(projectid);
        }, 500);
    };

    handleSnackbarClose = () => {
        this.setState({ snackbarOpen: false });
    };

    render() {
        const { cropYears, classes, projectid } = this.props;
        const { order, orderBy, rowsPerPage, page, snackbarOpen, snackbarMessage } = this.state;
        const emptyRows = rowsPerPage - Math.min(rowsPerPage, cropYears.length - page * rowsPerPage);

        return (
            <div>
                <Button
                    onClick={this.selectAllFields}
                    color="primary"
                    variant="raised"
                    style={{ float: "right", marginBottom: 16 }}
                >
                    Check All
                </Button>
                <Table>
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={this.handleRequestSort}
                        columnData={columnData3}
                    />
                    <TableBody>
                        {cropYears.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((cropYear) => (
                            <TableRow key={cropYear.id} hover>
                                <CustomTableCell>{cropYear.farm_name}</CustomTableCell>
                                <CustomTableCell>{cropYear.field_name}</CustomTableCell>
                                <CustomTableCell>{cropYear.year}</CustomTableCell>
                                <CustomTableCell>{cropYear.crop_name}</CustomTableCell>
                                <CustomTableCell>{cropYear.field_acres.split(" ")[0]}</CustomTableCell>
                                <CustomTableCell>{cropYear.is_final ? "Final" : "Provisional"}</CustomTableCell>
                                <CustomTableCell>
                                    <Tooltip
                                        title={
                                            CROP_YEAR_COMPLETED(cropYear)
                                                ? cropYear.metrics_version
                                                    ? "Analytics - Computed"
                                                    : "Analytics - Not Yet Computed"
                                                : " Analytics (Complete Data Entry to View)"
                                        }
                                    >
                                        <div className={classes.analyticsTooltip}>
                                            <Button
                                                disabled={!CROP_YEAR_COMPLETED(cropYear)}
                                                component={Link}
                                                to={"/cropyear/" + cropYear.id + "/analysis"}
                                                className={classes.buttonWidth}
                                            >
                                                {CROP_YEAR_COMPLETED(cropYear) && cropYear.metrics_version && (
                                                    <EqualizerIcon style={{ color: "green" }} />
                                                )}
                                                {CROP_YEAR_COMPLETED(cropYear) && !cropYear.metrics_version && (
                                                    <EqualizerIcon color="primary" />
                                                )}
                                                {!CROP_YEAR_COMPLETED(cropYear) && <EqualizerIcon />}
                                            </Button>
                                        </div>
                                    </Tooltip>
                                </CustomTableCell>
                                <CustomTableCell>
                                    <Checkbox
                                        checked={cropYear.project_instance === projectid}
                                        color="secondary"
                                        eventHandle={(checked) => this.applyCropYear(checked, cropYear.id)}
                                        field={cropYear.id}
                                        label=""
                                        align={classNames(classes.deleteWidth)}
                                    />
                                </CustomTableCell>
                            </TableRow>
                        ))}
                        {emptyRows > 0 && cropYears.length > 10 && (
                            <TableRow style={{ height: 48 * emptyRows }}>
                                <CustomTableCell colSpan={6} />
                            </TableRow>
                        )}
                        {cropYears.length < 1 && (
                            <TableRow>
                                <CustomTableCell colSpan={6} className={classes.centerAlign}>
                                    No Valid Crop Years Entered
                                </CustomTableCell>
                            </TableRow>
                        )}
                    </TableBody>
                    {cropYears.length > 10 && (
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    colSpan={6}
                                    count={cropYears.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    classes={{
                                        caption: classes.body1,
                                    }}
                                    onPageChange={this.handleChangePage}
                                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActionsWrapped}
                                />
                            </TableRow>
                        </TableFooter>
                    )}

                    <Snackbar
                        success
                        onClose={this.handleSnackbarClose}
                        open={snackbarOpen}
                        section="crop year to the project"
                        action={snackbarMessage}
                    />
                </Table>
            </div>
        );
    }
}

class ProjectDetails extends Component {
    state = {
        fieldFilter: null,
        yearFilter: null,
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        this.loadProject({});
    }

    componentDidUpdate(prevProps) {
        this.loadProject(prevProps);
    }

    loadProject(prevProps) {
        const { project, ormProjectLoadDetail, syncState } = this.props,
            { project: lastProject, syncState: lastSyncState } = prevProps;

        if (!project) {
            return;
        }
        if (!lastProject || lastProject.id !== project.id || !lastSyncState.ready) {
            if (syncState.ready && !getValue(project, "synced")) {
                ormProjectLoadDetail(project.id);
            }
        }
    }

    render() {
        var { classes, project, fields, cropYears, ormCropYearUpdate, ormProjectLoadDetail } = this.props;
        const { fieldFilter, yearFilter } = this.state;

        if (!project || project.name === "Not Found") return "Not Found";

        function makeOptions(table) {
            table = table.map((row) => ({
                label: row.name ? row.name : row.id,
                value: row.id,
                key: row.id,
            }));
            table.unshift({ label: <b>Clear Filter</b>, value: null, key: null });
            return table;
        }

        function makeYearOptions(years) {
            years = years.map((row) => ({
                label: row,
                value: row,
                key: row,
            }));
            years.unshift({ label: <b>Clear Filter</b>, value: null, key: null });
            return years;
        }

        // Don't show crop years that aren't applicable
        // Probably better to do it in the selector, but project data isn't available there?
        // No Future Crop Years
        cropYears = cropYears.filter((cY) => cY.year <= new Date().getFullYear());
        // Must have crop related to project
        cropYears = cropYears.filter((cY) => project.crops_label.includes(cY.crop_name));
        // Not related to a different project
        cropYears = cropYears.filter(
            (cY) =>
                cY.project_instance === null || cY.project_instance === undefined || cY.project_instance === project.id,
        );

        var years = [];
        cropYears.forEach(function (cY) {
            if (!years.includes(cY.year)) years.push(cY.year);
        });
        years.sort();
        // Filters
        var fieldsSort = fields.sort((a, b) =>
            (a.name ? a.name : a.id).toLowerCase() < (b.name ? b.name : b.id).toLowerCase() ? -1 : 1,
        );
        if (fieldFilter) {
            cropYears = cropYears.filter((cY) => cY.field_id === fieldFilter);
        }
        if (yearFilter) {
            cropYears = cropYears.filter((cY) => cY.year === yearFilter);
        }

        const synced = getValue(project, "synced");

        return (
            <AppContainer
                authenticated
                synced={!synced}
                color="project"
                title={project.name}
                crumbs={
                    <div>
                        <Link className={classes.linkColor} to={"/project"}>
                            Project Dashboard
                        </Link>
                        &nbsp;&nbsp;&nbsp;&gt;&nbsp;&nbsp;&nbsp;{" "}
                        <span className={classes.crumbColor}>{project.name}</span>
                    </div>
                }
            >
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <Typography>
                            This page helps you manage fields associated with a specific Project. The table below shows
                            existing associations and you have the option to associate additional fields.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="display3" gutterBottom>
                            Project Team
                        </Typography>
                        <ProjectTeam project={project} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="display3" gutterBottom>
                            Summary of Your Project Data
                        </Typography>
                        <ProjectData data={project.user_data ? project.user_data : []} classes={classes} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="display3" gutterBottom>
                            Your Field Associations
                        </Typography>
                        <Typography gutterBottom>
                            The following table lists all of your field crop years and indicates which, if any, have
                            been already associated with the project. You can add or remove associations. Note there are
                            certain limitations on field crop year associations. For example, a single field crop year
                            (e.g. Field 1, Year = 2017, Crop = Corn) can be associated with only one project. The table
                            below automatically filters out your field crop years already associated with the selected
                            project and field crop years already associated with other Projects. Also, as Projects focus
                            on specific crops (e.g. soybeans, corn), your listing of fields is filtered to show only
                            relevent crops.
                        </Typography>
                        <Form dontValidateOnMount="true" validateOnSubmit="true">
                            {(formApi) => (
                                <form>
                                    <Grid container spacing={8} className={classes.grey} alignItems="flex-end">
                                        <Grid item xs={4}>
                                            <Select
                                                eventHandle={(value) => this.setState({ fieldFilter: value })}
                                                field="dfield"
                                                label="Field"
                                                className={classes.standardMargin}
                                                options={makeOptions(fieldsSort)}
                                                fullWidth
                                                margin="normal"
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Select
                                                eventHandle={(value) => this.setState({ yearFilter: value })}
                                                field="dyear"
                                                label="Year"
                                                className={classes.standardMargin}
                                                options={makeYearOptions(years)}
                                                fullWidth
                                                margin="normal"
                                            />
                                        </Grid>
                                        <Grid item xs={3} className={classes.paddingRight}>
                                            <Button
                                                className={classNames(classes.standardMargin, classes.filterButton)}
                                                onClick={() => {
                                                    this.setState({
                                                        fieldFilter: "",
                                                        yearFilter: "",
                                                    });
                                                    formApi.resetAll();
                                                }}
                                            >
                                                <Typography>Clear All Filters</Typography>
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Form>
                    </Grid>
                    <Grid item xs={12}>
                        <Form dontValidateOnMount="true" validateOnSubmit="true">
                            {(formApi) => (
                                <form>
                                    <CropLibrary
                                        projectid={project.id}
                                        cropYears={cropYears}
                                        classes={classes}
                                        ormProjectLoadDetail={ormProjectLoadDetail}
                                        ormCropYearUpdate={ormCropYearUpdate}
                                    />
                                </form>
                            )}
                        </Form>
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="raised" component={Link} to="/project">
                            Return to Projects Dashboard
                        </Button>
                    </Grid>
                </Grid>
            </AppContainer>
        );
    }
}

ProjectDetails = connect(
    (state, ownProps) => ({
        project: getProject(state, ownProps),
        cropYears: allCropYears(state),
        fields: allFields(state),
        syncState: state.sync,
    }),
    {
        ...Project.actions,
        ...CropYear.actions,
    },
)(ProjectDetails);

export default withStyles(styles)(withRouter(ProjectDetails));
