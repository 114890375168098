import React from "react";

export default function Label({ children, color, size }) {
    return (
        <span
            style={{
                backgroundColor: color,
                color: "white",
                padding: "2px 4px",
                marginRight: 8,
                borderRadius: 3,
                fontSize: size || 12,
            }}
        >
            {children}
        </span>
    );
}
