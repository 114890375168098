import React, { useState } from "react";
import { connect } from "react-redux";
import CropYearReportLayout from "../field/analysis/ReportLayout";
import ScenarioReportLayout from "../scenario/ReportLayout";
import ProjectReportLayout from "../project/ReportLayout";
import orm from "./orm";

function ReportTool({ ormState }) {
    const [data, setData] = useState(null),
        ready = orm.registry
            .filter((model) => model.isStaticData)
            .every((model) => ormState[model.modelName].items.length > 0);
    window.setReportData = setData;

    if (ready) {
        if (data) {
            return (
                <div id="report">
                    {"scenario_id" in data ? (
                        <ScenarioReportLayout scenario={data} />
                    ) : "project_id" in data ? (
                        <ProjectReportLayout project={data} />
                    ) : (
                        <CropYearReportLayout cropYear={data} />
                    )}
                </div>
            );
        } else {
            return <div id="ready">No Report Data</div>;
        }
    } else {
        return <div id="loading">Loading...</div>;
    }
}

export default connect((state) => ({ ormState: state.orm }))(ReportTool);
