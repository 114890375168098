import React, { Component } from "react";

import Dialog from "@mui/material/Dialog";
import Grid from "../../common/GridWrapper";
import Typography from "../../common/TypographyWrapper";
import Close from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

import withStyles from "@mui/styles/withStyles";

const styles = (theme) => ({
    dialog: {
        backgroundColor: "#eee",
        maxWidth: 1200,
    },
});

class Popup extends Component {
    render() {
        const { classes, closeEvent, image, imagealt, title, text, ...other } = this.props;
        return (
            <Dialog onClose={closeEvent} classes={{ paper: classes.dialog }} {...other}>
                <Grid container spacing={0}>
                    <Grid item xs={6}>
                        <img src={image} alt={imagealt} style={{ width: "100%" }} />
                    </Grid>
                    <Grid item xs={6}>
                        <IconButton onClick={closeEvent} style={{ float: "right" }} size="large">
                            <Close />
                        </IconButton>
                        <Typography variant="title" style={{ padding: 16, fontSize: 28 }}>
                            {title}
                        </Typography>
                        {text}
                    </Grid>
                </Grid>
            </Dialog>
        );
    }
}

export default withStyles(styles)(Popup);
