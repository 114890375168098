import React, { useState } from "react";
import { Dialog, DialogContent } from "@mui/material";

export default function VimeoLink({ vid, className, children }) {
    const [open, setOpen] = useState();
    return (
        <>
            <span
                onClick={() => setOpen(true)}
                className={className}
                style={{ cursor: "pointer", textDecoration: "underline" }}
            >
                {children}
            </span>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                PaperProps={{
                    style: { maxWidth: "unset" },
                }}
            >
                <DialogContent style={{ width: "60vw", height: "33.75vw", padding: 0 }}>
                    {open && (
                        <>
                            <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
                                <iframe
                                    title={`Vimeo Video ${vid}`}
                                    src={`https://player.vimeo.com/video/${vid}`}
                                    style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
                                    frameborder="0"
                                    allow="autoplay; fullscreen; picture-in-picture"
                                    allowfullscreen
                                />
                            </div>
                            <script src="https://player.vimeo.com/api/player.js" />
                        </>
                    )}
                </DialogContent>
            </Dialog>
        </>
    );
}
