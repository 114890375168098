import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../common/withRouter";
import { Form } from "react-form";
import classNames from "classnames";

import withStyles from "@mui/styles/withStyles";
import FileCopy from "@mui/icons-material/FileCopyOutlined";

import Button from "../common/ButtonWrapper";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Grid from "../common/GridWrapper";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material/";
import { Table, TableBody, TableRow, TableFooter, TablePagination } from "@mui/material/";
import Typography from "../common/TypographyWrapper";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import RefreshIcon from "@mui/icons-material/Refresh";
import DialpadIcon from "@mui/icons-material/Dialpad";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import FileUploadIcon from "@mui/icons-material/Publish";
import AddCircleIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircleOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import DeleteIcon from "@mui/icons-material/Delete";
import ContentCopyIcon from "@mui/icons-material/FileCopyOutlined";
import FileDownloadIcon from "@mui/icons-material/GetApp";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AnnouncementIcon from "@mui/icons-material/Announcement";

import Tooltip from "@mui/material/Tooltip";

import { LinearProgress } from "@mui/material";

import AppContainer from "../common/AppContainer";
import EnhancedTableHead from "../common/EnhancedTableHead";
import TablePaginationActionsWrapped from "../common/Paginator";
import CustomTableCell from "../common/TableCell";
import Select from "../common/Select";
import WarningDialog from "../common/WarningDialog";
import HelpDialog from "../common/HelpDialog";

import * as utils from "../rotation/CropManagementUtils";

import ProjectFacts from "./components/ProjectFacts";
import ProjectTeam from "./components/ProjectTeam";
import { Project } from "./models";
import { connect } from "react-redux";
import { getValue } from "../../api/utils";
import { apiFetch, dbFetch } from "../../api/fetch";

import { reloadAll } from "../common/orm";

import { round } from "../field/analysis/StaticData";

import { RotationSystem, RotationEvent, ProjectRotationSystem } from "../rotation/models";
import * as rotationActions from "../rotation/actions";

import ImportTemplateDialog from "../rotation/ImportTemplateDialog";
import PreviewRotation from "../rotation/PreviewRotation";

import { ProjectContact } from "./models";

import saveAs from "file-saver";
import { Divider } from "@mui/material/";

const getProject = Project.selectByUrlId();

const allRotationSystems = RotationSystem.selectAll((rotationSystem) => {
    return {
        ...rotationSystem.ref,
        events: rotationSystem.events.toModelArray().map((e) => e._fields),
    };
});

const allProjectRotationSystems = ProjectRotationSystem.selectAll((sys) => ({ ...sys.ref }));

const allProjectContacts = ProjectContact.selectAll((PU) => ({
    id: PU.project.id,
    name: PU.project.name,
}));

// CSS in JS styles
const styles = (theme) => ({
    floatRight: {
        float: "right",
    },
    buttonWidth: {
        minWidth: "44px",
        width: "44px",
    },
    centerAlign: {
        textAlign: "center",
    },
    deleteWidth: {
        minWidth: "44px",
        width: "44px",
        marginLeft: "auto",
        marginRight: "auto",
    },
    grey: {
        backgroundColor: "#eeeeee",
        border: "4px solid #ffffff",
        whiteSpace: "nowrap",
    },
    standardMargin: {
        marginLeft: 8,
        marginRight: 8,
    },
    filterButton: {
        marginBottom: 8,
        width: "100%",
        border: "1px solid #666666",
    },
    paddingRight: {
        paddingRight: "24px !important",
    },
    linkColor: {
        color: "#808080",
    },
    crumbColor: {
        color: theme.palette.primary.main,
    },
    gutterTop: {
        marginTop: ".6em",
    },
    reportButton: {
        border: "1px solid",
        float: "right",
        marginRight: 24,
    },
    benchmarkSelectContainer: {
        backgroundColor: "#eeeeee",
        padding: 8,
    },
    benchmarkSelect: {
        marginTop: 0,
    },
    refreshButton: {
        // border: "1px solid",
        float: "right",
        // marginTop: 14
    },
    body1: {
        fontWeight: 400,
        fontSize: 14,
        color: "#666666",
    },
    paddingLeft: {
        paddingLeft: "24px !important",
    },
    optionButton: {
        border: "1px solid #979797",
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 12,
        paddingRight: 12,
        minHeight: 36,
        color: "#979797",
        backgroundColor: "#fff",
        boxShadow: "none",
        "&:hover": {
            border: "1px solid #fff",
            backgroundColor: "#ff7d32",
            color: "#fff",
        },
    },
    optionButtonIcon: {
        marginRight: 10,
        fontSize: 30,
        border: "1px solid",
        padding: 4,
        strokeWidth: 2,
        borderRadius: 4,
        margin: 10,
        borderColor: "inherit",
    },
    greenText: {
        color: "#45812B",
    },
    recalculateButton: {
        color: "#ffffff",
        backgroundColor: "#6D8D22",
    },
    recalculateButtonDisabled: {
        color: "#ffffff",
        backgroundColor: "#A9A9A9",
    },
});

const columnData = [
    { id: "year", numeric: false, label: "Year", allowSort: true, width: "15%" },
    { id: "field_acres", numeric: false, label: "Total Acres Entered for all Crops", allowSort: false, width: "40%" },
    {
        id: "unique_farmers",
        numeric: true,
        label: "Total Unique Farmers Participating",
        allowSort: false,
        width: "45%",
    },
];

class ProjectOverview extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            order: "desc",
            orderBy: "year",
            page: 0,
            rowsPerPage: 10,
        };
    }

    componentDidMount() {
        this.props.onRef(this);
    }

    componentWillUnmount() {
        this.props.onRef(undefined);
    }

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        order === "desc"
            ? this.props.data.sort((a, b) => (b[orderBy].toLowerCase() < a[orderBy].toLowerCase() ? -1 : 1))
            : this.props.data.sort((a, b) => (a[orderBy].toLowerCase() < b[orderBy].toLowerCase() ? -1 : 1));

        this.setState({ order, orderBy });
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: event.target.value });
    };

    getCSV = () => {
        var dataGrouped = this.renderTable();

        //var fields = Object.keys(dataGrouped[0])
        var fields = ["year", "field_acres", "unique_farmers"];
        var replacer = function (key, value) {
            return value === null ? "" : value;
        };
        var csv = dataGrouped.map(function (row) {
            return fields
                .map(function (fieldName) {
                    return JSON.stringify(row[fieldName], replacer);
                })
                .join(",");
        });
        csv.unshift(fields.join(","));

        return csv.join("\r\n");
    };

    renderTable = () => {
        const { data } = this.props;
        // If the cropyear has the same year group them together
        var dataGrouped = [];
        data.forEach(function (d) {
            if (!d.include_in_reports) {
                return;
            }
            var hit = false;
            dataGrouped.forEach(function (dG) {
                if (d.year === dG.year) {
                    hit = true;
                    dG.field_acres = dG.field_acres + d.field_acres;
                    if (
                        d.crop_user_id !== dG.crop_user_id &&
                        (!dG.farmer_list || !dG.farmer_list.includes(d.crop_user_id))
                    ) {
                        dG.unique_farmers = dG.unique_farmers + 1;
                        if (!dG.farmer_list) dG.farmer_list = [];
                        dG.farmer_list.push(d.crop_user_id);
                    }
                }
            });
            if (!hit) {
                var obj = Object.assign({}, d);
                // The above would be simple if unique_farmers could be an object holding farmer id and number but react crashes?
                obj.unique_farmers = 1;
                dataGrouped.push(obj);
            }
        });
        return dataGrouped;
    };

    render() {
        const { data, classes } = this.props;
        const { order, orderBy, rowsPerPage, page } = this.state;
        const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

        var dataGrouped = this.renderTable();

        return (
            <Table>
                <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={this.handleRequestSort}
                    columnData={columnData}
                />
                <TableBody>
                    {dataGrouped.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((a) => (
                        <TableRow hover key={a.id}>
                            <CustomTableCell>{a.year}</CustomTableCell>
                            <CustomTableCell>{a.field_acres.toFixed(2)}</CustomTableCell>
                            <CustomTableCell>{a.unique_farmers}</CustomTableCell>
                        </TableRow>
                    ))}
                    {emptyRows > 0 && dataGrouped.length > 10 && (
                        <TableRow style={{ height: 48 * emptyRows }}>
                            <CustomTableCell colSpan={3} />
                        </TableRow>
                    )}
                    {dataGrouped.length < 1 && (
                        <TableRow>
                            <CustomTableCell colSpan={3} className={classes.centerAlign}>
                                No Years Found
                            </CustomTableCell>
                        </TableRow>
                    )}
                </TableBody>
                {dataGrouped.length > 10 && (
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                colSpan={3}
                                count={dataGrouped.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                classes={{
                                    caption: classes.body1,
                                }}
                                onPageChange={this.handleChangePage}
                                onRowsPerPageChange={this.handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActionsWrapped}
                            />
                        </TableRow>
                    </TableFooter>
                )}
            </Table>
        );
    }
}

const columnData2 = [
    { id: "year", numeric: false, label: "Year", allowSort: true, width: "10%" },
    { id: "crop_label", numeric: false, label: "Crop", allowSort: true, width: "10%" },
    { id: "unique_farmers", numeric: true, label: "Total Unique Farmers", allowSort: false, width: "10%" },
    { id: "unique_farms", numeric: true, label: "Total Unique Farms", allowSort: false, width: "10%" },
    { id: "unique_fields", numeric: true, label: "Total Unique Fields", allowSort: false, width: "10%" },
    { id: "field_acres", numeric: true, label: "Total Entered Acres (all fields)", allowSort: false, width: "20%" },
    { id: "managed_acres", numeric: true, label: "Total Managed Acres (all farms)", allowSort: false, width: "15%" },
    { id: "managed_acres_calc", numeric: true, label: "Total Enrolled Acres*", allowSort: false, width: "15%" },
];

class ProjectData extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            order: "desc",
            orderBy: "year",
            page: 0,
            rowsPerPage: 10,
        };
    }

    componentDidMount() {
        this.props.onRef(this);
    }

    componentWillUnmount() {
        this.props.onRef(undefined);
    }

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        order === "desc"
            ? this.props.data.sort((a, b) => (b[orderBy].toLowerCase() < a[orderBy].toLowerCase() ? -1 : 1))
            : this.props.data.sort((a, b) => (a[orderBy].toLowerCase() < b[orderBy].toLowerCase() ? -1 : 1));

        this.setState({ order, orderBy });
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: event.target.value });
    };

    getCSV = () => {
        var dataGrouped = this.renderTable();

        //var fields = Object.keys(dataGrouped[0])
        var fields = [
            "year",
            "crop_label",
            "unique_farmers",
            "unique_farms",
            "unique_fields",
            "field_acres",
            "managed_acres",
            "managed_acres_calc",
        ];
        var replacer = function (key, value) {
            return value === null ? "" : value;
        };
        var csv = dataGrouped.map(function (row) {
            return fields
                .map(function (fieldName) {
                    return JSON.stringify(row[fieldName], replacer);
                })
                .join(",");
        });
        csv.unshift(fields.join(","));

        return csv.join("\r\n");
    };

    renderTable = () => {
        const { data } = this.props;

        // First group all the same crops+year+farmer
        var dataGrouped = [];
        data.forEach(function (d) {
            if (!d.include_in_reports) {
                return;
            }
            var hit = false;
            dataGrouped.forEach(function (dG) {
                if (
                    d.year === dG.year &&
                    d.crop_label === dG.crop_label &&
                    d.crop_user_id === dG.crop_user_id &&
                    d.farm_name === dG.farm_name
                ) {
                    hit = true;
                    dG.field_acres = dG.field_acres + d.field_acres;

                    if (
                        d.field_label !== dG.field_label &&
                        (!dG.field_list || !dG.field_list.includes(d.field_label))
                    ) {
                        dG.unique_fields = dG.unique_fields + 1;
                        if (!dG.field_list) dG.field_list = [];
                        dG.field_list.push(d.field_label);
                    }
                }
            });
            if (!hit) {
                var obj = Object.assign({}, d);
                obj.unique_farms = 1;
                obj.unique_fields = 1;
                dataGrouped.push(obj);
            }
        });

        // Next - If field_acres is less than 10% of managed_acres use field_acres otherwise use greater value
        dataGrouped.forEach(function (dG) {
            if (!dG.managed_acres) {
                dG.managed_acres = dG.field_acres;
            }
            var percent = dG.field_acres / dG.managed_acres;
            if (percent < 0.1 || percent > 1) {
                dG.managed_acres_calc = dG.field_acres;
            } else {
                dG.managed_acres_calc = dG.managed_acres;
            }
        });

        // Then group farmers together on year+crop
        var dataFinal = [];
        dataGrouped.forEach(function (dG) {
            var hit = false;
            dataFinal.forEach(function (dF) {
                if (dG.year === dF.year && dG.crop_label === dF.crop_label) {
                    hit = true;
                    if (!dF.farmer_ids.includes(dG.crop_user_id)) {
                        dF.farmer_ids.push(dG.crop_user_id);
                    }
                    dF.unique_farmers = dF.farmer_ids.length;
                    dF.managed_acres = dF.managed_acres + dG.managed_acres;
                    dF.managed_acres_calc = dF.managed_acres_calc + dG.managed_acres_calc;
                    dF.field_acres = dF.field_acres + dG.field_acres;
                    dF.unique_farms = dF.unique_farms + dG.unique_farms;
                    dF.unique_fields = dF.unique_fields + dG.unique_fields;
                }
            });
            if (!hit) {
                var obj = Object.assign({}, dG);
                obj.unique_farmers = 1;
                obj.farmer_ids = [dG.crop_user_id];
                dataFinal.push(obj);
            }
        });
        return dataFinal;
    };

    render() {
        const { data, classes } = this.props;
        const { order, orderBy, rowsPerPage, page } = this.state;
        const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

        var dataGrouped = this.renderTable();

        return (
            <Table>
                <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={this.handleRequestSort}
                    columnData={columnData2}
                />
                <TableBody>
                    {dataGrouped.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((a) => {
                        return (
                            <TableRow hover key={a.id}>
                                <CustomTableCell>{a.year}</CustomTableCell>
                                <CustomTableCell>{a.crop_label}</CustomTableCell>
                                <CustomTableCell>{a.unique_farmers}</CustomTableCell>
                                <CustomTableCell>{a.unique_farms}</CustomTableCell>
                                <CustomTableCell>{a.unique_fields}</CustomTableCell>
                                <CustomTableCell>
                                    {Number(a.field_acres).toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    })}
                                </CustomTableCell>
                                <CustomTableCell>
                                    {Number(a.managed_acres).toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    })}
                                </CustomTableCell>
                                <CustomTableCell>
                                    {Number(a.managed_acres_calc).toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    })}
                                </CustomTableCell>
                            </TableRow>
                        );
                    })}
                    {emptyRows > 0 && dataGrouped.length > 10 && (
                        <TableRow style={{ height: 48 * emptyRows }}>
                            <CustomTableCell colSpan={7} />
                        </TableRow>
                    )}
                    {dataGrouped.length < 1 && (
                        <TableRow>
                            <CustomTableCell colSpan={7} className={classes.centerAlign}>
                                No Crops Found
                            </CustomTableCell>
                        </TableRow>
                    )}
                </TableBody>
                {dataGrouped.length > 10 && (
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                colSpan={5}
                                count={dataGrouped.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                classes={{
                                    caption: classes.body1,
                                }}
                                onPageChange={this.handleChangePage}
                                onRowsPerPageChange={this.handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActionsWrapped}
                            />
                        </TableRow>
                    </TableFooter>
                )}
            </Table>
        );
    }
}

const columnData3 = [
    { id: "farm_name", numeric: false, label: "Farm", allowSort: true },
    { id: "field_label", numeric: false, label: "Field", allowSort: true },
    { id: "crop_user_label", numeric: true, label: "Farmer", allowSort: true },
    { id: "year", numeric: true, label: "Year", allowSort: true },
    { id: "crop_label", numeric: false, label: "Crop", allowSort: true },
    { id: "field_acres", numeric: true, label: "Entered Acres", allowSort: true },
    { id: "is_final", numeric: false, label: "Data Status", allowSort: true },
    { id: "metrics_version", numeric: false, label: "Metrics", allowSort: true },
];

function MetricValue({ version, current }) {
    if (current) {
        return version;
    } else {
        return <span style={{ color: "red" }}>{version || "Not Calculated"}</span>;
    }
}

class CropLibrary extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            order: "desc",
            orderBy: "year",
            page: 0,
            rowsPerPage: 10,
        };
    }

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        order === "desc"
            ? this.props.cropYears.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
            : this.props.cropYears.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));

        this.setState({ order, orderBy });
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: event.target.value });
    };

    render() {
        const { cropYears, classes } = this.props;

        const { order, orderBy, rowsPerPage, page } = this.state;
        const emptyRows = rowsPerPage - Math.min(rowsPerPage, cropYears.length - page * rowsPerPage);

        return (
            <Table>
                <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={this.handleRequestSort}
                    columnData={columnData3}
                />
                <TableBody>
                    {cropYears.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((cropYear) => (
                        <TableRow key={cropYear.id} hover>
                            <CustomTableCell>{cropYear.farm_name}</CustomTableCell>
                            <CustomTableCell>{cropYear.field_label}</CustomTableCell>
                            <CustomTableCell>{cropYear.crop_user_label}</CustomTableCell>
                            <CustomTableCell>{cropYear.year}</CustomTableCell>
                            <CustomTableCell>{cropYear.crop_label}</CustomTableCell>
                            <CustomTableCell>{cropYear.field_acres.toFixed(2)}</CustomTableCell>
                            <CustomTableCell>{cropYear.is_final ? "Final" : "Provisional"}</CustomTableCell>
                            <CustomTableCell>
                                <MetricValue version={cropYear.metrics_version} current={cropYear.metrics_current} />
                            </CustomTableCell>
                        </TableRow>
                    ))}
                    {emptyRows > 0 && cropYears.length > 10 && (
                        <TableRow style={{ height: 48 * emptyRows }}>
                            <CustomTableCell colSpan={6} />
                        </TableRow>
                    )}
                    {cropYears.length < 1 && (
                        <TableRow>
                            <CustomTableCell colSpan={6} className={classes.centerAlign}>
                                No Valid Crop Years Entered
                            </CustomTableCell>
                        </TableRow>
                    )}
                </TableBody>
                {cropYears.length > 10 && (
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                colSpan={6}
                                count={cropYears.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                classes={{
                                    caption: classes.body1,
                                }}
                                onPageChange={this.handleChangePage}
                                onRowsPerPageChange={this.handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActionsWrapped}
                            />
                        </TableRow>
                    </TableFooter>
                )}
            </Table>
        );
    }
}

const columnData4 = [
    { id: "detail", numeric: false, label: "Benchmark Details", allowSort: true },
    { id: "value", numeric: false, label: "Value", allowSort: true },
];

class BenchmarkDetails extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            order: "desc",
            orderBy: "detail",
            page: 0,
            rowsPerPage: 10,
        };
    }

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        order === "desc"
            ? this.props.cropYears.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
            : this.props.cropYears.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));

        this.setState({ order, orderBy });
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: event.target.value });
    };

    render() {
        const { details, classes } = this.props;

        const { order, orderBy, rowsPerPage, page } = this.state;
        const emptyRows = rowsPerPage - Math.min(rowsPerPage, details.length - page * rowsPerPage);

        return (
            <Table>
                <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={this.handleRequestSort}
                    columnData={columnData4}
                />
                <TableBody>
                    {details.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(function (detail) {
                        return (
                            <TableRow key={detail.id} hover>
                                <CustomTableCell>{detail.label}</CustomTableCell>
                                <CustomTableCell>{detail.value}</CustomTableCell>
                            </TableRow>
                        );
                    })}
                    {emptyRows > 0 && details.length > 10 && (
                        <TableRow style={{ height: 48 * emptyRows }}>
                            <CustomTableCell colSpan={6} />
                        </TableRow>
                    )}
                    {details.length < 1 && (
                        <TableRow>
                            <CustomTableCell colSpan={6} className={classes.centerAlign}>
                                No Details
                            </CustomTableCell>
                        </TableRow>
                    )}
                </TableBody>
                {details.length > 10 && (
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                colSpan={6}
                                count={details.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                classes={{
                                    caption: classes.body1,
                                }}
                                onPageChange={this.handleChangePage}
                                onRowsPerPageChange={this.handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActionsWrapped}
                            />
                        </TableRow>
                    </TableFooter>
                )}
            </Table>
        );
    }
}

const columnData5 = [
    { id: "metric", numeric: false, label: "Metric", allowSort: true },
    { id: "value", numeric: true, label: "Project Benchmark", allowSort: true },
];

class BenchmarkValues extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            order: "desc",
            orderBy: "metric",
            page: 0,
            rowsPerPage: 10,
        };
    }

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        order === "desc"
            ? this.props.benchmarks.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
            : this.props.benchmarks.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));

        this.setState({ order, orderBy });
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: event.target.value });
    };

    render() {
        const { benchmarks, classes } = this.props;

        const { order, orderBy, rowsPerPage, page } = this.state;
        const emptyRows = rowsPerPage - Math.min(rowsPerPage, benchmarks.length - page * rowsPerPage);

        return (
            <Table>
                <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={this.handleRequestSort}
                    columnData={columnData5}
                />
                <TableBody>
                    {benchmarks.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((benchmark) => (
                        <TableRow key={benchmark.id} hover>
                            <CustomTableCell>{benchmark.metric}</CustomTableCell>
                            <CustomTableCell>{benchmark.value}</CustomTableCell>
                        </TableRow>
                    ))}
                    {emptyRows > 0 && benchmarks.length > 10 && (
                        <TableRow style={{ height: 48 * emptyRows }}>
                            <CustomTableCell colSpan={6} />
                        </TableRow>
                    )}
                    {benchmarks.length < 1 && (
                        <TableRow>
                            <CustomTableCell colSpan={6} className={classes.centerAlign}>
                                No Details
                            </CustomTableCell>
                        </TableRow>
                    )}
                </TableBody>
                {benchmarks.length > 10 && (
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                colSpan={6}
                                count={benchmarks.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                classes={{
                                    caption: classes.body1,
                                }}
                                onPageChange={this.handleChangePage}
                                onRowsPerPageChange={this.handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActionsWrapped}
                            />
                        </TableRow>
                    </TableFooter>
                )}
            </Table>
        );
    }
}

const rotationSystemColumnData = [
    { id: "name", numeric: false, label: "Name", allowSort: true },
    { id: "user_id", numeric: false, label: "Owner", allowSort: true },
    { id: "template_name", numeric: false, label: "NRCS Template Name", allowSort: true },
    { id: "modify_date", numeric: false, label: "Last Updated", allowSort: true, isDate: true },
];

class ProjectRotationSystems extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            order: "asc",
            orderBy: "",
            page: 0,
            rowsPerPage: 10,
            useEmptyRows: false,
        };
    }

    componentDidMount() {
        this.setState({
            orderBy: this.props.orderBy,
            rowsPerPage: this.props.rowsPerPage ? this.props.rowsPerPage : 10,
            // rowsPerPage: this.props.pagination ? 10 : this.props.data.length + 1
        });
    }

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        this.setState({ order, orderBy });
    };

    incomingSort = () => {
        const { orderBy, order } = this.state;

        if (!orderBy) {
            return this.props.data;
        } else {
            function sniff(data) {
                function isNumber(n) {
                    return !isNaN(parseFloat(n)) && isFinite(n);
                }

                let isString = true;
                data.forEach((datum) => {
                    if (isNumber(datum[orderBy])) {
                        isString = false;
                    }
                });

                return isString;
            }

            function cleanValue(val) {
                return val ? val.toLowerCase() : "";
            }

            const isString = sniff(this.props.data);

            let data = [];

            if (isString) {
                data =
                    order === "desc"
                        ? this.props.data.sort((a, b) => (cleanValue(b[orderBy]) < cleanValue(a[orderBy]) ? -1 : 1))
                        : this.props.data.sort((a, b) => (cleanValue(a[orderBy]) < cleanValue(b[orderBy]) ? -1 : 1));
            } else {
                data =
                    order === "desc"
                        ? this.props.data.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
                        : this.props.data.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));
            }

            return data;
        }
    };

    getActionCount = (actions) => {
        var count = 0;
        actions.forEach((a) => {
            count += a ? Number(true) : Number(false);
        });
        // var a = onView ? Number(true) : Number(false)
        return count;
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: event.target.value });
    };

    formatDate(dateString) {
        if (!dateString) {
            return null;
        }
        const d = new Date(dateString);
        return d.toLocaleDateString();
    }

    getTip(toolTips, column, rowData) {
        console.log("getTip");

        if (toolTips) {
            let tips = toolTips.filter(function (el) {
                return el.column === column.id;
            });

            if (tips.length) {
                return rowData[tips[0].tip];
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    render() {
        const {
            classes,
            columnData,
            data,
            noRecordsMessage,
            onView,
            onCopy,
            onDownload,
            onSave,
            onEdit,
            onDelete,
            toolTips,
            noPagination,
            useEmptyRows,
        } = this.props;
        const { order, orderBy, page } = this.state;

        const sortedData = this.incomingSort(data);

        const actions = onView || onCopy || onDownload || onSave || onEdit || onDelete ? true : false;
        const actionsCount = this.getActionCount([onView, onCopy, onDownload, onSave, onEdit, onDelete]);
        const actionColumnWidth = actionsCount * 44 + "px";

        const rowsPerPage = noPagination === true ? data.length + 2 : this.state.rowsPerPage;

        const emptyRows =
            noPagination === true || useEmptyRows === false
                ? 0
                : rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

        return (
            <Table className={classes.table}>
                <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={this.handleRequestSort}
                    columnData={columnData}
                    actions={actions}
                    actionColumnWidth={actionColumnWidth}
                />
                <TableBody>
                    {sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((n) => {
                        return (
                            <TableRow hover role="checkbox" tabIndex={-1} key={n.id}>
                                {actions && (
                                    <CustomTableCell style={{ width: actionColumnWidth }}>
                                        {n.editable ? (
                                            <>
                                                {onView && (
                                                    <Tooltip title="View">
                                                        <Button
                                                            onClick={() => onView(n.id)}
                                                            className={classes.deleteWidth}
                                                        >
                                                            <RemoveRedEyeIcon color="primary" />
                                                        </Button>
                                                    </Tooltip>
                                                )}
                                                {onCopy && (
                                                    <Tooltip title="Copy">
                                                        <Button
                                                            onClick={() => onCopy(n.id)}
                                                            className={classes.deleteWidth}
                                                        >
                                                            <ContentCopyIcon color="primary" />
                                                        </Button>
                                                    </Tooltip>
                                                )}
                                                {onDownload && (
                                                    <Tooltip title="Download">
                                                        <Button
                                                            onClick={() => onDownload(n.id)}
                                                            className={classes.deleteWidth}
                                                        >
                                                            <FileDownloadIcon color="primary" />
                                                        </Button>
                                                    </Tooltip>
                                                )}
                                                {onSave && (
                                                    <Tooltip title="Save">
                                                        <Button
                                                            //  onClick={() => onSave(n.id)}
                                                            className={classes.deleteWidth}
                                                        >
                                                            <ContentCopyIcon color="primary" />
                                                        </Button>
                                                    </Tooltip>
                                                )}
                                                {onEdit && (
                                                    <Tooltip title="Edit">
                                                        <Button
                                                            // onClick={() => onEdit(n.id)}
                                                            className={classes.deleteWidth}
                                                        >
                                                            <EditIcon color="primary" />
                                                        </Button>
                                                    </Tooltip>
                                                )}
                                                {onDelete && (
                                                    <Tooltip title="Delete">
                                                        <Button
                                                            disabled={n.has_cropyear}
                                                            onClick={() => onDelete(n.id)}
                                                            className={classes.deleteWidth}
                                                        >
                                                            {!n.has_cropyear && <DeleteIcon color="primary" />}
                                                            {n.has_cropyear && <DeleteIcon />}
                                                        </Button>
                                                    </Tooltip>
                                                )}
                                            </>
                                        ) : (
                                            <Tooltip title="View">
                                                <Button onClick={() => onView(n.id)} className={classes.deleteWidth}>
                                                    <RemoveRedEyeIcon color="primary" />
                                                </Button>
                                            </Tooltip>
                                        )}
                                    </CustomTableCell>
                                )}
                                {columnData.map((column) => {
                                    if (!column.isAction) {
                                        return (
                                            <CustomTableCell
                                                style={{ width: "auto" }}
                                                // key={column.id + "_" + n.id}
                                                numeric={column.numeric}
                                                padding={column.disablePadding === true ? "none" : "default"}
                                                className={column.center === true ? classes.center : ""}
                                                title={this.getTip(toolTips, column, n)}
                                            >
                                                {column.isDate ? this.formatDate(n[column.id]) : n[column.id]}
                                            </CustomTableCell>
                                        );
                                    } else {
                                        return null;
                                    }
                                }, this)}
                            </TableRow>
                        );
                    })}
                    {(this.props.useEmptyRows === undefined || this.props.useEmptyRows === true) &&
                        emptyRows > 0 &&
                        data.length > 10 && (
                            <TableRow style={{ height: 48 * emptyRows }}>
                                <CustomTableCell colSpan={100} />
                            </TableRow>
                        )}
                    {data.length < 1 && (
                        <TableRow>
                            <CustomTableCell colSpan={100} className={classes.textCenter}>
                                {noRecordsMessage}
                            </CustomTableCell>
                        </TableRow>
                    )}
                </TableBody>
                {(noPagination === undefined || noPagination === false) && data.length > 10 && (
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                count={data.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={this.handleChangePage}
                                onRowsPerPageChange={this.handleChangeRowsPerPage}
                                Actions={TablePaginationActionsWrapped}
                            />
                        </TableRow>
                    </TableFooter>
                )}
            </Table>
        );
    }
}

class AdminDetails extends Component {
    state = {
        farmerFilter: null,
        cropFilter: null,
        finalFilter: null,
        yearFilter: null,
        selectedProjectBenchmarkGroup: null,
        isCalculating: false,
        rotationSystems: [],
        importTemplateDialogOpen: false,
        copySystemId: null,
        deleteSystemId: null,
        operations: [],
        crops: [],
        projectBenchmarksExpanded: true,
        metricsVersionCheckExpanded: true,
        metrics_api_version: "",
        recalculating: false,
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        this.loadProject({});
        this.loadCropAndOperationData();
    }

    componentDidUpdate(prevProps) {
        this.loadProject(prevProps);
    }

    loadProject(prevProps) {
        const { project, ormProjectLoadDetail, syncState } = this.props,
            { project: lastProject, syncState: lastSyncState } = prevProps;

        if (!project) {
            return;
        }
        if (!lastProject || lastProject.id !== project.id || !lastSyncState.ready) {
            if (syncState.ready && !getValue(project, "synced")) {
                ormProjectLoadDetail(project.id);
            }
        }
    }

    loadCropAndOperationData() {
        let that = this;
        Promise.all([utils.CropsRequest, utils.OperationsRequest]).then(function (values) {
            let operations = values[1]["result"][0]["value"]["operations"],
                crops = values[0];

            if (crops[0] === "error") {
                crops = [];
            }

            crops.forEach((crop) => {
                crop["show"] = true;
            });

            that.setState({
                operations: operations,
                crops: crops,
            });
        });

        apiFetch("/v4/calc/Version")
            .then((response) => response.json())
            .then((responseJson) => {
                const version_alias = responseJson.alias;
                const regex = /^\d+\.\d/;
                const match = regex.exec(version_alias);
                if (match) {
                    const metrics_api_version = match[0];
                    this.setState({ metrics_api_version });
                }
            });
    }

    copyStats = () => {
        var textField = document.createElement("textarea");
        textField.innerHTML = this.statsTable.getCSV();
        document.body.appendChild(textField);
        textField.select();
        document.execCommand("copy");
        textField.remove();
    };

    copyOverview = () => {
        var textField = document.createElement("textarea");
        textField.innerHTML = this.overviewTable.getCSV();
        document.body.appendChild(textField);
        textField.select();
        document.execCommand("copy");
        textField.remove();
    };

    handleSpidergramSwitchChange = (name) => (event) => {
        this.props.ormProjectUpdate({
            id: this.props.project.id,
            show_benchmarks: event.target.checked,
        });
    };

    runProjectBenchmarks = () => {
        this.setState({ isCalculating: true });
        var { project, reload } = this.props;
        dbFetch("/reports/" + project.id + "/benchmarks", {
            method: "POST",
            body: JSON.stringify({ report_type: "benchmarks", update_only: true }),
        }).then((response) => {
            reload();
            this.setState({ isCalculating: false });
        });
    };

    handleSearchNrcsLmodClick = () => {
        this.props.history.push("/rotation/search/project/" + this.props.match.params.id);
    };

    handleImportTemplateClick = () => {
        this.setState({ importTemplateDialogOpen: true });
    };

    handleImportTemplateCancelClick = () => {
        this.setState({ importTemplateDialogOpen: false });
    };

    handleViewSystemClick = (systemId) => {
        const { rotationSystems } = this.props,
            system = rotationSystems.find((s) => s.id === systemId);
        if (system && system.editable) {
            this.props.history.push("/rotation/" + systemId + "/project/" + this.props.project.id);
        } else {
            this.setState({ previewSystemId: systemId });
            this.props.ormProjectRotationSystemLoadDetail(systemId);
        }
    };

    handleViewSystemCloseClick = () => {
        this.setState({ previewSystemId: null });
    };

    handleCopySystemClick = (systemId) => {
        this.setState({
            copySystemId: systemId,
        });
    };

    handleDownloadSystemClick = (systemId) => {
        let managementSystem = this.props.rotationSystems.filter((system) => system.id === systemId)[0];

        //  remove unwanted info in the events
        const events = managementSystem.events.map((evt) => {
            // Make a copy first or the Redux state will be broken! (#576)
            let e = { ...evt };
            ["modify_user", "modify_date", "id", "system"].forEach((key) => {
                delete e[key];
            });
            return e;
        });

        var rotation = { managements: [{ events }] };
        var text = JSON.stringify(rotation);
        var filename = managementSystem.name + ".rot";
        var blob = new Blob([text], { type: "text/plain;charset=utf-8" });
        saveAs(blob, filename);
    };

    confirmCopy = () => {
        this.copySystem(this.state.copySystemId);
    };

    cancelCopy = () => {
        this.setState({ copySystemId: null });
    };

    addBlankRotationSystem = () => {
        var systemId = utils.createBlankRotationSystem(this.props.ormRotationSystemCreate, this.props.match.params.id);
        this.props.history.push("/rotation/" + systemId + "/project/" + this.props.match.params.id);
    };

    copySystem = (systemId) => {
        let rotationSystem = this.props.rotationSystems.filter((system) => system.id === systemId)[0];

        let rotationSystemObj = {
            name: "Copy of " + rotationSystem.name,
            template: rotationSystem.id,
            template_name: rotationSystem.template_name,
        };

        let events = rotationSystem.events.map((event) => {
            return {
                date: event.date,
                operation: event.operation,
                crop: event.crop,
                residue: event.residue,
            };
        });

        const parameters = {
            ormRotationSystemCreate: this.props.ormRotationSystemCreate,
            ormRotationEventCreate: this.props.ormRotationEventCreate,
            ormRotationSystemUpdate: this.props.ormRotationSystemUpdate,
            rotationSystem: rotationSystemObj,
            events: events,
            projectIds: [this.props.project.id],
            operations: this.state.operations,
            crops: this.state.crops,
        };

        const newSystemId = utils.createRotationSystemAndEvents(parameters);

        this.setState({
            copySystemId: null,
        });

        if (newSystemId) {
            this.props.history.push("/rotation/" + newSystemId + "/project/" + this.props.project.id);
        }
    };

    handleDeleteSystemClick = (systemId) => {
        const { rotationSystems } = this.props,
            system = rotationSystems.find((s) => s.id === systemId);
        if (system && system.editable) {
            this.setState({ deleteSystemId: systemId });
        }
    };

    okDeleteSystem = () => {
        this.props.ormRotationSystemDelete(this.state.deleteSystemId);
        this.setState({ deleteSystemId: null });
    };

    cancelDeleteSystem = () => {
        this.setState({ deleteSystemId: null });
    };

    submitRecalculateData = () => {
        var { history, authLogout } = this.props;
        const project_id = this.props.project.id;
        const url = "/reports/" + project_id + "/recalculate";
        const data = {
            project_name: this.props.project.name,
            metrics_api_version: this.state.metrics_api_version,
        };

        dbFetch(url, {
            method: "POST",
            body: JSON.stringify(data),
        }).then((e) => {
            if (e.status === 401) {
                history.push("/");
                authLogout();
            } else {
                e.json().then((data) => {
                    console.log(data);
                });
            }
        });

        this.setState({ recalculating: true });
    };

    render() {
        var { classes, project, authState, rotationSystems } = this.props;
        const {
            farmerFilter,
            cropFilter,
            finalFilter,
            metricsFilter,
            yearFilter,
            selectedProjectBenchmarkGroup,
            isCalculating,
            importTemplateDialogOpen,
            copySystemId,
            deleteSystemId,
            previewSystemId,
        } = this.state;

        const previewSystem = previewSystemId ? rotationSystems.find((s) => s.id === previewSystemId) : null;

        if (!project || project.name === "Not Found") return "Not Found";

        var isProjectAdmin =
            authState.user.access_user_info ||
            project.contacts.some(
                (contact) =>
                    // Set admin flag if user is in participants as an admin/specialist
                    authState.user.id === contact.user_id &&
                    (contact.contact_role === "PA" || contact.contact_role === "PP"),
            );

        function makeOptions(table) {
            table = table.map((row) => ({
                label: row.name ? row.name : row.id,
                value: row.id,
                key: row.id,
            }));
            table.unshift({ label: <b>Clear Filter</b>, value: null, key: null });
            return table;
        }

        function makeYearOptions(years) {
            years = years.map((row) => ({
                label: row,
                value: row,
                key: row,
            }));
            years.unshift({ label: <b>Clear Filter</b>, value: null, key: null });
            return years;
        }

        // Populate filters and filter Field Crop-Year Associations
        var project_data = Object.assign([], project.project_data);

        var farmers = [];
        project_data.forEach(function (cY) {
            if (farmers.filter((f) => f.id === cY.crop_user_id.toString()).length === 0) {
                farmers.push({ name: cY.crop_user_label, id: cY.crop_user_id.toString() });
            }
        });
        farmers.sort((a, b) => (a["name"] < b["name"] ? -1 : 1));

        var years = [];
        project_data.forEach(function (cY) {
            if (!years.includes(cY.year)) years.push(cY.year);
        });
        years.sort();

        var crops = [];
        project_data.forEach(function (cY) {
            if (crops.filter((f) => f.id === cY.crop_id.toString()).length === 0) {
                crops.push({ name: cY.crop_label, id: cY.crop_id.toString() });
            }
        });
        crops.sort((a, b) => (a["name"] < b["name"] ? -1 : 1));

        if (farmerFilter) {
            project_data = project_data.filter((cY) => cY.crop_user_id.toString() === farmerFilter);
        }
        if (yearFilter) {
            project_data = project_data.filter((cY) => cY.year === yearFilter);
        }
        if (cropFilter) {
            project_data = project_data.filter((cY) => cY.crop_id.toString() === cropFilter);
        }
        // values have to be strings
        if (finalFilter === "true") {
            project_data = project_data.filter((cY) => cY.is_final === true);
        } else if (finalFilter === "false") {
            project_data = project_data.filter((cY) => cY.is_final === false);
        }

        if (metricsFilter === "current") {
            project_data = project_data.filter((cY) => cY.metrics_current);
        } else if (metricsFilter === "old") {
            project_data = project_data.filter((cY) => cY.metrics_version && !cY.metrics_current);
        } else if (metricsFilter === "none") {
            project_data = project_data.filter((cY) => !cY.metrics_version);
        }

        const synced = getValue(project, "synced");

        function getPossibleProjectBenchmarkGroups(p, metric_data) {
            var possible_benchmarks = [];
            var crop_ids = [];
            var crops = [];

            if (!p.project_data) {
                return [];
            }

            var cropyears_final_metrics = p.project_data.filter((c) => c.include_in_reports);

            cropyears_final_metrics.forEach(function (d) {
                if (crop_ids.indexOf(d.crop_id) === -1) {
                    crop_ids.push(d.crop_id);
                    crops.push({ id: d.crop_id, label: d.crop_label });
                }
            });

            crops.forEach(function (crop) {
                var cropyears_final_metrics_crop = cropyears_final_metrics.filter((d) => {
                    return d.crop_id === crop.id;
                });

                [true, false].forEach(function (irrigated) {
                    var cropyears = cropyears_final_metrics_crop.filter((c) => {
                        return c.is_irrigated === irrigated;
                    });

                    var crop_user_ids = [];
                    cropyears.forEach(function (cy) {
                        if (crop_user_ids.indexOf(cy.crop_user_id) === -1) {
                            crop_user_ids.push(cy.crop_user_id);
                        }
                    });

                    if (crop_user_ids.length >= 10) {
                        var value = [irrigated ? "irrigated" : "rainfed", crop.label].join(" ");
                        var label = [irrigated ? "Irrigated" : "Rainfed", crop.label].join(" ");
                        possible_benchmarks.push({
                            irrigated: irrigated,
                            crop_id: crop.id,
                            crop_label: crop.label,
                            id: value,
                            value: value,
                            key: value,
                            label: label,
                        });
                    }
                });
            });
            return possible_benchmarks;
        }

        function getCalculatedProjectBenchmarks(project) {
            var options = project.project_benchmark_group.map((group) => ({
                irrigated: group.irrigated,
                crop_id: group.crop_id,
                crop_label: group.crop_label,
                id: group.label,
                value: group.label,
                key: group.label,
                label: group.label,
            }));
            // options.unshift({label: <b>Clear Filter</b>, value: null, key: null});
            return options;
        }

        function getSelectedProjectBenchmarkGroupData(project, selectedProjectBenchmarkGroup) {
            var benchmark_order = [
                "landUse",
                "soilConservation",
                "soilCarbon",
                "irrigationWaterUse",
                "energyUse",
                "greenhouseGas",
                "waterQuality",
                "biodiversity",
            ];

            var project_benchmarks = project.project_benchmark_group.filter((group) => {
                return group.label === selectedProjectBenchmarkGroup;
            })[0]["project_benchmarks"];

            var data = [];
            benchmark_order.forEach(function (benchmark_metric_code) {
                var project_benchmark = project_benchmarks.filter((b) => benchmark_metric_code === b.metric)[0];
                if (project_benchmark) {
                    var metric_code = project_benchmark.metric;
                    var metric_label = project_benchmark.metric_label;

                    var value = round(project_benchmark.value, metric_code);
                    if (metric_code === "biodiversity") {
                        value = project_benchmark.value * 100;
                        value = value.toFixed(1);
                    }
                    var metric =
                        project_benchmark.units === "dimensionless"
                            ? metric_label
                            : metric_label + " (" + project_benchmark.units + ")";
                    if (metric_code === "biodiversity") {
                        metric = metric_label + " (%)";
                    }
                    data.push({
                        id: project_benchmark.id,
                        value: value,
                        metric: metric,
                        metric_code: metric_code,
                    });
                }
            });
            return data;
        }

        function getSelectedProjectBenchmarkGroupDetails(project, selectedProjectBenchmarkGroup) {
            if (!project || !project.project_benchmark_group) {
                return [];
            }

            var fields = [
                { id: "years_included", label: "Years Included" },
                {
                    id: "grower_count",
                    label: "Total Growers Across All Years",
                },
                { id: "field_count", label: "Total Fields Across All years" },
                { id: "modify_date", label: "Last Updated" },
            ];

            //  handle if there is a group but no benchmarks (user will need to re-run benchmarks)
            var detail_object = project.project_benchmark_group.filter((group) => {
                return group.label === selectedProjectBenchmarkGroup;
            })[0]["project_benchmarks"][0];

            if (detail_object) {
                var details = fields.map((field) => {
                    if (field === "modify_date") {
                        return {
                            id: field.id,
                            value: detail_object[field.id],
                            label: field.label,
                        };
                    } else {
                        return {
                            id: field.id,
                            value: detail_object[field.id],
                            label: field.label,
                        };
                    }
                });
                return details;
            } else {
                return [];
            }
        }

        const possibleProjectBenchmarkGroups = project ? getPossibleProjectBenchmarkGroups(project, project_data) : [];
        const calculatedProjectBenchmarkGroups = project ? getCalculatedProjectBenchmarks(project) : [];
        const selectedProjectBenchmarkGroupAlreadyCalculated =
            calculatedProjectBenchmarkGroups.filter((group) => {
                return group.label === selectedProjectBenchmarkGroup;
            }).length > 0;
        const selectedProjectBenchmarkGroupDetails = selectedProjectBenchmarkGroupAlreadyCalculated
            ? getSelectedProjectBenchmarkGroupDetails(project, selectedProjectBenchmarkGroup)
            : [];
        const selectedProjectBenchmarkGroupData = selectedProjectBenchmarkGroupAlreadyCalculated
            ? getSelectedProjectBenchmarkGroupData(project, selectedProjectBenchmarkGroup)
            : [];

        const showLongWait = !!(isCalculating && project.project_data && project.project_data.length > 3000);

        const helpDialogText = copySystemId
            ? "Are you sure you want to copy rotation system: " +
              rotationSystems.filter((s) => s.id === copySystemId)[0].name +
              " - " +
              rotationSystems.filter((s) => s.id === copySystemId)[0].template_name
            : "";

        const metricsInErrorCount =
            project && project.project_data ? project.project_data.filter((pd) => !pd.metrics_current).length : 0;

        return (
            <AppContainer
                authenticated
                synced={!synced}
                color="project"
                title={
                    <>
                        <Button
                            className={classes.reportButton}
                            color="primary"
                            component={Link}
                            disabled={project_data.length === 0}
                            to={"/project/reports/" + project.id}
                        >
                            Reporting
                        </Button>
                        <Button
                            className={classes.reportButton}
                            color="primary"
                            component={Link}
                            to={`/projectadmin/${project.id}/manage`}
                        >
                            Manage Project
                        </Button>
                        {project.name}
                    </>
                }
                pageTitle={`Admin for ${project.name}`}
                crumbs={
                    <div>
                        <Link className={classes.linkColor} to={"/projectadmin"}>
                            Project Admin Dashboard
                        </Link>
                        &nbsp;&nbsp;&nbsp;&gt;&nbsp;&nbsp;&nbsp;{" "}
                        <span className={classes.crumbColor}>{project.name}</span>
                    </div>
                }
            >
                <ImportTemplateDialog
                    open={importTemplateDialogOpen}
                    admin={true}
                    crops={this.state.crops}
                    projectOptions={this.props.projects.map((p) => {
                        return { id: p.id.toString(), name: p.name };
                    })}
                    onImportTemplateSaveClick={this.handleImportTemplateSaveClick}
                    onImportRotationSystemToProject={this.handleImportRotationSystemToProject}
                    onImportTemplateCancelClick={this.handleImportTemplateCancelClick}
                    ormRotationSystemCreate={this.props.ormRotationSystemCreate}
                    ormRotationEventCreate={this.props.ormRotationEventCreate}
                    ormRotationSystemUpdate={this.props.ormRotationSystemUpdate}
                />

                <HelpDialog
                    open={!!copySystemId}
                    onClose={() => this.setState({ copySystemId: null })}
                    question="Copy Crop Rotation Template"
                    text={helpDialogText}
                    confirmAction={this.confirmCopy}
                    cancelAction={this.cancelCopy}
                />

                {!!deleteSystemId && (
                    <WarningDialog
                        confirmText={"Yes"}
                        confirmAction={this.okDeleteSystem}
                        cancelText={"No"}
                        cancelAction={this.cancelDeleteSystem}
                        open={!!deleteSystemId}
                        title="Delete Rotation System"
                        text={"Are you sure you want to delete this rotation system?"}
                    />
                )}

                <Grid container spacing={24}>
                    <ProjectFacts project={project} />
                    <Grid item xs={12}>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="display2">Project Team</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <ProjectTeam project={project} />
                            </AccordionDetails>
                        </Accordion>
                    </Grid>

                    <Grid item xs={12}>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="display2">Manage Project Data</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={24}>
                                    <Grid item xs={12}>
                                        <Typography>
                                            The following options help you manage Fieldprint Project data
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="title" gutterBottom style={{ fontWeight: 600 }}>
                                            Project Benchmarks{" "}
                                            <IconButton
                                                color="primary"
                                                style={{ float: "right", marginTop: "-12px" }}
                                                onClick={() =>
                                                    this.setState({
                                                        projectBenchmarksExpanded:
                                                            !this.state.projectBenchmarksExpanded,
                                                    })
                                                }
                                                size="large"
                                            >
                                                {this.state.projectBenchmarksExpanded ? (
                                                    <RemoveCircleIcon />
                                                ) : (
                                                    <AddCircleIcon />
                                                )}
                                            </IconButton>
                                        </Typography>
                                    </Grid>
                                    <Collapse
                                        in={this.state.projectBenchmarksExpanded}
                                        timeout="auto"
                                        style={{ paddingLeft: "12px", paddingRight: "12px" }}
                                    >
                                        <Grid item xs={12}>
                                            <Typography gutterBottom>
                                                A Project Benchmark is a value for each metric calculated based on the
                                                current performance of fields and growers enrolled in a Project. Project
                                                Benchmarks should be used with growers in the Project as comparison
                                                points to evaluate how their scores relate to those of other growers in
                                                the same Project. Project Benchmarks are crop and project specific and
                                                calculated separately for both irrigated and non-irrigated production. A
                                                Project must have a minimum of ten farmer participants before Project
                                                Benchmarks are calculated. Project Benchmarks are calculated on a
                                                running or cumulative basis starting in Year 1 and with a maximum of a
                                                five-year average (using the most recent years). Note that a Project
                                                Benchmark cannot be calculated for Soil Carbon.
                                            </Typography>

                                            {possibleProjectBenchmarkGroups.length > 0 && (
                                                <Form dontValidateOnMount="true" validateOnSubmit="true">
                                                    {(formApi) => (
                                                        <form>
                                                            {isProjectAdmin && (
                                                                <FormControlLabel
                                                                    style={{ marginLeft: 0, whiteSpace: "normal" }}
                                                                    control={
                                                                        <Switch
                                                                            checked={project.show_benchmarks}
                                                                            onChange={this.handleSpidergramSwitchChange()}
                                                                            value={project.show_benchmarks}
                                                                            color="secondary"
                                                                        />
                                                                    }
                                                                    label="Display Project Benchmarks for Growers on Spidergram?"
                                                                />
                                                            )}

                                                            <Grid
                                                                container
                                                                spacing={8}
                                                                className={classes.benchmarkSelectContainer}
                                                            >
                                                                <Grid item xs={12}>
                                                                    <Typography>
                                                                        Select one of the available Project Benchmarks.
                                                                        Remember a minimum of ten grower participants
                                                                        across all project years is required to
                                                                        calculate Project Benchmarks for a specific crop
                                                                        and irrigation type. Project Benchmarks may not
                                                                        be available.
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={6}
                                                                    sm={selectedProjectBenchmarkGroup ? 9 : 12}
                                                                >
                                                                    <Select
                                                                        className={classes.benchmarkSelect}
                                                                        eventHandle={(value) =>
                                                                            this.setState({
                                                                                selectedProjectBenchmarkGroup: value,
                                                                            })
                                                                        }
                                                                        field="dbenchmark"
                                                                        options={possibleProjectBenchmarkGroups}
                                                                        fullWidth
                                                                        margin="normal"
                                                                        disable={isCalculating}
                                                                    />
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={6}
                                                                    sm={selectedProjectBenchmarkGroup ? 3 : 0}
                                                                >
                                                                    {selectedProjectBenchmarkGroup &&
                                                                        selectedProjectBenchmarkGroupAlreadyCalculated && (
                                                                            <Button
                                                                                fullWidth
                                                                                className={classes.refreshButton}
                                                                                color="primary"
                                                                                onClick={() =>
                                                                                    this.runProjectBenchmarks()
                                                                                }
                                                                                disabled={
                                                                                    isCalculating ||
                                                                                    !selectedProjectBenchmarkGroup
                                                                                }
                                                                            >
                                                                                <RefreshIcon
                                                                                    className={classes.button}
                                                                                />{" "}
                                                                                <Typography>
                                                                                    Refresh Benchmarks
                                                                                </Typography>
                                                                            </Button>
                                                                        )}

                                                                    {selectedProjectBenchmarkGroup &&
                                                                        !selectedProjectBenchmarkGroupAlreadyCalculated && (
                                                                            <Button
                                                                                className={classes.refreshButton}
                                                                                color="primary"
                                                                                onClick={() =>
                                                                                    this.runProjectBenchmarks()
                                                                                }
                                                                                disabled={
                                                                                    isCalculating ||
                                                                                    !selectedProjectBenchmarkGroup
                                                                                }
                                                                            >
                                                                                <DialpadIcon
                                                                                    className={classes.button}
                                                                                />{" "}
                                                                                <Typography>
                                                                                    Calculate Benchmarks
                                                                                </Typography>
                                                                            </Button>
                                                                        )}
                                                                </Grid>
                                                            </Grid>
                                                        </form>
                                                    )}
                                                </Form>
                                            )}

                                            {!isCalculating &&
                                                selectedProjectBenchmarkGroup &&
                                                !selectedProjectBenchmarkGroupAlreadyCalculated && (
                                                    <Grid item xs={12}>
                                                        <p />
                                                        <Typography variant="title" gutterBottom>
                                                            Project benchmarks have not been calculated for{" "}
                                                            {selectedProjectBenchmarkGroup}
                                                        </Typography>
                                                    </Grid>
                                                )}

                                            {!isCalculating &&
                                                selectedProjectBenchmarkGroup &&
                                                selectedProjectBenchmarkGroupAlreadyCalculated &&
                                                selectedProjectBenchmarkGroupDetails.length === 0 && (
                                                    <Grid item xs={12}>
                                                        <Typography>
                                                            No project benchmarks found for{" "}
                                                            {selectedProjectBenchmarkGroup}.
                                                        </Typography>
                                                    </Grid>
                                                )}

                                            {!isCalculating &&
                                                selectedProjectBenchmarkGroup &&
                                                selectedProjectBenchmarkGroupDetails.length > 0 && (
                                                    <Grid item sx={12}>
                                                        <p />
                                                        <Typography variant="title" gutterBottom>
                                                            Project Benchmarks for {selectedProjectBenchmarkGroup}
                                                        </Typography>
                                                    </Grid>
                                                )}

                                            {!isCalculating &&
                                                selectedProjectBenchmarkGroup &&
                                                selectedProjectBenchmarkGroupDetails.length > 0 && (
                                                    <Grid item xs={12}>
                                                        <BenchmarkDetails
                                                            details={selectedProjectBenchmarkGroupDetails}
                                                            classes={classes}
                                                        />
                                                        <p />
                                                    </Grid>
                                                )}

                                            {!isCalculating &&
                                                selectedProjectBenchmarkGroup &&
                                                selectedProjectBenchmarkGroupData.length > 0 && (
                                                    <Grid item xs={12}>
                                                        <BenchmarkValues
                                                            benchmarks={selectedProjectBenchmarkGroupData}
                                                            classes={classes}
                                                        />
                                                    </Grid>
                                                )}

                                            {!isCalculating && possibleProjectBenchmarkGroups.length === 0 && (
                                                <Grid item xs={12}>
                                                    <Typography variant="title" gutterBottom>
                                                        No Project Benchmarks are currently available for this project.
                                                    </Typography>
                                                </Grid>
                                            )}

                                            {isCalculating && (
                                                <Grid item xs={12}>
                                                    {showLongWait && (
                                                        <Typography gutterBottom variant="title">
                                                            Calculating Project Benchmarks. There is a lot of data to
                                                            process. Thank you for your patience ...
                                                        </Typography>
                                                    )}
                                                    {!showLongWait && (
                                                        <Typography gutterBottom variant="title">
                                                            Calculating Project Benchmarks...
                                                        </Typography>
                                                    )}
                                                    <LinearProgress color="primary" />
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Collapse>
                                    <Grid
                                        item
                                        xs={12}
                                        // style={{
                                        //     marginTop: "12px",
                                        //     marginBottom: "12px",
                                        //     marginLeft: "0px",
                                        //     marginRight: "0px"
                                        // }}
                                    >
                                        <Divider />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="title" gutterBottom style={{ fontWeight: 600 }}>
                                            Metrics Version Check
                                            <IconButton
                                                color="primary"
                                                style={{ float: "right", marginTop: "-12px" }}
                                                onClick={() =>
                                                    this.setState({
                                                        metricsVersionCheckExpanded:
                                                            !this.state.metricsVersionCheckExpanded,
                                                    })
                                                }
                                                size="large"
                                            >
                                                {this.state.metricsVersionCheckExpanded ? (
                                                    <RemoveCircleIcon />
                                                ) : (
                                                    <AddCircleIcon />
                                                )}
                                            </IconButton>
                                        </Typography>
                                    </Grid>

                                    <Collapse
                                        in={this.state.metricsVersionCheckExpanded}
                                        timeout="auto"
                                        style={{ paddingLeft: "12px", paddingRight: "12px" }}
                                    >
                                        <Grid item xs={12}>
                                            <Typography gutterBottom>
                                                Prior to any reporting, it is important to confirm all project data has
                                                been calculated against the most recent metrics engine version [
                                                {this.state.metrics_api_version}]. It is possible that project data sets
                                                have a mixture of metric versions. Confirm below that all data is
                                                current and, if not, recalculate the project data.
                                            </Typography>

                                            {metricsInErrorCount > 0 ? (
                                                <div style={{ display: "flex", marginBottom: "12px" }}>
                                                    <div style={{ float: "left" }}>
                                                        <AnnouncementIcon
                                                            color="primary"
                                                            style={{ marginBottom: "13px", marginRight: "20px" }}
                                                        />
                                                    </div>
                                                    <div style={{ float: "left" }}>
                                                        <Typography color="primary" gutterBottom>
                                                            This project data set has {metricsInErrorCount} of{" "}
                                                            {project.project_data.length} crop years calculated on an
                                                            older metric version (or not at all). Please refresh the
                                                            data set prior to reporting. Note that this may update
                                                            individual grower Fieldprint results, including for
                                                            provisional crop years. Recalculating data can take anywhere
                                                            from a few minutes to an hour, depending on the size of the
                                                            data set. The system will send you an email notification
                                                            when calculations are complete.
                                                        </Typography>
                                                        <Button
                                                            variant="raised"
                                                            disabled={this.state.recalculating}
                                                            className={
                                                                this.state.recalculating
                                                                    ? classes.recalculateButtonDisabled
                                                                    : classes.recalculateButton
                                                            }
                                                            onClick={() => this.submitRecalculateData()}
                                                        >
                                                            Recalculate Data
                                                        </Button>
                                                        {this.state.recalculating && (
                                                            <>
                                                                <div style={{ float: "left" }}>
                                                                    <AnnouncementIcon
                                                                        color="primary"
                                                                        style={{
                                                                            marginBottom: "13px",
                                                                            marginRight: "20px",
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div style={{ float: "left" }}>
                                                                    <Typography color="primary" gutterBottom>
                                                                        Once requested, calculations will process in the
                                                                        background. The system will send a confirmation
                                                                        email when calculations are complete. If errors
                                                                        occur, a separate error report will be emailed.
                                                                        Note that you will need to wait until processing
                                                                        is complete and you may need to refresh this
                                                                        page before seeing updates to crop year metric
                                                                        versions.
                                                                    </Typography>
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            ) : (
                                                <div style={{ display: "flex" }}>
                                                    <div style={{ float: "left" }}>
                                                        <CheckCircleIcon
                                                            color="primary"
                                                            style={{
                                                                color: "#6D8D22",
                                                                marginBottom: "13px",
                                                                marginRight: "20px",
                                                            }}
                                                        />
                                                    </div>
                                                    <div style={{ float: "left" }}>
                                                        <Typography>
                                                            All project data as of today has been calculated using the
                                                            most recent metrics version.
                                                        </Typography>
                                                    </div>
                                                </div>
                                            )}
                                        </Grid>
                                    </Collapse>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>

                    <Grid item xs={12}>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="display2">Project Statistics</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography gutterBottom>
                                            Note that these totals can change as farmers associate and dissociate fields
                                            from the project. This table only includes crop years that are finalized and
                                            calculated with the latest metrics version.
                                            <Button
                                                color="primary"
                                                className={classes.floatRight}
                                                onClick={() => this.copyStats()}
                                                ref={(re) => {
                                                    this.cropRef = re;
                                                }}
                                            >
                                                <FileCopy className={classes.button} /> &nbsp;&nbsp;&nbsp;Copy to
                                                Clipboard
                                            </Button>
                                        </Typography>
                                        <ProjectData
                                            data={project.project_data ? project.project_data : []}
                                            classes={classes}
                                            onRef={(el) => (this.statsTable = el)}
                                        />
                                        <Typography className={classes.gutterTop}>
                                            Value for enrolled acres at the project level is the total of a) the sum of
                                            the entered acres for each farmer with less than 10% of their managed acres
                                            entered and b) the sum of the stated managed acres for each farmer with more
                                            than 10% of their managed acres entered. The total managed acres for a crop
                                            for a particular year is entered by farmers.
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>

                    <Grid item xs={12}>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="display2">Project Overview</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography gutterBottom>
                                            Total acres entered for all crops combined within the crop year reported and
                                            total unique farmers participating within each year. This table only
                                            includes crop years that are finalized and calculated with the latest
                                            metrics version.
                                            <Button
                                                color="primary"
                                                className={classes.floatRight}
                                                onClick={() => this.copyOverview()}
                                                ref={(re) => {
                                                    this.cropRef = re;
                                                }}
                                            >
                                                <FileCopy className={classes.button} /> &nbsp;&nbsp;&nbsp;Copy to
                                                Clipboard
                                            </Button>
                                        </Typography>
                                        <ProjectOverview
                                            data={project.project_data ? project.project_data : []}
                                            classes={classes}
                                            onRef={(el) => (this.overviewTable = el)}
                                        />
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>

                    {isProjectAdmin && (
                        <Grid item xs={12}>
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography variant="display2">Project Crop Management Systems</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={24}>
                                        <Grid item xs={12}>
                                            <Typography gutterBottom>
                                                These crop management templates appear in the crop rotation library of
                                                growers connected to this project. This is helpful when projects would
                                                like to develop templates for growers to use. Use the import feature if
                                                you are receiving a template from another user (e.g. agronomist).
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={24}>
                                                <Grid item xs={4} sm={4}>
                                                    <Button
                                                        fullWidth={true}
                                                        className={classes.optionButton}
                                                        onClick={this.addBlankRotationSystem}
                                                        color="primary"
                                                    >
                                                        <EditIcon className={classes.optionButtonIcon} />
                                                        Create Custom Template
                                                    </Button>
                                                </Grid>

                                                <Grid
                                                    item
                                                    xs={4}
                                                    sm={4}
                                                    //  className={classes.paddingLeft}
                                                >
                                                    <Button
                                                        fullWidth={true}
                                                        className={classes.optionButton}
                                                        onClick={this.handleSearchNrcsLmodClick}
                                                    >
                                                        <SearchIcon className={classes.optionButtonIcon} />
                                                        Search NRCS LMOD
                                                    </Button>
                                                </Grid>

                                                <Grid
                                                    item
                                                    xs={4}
                                                    sm={4}
                                                    // className={classes.paddingLeft}
                                                    // style={{ paddingRight: 12 }}
                                                >
                                                    <Button
                                                        fullWidth={true}
                                                        className={classes.optionButton}
                                                        onClick={this.handleImportTemplateClick}
                                                    >
                                                        <FileUploadIcon className={classes.optionButtonIcon} />
                                                        Import Template
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <ProjectRotationSystems
                                                data={rotationSystems}
                                                columnData={rotationSystemColumnData}
                                                classes={classes}
                                                onView={this.handleViewSystemClick}
                                                onCopy={this.handleCopySystemClick}
                                                onDownload={this.handleDownloadSystemClick}
                                                onDelete={this.handleDeleteSystemClick}
                                            />
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    )}

                    {isProjectAdmin && (
                        <Grid item xs={12}>
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography variant="display2">Field Crop-year Associations</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={16}>
                                        <Grid item xs={12}>
                                            <Typography>
                                                Listing of all crop years associated by farmers. Crop years appear here
                                                when farmers opt-in to projects and associate their crop years. Note
                                                that farmers can remove crop years at any time. Only crop years that are
                                                finalized and calculated with the latest metrics version are included in
                                                reports or analysis.
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Form dontValidateOnMount="true" validateOnSubmit="true">
                                                {(formApi) => (
                                                    <form>
                                                        <Grid
                                                            container
                                                            spacing={8}
                                                            className={classes.grey}
                                                            alignItems="flex-end"
                                                        >
                                                            <Grid item xs={3}>
                                                                <Select
                                                                    eventHandle={(value) =>
                                                                        this.setState({ farmerFilter: value })
                                                                    }
                                                                    field="dfarmer"
                                                                    label="Farmer"
                                                                    className={classes.standardMargin}
                                                                    options={makeOptions(farmers)}
                                                                    fullWidth
                                                                    margin="normal"
                                                                />
                                                            </Grid>
                                                            <Grid item xs={1}>
                                                                <Select
                                                                    eventHandle={(value) =>
                                                                        this.setState({ yearFilter: value })
                                                                    }
                                                                    field="dyear"
                                                                    label="Year"
                                                                    className={classes.standardMargin}
                                                                    options={makeYearOptions(years)}
                                                                    fullWidth
                                                                    margin="normal"
                                                                />
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <Select
                                                                    eventHandle={(value) =>
                                                                        this.setState({ cropFilter: value })
                                                                    }
                                                                    field="dcrop"
                                                                    label="Crop"
                                                                    className={classes.standardMargin}
                                                                    options={makeOptions(crops)}
                                                                    fullWidth
                                                                    margin="normal"
                                                                />
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <Select
                                                                    eventHandle={(value) =>
                                                                        this.setState({ finalFilter: value })
                                                                    }
                                                                    field="dstatus"
                                                                    label="Status"
                                                                    className={classes.standardMargin}
                                                                    options={makeOptions([
                                                                        { name: "Provisional", id: "false" },
                                                                        { name: "Finalized", id: "true" },
                                                                    ])}
                                                                    fullWidth
                                                                    margin="normal"
                                                                />
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <Select
                                                                    eventHandle={(value) =>
                                                                        this.setState({ metricsFilter: value })
                                                                    }
                                                                    field="dmetrics"
                                                                    label="Metrics"
                                                                    className={classes.standardMargin}
                                                                    options={makeOptions([
                                                                        { name: "Current Version", id: "current" },
                                                                        { name: "Old Version", id: "old" },
                                                                        { name: "Not Calculated", id: "none" },
                                                                    ])}
                                                                    fullWidth
                                                                    margin="normal"
                                                                />
                                                            </Grid>
                                                            <Grid item xs={2} className={classes.paddingRight}>
                                                                <Button
                                                                    className={classNames(
                                                                        classes.standardMargin,
                                                                        classes.filterButton,
                                                                    )}
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            farmerFilter: "",
                                                                            yearFilter: "",
                                                                            cropFilter: "",
                                                                            finalFilter: "",
                                                                            metricsFilter: "",
                                                                        });
                                                                        formApi.resetAll();
                                                                    }}
                                                                >
                                                                    <Typography>Clear Filters</Typography>
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </form>
                                                )}
                                            </Form>
                                        </Grid>
                                        {isProjectAdmin && (
                                            <Grid item xs={12}>
                                                <CropLibrary cropYears={project_data} classes={classes} />
                                            </Grid>
                                        )}
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    )}

                    <Grid item xs={12}>
                        <Button variant="raised" component={Link} to="/projectadmin">
                            Return to Projects Admin Dashboard
                        </Button>
                    </Grid>
                </Grid>
                <PreviewRotation
                    open={!!previewSystem}
                    rotationSystem={previewSystem}
                    onPreviewCancelClick={this.handleViewSystemCloseClick}
                />
            </AppContainer>
        );
    }
}

AdminDetails = connect(
    (state, ownProps) => {
        const project = getProject(state, ownProps),
            rotationSystems = allRotationSystems(state)
                .filter((r) => r.project && r.project.project_id === project.id)
                .map((r) => ({ ...r, user_id: state.auth.user.email, editable: true }))
                .concat(allProjectRotationSystems(state).filter((r) => r.project_id === project.id));
        return {
            project,
            authState: state.auth,
            syncState: state.sync,
            rotationSystems,
            projects: allProjectContacts(state),
        };
    },
    {
        reload: reloadAll,
        ...Project.actions,
        ...rotationActions,
        ...RotationSystem.actions,
        ...RotationEvent.actions,
        ...ProjectRotationSystem.actions,
    },
)(AdminDetails);

export default withStyles(styles)(withRouter(AdminDetails));
