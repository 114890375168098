import orm, { ReferenceData } from "../components/common/orm";
import { CropYear } from "../components/field/fieldactivities/models";
import { getValue, setValue } from "./utils";
import { apiFetch } from "./fetch";

export class Metric extends ReferenceData {
    static get modelName() {
        return "Metric";
    }
    static get actions() {
        let actions = super.actions;
        actions["runMetric"] = function (metricId, cropYearId, retry = false) {
            return function (dispatch, getState) {
                let payload = {};
                const state = getState(),
                    session = orm.session(state.orm),
                    metric = session.Metric.withId(metricId),
                    cropYear = session.CropYear.withId(cropYearId);

                if (!metric) {
                    console.warn("Unknown metric id: " + metricId);
                    return;
                }

                if (!cropYear) {
                    console.warn("Unknown crop year id: " + cropYearId);
                    return;
                }

                function update(state, save = false) {
                    let metricState = {
                            ...cropYear.metrics,
                            [metricId]: {
                                payload: { ...payload },
                                ...state,
                            },
                        },
                        fn = save ? CropYear.actions.ormCropYearUpdate : CropYear.actions.ormCropYearUpdateLocalOnly;
                    dispatch(
                        fn({
                            id: cropYearId,
                            metrics: metricState,
                        }),
                    );
                }

                update({ pending: true, percentComplete: 0, completeStatus: null });

                try {
                    metric.inputs.forEach((inputName) => {
                        payload = addInput(payload, cropYear, inputName);
                    });
                } catch (e) {
                    // We already know there is an error, so no need to send
                    // anything to the server.  But, we set an artificial
                    // timeout anyway to give the user a chance to see the
                    // "pending" state and feel that something happened.

                    setTimeout(() => {
                        update({
                            success: false,
                            error: e.message || "Unknown Input Error",
                        });
                        dispatch(
                            CropYear.actions.ormCropYearLogError({
                                timestamp: new Date(),
                                username: state.auth.user.email,
                                userid: state.auth.user.id,
                                error: e.message || "Unknown Input Error",
                                cropyearid: cropYearId,
                                payload: cropYear,
                            }),
                        );
                    }, 500);
                    return;
                }

                const options = {
                    method: "POST",
                    body: JSON.stringify(payload),
                };

                runUpdate(options, cropYearId, retry);

                function runUpdate(options, cropYearId, retry = false) {
                    apiFetch(`/v4/${metric.path}?async=1${retry ? "&retry=1" : ""}`, options)
                        .then((result) => result.json().then((data) => [result.status, data]))
                        .then(([status, data]) => {
                            if (data && data.error) {
                                dispatch(
                                    CropYear.actions.ormCropYearLogError({
                                        timestamp: new Date(),
                                        username: state.auth.user.email,
                                        userid: state.auth.user.id,
                                        error: data.error,
                                        trace: data.trace,
                                        traceback: data.traceback,
                                        metric: data.metric,
                                        version: data.version,
                                        cropyearid: cropYearId,
                                        payload: payload,
                                    }),
                                );
                                throw new Error(data.error);
                            } else if (status === 400) {
                                dispatch(
                                    CropYear.actions.ormCropYearLogError({
                                        timestamp: new Date(),
                                        username: state.auth.user.email,
                                        userid: state.auth.user.id,
                                        error: "Unknown Error",
                                        trace: data.trace,
                                        traceback: data.traceback,
                                        metric: data.metric,
                                        version: data.version,
                                        cropyearid: cropYearId,
                                        payload: payload,
                                    }),
                                );
                                throw new Error(JSON.stringify(data));
                            }

                            if (data && data.result === undefined) {
                                var complete = 0;
                                if (data.n !== undefined && data.total !== undefined)
                                    complete = Math.round((data.n / data.total) * 100);
                                if (complete > 100) {
                                    complete = 99;
                                }
                                update({ pending: true, percentComplete: complete, completeStatus: data.status });
                                if (complete !== 100 || data.status !== "Complete") runUpdate(options, cropYearId);
                            }
                            if (data.status === "Complete") {
                                update(
                                    {
                                        success: true,
                                        result: data.result,
                                    },
                                    true,
                                );
                            }
                        })
                        .catch((e) => {
                            update({
                                success: false,
                                error: e.message || "Unknown Error",
                            });
                            // e.* not available
                            /*dispatch(
                                CropYear.actions.ormCropYearLogError({
                                    timestamp: new Date(),
                                    username: state.auth.user.email,
                                    userid: state.auth.user.id,
                                    error: e.message || "Unknown Error",
                                    trace: e.trace,
                                    traceback: e.traceback,
                                    metric: e.metric,
                                    version: e.version,
                                    payload: payload
                                })
                            );*/
                        });
                }
            };
        };
        return actions;
    }
}
orm.register(Metric);

export function addInput(payload, cropYear, inputName) {
    let defaultValue = null;
    if (inputName.match(/\[\]$/)) {
        inputName = inputName.replace(/\[\]$/, "");
        defaultValue = [];
    } else if (inputName.match(/\?$/)) {
        inputName = inputName.replace(/\?$/, "");
        // TODO: Set default in definition rather than hardcoding here
        if (inputName === "cropyear.soils.crop_calibration_mode") {
            defaultValue = false; // Disable for now
        } else {
            defaultValue = false;
        }
    }

    let [model, ...path] = inputName.split("."),
        obj = null,
        type = null,
        multiple = false,
        fertilizers = false;

    switch (model) {
        case "field":
            obj = cropYear.field.ref;
            break;
        case "cropyear":
            if (path[0] === "rotation" && cropYear.rotation) {
                obj = { rotation: cropYear.rotation.toRotationJSON() };
                if (!cropYear.rotation.is_valid) {
                    throw new Error("Invalid rotation selected.");
                }
                if (!obj.rotation.events || !obj.rotation.events.length) {
                    throw new Error("Empty rotation selected.");
                }
            } else {
                obj = cropYear.ref;
            }
            break;
        case "activity":
            type = path[0];
            if (type.indexOf("[]") > -1) {
                path[0] = type = type.replace("[]", "");
                multiple = true;
            }
            if (path[1] === "fertilizers[]") {
                fertilizers = true;
                path = path.slice(2);
            }
            obj = [];
            let activityIndex = 0;
            cropYear.activities.toModelArray().forEach((activity) => {
                if (!activity[type]) {
                    return;
                }
                if (fertilizers) {
                    activity.fertilizers.toModelArray().forEach((fertilizer, j) => {
                        obj.push({
                            activity_index: activityIndex,
                            fert_index: j,
                            ...fertilizer.ref,
                        });
                    });
                    activityIndex += 1;
                } else {
                    obj.push(activity.ref);
                }
            });
            if (!multiple) {
                obj = obj[0] || null;
            }
            break;
        default:
            return payload;
    }
    path = path.join(".");
    if (multiple) {
        if (!payload[model] || !payload[model][type]) {
            setValue(payload, model + "." + type, []);
        }
        obj.forEach((row, i) => {
            let rowInputName;
            if (fertilizers) {
                rowInputName = inputName
                    .replace("application[]", "application[" + row.activity_index + "]")
                    .replace("fertilizers[]", "fertilizers[" + row.fert_index + "]");
            } else {
                rowInputName = inputName.replace("[]", "[" + i + "]");
            }
            setValue(payload, rowInputName, valueOrDefault(row, path));
        });
    } else {
        setValue(payload, inputName, valueOrDefault(obj, path));
    }
    return payload;

    function valueOrDefault(obj, path) {
        const value = getValue(obj, path);
        if (value === null || value === undefined) {
            return defaultValue;
        } else {
            return value;
        }
    }
}
