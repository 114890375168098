import React, { Component } from "react";
import Typography from "../../common/TypographyWrapper";
import Grid from "../../common/GridWrapper";
import withStyles from "@mui/styles/withStyles";
import classNames from "classnames";
import HorizontalLogo from "../../field/analysis/img/color_02_horizontal.jpg";

// Custom CSS classes
const styles = (theme) => ({
    header: {
        width: "7.5in",
        paddingLeft: "0.5in",
        paddingRight: "0.5in",
    },
    runningHeaderText: {
        lineHeight: "1.4em",
        fontSize: 12,
    },
    marginTop: {
        paddingTop: 0.5,
    },
    summary: {
        backgroundColor: "#eee",
        marginTop: "1.5em",
        marginBottom: "1em",
        padding: "0.5em",
        "& p": {
            fontSize: 13,
            lineHeight: "1.4em",
        },
    },
    headerImage: {
        height: "1in",
    },
});

function getTimeStamp() {
    function pad(n) {
        return n < 10 ? "0" + n : n;
    }

    var currentTime = new Date();
    var MM = pad(currentTime.getMonth() + 1);
    var DD = pad(currentTime.getDate());
    var YYYY = currentTime.getFullYear();
    var hh = currentTime.getHours();
    if (hh > 12) {
        hh = hh - 12;
    }
    hh = pad(hh);
    var mm = pad(currentTime.getMinutes());

    var ampm = "AM";
    if (currentTime.getHours() > 11) {
        ampm = "PM";
    }

    var timestamp = [[MM, DD, YYYY].join("/"), [hh, mm].join(":"), ampm].join(" ");

    return timestamp;
}

function add(total, value) {
    if (total === null) {
        return value;
    } else {
        return (total += value);
    }
}

class PageHeader extends Component {
    between(current_year, year) {
        if (current_year >= year && current_year - year < 10) {
            return true;
        } else {
            return false;
        }
    }

    render() {
        const { classes, scenario, first } = this.props,
            timestamp = getTimeStamp(),
            headerVariant = first ? "body2" : "caption",
            headerClass = first ? null : classes.runningHeaderText;

        const acres = parseFloat(scenario.size);
        const year = scenario.year ? parseInt(scenario.year) : new Date().getFullYear();
        var carbon = null;
        var nitrous = null;
        var totalCarbon = null;
        var totalNitrous = null;

        scenario.practices.forEach((x) => {
            if (this.between(year, parseInt(x.year))) {
                if (x.carbon !== -999) {
                    carbon = add(carbon, x.carbon * acres);
                    totalCarbon = add(totalCarbon, x.carbon);
                }

                if (x.nitrous !== -999) {
                    nitrous = add(nitrous, x.nitrous * acres);
                    totalNitrous = add(totalNitrous, x.nitrous);
                }
            }
        });

        return (
            <div className={classNames(classes.header, !first && classes.marginTop)}>
                {first && (
                    <>
                        <center>
                            <img className={classes.headerImage} src={HorizontalLogo} alt={"Field To Market logo"} />
                        </center>
                        <Typography variant={"title"} align="center">
                            COMET Planner Scenario {scenario.name && "|"} {scenario.name} | Report Year: {scenario.year}
                        </Typography>
                    </>
                )}
                <Grid container className={classes.summary}>
                    <Grid item xs={7}>
                        <Typography variant={headerVariant} className={headerClass}>
                            {scenario.user && (
                                <>
                                    <b>Generated for:</b> {scenario.user}
                                    <br />
                                </>
                            )}
                            <b>Date Generated:</b> {timestamp}
                            <br />
                            <b>Source:</b> {scenario.source}
                            <br />
                            {scenario.farm_name && scenario.field_name && (
                                <>
                                    <b>Farm, Field:</b> {scenario.farm_name}, {scenario.field_name}
                                    <br />
                                </>
                            )}
                            {scenario.county_name && scenario.state_name && (
                                <>
                                    <b>County, State:</b> {scenario.county_name}, {scenario.state_name}
                                    <br />
                                </>
                            )}
                            <b>Field Acres:</b> {scenario.size}
                        </Typography>
                    </Grid>

                    <Grid item xs={5}>
                        <Typography variant={headerVariant} className={headerClass}>
                            <b>Total Soil Carbon (per yr):</b> {carbon ? carbon.toFixed(2) : "Empty Value"}
                            <br />
                            <b>Soil Carbon (per ac per yr):</b> {totalCarbon ? totalCarbon.toFixed(2) : "Empty Value"}
                            <br />
                            <b>
                                Total N<sub>2</sub>O (per yr):
                            </b>{" "}
                            {nitrous ? nitrous.toFixed(2) : "Empty Value"}
                            <br />
                            <b>
                                N<sub>2</sub>O (per ac per yr):
                            </b>{" "}
                            {totalNitrous ? totalNitrous.toFixed(2) : "Empty Value"}
                        </Typography>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(PageHeader);
