import { useEffect } from "react";

import { Source, Layer, useMap } from "react-map-gl/maplibre";
import bbox from "@turf/bbox";
import area from "@turf/area";
import intersect from "@turf/intersect";
import { reduceFeature } from "../Draw";

const CLU_TILES = [`https://fpp-clu-tiles.s3.amazonaws.com/{z}/{y}/{x}.pbf`],
    CLU_FILL_STYLE = {
        id: "clu-fill",
        type: "fill",
        "source-layer": "Common_Land",
        minzoom: 10,
        maxzoom: 20,
        paint: { "fill-color": "#a99", "fill-opacity": 0.2 },
    },
    CLU_LINE_STYLE = {
        id: "clu-line",
        type: "line",
        "source-layer": "Common_Land",
        minzoom: 10,
        maxzoom: 20,
        paint: { "line-color": "#66f", "line-width": 3 },
    },
    CLU_SELECTED_FILL_STYLE = {
        id: "clu_selected_fill",
        type: "fill",
        paint: { "fill-color": "#c99", "fill-opacity": 0.8 },
    },
    CLU_SELECTED_LINE_STYLE = {
        id: "clu_selected_line",
        type: "line",
        paint: { "line-color": "#f95", "line-width": 5 },
    },
    VISIBLE = { visibility: "visible" },
    HIDDEN = { visibility: "none" },
    EMPTY = { type: "FeatureCollection", features: [] };

export default function CLULayer({ flags, selected, setSelected }) {
    const { current: map } = useMap();
    useEffect(() => {
        if (!map || !flags.select_clu) {
            return;
        }
        function onMouseMove(evt) {
            const features = map.queryRenderedFeatures(evt.point, { layers: ["clu-line", "clu-fill"] }),
                style = map.getMap()._canvasContainer.style;
            if (features.length > 0) {
                style.cursor = "pointer";
            } else {
                style.cursor = null;
            }
        }
        function onClick(evt) {
            const clicked = reduceFeature(map.queryRenderedFeatures(evt.point, { layers: ["clu-line", "clu-fill"] }));
            if (!clicked) {
                return;
            }
            if (evt.type === "touchstart") {
                evt.preventDefault(); // ensure "click" is fired next
                return;
            }

            const [feature, overlaps] = autoExtend(clicked, map, 5);
            setSelected((selected) => {
                let nextSelected = selected ? [...selected.features] : [];
                if (selected && selected.features.some((f) => overlaps(f))) {
                    nextSelected = nextSelected.filter((f) => !overlaps(f));
                } else {
                    nextSelected.push({ type: "Feature", geometry: feature.geometry });
                }
                if (nextSelected.length > 0) {
                    return { type: "FeatureCollection", features: nextSelected };
                } else {
                    return null;
                }
            });
        }
        map.on("mousemove", onMouseMove);
        map.on("click", onClick);
        map.on("touchstart", onClick);
        return () => {
            map.off("mousemove", onMouseMove);
            map.off("click", onClick);
            map.off("touchstart", onClick);
            const style = map.getMap()._canvasContainer.style;
            style.cursor = null;
        };
    }, [map, flags.select_clu, setSelected]);

    const layout = flags.select_clu ? VISIBLE : HIDDEN;
    return (
        <>
            <Source id="clu" type="vector" tiles={CLU_TILES} minzoom={10} maxzoom={14}>
                <Layer {...CLU_FILL_STYLE} layout={layout} />
                <Layer {...CLU_LINE_STYLE} layout={layout} />
            </Source>
            <Source id="clu_selected" type="geojson" data={selected || EMPTY}>
                <Layer {...CLU_SELECTED_FILL_STYLE} layout={layout} />
                <Layer {...CLU_SELECTED_LINE_STYLE} layout={layout} />
            </Source>
        </>
    );
}

function autoExtend(feature, map, iterations = 0) {
    const [w, s, e, n] = bbox(feature),
        size = area(feature),
        overlaps = (f) => {
            const intersection = intersect(feature, f);
            return intersection && area(intersection) / size > 0.01;
        },
        bounds = [map.project([w, s]), map.project([e, n])];

    const extended = reduceFeature(map.queryRenderedFeatures(bounds, { layers: ["clu-fill"] }).filter(overlaps));
    if (iterations > 0) {
        return autoExtend(extended, map, iterations - 1);
    } else {
        return [extended, overlaps];
    }
}
