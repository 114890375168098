import React, { Component } from "react";
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, ReferenceLine } from "recharts";
import Grid from "../../common/GridWrapper";
import Typography from "../../common/TypographyWrapper";
import withStyles from "@mui/styles/withStyles";
import CarbonIcon from "../icons/CarbonIcon";
import NitrousIcon from "../icons/NitrousIcon";

const styles = (theme) => ({
    graphInfo: {
        "& svg": {
            flexShrink: 0,
            width: "2em",
            height: "2em",
            fontSize: "24px",
            marginRight: 5,
        },
        "& div": {
            justifyContent: "center",
            alignContent: "center",
            flexDirection: "column",
            display: "flex",
        },
        "& h2": {
            fontSize: 30,
        },
    },
});

function GetYear(year) {
    if (year) {
        return year;
    }
    return new Date().getFullYear().toString();
}

class Chart extends Component {
    ChartContainer = ({ x, year, acres, stateKey, props, ...rest }) => {
        const { classes, scenario } = this.props;

        function GetTitle(d, year) {
            const ac = acres;
            const name = rest.field_name;

            if (d.stateKey === "carbon") {
                return `Total Soil Carbon Sequestration for ${name} (${ac}) in ${year}`;
            } else {
                return (
                    <>
                        Total Annual Direct/Indirect Soil N<sub>2</sub>O Emissions Change for {name} ({ac}) in {year}
                    </>
                );
            }
        }

        var year_dict = {};
        var practice_dict = {};
        var emptyData = {};
        var hasNegative = false;

        scenario.practices.forEach((x) => {
            const min = parseInt(x.year);
            const max = min + 10;
            for (var i = min; i < max; i++) {
                const totalPerAcres = acres * x[stateKey];
                if (x[stateKey] !== -999) {
                    if (totalPerAcres < 0) {
                        hasNegative = true;
                    }

                    if (!(i.toString() in year_dict)) {
                        practice_dict[i.toString()] = {};
                    }
                    if (x.year in practice_dict[i.toString()]) {
                        practice_dict[i.toString()][x.id] += totalPerAcres;
                    } else {
                        practice_dict[i.toString()][x.id] = totalPerAcres;
                    }

                    if (i.toString() in year_dict) {
                        year_dict[i.toString()] += totalPerAcres;
                    } else {
                        year_dict[i.toString()] = totalPerAcres;
                    }
                } else {
                    if (x.year in emptyData) {
                        const str = x.cps_name + ", " + x.planner_implementation;
                        if (str in emptyData[x.year]) {
                        }
                    } else {
                        emptyData[x.year] = {};
                        emptyData[x.year][x.cps_name + ", " + x.planner_implementation] = "";
                    }
                }
            }
        });

        const colors = [
            "#8591ee",
            "#ffb93c",
            "#a85cd5",
            "#5a50af",
            "#3aaaaa",
            "#ff4fa0",
            "#e43e24",
            "#afd200",
            "#328282",
            "#ff7d32",
        ];

        var flattened = [];
        Object.keys(practice_dict).forEach((j) => {
            var obj = {};
            obj.name = j;
            Object.keys(practice_dict[j]).forEach((z) => {
                obj[z] = practice_dict[j][z];
            });
            flattened.push(obj);
        });

        var emptyPractices = [];
        Object.keys(emptyData).forEach((x) => {
            Object.keys(emptyData[x]).forEach((z) => {
                emptyPractices.push(x + ", " + z);
            });
        });

        var displayYear = year;
        if (!(displayYear in year_dict)) {
            var yearList = [];
            scenario.practices.forEach((x) => {
                yearList.push(x.year);
            });
            yearList = [...new Set(yearList)];
            yearList.sort((a, b) => a - b);
            displayYear = yearList[0] || "";
        }

        return (
            <>
                <Grid container spacing={8}>
                    <Grid item xs={14}>
                        <Typography variant="title" style={{ color: "#000" }}>
                            <b>{GetTitle(x, displayYear)}</b>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ display: "flex" }} className={classes.graphInfo}>
                        <x.icon />
                        <div>
                            <Typography variant="title" style={{ color: x.color }}>
                                {!isNaN(year_dict[displayYear]) ? year_dict[displayYear].toFixed(2) : ""}
                                <span
                                    style={{
                                        fontSize: 15,
                                        marginLeft: 5,
                                        color: "#666666",
                                    }}
                                >
                                    {!isNaN(year_dict[displayYear]) ? (
                                        <>
                                            t CO<sub>2</sub>e / yr
                                        </>
                                    ) : (
                                        ""
                                    )}
                                </span>
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{ marginTop: 20 }}>
                    <ResponsiveContainer width="100%" height={200}>
                        <BarChart
                            stackOffset="sign"
                            data={flattened}
                            margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
                        >
                            {scenario.practices.map((z, index) => (
                                <Bar key={index} dataKey={z.id} stackId="a" fill={colors[index]} />
                            ))}
                            <XAxis dataKey="name" />
                            <YAxis />
                            {hasNegative && <ReferenceLine y={0} stroke="#929292" strokeDasharray="5 3" />}
                        </BarChart>
                    </ResponsiveContainer>
                    {emptyPractices.length > 0 && (
                        <div style={{ marginTop: 15 }}>
                            <Typography>Practices With Empty Values:</Typography>
                            {emptyPractices.map((j) => (
                                <Typography key={j}>{j}</Typography>
                            ))}
                        </div>
                    )}
                </Grid>
            </>
        );
    };

    render() {
        const { classes, scenario } = this.props;
        const data = [
            {
                stateKey: "carbon",
                color: "#34a853",
                icon: CarbonIcon,
            },
            {
                stateKey: "nitrous",
                color: "#0096ff",
                icon: NitrousIcon,
            },
        ];

        const size = scenario.size;

        return (
            <Grid container spacing={8} alignItems="center">
                {data.map((x, index) => (
                    <Grid key={index} item xs={12}>
                        <Grid container style={{ backgroundColor: "#efefef", padding: 15 }}>
                            <this.ChartContainer
                                year={GetYear(scenario.year)}
                                currentYear={GetYear(null)}
                                field_name={scenario.field_name}
                                farm_name={scenario.farm_name}
                                classes={classes}
                                x={x}
                                all={data}
                                stateKey={x.stateKey}
                                acres={parseFloat(size)}
                            />
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        );
    }
}

export default withStyles(styles)(Chart);
