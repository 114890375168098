import React from "react";
import { withStyles } from "@mui/styles";
import { Card, CardContent, Box, Typography } from "@mui/material";
import DateComponent from "../common/DateComponent";
import Pagination from "@mui/material/Pagination";

const styles = (theme) => ({
    card: {
        minWidth: 275,
        paddingBottom: "10px",
    },
    controls: {
        display: "flex",
        justifyContent: "center",
    },
    button: {
        margin: theme.spacing(1),
        background: "#727272",
        color: "white",
        height: "30px",
        width: "30px",
    },
});

class Carousel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
        };
    }

    handlePageChange = (event, value) => {
        this.setState({ page: value });
    };

    render() {
        const { classes, items } = this.props;
        const { page } = this.state;

        return (
            <Box>
                <Card className={classes.card}>
                    <CardContent>
                        <Typography style={{ fontWeight: "600" }}>
                            {items[page - 1] != undefined ? items[page - 1].label : ""}
                        </Typography>
                        <Typography style={{ fontSize: "small", marginBottom: "10px" }}>
                            <DateComponent date={items[page - 1] != undefined ? items[page - 1].createdate : null} />
                        </Typography>
                        <div
                            style={{ fontWeight: "200", fontSize: "small" }}
                            dangerouslySetInnerHTML={{
                                __html: items[page - 1] != undefined ? items[page - 1].content : "",
                            }}
                        />
                    </CardContent>
                    <Pagination count={items.length} page={page} onChange={this.handlePageChange} />
                </Card>
            </Box>
        );
    }
}

export default withStyles(styles)(Carousel);
