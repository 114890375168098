import { ROTATION_MGMT_SEARCH_PENDING, ROTATION_MGMT_SEARCH_ERROR, ROTATION_MGMT_SEARCH_SUCCESS } from "./actions";

function rotationReducer(state = {}, action) {
    switch (action.type) {
        case ROTATION_MGMT_SEARCH_PENDING:
            return {
                mgmt: { pending: true },
            };
        case ROTATION_MGMT_SEARCH_SUCCESS:
            return {
                mgmt: { results: action.results },
            };
        case ROTATION_MGMT_SEARCH_ERROR:
            return {
                mgmt: { error: true },
            };
        default:
            return state;
    }
}

export default rotationReducer;
