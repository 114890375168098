import React from "react";
import classNames from "classnames";
import Button from "./ButtonWrapper";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles({
    submitWrapper: {
        position: "relative",
    },
    progress: {
        top: "50%",
        left: "50%",
        color: "#4CAF50",
        position: "absolute",
        "margin-top": -12,
        "margin-left": -12,
    },
});

export default React.forwardRef(function ActionButton(
    {
        label,
        children,
        loading,
        type = "button",
        color = "primary",
        variant = "raised",
        icon = false,
        disableElevation = undefined,
        startIcon = undefined,
        href = undefined,
        onClick = undefined,
        className = undefined,
        disabled = undefined,
        ...rest
    },
    ref,
) {
    const classes = useStyles(),
        Root = icon ? IconButton : Button;
    return (
        <div className={classNames(classes.submitWrapper, className)}>
            <Root
                ref={ref}
                fullWidth
                type={type}
                variant={variant}
                disableElevation={disableElevation}
                color={color}
                startIcon={startIcon}
                disabled={disabled || loading}
                component={href ? "a" : "button"}
                href={href}
                onClick={onClick}
                {...rest}
            >
                {label || children}
            </Root>
            {loading && <CircularProgress size={24} className={classes.progress} />}
        </div>
    );
});
