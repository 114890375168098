import { Fragment, useState } from "react";
import { IconButton, Divider, Tooltip } from "@mui/material";

import Search from "mdi-material-ui/Magnify";
import Locate from "mdi-material-ui/CrosshairsGps";
import ZoomIn from "mdi-material-ui/Plus";
import ZoomOut from "mdi-material-ui/Minus";
import ZoomFit from "mdi-material-ui/FitToPage";
import Help from "mdi-material-ui/BookOpenVariant";
import Layers from "mdi-material-ui/Layers";
import SelectCLU from "mdi-material-ui/Grid";
import ApplyCLU from "mdi-material-ui/VectorSquareEdit";
import Polygon from "mdi-material-ui/VectorPolygon";
import Circle from "mdi-material-ui/VectorCircleVariant";
import Freehand from "mdi-material-ui/Gesture";
import Square from "mdi-material-ui/VectorSquare";
import Cut from "mdi-material-ui/ScissorsCutting";
import Delete from "mdi-material-ui/Delete";
import Undo from "mdi-material-ui/Undo";
import Redo from "mdi-material-ui/Redo";
import Import from "mdi-material-ui/DatabaseImport";
import Export from "mdi-material-ui/DatabaseExport";

const ACTION = "ACTION",
    MODE = "MODE",
    FLAG = "FLAG",
    ZOOM_TOOLS = [
        { id: "search", type: FLAG, title: "Search by Address", icon: Search },
        { id: "locate", type: FLAG, title: "Current Location", icon: Locate },
        { id: "zoomin", type: ACTION, title: "Zoom In", icon: ZoomIn },
        { id: "zoomout", type: ACTION, title: "Zoom Out", icon: ZoomOut },
        { id: "zoomfit", type: ACTION, title: "Zoom to Fit", icon: ZoomFit },
        { id: "help", type: ACTION, title: "Help", icon: Help },
    ],
    EXPLORE_TOOLS = [{ id: "layers", type: FLAG, title: "Layers", icon: Layers }],
    LAYER_TOOLS = [
        { id: "layers", type: FLAG, title: "Layers", icon: Layers },
        {
            id: "select_clu",
            type: FLAG,

            title: "Select CLU Polygon(s)",
            icon: SelectCLU,
            tip: "Zoom in further to use this tool.",
        },
        {
            id: "apply_clu",
            type: ACTION,

            title: "Apply and Edit Selected Polygon(s)",
            icon: ApplyCLU,
            divider: true,
            tip: "Select one or more adjacent CLU polygons, then click here to apply and edit.",
        },
    ],
    DRAW_TOOLS = [
        { id: "drag_circle", type: MODE, title: "Draw Circle", icon: Circle, divider: true },
        {
            id: "assist_rectangle",
            type: MODE,

            title: "Draw Rectangle",
            icon: Square,
        },
        { id: "draw_polygon", type: MODE, title: "Draw Polygon", icon: Polygon },
        {
            id: "freehand_polygon",
            type: MODE,

            title: "Draw Freeform Polygon",
            icon: Freehand,
        },
        { id: "cut", type: FLAG, title: "Cut Out of Existing Polygon", icon: Cut },
    ],
    LOAD_TOOLS = [
        {
            id: "delete",
            type: ACTION,
            title: "Delete",
            icon: Delete,
            divider: true,
        },
        { id: "undo", type: ACTION, title: "Undo", icon: Undo },
        { id: "redo", type: ACTION, title: "Redo", icon: Redo },
        { id: "import", type: ACTION, title: "Import Boundary", icon: Import },
        { id: "export", type: ACTION, title: "Export Boundary", icon: Export },
    ],
    DRAW_MODES = DRAW_TOOLS.filter((tool) => tool.type === MODE).map((tool) => tool.id);

export { ACTION, MODE, FLAG, DRAW_MODES };

function Tools({ tools, toolClass, activateTool, isActive, isDisabled, isVisible, left }) {
    return (
        <div className={toolClass}>
            {tools.filter(isVisible).map(({ icon: Icon, title, divider, toolClass, ...tool }) => (
                <Fragment key={tool.id}>
                    {divider && <Divider style={{ marginTop: 8, marginBottom: 8 }} />}
                    <IconButtonWithTooltip
                        id={tool.id}
                        className={toolClass}
                        left={left}
                        title={title}
                        tip={tool.tip}
                        onClick={() => activateTool(tool)}
                        color={isActive(tool) ? "secondary" : "default"}
                        disabled={isDisabled(tool)}
                    >
                        <Icon />
                    </IconButtonWithTooltip>
                </Fragment>
            ))}
        </div>
    );
}

function IconButtonWithTooltip({ tip, title, left, ...rest }) {
    const [open, setOpen] = useState(false),
        showTip = tip && !!rest.disabled;
    return (
        <Tooltip
            placement={left ? "right" : "left"}
            open={showTip || open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            title={showTip ? tip : title}
            arrow={showTip}
        >
            <IconButton {...rest} />
        </Tooltip>
    );
}

export function ZoomTools(props) {
    return <Tools {...props} tools={ZOOM_TOOLS} left />;
}

export function EditTools(props) {
    return (
        <>
            <Tools {...props} toolClass="layer" tools={LAYER_TOOLS} />
            <Tools {...props} toolClass="draw" tools={DRAW_TOOLS} />
            <Tools {...props} toolClass="load" tools={LOAD_TOOLS} />
        </>
    );
}

export function ExploreTools(props) {
    return <Tools {...props} toolClass="layer" tools={EXPLORE_TOOLS} />;
}
