import React, { Component } from "react";
import { Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Legend, Tooltip } from "recharts";
import { getValue } from "../../../api/utils";
import { getMetrics, getLabel, getColor } from "./StaticData";
import Card from "@mui/material/Card";
import Grid from "../../common/GridWrapper";
import Typography from "../../common/TypographyWrapper";
import withStyles from "@mui/styles/withStyles";

const styles = {
    noBenchmarks: {
        background: "#EEEEEE",
        border: "3px solid " + getColor("state"),
        margin: "1em",
        padding: "1em",
    },
};

class NoBenchmarks extends Component {
    render() {
        const { cropYear, classes, height } = this.props;
        return (
            <Grid container justifyContent="center" alignItems="center" style={{ height }}>
                <Grid item xs={6}>
                    <Card className={classes.noBenchmarks}>
                        <Typography>
                            <b>Notice:</b> Benchmarks for {cropYear.crop_name} are not yet available. While this does
                            not impact calculation of your individual metric scores (see below on this page), the
                            benchmarks are used to scale metric scores for display on the Spidergram. Therefore, the
                            Spidergram is not available.
                        </Typography>
                    </Card>
                </Grid>
            </Grid>
        );
    }
}
NoBenchmarks = withStyles(styles)(NoBenchmarks);

class CustomizedDot extends Component {
    render() {
        const { cx, cy, fillOpacity, strokeOpacity, r, stroke, fill } = this.props;
        if (isNaN(cx)) {
            return null;
        }

        return (
            <circle
                cx={cx}
                cy={cy}
                r={r}
                stroke={stroke}
                fill={fill}
                fillOpacity={fillOpacity}
                strokeOpacity={strokeOpacity}
            />
        );
    }
}
class CustomizedSquare extends Component {
    render() {
        const { cx, cy, fillOpacity, strokeOpacity, stroke, fill, width, height } = this.props;
        if (isNaN(cx)) {
            return null;
        }
        return (
            <rect
                width={width}
                height={height}
                x={cx - 6}
                y={cy - 6}
                stroke={stroke}
                fill={fill}
                fillOpacity={fillOpacity}
                strokeOpacity={strokeOpacity}
            />
        );
    }
}
class CustomizedTriangle extends Component {
    render() {
        const { cx, cy, fillOpacity, strokeOpacity, stroke, fill } = this.props;
        if (isNaN(cx)) {
            return null;
        }
        return (
            <polygon
                points="0,12 12,12 6,0"
                transform={"translate(" + (cx - 6) + "," + (cy - 6) + ")"}
                stroke={stroke}
                fill={fill}
                fillOpacity={fillOpacity}
                strokeOpacity={strokeOpacity}
            />
        );
    }
}
class CustomizedDiamond extends Component {
    render() {
        const { cx, cy, fillOpacity, strokeOpacity, stroke, fill } = this.props;
        if (isNaN(cx)) {
            return null;
        }
        return (
            <svg
                x={cx - 12}
                y={cy - 12}
                xmlns="http://www.w3.org/2000/svg"
                stroke={stroke}
                fill={fill}
                fillOpacity={fillOpacity}
                strokeOpacity={strokeOpacity}
            >
                <path
                    fill="#8A2BE2"
                    transform="translate(12, 12)"
                    d="M0,-8L8,0L0,8L-8,0Z"
                    x="430.2481001269517"
                    y="241.01098988988923"
                />
            </svg>
        );
    }
}

class SpiderGram extends Component {
    getSpiderGramData() {
        const { cropYear } = this.props,
            metrics = getMetrics(cropYear),
            result = getValue(cropYear, "metrics.Calculator.result.cropyears[0]"),
            project_benchmarks = getValue(cropYear, "project.project_benchmarks");

        if (!result) {
            return null;
        }

        const getVal = (metric, benchmark) => {
            let path = metric;
            if (benchmark) {
                path += ".benchmarks." + benchmark;
            }
            path += ".scaledFieldprintResult";
            let val = getValue(result, path);
            if (val || val === 0) {
                return val.toFixed(2);
            } else {
                return "-";
            }
        };

        const getProjectVal = (metric) => {
            if (!project_benchmarks) return "-";
            const benchmark = project_benchmarks.find((b) => b.metric === metric);
            if (!benchmark) return "-";
            let val = benchmark.project_benchmark_scaled;
            if (val || val === 0) {
                return val.toFixed(2);
            } else {
                return "-";
            }
        };

        // Only show spidergram if at least 5 metrics have scaled values
        let benchmarkCount = 0;
        metrics.forEach((metric) => {
            if (getVal(metric) !== "-") {
                benchmarkCount++;
            }
        });
        const hasBenchmarks = benchmarkCount >= 5;

        const data = metrics.map((metric) => ({
            subject: getLabel(metric),
            label: getLabel(metric),
            active_year: getVal(metric),
            state: getVal(metric, "state"),
            national: getVal(metric, "national"),
            project: getProjectVal(metric),
        }));
        return {
            data,
            hasBenchmarks,
        };
    }

    renderRadar(dataKey, shape) {
        const color = getColor(dataKey),
            strokeOpacity = shape !== "circle" ? 0.0 : 1.0,
            fillOpacity = shape !== "circle" ? 0.0 : 0.6;
        let shapeElement;
        switch (shape) {
            case "circle":
                shapeElement = (
                    <CustomizedDot fill={color} stroke={color} strokeOpacity={1.0} fillOpacity={0.6} r={6} />
                );
                break;
            case "square":
                shapeElement = (
                    <CustomizedSquare
                        fill={color}
                        stroke={color}
                        strokeOpacity={1.0}
                        fillOpacity={0.6}
                        width={12}
                        height={12}
                    />
                );
                break;
            case "triangle":
                shapeElement = <CustomizedTriangle fill={color} stroke={color} strokeOpacity={1.0} fillOpacity={0.6} />;
                break;
            case "diamond":
                shapeElement = <CustomizedDiamond fill={color} stroke={color} strokeOpacity={1.0} fillOpacity={0.6} />;
                break;
            default:
                shapeElement = (
                    <CustomizedDot fill={color} stroke={color} strokeOpacity={1.0} fillOpacity={0.6} r={6} />
                );
        }

        return (
            <Radar
                dataKey={dataKey}
                name={getLabel(dataKey)}
                dot={shapeElement}
                fill={color}
                fillOpacity={fillOpacity}
                stroke={color}
                strokeOpacity={strokeOpacity}
                legendType={shape}
                isAnimationActive={false}
            />
        );
    }

    render() {
        const { data, hasBenchmarks } = this.getSpiderGramData(),
            { cropYear, showProject, showState, showNational, showLegend, ...rest } = this.props;
        if (!hasBenchmarks) {
            return <NoBenchmarks cropYear={cropYear} height={rest.height || 400} />;
        }

        return (
            <RadarChart onClick={() => {}} data={data} {...rest}>
                <PolarGrid />
                <PolarAngleAxis dataKey="subject" />
                <PolarRadiusAxis domain={[0, 100]} />
                <Tooltip />

                {this.renderRadar("active_year", "circle")}
                {showState && this.renderRadar("state", "square")}
                {showNational && this.renderRadar("national", "triangle")}
                {showProject && this.renderRadar("project", "diamond")}
                {showLegend !== false && <Legend />}
            </RadarChart>
        );
    }
}

export default SpiderGram;
