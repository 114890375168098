import muiTableCell from "@mui/material/TableCell";
import withStyles from "@mui/styles/withStyles";

const styles = (theme) => ({
    head: {
        backgroundColor: "#ebebeb",
        [theme.breakpoints.up("lg")]: {
            padding: "4px 12px",
            "&:last-child": {
                paddingRight: 12,
            },
        },
        [theme.breakpoints.down("lg")]: {
            padding: "4px 8px",
            "&:last-child": {
                paddingRight: 8,
            },
        },
        "& p": {
            fontSize: 12,
            color: "#000000",
            fontWeight: 600,
        },
    },
    body: {
        fontSize: 14,
        fontFamily: "'Source Sans Pro', sans-serif",
        color: "#666666",
        fontWeight: 400,
        [theme.breakpoints.up("lg")]: {
            padding: "4px 12px",
            "&:last-child": {
                paddingRight: 12,
            },
        },
        [theme.breakpoints.down("lg")]: {
            padding: "4px 8px",
            "&:last-child": {
                paddingRight: 8,
            },
        },
    },
});

export default withStyles(styles)(muiTableCell);
