import React from "react";
import Typography from "../common/TypographyWrapper";

export default function ProjectsDescription() {
    return (
        <>
            <Typography gutterBottom>
                Fieldprint Projects harness the power of collaboration across the agricultural value chain to implement
                locally-led conservation solutions and deliver sustainable outcomes through member-led continuous
                improvement projects. Fieldprint Projects enable our member organizations to design and implement
                projects in one of three Project Pathways, allowing for maximum flexibility and impact in delivering
                sustainable outcomes.
            </Typography>
            <Typography gutterBottom>
                <b>Incubation</b>: Creates enabling conditions to advance sustainable agriculture by helping farmers
                connect farming practices to sustainability outcome(s) through outreach and education.
            </Typography>
            <Typography gutterBottom>
                <b>Insight</b>: Offers farmers actionable sustainability insights and improves transparency on
                sustainability performance for the value chain through measurement.
            </Typography>
            <Typography>
                <b>Innovation</b>: Supports farmers in accelerating adoption of practices that deliver improved
                sustainability outcomes through value-added incentives and quantifying impact.
            </Typography>
        </>
    );
}
