import React from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "./withRouter";
import classNames from "classnames";

import Drawer from "@mui/material/Drawer";
import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import Badge from "@mui/material/Badge";
import LinearProgress from "@mui/material/LinearProgress";
import Tooltip from "@mui/material/Tooltip";

import withStyles from "@mui/styles/withStyles";
import Collapse from "@mui/material/Collapse";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

import CropRotationIcon from "./icons/CropRotationIcon";
import DashboardIcon from "./icons/DashboardIcon";
import DocumentIcon from "./icons/Document";
import FieldLibraryIcon from "./icons/FieldLibraryIcon";
import FieldprintProjectIcon from "./icons/FieldprintProjectIcon";
import SupportIcon from "./icons/SupportIcon";
import FarmIcon from "./icons/FarmIcon";
import FieldIcon from "./icons/FieldIcon";
import AddNewIcon from "./icons/AddNewIcon";
import WelcomeIcon from "./icons/Welcome";
import CometPlanner from "./icons/CometPlanner";
import BetaDashboardIcon from "./icons/BetaDashboardIcon";

import * as navActions from "./actions";
import { Farm } from "../field/models";
import { RotationSystem } from "../rotation/models";

const allFarmFields = Farm.selectAll((farm) => ({
    fields: farm.fields.orderBy("name").toRefArray(),
    ...farm.ref,
}));
const allRotationSystems = RotationSystem.selectAll();

const styles = (theme) => ({
    drawerPaper: {
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        overflowX: "hidden",
        width: theme.spacing(7),
        whiteSpace: "nowrap",
        backgroundColor: "#242424",
        position: "relative",
    },
    drawerOpen: {
        [theme.breakpoints.up("lg")]: {
            width: 325,
        },
        [theme.breakpoints.down("lg")]: {
            width: 275,
        },
        "& $listItemSecond": {
            marginLeft: theme.spacing(3),
            paddingRight: theme.spacing(4),
        },
        "& $listItemThird": {
            marginLeft: theme.spacing(6),
            paddingRight: theme.spacing(7),
        },
        "& $overflow": {
            overflowY: "auto",
        },
    },
    selectedPage: {
        backgroundColor: "#4e4e4e",
    },
    pageText: {
        color: "#aaaaaa !important",
    },
    selectedPageText: {
        color: "#aaaaaa !important",
    },
    topPageText: {
        color: "#ffffff",
    },
    listItem: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        "&:hover": {
            backgroundColor: "#4e4e4e !important",
        },
    },
    listItemDashboard: {
        borderBottom: "1px solid #00adee",
    },
    listItemField: {
        borderBottom: "1px solid #34a853",
    },
    listItemCrop: {
        borderBottom: "1px solid #7ac143",
    },
    listItemScenario: {
        borderBottom: "1px solid #8646fd",
    },
    listItemProject: {
        borderBottom: "1px solid #ff7d32",
    },
    listItemSupport: {
        borderBottom: "1px solid #aaaaaa",
    },
    listItemWelcome: {
        borderBottom: "1px solid #edb13c",
    },
    listItemDashboardSelected: {
        borderBottom: "3px solid #00adee",
    },
    listItemCropSelected: {
        borderBottom: "3px solid #7ac143",
    },
    listItemScenarioSelected: {
        borderBottom: "3px solid #8646fd",
    },
    listItemProjectSelected: {
        borderBottom: "3px solid #ff7d32",
    },
    listItemWelcomeSelected: {
        borderBottom: "3px solid #edb13c",
    },
    listItemSecond: {
        transition: theme.transitions.create("margin-left", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    listItemThird: {
        transition: theme.transitions.create("margin-left", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    overflow: {
        overflowY: "hidden",
        overflowX: "hidden",
    },
    noFlex: {
        padding: 0,
        //display: "flex",
        //height: 48
    },
    iconSize: {
        flexShrink: 0,
        width: "1em",
        height: "1em",
        fontSize: "24px",
    },
    iconSizeSmaller: {
        flexShrink: 0,
        width: ".8em",
        height: ".8em",
        fontSize: "24px",
    },
    farmHalf: {
        width: "50%",
        display: "inline-block",
        height: 48,
    },
    farmHalfText: {
        display: "inline-block",
        verticalAlign: "top",
        maxWidth: "110%",
    },
    farmHalfLarge: {
        [theme.breakpoints.up("lg")]: {
            width: "74%",
        },
        [theme.breakpoints.down("lg")]: {
            width: "68%",
        },
    },
    supportHalfLarge: {
        [theme.breakpoints.up("lg")]: {
            width: "82%",
        },
        [theme.breakpoints.down("lg")]: {
            width: "76%",
        },
    },
    textOverflow: {
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    textOverflowField: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: "110%",
    },
    scenarioBadge: {
        backgroundColor: "#8646fd",
    },
    secondaryText: {
        color: "#b2abab",
        fontSize: 10,
    },
    scenarioFirstChild: {
        paddingLeft: "16px !important",
    },
    scenarioListItem: {
        paddingBottom: 0,
        paddingTop: 2,
    },
    ovalBadge: {
        right: -30,
        width: 40,
        borderRadius: 20,
        top: 10,
        height: 15,
    },
});

class FarmFieldLibrary extends React.Component {
    toggleOpen = () => {
        if (this.props.farm.id === "DEMO3") {
            this.props.navSelectPage("welcome");
            this.props.history.push("/welcome/3");
        } else if (this.props.farm.id === "DEMO4") {
            this.props.navSelectPage("welcome");
            this.props.history.push("/welcome/1");
        } else if (this.props.farm.id === "DEMO5") {
            this.props.navSelectPage("welcome");
            this.props.history.push("/welcome/2");
        } else {
            this.props.navSelectPage("farm_" + this.props.farm.id);
            this.props.history.push("/farm/" + this.props.farm.id + "/edit");
        }
    };
    toggleOpen2 = () => {
        this.props.ormFarmUpdateLocalOnly({
            id: this.props.farm.id,
            open: !this.props.farm.open,
        });
    };

    render() {
        var { classes, farm, navState, navSelectPage } = this.props;

        const fieldsSort = farm.fields.sort(function (a, b) {
            if (a.name.toUpperCase() < b.name.toUpperCase()) return -1;
            if (a.name.toUpperCase() > b.name.toUpperCase()) return 1;
            return 0;
        });
        return (
            <div>
                <List className={classes.noFlex}>
                    <Tooltip enterDelay={500} title={farm.fpp_id ? `FPP ID: ${farm.fpp_id}` : farm.name || farm.id}>
                        <ListItem
                            button
                            onClick={this.toggleOpen}
                            className={classNames(
                                classes.farmHalf,
                                classes.farmHalfLarge,
                                classes.listItem,
                                classes.listItemSupport,
                                classes.listItemSecond,
                                navState.selectedPage === "farm_" + farm.id && classes.selectedPage,
                            )}
                        >
                            <ListItemIcon className={classes.pageText}>
                                <FarmIcon className={classes.iconSize} />
                            </ListItemIcon>
                            <ListItemText
                                className={classes.farmHalfText}
                                primary={farm.name || farm.id}
                                classes={{
                                    primary: classNames(
                                        classes.textOverflow,
                                        navState.selectedPage === "farm_" + farm.id
                                            ? classes.selectedPageText
                                            : classes.pageText,
                                    ),
                                }}
                            />
                        </ListItem>
                    </Tooltip>
                    <Tooltip enterDelay={500} title="View fields in farm">
                        <ListItem
                            button
                            onClick={this.toggleOpen2}
                            className={classNames(
                                classes.farmHalf,
                                classes.listItem,
                                classes.listItemSupport,
                                navState.selectedPage === "farm_" + farm.id && classes.selectedPage,
                            )}
                        >
                            <ListItemText
                                classes={{
                                    primary:
                                        navState.selectedPage === "farm_" + farm.id
                                            ? classes.selectedPageText
                                            : classes.pageText,
                                }}
                            />
                            {farm.open ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                    </Tooltip>
                    <Collapse in={farm.open} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {fieldsSort.map((field) => (
                                <Tooltip
                                    key={field.id}
                                    enterDelay={500}
                                    title={field.fpp_id ? `FPP ID: ${field.fpp_id}` : field.name || field.id}
                                >
                                    <ListItem
                                        key={field.id}
                                        button
                                        className={classNames(
                                            classes.listItem,
                                            classes.listItemThird,
                                            navState.selectedPage === "field_" + field.id && classes.selectedPage,
                                        )}
                                        component={Link}
                                        to={"/field/" + field.id}
                                        onClick={() => navSelectPage("field_" + field.id)}
                                    >
                                        <ListItemIcon className={classes.pageText}>
                                            <FieldIcon className={classes.iconSizeSmaller} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={field.name || field.id}
                                            classes={{
                                                primary: classNames(
                                                    classes.textOverflowField,
                                                    navState.selectedPage === "field_" + field.id
                                                        ? classes.selectedPageText
                                                        : classes.pageText,
                                                ),
                                            }}
                                        />
                                    </ListItem>
                                </Tooltip>
                            ))}
                            <ListItem
                                button
                                className={classNames(
                                    classes.listItem,
                                    classes.listItemSupport,
                                    classes.listItemThird,
                                    navState.selectedPage === "field_new_" + farm.id && classes.selectedPage,
                                )}
                                component={Link}
                                to={"/farm/" + farm.id + "/addfield"}
                                onClick={() => navSelectPage("field_new_" + farm.id)}
                            >
                                <ListItemIcon className={classes.pageText}>
                                    <AddNewIcon className={classes.iconSizeSmaller} />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Add Field"
                                    classes={{
                                        primary:
                                            navState.selectedPage === "field_new_" + farm.id
                                                ? classes.selectedPageText
                                                : classes.pageText,
                                    }}
                                />
                            </ListItem>
                        </List>
                    </Collapse>
                </List>
            </div>
        );
    }
}

class Navigation extends React.Component {
    continueDashboardLegacy = () => {
        this.props.navSelectPage("dashboardlegacy");
        this.props.history.push("/dashboardlegacy");
    };

    continueDashboard = () => {
        this.props.navSelectPage("dashboard");
        this.props.history.push("/dashboard");
    };

    continueWelcome = () => {
        this.props.navSelectPage("welcome");
        this.props.history.push("/welcome");
    };

    fieldLibraryClick = () => {
        this.props.navToggleFieldLibrary();
    };

    navigationClick = () => {
        if (this.props.onNavOpenChange) {
            this.props.onNavOpenChange(!this.props.navOpen);
        } else {
            this.props.navToggleNavigation();
        }
    };

    projectClick = () => {
        this.props.navToggleProject();
    };

    renderThumb({ style, ...props }) {
        const thumbStyle = {
            backgroundColor: "#f15d22",
        };
        return <div style={{ ...style, ...thumbStyle }} />;
    }

    render() {
        const { classes, farms, ormFarmUpdateLocalOnly, syncState, authState, navState, navSelectPage, history } =
            this.props;

        const isRealUser = authState && authState.user && !authState.user.guest;
        const isProjectAdmin = isRealUser && authState.user.is_project_administrator;
        const syncProgress = Math.round((syncState.progress / syncState.total) * 100);

        var farmsSort;
        if (isRealUser) {
            farmsSort = farms.sort(function (a, b) {
                if (a.name.toUpperCase() < b.name.toUpperCase()) return -1;
                if (a.name.toUpperCase() > b.name.toUpperCase()) return 1;
                return 0;
            });
        } else {
            var ordering = {};
            var sortOrder = ["DEMO4", "DEMO5", "DEMO3", "DEMO2"];
            for (var i = 0; i < sortOrder.length; i++) ordering[sortOrder[i]] = i;
            farmsSort = farms.sort(function (a, b) {
                return ordering[a.id] - ordering[b.id] || a.name.localeCompare(b.name);
            });
        }

        // Initial state in index no longer working?
        if (!navState.navigationOpen && navState.navigationOpen !== false) this.props.navToggleNavigation();

        const navOpen = this.props.navOpen !== undefined ? this.props.navOpen : navState.navigationOpen,
            libraryOpen = navOpen && navState.libraryOpen,
            projectOpen = navOpen && navState.projectOpen;

        return (
            <Drawer
                id="drawContainer"
                variant="permanent"
                open={true}
                classes={{
                    paper: classNames(classes.drawerPaper, navOpen && classes.drawerOpen),
                }}
            >
                <Scrollbars
                    renderThumbVertical={this.renderThumb}
                    autoHide
                    autoHideTimeout={1000}
                    autoHideDuration={200}
                >
                    <div>
                        <div>
                            {authState && authState.user && authState.user.guest && (
                                <>
                                    <ListItem
                                        title="Welcome"
                                        button
                                        onClick={this.continueWelcome}
                                        className={classNames(
                                            classes.listItem,
                                            navState.selectedPage === "welcome"
                                                ? classes.listItemWelcomeSelected
                                                : classes.listItemWelcome,
                                            navState.selectedPage === "welcome" && classes.selectedPage,
                                        )}
                                    >
                                        <ListItemIcon>
                                            <WelcomeIcon style={{ color: "#edb13c" }} className={classes.iconSize} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary="Welcome"
                                            classes={{
                                                primary: classes.topPageText,
                                            }}
                                        />
                                    </ListItem>
                                    <ListItem
                                        title="Dashboard"
                                        button
                                        onClick={this.continueDashboardLegacy}
                                        className={classNames(
                                            classes.listItem,
                                            navState.selectedPage === "dashboardlegacy"
                                                ? classes.listItemDashboardSelected
                                                : classes.listItemDashboard,
                                            navState.selectedPage === "dashboardlegacy" && classes.selectedPage,
                                        )}
                                    >
                                        <ListItemIcon>
                                            <DashboardIcon className={classes.iconSize} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary="Dashboard"
                                            classes={{
                                                primary: classes.topPageText,
                                            }}
                                        />
                                    </ListItem>
                                </>
                            )}
                            {authState && authState.user && !authState.user.guest && (
                                <ListItem
                                    title="Dashboard"
                                    button
                                    onClick={this.continueDashboard}
                                    className={classNames(
                                        classes.listItem,
                                        navState.selectedPage === "dashboard"
                                            ? classes.listItemDashboardSelected
                                            : classes.listItemDashboard,
                                        navState.selectedPage === "dashboard" && classes.selectedPage,
                                    )}
                                >
                                    <ListItemIcon>
                                        <DashboardIcon className={classes.iconSize} />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Dashboard"
                                        classes={{
                                            primary: classes.topPageText,
                                        }}
                                    />
                                </ListItem>
                            )}
                            <ListItem
                                title="Field Library"
                                button
                                onClick={this.fieldLibraryClick}
                                className={classNames(classes.listItem, classes.listItemField)}
                            >
                                <ListItemIcon>
                                    <FieldLibraryIcon className={classes.iconSize} />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Field Library"
                                    classes={{
                                        primary: classes.topPageText,
                                    }}
                                />
                                {libraryOpen ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse
                                in={libraryOpen}
                                timeout="auto"
                                unmountOnExit
                                className={classNames(classes.overflow)}
                            >
                                {farmsSort.map((farm) => (
                                    <FarmFieldLibrary
                                        farm={farm}
                                        key={farm.id}
                                        classes={classes}
                                        ormFarmUpdateLocalOnly={ormFarmUpdateLocalOnly}
                                        navSelectPage={navSelectPage}
                                        history={history}
                                        navState={navState}
                                    />
                                ))}
                                {isRealUser && (
                                    <ListItem
                                        button
                                        className={classNames(
                                            classes.listItem,
                                            classes.listItemSupport,
                                            classes.listItemSecond,
                                            navState.selectedPage === "farm_new" && classes.selectedPage,
                                        )}
                                        component={Link}
                                        to={"/farm/add"}
                                        onClick={() => navSelectPage("farm_new")}
                                    >
                                        <ListItemIcon className={classes.pageText}>
                                            <AddNewIcon className={classes.iconSize} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary="Add Farm"
                                            classes={{
                                                primary:
                                                    navState.selectedPage === "farm_new"
                                                        ? classes.selectedPageText
                                                        : classes.pageText,
                                            }}
                                        />
                                    </ListItem>
                                )}
                            </Collapse>
                            <ListItem
                                title="Crop Rotation Library"
                                button
                                component={Link}
                                to="/rotation/library"
                                onClick={() => navSelectPage("rotation")}
                                className={classNames(
                                    classes.listItem,
                                    navState.selectedPage === "rotation"
                                        ? classes.listItemCropSelected
                                        : classes.listItemCrop,
                                    navState.selectedPage === "rotation" && classes.selectedPage,
                                )}
                            >
                                <ListItemIcon>
                                    <CropRotationIcon className={classes.iconSize} />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Crop Rotation Library"
                                    classes={{
                                        primary: classes.topPageText,
                                    }}
                                />
                            </ListItem>
                            {isProjectAdmin && (
                                <div>
                                    <ListItem
                                        title="Projects"
                                        button
                                        component={Link}
                                        to="/projectadmin"
                                        onClick={() => navSelectPage("project_admin")}
                                        className={classNames(
                                            classes.farmHalf,
                                            classes.supportHalfLarge,
                                            classes.listItem,
                                            navState.selectedPage === "project_admin"
                                                ? classes.listItemProjectSelected
                                                : classes.listItemProject,
                                            navState.selectedPage === "project_admin" && classes.selectedPage,
                                        )}
                                    >
                                        <ListItemIcon>
                                            <FieldprintProjectIcon className={classes.iconSize} />
                                        </ListItemIcon>
                                        <ListItemText
                                            className={classes.farmHalfText}
                                            primary="Projects"
                                            classes={{
                                                primary: classes.topPageText,
                                            }}
                                        />
                                    </ListItem>
                                    <ListItem
                                        button
                                        onClick={this.projectClick}
                                        className={classNames(
                                            classes.farmHalf,
                                            classes.listItem,
                                            classes.listItemProject,
                                            navState.selectedPage === "project_admin"
                                                ? classes.listItemProjectSelected
                                                : classes.listItemProject,
                                            navState.selectedPage === "project_admin" && classes.selectedPage,
                                        )}
                                    >
                                        <ListItemText
                                            classes={{
                                                primary: classes.pageText,
                                            }}
                                        />
                                        {projectOpen ? <ExpandLess /> : <ExpandMore />}
                                    </ListItem>
                                    <Collapse in={projectOpen} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            <ListItem
                                                button
                                                className={classNames(
                                                    classes.listItem,
                                                    classes.listItemSupport,
                                                    classes.listItemSecond,
                                                    navState.selectedPage === "project" && classes.selectedPage,
                                                )}
                                                component={Link}
                                                to={"/project"}
                                                onClick={() => navSelectPage("project")}
                                            >
                                                <ListItemIcon className={classes.pageText}>
                                                    <DocumentIcon className={classes.iconSize} />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary="My Fields"
                                                    classes={{
                                                        primary:
                                                            navState.selectedPage === "project"
                                                                ? classes.selectedPageText
                                                                : classes.pageText,
                                                    }}
                                                />
                                            </ListItem>
                                        </List>
                                    </Collapse>
                                </div>
                            )}
                            {!isProjectAdmin && isRealUser && (
                                <div>
                                    <ListItem
                                        title="Projects"
                                        button
                                        component={Link}
                                        to="/project"
                                        onClick={() => navSelectPage("project")}
                                        className={classNames(
                                            classes.listItem,
                                            navState.selectedPage === "project"
                                                ? classes.listItemProjectSelected
                                                : classes.listItemProject,
                                            navState.selectedPage === "project" && classes.selectedPage,
                                        )}
                                    >
                                        <ListItemIcon>
                                            <FieldprintProjectIcon className={classes.iconSize} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary="Projects"
                                            classes={{
                                                primary: classes.topPageText,
                                            }}
                                        />
                                    </ListItem>
                                </div>
                            )}
                            <ListItem
                                title="COMET Planner Scenario Tool"
                                button
                                component={Link}
                                to="/scenario"
                                onClick={() => navSelectPage("scenario")}
                                className={classNames(
                                    classes.scenarioListItem,
                                    classes.listItem,
                                    navState.selectedPage === "scenario"
                                        ? classes.listItemScenarioSelected
                                        : classes.listItemScenario,
                                    navState.selectedPage === "scenario" && classes.selectedPage,
                                )}
                            >
                                <ListItemIcon>
                                    <CometPlanner className={classes.iconSize} />
                                </ListItemIcon>
                                <Badge
                                    color="primary"
                                    classes={{ colorPrimary: classes.scenarioBadge, badge: classes.ovalBadge }}
                                    badgeContent="New"
                                >
                                    <ListItemText
                                        primary="COMET-Planner"
                                        secondary="SCENARIO TOOL"
                                        classes={{
                                            primary: classes.topPageText,
                                            secondary: classes.secondaryText,
                                            inset: classes.scenarioFirstChild,
                                        }}
                                    />
                                </Badge>
                            </ListItem>
                            {!syncState.ready && <LinearProgress variant="determinate" value={syncProgress} />}
                            {/* <ListItem
                                title="Beta Dashboard"
                                button
                                component={Link}
                                to={"/insights"}
                                onClick={() => navSelectPage("insights")}
                                className={classNames(classes.listItem, classes.listItemField)}>
                                <ListItemIcon>
                                    <BetaDashboardIcon className={classes.iconSize} />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Beta Dashboard"
                                    classes={{
                                        primary: classes.topPageText
                                    }}
                                />
                            </ListItem> */}
                            <List className={classes.noFlex}>
                                <ListItem
                                    title="Support"
                                    button
                                    component="a"
                                    href="https://support.fieldtomarket.org/"
                                    target="_blank"
                                    className={classNames(classes.listItem, classes.listItemSupport)}
                                >
                                    <ListItemIcon>
                                        <SupportIcon className={classes.iconSize} />
                                    </ListItemIcon>
                                    <ListItemText
                                        className={classes.farmHalfText}
                                        primary="Support"
                                        classes={{
                                            primary: classes.pageText,
                                        }}
                                    />
                                </ListItem>
                                <ListItem
                                    title="Collapse/Expand Navigation"
                                    button
                                    onClick={this.navigationClick}
                                    className={classes.listItem}
                                >
                                    <ListItemIcon className={classes.pageText}>
                                        {navOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Collapse Panel"
                                        classes={{
                                            primary: classes.pageText,
                                        }}
                                    />
                                </ListItem>
                            </List>
                        </div>
                    </div>
                </Scrollbars>
            </Drawer>
        );
    }
}

Navigation = connect(
    (state) => ({
        farms: allFarmFields(state),
        rotationSystems: allRotationSystems(state),
        navState: state.nav,
        authState: state.auth,
        syncState: state.sync,
    }),
    {
        ...Farm.actions,
        ...RotationSystem.actions,
        ...navActions,
    },
)(Navigation);

export default withStyles(styles)(withRouter(Navigation));
