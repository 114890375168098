export const NAV_TOGGLE_NAVIGATION = "NAV_TOGGLE_NAVIGATION";
export const NAV_TOGGLE_FIELD_LIBRARY = "NAV_TOGGLE_FIELD_LIBRARY";
export const NAV_TOGGLE_PROJECT = "NAV_TOGGLE_PROJECT";
export const NAV_SELECT_PAGE = "NAV_SELECT_PAGE";
export const NAV_TOGGLE_DASH = "NAV_TOGGLE_DASH";
export const DASHBOARD_SELECTOR = "DASHBOARD_SELECTOR";
export const FARM_MESSAGE = "FARM_MESSAGE";
export const SW_CHECKING = "SW_CHECKING";
export const SW_REGISTERED = "SW_REGISTERED";
export const SW_INSTALLED = "SW_INSTALLED";
export const SW_UPDATE = "SW_UPDATE";
export const SW_ERROR = "SW_ERROR";
export const SW_DISABLED = "SW_DISABLED";
export const STORE_READY = "STORE_READY";
export const TOGGLE_ACCORDIAN = "TOGGLE_ACCORDIAN";

export function navToggleNavigation() {
    return {
        type: NAV_TOGGLE_NAVIGATION,
    };
}

export function navToggleFieldLibrary() {
    return {
        type: NAV_TOGGLE_FIELD_LIBRARY,
    };
}

export function navToggleDash() {
    return {
        type: NAV_TOGGLE_DASH,
    };
}

export function navToggleProject() {
    return {
        type: NAV_TOGGLE_PROJECT,
    };
}

export function dashToggleMapTable(button) {
    return {
        type: DASHBOARD_SELECTOR,
        button: button,
    };
}

export function farmShowMessage() {
    return {
        type: FARM_MESSAGE,
    };
}

export function navSelectPage(page) {
    return {
        type: NAV_SELECT_PAGE,
        page: page,
    };
}

export function swChecking() {
    return { type: SW_CHECKING };
}

export function swRegistered() {
    return { type: SW_REGISTERED };
}

export function swInstalled() {
    return { type: SW_INSTALLED };
}

export function swUpdate() {
    return { type: SW_UPDATE };
}

export function swError(error) {
    return { type: SW_ERROR, error: error };
}

export function swDisabled(reason) {
    return { type: SW_DISABLED, reason: reason };
}

export function storeReady() {
    return { type: STORE_READY };
}

export function toggleAccordian(id) {
    return { type: TOGGLE_ACCORDIAN, payload: id };
}
