import React, { Component } from "react";
import Card from "@mui/material/Card";
import Typography from "../../../common/TypographyWrapper";
import { PieChart, Pie, Cell, Legend } from "recharts";
import { withTableStyles, TD, TH, Paragraph } from "../Table";
import { Submetric } from "./EnergyUseDetail";
import Callout from "../Callout";

import { getValue } from "../../../../api/utils";
import {
    getUnitsValue,
    getLabel,
    getDescription,
    round,
    chartValue,
    PIE_COLORS,
    ENERGY_USE_SUBMETRICS,
    PIE_CHART_LABEL,
} from "../StaticData";
import { RICE } from "../../../../api/constants";

const GHG_SUBMETRICS = ["nitrousOxide", "rice", "residueBurning"];

const styles = {
    pieLegend: {
        top: 10,
        left: 570,
        lineHeight: "22px",
    },
};

class GreenhouseGas extends Component {
    state = {
        activeIndex: null,
    };

    onPieEnter = (data, index) => {
        this.setState({
            activeIndex: index,
        });
    };

    render() {
        const { indicator, classes, printable } = this.props,
            P = printable ? Paragraph : Typography,
            fieldprintUnit = getUnitsValue(indicator, "fieldprintResult.unit", true),
            ghgUnit = getUnitsValue(indicator, "totalGreenhouse.unit", true),
            getVal = (path) => round(getValue(indicator, path), indicator.metric),
            getChartVal = (path) => chartValue(getValue(indicator, path), indicator.metric),
            ghg = getVal("totalGreenhouse.value"),
            fieldprint = getVal("fieldprintResult.value"),
            getVals = (component, parts) => ({
                name: getLabel(component),
                yieldValue: getVal(component + ".fieldprintResult.value"),
                yieldChartValue: getChartVal(component + ".fieldprintResult.value"),
                acreValue: getVal(component + ".greenhouse.value"),
                parts: parts
                    .map((part) => ({
                        name: getLabel(`${component}.${part}`),
                        yieldValue: getVal(`${component}.${part}.fieldprintResult.value`),
                        acreValue: getVal(`${component}.${part}.greenhouse.value`),
                    }))
                    .filter((part) => part.yieldValue),
            }),
            energyGHG = Object.entries(ENERGY_USE_SUBMETRICS).map(([component, parts]) => getVals(component, parts)),
            otherGHG = GHG_SUBMETRICS.filter((metric) => metric !== "rice" || indicator.cropyear.crop === RICE).map(
                (component) => getVals(component, []),
            ),
            pieChartData = energyGHG.concat(otherGHG).map(({ name, yieldChartValue: value }) => ({ name, value }));

        var ghgSubtotal = 0;
        var fieldprintSubtotal = 0;
        Object.entries(ENERGY_USE_SUBMETRICS).forEach(([component]) => {
            ghgSubtotal += getValue(indicator, component + ".greenhouse.value");
            fieldprintSubtotal += getValue(indicator, component + ".fieldprintResult.value");
        });
        ghgSubtotal = round(ghgSubtotal, indicator.metric);
        fieldprintSubtotal = round(fieldprintSubtotal, indicator.metric);

        return (
            <>
                <Callout
                    title={printable ? "" : "Breakdown of Greenhouse Gas Score Components"}
                    printable={printable}
                    split={printable ? 12 : 9}
                    variant="triangle2"
                >
                    <div>
                        <table className={classes.dataTable}>
                            <thead>
                                <tr>
                                    <TH noborder printable={printable} />
                                    <TH noborder printable={printable} colspan={2}>
                                        GHG Emissions
                                    </TH>
                                </tr>
                                <tr>
                                    <TH printable={printable}>Component</TH>
                                    <TH printable={printable}>({ghgUnit})</TH>
                                    <TH printable={printable}>({fieldprintUnit})</TH>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <TD subtotal first printable={printable} colspan={3}>
                                        Emissions associated with energy used on the Farm
                                    </TD>
                                </tr>
                                {energyGHG.map((component) => (
                                    <Submetric suffix="Energy Emissions" {...component} printable={printable} />
                                ))}
                                <tr>
                                    <TD first subtotal printable={printable} indentCollapse={!printable}>
                                        Energy Subtotal
                                    </TD>
                                    <TD subtotal printable={printable}>
                                        {ghgSubtotal}
                                    </TD>
                                    <TD subtotal printable={printable}>
                                        {fieldprintSubtotal}
                                    </TD>
                                </tr>
                                {otherGHG.map((component) => (
                                    <>
                                        <tr>
                                            <TD first printable={printable} indentCollapse={!printable}>
                                                {component.name}
                                            </TD>
                                            <TD printable={printable}>{component.acreValue}</TD>
                                            <TD printable={printable}>{component.yieldValue}</TD>
                                        </tr>
                                    </>
                                ))}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <TD first total printable={printable} indentCollapse={!printable}>
                                        Total GHG Emissions
                                    </TD>
                                    <TD total printable={printable}>
                                        {ghg}
                                    </TD>
                                    <TD total printable={printable}>
                                        {fieldprint}
                                    </TD>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                    <P>{getDescription(printable ? "greenhouseGasPartsPrint" : "greenhouseGasParts")}</P>
                </Callout>
                {!printable && (
                    <Callout printable={printable} split={"780px"} variant="triangleTop3">
                        <Card
                            style={{
                                width: "780px",
                                "text-align": "center",
                            }}
                        >
                            <Typography>GHG Components ({fieldprintUnit})</Typography>
                            <PieChart width={780} height={280}>
                                <Pie
                                    data={pieChartData}
                                    cx={270}
                                    cy={130}
                                    innerRadius={60}
                                    outerRadius={80}
                                    activeShape={(props) => PIE_CHART_LABEL(props, fieldprintUnit)}
                                    onMouseEnter={this.onPieEnter}
                                    activeIndex={this.state.activeIndex}
                                >
                                    {pieChartData.map((entry, index) => (
                                        <Cell fill={PIE_COLORS[index % PIE_COLORS.length]} />
                                    ))}
                                </Pie>
                                <Legend
                                    layout="vertical"
                                    verticalAlign="middle"
                                    wrapperStyle={styles.pieLegend}
                                    onMouseEnter={this.onPieEnter}
                                    iconSize={7}
                                />
                            </PieChart>
                        </Card>
                        <Typography>{getDescription("greenhouseGasChart")}</Typography>
                    </Callout>
                )}
            </>
        );
    }
}

export default withTableStyles(styles)(GreenhouseGas);
