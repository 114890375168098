/* global google */
import React, { Component } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import * as jsts from "jsts";

import { GoogleMap, Marker, withGoogleMap, withScriptjs } from "react-google-maps";
import { MAP } from "react-google-maps/lib/constants";

import { Field } from "../field/models";
import WarningDialog from "./WarningDialog";
import { connect } from "react-redux";
import { apiFetch } from "../../api/fetch";

import SsurgoMapControl from "./SsurgoMapControl";
import ssurgoMarkerPng from "./icons/SsurgoMarker.png";

import Button from "../common/ButtonWrapper";

const ssurgoStyleOn = {
    fillColor: "yellow",
    strokeWeight: 1,
    strokeColor: "orange",
    fillOpacity: 0,
};

const ssurgoStyleOff = {
    fillColor: "yellow",
    strokeWeight: 0,
    strokeColor: "orange",
    fillOpacity: 0,
};

const ssurgoSoilButtonStyle = {
    direction: "ltr",
    overflow: "hidden",
    "text-align": "center",
    height: "40px",
    display: "table-cell",
    "vertical-align": "middle",
    position: "relative",
    color: "rgb(0, 0, 0)",
    "font-family": "Roboto, Arial, sans-serif",
    "user-select": "none",
    "font-size": "18px",
    "background-color": "rgb(255, 255, 255)",
    padding: "0px 17px",
    "border-bottom-right-radius": "2px",
    "border-top-right-radius": "2px",
    "background-clip": "padding-box",
    "box-shadow": "rgba(0, 0, 0, 0.3) 0px 1px 4px -1px",
    "min-width": "66px",
    "border-left": "0px",
    "font-weight": "500",
    "margin-left": "10px",
};

var findSelfIntersects = function (googlePolygonPath) {
    var coordinates = googleMaps2JTS(googlePolygonPath);
    var geometryFactory = new jsts.geom.GeometryFactory();
    var shell = geometryFactory.createLinearRing(coordinates);
    var jstsPolygon = geometryFactory.createPolygon(shell);

    // if the geometry is aleady a simple linear ring, do not
    // try to find self intersection points.
    var validator = new jsts.operation.IsSimpleOp(jstsPolygon);
    if (validator.isSimpleLinearGeometry(jstsPolygon)) {
        return;
    }

    var res = [];
    var graph = new jsts.geomgraph.GeometryGraph(0, jstsPolygon);
    var cat = new jsts.operation.valid.ConsistentAreaTester(graph);
    var r = cat.isNodeConsistentArea();
    if (!r) {
        var pt = cat.getInvalidPoint();
        res.push([pt.x, pt.y]);
    }
    return res;
};

var googleMaps2JTS = function (boundaries) {
    var coordinates = [];
    for (var i = 0; i < boundaries.getLength(); i++) {
        coordinates.push(new jsts.geom.Coordinate(boundaries.getAt(i).lat(), boundaries.getAt(i).lng()));
    }
    coordinates.push(coordinates[0]);
    return coordinates;
};

class MapContainerWrapped extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bounds: null,
            center: {
                lat: 39.4,
                lng: -98.35,
            },
            markers: [],
            drawingManager: null,
            map: null,
            shapes: [],
            helpOpen: false,
            helpText: null,

            ssurgoEnabled: true,
            ssurgoOn: false,
            ssurgoIds: [],
            ssurgoStyle: ssurgoStyleOn,
            ssurgoMarkers: [],
            ssurgoFeatures: [],
            prevBoundsList: [],
            updateSsurgo: true,
        };
        this.handlePlacesChanged = this.handlePlacesChanged.bind(this);
        this.handleOverlayComplete = this.handleOverlayComplete.bind(this);
        this.handleMapMounted = this.handleMapMounted.bind(this);
        this.handleBoundsChanged = this.handleBoundsChanged.bind(this);
        this.CalculateAcres = this.CalculateAcres.bind(this);
    }
    static contextTypes = { [MAP]: PropTypes.object };

    _map = null;
    _drawManager = null;
    _shapes = [];

    componentDidMount() {
        if (!this.props.readonly) {
            this.props.onRef(this);
        }
    }

    componentWillUnmount() {
        if (!this.props.readonly) {
            this.props.onRef(undefined);
        }
        this.setState({ isMounted: false });
    }

    handleMapMounted = (e) => {
        if (!e || this._map) return;
        this._map = e;
        this.setState({
            map: e,
        });
        this.ReloadMapData();
        if (!this.state.drawingManager) {
            let drawManager = new google.maps.drawing.DrawingManager({
                drawingControl: false,
                drawMode: null,
                polygonOptions: {
                    editable: true,
                    strokeColor: "#ffff00",
                    fillColor: "#00ffff",
                    strokeWeight: 1,
                    fillOpacity: 0.1,
                },
                rectangleOptions: {
                    editable: true,
                    strokeColor: "#ffff00",
                    fillColor: "#00ffff",
                    strokeWeight: 1,
                    fillOpacity: 0.1,
                },
            });
            google.maps.event.addListener(drawManager, "overlaycomplete", this.handleOverlayComplete);

            // Check for self intersection
            const mContainer = this;
            google.maps.event.addListener(drawManager, "rectanglecomplete", function (rectangle) {
                var usa = [
                    { lat: 24.396308, lng: -124.848974 },
                    { lat: 24.396308, lng: -66.885444 },
                    { lat: 49.384358, lng: -66.885444 },
                    { lat: 49.384358, lng: -124.848974 },
                ];
                var bboxusa = new google.maps.Polygon({ paths: usa });
                var hawaii = [
                    { lat: 18.7589, lng: -160.2925 },
                    { lat: 18.7589, lng: -154.7774 },
                    { lat: 22.2365, lng: -154.7774 },
                    { lat: 22.2365, lng: -160.2925 },
                ];
                var bboxhawaii = new google.maps.Polygon({ paths: hawaii });
                var alaska = [
                    { lat: 51.98, lng: -168.76 },
                    { lat: 51.98, lng: -130.44 },
                    { lat: 71.58, lng: -130.44 },
                    { lat: 71.58, lng: -168.76 },
                ];
                var bboxalaska = new google.maps.Polygon({ paths: alaska });

                var isOutsideUsa = google.maps.geometry.poly.containsLocation(
                    rectangle.getBounds().getCenter(),
                    bboxusa,
                );
                var isOutsideHawaii = google.maps.geometry.poly.containsLocation(
                    rectangle.getBounds().getCenter(),
                    bboxhawaii,
                );
                var isOutsideAlaska = google.maps.geometry.poly.containsLocation(
                    rectangle.getBounds().getCenter(),
                    bboxalaska,
                );
                if (!isOutsideUsa && !isOutsideHawaii && !isOutsideAlaska) {
                    mContainer.setState({ helpOpen: true, helpText: "Field must be within the United States." });
                    mContainer.props.clearClick();
                    return;
                }
            });
            google.maps.event.addListener(drawManager, "polygoncomplete", function (polygon) {
                var intersects = findSelfIntersects(polygon.getPath());
                if (intersects && intersects.length) {
                    mContainer.setState({ helpOpen: true, helpText: "Field cannot self intersect." });
                    mContainer.props.clearClick();
                    return;
                }
                var usa = [
                    { lat: 24.396308, lng: -124.848974 },
                    { lat: 24.396308, lng: -66.885444 },
                    { lat: 49.384358, lng: -66.885444 },
                    { lat: 49.384358, lng: -124.848974 },
                ];
                var bboxusa = new google.maps.Polygon({ paths: usa });
                var hawaii = [
                    { lat: 18.7589, lng: -160.2925 },
                    { lat: 18.7589, lng: -154.7774 },
                    { lat: 22.2365, lng: -154.7774 },
                    { lat: 22.2365, lng: -160.2925 },
                ];
                var bboxhawaii = new google.maps.Polygon({ paths: hawaii });
                var alaska = [
                    { lat: 51.98, lng: -168.76 },
                    { lat: 51.98, lng: -130.44 },
                    { lat: 71.58, lng: -130.44 },
                    { lat: 71.58, lng: -168.76 },
                ];
                var bboxalaska = new google.maps.Polygon({ paths: alaska });
                polygon.latLngs.forEach(function (ln) {
                    ln.forEach(function (l) {
                        var isOutsideUsa = google.maps.geometry.poly.containsLocation(
                            new google.maps.LatLng({ lat: l.lat(), lng: l.lng() }),
                            bboxusa,
                        );
                        var isOutsideHawaii = google.maps.geometry.poly.containsLocation(
                            new google.maps.LatLng({ lat: l.lat(), lng: l.lng() }),
                            bboxhawaii,
                        );
                        var isOutsideAlaska = google.maps.geometry.poly.containsLocation(
                            new google.maps.LatLng({ lat: l.lat(), lng: l.lng() }),
                            bboxalaska,
                        );
                        if (!isOutsideUsa && !isOutsideHawaii && !isOutsideAlaska) {
                            mContainer.setState({
                                helpOpen: true,
                                helpText: "Field must be within the United States.",
                            });
                            mContainer.props.clearClick();
                            return;
                        }
                    });
                });
            });

            if (drawManager.getDrawingMode() == null) {
                drawManager.setMap(this.context[MAP]);
            }

            this.setState({
                drawingManager: drawManager,
            });
        }
    };

    handlePlacesChanged() {
        if (!this._autoComplete) return;

        const place = this._autoComplete.getPlace(); //this._searchBox.getPlaces();

        if (place.geometry) {
            const markers = [{ position: place.geometry.location }];
            const mapCenter = { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() };
            const bounds = new google.maps.LatLngBounds();
            if (place.geometry.viewport) {
                bounds.union(place.geometry.viewport);
            } else {
                bounds.extend(place.geometry.location);
            }
            this.setState({
                center: mapCenter,
                markers,
                bounds: bounds,
            });
            this._map.fitBounds(bounds);
        }
    }

    ReloadMapData = () => {
        //load map with existing data
        const { field } = this.props,
            { geometry } = field;

        if (!geometry) {
            return;
        }

        this.loadGeoJSON(geometry, true);
    };

    loadGeoJSON(geometry, preserveLast) {
        const map = this.context[MAP];
        if (geometry.type === "MultiPolygon") {
            var allBounds = new google.maps.LatLngBounds(),
                area = 0;
            geometry.coordinates.forEach((coords) => {
                const polygon = new google.maps.Polygon({
                    paths: this.ConvertCoordToLatLong(coords[0]),
                    editable: false,
                    strokeColor: "#ffff00",
                    fillColor: "#00ffff",
                    strokeWeight: 1,
                    fillOpacity: 0.1,
                });
                allBounds = allBounds.union(this.getBounds(polygon));
                polygon.setMap(map);
                const sqmts = google.maps.geometry.spherical.computeArea(polygon.getPath());
                area += sqmts * 0.00024711;
                this.state.shapes.push({
                    type: "polygon",
                    overlay: polygon,
                });
            });
            map.fitBounds(allBounds);
            if (this.props.readonly) {
                return;
            }
            const acres = area.toFixed(2);
            this.props.formApi.setValue("size_calc", `${acres} acre`);
            this.props.formApi.setValue("size", `${acres} acre`);
            this.props.formApi.setValue("geometry", geometry);
            this.props.formApi.setValue("diffAcres", "(0%  Diff.)");
            this.props.handleConfirmBoundary(acres);
        } else {
            var mapData = new google.maps.Polygon({
                paths: this.ConvertCoordToLatLong(geometry.coordinates[0]),
                editable: !this.props.readonly,
                strokeColor: "#ffff00",
                fillColor: "#00ffff",
                strokeWeight: 1,
                fillOpacity: 0.1,
            });
            mapData.setMap(map);
            this.state.shapes.push({
                type: "polygon",
                overlay: mapData,
            });
            map.fitBounds(this.getBounds(mapData));

            if (this.props.readonly) {
                return;
            }

            const evt = {
                type: google.maps.drawing.OverlayType.POLYGON,
                overlay: mapData,
            };
            this.addEditEventListeners(evt);

            // Recalculate acres based on geometry since not saved in DB
            this.RecalculateShapes(evt, preserveLast);
        }

        google.maps.event.addListener(this.context[MAP], "dragend", () => {
            this.setState({ updateSsurgo: true });
        });

        google.maps.event.addListener(this.context[MAP], "zoom_changed", () => {
            let ssurgoEnabled = this._map.getZoom() < 13 ? false : true;
            this.setState({ ssurgoEnabled: ssurgoEnabled, updateSsurgo: true });
        });
    }

    getBounds = (mapData) => {
        var bounds = new google.maps.LatLngBounds();
        mapData.getPath().forEach((element) => {
            bounds.extend(element);
        });
        return bounds;
    };

    ConvertCoordToLatLong = (path) => {
        var fixedPath = [];
        // arr.map(path => ({ lat: person.id, text: person.name }));
        for (var i = 0; i < path.length; i++) {
            fixedPath.push({
                lat: path[i][1],
                lng: path[i][0],
            });
        }
        return fixedPath;
    };

    toggleSsurgo() {
        const ssurgoOn = !this.state.ssurgoOn;
        this.setState({
            ssurgoOn: ssurgoOn,
            ssurgoStyle: ssurgoOn ? ssurgoStyleOn : ssurgoStyleOff,
        });
    }

    handleSsurgoUpdate() {
        this.handleSsurgoUpdateAsync();
    }

    async handleSsurgoUpdateAsync() {
        var { updateSsurgo, ssurgoOn, ssurgoEnabled, ssurgoIds } = this.state;

        var the_map = this.context[MAP];
        if (!this._map) return;

        if (!ssurgoEnabled) {
            the_map.data.setStyle(ssurgoStyleOff);
        }

        if (ssurgoOn) {
            the_map.data.setStyle(ssurgoStyleOn);
        } else {
            the_map.data.setStyle(ssurgoStyleOff);
        }

        if (updateSsurgo) {
            this.setState({ updateSsurgo: false });
        } else {
            return;
        }

        function xmlToJson(xml) {
            // Create the return object
            var obj = {};

            if (xml.nodeType === 1) {
                // element
                // do attributes
                if (xml.attributes.length > 0) {
                    obj["@attributes"] = {};
                    for (var j = 0; j < xml.attributes.length; j++) {
                        var attribute = xml.attributes.item(j);
                        obj["@attributes"][attribute.nodeName] = attribute.nodeValue;
                    }
                }
            } else if (xml.nodeType === 3) {
                // text
                obj = xml.nodeValue;
            }

            // do children
            if (xml.hasChildNodes()) {
                for (var i = 0; i < xml.childNodes.length; i++) {
                    var item = xml.childNodes.item(i);
                    var nodeName = item.nodeName;
                    if (typeof obj[nodeName] === "undefined") {
                        obj[nodeName] = xmlToJson(item);
                    } else {
                        if (typeof obj[nodeName].push === "undefined") {
                            var old = obj[nodeName];
                            obj[nodeName] = [];
                            obj[nodeName].push(old);
                        }
                        obj[nodeName].push(xmlToJson(item));
                    }
                }
            }
            return obj;
        }

        let bounds = this._map.getBounds();
        if (!bounds) return;
        // const sw = [bounds.getSouthWest().lng(), bounds.getSouthWest().lat()].join(",");
        // const ne = [bounds.getNorthEast().lng(), bounds.getNorthEast().lat()].join(",");
        // const bbox = [sw, ne].join(" ");
        const bbox_expanded = [
            [bounds.getSouthWest().lng() - 0.005, bounds.getSouthWest().lat() - 0.005].join(","),
            [bounds.getNorthEast().lng() + 0.005, bounds.getNorthEast().lat() + 0.005].join(","),
        ].join(" ");

        const blah = {
            lng_min: bounds.getSouthWest().lng() - 0.005,
            lat_min: bounds.getSouthWest().lat() - 0.005,
            lng_max: bounds.getNorthEast().lng() + 0.005,
            lat_max: bounds.getNorthEast().lat() + 0.005,
        };

        //  If the new map bounds are within any other previous bounds, do not update SSURGO.
        var withinBounds = false;
        this.state.prevBoundsList.forEach((prevBounds) => {
            let prevLngMin = prevBounds.lng_min;
            let prevLngMax = prevBounds.lng_max;
            let prevLatMin = prevBounds.lat_min;
            let prevLatMax = prevBounds.lat_max;

            let currLngMin = bounds.getSouthWest().lng();
            let currLngMax = bounds.getNorthEast().lng();
            let currLatMin = bounds.getSouthWest().lat();
            let currLatMax = bounds.getNorthEast().lat();

            if (
                currLngMin > prevLngMin &&
                currLngMax < prevLngMax &&
                currLatMin > prevLatMin &&
                currLatMax < prevLatMax
            ) {
                withinBounds = true;
            } else if (
                currLngMin === prevLngMin &&
                currLngMax === prevLngMax &&
                currLatMin === prevLatMin &&
                currLatMax === prevLatMax
            ) {
                withinBounds = true;
            }
        });

        if (withinBounds) {
            this.redrawSsurgoFeatures();
            this.setState({ prevBoundsList: [...this.state.prevBoundsList, blah] });
            return;
        }

        this.setState({ prevBoundsList: [...this.state.prevBoundsList, blah] });

        var { ssurgoFeatures, ssurgoMarkers } = this.state;
        var _this = this;
        let url =
            "https://sdmdataaccess.nrcs.usda.gov/Spatial/SDMWGS84Geographic.wfs?SERVICE=WFS&VERSION=1.1.0&REQUEST=GetFeature&TYPENAME=MapunitPoly&FILTER=%3CFilter%3E%3CBBOX%3E%3CPropertyName%3EGeometry%3C/PropertyName%3E%3CBox%20srsName=%27EPSG:4326%27%3E%3Ccoordinates%3E" +
            bbox_expanded +
            "%3C/coordinates%3E%3C/Box%3E%3C/BBOX%3E%3C/Filter%3E&SRSNAME=EPSG:4326&OUTPUTFORMAT=GML2";
        fetch(url)
            .then((response) => response.text())
            .then((str) => new window.DOMParser().parseFromString(str, "text/xml"))
            .then((xml_features) => {
                let json_features = xmlToJson(xml_features);

                if (Array.isArray(json_features["wfs:FeatureCollection"]["gml:featureMember"]) === false) {
                    json_features["wfs:FeatureCollection"]["gml:featureMember"] = [
                        json_features["wfs:FeatureCollection"]["gml:featureMember"],
                    ];
                }

                json_features["wfs:FeatureCollection"]["gml:featureMember"].forEach((a) => {
                    let ssurgoId = a["ms:mapunitpoly"]["ms:mupolygonkey"]["#text"];

                    if (ssurgoIds.indexOf(ssurgoId) === -1) {
                        let marker_label = a["ms:mapunitpoly"]["ms:musym"]["#text"];

                        let marker_bbox_coords = a["ms:mapunitpoly"]["gml:boundedBy"]["gml:Box"]["gml:coordinates"][
                            "#text"
                        ]
                            .trim()
                            .split(" ")
                            .map((str_pair) => {
                                let pair = str_pair.split(",");
                                let lat = parseFloat(pair[0]);
                                let lng = parseFloat(pair[1]);
                                return { lat: lat, lng: lng };
                            });

                        let x = (marker_bbox_coords[0]["lng"] + marker_bbox_coords[1]["lng"]) / 2;
                        let y = (marker_bbox_coords[0]["lat"] + marker_bbox_coords[1]["lat"]) / 2;

                        ssurgoMarkers.push(
                            <Marker
                                key={ssurgoMarkers.length}
                                id={ssurgoMarkers.length}
                                position={{ lat: y, lng: x }}
                                label={{ color: "orange", text: marker_label }}
                                icon={{ url: ssurgoMarkerPng }}
                                onClick={() => console.log("You clicked me!")}
                            />,
                        );

                        let rings = [];
                        let outer_boundary_coordinates = a["ms:mapunitpoly"]["ms:multiPolygon"]["gml:MultiPolygon"][
                            "gml:polygonMember"
                        ]["gml:Polygon"]["gml:outerBoundaryIs"]["gml:LinearRing"]["gml:coordinates"]["#text"]
                            .trim()
                            .split(" ")
                            .map((str_pair) => {
                                let pair = str_pair.split(",");
                                let lat = parseFloat(pair[0]);
                                let lng = parseFloat(pair[1]);
                                return { lat: lat, lng: lng };
                            });
                        rings.push(outer_boundary_coordinates);

                        let all_x_coordinates = outer_boundary_coordinates.map((c) => c.lng);
                        let all_y_coordinates = outer_boundary_coordinates.map((c) => c.lat);

                        if (
                            a["ms:mapunitpoly"]["ms:multiPolygon"]["gml:MultiPolygon"]["gml:polygonMember"][
                                "gml:Polygon"
                            ]["gml:innerBoundaryIs"]
                        ) {
                            let inner_boundary_coordinates =
                                a["ms:mapunitpoly"]["ms:multiPolygon"]["gml:MultiPolygon"]["gml:polygonMember"][
                                    "gml:Polygon"
                                ]["gml:innerBoundaryIs"];

                            var type = Function.prototype.call.bind(Object.prototype.toString);

                            if (type(inner_boundary_coordinates) === "[object Object]") {
                                let inner_ring = inner_boundary_coordinates["gml:LinearRing"]["gml:coordinates"][
                                    "#text"
                                ]
                                    .trim()
                                    .split(" ")
                                    .map((str_pair) => {
                                        let pair = str_pair.split(",");
                                        let lat = parseFloat(pair[0]);
                                        let lng = parseFloat(pair[1]);
                                        return { lat: lat, lng: lng };
                                    });
                                rings.push(inner_ring);
                                all_x_coordinates = [...all_x_coordinates, ...inner_ring.map((c) => c.lng)];
                                all_y_coordinates = [...all_y_coordinates, ...inner_ring.map((c) => c.lat)];
                            } else {
                                inner_boundary_coordinates.forEach((c) => {
                                    let inner_ring = c["gml:LinearRing"]["gml:coordinates"]["#text"]
                                        .trim()
                                        .split(" ")
                                        .map((str_pair) => {
                                            let pair = str_pair.split(",");
                                            let lat = parseFloat(pair[0]);
                                            let lng = parseFloat(pair[1]);
                                            return { lat: lat, lng: lng };
                                        });
                                    rings.push(inner_ring);
                                    all_x_coordinates = [...all_x_coordinates, ...inner_ring.map((c) => c.lng)];
                                    all_y_coordinates = [...all_y_coordinates, ...inner_ring.map((c) => c.lat)];
                                });
                            }
                        }

                        let min_x = Math.min(...all_x_coordinates);
                        let max_x = Math.max(...all_x_coordinates);
                        let min_y = Math.min(...all_y_coordinates);
                        let max_y = Math.max(...all_y_coordinates);

                        let feature = new google.maps.Data.Feature({ id: ssurgoId });
                        feature.setGeometry(new google.maps.Data.Polygon(rings));
                        feature.setProperty("id", ssurgoId);
                        feature.setProperty("min_x", min_x);
                        feature.setProperty("max_x", max_x);
                        feature.setProperty("min_y", min_y);
                        feature.setProperty("max_y", max_y);

                        ssurgoFeatures.push(feature);
                        ssurgoIds.push(ssurgoId);
                    }
                });

                the_map.data.setStyle(ssurgoStyleOn);

                this.setState({ ssurgoMarkers, ssurgoIds, ssurgoFeatures });

                _this.redrawSsurgoFeatures();
            })
            .catch((error) => {
                console.log(error);
                return ["error"];
            });
    }

    redrawSsurgoFeatures = () => {
        const { ssurgoFeatures } = this.state;
        var the_map = this.context[MAP];
        let bounds = the_map.getBounds();
        if (!bounds) return;
        const min_x = bounds.getSouthWest().lng();
        const max_x = bounds.getNorthEast().lng();
        const min_y = bounds.getSouthWest().lat();
        const max_y = bounds.getNorthEast().lat();

        ssurgoFeatures.forEach((f) => {
            let f_min_x = f.getProperty("min_x");
            let f_max_x = f.getProperty("max_x");
            let f_min_y = f.getProperty("min_y");
            let f_max_y = f.getProperty("max_y");

            if (
                (f_min_x > min_x && f_min_x < max_x) ||
                (f_max_x > min_x && f_max_x < max_x) ||
                (f_min_y > min_y && f_min_y < max_y) ||
                (f_max_y > min_y && f_min_y < max_y)
            ) {
                if (the_map.data.getFeatureById(f.getProperty("id"))) {
                    // do nothing, feature is already in data
                } else {
                    the_map.data.add(f);
                }
            } else {
                the_map.data.remove(f);
            }
        });
    };

    displaySsurgoMarkers = () => {
        var the_map = this.context[MAP];
        let bounds = the_map.getBounds();
        if (!bounds) return;
        const min_x = bounds.getSouthWest().lng();
        const max_x = bounds.getNorthEast().lng();
        const min_y = bounds.getSouthWest().lat();
        const max_y = bounds.getNorthEast().lat();

        if (!this.state.ssurgoOn || !this.state.ssurgoEnabled) {
            return [];
        }

        var markers = [];
        this.state.ssurgoMarkers.forEach((m) => {
            if (
                m.props.position.lng > min_x &&
                m.props.position.lng < max_x &&
                m.props.position.lat > min_y &&
                m.props.position.lat < max_y
            ) {
                markers.push(m);
            }
        });

        return markers;
    };

    handleBoundsChanged() {
        if (!this._map) return;
        this.setState({
            bounds: this._map.getBounds(),
            center: this._map.getCenter(),
        });
    }

    deleteAllShape() {
        this.deleteMapData();
        this.props.formApi.setAllValues({
            soils: null,
            size: null,
            size_calc: null,
            geometry: null,
            diffAcres: "",
        });
    }

    deleteMapData() {
        let userShapes = this.state.shapes;

        for (let shpKey in userShapes) {
            let overlayShp = userShapes[shpKey];
            overlayShp.overlay.setMap(null);
        }
    }

    handleOverlayComplete = (event) => {
        this.state.shapes.push(event);
        let drawManager = this.state.drawingManager;
        drawManager.setDrawingMode(null);
        //add editing events for user drawn shapes
        this.addEditEventListeners(event);

        this.RecalculateShapes(event);
    };

    CreateDataLayerAndCalculate = (event) => {
        //create a temp data layer - not shown on map
        let mapData = new google.maps.Data();
        let newFeature = null;
        switch (event.type) {
            case google.maps.drawing.OverlayType.RECTANGLE:
                var b = event.overlay.getBounds(),
                    p = [
                        b.getSouthWest(),
                        {
                            lat: b.getSouthWest().lat(),
                            lng: b.getNorthEast().lng(),
                        },
                        b.getNorthEast(),
                        {
                            lng: b.getSouthWest().lng(),
                            lat: b.getNorthEast().lat(),
                        },
                    ];
                newFeature = new google.maps.Data.Feature({
                    geometry: new google.maps.Data.Polygon([p]),
                });
                break;
            case google.maps.drawing.OverlayType.POLYGON:
                newFeature = new google.maps.Data.Feature({
                    geometry: new google.maps.Data.Polygon([event.overlay.getPath().getArray()]),
                });
                break;
            default:
                break;
        }
        if (newFeature) {
            mapData.add(newFeature);
            return mapData;
        }
    };

    GetSoilProperties = (mapGeom, values) => {
        return apiFetch("/v4/calc/Soils?format=json", {
            method: "POST",
            body: JSON.stringify({
                "field.geometry": {
                    type: "FeatureCollection",
                    features: [{ type: "Feature", properties: {}, geometry: mapGeom }],
                },
            }), //FIXME: better way to get geojson from geom
        })
            .then((resp) => resp.json())
            .then((data) => {
                const pct = (val, dec) => (val * 100).toFixed(dec) + " %";
                this.props.formApi.setValue("soils.slope", pct(data.slope, 1));
                this.props.formApi.setValue("soils.slope_length", data.slope_length);
                this.props.formApi.setValue("greenhouse.soil_texture_id", data.texture_id);
                this.props.formApi.setValue("waterquality.omcontent", pct(data.om_content, 2));
                this.props.formApi.setValue("waterquality.k_factor", Math.round(data.k_factor * 100) / 100);
                this.props.formApi.setValue("waterquality.hsgroup", data.hydrologic_group);
            });
    };

    addEditEventListeners = (event) => {
        switch (event.type) {
            case google.maps.drawing.OverlayType.RECTANGLE:
                google.maps.event.addListener(event.overlay, "bounds_changed", () => {
                    this.RecalculateShapes(event);
                });
                break;
            case google.maps.drawing.OverlayType.POLYGON:
                const mContainer = this;
                event.overlay.getPaths().forEach((path) => {
                    google.maps.event.addListener(path, "insert_at", () => {
                        var intersects = findSelfIntersects(path);
                        if (intersects && intersects.length) {
                            mContainer.setState({ helpOpen: true, helpText: "Field cannot self intersect." });
                            const node = ReactDOM.findDOMNode(mContainer.props.parentContainer);
                            setTimeout(function () {
                                node.querySelector(
                                    "img[src$='https://maps.gstatic.com/mapfiles/undo_poly.png']",
                                ).click();
                            }, 500);
                            return;
                        }

                        this.RecalculateShapes(event);
                    });
                    google.maps.event.addListener(path, "remove_at", () => {
                        var intersects = findSelfIntersects(path);
                        if (intersects && intersects.length) {
                            mContainer.setState({ helpOpen: true, helpText: "Field cannot self intersect." });
                            const node = ReactDOM.findDOMNode(mContainer.props.parentContainer);
                            setTimeout(function () {
                                node.querySelector(
                                    "img[src$='https://maps.gstatic.com/mapfiles/undo_poly.png']",
                                ).click();
                            }, 500);
                            return;
                        }

                        this.RecalculateShapes(event);
                    });
                    google.maps.event.addListener(path, "set_at", () => {
                        var intersects = findSelfIntersects(path);
                        if (intersects && intersects.length) {
                            mContainer.setState({ helpOpen: true, helpText: "Field cannot self intersect." });
                            const node = ReactDOM.findDOMNode(mContainer.props.parentContainer);
                            setTimeout(function () {
                                node.querySelector(
                                    "img[src$='https://maps.gstatic.com/mapfiles/undo_poly.png']",
                                ).click();
                            }, 500);
                            return;
                        }

                        this.RecalculateShapes(event);
                    });
                });
                break;
            default:
                break;
        }
    };

    RecalculateShapes = (event, preserveLast) => {
        let mapData = this.CreateDataLayerAndCalculate(event);
        if (mapData) {
            this.CalculateAcres(event, mapData, preserveLast);
        }
    };

    // Calculate acres and then set the geometry
    CalculateAcres(shp, mapData, preserveLast) {
        let sqmts = 0,
            area = 0;
        if (shp) {
            switch (shp.type) {
                case google.maps.drawing.OverlayType.POLYGON:
                    sqmts = google.maps.geometry.spherical.computeArea(shp.overlay.getPath());
                    area = sqmts * 0.00024711;
                    break;
                case google.maps.drawing.OverlayType.RECTANGLE:
                    let bounds = shp.overlay.getBounds();
                    let sw = bounds.getSouthWest();
                    let ne = bounds.getNorthEast();
                    let southWest = new google.maps.LatLng(sw.lat(), sw.lng());
                    let northEast = new google.maps.LatLng(ne.lat(), ne.lng());
                    let southEast = new google.maps.LatLng(sw.lat(), ne.lng());
                    let northWest = new google.maps.LatLng(ne.lat(), sw.lng());
                    sqmts = google.maps.geometry.spherical.computeArea([northEast, northWest, southWest, southEast]);
                    area = sqmts * 0.00024711;
                    break;
                default:
                    break;
            }

            if (area > 10000) {
                this.setState({ helpOpen: true, helpText: "Field size cannot be larger than 10,000 acres" });
                this.props.clearClick();
                return;
            }

            if (area > 5000 && area <= 10000) {
                this.setState({
                    helpOpen: true,
                    helpText:
                        "Typically field sizes do not exceed 5,000 acres. Please validate your field boundary is correct or consider splitting into multiple fields.",
                });
            }

            if (area < 0.1) {
                this.setState({ helpOpen: true, helpText: "Field size cannot be smaller than 0.1 acres" });
                this.props.clearClick();
                return;
            }

            let acres = area.toFixed(2);

            mapData.toGeoJson((json) => {
                this.props.formApi.setValue("size_calc", acres + " acre");
                if (preserveLast) {
                    this.props.handleAcresChange(null, acres);
                } else {
                    this.props.formApi.setValue("size", acres + " acre");
                    this.props.formApi.setValue("geometry", json.features[0].geometry);
                    this.props.formApi.setValue("diffAcres", "(0%  Diff.)");
                    this.props.handleConfirmBoundary(acres);
                }
            });
        }
    }

    render() {
        const { helpOpen, helpText } = this.state;

        return (
            <div>
                <GoogleMap
                    ref={(c) => {
                        this.handleMapMounted(c);
                    }}
                    defaultZoom={4}
                    defaultCenter={{ lat: 39.4, lng: -98.35 }}
                    mapTypeId={google.maps.MapTypeId.HYBRID}
                    defaultOptions={{
                        fullscreenControl: false,
                        streetViewControl: false,
                        mapTypeControl: !this.props.readonly,
                        zoomControl: !this.props.readonly,
                        zoomControlOptions: {
                            position: google.maps.ControlPosition.TOP_RIGHT,
                        },
                    }}
                    bounds={this.state.bounds}
                    onMapMounted={this.handleMapMounted}
                    onBoundsChanged={this.handleBoundsChanged}
                >
                    {this.isMarkerShown && <Marker position={{ lat: 39.4, lng: -98.35 }} />}
                    {this.state.markers.map((marker, index) => (
                        <Marker position={marker.position} key={index} />
                    ))}
                    {this.displaySsurgoMarkers()}
                    {this.handleSsurgoUpdate()}

                    {!this.props.readonly && (
                        <SsurgoMapControl position={google.maps.ControlPosition.LEFT_BOTTOM}>
                            <Button
                                disabled={!this.state.ssurgoEnabled}
                                onClick={() => this.toggleSsurgo()}
                                style={ssurgoSoilButtonStyle}
                            >
                                SSURGO Soils:{" "}
                                {this.state.ssurgoEnabled ? (this.state.ssurgoOn ? "On" : "Off") : "Disabled"}
                            </Button>
                        </SsurgoMapControl>
                    )}
                </GoogleMap>

                <WarningDialog
                    noActions
                    open={helpOpen}
                    onClose={() => this.setState({ helpOpen: false })}
                    title="Field Error"
                    text={helpText}
                />
            </div>
        );
    }
}
MapContainerWrapped = withScriptjs(withGoogleMap(MapContainerWrapped));
MapContainerWrapped = connect(null, Field.actions)(MapContainerWrapped);

const PROD_DOMAINS = ["calculator.fieldtomarket.org", "fpp-prod-static.s3-website-us-east-1.amazonaws.com"];
var key;
if (PROD_DOMAINS.includes(window.location.host)) {
    key = "AIzaSyBeppehipaycm7a0WRPhbN9MTq4rpGWH4k";
} else {
    key = "AIzaSyCqjdm8s3eVGfyYxAW_fHFy7_m0ERTSVQQ";
}

const MapContainer = (props) => (
    <MapContainerWrapped
        googleMapURL={
            "https://maps.googleapis.com/maps/api/js?key=" + key + "&v=3.exp&libraries=geometry,drawing,places"
        }
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: props.readonly ? "320px" : "100%" }} />}
        mapElement={<div style={{ height: `100%` }} />}
        field={props.field}
        readonly={props.readonly}
        formApi={props.formApi}
        handleConfirmBoundary={props.handleConfirmBoundary}
        handleAcresChange={props.handleAcresChange}
        clearClick={props.clearClick}
        parentContainer={props.parentContainer}
        onRef={(ref) => props.onRef(ref)}
    />
);

export default MapContainer;
