/* global google */
import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";

import { GoogleMap, withGoogleMap, withScriptjs, Polygon, Marker } from "react-google-maps";
import MarkerClusterer from "react-google-maps/lib/components/addons/MarkerClusterer";
import { MAP } from "react-google-maps/lib/constants";

import * as navActions from "../common/actions";
import { Field } from "../field/models";
import cluster from "./img/cluster.png";

const styles = (theme) => ({
    legend: {
        backgroundColor: "rgba(255, 255, 255, .70)",
        borderRadius: theme.spacing(1),
        margin: theme.spacing(1),
        padding: theme.spacing(1),
    },
    legendItem: {
        height: 12,
        width: theme.spacing(2),
        display: "inline-block",
    },
    legendText: {
        display: "inline-block",
        marginLeft: theme.spacing(1),
    },
    marginTop: {
        marginTop: theme.spacing(1),
    },
});

class ProjectMapContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            infoOpen: false,
        };
        this.bounds = {};
        this.zoomExtent = this.zoomExtent.bind(this);
    }
    //FIXME: This probably isn't the right way to access the map object...
    static contextTypes = { [MAP]: PropTypes.object };

    ConvertCoordToLatLong = (path) => {
        var fixedPath = [];
        for (var i = 0; i < path.length; i++) {
            fixedPath.push({
                lat: path[i][1],
                lng: path[i][0],
            });
        }
        return fixedPath;
    };

    getBounds = (polygon, b) => {
        polygon.forEach((element) => {
            b.extend(element);
        });
        return b;
    };

    // Zoom to the fields if any
    zoomExtent() {
        if (this.props.boundary.length > 0) {
            const map = this.context[MAP];
            map.initialZoom = true;
            map.fitBounds(this.bounds);
            map.fitBounds(this.bounds); // BUG - if zoomed in to 3D mode it doesnt fully fit back until 2nd time
        }
    }

    componentDidMount() {
        if (this.props.boundary.length > 0) {
            this.zoomExtent(true);
        } else {
            // FIXME: Fields aren't loaded yet when user first logs in, 1 second might not be enough
            // Maybe force table view on login?
            const mapContainer = this;
            setTimeout(function () {
                mapContainer.zoomExtent(true);
            }, 1000);
        }
    }

    render() {
        const { boundary, fields } = this.props;
        this.bounds = new google.maps.LatLngBounds();

        return (
            <GoogleMap
                defaultZoom={4}
                defaultCenter={{ lat: 39.4, lng: -98.35 }}
                //mapTypeId={google.maps.MapTypeId.HYBRID}
                defaultOptions={{
                    streetViewControl: false,
                    fullscreenControl: false,
                    zoomControl: false,
                    mapTypeControl: false,
                }}
            >
                <MarkerClusterer
                    gridSize={30}
                    minimumClusterSize={1}
                    styles={[
                        {
                            url: cluster,
                            height: 30,
                            width: 30,
                        },
                    ]}
                >
                    {fields.map((marker) => (
                        <Marker position={marker.field_centroid} />
                    ))}
                </MarkerClusterer>
                {boundary.map((field) => {
                    // Populate Map components
                    var paths = this.ConvertCoordToLatLong(field.coordinates[0]);
                    this.bounds = this.getBounds(paths, this.bounds);

                    return (
                        <Polygon
                            paths={paths}
                            editable={false}
                            visible={true}
                            options={{
                                strokeColor: "#000000",
                                fillColor: "#f15d22",
                                strokeWeight: 1,
                                fillOpacity: 0.25,
                            }}
                        />
                    );
                })}
            </GoogleMap>
        );
    }
}

ProjectMapContainer = connect(null, {
    ...Field.actions,
    ...navActions,
})(ProjectMapContainer);

export default withStyles(styles)(withScriptjs(withGoogleMap(ProjectMapContainer)));
