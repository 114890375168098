import React, { useState, useEffect } from "react";
import { Source, Layer, Popup, useMap } from "react-map-gl";

export default function GeoJSONLayer({ id, data, style, renderPopup }) {
    const { current: map } = useMap(),
        [popup, setPopup] = useState(null),
        closePopup = () => setPopup(null);

    useEffect(() => {
        if (!map) {
            return;
        }
        function onMouseEnter() {
            map.getCanvas().style.cursor = "pointer";
        }
        function onMouseLeave() {
            map.getCanvas().style.cursor = "";
        }
        function onClick(evt) {
            if (evt.features.length === 0) {
                return;
            }
            setPopup({ features: evt.features, lngLat: evt.lngLat });
        }
        map.on("mouseenter", id, onMouseEnter);
        map.on("mouseleave", id, onMouseLeave);
        map.on("click", id, onClick);
        return () => {
            map.off("mouseenter", id, onMouseEnter);
            map.off("mouseleave", id, onMouseEnter);
            map.off("click", onClick);
            map.getCanvas().style.cursor = "";
        };
    }, [map]);

    return (
        <>
            <Source id={id} type="geojson" data={data}>
                <Layer {...style} />
            </Source>
            {popup && (
                <Popup longitude={popup.lngLat.lng} latitude={popup.lngLat.lat} maxWidth={null} onClose={closePopup}>
                    {popup.features.map((feature) => (
                        <React.Fragment key={feature.id}>
                            {renderPopup(feature, popup.features.length > 1)}
                        </React.Fragment>
                    ))}
                </Popup>
            )}
        </>
    );
}
