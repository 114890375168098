import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../common/withRouter";
import { connect } from "react-redux";

import Button from "../../common/ButtonWrapper";
import Paper from "@mui/material/Paper";
import { Table, TableBody, TableRow, TableFooter, TablePagination } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";

import withStyles from "@mui/styles/withStyles";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import BookIcon from "@mui/icons-material/Book";

import EnhancedTableHead from "../../common/EnhancedTableHead";
import CustomTableCell from "../../common/TableCell";
import TablePaginationActionsWrapped from "../../common/Paginator";
import WarningDialog from "../../common/WarningDialog";
import * as authActions from "../../auth/actions";

import { SURVEY_COMPLETED } from "../models";

const styles = (theme) => ({
    body1: {
        fontWeight: 400,
        fontSize: 14,
        color: "#666666",
    },
    centerAlign: {
        textAlign: "center",
    },
    root: {
        width: "100%",
        overflowX: "auto",
    },
    table: {
        minWidth: 500,
    },
    buttonWidth: {
        minWidth: "44px",
        width: "44px",
    },
    analyticsTooltip: {
        // Tooltips don't work on disabled buttons without div, style div as button
        display: "inline-block",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.08)",
        },
    },
});

const columnData = [
    { id: "actions", numeric: false, label: "Actions", allowSort: false, dissablePadding: true },
    { id: "year", numeric: true, label: "Year", allowSort: true, dissablePadding: true },
    { id: "entryStatus", numeric: false, label: "Entry Status", allowSort: true, dissablePadding: true },
    { id: "cultivatedAcres", numeric: false, label: "Cultivated Land Acres", allowSort: true, dissablePadding: true },
    { id: "cultivatedLandHpi", numeric: false, label: "Cultivated Land HPI %", allowSort: true, dissablePadding: true },
    {
        id: "nonCultivatedAcres",
        numeric: false,
        label: "Non-Cultivated Land Acres",
        allowSort: true,
        dissablePadding: true,
    },
    {
        id: "cultivatedLandHpi",
        numeric: false,
        label: "Non Cultivated Land HPI %",
        allowSort: true,
        dissablePadding: true,
    },
    { id: "dataStatus", numeric: false, label: "Data Status", allowSort: true, dissablePadding: true },
];

class FarmHpiTable extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            order: "",
            orderBy: "year",
            page: 0,
            rowsPerPage: 10,
            deleteDialogOpen: false,
            deleteDialogText: null,
            deleteDialogConfirmAction: null,
        };
    }

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        const isNumeric = columnData.filter(function (item) {
            if (item.id === property) return item;
            else return null;
        });
        if (isNumeric[0].numeric) {
            order === "desc"
                ? this.props.farmHpiSurveys.sort((a, b) => (parseFloat(b[orderBy]) < parseFloat(a[orderBy]) ? -1 : 1))
                : this.props.farmHpiSurveys.sort((a, b) => (parseFloat(a[orderBy]) < parseFloat(b[orderBy]) ? -1 : 1));
        } else {
            order === "desc"
                ? this.props.farmHpiSurveys.sort((a, b) =>
                      b[orderBy].toLowerCase() < a[orderBy].toLowerCase() ? -1 : 1,
                  )
                : this.props.farmHpiSurveys.sort((a, b) =>
                      a[orderBy].toLowerCase() < b[orderBy].toLowerCase() ? -1 : 1,
                  );
        }

        this.setState({ order, orderBy });
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: event.target.value });
    };

    deleteFarmHpiSurvey = (survey, year) => {
        this.setState({
            deleteDialogOpen: true,
            deleteDialogConfirmAction: survey,
            deleteDialogText: "Are you sure you wish to permanently delete the " + year + " Farm HPI survey?",
        });
    };

    getCultivatedLandAcres = (survey) => {
        if (survey.cultivated.length === 0) {
            return "---";
        }
        var area = 0;
        survey.cultivated.forEach((c) => {
            if (c["plantable_area"]) {
                area += parseFloat(c["plantable_area"]);
            }
        });
        return area.toFixed(2);
    };

    getNonCultivatedLandAcres = (survey) => {
        if (
            survey.forest === undefined &&
            survey.buffer === undefined &&
            survey.surfacewater === undefined &&
            survey.grassland === undefined &&
            survey.wetland === undefined
        ) {
            return "---";
        }

        var area = 0;

        if (survey.forest) {
            ["conifer", "deciduous", "evergreen", "mixed"].forEach((type) => {
                if (survey["forest"][type + "_area"]) {
                    if (parseFloat(survey["forest"][type + "_area"]))
                        area += parseFloat(survey["forest"][type + "_area"]);
                }
            });
        }

        if (survey.buffer) {
            ["edge", "buffer"].forEach((type) => {
                if (survey["buffer"][type + "_area"]) {
                    area += parseFloat(survey["buffer"][type + "_area"]);
                }
            });
        }

        if (survey.surfacewater) {
            ["stream", "river"].forEach((type) => {
                if (survey["surfacewater"][type + "_area"]) {
                    area += parseFloat(survey["surfacewater"][type + "_area"]);
                }
            });
            ["pond", "lake"].forEach((type_1) => {
                ["natural", "artificial"].forEach((type_2) => {
                    if (survey["surfacewater"][type_1 + "_" + type_2 + "_area"]) {
                        area += parseFloat(survey["surfacewater"][type_1 + "_" + type_2 + "_area"]);
                    }
                });
            });
        }

        if (survey.grassland) {
            ["native", "nonnative"].forEach((type_1) => {
                ["ungrazed", "grazed"].forEach((type_2) => {
                    if (survey["grassland"][type_1 + "_" + type_2 + "_area"]) {
                        area += parseFloat(survey["grassland"][type_1 + "_" + type_2 + "_area"]);
                    }
                });
            });
        }

        if (survey.wetland) {
            ["natural", "artificial"].forEach((type) => {
                if (survey["wetland"][type + "_area"]) {
                    area += parseFloat(survey["wetland"][type + "_area"]);
                }
            });
        }

        return area.toFixed(2);
    };

    getCultivatedFarmPercentRealizedHpi = (survey) => {
        //  check to see if all cultivated surveys are done

        if (this.getCompleted(survey)) {
            return survey.results &&
                survey.results.farm &&
                survey.results.farm.cultivated &&
                survey.results.farm.cultivated.farm_percent_realized_hpi
                ? (parseFloat(survey.results.farm.cultivated.farm_percent_realized_hpi) * 100).toFixed(0)
                : "---";
        }
        return "---";
    };

    getNonCultivatedFarmPercentRealizedHpi = (survey) => {
        //  check to see if all cultivated surveys are done

        if (this.getCompleted(survey)) {
            return survey.results &&
                survey.results.farm &&
                survey.results.farm.noncultivated &&
                survey.results.farm.noncultivated.farm_percent_realized_hpi
                ? (parseFloat(survey.results.farm.noncultivated.farm_percent_realized_hpi) * 100).toFixed(0)
                : "---";
        }
        return "---";
    };

    getCompleted = (survey) => {
        var completed = true;

        ["forest", "grassland", "buffer", "surfacewater", "wetland"].forEach((type) => {
            if (survey[type]) {
                if (survey[type].missing > 0) {
                    return false;
                }
            }
        });

        if (survey["cultivated"].length > 0) {
            survey["cultivated"].forEach((c) => {
                if (c.completed === false) {
                    completed = false;
                }
            });
        }

        return completed;
    };

    render() {
        const { farmHpiSurveys, classes, ormFarmHpiSurveyDelete } = this.props;

        const { order, orderBy, rowsPerPage, page, deleteDialogOpen, deleteDialogText, deleteDialogConfirmAction } =
            this.state;
        const emptyRows = rowsPerPage - Math.min(rowsPerPage, farmHpiSurveys.length - page * rowsPerPage);

        return (
            <Paper className={classes.root}>
                <Table className={classes.table}>
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={this.handleRequestSort}
                        columnData={columnData}
                    />
                    <TableBody>
                        {farmHpiSurveys.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((survey) => {
                            var dataEntryCompleted = SURVEY_COMPLETED(survey, true);
                            var reportDisabled = dataEntryCompleted && survey.results ? false : true;
                            var completedMessage = "";
                            if (dataEntryCompleted) {
                                if (survey.results) {
                                    completedMessage = "Report (Computed)";
                                } else {
                                    completedMessage = "Report (Not Yet Computed)";
                                }
                            } else {
                                completedMessage = "Report (Complete Data Entry to View)";
                            }
                            return (
                                <TableRow key={survey.id} hover>
                                    <CustomTableCell style={{ minWidth: "135px" }}>
                                        <Tooltip title="Edit Farm HPI Survey">
                                            <Button
                                                className={classes.buttonWidth}
                                                component={Link}
                                                to={"/farm/hpisurvey/" + survey.id}
                                            >
                                                <EditIcon color="primary" />
                                            </Button>
                                        </Tooltip>
                                        <Tooltip title={completedMessage}>
                                            <div className={classes.analyticsTooltip}>
                                                <Button
                                                    className={classes.buttonWidth}
                                                    disabled={reportDisabled}
                                                    component={Link}
                                                    to={"/farm/hpiresults/" + survey.id}
                                                >
                                                    {dataEntryCompleted && survey.results && (
                                                        <BookIcon style={{ color: "green" }} />
                                                    )}
                                                    {dataEntryCompleted && !survey.results && <BookIcon />}
                                                    {!dataEntryCompleted && <BookIcon />}
                                                </Button>
                                            </div>
                                        </Tooltip>
                                        <Tooltip title="Delete Farm HPI Survey">
                                            <Button
                                                className={classes.buttonWidth}
                                                onClick={() => this.deleteFarmHpiSurvey(survey.id, survey.year)}
                                            >
                                                <DeleteIcon color="primary" />
                                            </Button>
                                        </Tooltip>
                                    </CustomTableCell>
                                    <CustomTableCell>{survey.year}</CustomTableCell>
                                    <CustomTableCell>{dataEntryCompleted ? "Complete" : "Incomplete"}</CustomTableCell>
                                    <CustomTableCell>{this.getCultivatedLandAcres(survey)}</CustomTableCell>
                                    <CustomTableCell>
                                        {this.getCultivatedFarmPercentRealizedHpi(survey)}
                                    </CustomTableCell>
                                    <CustomTableCell>{this.getNonCultivatedLandAcres(survey)}</CustomTableCell>
                                    <CustomTableCell>
                                        {this.getNonCultivatedFarmPercentRealizedHpi(survey)}
                                    </CustomTableCell>
                                    <CustomTableCell>{survey.is_final ? "Finalized" : "Provisional"}</CustomTableCell>
                                </TableRow>
                            );
                        })}
                        {emptyRows > 0 && farmHpiSurveys.length > 10 && (
                            <TableRow style={{ height: 48 * emptyRows }}>
                                <CustomTableCell colSpan={4} />
                            </TableRow>
                        )}
                        {farmHpiSurveys.length < 1 && (
                            <TableRow>
                                <CustomTableCell colSpan={7} className={classes.centerAlign}>
                                    No Farm Level HPI Surveys Entered
                                </CustomTableCell>
                            </TableRow>
                        )}
                    </TableBody>
                    {farmHpiSurveys.length > 10 && (
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    colSpan={4}
                                    count={farmHpiSurveys.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    classes={{
                                        caption: classes.body1,
                                    }}
                                    onPageChange={this.handleChangePage}
                                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActionsWrapped}
                                />
                            </TableRow>
                        </TableFooter>
                    )}
                </Table>
                <WarningDialog
                    confirmAction={() => {
                        ormFarmHpiSurveyDelete(deleteDialogConfirmAction);
                        this.setState({ deleteDialogOpen: false });
                    }}
                    cancelAction={() => this.setState({ deleteDialogOpen: false })}
                    open={deleteDialogOpen}
                    title="Delete Survey"
                    text={deleteDialogText}
                />
            </Paper>
        );
    }
}

FarmHpiTable = connect((state, ownProps) => ({}), {
    ...authActions,
})(FarmHpiTable);

export default withStyles(styles)(withRouter(FarmHpiTable));
