import React, { Component } from "react";
import withRouter from "../common/withRouter";
import { Form } from "react-form";
import classNames from "classnames";

import Grid from "../common/GridWrapper";
import withStyles from "@mui/styles/withStyles";
import Typography from "../common/TypographyWrapper";

import AppContainer from "../common/AppContainer";
import Select from "../common/Select";
import states from "../common/states.json";
import Submit from "../common/Submit";
import TextField from "../common/TextField";
import Checkbox from "../common/Checkbox";
import Terms from "./Terms";
import Privacy from "./Privacy";

import { connect } from "react-redux";
import * as authActions from "./actions";

const styles = {
    registerError: {
        color: "red",
        "text-align": "center",
        "font-size": 16,
    },
    marginSmallTop: {
        marginTop: "24px",
    },
    marginBottom: {
        marginBottom: "40px",
    },
    centerAlign: {
        textAlign: "center !important",
    },
    linkColor: {
        // TODO: global theme?
        color: "#808080",
        cursor: "pointer",
        textDecoration: "underline",
    },
};

class RegisterPage extends Component {
    state = {
        termsOpen: false,
        privacyOpen: false,
    };

    openTerms = () => {
        this.setState({ termsOpen: true });
    };

    closeTerms = () => {
        this.setState({ termsOpen: false });
    };

    openPrivacy = () => {
        this.setState({ privacyOpen: true });
    };

    closePrivacy = () => {
        this.setState({ privacyOpen: false });
    };

    errorValidator = (values) => {
        const validateFirstName = (name) => {
            return !name ? "First Name is required" : null;
        };
        const validateLastName = (name) => {
            return !name ? "Last Name is required" : null;
        };
        const validateConfirm = (confirm) => {
            return !confirm ? "Please confirm." : null;
        };
        const validateEmail = (email) => {
            var re =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return !re.test(email) ? "Invalid email address" : null;
        };
        const validatePassword = (password, password2) => {
            if (password !== password2) return "Passwords do not match";
            else if (!password || password.length < 8) return "Invalid password";
            else return null;
        };
        const validateZip = (zip) => {
            return !zip || !zip.match(/(^\d{5}$)|(^\d{5}-\d{4}$)/g)
                ? "Invalid zip code (Ex: 55555 or 55555-1234)"
                : null;
        };
        const validatePhone = (phone) => {
            if (!phone) return "Invalid Phone (Ex: 555-555-5555)";
            const phonematch = phone.match(/\d/g);
            return !phonematch || phonematch.length !== 10 ? "Invalid Phone (Ex: 555-555-5555)" : null;
        };
        return {
            first_name: validateFirstName(values.first_name),
            last_name: validateLastName(values.last_name),
            email: validateEmail(values.email),
            password: validatePassword(values.password, values.password2),
            password2: validatePassword(values.password, values.password2),
            zip: validateZip(values.zip),
            phone: validatePhone(values.phone),
            confirm: validateConfirm(values.confirm),
        };
    };

    registerSubmit = (values) => {
        this.props.authRegister(values).then(() => {
            if (this.props.register.success) {
                this.props.history.push("/registered/" + values.email);
            }
        });
    };

    render() {
        const { classes, register } = this.props;
        const { termsOpen, privacyOpen } = this.state;
        return (
            <AppContainer>
                <Form
                    dontValidateOnMount="true"
                    validateOnSubmit="true"
                    validateError={this.errorValidator}
                    onSubmit={this.registerSubmit}
                >
                    {(formApi) => (
                        <form onSubmit={formApi.submitForm}>
                            <Grid container justifyContent="center" spacing={40}>
                                <Grid item xs={12} md={8} lg={6}>
                                    <Typography variant="title" gutterBottom>
                                        User Registration
                                    </Typography>
                                    <Typography>
                                        Please complete registration to gain access to the Fieldprint Calculator. Once
                                        you register, a confirmation email will be sent to verify your details. You will
                                        need to verify your email address before being able to log in.
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container justifyContent="center" spacing={40} gutterBottom>
                                <Grid item xs={10} sm={6} md={4} lg={3}>
                                    <TextField field="first_name" label="First Name*" fullWidth margin="normal" />
                                    <TextField field="last_name" label="Last Name*" fullWidth margin="normal" />
                                    <TextField
                                        field="email"
                                        label="Email (this is your user name)*"
                                        fullWidth
                                        margin="normal"
                                    />
                                    <TextField
                                        field="phone"
                                        label="Telephone*"
                                        placeholder="555-555-5555"
                                        fullWidth
                                        margin="normal"
                                    />
                                    <TextField
                                        field="password"
                                        type="password"
                                        label="Password*"
                                        fullWidth
                                        margin="normal"
                                    />
                                    <TextField
                                        field="password2"
                                        type="password"
                                        label="Retype Password*"
                                        fullWidth
                                        margin="normal"
                                    />
                                    <Typography>
                                        Password Requirements:
                                        <ul>
                                            <li>Minimum 8 characters</li>
                                            <li>Cannot be part of your name or email</li>
                                            <li>Cannot be entirely numeric</li>
                                        </ul>
                                    </Typography>
                                </Grid>
                                <Grid item xs={10} sm={6} md={4} lg={3}>
                                    <TextField field="address" label="Address" fullWidth margin="normal" />
                                    <TextField field="address2" label="Address 2" fullWidth margin="normal" />
                                    <TextField field="city" label="City" fullWidth margin="normal" />
                                    <Select field="state" label="State" options={states} fullWidth margin="normal" />
                                    <TextField
                                        field="zip"
                                        label="Zip Code*"
                                        placeholder="55555"
                                        fullWidth
                                        margin="normal"
                                    />
                                </Grid>
                            </Grid>
                            <Grid container justifyContent="center" spacing={40}>
                                <Grid item xs={12} md={8} lg={6}>
                                    <Typography gutterBottom className={classes.centerAlign}>
                                        By continuing with registartion, you agree to the{" "}
                                        <span className={classes.linkColor} onClick={this.openTerms}>
                                            Terms of Use
                                        </span>
                                        . Please read our{" "}
                                        <span className={classes.linkColor} onClick={this.openPrivacy}>
                                            Data and Privacy Policy
                                        </span>{" "}
                                        for information on how data is collected and used.
                                    </Typography>
                                    <Checkbox
                                        field="confirm"
                                        label="I have read the Privacy Policy and Terms of Use"
                                        fullWidth
                                        align={classNames(classes.centerAlign, classes.marginBottom)}
                                    />
                                    <Submit
                                        label="Register"
                                        altAction="/"
                                        altLabel="Return to Home Page"
                                        loading={register.pending}
                                    />
                                    <div className={classes.registerError}>{register.error}</div>
                                    <Typography variant="title" gutterBottom className={classes.marginSmallTop}>
                                        Need Help?
                                    </Typography>
                                    <Typography variant="body1">
                                        Please contact us at{" "}
                                        <a className={classes.linkColor} href="mailto:support@fieldtomarket.org">
                                            support@fieldtomarket.org
                                        </a>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </Form>
                <Terms dialogOpen={termsOpen} closeEvent={this.closeTerms} />
                <Privacy dialogOpen={privacyOpen} closeEvent={this.closePrivacy} />
            </AppContainer>
        );
    }
}

RegisterPage = connect((state) => ({ register: (state.auth && state.auth.register) || {} }), authActions)(RegisterPage);

export default withStyles(styles)(withRouter(RegisterPage));
