import React, { Component } from "react";
import { Form } from "react-form";
import { connect } from "react-redux";
import withStyles from "@mui/styles/withStyles";
import Button from "../../common/ButtonWrapper";
import Grid from "../../common/GridWrapper";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CheckboxGroup from "../../common/CheckboxGroup";
import HelpDialog from "../../common/HelpDialog";
import { MAKE_OPTIONS } from "../../../api/constants";
import Typography from "../../common/TypographyWrapper";

import { GET_HAS_WHAT_1, VALIDATION, GET_DEFAULT_SURVEY_VALUES, land_types, landcover_groups } from "./constants";

import {
    FarmHpiSurvey,
    FarmHpiForestQuestionnaire,
    FarmHpiGrasslandQuestionnaire,
    FarmHpiRiparianQuestionnaire,
    FarmHpiSurfaceWaterQuestionnaire,
    FarmHpiWetlandQuestionnaire,
} from "../models";

const styles = (theme) => ({
    rightAlign: {
        textAlign: "right",
    },
    button: {
        marginRight: theme.spacing(1),
    },
    indent: {
        marginLeft: 24,
    },
    leftPadding: {
        paddingLeft: 25,
    },
    redText: {
        color: "#FF0000",
    },
});

class LandTypes extends Component {
    constructor(props) {
        super(props);

        this.state = {
            warningDialogOpen: false,
            nothing_selected: false,
        };
    }

    getDefaultValues = () => {
        let landtypes = this.props.farmHpiSurvey.landtypes.split(",");

        var defaultValues = { buffer: [], forest: [], grassland: [], wetland: [], surfacewater: [] };
        landcover_groups.forEach((g) => {
            g.land_type_ids.forEach((c) => {
                if (landtypes.indexOf(c) > -1) {
                    let values = defaultValues[g.field];
                    values.push(c);
                    defaultValues[g.field] = values;
                }
            });
        });

        return defaultValues;
    };

    getLandTypeOptions = (group_id) => {
        const codes = landcover_groups.filter((g) => g.id === group_id)[0].land_type_ids;
        let names = land_types.filter((c) => codes.indexOf(c.id) > -1);
        let options = MAKE_OPTIONS(names);
        return options;
    };

    updateSurvey = (values) => {
        console.log("updateSurvey");

        const survey_values = GET_DEFAULT_SURVEY_VALUES(this.props.farmHpiSurvey);

        const has_what = GET_HAS_WHAT_1(values);

        const validation = VALIDATION(survey_values, has_what, false, this.props.farmHpiSurvey, true, false, false);

        const {
            ormFarmHpiSurveyUpdate,
            ormFarmHpiForestQuestionnaireCreateRemoteFirst,
            ormFarmHpiForestQuestionnaireUpdate,
            ormFarmHpiForestQuestionnaireDelete,
            ormFarmHpiGrasslandQuestionnaireCreateRemoteFirst,
            ormFarmHpiGrasslandQuestionnaireUpdate,
            ormFarmHpiGrasslandQuestionnaireDelete,
            ormFarmHpiRiparianQuestionnaireCreateRemoteFirst,
            ormFarmHpiRiparianQuestionnaireUpdate,
            ormFarmHpiRiparianQuestionnaireDelete,
            ormFarmHpiSurfaceWaterQuestionnaireCreateRemoteFirst,
            ormFarmHpiSurfaceWaterQuestionnaireUpdate,
            ormFarmHpiSurfaceWaterQuestionnaireDelete,
            ormFarmHpiWetlandQuestionnaireCreateRemoteFirst,
            ormFarmHpiWetlandQuestionnaireUpdate,
            ormFarmHpiWetlandQuestionnaireDelete,
        } = this.props;

        let selected_landtypes = [];
        var keys = Object.keys(values);
        keys.forEach((k) => {
            selected_landtypes = [...selected_landtypes, ...values[k]];
        });

        let selected_groups = [];

        landcover_groups.forEach((g) => {
            g.land_type_ids.forEach((code) => {
                if (selected_landtypes.indexOf(code) > -1) {
                    if (selected_groups.indexOf(g.field) === -1) {
                        selected_groups.push(g.field);
                    }
                }
            });
        });

        //  update farm hpi survey with the selections
        let selection_string = selected_landtypes.join(",");

        let promises = [];

        landcover_groups.forEach((landCoverGroup) => {
            const landCoverType = landCoverGroup.field;
            if (selected_groups.indexOf(landCoverType) > -1) {
                //  Land cover group is selected, if questionnaire does not exist, create it.
                //  If user is unchecking an option, set values for that option to null and update completed, missing, required.

                if (landCoverType === "forest") {
                    if (this.props.farmHpiSurvey.forest === undefined) {
                        promises.push(
                            ormFarmHpiForestQuestionnaireCreateRemoteFirst({
                                survey_id: this.props.farmHpiSurvey.id,
                                farm_id: this.props.farmHpiSurvey.farm.id,
                                code: "01",
                                missing: 1,
                                required: 1,
                            }),
                        );
                    } else {
                        const q = this.props.farmHpiSurvey.forest;
                        promises.push(
                            ormFarmHpiForestQuestionnaireUpdate({
                                id: q.id,
                                conifer_area: has_what.has_conifer ? q["conifer_area"] : null,
                                conifer_area_converted: has_what.has_conifer ? q["conifer_area_converted"] : null,
                                conifer_predominant_configuration: has_what.has_conifer
                                    ? q["conifer_predominant_configuration"]
                                    : null,
                                conifer_management: has_what.has_conifer ? q["conifer_management"] : null,
                                conifer_previous_land_cover: has_what.has_conifer
                                    ? q["conifer_previous_land_cover"]
                                    : null,
                                deciduous_area: has_what.has_deciduous ? q["deciduous_area"] : null,
                                deciduous_area_converted: has_what.has_deciduous ? q["deciduous_area_converted"] : null,
                                deciduous_predominant_configuration: has_what.has_deciduous
                                    ? q["deciduous_predominant_configuration"]
                                    : null,
                                deciduous_management: has_what.has_deciduous ? q["deciduous_management"] : null,
                                deciduous_previous_land_cover: has_what.has_deciduous
                                    ? q["deciduous_previous_land_cover"]
                                    : null,
                                evergreen_area: has_what.has_evergreen ? q["evergreen_area"] : null,
                                evergreen_area_converted: has_what.has_evergreen ? q["evergreen_area_converted"] : null,
                                evergreen_predominant_configuration: has_what.has_evergreen
                                    ? q["evergreen_predominant_configuration"]
                                    : null,
                                evergreen_management: has_what.has_evergreen ? q["evergreen_management"] : null,
                                evergreen_previous_land_cover: has_what.has_evergreen
                                    ? q["evergreen_previous_land_cover"]
                                    : null,
                                mixed_area: has_what.has_mixed ? q["mixed_area"] : null,
                                mixed_area_converted: has_what.has_mixed ? q["mixed_area_converted"] : null,
                                mixed_predominant_configuration: has_what.has_mixed
                                    ? q["mixed_predominant_configuration"]
                                    : null,
                                mixed_management: has_what.has_mixed ? q["mixed_management"] : null,
                                mixed_previous_land_cover: has_what.has_mixed ? q["mixed_previous_land_cover"] : null,
                                completed: validation["forest"][0] === 0 ? true : false,
                                missing: validation["forest"][0],
                                required: validation["forest"][1],
                            }),
                        );
                    }
                } else if (landCoverType === "grassland") {
                    if (this.props.farmHpiSurvey.grassland === undefined) {
                        promises.push(
                            ormFarmHpiGrasslandQuestionnaireCreateRemoteFirst({
                                survey_id: this.props.farmHpiSurvey.id,
                                farm_id: this.props.farmHpiSurvey.farm.id,
                                code: "01",
                                missing: 1,
                                required: 1,
                            }),
                        );
                    } else {
                        const q = this.props.farmHpiSurvey.grassland;

                        promises.push(
                            ormFarmHpiGrasslandQuestionnaireUpdate({
                                id: q.id,

                                native_ungrazed_area: has_what.has_native_ungrazed ? q["native_ungrazed_area"] : null,
                                native_ungrazed_area_converted: has_what.has_native_ungrazed
                                    ? q["native_ungrazed_area_converted"]
                                    : null,
                                native_ungrazed_configuration: has_what.has_native_ungrazed
                                    ? q["native_ungrazed_configuration"]
                                    : null,
                                native_ungrazed_management: has_what.has_native_ungrazed
                                    ? q["native_ungrazed_management"]
                                    : null,
                                native_ungrazed_previous_land_cover: has_what.has_native_ungrazed
                                    ? q["native_ungrazed_previous_land_cover"]
                                    : null,

                                native_grazed_area: has_what.has_native_grazed ? q["native_grazed_area"] : null,
                                native_grazed_area_converted: has_what.has_native_grazed
                                    ? q["native_grazed_area_converted"]
                                    : null,
                                native_grazed_configuration: has_what.has_native_grazed
                                    ? q["native_grazed_configuration"]
                                    : null,
                                native_grazed_management: has_what.has_native_grazed
                                    ? q["native_grazed_management"]
                                    : null,
                                native_grazed_previous_land_cover: has_what.has_native_grazed
                                    ? q["native_grazed_previous_land_cover"]
                                    : null,
                                native_grazed_grazing_intensity: has_what.has_native_grazed
                                    ? q["native_grazed_grazing_intensity"]
                                    : null,
                                native_grazed_grazing_conservation: has_what.has_native_grazed
                                    ? q["native_grazed_grazing_conservation"]
                                    : null,

                                nonnative_ungrazed_area: has_what.has_nonnative_ungrazed
                                    ? q["nonnative_ungrazed_area"]
                                    : null,
                                nonnative_ungrazed_area_converted: has_what.has_nonnative_ungrazed
                                    ? q["nonnative_ungrazed_area_converted"]
                                    : null,
                                nonnative_ungrazed_configuration: has_what.has_nonnative_ungrazed
                                    ? q["nonnative_ungrazed_configuration"]
                                    : null,
                                nonnative_ungrazed_management: has_what.has_nonnative_ungrazed
                                    ? q["nonnative_ungrazed_management"]
                                    : null,
                                nonnative_ungrazed_previous_land_cover: has_what.has_nonnative_ungrazed
                                    ? q["nonnative_ungrazed_previous_land_cover"]
                                    : null,

                                nonnative_grazed_area: has_what.has_nonnative_grazed
                                    ? q["nonnative_grazed_area"]
                                    : null,
                                nonnative_grazed_area_converted: has_what.has_nonnative_grazed
                                    ? q["nonnative_grazed_area_converted"]
                                    : null,
                                nonnative_grazed_configuration: has_what.has_nonnative_grazed
                                    ? q["nonnative_grazed_configuration"]
                                    : null,
                                nonnative_grazed_management: has_what.has_nonnative_grazed
                                    ? q["nonnative_grazed_management"]
                                    : null,
                                nonnative_grazed_previous_land_cover: has_what.has_nonnative_grazed
                                    ? q["nonnative_grazed_previous_land_cover"]
                                    : null,
                                nonnative_grazed_grazing_intensity: has_what.has_nonnative_grazed
                                    ? q["nonnative_grazed_grazing_intensity"]
                                    : null,
                                nonnative_grazed_grazing_conservation: has_what.has_nonnative_grazed
                                    ? q["nonnative_grazed_grazing_conservation"]
                                    : null,
                                completed: validation["grassland"][0] === 0 ? true : false,
                                missing: validation["grassland"][0],
                                required: validation["grassland"][1],
                            }),
                        );
                    }
                } else if (landCoverType === "buffer") {
                    if (this.props.farmHpiSurvey.buffer === undefined) {
                        promises.push(
                            ormFarmHpiRiparianQuestionnaireCreateRemoteFirst({
                                survey_id: this.props.farmHpiSurvey.id,
                                farm_id: this.props.farmHpiSurvey.farm.id,
                                code: "01",
                                missing: 1,
                                required: 1,
                            }),
                        );
                    } else {
                        const q = this.props.farmHpiSurvey.buffer;
                        promises.push(
                            ormFarmHpiRiparianQuestionnaireUpdate({
                                id: q.id,
                                edge_area: has_what.has_edge ? q["edge_area"] : null,
                                edge_area_converted: has_what.has_edge ? q["edge_area_converted"] : null,
                                edge_vegetated: has_what.has_edge ? q["edge_vegetated"] : null,
                                edge_management: has_what.has_edge ? q["edge_management"] : null,
                                edge_previous_land_cover: has_what.has_edge ? q["edge_previous_land_cover"] : null,
                                riparian_area: has_what.has_riparian ? q["riparian_area"] : null,
                                riparian_area_converted: has_what.has_riparian ? q["riparian_area_converted"] : null,
                                riparian_vegetated: has_what.has_riparian ? q["riparian_vegetated"] : null,
                                riparian_management: has_what.has_riparian ? q["riparian_management"] : null,
                                riparian_previous_land_cover: has_what.has_riparian
                                    ? q["riparian_previous_land_cover"]
                                    : null,
                                completed: validation["buffer"][0] === 0 ? true : false,
                                missing: validation["buffer"][0],
                                required: validation["buffer"][1],
                            }),
                        );
                    }
                } else if (landCoverType === "surfacewater") {
                    if (this.props.farmHpiSurvey.surfacewater === undefined) {
                        promises.push(
                            ormFarmHpiSurfaceWaterQuestionnaireCreateRemoteFirst({
                                survey_id: this.props.farmHpiSurvey.id,
                                farm_id: this.props.farmHpiSurvey.farm.id,
                                code: "01",
                                missing: 1,
                                required: 1,
                            }),
                        );
                    } else {
                        const q = this.props.farmHpiSurvey.surfacewater;

                        var surfaceWaterObj = {
                            id: q.id,
                            stream_length: has_what.has_streams ? q["stream_length"] : null,
                            stream_bank_condition: has_what.has_streams ? q["stream_bank_condition"] : null,
                            stream_bank_management: has_what.has_streams ? q["stream_bank_management"] : null,
                            stream_dams: has_what.has_streams ? q["stream_dams"] : null,
                            stream_instream_habitat: has_what.has_streams ? q["stream_instream_habitat"] : null,
                            stream_modifications: has_what.has_streams ? q["stream_modifications"] : null,
                            stream_riparian: has_what.has_streams ? q["stream_riparian"] : null,
                            stream_riparian_crop: has_what.has_streams ? q["stream_riparian_crop"] : null,
                            stream_riparian_impervious: has_what.has_streams ? q["stream_riparian_impervious"] : null,
                            stream_riparian_limited_cattle_access: has_what.has_streams
                                ? q["stream_riparian_limited_cattle_access"]
                                : null,
                            stream_riparian_undisturbed: has_what.has_streams ? q["stream_riparian_undisturbed"] : null,
                            stream_riparian_unlimited_cattle_access: has_what.has_streams
                                ? q["stream_riparian_unlimited_cattle_access"]
                                : null,
                            stream_riparian_vegetation: has_what.has_streams ? q["stream_riparian_vegetation"] : null,

                            river_length: has_what.has_rivers ? q["river_length"] : null,
                            river_bank_condition: has_what.has_rivers ? q["river_bank_condition"] : null,
                            river_bank_management: has_what.has_rivers ? q["river_bank_management"] : null,
                            river_dams: has_what.has_rivers ? q["river_dams"] : null,
                            river_instream_habitat: has_what.has_rivers ? q["river_instream_habitat"] : null,
                            river_modifications: has_what.has_rivers ? q["river_modifications"] : null,
                            river_riparian: has_what.has_rivers ? q["river_riparian"] : null,
                            river_riparian_crop: has_what.has_rivers ? q["river_riparian_crop"] : null,
                            river_riparian_impervious: has_what.has_rivers ? q["river_riparian_impervious"] : null,
                            river_riparian_limited_cattle_access: has_what.has_rivers
                                ? q["river_riparian_limited_cattle_access"]
                                : null,
                            river_riparian_undisturbed: has_what.has_rivers ? q["river_riparian_undisturbed"] : null,
                            river_riparian_unlimited_cattle_access: has_what.has_rivers
                                ? q["river_riparian_unlimited_cattle_access"]
                                : null,
                            river_riparian_vegetation: has_what.has_rivers ? q["river_riparian_vegetation"] : null,

                            lake_natural_area: has_what.has_lakes_natural ? q["lake_natural_area"] : null,
                            lake_natural_average_depth: has_what.has_lakes_natural
                                ? q["lake_natural_average_depth"]
                                : null,
                            lake_natural_bank_condition: has_what.has_lakes_natural
                                ? q["lake_natural_bank_condition"]
                                : null,
                            lake_natural_bank_management: has_what.has_lakes_natural
                                ? q["lake_natural_bank_management"]
                                : null,
                            lake_natural_management: has_what.has_lakes_natural ? q["lake_natural_management"] : null,
                            lake_natural_predominant_use: has_what.has_lakes_natural
                                ? q["lake_natural_predominant_use"]
                                : null,
                            lake_natural_riparian: has_what.has_lakes_natural ? q["lake_natural_riparian"] : null,
                            lake_natural_riparian_crop: has_what.has_lakes_natural
                                ? q["lake_natural_riparian_crop"]
                                : null,
                            lake_natural_riparian_impervious: has_what.has_lakes_natural
                                ? q["lake_natural_riparian_impervious"]
                                : null,
                            lake_natural_riparian_limited_cattle_access: has_what.has_lakes_natural
                                ? q["lake_natural_riparian_limited_cattle_access"]
                                : null,
                            lake_natural_riparian_undisturbed: has_what.has_lakes_natural
                                ? q["lake_natural_riparian_undisturbed"]
                                : null,
                            lake_natural_riparian_unlimited_cattle_access: has_what.has_lakes_natural
                                ? q["lake_natural_riparian_unlimited_cattle_access"]
                                : null,
                            lake_natural_riparian_vegetation: has_what.has_lakes_natural
                                ? q["lake_natural_riparian_vegetation"]
                                : null,

                            lake_artificial_area: has_what.has_lakes_artificial ? q["lake_artificial_area"] : null,
                            lake_artificial_average_depth: has_what.has_lakes_artificial
                                ? q["lake_artificial_average_depth"]
                                : null,
                            lake_artificial_bank_condition: has_what.has_lakes_artificial
                                ? q["lake_artificial_bank_condition"]
                                : null,
                            lake_artificial_bank_management: has_what.has_lakes_artificial
                                ? q["lake_artificial_bank_management"]
                                : null,
                            lake_artificial_management: has_what.has_lakes_artificial
                                ? q["lake_artificial_management"]
                                : null,
                            lake_artificial_predominant_use: has_what.has_lakes_artificial
                                ? q["lake_artificial_predominant_use"]
                                : null,
                            lake_artificial_riparian: has_what.has_lakes_artificial
                                ? q["lake_artificial_riparian"]
                                : null,
                            lake_artificial_riparian_crop: has_what.has_lakes_artificial
                                ? q["lake_artificial_riparian_crop"]
                                : null,
                            lake_artificial_riparian_impervious: has_what.has_lakes_artificial
                                ? q["lake_artificial_riparian_impervious"]
                                : null,
                            lake_artificial_riparian_limited_cattle_access: has_what.has_lakes_artificial
                                ? q["lake_artificial_riparian_limited_cattle_access"]
                                : null,
                            lake_artificial_riparian_undisturbed: has_what.has_lakes_artificial
                                ? q["lake_artificial_riparian_undisturbed"]
                                : null,
                            lake_artificial_riparian_unlimited_cattle_access: has_what.has_lakes_artificial
                                ? q["lake_artificial_riparian_unlimited_cattle_access"]
                                : null,
                            lake_artificial_riparian_vegetation: has_what.has_lakes_artificial
                                ? q["lake_artificial_riparian_vegetation"]
                                : null,

                            pond_natural_area: has_what.has_ponds_natural ? q["pond_natural_area"] : null,
                            pond_natural_average_depth: has_what.has_ponds_natural
                                ? q["pond_natural_average_depth"]
                                : null,
                            pond_natural_bank_condition: has_what.has_ponds_natural
                                ? q["pond_natural_bank_condition"]
                                : null,
                            pond_natural_bank_management: has_what.has_ponds_natural
                                ? q["pond_natural_bank_management"]
                                : null,
                            pond_natural_management: has_what.has_ponds_natural ? q["pond_natural_management"] : null,
                            pond_natural_predominant_use: has_what.has_ponds_natural
                                ? q["pond_natural_predominant_use"]
                                : null,
                            pond_natural_riparian: has_what.has_ponds_natural ? q["pond_natural_riparian"] : null,
                            pond_natural_riparian_crop: has_what.has_ponds_natural
                                ? q["pond_natural_riparian_crop"]
                                : null,
                            pond_natural_riparian_impervious: has_what.has_ponds_natural
                                ? q["pond_natural_riparian_impervious"]
                                : null,
                            pond_natural_riparian_limited_cattle_access: has_what.has_ponds_natural
                                ? q["pond_natural_riparian_limited_cattle_access"]
                                : null,
                            pond_natural_riparian_undisturbed: has_what.has_ponds_natural
                                ? q["pond_natural_riparian_undisturbed"]
                                : null,
                            pond_natural_riparian_unlimited_cattle_access: has_what.has_ponds_natural
                                ? q["pond_natural_riparian_unlimited_cattle_access"]
                                : null,
                            pond_natural_riparian_vegetation: has_what.has_ponds_natural
                                ? q["pond_natural_riparian_vegetation"]
                                : null,

                            pond_artificial_area: has_what.has_ponds_artificial ? q["pond_artificial_area"] : null,
                            pond_artificial_average_depth: has_what.has_ponds_artificial
                                ? q["pond_artificial_average_depth"]
                                : null,
                            pond_artificial_bank_condition: has_what.has_ponds_artificial
                                ? q["pond_artificial_bank_condition"]
                                : null,
                            pond_artificial_bank_management: has_what.has_ponds_artificial
                                ? q["pond_artificial_bank_management"]
                                : null,
                            pond_artificial_management: has_what.has_ponds_artificial
                                ? q["pond_artificial_management"]
                                : null,
                            pond_artificial_predominant_use: has_what.has_ponds_artificial
                                ? q["pond_artificial_predominant_use"]
                                : null,
                            pond_artificial_riparian: has_what.has_ponds_artificial
                                ? q["pond_artificial_riparian"]
                                : null,
                            pond_artificial_riparian_crop: has_what.has_ponds_artificial
                                ? q["pond_artificial_riparian_crop"]
                                : null,
                            pond_artificial_riparian_impervious: has_what.has_ponds_artificial
                                ? q["pond_artificial_riparian_impervious"]
                                : null,
                            pond_artificial_riparian_limited_cattle_access: has_what.has_ponds_artificial
                                ? q["pond_artificial_riparian_limited_cattle_access"]
                                : null,
                            pond_artificial_riparian_undisturbed: has_what.has_ponds_artificial
                                ? q["pond_artificial_riparian_undisturbed"]
                                : null,
                            pond_artificial_riparian_unlimited_cattle_access: has_what.has_ponds_artificial
                                ? q["pond_artificial_riparian_unlimited_cattle_access"]
                                : null,
                            pond_artificial_riparian_vegetation: has_what.has_ponds_artificial
                                ? q["pond_artificial_riparian_vegetation"]
                                : null,
                            completed: validation["surfacewater"][0] === 0 ? true : false,
                            missing: validation["surfacewater"][0],
                            required: validation["surfacewater"][1],
                        };

                        promises.push(ormFarmHpiSurfaceWaterQuestionnaireUpdate(surfaceWaterObj));
                    }
                } else if (landCoverType === "wetland") {
                    if (this.props.farmHpiSurvey.wetland === undefined) {
                        promises.push(
                            ormFarmHpiWetlandQuestionnaireCreateRemoteFirst({
                                survey_id: this.props.farmHpiSurvey.id,
                                farm_id: this.props.farmHpiSurvey.farm.id,
                                code: "01",
                                missing: 1,
                                required: 1,
                            }),
                        );
                    } else {
                        const q = this.props.farmHpiSurvey.wetland;
                        promises.push(
                            ormFarmHpiWetlandQuestionnaireUpdate({
                                id: q.id,

                                natural_area: has_what.has_wetland_artificial ? q["natural_area"] : null,
                                natural_area_converted: has_what.has_wetland_artificial
                                    ? q["natural_area_converted"]
                                    : null,
                                natural_management: has_what.has_wetland_artificial ? q["natural_management"] : null,
                                natural_habitat_management: has_what.has_wetland_artificial
                                    ? q["natural_habitat_management"]
                                    : null,
                                natural_previous_land_cover: has_what.has_wetland_artificial
                                    ? q["natural_previous_land_cover"]
                                    : null,

                                artificial_area: has_what.has_wetland_artificial ? q["artificial_area"] : null,
                                artificial_area_converted: has_what.has_wetland_artificial
                                    ? q["artificial_area_converted"]
                                    : null,
                                artificial_management: has_what.has_wetland_artificial
                                    ? q["artificial_management"]
                                    : null,
                                artificial_habitat_management: has_what.has_wetland_artificial
                                    ? q["artificial_habitat_management"]
                                    : null,
                                artificial_previous_land_cover: has_what.has_wetland_artificial
                                    ? q["artificial_previous_land_cover"]
                                    : null,
                                completed: validation["wetland"][0] === 0 ? true : false,
                                missing: validation["wetland"][0],
                                required: validation["wetland"][1],
                            }),
                        );
                    }
                }
            } else {
                //  land cover group is not selected, delete questionnaire if it exists

                if (landCoverType === "forest") {
                    if (this.props.farmHpiSurvey.forest !== undefined) {
                        promises.push(ormFarmHpiForestQuestionnaireDelete(this.props.farmHpiSurvey.forest.id));
                    }
                } else if (landCoverType === "grassland") {
                    if (this.props.farmHpiSurvey.grassland !== undefined) {
                        promises.push(ormFarmHpiGrasslandQuestionnaireDelete(this.props.farmHpiSurvey.grassland.id));
                    }
                } else if (landCoverType === "buffer") {
                    if (this.props.farmHpiSurvey.buffer !== undefined) {
                        promises.push(ormFarmHpiRiparianQuestionnaireDelete(this.props.farmHpiSurvey.buffer.id));
                    }
                } else if (landCoverType === "surfacewater") {
                    if (this.props.farmHpiSurvey.surfacewater !== undefined) {
                        promises.push(
                            ormFarmHpiSurfaceWaterQuestionnaireDelete(this.props.farmHpiSurvey.surfacewater.id),
                        );
                    }
                } else if (landCoverType === "wetland") {
                    if (this.props.farmHpiSurvey.wetland !== undefined) {
                        promises.push(ormFarmHpiWetlandQuestionnaireDelete(this.props.farmHpiSurvey.wetland.id));
                    }
                }
            }
        });

        promises.push(
            ormFarmHpiSurveyUpdate({
                id: this.props.farmHpiSurvey.id,
                landtypes: selection_string,
                results: null,
            }),
        );

        Promise.all(promises).then((result) => {
            if (this.state.draftClicked) {
                this.props.history.push("/farm/" + this.props.farmHpiSurvey.farm.id + "/edit");
            } else {
                this.props.handleNext();
            }
        });
    };

    componentWillMount() {}

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    errorValidator = (values) => {
        console.log("errorValidator");
        var valObj = {};
        return valObj;
    };

    render() {
        const { classes } = this.props;

        const { helpDialogOpen, nothing_selected } = this.state;

        const defaultValues = this.getDefaultValues();

        return (
            <>
                <HelpDialog
                    open={helpDialogOpen}
                    onClose={() => this.setState({ helpDialogOpen: false })}
                    question="Selection Required"
                    text="You must select at least one land type to continue."
                    confirmAction={this.confirmHarvest}
                    cancelAction={this.cancelHarvest}
                />

                <Form
                    getApi={(el) => (this.form = el)}
                    dontValidateOnMount="true"
                    validateOnSubmit="true"
                    defaultValues={defaultValues}
                    validateError={(values) => this.errorValidator(values)}
                    onSubmit={(values) => this.updateSurvey(values)}
                >
                    {(formApi) => (
                        <form onSubmit={formApi.submitForm}>
                            <Grid container spacing={0}>
                                <Grid item xs={12}>
                                    <br />
                                    <Typography gutterBottom>
                                        Please select which non-cultivated land areas are on your farm:
                                    </Typography>

                                    {nothing_selected === true && (
                                        <Typography gutterBottom className={classes.redText}>
                                            You must select at least one non-cultivated land type.
                                        </Typography>
                                    )}
                                </Grid>

                                <Grid item xs={12}>
                                    <div className={classes.indent}>
                                        <Grid container spacing={0}>
                                            <Grid item xs={12}>
                                                <CheckboxGroup
                                                    field="buffer"
                                                    label={landcover_groups.filter((g) => g.id === "1")[0]["name"]}
                                                    row={false}
                                                    options={this.getLandTypeOptions("1")}
                                                />
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>

                                <Grid item xs={12}>
                                    <div className={classes.indent}>
                                        <Grid container spacing={0}>
                                            <Grid item xs={12}>
                                                <CheckboxGroup
                                                    field="forest"
                                                    label={landcover_groups.filter((g) => g.id === "2")[0]["name"]}
                                                    row={false}
                                                    options={this.getLandTypeOptions("2")}
                                                />
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>

                                <Grid item xs={12}>
                                    <div className={classes.indent}>
                                        <Grid container spacing={0}>
                                            <Grid item xs={12}>
                                                <CheckboxGroup
                                                    field="grassland"
                                                    label={landcover_groups.filter((g) => g.id === "3")[0]["name"]}
                                                    row={false}
                                                    options={this.getLandTypeOptions("3")}
                                                />
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>

                                <Grid item xs={12}>
                                    <div className={classes.indent}>
                                        <Grid container spacing={0}>
                                            <Grid item xs={12}>
                                                <CheckboxGroup
                                                    field="wetland"
                                                    label={landcover_groups.filter((g) => g.id === "4")[0]["name"]}
                                                    row={false}
                                                    options={this.getLandTypeOptions("4")}
                                                />
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>

                                <Grid item xs={12}>
                                    <div className={classes.indent}>
                                        <Grid container spacing={0}>
                                            <Grid item xs={12}>
                                                <CheckboxGroup
                                                    field="surfacewater"
                                                    label={landcover_groups.filter((g) => g.id === "5")[0]["name"]}
                                                    row={false}
                                                    options={this.getLandTypeOptions("5")}
                                                />
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>

                                <Grid item xs={12} className={classes.rightAlign}>
                                    <Button
                                        variant="raised"
                                        type="submit"
                                        onClick={() => this.setState({ draftClicked: true })}
                                        className={classes.button}
                                    >
                                        Save and Return to Farm Homepage
                                    </Button>
                                    <Button
                                        type="submit"
                                        variant="raised"
                                        color="primary"
                                        onClick={() => this.setState({ draftClicked: false })}
                                        className={classes.button}
                                    >
                                        Save and Move to Next Step
                                        <ChevronRightIcon />
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </Form>
            </>
        );
    }
}

LandTypes = connect((state, ownProps) => ({}), {
    ...FarmHpiSurvey.actions,
    ...FarmHpiForestQuestionnaire.actions,
    ...FarmHpiGrasslandQuestionnaire.actions,
    ...FarmHpiRiparianQuestionnaire.actions,
    ...FarmHpiSurfaceWaterQuestionnaire.actions,
    ...FarmHpiWetlandQuestionnaire.actions,
})(LandTypes);

export default withStyles(styles)(LandTypes);
