import React, { Component } from "react";
import classNames from "classnames";
import Grid from "../common/GridWrapper";
import Terms from "../auth/Terms";
import Privacy from "../auth/Privacy";

import withStyles from "@mui/styles/withStyles";

import Typography from "../common/TypographyWrapper";

const styles = {
    linkColor: {
        color: "#fff",
        cursor: "pointer",
        textDecoration: "none",
        "&:hover": {
            textDecoration: "underline",
        },
    },
    rootText: {
        background: "#333333",
        textAlign: "center",
        color: "#fff",
        padding: "8px 0",
        fontWeight: 200,
    },
    paddingRight: {
        paddingRight: "79px",
        float: "right",
        fontSize: 11,
    },
    paddingLeft: {
        paddingLeft: "79px",
        float: "left",
        fontSize: 11,
    },
    marginLeft: {
        paddingLeft: "10px",
    },
    marginRight: {
        paddingRight: "10px",
    },
    border: {
        border: "none",
    },
};

class Footer extends Component {
    state = {
        termsOpen: false,
        privacyOpen: false,
    };

    openTerms = () => {
        this.setState({ termsOpen: true });
    };

    closeTerms = () => {
        this.setState({ termsOpen: false });
    };

    openPrivacy = () => {
        this.setState({ privacyOpen: true });
    };

    closePrivacy = () => {
        this.setState({ privacyOpen: false });
    };

    render() {
        const { classes } = this.props;
        const { termsOpen, privacyOpen } = this.state;

        return (
            <div className={classes.border}>
                <Typography variant="caption" className={classNames(classes.rootText)}>
                    <Grid container>
                        <Grid item xs={12}>
                            <span className={classes.paddingLeft}>
                                <span
                                    className={classNames(classes.linkColor, classes.marginRight)}
                                    onClick={this.openPrivacy}
                                >
                                    Data and Privacy Policy
                                </span>
                                |
                                <span
                                    className={classNames(classes.linkColor, classes.marginLeft)}
                                    onClick={this.openTerms}
                                >
                                    Terms of Use
                                </span>
                            </span>
                            <span className={classes.paddingRight}>
                                <a
                                    className={classNames(classes.linkColor, classes.marginRight)}
                                    href="mailto:support@fieldtomarket.org"
                                >
                                    support@fieldtomarket.org
                                </a>
                                |
                                <a
                                    className={classNames(classes.linkColor, classes.marginLeft)}
                                    rel="noopener noreferrer"
                                    href="https://fieldtomarket.org"
                                    target="_blank"
                                >
                                    www.fieldtomarket.org
                                </a>
                            </span>
                        </Grid>
                    </Grid>
                    <Terms dialogOpen={termsOpen} closeEvent={this.closeTerms} />
                    <Privacy dialogOpen={privacyOpen} closeEvent={this.closePrivacy} />
                </Typography>
            </div>
        );
    }
}

export default withStyles(styles)(Footer);
