import React, { Component } from "react";
import withRouter from "../common/withRouter";
import { Form } from "react-form";
import withStyles from "@mui/styles/withStyles";
import Grid from "../common/GridWrapper";
import { Dialog, DialogContent, DialogActions } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import Button from "../common/ButtonWrapper";
import Typography from "../common/TypographyWrapper";
import Close from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import TextField from "@mui/material/TextField";
import Collapse from "@mui/material/Collapse";

import * as utils from "./CropManagementUtils";
import TemplateLibraryTable from "./tables/TemplateLibraryTable";

import Select from "../common/Select";
import { MAKE_OPTIONS } from "../../api/constants";

const styles = (theme) => ({
    flex: {
        flex: 1,
    },
    contentPadding: {
        padding: "24px",
    },
    toolbarBackground: {
        backgroundColor: "#ff8300",
    },
    cancelBorder: {
        border: "1px solid",
    },
    buttonMargin: {
        marginLeft: 12,
        marginBottom: 0,
    },
    preview: {
        minWidth: 500,
        minHeight: 100,
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        minWidth: 250,
    },
    errorBox: {
        minWidth: 400,
    },
    searchButton: {
        border: "1px solid #979797",
    },
    chip: {
        margin: theme.spacing(1 / 2),
    },
    orange: {
        color: "#ff8300",
    },
});

class CopyProjectTemplateDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            projectId: "0",
            viewId: null,
            copyId: null,
            templateCopied: false,
        };
    }

    resetState = () => {
        this.setState({
            projectId: "0",
            viewId: null,
            copyId: null,
            templateCopied: false,
        });
    };

    prepEvents() {
        var rotationSystem = this.props.rotationSystems.filter((s) => s.id === this.state.viewId)[0];
        var events = [];
        var that = this;

        function getOperationTip(id) {
            if (that.state && that.props.operations) {
                let matches = that.props.operations.filter((operation) => operation.id.toString() === id.toString());
                if (matches.length > 0) {
                    return matches[0].opNotes;
                }
            }
            return null;
        }

        if (rotationSystem && rotationSystem.events) {
            var temp_events = utils.updateRotationEventsWithNewRealDates(rotationSystem["events"]);

            events = temp_events.map((event, i) => ({
                id: i,
                index: event["index"] === undefined ? utils.GetGUID() : event["index"],
                date: event["date"],
                day: [event["date"].split("-")[1], event["date"].split("-")[2]].join("-"),
                year: "Year " + parseInt(event["date"].split("-")[0]).toString(),
                operationName: event.operation ? event.operation.name : "",
                operationId: event.operation ? event.operation.id : "",
                stir: event.operation ? event.operation.stir : "",
                crop: event.crop ? event.crop.name : "",
                yield: event.crop ? event.crop.defaultYield : "",
                yieldUnit: event.crop ? event.crop.yieldUnit : "",
                residue: event.residue ? event.residue.name : "",
                residueAmount: event.residue ? event.operation.resAdded : "",
                operationTip: event.operation ? getOperationTip(event.operation.id) : "",
            }));

            // sort by date
            events.sort(function (a, b) {
                a = a.date.split("-").join("");
                b = b.date.split("-").join("");
                return a > b ? 1 : a < b ? -1 : 0;
            });
        }
        return events;
    }

    handleDialogCloseClick = () => {
        this.resetState();
        this.props.onDialogCloseClick();
    };

    handleViewSystemClick = (id) => {
        const system = this.props.rotationSystems.find((s) => s.id === id);
        if (!system) {
            return;
        }
        this.setState({
            viewId: id,
            copyId: null,
            templateCopied: null,
            name: "",
        });
        if (!system.events) {
            this.props.ormProjectRotationSystemLoadDetail(id);
        }
    };

    handleCopySystemClick = (id) => {
        const system = this.props.rotationSystems.find((s) => s.id === id);
        if (!system) {
            return;
        }
        this.setState({
            viewId: null,
            copyId: id,
            name: system.name,
            templateCopied: false,
        });
        if (!system.events) {
            this.props.ormProjectRotationSystemLoadDetail(id);
        }
    };

    handleNameChange = (e) => {
        var name = e.target.value.substring(0, 250);
        this.setState({ name: name });
    };

    getNameError = () => {
        const { name } = this.state;
        if (name) {
            if (name.trim().length < 3) {
                return "Must be 3 - 250 characters.";
            }
        }
    };

    handleCopyClick = () => {
        var rotationSystem = this.props.rotationSystems.filter((s) => s.id === this.state.copyId)[0];
        if (!rotationSystem.events) {
            return;
        }

        var rotationSystemObj = {
            name: this.state.name,
            template: rotationSystem.id,
            template_name: rotationSystem.template_name,
        };

        let events = rotationSystem.events.map((event) => {
            return {
                date: event.date,
                operation: event.operation,
                crop: event.crop,
                residue: event.residue,
            };
        });

        const parameters = {
            ormRotationSystemCreate: this.props.ormRotationSystemCreate,
            ormRotationEventCreate: this.props.ormRotationEventCreate,
            ormRotationSystemUpdate: this.props.ormRotationSystemUpdate,
            rotationSystem: rotationSystemObj,
            events: events,
            projectIds: null,
            operations: this.props.operations,
            crops: this.props.crops,
        };

        utils.createRotationSystemAndEvents(parameters);

        this.setState({
            templateCopied: true,
        });
    };

    render() {
        const { classes, rotationSystems, cropyearId, projectOptions } = this.props;

        const { projectId, viewId, copyId, templateCopied, name } = this.state;

        const columnData = [
            { id: "name", numeric: false, disablePadding: false, label: "Name", allowSort: true },
            {
                id: "template_name",
                numeric: false,
                disablePadding: false,
                label: "NRCS Template Name",
                allowSort: true,
            },
            {
                id: "modify_date",
                numeric: false,
                disablePadding: false,
                label: "Last Updated",
                allowSort: true,
                isDate: true,
            },
        ];

        const previewColumnData = [
            { id: "year", numeric: false, disablePadding: false, label: "Year", allowSort: false },
            { id: "day", numeric: false, disablePadding: false, label: "Day", allowSort: false },
            { id: "operationName", numeric: false, disablePadding: false, label: "Operation Name", allowSort: false },
            { id: "crop", numeric: false, disablePadding: false, label: "Crop", allowSort: false },
            { id: "residue", numeric: false, disablePadding: false, label: "Residue", allowSort: false },
            { id: "residueAmount", numeric: false, disablePadding: false, label: "Residue Amount", allowSort: false },
        ];

        const events = this.prepEvents();

        const filtered_rotation_systems =
            projectId === "0"
                ? rotationSystems
                : rotationSystems.filter((r) => r.project_id === parseInt(this.state.projectId));

        const nameError = this.getNameError();

        return (
            <Dialog
                open={this.props.open}
                onClose={this.handleClose}
                aria-labelledby="form-dialog-title"
                maxWidth={"md"}
            >
                <Toolbar>
                    <Typography variant="title" className={classes.flex}>
                        Crop Project Rotation Template
                    </Typography>
                    <Tooltip title="Close">
                        <IconButton onClick={() => this.handleDialogCloseClick()} size="large">
                            <Close color="primary" />
                        </IconButton>
                    </Tooltip>
                </Toolbar>

                <DialogContent className={classes.contentPadding}>
                    <Grid container spacing={24}>
                        {projectOptions.length > 1 && (
                            <Grid item xs={12} sm={6} md={4}>
                                <Form>
                                    {(formApi) => (
                                        <form>
                                            <Select
                                                field="project"
                                                label="Filter by Project Assignment"
                                                help="Select a project to filter crop rotation templates for that project."
                                                options={MAKE_OPTIONS(projectOptions)}
                                                eventHandle={(id) =>
                                                    this.setState({
                                                        projectId: id,
                                                        viewId: null,
                                                        copyId: null,
                                                        templateCopied: false,
                                                    })
                                                }
                                                fullWidth
                                                margin="normal"
                                            />
                                        </form>
                                    )}
                                </Form>
                            </Grid>
                        )}

                        <Grid item xs={12}>
                            <TemplateLibraryTable
                                orderBy={"template_name"}
                                data={filtered_rotation_systems}
                                columnData={columnData}
                                noRecordsMessage={"No saved templates."}
                                onView={this.handleViewSystemClick}
                                onCopy={this.handleCopySystemClick}
                            />
                        </Grid>

                        <Collapse in={copyId} timeout="auto" style={{ width: "100%" }}>
                            <Grid item xs={12} style={{ border: "1px solid lightgrey", padding: "12px" }}>
                                <Typography gutterBottom variant="body1">
                                    Copy Rotation System:{" "}
                                    {copyId ? rotationSystems.filter((s) => s.id === copyId)[0].name : ""}
                                </Typography>

                                {!templateCopied && (
                                    <>
                                        <Typography gutterBottom variant="body1">
                                            Enter a name for the new crop rotation system. Click Copy to copy the
                                            project template to your Crop Rotation Library. From there you can make
                                            edits to the rotation system as necessary.
                                        </Typography>

                                        <TextField
                                            required
                                            className={classes.textField}
                                            margin="normal"
                                            color="primary"
                                            onChange={this.handleNameChange}
                                            value={name}
                                            helperText={nameError}
                                        />

                                        <Button
                                            onClick={(e) => this.handleCopyClick(e)}
                                            className={classes.buttonMargin}
                                            variant="raised"
                                            color="primary"
                                            disabled={name && name.length >= 3 && name.length <= 250 ? false : true}
                                        >
                                            Copy
                                        </Button>
                                    </>
                                )}

                                {templateCopied && (
                                    <Typography variant="body1">
                                        Project Rotation Template{" "}
                                        <strong className={classes.orange}>
                                            {" "}
                                            {rotationSystems.filter((s) => s.id === copyId)[0].name}{" "}
                                        </strong>{" "}
                                        has been copied to your Crop Rotation Library with the name{" "}
                                        <strong className={classes.orange}>{this.state.name}</strong>.
                                    </Typography>
                                )}
                            </Grid>
                        </Collapse>

                        <Collapse in={viewId} timeout="auto" style={{ width: "100%" }}>
                            <Grid item xs={12} style={{ border: "1px solid lightgrey", padding: "12px" }}>
                                <Typography gutterBottom variant="body">
                                    Preview of Rotation System:{" "}
                                    {viewId ? rotationSystems.filter((s) => s.id === viewId)[0].name : ""}
                                </Typography>

                                <TemplateLibraryTable
                                    orderBy={""}
                                    useEmptyRows={false}
                                    data={events}
                                    columnData={previewColumnData}
                                    noRecordsMessage={"No rotation events."}
                                    toolTips={[{ column: "operationName", tip: "operationTip" }]}
                                />
                            </Grid>
                        </Collapse>
                    </Grid>
                </DialogContent>
                {cropyearId && (
                    <DialogActions>
                        <Button
                            onClick={() => this.props.history.push("/cropyear/" + cropyearId)}
                            className={classes.buttonMargin}
                            variant="raised"
                            color="primary"
                        >
                            Return to Editing Crop Year
                        </Button>
                    </DialogActions>
                )}
            </Dialog>
        );
    }
}

export default withStyles(styles)(withRouter(CopyProjectTemplateDialog));
