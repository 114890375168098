import React from "react";
import { connect } from "react-redux";
import Grid from "../common/GridWrapper";

import { Badge, ListItemIcon } from "@mui/material";
import Typography from "../common/TypographyWrapper";
import FarmBoundaryIcon from "../common/icons/FarmBoundaryIcon";
import { toggleAccordian } from "../common/actions";
import CropYearComponent from "./CropYearComponent";
import ActionMenu from "../common/ActionMenu";
import CircleIcon from "@mui/icons-material/Circle";
import withRouter from "../common/withRouter";
import { Project, ProjectUser } from "../project/models";
import { CropYear, FieldActivity } from "../field/fieldactivities/models";
import Tooltip from "@mui/material/Tooltip";
import { SoilTextures } from "../field/models";
import FieldNameDialog from "./FieldNameDialog";
import WarningDialog from "../common/WarningDialog";
import { Field as FieldModel } from "../field/models";
import * as navActions from "../common/actions";
import AddCropYear from "../field/AddCropYear";

const allProjectUsers = ProjectUser.selectAll((PU) => ({
    project_attributes: PU.project,
    ...PU.ref,
}));

const allSoilTextures = SoilTextures.selectAll();

class FarmFieldComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            deleteDialogOpen: false,
            deleteDialogText: null,
            deleteDialogConfirmAction: null,
            renameFieldOpen: false,
            deleteFieldDialogConfirmAction: null,
            deleteFieldDialogOpen: false,
            deleteFieldDialogText: null,
            addCropYearOpen: false,
        };
        this.closeNewCropYearDialog = this.closeNewCropYearDialog.bind(this);
    }

    deleteField = (field, name) => {
        this.setState({
            deleteFieldDialogOpen: true,
            deleteFieldDialogConfirmAction: field,
            deleteFieldDialogText: "Are you sure you wish to permanently delete " + name + "?",
        });
    };

    doDeleteField = (field) => {
        this.props.ormFieldDelete(field);
        this.setState({ deleteFieldDialogOpen: false });
        this.props.navSelectPage("dashboard");
        this.props.history.push("/dashboard");
    };

    optOut = (project, name) => {
        this.setState({
            deleteDialogOpen: true,
            deleteDialogConfirmAction: project,
            deleteDialogText: "Are you sure you wish to opt out of " + name + "?",
        });
    };

    doOptOut = (project) => {
        const { projects, ormProjectUserDelete, ormCropYearReload } = this.props;
        // Find the id of the ProjectUser Model based on the Project id
        const deletion = projects.filter((pU) => pU.project === project)[0];

        ormProjectUserDelete(deletion.id);
        setTimeout(() => {
            ormCropYearReload();
        }, 1000);
        this.setState({ deleteDialogOpen: false });
    };

    handlePopupClickOpen = (event) => {
        event.preventDefault();
        this.setState({ renameFieldOpen: true });
    };

    handlePopupClickClose = () => {
        this.setState({ renameFieldOpen: false });
    };

    handleClick = () => {
        this.props.toggleAccordian(this.props.field.id);
    };

    handleFarmEdit = () => {
        var { field, history } = this.props;

        history.push("/field/" + field.id + "/location");
    };

    closeNewCropYearDialog() {
        this.setState({ addCropYearOpen: false });
    }

    render() {
        var {
            field,
            cropYears,
            classes,
            authState,
            navState,
            syncState,
            soilTextures,
            ormCropYearCreate,
            ormFieldActivityCreate,
        } = this.props;

        const { deleteFieldDialogOpen, deleteFieldDialogText, deleteFieldDialogConfirmAction, addCropYearOpen } =
            this.state;

        const dotSeperator = {
            width: "6px",
            paddingLeft: "3px",
            paddingRight: "3px",
            color: "#4e4e4e",
        };

        return (
            <Grid key={field.id} container maxWidth="lg">
                <Grid item xs={12} style={{ border: "2px solid #839e43", borderRadius: "6px" }}>
                    <Grid container style={{ width: "100%" }}>
                        <Grid item xs={12} style={{ margin: "10px" }}>
                            <Grid container xs={12}>
                                <Grid container sm={9}>
                                    <ListItemIcon>
                                        <FarmBoundaryIcon className={classes.farmIconSize} geojson={field.geometry} />
                                    </ListItemIcon>
                                    <Grid>
                                        <span
                                            style={{
                                                color: "#212121",
                                                fontSize: "18px",
                                                fontWeight: "bold",
                                                paddingRight: "5px",
                                            }}
                                        >
                                            {field.name}
                                        </span>
                                        <Typography style={{ fontSize: "12px" }}>
                                            {field.location === "(unknown)"
                                                ? "Franklin County, Illinois"
                                                : field.location}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid sm={3} alignItems="flex-end" justify="flex-end">
                                    <ActionMenu
                                        title="Actions"
                                        style={{
                                            backgroundColor: "#6d8d22",
                                            color: "white",
                                            height: "30px",
                                            minWidth: "110px",
                                            float: "right",
                                        }}
                                        actions={[
                                            { name: "Edit Boundary", onClick: this.handleFarmEdit },
                                            { name: "Rename", onClick: () => this.setState({ renameFieldOpen: true }) },
                                            {
                                                name: "Add Crop Year",
                                                onClick: () => this.setState({ addCropYearOpen: true }),
                                            },
                                            {
                                                name: "Enroll All Years in Project",
                                                onClick: () => console.log("action 3"),
                                            },
                                            {
                                                name: "Delete",
                                                onClick: () => this.deleteField(field.id, field.name || field.id),
                                            },
                                        ]}
                                    />
                                </Grid>
                                <FieldNameDialog
                                    open={this.state.renameFieldOpen}
                                    field={field}
                                    handlePopupClickClose={this.handlePopupClickClose}
                                />
                                <AddCropYear
                                    fieldId={field.id}
                                    fieldSize={field.size}
                                    cropYearsField={cropYears}
                                    ormCropYearCreate={ormCropYearCreate}
                                    ormFieldActivityCreate={ormFieldActivityCreate}
                                    returnDialog={this.closeNewCropYearDialog}
                                    farm_id={field.farm_id}
                                    farm_name={field.farm_name}
                                    open={addCropYearOpen}
                                />
                                <WarningDialog
                                    confirmAction={() => this.doDeleteField(deleteFieldDialogConfirmAction)}
                                    cancelAction={() => this.setState({ deleteFieldDialogOpen: false })}
                                    open={deleteFieldDialogOpen}
                                    title="Delete Field"
                                    text={deleteFieldDialogText}
                                />
                            </Grid>
                            <Grid container xs={12}>
                                <Grid item>
                                    <Badge color="secondary" style={{ paddingRight: "5px" }}>
                                        <Typography
                                            style={{
                                                fontSize: "12px",
                                                color: "#4e4e4e",
                                                padding: "3px",
                                                marginBottom: "3px",
                                            }}
                                        >
                                            Acres:{" "}
                                            {field !== undefined && field.size !== undefined
                                                ? Math.round(field.size.replace(/\D+$/g, "")).toLocaleString()
                                                : ""}
                                        </Typography>
                                        <CircleIcon style={dotSeperator} className="hideWhenSmall" />
                                    </Badge>
                                </Grid>
                                <Grid item>
                                    <Badge color="secondary" style={{ paddingRight: "5px" }}>
                                        <Typography
                                            style={{
                                                fontSize: "12px",
                                                color: "#4e4e4e",
                                                padding: "3px",
                                                marginBottom: "3px",
                                            }}
                                        >
                                            <Tooltip
                                                title="Dominant surface soil texture class for the field boundary."
                                                arrow
                                                placement="bottom"
                                            >
                                                Surface Soil Texture:{" "}
                                                {soilTextures !== undefined && field.greenhouse !== undefined
                                                    ? soilTextures.find(
                                                          (s) => s.id === field.greenhouse.soil_texture_id,
                                                      ).name
                                                    : "NA"}
                                            </Tooltip>
                                        </Typography>
                                        <CircleIcon style={dotSeperator} className="hideWhenSmall" />
                                    </Badge>
                                </Grid>
                                <Grid item>
                                    <Badge color="secondary" style={{ paddingRight: "5px" }}>
                                        <Typography
                                            style={{
                                                fontSize: "12px",
                                                color: "#4e4e4e",
                                                padding: "3px",
                                                marginBottom: "3px",
                                            }}
                                        >
                                            Organic Matter Content:{" "}
                                            {field.waterquality !== undefined ? field.waterquality.omcontent : "NA"}
                                        </Typography>
                                        <CircleIcon style={dotSeperator} className="hideWhenSmall" />
                                    </Badge>
                                </Grid>
                                <Grid item>
                                    <Badge color="secondary" style={{ paddingRight: "5px" }}>
                                        <Typography
                                            style={{
                                                fontSize: "12px",
                                                color: "#4e4e4e",
                                                padding: "3px",
                                                marginBottom: "3px",
                                            }}
                                        >
                                            Slope: {field.soils !== undefined ? field.soils.slope : "NA"}
                                        </Typography>
                                    </Badge>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <>
                    {cropYears
                        .filter((c) => c.field === field.id)
                        .map((cropHistory) => (
                            <CropYearComponent
                                key={"component" + cropHistory.id}
                                field={field}
                                cropHistory={cropHistory}
                                allCropHistory={cropYears.filter((c) => c.field === field.id)}
                                classes={classes}
                                authState={authState}
                                navState={navState}
                                syncState={syncState}
                            />
                        ))}
                </>
            </Grid>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    isExpanded: state.nav.dashboardAccordian != null ? state.nav.dashboardAccordian[ownProps.field.id] : false || false,
    projects: allProjectUsers(state),
    soilTextures: allSoilTextures(state),
});
const mapDispatchToProps = {
    toggleAccordian,
    ...Project.actions,
    ...ProjectUser.actions,
    ...CropYear.actions,
    ...FieldModel.actions,
    ...FieldActivity.actions,
    ...navActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FarmFieldComponent));
