import { useState, useEffect } from "react";
import { useTour } from "@reactour/tour";
import useHistory from "./history";
import { ZoomTools, EditTools, MODE, DRAW_MODES } from "./Tools";
import SoilsLayer from "./layers/SoilsLayer";
import CLULayer from "./layers/CLULayer";
import Draw, { reduceFeatures } from "./Draw";
import MapView from "./MapView";

const TOOLBARS = [
        {
            id: "zoom-tools",
            component: ZoomTools,
            left: true,
        },
        { id: "edit-tools", component: EditTools },
    ],
    LAYERS = [
        { id: "imagery", title: "ESRI Imagery" },
        { id: "soils", title: "SSURGO Soils", component: SoilsLayer },
        { id: "clu", component: CLULayer },
        { id: "draw", component: Draw },
    ];

export default function MapInput({ initialValue, onChange, onAction: onOtherAction, importData, onImported }) {
    const [data, setData, undo, redo] = useHistory(initialValue),
        { steps, currentStep, isOpen: isTourOpen } = useTour(),
        extraFlags = isTourOpen && { select_clu: ["#apply_clu", "#select_clu"].includes(steps[currentStep].selector) },
        [selected, setSelected] = useState(null),
        onClearMode = (setMode, flags, toggleFlag) => {
            setMode("simple_select");
            if (flags.cut) {
                toggleFlag("cut");
            }
        },
        isToolVisible = (tool, flags) => {
            if (
                flags.select_clu &&
                (tool.type === MODE || ["cut", "undo", "redo", "delete", "import", "export"].includes(tool.id))
            ) {
                return false;
            } else if (!flags.select_clu && tool.id === "apply_clu") {
                return false;
            } else {
                return true;
            }
        },
        isToolDisabled = (tool, flags, mapRef) => {
            if (tool.id === "undo" && !undo) {
                return true;
            } else if (tool.id === "redo" && !redo) {
                return true;
            } else if ((tool.id === "export" || tool.id === "zoomfit") && (!data || data.features.length === 0)) {
                return true;
            } else if (tool.id === "select_clu" && mapRef.current && mapRef.current.getZoom() < 11) {
                return true;
            } else if (tool.id === "apply_clu" && (!selected || !flags.select_clu)) {
                return true;
            } else {
                return false;
            }
        },
        isLayerDisabled = (layer, flags, mapRef) => {
            if (layer.id.startsWith("soils") && (flags.select_clu || mapRef.current.getZoom() < 12)) {
                return true;
            } else {
                return false;
            }
        },
        onAction = (action, flags, toggleFlag, setMode, noFlag) => {
            switch (action) {
                case "apply_clu": {
                    if (selected) {
                        setData(reduceFeatures({ ...data, features: data.features.concat(selected.features) }));
                        setSelected(null);
                        if (flags.select_clu && !noFlag) {
                            toggleFlag("select_clu", true);
                        }
                    }
                    break;
                }
                case "delete": {
                    setMode("delete");
                    break;
                }
                case "undo": {
                    if (undo) {
                        undo();
                    }
                    break;
                }
                case "redo": {
                    if (redo) {
                        redo();
                    }
                    break;
                }
                default: {
                    onOtherAction(action);
                }
            }
        },
        onToggleFlag = (id, nextState, mode, setMode, triggerAction, noAction) => {
            if (id === "cut" && nextState && !DRAW_MODES.includes(mode)) {
                setMode("draw_polygon");
            }
            if (id === "select_clu" && selected && !noAction) {
                if (window.confirm("Copy selected CLU polygon to field?")) {
                    triggerAction("apply_clu", true);
                } else {
                    setSelected(null);
                }
            }
        };
    useEffect(() => {
        if (data && data.features.length > 0) {
            onChange(data.features[0].geometry);
        } else {
            onChange(null);
        }
    }, [data, onChange]);
    useEffect(() => {
        if (importData) {
            setData({
                type: "FeatureCollection",
                features: [
                    {
                        type: "Feature",
                        id: 1,
                        geometry: importData,
                    },
                ],
            });
            onImported();
        }
    }, [importData, onImported, setData]);

    return (
        <MapView
            initialFitData={initialValue}
            fitData={data}
            layers={LAYERS}
            extraLayerProps={{ selected, setSelected, data, onSetData: setData }}
            toolbars={TOOLBARS}
            extraFlags={extraFlags}
            isToolVisible={isToolVisible}
            isToolDisabled={isToolDisabled}
            isLayerDisabled={isLayerDisabled}
            onAction={onAction}
            onClearMode={onClearMode}
            onToggleFlag={onToggleFlag}
        />
    );
}
