import React, { Component } from "react";
import { getValue } from "../../../api/utils";
import { getColor, getMetrics, getLabel, getUnitsValue, round } from "./StaticData";

import { withTableStyles, TD, TH } from "./Table";

const D = 11,
    R = D / 2;

function Circle({ fill }) {
    return <circle cx={R} cy={R} r={R} fill={fill} />;
}
function Square({ fill }) {
    return <rect width={D} height={D} x={0} y={0} fill={fill} />;
}
function Triangle({ fill }) {
    return <polygon points={`0,${D} ${D},${D} ${R},0`} fill={fill} />;
}
function Diamond({ fill }) {
    return <polygon points={`0,${R} ${R},${D} ${D},${R} ${R},0`} fill={fill} />;
}

const shapes = {
    active_year: Circle,
    state: Square,
    national: Triangle,
    project: Diamond,
};

function Symbol({ dataKey }) {
    const Shape = shapes[dataKey];
    return (
        <svg height={D} width={D} style={{ marginRight: 4 }}>
            <Shape fill={getColor(dataKey)} />
        </svg>
    );
}

const SpiderGramCell = ({ value }) => <TD printable>{value.value}</TD>;

const SpiderGramRow = ({ classes, data }) => (
    <tr>
        <TD first bold printable>
            {data.label} {data.active_year.unit && <>({data.active_year.unit})</>}
        </TD>
        <SpiderGramCell value={data.active_year} />
        <SpiderGramCell value={data.project} />
        <SpiderGramCell value={data.state} />
        <SpiderGramCell value={data.national} />
    </tr>
);

const SpiderGramRow2 = ({ classes, data }) => (
    <tr>
        <TD first bold printable>
            {data.label} {data.active_year.unit && <>({data.active_year.unit})</>}
        </TD>
        <SpiderGramCell value={data.active_year} />
        <SpiderGramCell value={data.state} />
        <SpiderGramCell value={data.national} />
    </tr>
);

class SpiderGramTable extends Component {
    getTableData() {
        const { cropYear } = this.props,
            metrics = getMetrics(cropYear),
            result = getValue(cropYear, "metrics.Calculator.result.cropyears[0]"),
            project_benchmarks = getValue(cropYear, "project.project_benchmarks");

        const getVal = (metric, benchmark) => {
            let path = metric;
            if (benchmark) {
                path += ".benchmarks." + benchmark;
            }
            path += ".fieldprintResult";
            let val = getValue(result, path);
            if (val && (val.value || val.unit || val.index)) {
                if (metric === "biodiversity") {
                    return {
                        value: round(val.value * 100, metric) + "%",
                        unit: val.unit || "",
                    };
                }
                return {
                    value: round(val.value, metric),
                    unit: getUnitsValue(val, "unit") || "",
                };
            } else {
                return {
                    value: "N/A",
                };
            }
        };

        const getProjectVal = (metric) => {
            const benchmark = project_benchmarks.find((b) => b.metric_label.includes(metric));
            if (!benchmark) {
                return {
                    value: "N/A",
                };
            }
            if (metric === "biodiversity") {
                return {
                    value: round(benchmark.value * 100, metric) + "%",
                    unit: benchmark.units === "dimensionless" ? "" : benchmark.units,
                };
            }
            return {
                value: round(benchmark.value, metric),
                unit: benchmark.units === "dimensionless" ? "" : benchmark.units,
            };
        };

        if (project_benchmarks) {
            return metrics.map((metric) => ({
                label: getLabel(metric),
                active_year: getVal(metric),
                state: getVal(metric, "state"),
                national: getVal(metric, "national"),
                project: getProjectVal(metric),
            }));
        } else {
            return metrics.map((metric) => ({
                label: getLabel(metric),
                active_year: getVal(metric),
                state: getVal(metric, "state"),
                national: getVal(metric, "national"),
            }));
        }
    }

    render() {
        const { classes, cropYear } = this.props,
            tableData = this.getTableData(),
            project_benchmarks = getValue(cropYear, "project.project_benchmarks");

        return (
            <table className={classes.dataTable}>
                <thead>
                    <tr>
                        <TH printable>Metric</TH>
                        <TH printable>
                            <Symbol dataKey="active_year" />
                            Your Result
                        </TH>
                        {project_benchmarks && (
                            <TH printable>
                                <Symbol dataKey="project" />
                                Project Benchmark
                            </TH>
                        )}
                        <TH printable>
                            <Symbol dataKey="state" />
                            State Benchmark
                        </TH>
                        <TH printable>
                            <Symbol dataKey="national" />
                            National Benchmark
                        </TH>
                    </tr>
                </thead>
                <tbody>
                    {tableData.map((row) => {
                        if (project_benchmarks) {
                            return <SpiderGramRow classes={classes} data={row} />;
                        } else {
                            return <SpiderGramRow2 classes={classes} data={row} />;
                        }
                    })}
                </tbody>
            </table>
        );
    }
}

SpiderGramTable = withTableStyles()(SpiderGramTable);

export default SpiderGramTable;
