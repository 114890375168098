import React from "react";
import { Card, CardContent, Badge, Typography } from "@mui/material";
import SnoozeIcon from "@mui/icons-material/Snooze";
import Cancel from "@mui/icons-material/Cancel";

class AlertUpdates extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            viewAllPast: false,
            page: 1,
            itemsPerPage: 2,
        };
    }

    hideAlert = (id) => {
        let date = new Date();
        date.setDate(date.getDate() + 7);
        document.cookie = `hideAlert${id}=true; expires=${date.toUTCString()}`;
        this.forceUpdate();
    };

    dismissAlert = (id) => {
        document.cookie = `hideAlert${id}=true`;
        this.forceUpdate();
    };

    render() {
        const { alerts } = this.props;

        return (
            <Card style={{ paddingTop: "12px" }}>
                {alerts.map(
                    (alert, index) =>
                        !document.cookie.includes(`hideAlert${alert.id}=true`) && (
                            <CardContent key={index} style={{ paddingTop: "0px", paddingBottom: "10px" }}>
                                <Typography style={{ fontWeight: "bold" }}>{alert.label}</Typography>
                                <div
                                    style={{
                                        fontWeight: "200",
                                        fontSize: "small",
                                        marginTop: "-10px",
                                        marginBottom: "-10px",
                                    }}
                                    dangerouslySetInnerHTML={{ __html: alert.content }}
                                />
                                <Badge
                                    onClick={() => this.hideAlert(alert.id)}
                                    color="secondary"
                                    style={{
                                        marginRight: "10px",
                                        backgroundColor: "#e8ebed",
                                        marginBottom: "10px",
                                        cursor: "pointer",
                                    }}
                                >
                                    <SnoozeIcon
                                        style={{
                                            height: "17px",
                                            color: "#515253",
                                            paddingTop: "2px",
                                            paddingBottom: "2px",
                                        }}
                                    />
                                    <span
                                        style={{
                                            fontSize: "11px",
                                            color: "#515253",
                                            paddingRight: "3px",
                                            paddingTop: "3px",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Remind Me Later
                                    </span>
                                </Badge>
                                <Badge
                                    onClick={() => this.dismissAlert(alert.id)}
                                    color="secondary"
                                    style={{
                                        marginRight: "10px",
                                        backgroundColor: "#e8ebed",
                                        marginBottom: "10px",
                                        cursor: "pointer",
                                    }}
                                >
                                    <Cancel
                                        style={{
                                            height: "17px",
                                            color: "#515253",
                                            paddingTop: "2px",
                                            paddingBottom: "2px",
                                        }}
                                    />
                                    <span
                                        style={{
                                            fontSize: "11px",
                                            color: "#515253",
                                            paddingRight: "3px",
                                            paddingTop: "3px",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Dismiss
                                    </span>
                                </Badge>
                            </CardContent>
                        ),
                )}
            </Card>
        );
    }
}

export default AlertUpdates;
