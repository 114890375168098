import React from "react";
import { Dialog, DialogActions, DialogContent, Button, TextField } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import Typography from "../common/TypographyWrapper";
import Close from "@mui/icons-material/CloseOutlined";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { connect } from "react-redux";
import { Field } from "../field/models";

const cancelButtonStyle = {
    color: "#6d8d22",
    borderColor: "#6d8d22",
    backgroundColor: "white",
    border: "1px solid",
    height: "35px",
    borderRadius: "3px",
};

const confirmButtonStyle = {
    color: "white",
    backgroundColor: "#6d8d22",
    height: "35px",
    borderRadius: "3px",
};

class FieldNameDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fieldName: "",
        };
    }

    handleTextChange = (event) => {
        this.setState({ fieldName: event.target.value });
    };

    handleSave = () => {
        const { field, ormFieldUpdate } = this.props;

        ormFieldUpdate({
            id: field.id,
            name: this.state.fieldName,
        });

        this.handleClose();
    };

    handleClose = () => {
        this.props.handlePopupClickClose();
    };

    componentDidUpdate(prevProps) {
        if (!prevProps.open && this.props.open) {
            if (this.props.field.name !== undefined) {
                // set a default value
                this.setState({ fieldName: this.props.field.name });
            }
        }
    }

    render() {
        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.onClose}
                aria-labelledby="form-dialog-title"
                style={{ textAlign: "center", minHeight: "300px" }}
                fullWidth
                maxWidth="xs"
            >
                <Toolbar
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        backgroundColor: "#f15d22",
                        minHeight: 50,
                    }}
                >
                    <Typography variant="title"></Typography>
                    <Tooltip title="Close">
                        <IconButton
                            size="large"
                            style={{ color: "white" }}
                            onClick={() => this.props.handlePopupClickClose()}
                        >
                            <Close color="white" />
                        </IconButton>
                    </Tooltip>
                </Toolbar>
                <DialogContent>
                    <div>
                        <Typography variant="title" style={{ fontWeight: "bold", paddingBottom: "10px" }}>
                            Update Field Name
                        </Typography>
                        <TextField
                            variant="standard"
                            label="Field Name"
                            margin="dense"
                            value={this.state.fieldName}
                            onChange={this.handleTextChange}
                        />
                    </div>
                </DialogContent>

                <DialogActions style={{ justifyContent: "center", paddingTop: "8px", marginBottom: "20px" }}>
                    <Button style={cancelButtonStyle} onClick={() => this.handleClose()}>
                        Close
                    </Button>
                    <Button style={confirmButtonStyle} onClick={() => this.handleSave()}>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

FieldNameDialog = connect(
    (state) => ({
        authState: state.auth,
    }),
    {
        ...Field.actions,
    },
)(FieldNameDialog);

export default FieldNameDialog;
