import React from "react";
import { Box, Card, CardContent, Button, Typography } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import DateComponent from "../common/DateComponent";

const ColoredLine = ({ color }) => (
    <hr
        style={{
            color: color,
            backgroundColor: color,
            height: 1,
            marginLeft: "14px",
            marginRight: "14px",
        }}
    />
);

class PlatformUpdates extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            viewAllPast: false,
            page: 1,
            itemsPerPage: 2,
        };
    }

    handleViewAll = () => {
        this.setState({ viewAllPast: true });
    };

    handleHide = () => {
        this.setState({ viewAllPast: false, page: 1 });
    };

    handlePageChange = (event, value) => {
        this.setState({ page: value });
    };

    render() {
        const { announcements } = this.props;
        const { viewAllPast, page, itemsPerPage } = this.state;

        const futureAnnouncements = announcements.filter(
            (announcement) => new Date(announcement.createdate) > new Date(),
        );
        const pastAnnouncements = announcements.filter(
            (announcement) => new Date(announcement.createdate) <= new Date(),
        );

        const itemsToShow = viewAllPast ? pastAnnouncements : pastAnnouncements.slice(0, itemsPerPage);

        const pageCount = Math.ceil(pastAnnouncements.length / itemsPerPage);

        return (
            <Card>
                <Typography style={{ fontWeight: "bold", paddingLeft: "14px", paddingTop: "10px" }}>
                    Pending Releases
                </Typography>
                {futureAnnouncements.map((announcement, index) => (
                    <CardContent key={index} style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                        <Typography style={{ fontWeight: "600", fontSize: "12px" }}>{announcement.label}</Typography>
                        <Typography style={{ fontSize: "small", marginBottom: "10px" }}>
                            <DateComponent date={announcement.createdate} />
                        </Typography>
                        <div
                            style={{ fontWeight: "200", fontSize: "small" }}
                            dangerouslySetInnerHTML={{ __html: announcement.content }}
                        />
                    </CardContent>
                ))}
                <ColoredLine color="#666666" />
                <Typography style={{ fontWeight: "bold", paddingLeft: "14px" }}>Recent Releases</Typography>
                {itemsToShow.slice((page - 1) * itemsPerPage, page * itemsPerPage).map((announcement, index) => (
                    <CardContent key={index} style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                        <Typography style={{ fontWeight: "600", fontSize: "12px" }}>{announcement.label}</Typography>
                        <Typography style={{ fontSize: "small", marginBottom: "10px" }}>
                            <DateComponent date={announcement.createdate} />
                        </Typography>
                        <div
                            style={{ fontWeight: "200", fontSize: "small", marginBottom: "10px" }}
                            dangerouslySetInnerHTML={{ __html: announcement.content }}
                        />
                    </CardContent>
                ))}
                {!viewAllPast && (
                    <Button style={{ fontSize: "14px" }} onClick={this.handleViewAll}>
                        View All
                    </Button>
                )}
                {viewAllPast && (
                    <Box>
                        <Pagination count={pageCount} page={page} onChange={this.handlePageChange} />
                        <Button style={{ fontSize: "14px", fontWeight: "300" }} onClick={this.handleHide}>
                            Hide
                        </Button>
                    </Box>
                )}
            </Card>
        );
    }
}

export default PlatformUpdates;
