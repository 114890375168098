import React, { useMemo } from "react";
import MapView from "../map/MapView";
import { ZoomTools, ExploreTools } from "../map/Tools";
import SoilsLayer from "../map/layers/SoilsLayer";
import GeoJSONLayer from "../map/layers/GeoJSONLayer";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Button from "../common/ButtonWrapper";
import Typography from "../common/TypographyWrapper";
import { Field } from "../field/models";
import { navSelectPage } from "../common/actions";
import { Box, Divider } from "@mui/material";
import ZoomFit from "mdi-material-ui/FitToPage";
import LandFields from "mdi-material-ui/LandFields";

const { ormFieldLoadDetail } = Field.actions;

const TOOLBARS = [
        {
            id: "zoom-tools",
            component: ZoomTools,
            left: true,
        },
        {
            id: "explore-tools",
            component: ExploreTools,
        },
    ],
    LAYERS = [
        { id: "imagery", title: "ESRI Imagery" },
        { id: "soils", title: "SSURGO Soils", component: SoilsLayer },
    ];

const CROP_COLORS = {
    Alfalfa: "e6194b",
    Barley: "3cb44b",
    "Corn (grain)": "ffe119",
    "Corn (silage)": "0082c8",
    "Chickpeas (garbanzos)": "ffff88",
    Cotton: "f58231",
    "Dry Beans": "aa0000",
    "Dry Peas": "00ff66",
    "Fava Beans": "ff2200",
    Lentils: "6666cc",
    Lupin: "66cc66",
    Peanuts: "911eb4",
    Potatoes: "46f0f0",
    Rice: "f032e6",
    Sorghum: "d2f53c",
    Soybeans: "fabebe",
    "Sugar beets": "008080",
    "Wheat (durum)": "e6beff",
    "Wheat (spring)": "aa6e28",
    "Wheat (winter)": "fffac8",
    "None/Other": "333333",
};

const POINT_CIRCLE_STYLE = {
        id: "field_points",
        type: "circle",
        paint: {
            "circle-color": ["match", ["get", "last_crop"]],
            "circle-radius": 6,
            "circle-stroke-width": 2,
            "circle-stroke-color": "black",
            "circle-opacity": ["step", ["zoom"], 1, 12, ["case", ["to-boolean", ["get", "geometry"]], 0, 1]],
            "circle-stroke-opacity": ["step", ["zoom"], 1, 12, ["case", ["to-boolean", ["get", "geometry"]], 0, 1]],
        },
    },
    POLYGON_FILL_STYLE = {
        id: "field_polygons",
        type: "fill",
        paint: {
            "fill-color": ["match", ["get", "last_crop"]],
            "fill-outline-color": ["match", ["get", "last_crop"]],
        },
    };

for (const [crop_name, color] of Object.entries(CROP_COLORS)) {
    POINT_CIRCLE_STYLE.paint["circle-color"].push(crop_name, `#${color}`);
    POLYGON_FILL_STYLE.paint["fill-color"].push(crop_name, `#${color}66`);
    POLYGON_FILL_STYLE.paint["fill-outline-color"].push(crop_name, `#${color}`);
}
POINT_CIRCLE_STYLE.paint["circle-color"].push(`#${CROP_COLORS["None/Other"]}`);
POLYGON_FILL_STYLE.paint["fill-color"].push(`#${CROP_COLORS["None/Other"]}99`);
POLYGON_FILL_STYLE.paint["fill-outline-color"].push(`#${CROP_COLORS["None/Other"]}`);

export function FieldLayer({ id, data, style, zoomTo }) {
    const dispatch = useDispatch();

    function zoomToField(field) {
        if (field.geometry) {
            const geometry = typeof field.geometry === "string" ? JSON.parse(field.geometry) : field.geometry;
            zoomTo(geometry);
            return;
        }
        dispatch(
            ormFieldLoadDetail(field.id, function (field) {
                // Zoom to field after load
                if (field.geometry) {
                    setTimeout(() => {
                        zoomTo(field.geometry);
                    }, 100);
                }
            }),
        );
    }

    return (
        <GeoJSONLayer
            id={id}
            data={data}
            style={style}
            renderPopup={(feature, multiple) => {
                const field = feature.properties,
                    acres = (field.size || "").split(" ")[0];
                return (
                    <>
                        <Typography>
                            <b>Farm</b>: {field.farm_name} <br />
                            <b>Field</b>: {field.name} <br />
                            <b>Acres</b>: {acres} <br />
                            <b>Last Crop</b>: {field.last_crop || "N/A"}
                        </Typography>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <Button
                                size="small"
                                variant="raised"
                                color="primary"
                                style={{ marginRight: 8 }}
                                startIcon={<ZoomFit />}
                                onClick={() => zoomToField(field)}
                            >
                                Zoom To Field
                            </Button>
                            <Button
                                size="small"
                                variant="raised"
                                color="primary"
                                startIcon={<LandFields />}
                                onClick={() => dispatch(navSelectPage("field_" + field.id))}
                                component={Link}
                                to={"/field/" + field.id}
                            >
                                Field Dashboard
                            </Button>
                        </div>
                        {multiple && (
                            <>
                                <br />
                                <Divider />
                                <br />
                            </>
                        )}
                    </>
                );
            }}
        />
    );
}

export default function DashboardMap({ fields, allCrops }) {
    const [layers, points, allFeatures, cropCounts] = useMemo(() => {
        const points = {
                type: "FeatureCollection",
                features: fields
                    .filter((field) => Boolean(field.center))
                    .map((field) => ({
                        type: "Feature",
                        id: field.id,
                        properties: field,
                        geometry: {
                            type: "Point",
                            coordinates: [field.center.lng, field.center.lat],
                        },
                    })),
            },
            polygons = {
                ...points,
                features: points.features
                    .filter((feature) => Boolean(feature.properties.geometry))
                    .map((feature) => ({ ...feature, geometry: feature.properties.geometry })),
            },
            allFeatures = {
                ...points,
                features: points.features.concat(polygons.features),
            },
            layers = LAYERS.concat([
                {
                    id: "field_polygons",
                    component: ({ zoomTo }) => (
                        <FieldLayer id="field_polygons" data={polygons} style={POLYGON_FILL_STYLE} zoomTo={zoomTo} />
                    ),
                },
                {
                    id: "field_points",
                    component: ({ zoomTo }) => (
                        <FieldLayer id="field_points" data={points} style={POINT_CIRCLE_STYLE} zoomTo={zoomTo} />
                    ),
                },
            ]),
            cropCounts = {};
        for (const field of fields) {
            const lastCrop = field.last_crop || "None/Other";
            cropCounts[lastCrop] = (cropCounts[lastCrop] || 0) + 1;
        }
        return [layers, points, allFeatures, cropCounts];
    }, [fields]);
    return (
        <div style={{ display: "flex", height: 600 }}>
            <MapView
                initialFitData={points}
                fitData={allFeatures}
                layers={layers}
                toolbars={TOOLBARS}
                isToolVisible={(tool) => tool.id !== "help"}
            >
                <CropLegend allCrops={allCrops} cropCounts={cropCounts} />
            </MapView>
        </div>
    );
}

function CropLegend({ allCrops, cropCounts }) {
    const hasCounts = Object.values(cropCounts).some(Boolean);
    return (
        <Box
            sx={{
                backgroundColor: "rgba(255, 255, 255, .70)",
                br: 1,
                m: 1,
                p: 1,
                zIndex: 1000,
                position: "absolute",
                bottom: 0,
            }}
        >
            <Typography>{hasCounts ? <u>Last Grown Crop</u> : "No fields match the given filters."}</Typography>
            {allCrops
                .concat([{ id: "-1", name: "None/Other" }])
                .filter((crop) => cropCounts[crop.name] > 0)
                .map((crop) => {
                    return (
                        <div key={crop.id}>
                            <Typography variant="caption">
                                <LegendIcon color={CROP_COLORS[crop.name]} />
                                {crop.name}
                            </Typography>
                        </div>
                    );
                })}
        </Box>
    );
}

export function LegendIcon({ color, noMargin }) {
    const margins = noMargin
        ? {}
        : {
              marginRight: 4,
              marginTop: 2,
              marginBottom: 2,
          };
    return (
        <span
            style={{
                display: "inline-block",
                verticalAlign: "middle",
                width: 12,
                height: 12,
                borderRadius: 8,
                border: "2px solid black",
                backgroundColor: `#${color}`,
                ...margins,
            }}
        />
    );
}
