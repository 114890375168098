import React, { Component } from "react";
import withStyles from "@mui/styles/withStyles";
import { getDescription } from "../StaticData";
import { Caption } from "../Table";

// Custom CSS classes
const styles = (theme) => ({
    footer: {
        width: "7.5in",
        position: "absolute",
        bottom: 0,
        left: 0,
        paddingBottom: "1.5em",
        paddingLeft: "0.5in",
        paddingRight: "0.5in",
        color: "rgba(0, 0, 0, 0.54)",
    },
    horizontalView: {
        display: "flex",
    },
    spacer: {
        flex: 1,
    },
});

class PageFooter extends Component {
    render() {
        const { classes, page, pages } = this.props,
            year = new Date().getFullYear();

        return (
            <div className={classes.footer}>
                {page && page === pages && <Caption>{getDescription("disclaimer")}</Caption>}
                <div className={classes.horizontalView}>
                    <Caption>Copyright &copy; {year} Field to Market. All Rights Reserved.</Caption>
                    <div className={classes.spacer} />
                    <Caption>
                        Page {page} of {pages}
                    </Caption>
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(PageFooter);
