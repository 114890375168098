// FIXME: This file does not contain any components, and is primarily for
// integrating with the API.  It should probably be moved to src/api/orm.js

import { ORM, Model as ORMModel, ForeignKey, createSelector as ormSelector } from "redux-orm";
import { dbFetch, dbEffect, apiFetch } from "../../api/fetch";
import {
    AUTH_AUTOLOGIN_SUCCESS,
    AUTH_AUTOLOGIN_ERROR,
    AUTH_LOGOUT,
    AUTH_START_IMPERSONATE,
    AUTH_STOP_IMPERSONATE,
    AUTH_REVALIDATE_ERROR,
} from "../auth/actions";
import REFERENCE_DATA from "../../reference_data.json";
const REPORT_MODE = window.location.href.match("mode=report");

const orm = new ORM({ stateSelector: (state) => state.orm });
export default orm;

export function createSelector(...args) {
    return ormSelector(orm, ...args);
}

export function selectAll(cls, serialize = (obj) => obj.ref) {
    return createSelector((schema) => schema[cls.modelName].all().toModelArray().map(serialize));
}

export function selectByUrlId(cls, serialize = (obj) => obj.ref, param = "id") {
    return createSelector(
        (state, ownProps) => ownProps.match.params[param],
        (schema, objId) => {
            cls = schema[cls.modelName];
            if (cls.hasId(objId)) {
                var obj = cls.withId(objId);
                return serialize(obj);
            } else {
                // FIXME: Render a 404 page?
                return { name: "Not Found" };
                //return null;
            }
        },
    );
}

function isRealUser(authState) {
    return authState && authState.user && !authState.user.guest;
}

export class BaseModel extends ORMModel {
    static selectAll(serializer) {
        return selectAll(this, serializer);
    }

    static selectByUrlId(serializer, param = "id") {
        return selectByUrlId(this, serializer, param);
    }

    static get source() {
        return null;
    }

    static get isUserData() {
        return null;
    }

    static get isStaticData() {
        return null;
    }

    static get actions() {
        const functionPrefix = `orm${this.modelName}`,
            typePrefix = `ORM_${this.modelName.toUpperCase()}`;
        return {
            [`${functionPrefix}Reload`]: (alreadyPulling) => {
                var cls = this;
                return function (dispatch, getState) {
                    if (cls.isStaticData || REPORT_MODE) {
                        dispatch({
                            type: `${typePrefix}_STATICDATA`,
                            payload: REFERENCE_DATA[cls.modelName] || [],
                        });
                        return;
                    }

                    if (!alreadyPulling) {
                        dispatch({
                            type: `${typePrefix}_PULLING`,
                        });
                    }

                    const state = getState();

                    let user, fn;
                    if (cls.isUserData) {
                        if (!isRealUser(state.auth)) {
                            return;
                        }
                        fn = dbFetch;
                        user = state.auth.user;
                    } else if (cls.isStaticData) {
                        fn = apiFetch;
                    } else {
                        fn = dbFetch;
                    }
                    fn(`${cls.source}?format=json&t=${Date.now()}`)
                        .then((result) => result.json())
                        .then((data) => {
                            if (data.list) {
                                data = data.list;
                            }
                            if (!(data instanceof Array)) {
                                throw new Error(data.detail || data);
                            }
                            return data;
                        })
                        .then((data) => {
                            const latestState = getState();
                            if (user && (!isRealUser(latestState.auth) || latestState.auth.user.email !== user.email)) {
                                throw new Error("Auth state changed during fetch");
                            }
                            dispatch({
                                type: `${typePrefix}_PULLED`,
                                payload: data,
                            });
                        })
                        .catch((e) => {
                            if (e.message === "Invalid token.") {
                                dispatch({
                                    type: AUTH_LOGOUT,
                                });
                            }
                            dispatch({
                                type: `${typePrefix}_PULLERROR`,
                                error: e,
                            });
                        });
                };
            },
        };
    }

    static fromResponse(data) {
        return data;
    }

    static reducer(action, cls) {
        const prefix = `ORM_${cls.modelName.toUpperCase()}`;
        switch (action.type) {
            case `${prefix}_PULLED`:
            case `${prefix}_STATICDATA`:
                const ids = action.payload.map((obj) => obj.id);
                cls.exclude((obj) => ids.includes(obj.id)).delete();
                const checkSynced = (obj) => {
                    const exist = cls.withId(obj.id);
                    if (exist && exist.synced) {
                        // FIXME: leave as synced if matching modify_date/hash
                        return { ...obj, synced: false };
                    } else {
                        return obj;
                    }
                };
                action.payload.forEach((obj) => cls.upsert(checkSynced(cls.fromResponse(obj))));
                break;
            default:
                break;
        }
    }
}

export class ReferenceData extends BaseModel {
    static get fields() {
        return {};
    }
    static get source() {
        throw new Error("Static data");
        // return `/v4/ReferenceData/${this.modelName}`;
    }
    static get isUserData() {
        return false;
    }
    static get isStaticData() {
        return true;
    }
}

export class CustomModel extends BaseModel {
    static get fields() {
        return {};
    }
    static get source() {
        throw new Error("Not implemented");
    }
    static get isUserData() {
        return false;
    }
    static get isStaticData() {
        return false;
    }
}

export class Model extends BaseModel {
    static get source() {
        return `/api/db/${this.pluralName}`;
    }

    static get pluralName() {
        return `${this.modelName.toLowerCase()}s`;
    }

    static get isUserData() {
        return true;
    }

    static createAction({ type, payload, effectIfLoggedIn, generateId }) {
        this.checkFks(payload);
        return (dispatch, getState) => {
            const state = getState();
            const { auth } = state;
            if (generateId) {
                if (!payload) {
                    payload = {};
                }
                let code = this.generateCode(payload, state);
                if (code) {
                    payload.code = code;
                }
                payload.id = this.generateId(payload);
            }
            let action = {
                type: type,
                payload: payload,
            };
            if (isRealUser(auth)) {
                action.meta = {
                    offline: {
                        effect: dbEffect({
                            ...effectIfLoggedIn,
                            body: JSON.stringify(this.toRequest(payload)),
                        }),
                        commit: {
                            type: `${type}_PUSHED`,
                        },
                        rollback: {
                            type: `${type}_PUSHERROR`,
                            meta: { objectId: payload.id },
                        },
                    },
                };
            }
            dispatch(action);
            if (generateId) {
                return payload.id;
            }
        };
    }

    static fail(message) {
        message = `FPP ORM Error: ${message}`;
        console.error(message);
        throw new Error(message);
    }

    static _getForeignKeyInfo(payload, required = true) {
        const payloadName = `orm${this.modelName}Create payload`;

        if (!payload) {
            if (required) {
                this.fail(`Missing ${payloadName}`);
            } else {
                return [null, null];
            }
        }

        // Find foreign key field(s)
        const fks = Object.entries(this.fields).filter(([, field]) => field instanceof ForeignKey);

        if (fks.length < 1) {
            if (required) {
                this.fail(`No foreign key on ${this.modelName}`);
            } else {
                return [null, null];
            }
        } else if (fks.length > 1) {
            if (required) {
                this.fail(`Multiple foreign keys on ${this.modelName}`);
            } else {
                return [null, null];
            }
        }

        const [fkname] = fks[0],
            fkvalue = payload[fkname];

        if (!fkvalue && required) {
            this.fail(`Expected ${fkname} in ${payloadName}`);
        }
        return [fkname, fkvalue];
    }

    static generateCode(payload, state) {
        const [fkname, fkvalue] = this._getForeignKeyInfo(payload, true),
            session = orm.session(state.orm);

        let maxCode = 0;
        session[this.modelName]
            .filter({
                [fkname]: fkvalue,
            })
            .toRefArray()
            .forEach((row) => {
                const lastCode = +row.code;
                if (lastCode > maxCode) {
                    maxCode = lastCode;
                }
            });
        let nextCode = `${maxCode + 1}`;
        while (nextCode.length < this.codeWidth) {
            nextCode = `0${nextCode}`;
        }

        return nextCode;
    }

    static generateId(payload) {
        const payloadName = `orm${this.modelName}Create payload`,
            [, fkvalue] = this._getForeignKeyInfo(payload, false);

        if (payload.id) {
            this.fail(`Unexpected id in ${payloadName}`);
        }

        if (fkvalue) {
            return `${fkvalue}-${payload.code}`;
        } else {
            return payload.code;
        }
    }

    static get codeWidth() {
        return 0;
    }

    static checkFks(payload) {
        try {
            this._checkFks(payload);
        } catch (e) {
            console.error(e);
        }
    }

    static _checkFks(payload) {
        const fks = Object.entries(this.fields).filter(([, field]) => field instanceof ForeignKey);
        fks.forEach(([key, field]) => {
            if (`${key}_id` in payload) {
                console.warn(
                    `Specify foreign key as "${key}" rather than "${key}_id" when calling orm${this.modelName}*()`,
                );
            }
        });
    }

    static toRequest(data) {
        var req = {};
        Object.entries(data).forEach(([key, value]) => {
            if (this.fields[key] instanceof ForeignKey) {
                req[key + "_id"] = value;
            } else if (!(key in req)) {
                req[key] = value;
            }
        });
        return req;
    }

    static fromResponse(data) {
        Object.keys(data).forEach((key) => {
            const field = key.replace(/_id$/, "");
            if (field !== key && this.fields[field] instanceof ForeignKey) {
                data[field] = data[key];
                delete data[key];
            }
        });
        return data;
    }

    static get actions() {
        const functionPrefix = `orm${this.modelName}`,
            typePrefix = `ORM_${this.modelName.toUpperCase()}`,
            baseActions = super.actions;

        return {
            [`${functionPrefix}Create`]: (payload) =>
                this.createAction({
                    type: `${typePrefix}_CREATE`,
                    payload: payload,
                    generateId: true,
                    effectIfLoggedIn: {
                        url: `${this.source}?format=json&t=${Date.now()}`,
                        method: "POST",
                    },
                }),
            [`${functionPrefix}CreateLocalOnly`]: (payload) =>
                this.createAction({
                    type: `${typePrefix}_CREATE`,
                    payload: payload,
                    generateId: false,
                }),
            [`${functionPrefix}CreateRemoteFirst`]: (payload) => {
                var cls = this;
                this.checkFks(payload);
                return function (dispatch, getState) {
                    const state = getState(),
                        { auth } = state;
                    if (!isRealUser(auth)) {
                        dispatch({
                            type: `${typePrefix}_CREATE_PUSHERROR`,
                            error: new Error("Not logged in."),
                        });
                        return;
                    }
                    dispatch({
                        type: `${typePrefix}_CREATE_PUSHING`,
                    });
                    let options = {
                        method: "POST",
                        body: JSON.stringify(cls.toRequest(payload)),
                    };
                    return dbFetch(`${cls.source}?format=json&t=${Date.now()}`, options)
                        .then((result) => {
                            if (!result.ok) {
                                return result.text().then((text) => {
                                    throw new Error(text);
                                });
                            } else {
                                return result.json();
                            }
                        })
                        .then((data) => {
                            dispatch({
                                type: `${typePrefix}_CREATE`,
                                payload: data,
                            });
                            dispatch({
                                type: `${typePrefix}_CREATE_PUSHED`,
                                payload: data,
                            });
                            return data.id;
                        })
                        .catch((e) =>
                            dispatch({
                                type: `${typePrefix}_CREATE_PUSHERROR`,
                                error: e,
                            }),
                        );
                };
            },
            [`${functionPrefix}LoadDetail`]: (id, callback) => {
                var cls = this;
                return function (dispatch, getState) {
                    const state = getState(),
                        { auth } = state;
                    if (!isRealUser(auth)) {
                        dispatch({
                            type: `${typePrefix}_DETAIL_ERROR`,
                            error: new Error("Not logged in."),
                        });
                        return;
                    }
                    dispatch({
                        type: `${typePrefix}_DETAIL_LOADING`,
                    });
                    dbFetch(`${cls.source}/${id}?format=json`)
                        .then((result) => result.json())
                        .then((data) => {
                            if (data.list) {
                                data = data.list;
                            }
                            // Data comes back as an object - needs to be an array?
                            if (!(data instanceof Object)) {
                                throw new Error(data.detail || data);
                            }
                            return data;
                        })
                        .then((data) => {
                            if (data.detail && data.detail === "Invalid token.") {
                                dispatch({
                                    type: AUTH_LOGOUT,
                                });
                            }
                            dispatch({
                                type: `${typePrefix}_DETAIL_LOADED`,
                                payload: data,
                            });
                            if (callback) callback(data);
                        })
                        .catch((e) => {
                            if (e.message === "Invalid token.") {
                                dispatch({
                                    type: AUTH_LOGOUT,
                                });
                            }
                            dispatch({
                                type: `${typePrefix}_DETAIL_ERROR`,
                                error: e,
                            });
                        });
                };
            },
            [`${functionPrefix}Update`]: (payload) =>
                this.createAction({
                    type: `${typePrefix}_UPDATE`,
                    payload: payload,
                    effectIfLoggedIn: {
                        url: `${this.source}/${payload.id}?t=${Date.now()}`,
                        method: "PATCH",
                    },
                }),
            [`${functionPrefix}UpdateLocalOnly`]: (payload) => {
                this.checkFks(payload);
                return {
                    type: `${typePrefix}_UPDATE`,
                    payload: payload,
                };
            },
            [`${functionPrefix}LogError`]: (payload) =>
                this.createAction({
                    type: `${typePrefix}_LOG_ERROR`,
                    payload: payload,
                    effectIfLoggedIn: {
                        url: `/logs/metrics`,
                        method: "POST",
                    },
                }),
            [`${functionPrefix}Delete`]: (objId) =>
                this.createAction({
                    type: `${typePrefix}_DELETE`,
                    payload: { id: objId },
                    effectIfLoggedIn: {
                        url: `${this.source}/${objId}?&t=${Date.now()}`,
                        method: "DELETE",
                    },
                }),
            ...baseActions,
        };
    }

    static reducer(action, cls) {
        const prefix = `ORM_${cls.modelName.toUpperCase()}`,
            errorPattern = new RegExp(`^${prefix}_([^_]+)_PUSHERROR$`),
            { payload, meta } = action,
            objId = (payload && payload.id) || (meta && meta.objectId);
        switch (action.type) {
            case AUTH_AUTOLOGIN_SUCCESS:
            case AUTH_AUTOLOGIN_ERROR:
            case AUTH_LOGOUT:
            case AUTH_START_IMPERSONATE:
            case AUTH_STOP_IMPERSONATE:
            case AUTH_REVALIDATE_ERROR:
                cls.all().delete();
                break;
            case `${prefix}_CREATE`:
                cls.create(payload || {});
                break;
            case `${prefix}_UPDATE`:
                if (!cls.hasId(objId)) {
                    break;
                }
                cls.withId(objId).update(action.payload);
                break;
            case `${prefix}_CREATE_PUSHED`:
            case `${prefix}_UPDATE_PUSHED`:
                if (!cls.hasId(objId)) {
                    break;
                }
                cls.withId(objId).update(cls.fromResponse(action.payload));
                break;
            case `${prefix}_DETAIL_LOADED`:
                if (!cls.hasId(objId)) {
                    break;
                }
                action.payload.synced = true;
                cls.withId(objId).update(cls.fromResponse(action.payload));
                break;
            case `${prefix}_CREATE_PUSHERROR`:
            case `${prefix}_UPDATE_PUSHERROR`:
            case `${prefix}_DETAIL_ERROR`:
                if (!cls.hasId(objId)) {
                    break;
                }
                if (payload.response && payload.response.detail && payload.response.detail === "Invalid token.") {
                    //FIXME: How would you log out when you can't dispatch in a reducer?
                    // Redux-offline completely handles the request so you don't know prior to the reducer if invalid token is causing rollback
                    //action.meta.dispatch({type: AUTH_LOGOUT});
                }
                cls.withId(objId).update({
                    serverError: payload.response || payload.status,
                });
                break;
            case `${prefix}_DELETE`:
                if (!cls.hasId(objId)) {
                    break;
                }
                cls.withId(objId).delete();
                break;
            default:
                if (action.type.match(errorPattern)) {
                    console.warn(action);
                } else {
                    super.reducer(action, cls);
                }
        }
    }

    _onDelete() {
        const virtualFields = this.getClass().virtualFields;
        for (const key in virtualFields) {
            // eslint-disable-line
            if (this[key] !== null) {
                const relatedQs = this[key];
                if (relatedQs.exists()) {
                    relatedQs.delete();
                }
            }
        }
    }
}

// reloadAll is called in a variety of places - most noticeably if a user first hits the main pages this is called, and then called again when they log in.
// This creates a backload of requests which can take 10-20 or more seconds to clear out.
// To help alleviate this only call every model once during the initial page load in index.js.
// But everywhere else we don't need to reload the ReadOnlyModels (!isUserData) since they never change.
// This should cut down the number of requests to the server.
export function reloadAll(reloadLookups) {
    return function (dispatch, getState) {
        const state = getState(),
            type = isRealUser(state.auth) ? "user" : "guest";

        orm.registry.forEach((model) => {
            if (model.isUserData) {
                dispatch({
                    type: `ORM_${model.modelName.toUpperCase()}_PULLING`,
                });
            }
        });

        const getInitData = REPORT_MODE
            ? Promise.resolve({ account: "GUEST" })
            : dbFetch(`/api/db/init/${type}`).then((result) => result.json());

        getInitData.then((initData) => {
            const latestState = getState();
            if (isRealUser(latestState.auth)) {
                const { user } = latestState.auth,
                    checkAccount = user._impersonate ? user._impersonateEmail : user.email;
                if (initData.account !== checkAccount) {
                    throw new Error("Auth state changed during fetch");
                }
            } else {
                if (initData.account !== "GUEST") {
                    throw new Error("Auth state changed during fetch");
                }
            }
            orm.registry.forEach((model) => {
                if (!model.isUserData) {
                    return;
                }

                const key = (model.djangoModelName || model.modelName).toLowerCase();

                if (initData[key]) {
                    dispatch({
                        type: `ORM_${model.modelName.toUpperCase()}_PULLED`,
                        payload: initData[key],
                    });
                } else {
                    const fn = model.actions[`orm${model.modelName}Reload`];
                    dispatch(fn(true));
                }
            });
        });

        if (reloadLookups) {
            orm.registry.forEach((model) => {
                if (!model.isUserData) {
                    const fn = model.actions[`orm${model.modelName}Reload`];
                    dispatch(fn());
                }
            });
        }
    };
}

export function syncReducer(state = {}, action) {
    let { type } = action;
    if (action.meta && action.meta.offline) {
        type = `${type}_PUSHING`;
    }
    const pushPattern = /^ORM_([^_]+)_([^_]+)_(PUSH[^_]+)$/;
    const pullPattern = /^ORM_([^_]+)_((PULL[^_]+))$/;
    const detailPattern = /^ORM_([^_]+)_(DETAIL)_([^_]+)$/;
    var match = type.match(pushPattern) || type.match(pullPattern) || type.match(detailPattern);
    if (!match) {
        return state;
    }

    let pending = {},
        error = {};
    let [, modelName, actionName, statusName] = match;
    let total = 0;
    orm.registry.forEach((model) => {
        if (!model.isUserData) {
            return;
        }
        total += 1;
        if (model.modelName.toUpperCase() === modelName) {
            modelName = model.modelName;
        } else if (state.error && state.error[model.modelName]) {
            error[model.modelName] = state.error[model.modelName];
        } else if (state.pending && state.pending[model.modelName]) {
            pending[model.modelName] = state.pending[model.modelName];
        }
    });

    switch (statusName) {
        case "PUSHING":
        case "PULLING":
        case "LOADING":
            pending[modelName] = actionName;
            break;
        case "PUSHED":
        case "PULLED":
        case "LOADED":
            break;
        case "PUSHERROR":
        case "PULLERROR":
        case "ERROR":
            error[modelName] = actionName;
            break;
        default:
            break;
    }
    const pendingCount = Object.keys(pending).length;
    let progress, ready;
    if (pendingCount > 0) {
        progress = total - pendingCount;
        ready = false;
    } else {
        progress = total;
        ready = true;
    }
    return {
        ready,
        progress,
        total,
        pending,
        error,
    };
}
