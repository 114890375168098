import React, { Component } from "react";
import classNames from "classnames";
import ImageMap from "image-map";
import withRouter from "../common/withRouter";

import Grid from "../common/GridWrapper";
import Typography from "../common/TypographyWrapper";
import Paper from "@mui/material/Paper";

import withStyles from "@mui/styles/withStyles";

import AppContainer from "../common/AppContainer";
import CornerArrow from "../common/icons/CornerArrow";
import farmer1 from "./farmers/1.png";
import farmer2 from "./farmers/2.png";
import farmer3 from "./farmers/3.jpg";
import map from "./map.png";

const styles = (theme) => ({
    marginTopSmall: {
        marginTop: 0,
    },
    marginTop: {
        marginTop: 16,
    },
    paddingTop: {
        paddingTop: 16,
    },
    borderBottom: {
        borderBottom: "2px solid #efefef",
    },
    paddingBottom: {
        paddingBottom: 8,
    },
    paperMargin: {
        margin: "8px 16px",
        backgroundColor: "#eee",
    },
    paperMarginLeft: {
        marginLeft: 8,
    },
    paperPaddingTop: {
        paddingTop: 12,
        color: "#fff",
        fontWeight: "bold",
        display: "inline-block",
    },
    paperWidth: {
        width: "100%",
        cursor: "pointer",
    },
    iconSize: {
        float: "right",
        flexShrink: 0,
        width: "1em",
        height: "1em",
        fontSize: "24px",
    },
    hover: {
        filter: "brightness(100%)",
        cursor: "pointer",
        "&:hover": {
            filter: "brightness(70%)",
        },
    },
});

const farmers = [
    {
        name: "Tim Smith",
        generation: "4th Generation Farmer",
        location: "Eagle Grove, Iowa",
        crops: "Corn & Soy",
        profile: 1,
        color: "#02BDC0",
        image: farmer1,
    },
    {
        name: "Justin Knopf",
        generation: "5th Generation Farmer",
        location: "Gypsum, Kansas",
        crops: "Wheat",
        profile: 2,
        color: "#33A954",
        image: farmer2,
    },
    {
        name: "Glenn Schur",
        generation: "4th Generation Farmer",
        location: "Plainview, Texas",
        crops: "Sorghum & Cotton",
        profile: 3,
        color: "#FCBB3B",
        image: farmer3,
    },
];

class FarmerBox extends Component {
    state = {
        imageLoaded: false,
    };
    render() {
        const { imageLoaded } = this.state;
        const { classes, farmer, history } = this.props;

        return (
            <Paper className={classes.paperMargin} style={{ borderTop: "3px solid " + farmer.color }}>
                {!imageLoaded && (
                    <svg width="100%">
                        <rect width="100%" fill="#CCC" />
                    </svg>
                )}
                <img
                    className={classes.paperWidth}
                    onClick={() => history.push("/welcome/" + farmer.profile)}
                    style={!imageLoaded ? { visibility: "hidden" } : {}}
                    alt={farmer.name}
                    src={farmer.image}
                    onLoad={() => this.setState({ imageLoaded: true })}
                />
                <Typography className={classes.paperMarginLeft}>
                    <b>{farmer.name}</b>
                </Typography>
                <Typography className={classes.paperMarginLeft}>
                    <i>{farmer.generation}</i>
                </Typography>
                <Typography className={classes.paperMarginLeft}>
                    <i>{farmer.location}</i>
                </Typography>
                <Typography className={classes.paperMarginLeft}>
                    <i>{farmer.crops}</i>
                </Typography>
                <div
                    onClick={() => history.push("/welcome/" + farmer.profile)}
                    className={classNames(classes.hover)}
                    style={{ width: "100%", height: "48px", backgroundColor: farmer.color }}
                >
                    <Typography className={classNames(classes.paperPaddingTop, classes.paperMarginLeft)}>
                        View Profile
                    </Typography>
                    <CornerArrow className={classes.iconSize} />
                </div>
            </Paper>
        );
    }
}

class Welcome extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const { classes, history } = this.props;

        return (
            <AppContainer authenticated color="welcome" title="Shine a Light on Your Sustainability Story">
                <Grid container spacing={16}>
                    <Grid item xs={12} lg={7} className={classes.marginTop}>
                        <Typography gutterBottom>
                            Across the country, farmers like you harness the power of sustainability metrics from the
                            Fieldprint® Platform to engage the supply chain and consumers on their sustainability
                            story. Discover how this free and confidential tool can help you demonstrate the
                            conservation and stewardship efforts on your farm.
                        </Typography>
                        <Typography gutterBottom>
                            Learn how farmers throughout the United States use the Fieldprint Platform to document the
                            sustainability outcomes of their stewardship efforts, pursue continuous improvement and
                            partner with downstream customers in the value chain to advance sustainable agriculture.
                        </Typography>
                        <Typography className={classes.paddingTop} variant="title" gutterBottom>
                            Hear from Farmers Like You
                        </Typography>
                        <img
                            onLoad={() => ImageMap("img[usemap]")}
                            alt="Map"
                            src={map}
                            style={{ width: "100%" }}
                            usemap="#image-map"
                        />
                        <map name="image-map">
                            <area
                                alt="Iowa"
                                onClick={() => history.push("/welcome/1")}
                                coords="730,277,747,378,864,372,865,349,890,328,856,276"
                                shape="poly"
                            />
                            <area
                                alt="Kansas"
                                onClick={() => history.push("/welcome/2")}
                                coords="586,395,579,487,777,497,779,422,767,400"
                                shape="poly"
                            />
                            <area
                                alt="Texas"
                                onClick={() => history.push("/welcome/3")}
                                coords="435,658,540,666,552,514,632,514,634,585,671,597,731,613,766,605,784,618,791,645,807,691,807,738,743,782,689,825,697,875,648,862,593,756,513,760"
                                shape="poly"
                            />
                        </map>
                    </Grid>
                    <Grid item xs={12} lg={5} className={classes.marginTop}>
                        <Typography variant="title" className={classNames(classes.borderBottom, classes.paddingBottom)}>
                            All Farmers:
                        </Typography>
                        <Grid container spacing={16} className={classes.marginTopSmall}>
                            {farmers.map((f) => (
                                <Grid xs={6} md={4} lg={6}>
                                    <FarmerBox farmer={f} classes={classes} history={history} />
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </AppContainer>
        );
    }
}

export default withStyles(styles)(withRouter(Welcome));
