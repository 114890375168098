import React, { Component } from "react";
import { Form } from "react-form";
import { connect } from "react-redux";
import withRouter from "../../common/withRouter";

import Button from "../../common/ButtonWrapper";
import Grid from "../../common/GridWrapper";

import { FarmSurvey } from "../models";
import AppContainer from "../../common/AppContainer";
import HelpLabel from "../../common/HelpLabel";
import SubquestionIcon from "../../common/icons/Subquestion";
import TextField from "../../common/TextField";
import RadioGroup from "../../common/RadioGroup";
import { Typography } from "@mui/material";

const getFarmSurvey = FarmSurvey.selectByUrlId((survey) => ({
    farm_name: survey.farm.name,
    farm_id: survey.farm.id,
    ...survey.ref,
}));

class Survey extends Component {
    returnFarm = () => {
        const { history, farmSurvey } = this.props;
        history.push("/farm/" + farmSurvey.farm_id + "/edit");
    };

    calculateScore = (values) => {
        const questions = [
            { q: "q_1", essential: 19, basic: 9, advanced: 1 },
            { q: "q_2", essential: 2, basic: 0, advanced: 0 },
            { q: "q_3", essential: 0, basic: 2, advanced: 0 },
            { q: "q_4", essential: 0, basic: 4, advanced: 2 },
            { q: "q_5", essential: 0, basic: 4, advanced: 1 },
            { q: "q_6", essential: 0, basic: 1, advanced: 1 },
            { q: "q_7", essential: 0, basic: 1, advanced: 0 },
            { q: "q_8", essential: 0, basic: 4, advanced: 0 },
            { q: "q_9", essential: 1, basic: 0, advanced: 0 },
            { q: "q_10", essential: 0, basic: 2, advanced: 0 },
            { q: "q_11", essential: 0, basic: 5, advanced: 0 },
            { q: "q_12", essential: 0, basic: 0, advanced: 0 },
            { q: "q_13", essential: 0, basic: 2, advanced: 0 },
            { q: "q_14", essential: 3, basic: 6, advanced: 2 },
            { q: "q_15", essential: 0, basic: 0, advanced: 0 },
            { q: "q_16", essential: 0, basic: 0, advanced: 0 },
            { q: "q_17", essential: 0, basic: 0, advanced: 3 },
            { q: "q_18", essential: 0, basic: 3, advanced: 0 },
            { q: "q_19", essential: 0, basic: 0, advanced: 0 },
            { q: "q_20", essential: 2, basic: 1, advanced: 0 },
            { q: "q_21", essential: 1, basic: 0, advanced: 1 },
            { q: "q_22", essential: 0, basic: 1, advanced: 0 },
        ];
        var essential = 0,
            basic = 14,
            advanced = 9,
            total_essential = 35,
            total_basic = 77,
            total_advanced = 22;
        questions.forEach(function (question) {
            var val = values[question["q"]];
            if (val === "T") {
                essential = essential + question["essential"];
                basic = basic + question["basic"];
                advanced = advanced + question["advanced"];
            }
            if (val === "N") {
                total_essential = total_essential - question["essential"];
                total_basic = total_basic - question["basic"];
                total_advanced = total_advanced - question["advanced"];
            }
        });

        if (values["q_1"] === "T" && values["q_3"] === "T") {
            essential = essential + 1;
            basic = basic + 1;
        }
        if (values["q_1"] === "T" && values["q_7"] === "T") {
            essential = essential + 1;
        }
        if (values["q_7"] === "N") {
            total_essential = total_essential - 1;
        }
        if (values["q_1"] === "T" && values["q_8"] === "T") {
            basic = basic + 4;
        }
        if (values["q_8"] === "N") {
            total_basic = total_basic - 4;
        }
        if (values["q_1"] === "T" && values["q_9"] === "T") {
            basic = basic + 3;
        }
        if (values["q_9"] === "N") {
            total_basic = total_basic - 3;
        }
        if (values["q_1"] === "T" && values["q_11"] === "T") {
            essential = essential + 1;
            basic = basic + 1;
        }
        if (values["q_1"] === "T" && values["q_12"] === "T") {
            basic = basic + 1;
        }
        if (values["q_1"] === "T" && values["q_14"] === "T") {
            basic = basic + 1;
        }
        if (values["q_14"] === "N") {
            total_basic = total_basic - 1;
        }
        if (values["q_1"] === "T" && values["q_15"] === "T") {
            essential = essential + 1;
        }
        if (values["q_15"] === "N") {
            total_essential = total_essential - 1;
        }
        if (values["q_1"] === "T" && values["q_17"] === "T") {
            basic = basic + 2;
        }
        if (values["q_1"] === "T" && values["q_19"] === "T") {
            essential = essential + 1;
            basic = basic + 1;
        }
        if (values["q_19"] === "N") {
            total_essential = total_essential - 1;
            total_basic = total_basic - 1;
        }
        if (values["q_1"] === "T" && values["q_8"] === "T" && values["q_9"] === "T") {
            essential = essential + 1;
            basic = basic + 1;
        }
        if (values["q_8"] === "N" || values["q_9"] === "N") {
            total_essential = total_essential - 1;
            total_basic = total_basic - 1;
        }
        if (values["q_1"] === "T" && values["q_8"] === "T" && values["q_12"] === "T") {
            basic = basic + 1;
        }
        if (values["q_8"] === "N") {
            total_basic = total_basic - 1;
        }
        if (values["q_1"] === "T" && values["q_4"] === "T") {
            basic = basic + 1;
        }
        if (values["q_1"] === "T" && values["q_16"] === "T") {
            essential = essential + 1;
            basic = basic + 1;
        }
        if (values["q_16"] === "N") {
            total_essential = total_essential - 1;
            total_basic = total_basic - 1;
        }

        var fsa = "-";
        if (essential >= total_essential && basic >= total_basic && advanced >= total_advanced * 0.75) {
            fsa = "Gold";
        } else if (essential >= total_essential && basic >= total_basic * 0.75 && advanced >= total_advanced * 0.5) {
            fsa = "Silver";
        } else if (essential >= total_essential && basic >= total_basic * 0.5) {
            fsa = "Bronze";
        }

        return (
            <div
                style={{
                    position: "absolute",
                    bottom: 40,
                    right: 40,
                    backgroundColor: "#fafafa",
                    padding: 8,
                    zIndex: 999,
                }}
            >
                <Typography>Score Debug</Typography>
                <Typography>
                    Essential: {essential} / {total_essential} (
                    {Math.round((essential / total_essential) * 10000) / 100}%)
                </Typography>
                <Typography>
                    Intermediate: {basic} / {total_basic} ({Math.round((basic / total_basic) * 10000) / 100}%)
                </Typography>
                <Typography>
                    Advanced: {advanced} / {total_advanced} ({Math.round((advanced / total_advanced) * 10000) / 100}%)
                </Typography>
                <Typography>Performance: {fsa}</Typography>
            </div>
        );
    };

    updateSurvey = (values) => {
        const { ormFarmSurveyUpdate, farmSurvey } = this.props;

        const questions = [
            { q: "q_1", essential: 19, basic: 9, advanced: 1 },
            { q: "q_2", essential: 2, basic: 0, advanced: 0 },
            { q: "q_3", essential: 0, basic: 2, advanced: 0 },
            { q: "q_4", essential: 0, basic: 4, advanced: 2 },
            { q: "q_5", essential: 0, basic: 4, advanced: 1 },
            { q: "q_6", essential: 0, basic: 1, advanced: 1 },
            { q: "q_7", essential: 0, basic: 1, advanced: 0 },
            { q: "q_8", essential: 0, basic: 4, advanced: 0 },
            { q: "q_9", essential: 1, basic: 0, advanced: 0 },
            { q: "q_10", essential: 0, basic: 2, advanced: 0 },
            { q: "q_11", essential: 0, basic: 5, advanced: 0 },
            { q: "q_12", essential: 0, basic: 0, advanced: 0 },
            { q: "q_13", essential: 0, basic: 2, advanced: 0 },
            { q: "q_14", essential: 3, basic: 6, advanced: 2 },
            { q: "q_15", essential: 0, basic: 0, advanced: 0 },
            { q: "q_16", essential: 0, basic: 0, advanced: 0 },
            { q: "q_17", essential: 0, basic: 0, advanced: 3 },
            { q: "q_18", essential: 0, basic: 3, advanced: 0 },
            { q: "q_19", essential: 0, basic: 0, advanced: 0 },
            { q: "q_20", essential: 2, basic: 1, advanced: 0 },
            { q: "q_21", essential: 1, basic: 0, advanced: 1 },
            { q: "q_22", essential: 0, basic: 1, advanced: 0 },
        ];
        var essential = 0,
            basic = 14,
            advanced = 9,
            total_essential = 35,
            total_basic = 77,
            total_advanced = 22;
        var isComplete = true;
        questions.forEach(function (question) {
            var val = values[question["q"]];
            if (val === "T") {
                essential = essential + question["essential"];
                basic = basic + question["basic"];
                advanced = advanced + question["advanced"];
            }
            if (val === "N") {
                total_essential = total_essential - question["essential"];
                total_basic = total_basic - question["basic"];
                total_advanced = total_advanced - question["advanced"];
            }
            if (!val) isComplete = false;
        });

        if (values["q_1"] === "T" && values["q_3"] === "T") {
            essential = essential + 1;
            basic = basic + 1;
        }
        if (values["q_1"] === "T" && values["q_7"] === "T") {
            essential = essential + 1;
        }
        if (values["q_7"] === "N") {
            total_essential = total_essential - 1;
        }
        if (values["q_1"] === "T" && values["q_8"] === "T") {
            basic = basic + 4;
        }
        if (values["q_8"] === "N") {
            total_basic = total_basic - 4;
        }
        if (values["q_1"] === "T" && values["q_9"] === "T") {
            basic = basic + 3;
        }
        if (values["q_9"] === "N") {
            total_basic = total_basic - 3;
        }
        if (values["q_1"] === "T" && values["q_11"] === "T") {
            essential = essential + 1;
            basic = basic + 1;
        }
        if (values["q_1"] === "T" && values["q_12"] === "T") {
            basic = basic + 1;
        }
        if (values["q_1"] === "T" && values["q_14"] === "T") {
            basic = basic + 1;
        }
        if (values["q_14"] === "N") {
            total_basic = total_basic - 1;
        }
        if (values["q_1"] === "T" && values["q_15"] === "T") {
            essential = essential + 1;
        }
        if (values["q_15"] === "N") {
            total_essential = total_essential - 1;
        }
        if (values["q_1"] === "T" && values["q_17"] === "T") {
            basic = basic + 2;
        }
        if (values["q_1"] === "T" && values["q_19"] === "T") {
            essential = essential + 1;
            basic = basic + 1;
        }
        if (values["q_19"] === "N") {
            total_essential = total_essential - 1;
            total_basic = total_basic - 1;
        }
        if (values["q_1"] === "T" && values["q_8"] === "T" && values["q_9"] === "T") {
            essential = essential + 1;
            basic = basic + 1;
        }
        if (values["q_8"] === "N" || values["q_9"] === "N") {
            total_essential = total_essential - 1;
            total_basic = total_basic - 1;
        }
        if (values["q_1"] === "T" && values["q_8"] === "T" && values["q_12"] === "T") {
            basic = basic + 1;
        }
        if (values["q_8"] === "N") {
            total_basic = total_basic - 1;
        }
        if (values["q_1"] === "T" && values["q_4"] === "T") {
            basic = basic + 1;
        }
        if (values["q_1"] === "T" && values["q_16"] === "T") {
            essential = essential + 1;
            basic = basic + 1;
        }
        if (values["q_16"] === "N") {
            total_essential = total_essential - 1;
            total_basic = total_basic - 1;
        }

        var fsa = "";
        if (isComplete) {
            if (essential >= total_essential && basic >= total_basic && advanced >= total_advanced * 0.75) {
                fsa = "Gold";
            } else if (
                essential >= total_essential &&
                basic >= total_basic * 0.75 &&
                advanced >= total_advanced * 0.5
            ) {
                fsa = "Silver";
            } else if (essential >= total_essential && basic >= total_basic * 0.5) {
                fsa = "Bronze";
            }
            ormFarmSurveyUpdate({
                id: farmSurvey.id,
                ...values,
                status: true,
                fsa: fsa,
            });
        } else {
            ormFarmSurveyUpdate({
                id: farmSurvey.id,
                ...values,
                status: false,
                fsa: fsa,
            });
        }

        this.returnFarm();
    };

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const { farmSurvey } = this.props;
        const YES_NO_OPTIONS = [
            { label: "Yes", value: "T" },
            { label: "No", value: "F" },
            { label: "N/A", value: "N" },
        ];
        const YES_NO_OPTIONS_1 = [
            { label: "Yes", value: "T" },
            { label: "No", value: "F" },
        ];

        return (
            <AppContainer
                authenticated
                color="field"
                title={farmSurvey.year + " Questionnaire for " + farmSurvey.farm_name}
            >
                <Grid container spacing={24}>
                    <Form
                        key={farmSurvey.id}
                        dontValidateOnMount="true"
                        validateOnSubmit="true"
                        defaultValues={farmSurvey}
                        onSubmit={this.updateSurvey}
                    >
                        {(formApi) => (
                            <Grid item xs={12}>
                                <form onSubmit={formApi.submitForm}>
                                    {(window.location.hostname === "localhost" ||
                                        window.location.hostname === "build.fieldtomarket.org" ||
                                        window.location.hostname === "test.fieldtomarket.org") &&
                                        this.calculateScore(formApi.values)}
                                    <Grid container spacing={24}>
                                        <Grid item xs={12} lg={7}>
                                            <RadioGroup
                                                fullWidth
                                                field="q_1"
                                                name="q_1"
                                                options={YES_NO_OPTIONS_1}
                                                alignment={true}
                                                label={
                                                    <HelpLabel
                                                        inputLabel="1. To the best of your knowledge do you comply with all local, state and federal laws and regulations that are relevant for your farming operation?"
                                                        helpText={
                                                            <div>
                                                                Farmers should stay up to date in the areas of
                                                                agricultural production, workers' rights, health &
                                                                safety, environment, and food safety. This can be done
                                                                through specialized publications and news sources, by
                                                                farmer organizations, local governments or cooperatives.
                                                                <br />
                                                                <br />
                                                                Examples of laws and regulations include those related
                                                                to pesticide handling and application, employment and
                                                                recruitment practices, and occupational health and
                                                                safety. Regular review of relevant newsletters and
                                                                publications, agriculture trade association membership,
                                                                and support from qualified consultants can provide a
                                                                basis for updates on any changes to applicable laws and
                                                                regulations.
                                                            </div>
                                                        }
                                                    />
                                                }
                                            />
                                            <Grid container wrap="nowrap">
                                                <Grid xs={1} style={{ marginRight: 8 }}>
                                                    <SubquestionIcon />
                                                </Grid>
                                                <Grid xs={11}>
                                                    <TextField
                                                        field="q_1_comment"
                                                        label="Comments"
                                                        fullWidth
                                                        multiline
                                                        rows="4"
                                                        maxRows="20"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} lg={7}>
                                            <RadioGroup
                                                fullWidth
                                                field="q_2"
                                                name="q_2"
                                                options={YES_NO_OPTIONS_1}
                                                alignment={true}
                                                label={
                                                    <HelpLabel
                                                        inputLabel="2. Do you have documentation of land ownership or leases for your farmland?"
                                                        helpText={
                                                            <div>
                                                                Land rights can be defined as the rights of people (as
                                                                individuals and groups) to occupy, use, access, control,
                                                                and transfer land. Formal land rights are the rights to
                                                                occupy, use, access, control, and transfer land that are
                                                                derived from and given documented status under
                                                                constitutional and statutory law.
                                                                <br />
                                                                <br />
                                                                Be able to demonstrate land use title, or in its
                                                                absence, official confirmation from either the local
                                                                authority or customary leader that the land is being
                                                                legitimately managed by the farmer. Show that the farm:
                                                                <br />
                                                                1. Has a formal land title or contracts (maps,
                                                                contracts, etc.).
                                                                <br />
                                                                2. OR can explain rights to occupy, use, access,
                                                                control, and transfer land that are derived from and
                                                                sustained by the customary norms and practices specific
                                                                to a community, family, tribe, clan, or other social
                                                                collective.
                                                                <br />
                                                                3. Avoids impact on critical cultural heritage.
                                                            </div>
                                                        }
                                                    />
                                                }
                                            />
                                            <Grid container wrap="nowrap">
                                                <Grid xs={1} style={{ marginRight: 8 }}>
                                                    <SubquestionIcon />
                                                </Grid>
                                                <Grid xs={11}>
                                                    <TextField
                                                        field="q_2_comment"
                                                        label="Comments"
                                                        fullWidth
                                                        multiline
                                                        rows="4"
                                                        maxRows="20"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} lg={7}>
                                            <RadioGroup
                                                fullWidth
                                                field="q_3"
                                                name="q_3"
                                                options={YES_NO_OPTIONS_1}
                                                alignment={true}
                                                label={
                                                    <HelpLabel
                                                        inputLabel="3. Do you seek training and consultation, as needed, to address the following: seed and varietal selection, crop quality, and any other relevant supply chain specifications?"
                                                        helpText={
                                                            <div>
                                                                The farm has sought information about the varieties
                                                                available and is able to create a case for the selection
                                                                against other available varieties.
                                                                <br />
                                                                <br />
                                                                Training is considered to be one of the following
                                                                possibilities:
                                                                <br />- College level courses
                                                                <br />- Training provided by independent accredited
                                                                organisations
                                                                <br />- Crop advisor trainings related to IPM
                                                                <br />- Manuals or information from reputable sources
                                                                <br />
                                                                <br />
                                                                Varieties include seed and planting material as well as
                                                                grafting material.
                                                                <br />
                                                                <br />
                                                                An informed choice can take into account any of the
                                                                below:
                                                                <br />- Yield performance;
                                                                <br />- Disease resistance of the varieties (resistance
                                                                or tolerance to commercially important pests and
                                                                diseases);
                                                                <br />- Adaptation to local climatic and geographic
                                                                conditions;
                                                                <br />- Soil characteristics and crop rotation;
                                                                <br />- Customers' requirements;
                                                                <br />- Pest, disease and weed pressure;
                                                                <br />- Fertilization needs;
                                                                <br />- Water needs;
                                                                <br />- Genetic diversity on the farm;
                                                                <br />- Impacts on the adjacent cultivated area;
                                                                <br />- Recommendations by extension officers;
                                                                <br />- Results from variety trials.
                                                            </div>
                                                        }
                                                    />
                                                }
                                            />
                                            <Grid container wrap="nowrap">
                                                <Grid xs={1} style={{ marginRight: 8 }}>
                                                    <SubquestionIcon />
                                                </Grid>
                                                <Grid xs={11}>
                                                    <TextField
                                                        field="q_3_comment"
                                                        label="Comments"
                                                        fullWidth
                                                        multiline
                                                        rows="4"
                                                        maxRows="20"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} lg={7}>
                                            <RadioGroup
                                                fullWidth
                                                field="q_4"
                                                name="q_4"
                                                options={YES_NO_OPTIONS_1}
                                                alignment={true}
                                                label={
                                                    <HelpLabel
                                                        inputLabel="4. Do you use written records or a software system to manage information related to all inputs and outputs, associated costs and income for your farm operations, and sales receipts or contracts, if applicable?"
                                                        helpText={
                                                            <div>
                                                                Be able to demonstrate that records of yields, costs,
                                                                income and profitability are kept. Show that, as part of
                                                                an organised farm system, an inventory of farm fixed
                                                                assets and inputs is maintained and updated to help crop
                                                                and land management/development.
                                                                <br />
                                                                <br />
                                                                A farm inventory is a list of assets - anything of value
                                                                - that can be sold.
                                                                <br />
                                                                An inventory usually contains the following details:
                                                                <br />- Size of the farm
                                                                <br />- The right of ownership
                                                                <br />- Size of the land that is used for agriculture
                                                                <br />- A valuation of the production unit
                                                                <br />- Description and valuation of fixed improvements,
                                                                such as a new borehole, store or roof
                                                                <br />- Vehicles, machinery and equipment, as well as
                                                                numbers and types or models
                                                                <br />- Stocks, farm supplies and production inputs such
                                                                as seed, fertiliser and CPP
                                                                <br />
                                                                In separate columns of the inventory, indicate:
                                                                <br />- The year when each item of property was
                                                                purchased
                                                                <br />- The purchase price of each item
                                                                <br />- The expected lifetime of machinery/equipment
                                                            </div>
                                                        }
                                                    />
                                                }
                                            />
                                            <Grid container wrap="nowrap">
                                                <Grid xs={1} style={{ marginRight: 8 }}>
                                                    <SubquestionIcon />
                                                </Grid>
                                                <Grid xs={11}>
                                                    <TextField
                                                        field="q_4_comment"
                                                        label="Comments"
                                                        fullWidth
                                                        multiline
                                                        rows="4"
                                                        maxRows="20"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} lg={7}>
                                            <RadioGroup
                                                fullWidth
                                                field="q_5"
                                                name="q_5"
                                                options={YES_NO_OPTIONS}
                                                alignment={true}
                                                label={
                                                    <HelpLabel
                                                        inputLabel="5. Have you assessed impacts on the environment and community from your farming operation, and addressed any risks identified?"
                                                        helpText={
                                                            <div>
                                                                Have procedures in place, and be able to provide
                                                                evidence, to show the approaches taken on the farm to
                                                                minimise the impact on the community, ecosystems and
                                                                biodiversity.
                                                                <br />
                                                                <br />
                                                                The assessment addresses the following typical criteria,
                                                                based on current situation and potential impact caused
                                                                by change in land use management:
                                                                <br />- Air, water and soil pollution
                                                                <br />- Biodiversity, including HCV and HCS areas
                                                                <br />- Rare, threatened and endangered species
                                                                <br />- Land and natural resource use rights
                                                                <br />- Tenure and conflicts
                                                                <br />- Historical and archaelogical sites of interest
                                                                <br />- Other possible impacts on local communities
                                                            </div>
                                                        }
                                                    />
                                                }
                                            />
                                            <Grid container wrap="nowrap">
                                                <Grid xs={1} style={{ marginRight: 8 }}>
                                                    <SubquestionIcon />
                                                </Grid>
                                                <Grid xs={11}>
                                                    <TextField
                                                        field="q_5_comment"
                                                        label="Comments"
                                                        fullWidth
                                                        multiline
                                                        rows="4"
                                                        maxRows="20"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} lg={7}>
                                            <RadioGroup
                                                fullWidth
                                                field="q_6"
                                                name="q_6"
                                                options={YES_NO_OPTIONS}
                                                alignment={true}
                                                label={
                                                    <HelpLabel
                                                        inputLabel="6. If farming is your only source of income, do you have financial safeguards in place to minimize risk to crop losses, such as crop insurance, and to ensure the long-term viability of the operation?"
                                                        helpText={
                                                            <div>
                                                                Have evidence that the farm management is aware of risks
                                                                associated with crop production and measures taken to
                                                                optimise yields whilst taking into account safety,
                                                                quality, sustainable use of inputs, good agricultural
                                                                practices and costs. Be aware of risks to crop losses
                                                                associated with climatic or economic shock and adopt
                                                                measures to build in resilience to crop and financial
                                                                security.
                                                                <br />
                                                                <br />
                                                                Resilience concerns the ability to weather climatic,
                                                                environmental, market and social shocks, i.e. drop in
                                                                prices, especially commodity goods, extreme weather
                                                                events, social unrest, and major pest or disease
                                                                outbreak. Consider developing more than one income
                                                                stream, for example:
                                                                <br />- Crop diversity
                                                                <br />- Crop utilisation
                                                                <br />- Land and land use management
                                                                <br />- By-product valorisation
                                                                <br />- Product mix
                                                                <br />- On-farm value addition
                                                                <br />- Client portfolio
                                                                <br />- Non-farming activities
                                                            </div>
                                                        }
                                                    />
                                                }
                                            />
                                            <Grid container wrap="nowrap">
                                                <Grid xs={1} style={{ marginRight: 8 }}>
                                                    <SubquestionIcon />
                                                </Grid>
                                                <Grid xs={11}>
                                                    <TextField
                                                        field="q_6_comment"
                                                        label="Comments"
                                                        fullWidth
                                                        multiline
                                                        rows="4"
                                                        maxRows="20"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} lg={7}>
                                            <RadioGroup
                                                fullWidth
                                                field="q_7"
                                                name="q_7"
                                                options={YES_NO_OPTIONS}
                                                label={
                                                    <HelpLabel
                                                        inputLabel="7. If bioengineered crops are grown or handled, are appropriate measures (buffer zones, segregation) taken to prevent the risk of cross-contamination with other non-bio-engineered (genetically engineered) crops."
                                                        helpText={
                                                            <div>
                                                                The National Bioengineered Food Disclosure Standard
                                                                defines bioengineered foods (or crops) as those that
                                                                contain detectable genetic material that has been
                                                                modified through certain lab techniques and cannot be
                                                                created through conventional breeding or found in
                                                                nature.
                                                                <br />
                                                                <br />
                                                                Show that the farm has a clear understanding of:
                                                                <br />- the laws and regulations for growing GMOs in the
                                                                region of production
                                                                <br />- the customer's requirements and holds all of the
                                                                relevant permits and licences, including ensuring all
                                                                varieties planted are approved for cultivation.
                                                                <br />
                                                                Demonstrate that:
                                                                <br />- the farm keeps accurate records of all fields
                                                                used for growing GMOs
                                                                <br />- the farm communicates to direct clients about
                                                                the GMO status of the supplied products.
                                                                <br />
                                                                <br />
                                                                Have evidence that the farm maintains appropriate buffer
                                                                zones between conventional and GMO crops. Have evidence
                                                                of how seeds, produce and grafted materials are handled,
                                                                appropriately segregated and stored in accordance with
                                                                any relevant legislation and given guidelines.
                                                            </div>
                                                        }
                                                    />
                                                }
                                                alignment={true}
                                            />
                                            <Grid container wrap="nowrap">
                                                <Grid xs={1} style={{ marginRight: 8 }}>
                                                    <SubquestionIcon />
                                                </Grid>
                                                <Grid xs={11}>
                                                    <TextField
                                                        field="q_7_comment"
                                                        label="Comments"
                                                        fullWidth
                                                        multiline
                                                        rows="4"
                                                        maxRows="20"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} lg={7}>
                                            <RadioGroup
                                                fullWidth
                                                field="q_8"
                                                name="q_8"
                                                options={YES_NO_OPTIONS}
                                                alignment={true}
                                                label={
                                                    <HelpLabel
                                                        inputLabel="8. If any fertilizer, fuel, or pesticide is used, do you have safety procedures in place to prevent exposure to people and the environment through proper labeling, storage, mixing, and handling, and provide access to washing facilities?"
                                                        helpText={
                                                            <div>
                                                                This is N/A if there are no fuel storage units, tanks or
                                                                refuelling areas on your farm.
                                                                <br />
                                                                <br />
                                                                The farm must be able to demonstrate:
                                                                <br />- Storage facilities are constructed of suitable
                                                                materials and are kept secure, dry and well ventilated.
                                                                <br />- Stores are located where they minimise risks to
                                                                the environment during normal use and in foreseeable
                                                                emergencies.
                                                                <br />- A clear understanding of relevant legislation
                                                                and guidance.
                                                                <br />
                                                                <br />
                                                                If fertilisers and manures are stored on farm, either
                                                                temporarily or permanently, explain how they are stored
                                                                safely to minimise:
                                                                <br />- environmental loss (preventing runoff and
                                                                pollution)
                                                                <br />- potential theft
                                                                <br />- human exposure where higher risk products are
                                                                used (e.g. anhydrous ammonia)
                                                                <br />
                                                                <br />
                                                                Have a clear training plan and training records of all
                                                                farmers, workers and contractors who manage or are
                                                                exposed to hazardous substances. Be able to show
                                                                evidence that dealing with hazardous chemical spills is
                                                                included in health and safety training for all relevant
                                                                workers, including records of any training given.
                                                            </div>
                                                        }
                                                    />
                                                }
                                            />
                                            <Grid container wrap="nowrap">
                                                <Grid xs={1} style={{ marginRight: 8 }}>
                                                    <SubquestionIcon />
                                                </Grid>
                                                <Grid xs={11}>
                                                    <TextField
                                                        field="q_8_comment"
                                                        label="Comments"
                                                        fullWidth
                                                        multiline
                                                        rows="4"
                                                        maxRows="20"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} lg={7}>
                                            <RadioGroup
                                                fullWidth
                                                field="q_9"
                                                name="q_9"
                                                options={YES_NO_OPTIONS}
                                                alignment={true}
                                                label={
                                                    <HelpLabel
                                                        inputLabel="9. Do you provide health, safety and first aid information or training, including access to a first aid kit, to employees, and are special precautions taken for workers who are considered vulnerable (e.g., minors and any workers with compromised immune systems)?"
                                                        helpText={
                                                            <div>
                                                                Trainings can be related to general health or dangerous
                                                                work:
                                                                <br />- First aid
                                                                <br />- Working circumstances (e.g. working at height)
                                                                <br />- Emergency and accident procedures
                                                                <br />- Human health and hygiene
                                                                <br />- Other work related health risks
                                                                <br />- Using fertilizer or chemicals (agrochemicals or
                                                                other)
                                                                <br />- Handling waste
                                                                <br />- Using machinery
                                                                <br />
                                                                <br />
                                                                It is highly recommended to have a senior management
                                                                representative appointed that is responsible for the
                                                                implementation of the program and that the training is
                                                                documented.
                                                                <br />
                                                                <br />
                                                                Permanent and temporary workers refers to all workers
                                                                hired directly and through subcontractors.
                                                                <br />
                                                                <br />
                                                                First aid supplies may include:
                                                                <br />- Eye washing stations
                                                                <br />- First aid kit with sufficient supplies that are
                                                                up to date and that is regularly checked.
                                                                <br />- A list of emergency telephone numbers
                                                                <br />- Warning / Danger signs, where relevant
                                                            </div>
                                                        }
                                                    />
                                                }
                                            />
                                            <Grid container wrap="nowrap">
                                                <Grid xs={1} style={{ marginRight: 8 }}>
                                                    <SubquestionIcon />
                                                </Grid>
                                                <Grid xs={11}>
                                                    <TextField
                                                        field="q_9_comment"
                                                        label="Comments"
                                                        fullWidth
                                                        multiline
                                                        rows="4"
                                                        maxRows="20"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} lg={7}>
                                            <RadioGroup
                                                fullWidth
                                                field="q_10"
                                                name="q_10"
                                                options={YES_NO_OPTIONS_1}
                                                alignment={true}
                                                label={
                                                    <HelpLabel
                                                        inputLabel="10. Do you perform regular maintenance and, if necessary, calibration of your equipment and machinery?"
                                                        helpText={
                                                            <div>
                                                                Large farms may have a documented maintenance plan.
                                                                <br />
                                                                <br />
                                                                Equipment/machinery in question can be used for chemical
                                                                pesticides, herbicides, fungicides, rodenticides,
                                                                fertilizers, thinning or crop setting agents.
                                                                <br />
                                                                <br />
                                                                If a grower works with a subcontractor, the grower
                                                                should show that they work with a certified pest
                                                                operator. By working with a certified pest operator, one
                                                                should assume they are maintaining and calibrating their
                                                                machinery and equipment appropriately.
                                                            </div>
                                                        }
                                                    />
                                                }
                                            />
                                            <Grid container wrap="nowrap">
                                                <Grid xs={1} style={{ marginRight: 8 }}>
                                                    <SubquestionIcon />
                                                </Grid>
                                                <Grid xs={11}>
                                                    <TextField
                                                        field="q_10_comment"
                                                        label="Comments"
                                                        fullWidth
                                                        multiline
                                                        rows="4"
                                                        maxRows="20"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} lg={7}>
                                            <RadioGroup
                                                fullWidth
                                                field="q_11"
                                                name="q_11"
                                                options={YES_NO_OPTIONS_1}
                                                label={
                                                    <HelpLabel
                                                        inputLabel="11. Do you practice Integrated Pest Management when selecting and applying chemical products on your farm?"
                                                        helpText={
                                                            <div>
                                                                Using the same crop protection product (especially the
                                                                same active ingredient) continuously will result in
                                                                resistant weeds, pests or diseases. Where possible,
                                                                different crop protection products, including those with
                                                                different active ingredients should be used. This may
                                                                not be possible if a selection of different crop
                                                                protection products are not authorised for the target.
                                                                <br />
                                                                <br />
                                                                Contamination can be avoided by removing infested or
                                                                diseased plant material from the field or by chopping
                                                                and burying it in the soil and disinfecting pruning and
                                                                propagation equipment.
                                                                <br />
                                                                <br />
                                                                IPM should include a monitoring system (scouting), and
                                                                analysis about which control to use, before applying
                                                                any. This analysis should be based on
                                                                <br />- Visual inspections, taking into account economic
                                                                thresholds of pest / disease / weed occurrence
                                                                <br />- Weather forecasts
                                                                <br />- Electronic systems
                                                                <br />- Local knowledge
                                                                <br />- Advice from an expert consultant, with
                                                                recognized certificates or qualifications.
                                                                <br />
                                                                <br />
                                                                Products can include chemical pesticides, insecticides,
                                                                herbicides, fungicides and rodenticides. Non-chemical
                                                                control methods can also be used. They include:
                                                                <br />- Choice of disease and pest resistant
                                                                crop/variety;
                                                                <br />- Use of crop rotations;
                                                                <br />- Mechanical and physical methods of crop
                                                                husbandry (mechanical, weeding, trimming and pruning);
                                                                <br />- Biological pest control measures;
                                                                <br />- Good fertilizer and irrigation practices.
                                                                <br />
                                                                <br />
                                                                'Selective' means effective for the specific pest, but
                                                                not damaging or lethal to a much wider range of
                                                                organisms (which would be 'broad spectrum'). 'Targeted
                                                                application' means to aim specifically at the pest at
                                                                the right time (instead of indiscriminate spraying for
                                                                instance). 'Seed dressing' is the application of a
                                                                protective chemical coating to the seeds.
                                                                <br />
                                                                <br />
                                                                The grower can work with CCA (Certified Crop Advisers)
                                                                to incorporate resistant management into their IPM Plan.
                                                                <br />
                                                                <br />
                                                                IPM may involve scouting rather than prophylactic
                                                                approaches to pest management and a mix of mechanical,
                                                                biological and chemical control methods.
                                                            </div>
                                                        }
                                                    />
                                                }
                                                alignment={true}
                                            />
                                            <Grid container wrap="nowrap">
                                                <Grid xs={1} style={{ marginRight: 8 }}>
                                                    <SubquestionIcon />
                                                </Grid>
                                                <Grid xs={11}>
                                                    <TextField
                                                        field="q_11_comment"
                                                        label="Comments"
                                                        fullWidth
                                                        multiline
                                                        rows="4"
                                                        maxRows="20"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} lg={7}>
                                            <RadioGroup
                                                fullWidth
                                                field="q_12"
                                                name="q_12"
                                                options={YES_NO_OPTIONS_1}
                                                alignment={true}
                                                label={
                                                    <HelpLabel
                                                        inputLabel="12. When disposing of containers, do you participate in a chemical container safe disposal program, if available?"
                                                        helpText={
                                                            <div>
                                                                Waste related to chemical containers could include:
                                                                <br />- Obsolete fertilizers and pesticides and empty
                                                                containers
                                                                <br />- Plastic, paper and metal waste
                                                                <br />- Fuel and oil residues
                                                                <br />- Carcasses (in the case of mixed farms)
                                                                <br />- Redundant equipment and machinery
                                                                <br />
                                                                <br />
                                                                Mitigating measures may include:
                                                                <br />- One designated responsible person for proper
                                                                handling of waste.
                                                                <br />- Good inventory management, that supports
                                                                awareness about the amount and location of on farm
                                                                waste.
                                                                <br />- Well constructed and locked storage facilities.
                                                            </div>
                                                        }
                                                    />
                                                }
                                            />
                                            <Grid container wrap="nowrap">
                                                <Grid xs={1} style={{ marginRight: 8 }}>
                                                    <SubquestionIcon />
                                                </Grid>
                                                <Grid xs={11}>
                                                    <TextField
                                                        field="q_12_comment"
                                                        label="Comments"
                                                        fullWidth
                                                        multiline
                                                        rows="4"
                                                        maxRows="20"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} lg={7}>
                                            <RadioGroup
                                                fullWidth
                                                field="q_13"
                                                name="q_13"
                                                options={YES_NO_OPTIONS_1}
                                                alignment={true}
                                                label={
                                                    <HelpLabel
                                                        inputLabel="13. When handling wastes (containers, pallets, (excluding crop residue) from your farm, do you look for opportunities to reuse and recycle (e.g. composting, alternative markets, etc.)?"
                                                        helpText={
                                                            <div>
                                                                Examples of re-use are:
                                                                <br />- Composting organic debris on-farm and re-using
                                                                it for soil conditioning (where there is no risk of
                                                                disease transmission).
                                                                <br />- Selling your crop by-products to alternative
                                                                markets.
                                                                <br />- Use your crop by-products for alternative
                                                                purposes.
                                                                <br />
                                                                <br />
                                                                An example of recylcing is:
                                                                <br />- partaking in any recycling program for
                                                                (non-chemical) plastic or metal containers
                                                                <br />
                                                                <br />
                                                                Examples of reducing:
                                                                <br />- Purchasing in bulk, instead of many small
                                                                containers of one thing
                                                                <br />- Optimizing the production in the field, but
                                                                using the least amount of farm inputs as possible
                                                            </div>
                                                        }
                                                    />
                                                }
                                            />
                                            <Grid container wrap="nowrap">
                                                <Grid xs={1} style={{ marginRight: 8 }}>
                                                    <SubquestionIcon />
                                                </Grid>
                                                <Grid xs={11}>
                                                    <TextField
                                                        field="q_13_comment"
                                                        label="Comments"
                                                        fullWidth
                                                        multiline
                                                        rows="4"
                                                        maxRows="20"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} lg={7}>
                                            <RadioGroup
                                                fullWidth
                                                field="q_14"
                                                name="q_14"
                                                options={YES_NO_OPTIONS}
                                                alignment={true}
                                                label={
                                                    <HelpLabel
                                                        inputLabel="14. If you irrigate, do you have a water use plan that supports adherence to applicable water extraction regulations and optimization of water use, reduction of water waste, and any water quality requirements?"
                                                        helpText={
                                                            <div>
                                                                Be able to justify that the amount of irrigation water
                                                                recommended for use has been optimised for crop
                                                                requirements.
                                                                <br />
                                                                <br />
                                                                The irrigation management plan should show that your
                                                                farm's extraction and distribution patterns do not
                                                                compromise the drinking water and sanitary needs of
                                                                local communities, remove water from natural ecosystems
                                                                that require it for healthy functioning or impact crop
                                                                production because salinity problems are exacerbated by
                                                                shallow water tables.
                                                                <br />
                                                                <br />
                                                                Be able to demonstrate that the irrigation method used
                                                                on the farm is the most suitable for the crop and for
                                                                climatic and economic conditions and takes into
                                                                consideration water stress.
                                                                <br />
                                                                Be able to show that the irrigation system is maintained
                                                                to ensure efficient water use.
                                                                <br />
                                                                <br />
                                                                Provide evidence of water quality analysis for all of
                                                                the different water sources and, where necessary, any
                                                                treatment required to bring it into compliance for crop
                                                                water and soil requirements.
                                                            </div>
                                                        }
                                                    />
                                                }
                                            />
                                            <Grid container wrap="nowrap">
                                                <Grid xs={1} style={{ marginRight: 8 }}>
                                                    <SubquestionIcon />
                                                </Grid>
                                                <Grid xs={11}>
                                                    <TextField
                                                        field="q_14_comment"
                                                        label="Comments"
                                                        fullWidth
                                                        multiline
                                                        rows="4"
                                                        maxRows="20"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} lg={7}>
                                            <RadioGroup
                                                fullWidth
                                                field="q_15"
                                                name="q_15"
                                                options={YES_NO_OPTIONS}
                                                alignment={true}
                                                label={
                                                    <HelpLabel
                                                        inputLabel="15. Is discharge and use of any untreated sewage sludge or manure slurry on land managed in accordance with relevant regulations and industry guidelines to avoid contamination of the environment and to protect water bodies?"
                                                        helpText="If sewage sludge or manure slurry is used as a fertilizer, measures should be in place to manage its use in accordance with relevant regulations and industry guidelines."
                                                    />
                                                }
                                            />
                                            <Grid container wrap="nowrap">
                                                <Grid xs={1} style={{ marginRight: 8 }}>
                                                    <SubquestionIcon />
                                                </Grid>
                                                <Grid xs={11}>
                                                    <TextField
                                                        field="q_15_comment"
                                                        label="Comments"
                                                        fullWidth
                                                        multiline
                                                        rows="4"
                                                        maxRows="20"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} lg={7}>
                                            <RadioGroup
                                                fullWidth
                                                field="q_16"
                                                name="q_16"
                                                options={YES_NO_OPTIONS}
                                                alignment={true}
                                                label={
                                                    <HelpLabel
                                                        inputLabel="16. Were all areas identified as conservation areas left in their original state since 2016?"
                                                        helpText={
                                                            <div>
                                                                Provide historic and current aerial or satellite imagery
                                                                that illustrates no primary forest, mangrove, wetland,
                                                                peatland, protected grassland or legal reserve has been
                                                                destroyed since 31.12.2015. If no aerial or satellite
                                                                imagery is available, the provision of a map or other
                                                                documentation that delineates the area of the farm and
                                                                any protected areas should be provided as evidence.
                                                                <br />
                                                                This is N/A if there is no primary forest, mangrove,
                                                                wetland, peatland, protected grassland or legal reserve
                                                                on your land.
                                                            </div>
                                                        }
                                                    />
                                                }
                                            />
                                            <Grid container wrap="nowrap">
                                                <Grid xs={1} style={{ marginRight: 8 }}>
                                                    <SubquestionIcon />
                                                </Grid>
                                                <Grid xs={11}>
                                                    <TextField
                                                        field="q_16_comment"
                                                        label="Comments"
                                                        fullWidth
                                                        multiline
                                                        rows="4"
                                                        maxRows="20"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} lg={7}>
                                            <RadioGroup
                                                fullWidth
                                                field="q_17"
                                                name="q_17"
                                                options={YES_NO_OPTIONS_1}
                                                alignment={true}
                                                label={
                                                    <HelpLabel
                                                        inputLabel="17. Do you take measures to improve biodiversity with any of the following practices that apply to your farming operation: protecting native species, managing invasive species, and preventing illegal hunting and extraction of flora and fauna?"
                                                        helpText={
                                                            <div>
                                                                The farm manages and creates habitats as part of a BAP
                                                                by planting and managing a mixed population of native
                                                                herbaceous, bush and tree species to create habitats of
                                                                high biodiversity value. The farm manages any areas
                                                                where beneficial species of flora and fauna can be found
                                                                to promote their existence.
                                                                <br />
                                                                <br />
                                                                The farm carries out assessments and records the
                                                                presence of rare or endangered species and habitats on
                                                                the farm and within the local area. Records are also
                                                                kept of any known wildlife corridors within the
                                                                landscape.
                                                                <br />
                                                                <br />
                                                                Show that you have carried out an assessment of plants
                                                                growing on your farm to determine the presence of any
                                                                invasive species and that you are aware of potential
                                                                impact to your farm, the local environment and the
                                                                community.
                                                                <br />
                                                                <br />
                                                                Demonstrate how the farm communicates with staff,
                                                                workers, the community and any hunters allowed to use
                                                                the land to ensure all activities are in accordance with
                                                                relevant legislation and customary laws.
                                                            </div>
                                                        }
                                                    />
                                                }
                                            />
                                            <Grid container wrap="nowrap">
                                                <Grid xs={1} style={{ marginRight: 8 }}>
                                                    <SubquestionIcon />
                                                </Grid>
                                                <Grid xs={11}>
                                                    <TextField
                                                        field="q_17_comment"
                                                        label="Comments"
                                                        fullWidth
                                                        multiline
                                                        rows="4"
                                                        maxRows="20"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} lg={7}>
                                            <RadioGroup
                                                fullWidth
                                                field="q_18"
                                                name="q_18"
                                                options={YES_NO_OPTIONS}
                                                alignment={true}
                                                label={
                                                    <HelpLabel
                                                        inputLabel="18. Do you ensure that working hours do not exceed the maximum allowed, and is paid overtime, paid holiday leave, paid sick leave and paid parental leave provided for your permanent and temporary workers?"
                                                        helpText={
                                                            <div>
                                                                The farm has clear policies and procedures stating
                                                                permanent, temporary and seasonal workers are not
                                                                required to work more than the regular and overtime
                                                                hours in accordance with national laws, collective
                                                                agreements and the provisions of the relevant ILO
                                                                Conventions.
                                                                <br />
                                                                <br />
                                                                Show clear policies for deciding on overtime and
                                                                securing worker consent.
                                                                <br />
                                                                Be able to present evidence showing all overtime is on a
                                                                voluntary basis and fairly remunerated.
                                                                <br />
                                                                <br />
                                                                Have a clear policy on the various types of leave in
                                                                accordance with national laws, collective agreements and
                                                                the provisions of the relevant ILO Conventions and
                                                                provide evidence to show how the policy has been applied
                                                                on the farm.
                                                                <br />
                                                                This is N/A if there is no legal requirement for paid
                                                                vacation, parental leave and sickness benefit.
                                                            </div>
                                                        }
                                                    />
                                                }
                                            />
                                            <Grid container wrap="nowrap">
                                                <Grid xs={1} style={{ marginRight: 8 }}>
                                                    <SubquestionIcon />
                                                </Grid>
                                                <Grid xs={11}>
                                                    <TextField
                                                        field="q_18_comment"
                                                        label="Comments"
                                                        fullWidth
                                                        multiline
                                                        rows="4"
                                                        maxRows="20"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} lg={7}>
                                            <RadioGroup
                                                fullWidth
                                                field="q_19"
                                                name="q_19"
                                                options={YES_NO_OPTIONS}
                                                alignment={true}
                                                label={
                                                    <HelpLabel
                                                        inputLabel="19. Do workers have access to sanitation facilities and potable water, and if workers live onsite housing is in good condition?"
                                                        helpText={
                                                            <div>
                                                                Facilities are provided for all permanent, temporary and
                                                                seasonal workers and there is a policy allowing for
                                                                regular breaks in accordance with national laws,
                                                                collective agreements and the provisions of the relevant
                                                                ILO conventions.
                                                                <br />
                                                                <br />
                                                                Be able to show that on-farm living quarters offered to
                                                                permanent, temporary and seasonal workers:
                                                                <br />- are habitable and structurally safe,
                                                                <br />- have provision for hygienic food storage and
                                                                preparation,
                                                                <br />- have cooking facilities with suitable
                                                                ventilation,
                                                                <br />- and have the basic services of drinking water,
                                                                toilets, and drains. In the case of no drains, septic
                                                                pits can be accepted if compliant with local
                                                                regulations.
                                                                <br />
                                                                This is N/A if permanent, temporary or seasonal workers,
                                                                or their families, do not rely on accommodation provided
                                                                by the farm.
                                                            </div>
                                                        }
                                                    />
                                                }
                                            />
                                            <Grid container wrap="nowrap">
                                                <Grid xs={1} style={{ marginRight: 8 }}>
                                                    <SubquestionIcon />
                                                </Grid>
                                                <Grid xs={11}>
                                                    <TextField
                                                        field="q_19_comment"
                                                        label="Comments"
                                                        fullWidth
                                                        multiline
                                                        rows="4"
                                                        maxRows="20"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} lg={7}>
                                            <RadioGroup
                                                fullWidth
                                                field="q_20"
                                                name="q_20"
                                                options={YES_NO_OPTIONS}
                                                alignment={true}
                                                label={
                                                    <HelpLabel
                                                        inputLabel="20. Do workers have the right to form worker associations if they choose and is there a mechanism that allows workers to resolve complaints with farm management?"
                                                        helpText={
                                                            <div>
                                                                Develop policies and procedures that demonstrate a
                                                                worker’s right to freedom of association, trade union
                                                                membership and collective bargaining. Show that these
                                                                are recognised and respected on the farm and workers are
                                                                not intimidated or harassed in exercising their right to
                                                                join or refrain from joining any organisation.
                                                                <br />
                                                                This is N/A if there are government imposed restrictions
                                                                on freedom of association.
                                                                <br />
                                                                <br />
                                                                Worker representatives are able to talk to employees and
                                                                have reasonable access to company facilities and
                                                                documents required to fulfil their duties. Ensure that a
                                                                worker’s choice to form, or join, a trade union will not
                                                                compromise their equal treatment at work.
                                                                <br />
                                                                This is N/A if labour organisations do not exist for
                                                                your industry.
                                                                <br />
                                                                <br />
                                                                The farm has transparent, fair and confidential
                                                                procedures that provide effective, accessible channels
                                                                for workers to complain, lodge grievances and make
                                                                suggestions. The processes in place will ensure the
                                                                matter is fully investigated and will result in swift,
                                                                unbiased and fair resolution.
                                                            </div>
                                                        }
                                                    />
                                                }
                                            />
                                            <Grid container wrap="nowrap">
                                                <Grid xs={1} style={{ marginRight: 8 }}>
                                                    <SubquestionIcon />
                                                </Grid>
                                                <Grid xs={11}>
                                                    <TextField
                                                        field="q_20_comment"
                                                        label="Comments"
                                                        fullWidth
                                                        multiline
                                                        rows="4"
                                                        maxRows="20"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} lg={7}>
                                            <RadioGroup
                                                fullWidth
                                                field="q_21"
                                                name="q_21"
                                                options={YES_NO_OPTIONS}
                                                alignment={true}
                                                label={
                                                    <HelpLabel
                                                        inputLabel="21. Is safety information, including emergency contact details for the operation’s management, easily accessible for your workers with translation of important information or language lessons available if necessary?"
                                                        helpText={
                                                            <div>
                                                                Where workers have poor reading skills or do not read
                                                                the local language, have a system in place so
                                                                information is provided in such a way that it is
                                                                understood by all workers.
                                                                <br />
                                                                This is N/A if the primary language and ethnicity is
                                                                common to the entire permanent, temporary and seasonal
                                                                workforce.
                                                                <br />
                                                                <br />
                                                                Emergency contact details shall be provided in all
                                                                respective languages used to communicate to and by
                                                                permanent, temporary and seasonal workers and, where
                                                                possible, using large, visible signage strategically
                                                                displayed in key positions around the farm and in farm
                                                                vehicles.
                                                            </div>
                                                        }
                                                    />
                                                }
                                            />
                                            <Grid container wrap="nowrap">
                                                <Grid xs={1} style={{ marginRight: 8 }}>
                                                    <SubquestionIcon />
                                                </Grid>
                                                <Grid xs={11}>
                                                    <TextField
                                                        field="q_21_comment"
                                                        label="Comments"
                                                        fullWidth
                                                        multiline
                                                        rows="4"
                                                        maxRows="20"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} lg={7}>
                                            <RadioGroup
                                                fullWidth
                                                field="q_22"
                                                name="q_22"
                                                options={YES_NO_OPTIONS}
                                                alignment={true}
                                                label={
                                                    <HelpLabel
                                                        inputLabel="22. Do you ensure minors on the farm go to school or receive home schooling?"
                                                        helpText={
                                                            <div>
                                                                Be able to demonstrate all minors on the farm are
                                                                enrolled in a recognised schooling programme.
                                                                <br />
                                                                This is N/A if you do not have children working or
                                                                living on the farm.
                                                                <br />
                                                                <br />
                                                                When young workers are employed it is important to
                                                                promote their development by allowing them access to
                                                                education, whether this is attending school or receiving
                                                                schooling at home as part of a recognised programme.
                                                            </div>
                                                        }
                                                    />
                                                }
                                            />
                                            <Grid container wrap="nowrap">
                                                <Grid xs={1} style={{ marginRight: 8 }}>
                                                    <SubquestionIcon />
                                                </Grid>
                                                <Grid xs={11}>
                                                    <TextField
                                                        field="q_22_comment"
                                                        label="Comments"
                                                        fullWidth
                                                        multiline
                                                        rows="4"
                                                        maxRows="20"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} lg={7} style={{ textAlign: "center" }}>
                                            <Button
                                                onClick={() => this.returnFarm()}
                                                size="small"
                                                color="primary"
                                                style={{ marginRight: 24, border: "1px solid" }}
                                            >
                                                Return to Farm Homepage
                                            </Button>
                                            <Button type="submit" variant="raised" size="small" color="primary">
                                                Save and Return to Farm Homepage
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Grid>
                        )}
                    </Form>
                </Grid>
            </AppContainer>
        );
    }
}

Survey = connect(
    (state, ownProps) => ({
        farmSurvey: getFarmSurvey(state, ownProps),
    }),
    {
        ...FarmSurvey.actions,
    },
)(Survey);

export default withRouter(Survey);
