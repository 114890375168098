import React, { Component } from "react";

import withStyles from "@mui/styles/withStyles";

import { TableHead, TableRow, TableSortLabel } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Typography from "../common/TypographyWrapper";

import CustomTableCell from "./TableCell";

const styles = {
    centerAlign: {
        textAlign: "center",
    },
};

class EnhancedTableHead extends Component {
    createSortHandler = (property) => (event) => {
        this.props.onRequestSort(event, property);
    };

    render() {
        const { order, classes, orderBy, columnData, actions, actionColumnWidth, rowStyle, cellStyle } = this.props;

        return (
            <TableHead>
                <TableRow style={rowStyle}>
                    {actions && (
                        <CustomTableCell key={"actions"} sortDirection={false} style={{ width: actionColumnWidth }}>
                            <Typography>{"Actions"}</Typography>
                        </CustomTableCell>
                    )}
                    {columnData.map((column) => {
                        return (
                            <CustomTableCell
                                key={column.id}
                                sortDirection={orderBy === column.id ? order : false}
                                style={{
                                    width: column.width ? column.width : "auto",
                                    ...cellStyle,
                                }}
                                className={column.center ? classes.centerAlign : ""}
                            >
                                {column.allowSort && (
                                    <Tooltip title="Sort" placement="bottom-start" enterDelay={300}>
                                        <TableSortLabel
                                            active={orderBy === column.id}
                                            direction={order}
                                            onClick={this.createSortHandler(column.id)}
                                        >
                                            <Typography>{column.label}</Typography>
                                        </TableSortLabel>
                                    </Tooltip>
                                )}
                                {!column.allowSort &&
                                    (cellStyle ? column.label : <Typography>{column.label}</Typography>)}
                            </CustomTableCell>
                        );
                    }, this)}
                </TableRow>
            </TableHead>
        );
    }
}

export default withStyles(styles)(EnhancedTableHead);
