import React, { Component } from "react";
import Card from "@mui/material/Card";
import Typography from "../../../common/TypographyWrapper";

import { PieChart, Pie, Cell, Legend } from "recharts";
import { getValue } from "../../../../api/utils";
import { chartValue, getDescription, PIE_CHART_LABEL } from "../StaticData";

import Callout from "../Callout";

const SOIL_CONSERVATION_COLORS = ["blue", "orange"];

class SoilConservation extends Component {
    state = {
        activeIndex: null,
    };

    onPieEnter = (data, index) => {
        this.setState({
            activeIndex: index,
        });
    };

    render() {
        const { indicator, printable } = this.props,
            water = chartValue(getValue(indicator, "fieldprintWater.value"), indicator.metric),
            wind = chartValue(getValue(indicator, "fieldprintWind.value"), indicator.metric),
            unit = getValue(indicator, "fieldprintWater.unit"),
            chartHeight = printable ? 120 : 270;
        if (printable) {
            return null;
        }
        return (
            <Callout
                title={"Breakdown of Soil Conservation Score Components"}
                printable={printable}
                split={"500px"}
                variant="triangleTop2"
            >
                <Card
                    style={{
                        width: "550px",
                        textAlign: "center",
                    }}
                    elevation={printable ? 0 : 1}
                >
                    <Typography>Soil Erosion in {unit}</Typography>
                    <PieChart width={550} height={chartHeight}>
                        <Pie
                            data={[
                                {
                                    name: "Soil Water Erosion",
                                    value: water,
                                },
                                {
                                    name: "Soil Wind Erosion",
                                    value: wind,
                                },
                            ]}
                            cx={265}
                            cy={chartHeight / (2 + 3 / 9)}
                            innerRadius={chartHeight / (3 + 4 / 3)}
                            outerRadius={chartHeight / (2 + 5 / 4)}
                            startAngle={45}
                            endAngle={-360}
                            activeShape={(props) => PIE_CHART_LABEL(props, unit)}
                            onMouseEnter={this.onPieEnter}
                            activeIndex={this.state.activeIndex}
                        >
                            <Cell fill={SOIL_CONSERVATION_COLORS[0]} />
                            <Cell fill={SOIL_CONSERVATION_COLORS[1]} />
                        </Pie>
                        <br />
                        <Legend iconSize={7} onMouseEnter={this.onPieEnter} />
                    </PieChart>
                </Card>
                <Typography>{getDescription("soilConservationChart")}</Typography>
            </Callout>
        );
    }
}

export default SoilConservation;
