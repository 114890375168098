import { useState, useEffect } from "react";
import { Layer, Popup, useMap } from "react-map-gl/maplibre";
import intersect from "@turf/intersect";
import circle from "@turf/circle";
import { reduceFeature } from "../Draw";
import FeatureService from "mapbox-gl-arcgis-featureserver";
import apiKey from "../../../apikey.json";

const SSURGO_SERVICE = "https://landscape11.arcgis.com/arcgis/rest/services/USA_Soils_Map_Units/featureserver/0",
    SSURGO_FILL_STYLE = {
        id: "ssurgo_fill",
        type: "fill",
        minzoom: 12,
        maxzoom: 20,
        paint: {
            "fill-color": "#ff8000",
            "fill-opacity": ["case", ["to-boolean", ["feature-state", "selected"]], 0.6, 0.1],
        },
    },
    SSURGO_LINE_STYLE = {
        id: "ssurgo_line",
        type: "line",
        minzoom: 12,
        maxzoom: 20,
        paint: {
            "line-color": "#ff8000",
            "line-width": ["interpolate", ["linear"], ["zoom"], 12, 1, 15, 3],
        },
    },
    SSURGO_SYMBOL_STYLE = {
        id: "ssurgo_symbol",
        type: "symbol",
        minzoom: 12,
        maxzoom: 20,
        layout: {
            "text-field": "{musym}",
            "text-font": ["Ubuntu Bold"],
            "text-size": 12,
        },
        paint: {
            "text-color": "#ff8000",
            "text-halo-color": "black",
            "text-halo-width": 1,
        },
    };

class SsurgoService extends FeatureService {
    _getServiceMetadata() {
        return super._getServiceMetadata().then((m) => (m.uniqueIdField = { name: "objectid" }));
    }
    _findAndMapData() {
        if (!this._map.getLayer("ssurgo_fill")) {
            return;
        }
        return super._findAndMapData();
    }
    _preload() {
        return super._findAndMapData();
    }
}

export default function SoilsLayer({ activeLayers, flags }) {
    const { current: map } = useMap(),
        [service, setService] = useState(null),
        [popup, setPopup] = useState(null),
        closePopup = () => setPopup(null);

    useEffect(() => {
        if (!map) {
            return;
        }
        map.on("idle", updateService);
        function updateService() {
            map.off("idle", updateService);
            setService((service) => {
                if (map.getSource("ssurgo")) {
                    return service;
                } else {
                    if (service) {
                        service.disableRequests();
                    }
                    return new SsurgoService("ssurgo", map.getMap(), {
                        url: SSURGO_SERVICE,
                        token: apiKey.agolToken,
                        minZoom: SSURGO_FILL_STYLE.minzoom,
                        useStaticZoomLevel: true,
                        useServiceBounds: false,
                        outFields: "esrisymbology,musym,objectid",
                    });
                }
            });
        }
        return () => map.off("idle", updateService);
    }, [map, activeLayers]);
    useEffect(() => {
        if (!service || !map) {
            return;
        }
        map.on("click", "ssurgo_fill", getInfo);
        map.on("click", "ssurgo_line", getInfo);
        map.on("click", "ssurgo_symbol", getInfo);

        async function getInfo(evt) {
            const feature = evt.features[0];
            if (!feature) {
                return;
            }
            const draw = map.getMap()._controls.find((control) => control.getMode);
            if (
                draw &&
                (draw.getMode() !== "simple_select" ||
                    intersect(reduceFeature(draw.getAll()), circle(evt.lngLat.toArray(), 0.01)))
            ) {
                return;
            }
            setPopup({ feature, title: "Loading details...", lngLat: evt.lngLat });

            const data = await service.getFeaturesByObjectIds(feature.id),
                fullFeature = data && data.features && data.features[0];

            if (fullFeature) {
                const { muname, popupstring } = fullFeature.properties;
                setPopup({ feature: fullFeature, title: muname, content: popupstring, lngLat: evt.lngLat });
            } else {
                setPopup({ feature, title: "Error loading details", lngLat: evt.lngLat });
            }
        }

        return () => {
            map.off("click", "ssurgo_fill", getInfo);
            map.off("click", "ssurgo_line", getInfo);
            map.off("click", "ssurgo_symbol", getInfo);
        };
    }, [service, map]);

    useEffect(() => {
        if (!service || !activeLayers.soils) {
            return;
        }
        service._preload();
    }, [service, activeLayers.soils]);

    useEffect(() => {
        if (!map || !map.loaded()) {
            return;
        }
        map.removeFeatureState({ source: "ssurgo" });
        if (popup) {
            map.setFeatureState({ source: "ssurgo", id: popup.feature.id }, { selected: true });
        }
    }, [map, popup]);

    if (!service || !activeLayers.soils || flags.select_clu) {
        return null;
    }

    return (
        <>
            <Layer {...SSURGO_FILL_STYLE} source="ssurgo" beforeId="_placeholder" />
            <Layer {...SSURGO_LINE_STYLE} source="ssurgo" beforeId="_placeholder" />
            <Layer {...SSURGO_SYMBOL_STYLE} source="ssurgo" beforeId="_placeholder" />
            {popup && (
                <Popup longitude={popup.lngLat.lng} latitude={popup.lngLat.lat} maxWidth={null} onClose={closePopup}>
                    <div style={{ width: 360, minHeight: 200 }}>
                        <h3>{popup.title}</h3>
                        <div
                            style={{ height: 200, borderTop: "1px solid #999", overflowY: "auto" }}
                            dangerouslySetInnerHTML={{ __html: popup.content || "Loading..." }}
                        />
                    </div>
                </Popup>
            )}
        </>
    );
}
