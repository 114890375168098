import React, { Component } from "react";
import Typography from "../../../common/TypographyWrapper";
import Card from "@mui/material/Card";
import { getValue } from "../../../../api/utils";
import { getColor, getDescription } from "../StaticData";

import Callout from "../Callout";
import { withTableStyles } from "../Table";

const styles = {
    soilCarbonCard: {
        width: "530px",
        height: "133px",
    },
    soilCarbonAxisQuartile: {
        width: "25%",
        height: "30px",
        display: "inline-block",
    },
    soilCarbonDot: {
        position: "relative",
        height: 0,
        width: 0,
        borderLeft: "20px solid transparent",
        borderRight: "20px solid transparent",
        borderTop: "20px solid " + getColor("national"),
    },
    soilCarbonGradient: {
        height: "30px",
        background: "linear-gradient(to right, #f8d568, green)",
    },
};

// Convert the SCI index to a CSS left value
const convertRange = (value, r1, r2) => {
    return ((value - r1[0]) * (r2[1] - r2[0])) / (r1[1] - r1[0]) + r2[0];
};

class SoilCarbon extends Component {
    render() {
        const { indicator, printable } = this.props,
            score = getValue(indicator, "fieldprintResult.value");

        if (printable) {
            return null;
        }

        return (
            <Callout
                title={"Soil Carbon Score Description"}
                printable={printable}
                split={"580px"}
                variant="triangleTop2"
            >
                <>
                    <Card style={styles.soilCarbonCard}>
                        <Typography
                            gutterBottom
                            style={{
                                textAlign: "center",
                            }}
                        >
                            SCI — {indicator.year} {indicator.crop}
                        </Typography>
                        <div
                            style={{
                                width: "500px",
                                margin: "0 auto",
                            }}
                        >
                            <div
                                style={{
                                    ...styles.soilCarbonDot,
                                    ...{
                                        // left: -20 = -1
                                        // left: 480 = 1
                                        left: convertRange(score, [-1, 1], [-20, 480]),
                                    },
                                }}
                            />
                        </div>
                        <hr
                            style={{
                                width: "500px",
                            }}
                        />
                        <div
                            style={{
                                width: "500px",
                                height: "30px",
                                margin: "0 auto",
                            }}
                        >
                            <div style={styles.soilCarbonAxisQuartile}>
                                <span
                                    style={{
                                        float: "left",
                                    }}
                                >
                                    -1
                                </span>
                            </div>
                            <div style={styles.soilCarbonAxisQuartile}>
                                <span
                                    style={{
                                        float: "left",
                                    }}
                                >
                                    -0.5
                                </span>
                            </div>
                            <div style={styles.soilCarbonAxisQuartile}>
                                <span
                                    style={{
                                        float: "left",
                                    }}
                                >
                                    0
                                </span>
                            </div>
                            <div style={styles.soilCarbonAxisQuartile}>
                                <span
                                    style={{
                                        float: "left",
                                    }}
                                >
                                    0.5
                                </span>
                                <span
                                    style={{
                                        float: "right",
                                    }}
                                >
                                    1
                                </span>
                            </div>
                        </div>
                        <div style={styles.soilCarbonGradient}> </div>
                    </Card>
                    <div
                        style={{
                            width: "530px",
                        }}
                    >
                        <span
                            style={{
                                float: "left",
                            }}
                        >
                            Depleting
                        </span>
                        <span
                            style={{
                                position: "relative",
                                left: 170,
                            }}
                        >
                            Maintaining
                        </span>
                        <span
                            style={{
                                float: "right",
                            }}
                        >
                            Increasing
                        </span>
                    </div>
                    <div
                        style={{
                            width: "530px",
                            margin: "20px auto",
                        }}
                    />
                </>
                <Typography>{getDescription("soilCarbonChart")}</Typography>
            </Callout>
        );
    }
}

export default withTableStyles(styles)(SoilCarbon);
