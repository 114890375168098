import { useBlocker } from "react-router-dom";
import DefaultWarning from "./WarningDialogWrapper";
export default function Prompt({ when, message, Warning = DefaultWarning }) {
    const blocker = useBlocker(
        ({ currentLocation, nextLocation }) => when && currentLocation.pathname !== nextLocation.pathname,
    );
    if (blocker.state === "blocked") {
        return <Warning message={message} callback={(proceed) => (proceed ? blocker.proceed() : blocker.reset())} />;
    } else {
        return null;
    }
}
